import { paramsSerializerWithArray } from '~shared/lib/utils';

import { apiInstance } from '../apiInstance';

import {
  ApiGetCalls,
  ApiGetCallsRawData,
  ApiPostAcceptCall,
  ApiPostAcceptCallResponseData,
  ApiPostCreateCall,
  ApiPostCreateCallResponseData,
} from './types';
import { CallsRoutes } from './routes';
import { mapGetCallsResponse } from './mappers';

export const getCalls: ApiGetCalls = (params?) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<Array<ApiGetCallsRawData>>(CallsRoutes.calls(), {
        params,
        paramsSerializer: paramsSerializerWithArray,
      })
      .then(({ data }) => resolve(mapGetCallsResponse(data)))
      .catch(reject);
  });
};

export const postCreateCall: ApiPostCreateCall = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiPostCreateCallResponseData>(CallsRoutes.createCall(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postAcceptCall: ApiPostAcceptCall = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiPostAcceptCallResponseData>(CallsRoutes.acceptCall(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
