import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

import { Backdrop, Modal } from '../../common';

export const PreloaderRoot = styled(Modal)(({ theme }) => ({
  zIndex: theme.zIndex.preloader,
}));

export const PreloaderPaper = styled(motion.div)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const LogoWrapper = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gap: 24,
  alignItems: 'center',
  justifyItems: 'center',
});

export const PreloaderBackdrop = styled(Backdrop)(({ theme }) => ({
  background: theme.palette.paper.main,
}));
