import { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useThrottle } from 'react-use';
import i18n from 'i18next';

import { useQuery } from 'react-query';

import { BaseTextField, CircularProgress, Icon } from '~shared/ui';
import { postCheckNickname } from '~shared/api';

import { NicknameFieldFC, NicknameFieldProps } from './types';

/**
 * Use `_checkingNickname` hook-form field value to determine is nickname uniqueness is checking at time
 *
 * @example
 * const checking: boolean = form.watch(NicknameField.checkingNicknameFieldName);
 * @example
 * const checking: boolean = form.watch('_checkingNickname');
 */
export const NicknameField: NicknameFieldFC<NicknameFieldProps> = ({
  name = 'nickname',
  currentNickname,

  ...props
}) => {
  const { trigger, watch } = useFormContext();

  const abortControllerRef = useRef<AbortController>();

  const nickname = useThrottle(watch(name) || '', 250);

  const {
    isFetching,
    isFetched,
    data: isTaken = false,
  } = useQuery({
    queryKey: ['checkingNickname', nickname],
    enabled: !!nickname.length && validateNickname(false)(nickname) === true,
    queryFn: () => {
      abortControllerRef.current?.abort();

      if (nickname === currentNickname) {
        return false;
      }

      abortControllerRef.current = new AbortController();

      return postCheckNickname({ nickname }, abortControllerRef.current.signal).then(
        (data) => data.isTaken
      );
    },
  });

  useEffect(() => {
    if (isFetched) {
      trigger(name);
    }
  }, [isFetched, isTaken, name, trigger]);

  const {
    field: { onChange: setChecking },
  } = useController({
    name: NicknameField.checkingNicknameFieldName,
    defaultValue: null,
    shouldUnregister: true,
  });

  useEffect(() => {
    setChecking(isFetching);
  }, [isFetching, setChecking]);

  const renderEndAdornment = () => {
    switch (true) {
      case isFetching:
        return <CircularProgress />;
      case !isFetched:
        return;
      case validateNickname(isTaken)(nickname) !== true:
        return <Icon name="x" color="error.main" />;
      case validateNickname(isTaken)(nickname) === true:
        return <Icon name="check-circle" color="success.main" />;
    }
  };

  return (
    <BaseTextField
      omitWhitespace
      name={name}
      label="Nickname"
      maxLength={50}
      required
      endAdornment={renderEndAdornment()}
      rules={{ validate: validateNickname(isTaken) }}
      {...props}
    />
  );
};

const validateNickname = (isTaken?: boolean) => (nickname: string) => {
  if (isTaken) {
    return i18n.t('Other.nicknameAlreadyTaken');
  }

  if (/[a-zA-Z]+/.test(nickname)) {
    if (/^([a-zA-Z0-9_-]+){1,50}$/.test(nickname)) {
      return true;
    }

    return i18n.t('Other.nicknameValidationRules');
  }

  return i18n.t('Other.nicknameValidationLatinCharacters');
};

NicknameField.checkingNicknameFieldName = '_checkingNickname';
