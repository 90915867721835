import { FC, SVGProps, forwardRef, useMemo } from 'react';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';
import { clsx } from 'clsx';

import { IconAssetNamesType, IconProps } from './types';
import { UIKitIcon, iconClasses } from './styled';
import { assets } from './assets/assets';

export const Icon: FC<IconProps> = forwardRef<SVGSVGElement, IconProps>(
  ({ name, size = 24, component, ...props }, ref) => {
    const computedComponent: FC<SVGProps<SVGSVGElement>> | undefined = useMemo(() => {
      return getAssetComponentByName(name) || component;
    }, [name, component]);

    if (!computedComponent) {
      throw new Error(`There is no icon with name '${name}' or \`component\` prop not provided`);
    }

    return (
      <UIKitIcon
        {...extendSxProp(props as {})}
        className={clsx(iconClasses.root, props.className)}
        as={computedComponent}
        size={size}
        ref={ref}
      />
    );
  }
);

const getAssetComponentByName = (name?: IconAssetNamesType): FC | null => {
  return name ? assets[name] : null;
};
