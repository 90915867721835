import { styled } from '~shared/lib/styles';
import { ButtonIcon, buttonIconClasses } from '~shared/ui';

interface SwitchLanguageButtonProps {
  $open: boolean;
}

export const SwitchLanguageButton = styled(ButtonIcon)<SwitchLanguageButtonProps>(
  ({ theme, $open }) => ({
    [`&.${buttonIconClasses.plain}`]: {
      border: '2px solid transparent',
      transition: theme.transitions.create('border'),

      ['&:hover']: {
        boxShadow: 'none',
        border: `2px solid ${theme.palette.primary.main}`,
      },

      ...($open ? { border: `2px solid ${theme.palette.primary.main}` } : null),
    },
  })
);
