import { FC } from 'react';
import { clsx } from 'clsx';
import { Autoplay } from 'swiper/modules';

import { useMediaQuery } from '~shared/lib/hooks';

import { UIKitSlidesRoot, slidesClasses } from './styled';
import { SlidesProps } from './types';

export const Slides: FC<SlidesProps> = ({
  size = 'auto',
  container = true,

  className,
  modules,
  ...props
}) => {
  const { isMobile, isDesktop } = useMediaQuery();

  const computedClassName = clsx(
    slidesClasses.root,
    {
      [slidesClasses.container]: container,

      [slidesClasses.sm]: size === 'auto' ? isMobile : size === 'sm',
      [slidesClasses.md]: size === 'auto' ? isDesktop : size === 'md',

      [slidesClasses.responsive]: size === 'responsive',
    },
    className
  );

  return (
    <UIKitSlidesRoot
      className={computedClassName}
      spaceBetween={12}
      slidesPerView="auto"
      modules={[Autoplay, ...(Array.isArray(modules) ? modules : [])]}
      height={typeof size === 'number' ? size : undefined}
      {...props}
    />
  );
};
