import { useNavigate } from 'react-router';
import { SyntheticEvent, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';

import { useCreateSwitcher, useDispatch, useSearchParamsState } from '~shared/lib/hooks';
import { parseEventIdToBlockchainFormat } from '~shared/lib/utils';
import {
  ApiGetBetsBetMappedData,
  ApiGetCallsMappedData,
  ApiMakeBetRequestData,
  ApiPostAcceptCallRequestData,
  ApiPostCreateCallRequestData,
  BattleResult,
  Nft,
  NftRelatedBet,
  PartialNft,
  getBattlesByIds,
  postAcceptCall,
  postCreateCall,
  postMakeBet,
} from '~shared/api';
import { routes } from '~shared/config';

import { nftActions, useNftCardModel } from '~entities/nft';
import { useProfileModel } from '~entities/profile';
import { useViewerModel } from '~entities/viewer';
import { isEventPassed } from '~entities/battle';

// todo: fsd
import { useEventTransactions } from '~features/event-dialog';

import { signGaslessTxMessage, useCallGasless, useConnect } from '~entities/wallet';

import { Arena__factory } from '~shared/contracts';

import { getEventStatisticRowsData } from '../lib';

import { eventActions } from './slice';
import { EventDialogTab } from './config';
import { useEventSelector } from './selectors';
import { EventState, EventStateEvent } from './types';

export const useEventModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { account } = useWeb3React();

  const isConnected = !!account;

  const profile = useProfileModel();
  const viewer = useViewerModel();
  const { nfts, setSelectionMode } = useNftCardModel();

  const [eventIdParam, setEventIdParam, omitEventIdParam] = useSearchParamsState<string>(
    'eventId',
    ''
  );

  const {
    closingConfirmationDialogOpen,
    bannerDetailedInfoDialogOpen,
    nftWithWinstreakDialog,

    tab,

    event,
    cards,
    additionalCards,
    choice,
    result,

    callId,
    callCreator,
    callAcceptor,
    callAcceptableRarity,

    isCall,
    isMakeCall,
    isPassedEvent,
    isViewerCall,
    isViewerBattle,
    isViewMode,
  } = useEventSelector();

  const { isLoading } = useQuery(
    ['event', eventIdParam],
    () => {
      getBattlesByIds({ ids: [eventIdParam] }).then((data) => {
        if (data[0]) {
          dispatch(eventActions.set({ event: data[0] }));
        }
      });
    },
    { enabled: Boolean(eventIdParam) ? !event || event.id !== eventIdParam : false }
  );

  const openEvent = (
    event: string | EventStateEvent,
    params?: Partial<Omit<EventState, 'event' | 'cards'> & { cards: Array<Nft | PartialNft> }>
  ) => {
    dispatch(eventActions.reset());

    const { cards = [], additionalCards = [] } = params ?? {};
    const possibleNftIds = [...cards, ...additionalCards].map((card) => card.token_id);

    if (possibleNftIds.length) {
      // todo: model method
      dispatch(nftActions.setNftHidden({ nftIds: possibleNftIds, isHidden: true }));
    }

    if (typeof event === 'object') {
      dispatch(eventActions.set({ event, ...params } as Partial<Omit<EventState, 'event'>>));
      setEventIdParam(event.id);
    } else {
      dispatch(eventActions.set({ ...params } as Partial<Omit<EventState, 'event'>>));
      setEventIdParam(event);
    }
  };

  const openActiveEvent = (params?: Partial<Omit<EventState, 'event'>>) => {
    if (event) {
      dispatch(eventActions.set({ ...params }));
      setEventIdParam(event.id);
    }
  };

  const openCallEvent = (
    { callId, firstParticipant, secondParticipant, rarity, ...event }: ApiGetCallsMappedData,
    params?: Partial<Pick<EventState, 'choice' | 'cards' | 'isViewMode'>>
  ) => {
    const participants = [firstParticipant, secondParticipant];
    const isViewerCall = participants.some((p) => p?.address === viewer.wallet);
    const isPassedEvent = isEventPassed(event.result);

    const possibleViewerAcceptor =
      isViewerCall || isPassedEvent
        ? null
        : { nickname: viewer.nickname, avatar: viewer.avatar?.src ?? '' };

    openEvent(event, {
      callAcceptableRarity: rarity,
      result: isPassedEvent ? event.result : null,
      cards: params?.cards ?? [],
      choice: params?.choice,
      callId,

      isCall: true,
      isViewerCall,
      isViewMode:
        params?.isViewMode || isViewerCall || participants.every((p) => p?.card) || isPassedEvent,
      isPassedEvent: isPassedEvent,

      callCreator: firstParticipant?.card
        ? {
            nickname: firstParticipant?.nickname,
            avatar: firstParticipant?.avatar,
            card: firstParticipant?.card,
            choiceId: firstParticipant?.choiceId,
          }
        : null,
      callAcceptor: secondParticipant?.card
        ? {
            nickname: secondParticipant?.nickname,
            avatar: secondParticipant?.avatar,
            card: secondParticipant?.card,
            choiceId: secondParticipant?.choiceId,
          }
        : possibleViewerAcceptor,
    });
  };

  const openBetEvent = (
    bet: ApiGetBetsBetMappedData,
    params?: Partial<Omit<EventState, 'event' | 'cards'> & { cards: Array<Nft | PartialNft> }>
  ) => {
    const isPassedEvent = isEventPassed(bet.result);
    const betResult = isPassedEvent ? bet.result : null;

    const relatedBetEvents = nfts
      .map((nft) => nft.relatedEvent)
      .filter((relatedEvent) => relatedEvent?.isCall === false) as NftRelatedBet[];

    const relatedEvent: NftRelatedBet | undefined = isPassedEvent
      ? { ...bet, cards: [], choice: bet.choiceId, id: bet.eventId, isCall: false }
      : relatedBetEvents.find((relatedEvent) => relatedEvent.id === bet.eventId);

    if (relatedEvent) {
      const cards = (
        isPassedEvent ? bet.tokenIds.map((id) => ({ token_id: id })) : relatedEvent.cards
      ) as Array<Nft>;

      openEvent(relatedEvent, {
        cards,
        isPassedEvent,
        isViewMode: true,
        choice: relatedEvent.choice,
        result: betResult,
        ...params,
      });
    }
  };

  const closeEventDialog = () => {
    omitEventIdParam();
    dispatch(nftActions.resetEventDialog());
  };

  const onConfirmCloseEvent = () => {
    closeEventDialog();
    closingConfirmationDialog.switchOff();
    dispatch(nftActions.resetEventDialog());
  };

  const { takeAllCardsFromEvent } = useEventTransactions(closeEventDialog);

  const { openConnectWalletWarnDialog } = useConnect();

  const closingConfirmationDialog = useCreateSwitcher(closingConfirmationDialogOpen, (value) => {
    dispatch(eventActions.setClosingConfirmationDialogOpen(value));
  });

  const bannerDetailedDialogInfo = useCreateSwitcher(bannerDetailedInfoDialogOpen, (value) => {
    dispatch(eventActions.setBannerDetailedInfoDialogOpen(value));
  });

  const potentialRewardAmount = useMemo(
    () => cards.reduce((acc, card) => card.rewardForCorrectVote + acc, 0),
    [cards]
  );

  const eventStatisticsEntries = useMemo(() => {
    return event ? Object.entries(getEventStatisticRowsData(event)) : [];
  }, [event]);

  const onAddCards = (result: BattleResult, isMobile: boolean) => () => {
    if (isMobile) {
      setSelectionMode(true);
      dispatch(eventActions.set({ choice: result }));
      navigate(routes.cards);
    }
  };

  const onTabChange = (_: SyntheticEvent | null, tab: any) => {
    dispatch(eventActions.setTab(tab));
  };

  const onToggleMakeCall = () => {
    dispatch(eventActions.set({ isMakeCall: !isMakeCall }));
  };

  const onOpenDetailedBannerInfo = () => {
    if (!isMakeCall) {
      bannerDetailedDialogInfo.switchOn();
    }
  };

  const onClose = () => {
    const isPlacedCardsToNewBet = !isViewMode && cards.length > 0;
    const isAddedCardsToExistingBet = isViewMode && additionalCards.length > 0;

    const shouldOpenCloseEventConfirmationDialog =
      isPlacedCardsToNewBet || isAddedCardsToExistingBet;

    if (shouldOpenCloseEventConfirmationDialog) {
      closingConfirmationDialog.switchOn();

      return;
    }

    closeEventDialog();
  };

  const { provider } = useWeb3React();

  const signMakeBetMessage = async (
    eventId: string,
    tokenIds: Array<string>,
    choice: BattleResult
  ) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return;
    }

    const address = await signer.getAddress();

    const arenaContract = Arena__factory.connect(process.env.REACT_APP_ADDRESS_SK_ARENA, signer);

    const gaslessFreeCounter = await arenaContract.gasFreeOpCounter(address);

    const types: Array<string> = ['uint256'];
    const values: Array<string | BattleResult | BigNumber> = [gaslessFreeCounter];

    tokenIds.forEach((tokenId) => {
      types.push('uint256', 'uint256', 'uint8');
      values.push(eventId, tokenId, choice);
    });

    const signedMessage = await signGaslessTxMessage({ signer, types, values });

    return signedMessage;
  };

  const gaslessMakeBetCall = useCallGasless<ApiMakeBetRequestData>({
    callback: postMakeBet,
    transactionName: t('Alerts.votingEvent'),
    successMessage: `${t('Alerts.successfulVote')}`,
    errorMessage: `${t('Errors.votingFailed')}`,
  });

  const gaslessCreateCall = useCallGasless<ApiPostCreateCallRequestData>({
    callback: postCreateCall,
    transactionName: 'Call for event',
    successMessage: `${t('Alerts.successfulCall')}`,
    errorMessage: `${t('Errors.callFailed')}`,
  });

  const gaslessAcceptCall = useCallGasless<ApiPostAcceptCallRequestData>({
    callback: postAcceptCall,
    transactionName: 'Accept call',
    successMessage: `${t('Alerts.successfulAceptedCall')}`,
    errorMessage: `${t('Errors.failedAcccept')}`,
  });

  const handleSingleBet = async () => {
    if (!event) {
      return;
    }

    const targetCards = isViewMode ? additionalCards : cards;
    const card = targetCards[0];
    const tokenId = card.token_id;
    const eventId = parseEventIdToBlockchainFormat(event.id);

    if (choice) {
      try {
        const signedMessage = await signMakeBetMessage(eventId, [tokenId], choice);

        if (!signedMessage) {
          throw new Error('Make bet message sign is failed');
        }

        const { r, v, s } = signedMessage;

        await gaslessMakeBetCall({
          eventIds: [eventId],
          cardId: [Number(tokenId)],
          choiceId: [choice],
          caller: account!,
          r,
          v,
          s,
        });

        dispatch(eventActions.set({ isViewMode: true }));

        // todo: make all changes to another state trough model (useNftModel)
        dispatch(
          nftActions.makeBet({
            // todo: remove ts-ignore
            // @ts-ignore
            event: {
              ...event,
              choice: choice!,
              cards: isViewMode ? [...cards, ...additionalCards] : cards,
            },
            cardIds: [...cards.map((card) => card.token_id), card.token_id],
          })
        );

        closeEventDialog();
      } catch (e) {
        console.error('Make bet single: ', e);
      }
    }
  };

  const handleMultipleBet = async () => {
    if (!event || !choice) {
      return;
    }

    const targetCards = isViewMode ? additionalCards : cards;
    const eventId = parseEventIdToBlockchainFormat(event.id);
    const cardIds = targetCards.map(({ token_id }) => Number(token_id));

    const tokenIds = targetCards.map(({ token_id }) => token_id);

    try {
      const signedMessage = await signMakeBetMessage(eventId, tokenIds, choice!);

      if (!signedMessage) {
        throw new Error('Make bet message sign is failed');
      }

      const { r, v, s } = signedMessage;

      await gaslessMakeBetCall({
        eventIds: tokenIds.map(() => eventId),
        cardId: tokenIds.map((tokenId) => Number(tokenId)),
        choiceId: tokenIds.map(() => choice),
        caller: account!,
        r,
        v,
        s,
      });

      // todo: model
      dispatch(eventActions.set({ isViewMode: true }));

      // todo: make all changes to another state trough model (useNftModel)
      dispatch(
        nftActions.makeBet({
          event: {
            ...(event as EventStateEvent),
            isCall: false,
            result: BattleResult.InProgress,
            choice: choice!,
            cards: isViewMode ? [...cards, ...additionalCards] : cards,
          },
          cardIds: [...cards.map((card) => card.token_id), ...cardIds.map((id) => String(id))],
        })
      );

      closeEventDialog();
    } catch {}
  };

  /**
   * @param id - id of Event or Call. If we are creating call - it is going to be id of Event, otherwise it would be id of existing Call
   * @param cardId - id of NFT
   * @param choice - Enum value of BattleResult
   * @param address - Wallet address of viewer
   */
  const signActionCallMessage = async <Id extends string | number>(
    id: Id,
    cardId: string,
    choice: BattleResult,
    address: string
  ) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return;
    }

    const arenaContract = Arena__factory.connect(process.env.REACT_APP_ADDRESS_SK_ARENA, signer);

    const gaslessFreeCounter = await arenaContract.gasFreeOpCounter(address);

    const types: Array<'uint256' | 'uint8'> = ['uint256', 'uint256', 'uint256', 'uint8'];
    const values: Array<BigNumber | BattleResult> = [
      gaslessFreeCounter,
      BigNumber.from(id),
      BigNumber.from(cardId),
      choice,
    ];

    const signedMessage = await signGaslessTxMessage({ signer, types, values });

    return signedMessage;
  };

  const handleCreateCall = async () => {
    if (!event || !choice) {
      return;
    }

    try {
      const eventId = parseEventIdToBlockchainFormat(event.id);

      const card = cards[0];

      const signedMessage = await signActionCallMessage(eventId, card.token_id, choice, account!);

      if (!signedMessage) {
        return;
      }

      const { r, s, v } = signedMessage;

      // const tx = await Arena__factory.connect(
      //   process.env.REACT_APP_ADDRESS_SK_ARENA,
      //   provider?.getSigner()!
      // ).createCallGasFree(eventId, card.token_id, choice, account!, v, r, s);

      await gaslessCreateCall({
        eventIds: eventId,
        cardId: Number(card.token_id),
        choiceId: choice,
        caller: account!,
        r,
        s,
        v,
      });

      // todo: model
      dispatch(eventActions.set({ isViewMode: true }));

      // todo: make all changes to another state through model (useNftModel)
      dispatch(
        nftActions.makeBet({
          // @ts-ignore
          event: {
            ...event,
            cards: cards,
            isCall: true,
            firstParticipant: {
              address: viewer.wallet,
              avatar: viewer.avatar?.src!,
              nickname: viewer.nickname,
              card: card.token_id,
              choiceId: choice!,
            },
            secondParticipant: null,
          },
          cardIds: [card.token_id],
        })
      );

      closeEventDialog();
    } catch {}
  };

  const handleAcceptCall = async () => {
    if (!event || !callId || !choice) {
      return;
    }

    try {
      const card = cards[0];

      const signedMessage = await signActionCallMessage(callId, card.token_id, choice, account!);

      if (!signedMessage) {
        return;
      }

      const { r, s, v } = signedMessage;

      // const tx = await Arena__factory.connect(
      //   process.env.REACT_APP_ADDRESS_SK_ARENA,
      //   provider?.getSigner()!
      // ).acceptCallGasFree(callId, card.token_id, choice, account!, v, r, s);
      //
      // await tx.wait();

      await gaslessAcceptCall({
        callId: String(callId),
        cardId: Number(card.token_id),
        choiceId: choice,
        caller: account!,
        r,
        s,
        v,
      });

      // todo: model
      dispatch(eventActions.set({ isViewMode: true }));

      // todo: make all changes to another state trough model (useNftModel)
      dispatch(
        nftActions.makeBet({
          // @ts-ignore
          event: {
            ...event,
            cards: cards,
            isCall: true,
            firstParticipant: callCreator as any,
            secondParticipant: {
              address: viewer.wallet,
              avatar: viewer.avatar?.src!,
              nickname: viewer.nickname,
              card: card.token_id,
              choiceId: choice!,
            },
          },
          cardIds: [card.token_id],
        })
      );

      closeEventDialog();
    } catch {}
  };

  // todo: maybe move to dedicated model
  const onVote = () => {
    if (!isConnected) {
      openConnectWalletWarnDialog();

      return;
    }

    const isAcceptingCall = isCall;
    const isMultipleBetting = cards.length > 1 || additionalCards.length > 1;

    switch (true) {
      case isAcceptingCall:
        return handleAcceptCall();
      case isMakeCall:
        return handleCreateCall();
      case isMultipleBetting:
        return handleMultipleBet();
      default:
        return handleSingleBet();
    }
  };

  const onRemoveAllCardsFromEvent = async () => {
    if (!event) {
      return;
    }

    const cardIds = cards.map(({ token_id }) => token_id);
    const parsedEventId = parseEventIdToBlockchainFormat(event.id);

    try {
      await takeAllCardsFromEvent({ args: [parsedEventId, cardIds] });
      // todo: nft model
      dispatch(nftActions.removeAllCardsFromEvent(event.id));
      setTimeout(profile.invalidateProfileBattlesQuery, 10000); // refetch bets in case user on the profile battles page
      closeEventDialog();
    } catch {}
  };

  const onClearAll = () => {
    const cardsKey = isViewMode && additionalCards.length > 0 ? 'additionalCards' : 'cards';
    const cardsToClear = additionalCards.length > 0 ? additionalCards : cards;

    dispatch(eventActions.clearEventCards({ cardsKey }));
    // todo: nft model
    // @ts-ignore
    dispatch(nftActions.clearEventCards({ cards: cardsToClear }));
  };

  return {
    closingConfirmationDialog,
    bannerDetailedDialogInfo,

    tab,

    event,
    potentialRewardAmount,
    choice,
    // todo: use result from `event`
    result,
    cards,
    additionalCards,
    eventStatisticsEntries,
    nftWithWinstreakDialog,
    callAcceptor,
    callCreator,
    callAcceptableRarity,

    isConnected,
    isLoading,
    isViewMode,
    isPassedEvent,
    isMakeCall,
    isCall,
    isViewerCall,
    isViewerBattle,
    isOpen: Boolean(eventIdParam),
    isStatisticsTabDisabled: eventStatisticsEntries.length === 0,
    isNoCardsSelected: cards.length === 0,
    isCallAvailable: cards.length < 2,
    isCallsBannerVisible:
      event && !isMakeCall && !isCall && !isViewMode && tab === EventDialogTab.Cards,

    openEvent,
    openActiveEvent,
    openCallEvent,
    openBetEvent,
    onClose,
    onConfirmCloseEvent,
    onTabChange,
    onVote,
    onClearAll,
    onAddCards,
    onRemoveAllCardsFromEvent,
    onToggleMakeCall,
    onOpenDetailedBannerInfo,
  };
};
