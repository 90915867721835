import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';

import { Divider, Stack } from '~shared/ui';

import {
  DashboardCallsTableCell,
  DashboardCallsTableHeading,
  DashboardCallsTableRoot,
} from './styled';

import { DashboardCallsTableProps } from './types';

export const DashboardCallsTable: FC<DashboardCallsTableProps> = ({
  total,
  wins,
  loses,
  future,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('sm');

  const divider = !isMobile && <Divider orientation="vertical" />;

  return (
    <DashboardCallsTableRoot>
      <Stack>
        <DashboardCallsTableHeading>{t('Other.total')}</DashboardCallsTableHeading>
        <DashboardCallsTableCell>{total}</DashboardCallsTableCell>
      </Stack>

      {divider}

      <Stack>
        <DashboardCallsTableHeading>{t('Other.future')}</DashboardCallsTableHeading>
        <DashboardCallsTableCell>{future}</DashboardCallsTableCell>
      </Stack>

      {divider}

      <Stack>
        <DashboardCallsTableHeading>{t('Other.wins')}</DashboardCallsTableHeading>
        <DashboardCallsTableCell>{wins}</DashboardCallsTableCell>
      </Stack>

      {divider}

      <Stack>
        <DashboardCallsTableHeading>{t('Other.loses')}</DashboardCallsTableHeading>
        <DashboardCallsTableCell>{loses}</DashboardCallsTableCell>
      </Stack>
    </DashboardCallsTableRoot>
  );
};
