import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { StaticNftCard } from '~entities/nft';

import { NotificationCardAddedProps } from './types';
import {
  Notification,
  NotificationIconFactory,
  NotificationMessage,
  NotificationTag,
} from '../../../factory';

export const NotificationCardAdded = forwardRef<HTMLDivElement, NotificationCardAddedProps>(
  ({ notification, place }, ref) => {
    const { t } = useTranslation();

    return (
      <Notification
        ref={ref}
        place={place}
        date={notification.date}
        read={notification.read}
        icon={
          <NotificationIconFactory type="card-new">
            <StaticNftCard tokenId={notification.payload.tokenId} />
          </NotificationIconFactory>
        }
      >
        <NotificationTag color="primary">{t('Notifications.newCard')}</NotificationTag>
        <NotificationMessage>{t('Notifications.youHaveNewCard')}</NotificationMessage>
      </Notification>
    );
  }
);
