import { useQuery } from 'react-query';

import { MarketplaceItem } from '../../lib';
import { getMarketplaceItems } from '../helpers';

export const useMarketplaceItemsQuery = () => {
  return useQuery<Array<MarketplaceItem>>('getMarketplaceItems', getMarketplaceItems, {
    staleTime: 5 * 60 * 1000,
  });
};
