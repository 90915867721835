import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { WalletBalance, WalletState } from './types';

const initialState: WalletState = {
  isConnectWalletDialogOpen: false,
  isWarnDialogOpen: false,
  isWalletConnectedDialogOpen: false,
  isOnboardingShownAfterConnect: false,
  isCorrectWalletConnected: false,
  balance: {
    mcn: 0,
    native: 0,
  },
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<Partial<WalletState>>) => ({
      ...state,
      ...action.payload,
    }),
    setBalance: (state, action: PayloadAction<WalletBalance>) => {
      state.balance = action.payload;
    },
    setWarnDialog: (state, action: PayloadAction<boolean>) => {
      state.isWarnDialogOpen = action.payload;
    },
    setWalletConnectedDialog: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnectedDialogOpen = action.payload;
    },

    resetWallet: () => ({ ...initialState }),
  },
});

export const walletReducer = walletSlice.reducer;
export const walletActions = walletSlice.actions;
