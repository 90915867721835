import { useTranslation } from 'react-i18next';

import { Typography } from '~shared/ui';

import { LeaderboardHeadTable } from './styled';

export const LeaderboardTableHead = () => {
  const { t } = useTranslation();

  return (
    <LeaderboardHeadTable>
      <Typography color="secondary">{t('Rankings.nickname')}</Typography>
      <Typography color="secondary" align="center">
        {t('Rankings.accuracy')}
      </Typography>
      <Typography color="secondary" align="center">
        {t('Rankings.votes')}
      </Typography>
      <Typography color="secondary" align="center">
        MCN
      </Typography>
    </LeaderboardHeadTable>
  );
};
