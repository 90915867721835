import { useForm } from 'react-hook-form';

import { useSnackbar } from '~shared/lib/hooks';
import { putNickname } from '~shared/api';
import { NicknameField, NicknameFieldCheckingNicknameFieldName } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

interface PersonalDataForm extends Record<NicknameFieldCheckingNicknameFieldName, boolean> {
  email: string;
  nickname: string;
}

export const usePersonalDataForm = () => {
  const { openSnackbar } = useSnackbar();
  const { nickname, email, fetchProfile } = useViewerModel();

  const form = useForm<PersonalDataForm>({
    defaultValues: { email, nickname },
    mode: 'onChange',
  });

  const handleSubmit = form.handleSubmit((data) => {
    if (data.nickname !== nickname) {
      putNickname({ nickname: data.nickname })
        .then(fetchProfile)
        .then(() => {
          openSnackbar({ message: 'Data saved successfully' });
        });
    }
  });

  const isSubmitAvailable =
    nickname !== form.watch('nickname') && !form.watch(NicknameField.checkingNicknameFieldName);

  return {
    currentNickname: nickname,
    isSubmitAvailable,
    handleSubmit,
    form,
  };
};
