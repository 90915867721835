import { noScrollbar, styled } from '~shared/lib/styles';
import { Drawer } from '~shared/ui';

export const StyledDrawer = styled(Drawer, {
  name: 'ConnectWalletDialogMobile',
  slot: 'styledDrawer',
})(({ theme }) => ({
  ['& .Drawer-paper']: {
    maxHeight: '70vh',
    overflowY: 'scroll',
    background: theme.palette.paper.semi,

    ...noScrollbar,
  },
}));
