import { styled } from '~shared/lib/styles';

export const CircularDiagramRoot = styled('div')(() => ({
  position: 'relative' as const,
  width: 84,
  height: 84,
}));

export const CircularDiagramContent = styled('div')({
  position: 'absolute',
  inset: '24px 0 19px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const CircularDiagramContentLabel = styled('div')({
  fontSize: 9,
  fontWeight: 400,
  lineHeight: 11 / 9,
  marginTop: 2,
});

export const CircularDiagramContentValue = styled('div')({
  fontSize: 22,
  fontWeight: 600,
  lineHeight: 30 / 22,
  letterSpacing: -0.2,
});

export const CircularDiagramProgressSVG = styled('svg')({
  width: 84,
  height: 84,
});

const strokeWidth = 8;

const circle = {
  cx: '50%',
  cy: '50%',
  r: (84 - strokeWidth) / 2,
};

interface AccuracyProgressCommonProps {
  hasProgress?: boolean;
}

export const CircularDiagramProgressTrack = styled('circle')<AccuracyProgressCommonProps>(
  ({ theme, hasProgress }) => ({
    ...circle,
    fill: 'none',

    strokeWidth,
    stroke: hasProgress ? theme.palette.error.main : theme.palette.grey.A20,
  })
);

interface AccuracyProgressProps extends AccuracyProgressCommonProps {
  progress?: number;
}

export const CircularDiagramProgress = styled('circle')<AccuracyProgressProps>(({
  progress,
  hasProgress,
  theme,
}) => {
  return {
    ...circle,
    fill: 'none',

    strokeWidth,
    stroke: hasProgress ? theme.palette.primary.main : 'none',
    strokeDasharray: `calc(${circle.r}px * 2 * 3.14)`,
    strokeDashoffset: `calc((${circle.r}px * 2 * 3.14) - (${progress} * (${circle.r}px * 2 * 3.14)))`,

    transform: 'rotate(-90deg)',
    transformOrigin: 'center center',
  };
});
