import { styled } from '~shared/lib/styles';

export const DashboardBattlesTableRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1px 1fr 1px 1fr 1px 1fr',
  height: 44,
  gap: 12,

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: '8px',
    height: 'initial',
  },
}));

export const DashboardBattlesTableHeading = styled('div')(({ theme }) => ({
  color: theme.palette.grey.A50,
  fontSize: 13,
  lineHeight: 14 / 13,
  marginBottom: 4,
}));

export const DashboardBattlesTableCell = styled('div')(() => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: 24 / 18,
  letterSpacing: -0.2,
}));
