import { Tab as MuiBaseTab, tabClasses as muiTabClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

import { Box } from '../../../layout';

export const tabClasses = {
  ...muiTabClasses,
  indicator: 'Tab-indicator',
  hidden: 'Tab-hidden',
};

export const UIKitTab = styled(MuiBaseTab)(({ theme }) => ({
  color: theme.palette.text.primary,
  background: 'none',
  border: 'none',
  position: 'relative',
  overflow: 'hidden',
  flexShrink: 0,

  [`&.${tabClasses.root}`]: {
    padding: '15px 10px',

    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,

    transition: [
      theme.transitions.create(['background', 'color']),
      theme.transitions.create('font-weight', {
        duration: theme.transitions.duration.shortest,
      }),
    ].join(', '),

    [`&:hover:not(.${tabClasses.disabled})`]: {
      background: theme.palette.primary.dark,
      color: theme.palette.text.primary,
    },
  },

  [`&.${tabClasses.selected}`]: {
    color: theme.palette.text.primary,
    fontWeight: 600,

    [`& .${tabClasses.indicator}`]: {
      top: '100%',
    },
  },

  [`&.${tabClasses.disabled}`]: {
    color: theme.palette.grey.A30,
    cursor: 'not-allowed',
  },
}));

// font-weight change affects container width, so render
// hidden `children` with large font-weight
export const UIKitTabHidden = styled('div')({
  opacity: 0,
  height: 0,
  fontWeight: 600,
});

export const UIKitTabIndicator = styled(Box, {
  name: 'Tab-indicator',
})(({ theme }) => ({
  position: 'absolute',
  top: '145%',
  left: '-20%',
  right: '-20%',
  bottom: '-55%',

  filter: 'blur(7px)',
  background: theme.palette.primary.main,
  transition: theme.transitions.create('top'),
}));
