import {
  UNSAFE_NavigationContext as NavigationContext,
  To,
  useLocation,
  useResolvedPath,
} from 'react-router';
import { useContext } from 'react';

interface UseMatchRoute {
  (to: To, props?: { end?: boolean; caseSensitive?: boolean }): boolean;
}

export const useMatchRoute: UseMatchRoute = (to, { end = false, caseSensitive = false } = {}) => {
  const path = useResolvedPath(to);
  const location = useLocation();

  const { navigator } = useContext(NavigationContext);

  const encodeLocation = (navigator as any)?.encodeLocation;

  let toPathname = encodeLocation ? encodeLocation(path).pathname : path.pathname;
  let locationPathname = location.pathname;

  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();

    toPathname = toPathname.toLowerCase();
  }

  return (
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/')
  );
};
