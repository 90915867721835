import { useDispatch } from '~shared/lib/hooks';

import { Nft } from '~shared/api';

import { useEventModel } from '~entities/event';
import { isNftAvailableForMerge } from '~entities/nft';

import {
  checkWinstreakWarnDialogNotShown,
  useWinstreakWarningDialog,
} from '~features/event-dialog';

import { useUserCardsSelector } from '../selectors';
import { userCardsSlice } from '../slice';

export const useSelectCard = () => {
  const dispatch = useDispatch();

  const { selectedCards } = useUserCardsSelector();

  const { openWinstreakWarningDialog } = useWinstreakWarningDialog();
  const { choice } = useEventModel();

  const selectCard = async (selectedCard: Nft) => {
    const { isOnAuction, isFreezed, isBlockedForEvent, isOnEvent } = selectedCard;
    const isCardNotAvailableForSelection =
      isOnAuction || isFreezed || isBlockedForEvent || isOnEvent;

    if (isCardNotAvailableForSelection) {
      return;
    }

    const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

    if (
      isWinstreakDialogNotShown &&
      isNftAvailableForMerge(selectedCard).isAvailable &&
      !selectedCards.has(selectedCard.token_id) &&
      choice
    ) {
      openWinstreakWarningDialog(selectedCard, choice, () => {
        dispatch(userCardsSlice.actions.toggleSelectedCard(selectedCard));
      });

      return;
    }

    dispatch(userCardsSlice.actions.toggleSelectedCard(selectedCard));
  };

  return {
    selectCard,
  };
};
