import { styled } from '~shared/lib/styles';
import { Box, Stack } from '~shared/ui';

export const ContentContainer = styled(Stack, {
  name: 'NftCardDetailedInfoEvents',
  slot: 'contentContainer',
})(({ theme }) => ({
  margin: '30px -20px -30px -20px',
  background: theme.palette.paper.main,
  padding: '16px 20px 30px 20px',

  [theme.breakpoints.down('mobile')]: {
    background: theme.palette.paper.semi,
    margin: '30px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: 0,
    marginTop: '224px',
  },
}));

export const TabContentContainer = styled(Box, {
  name: 'NftCardDetailedInfoContent',
  slot: 'tabContentContainer',
})({
  position: 'relative',
  minHeight: '327px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});
