import { FC } from 'react';
import { clsx } from 'clsx';

import { Link } from '~shared/ui';

import { UIKitSlideImg, UIKitSlideRoot, slideClasses } from './styled';
import { SlideProps } from './types';

export const Slide: FC<SlideProps> = ({ src, className, to, linkProps = {}, ...props }) => {
  const slideImg = <UIKitSlideImg className={slideClasses.img} src={src} />;

  return (
    <UIKitSlideRoot
      className={clsx(slideClasses.root, { [slideClasses.interactive]: props.onClick }, className)}
      {...props}
    >
      {to ? (
        <Link to={to} {...linkProps}>
          {slideImg}
        </Link>
      ) : (
        slideImg
      )}
    </UIKitSlideRoot>
  );
};

/**
 * @see https://github.com/nolimits4web/swiper/issues/4413#issuecomment-1021387492
 */
Slide.displayName = 'SwiperSlide';
