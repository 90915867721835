import { HTMLAttributes } from 'react';

import * as S from './styled';
import { ChipColor } from './types';

type Props = {
  color?: ChipColor;
  label: string;
} & HTMLAttributes<HTMLDivElement>;

export const Chip = ({ color = 'primary', label, ...otherProps }: Props) => {
  return (
    <S.ChipRoot color={color} {...otherProps}>
      <S.ChipLabel>{label}</S.ChipLabel>
    </S.ChipRoot>
  );
};
