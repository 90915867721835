/**
 * Shared sport field from `bets`, `calls`, `battle`
 */
export type ApiCommonSport = `${ApiCommonSportEnum}`;

// soccer is actually football (not american soccer)
export enum ApiCommonSportEnum {
  AmericanFootball = 'american_football',
  Baseball = 'baseball',
  Basketball = 'basketball',
  Cricket = 'cricket',
  Football = 'soccer',
  MMA = 'mma',
  Tennis = 'tennis',
  Hockey = 'hockey',

  CSGO = 'csgo',
  Dota = 'dota2',
  LeagueOfLegends = 'lol',
}

export enum ApiCommonLeagueEnum {
  // Football
  UEFAChampions = 'UEFA Champions League',
  UEFAEurope = 'UEFA Europa Conference League',
  EnglishLeague = 'English Premier League',
  FrenchLeague = 'Ligue 1',
  SpainLeague = 'LaLiga',
  GermanLeague = 'Bundesliga',
  ItalyLeague = 'Serie A',

  // Tennis
  Legends = 'Legends',
  WTA125K = 'WTA 125K',
  WTA = 'WTA',
  ATP = 'ATP',

  // CS GO
  CSGO_1xP = '1xP',
  CSGO_BLAST = 'BLAST',
  CSGO_CBCS = 'CBCS',
  CSGO_CCTCEU = 'CCT CEU',
  CSGO_CCTEE = 'CCT EE',
  CSGO_CCTNE = 'CCT NE',
  CSGO_CCTSA = 'CCT SA',
  CSGO_EME = 'EME',
  CSGO_EPLDiv1 = 'EPL Div 1',
  CSGO_ESEAPSCNA = 'ESEA PSC NA',
  CSGO_ESL = 'ESL',
  CSGO_ESLAPAC = 'ESL APAC',
  CSGO_ESLDA = 'ESL DA',
  CSGO_ESLDW = 'ESL DW',
  CSGO_ESLEU = 'ESL EU',
  CSGO_ESLNA = 'ESL NA',
  CSGO_ESLPRO = 'ESL PRO',
  CSGO_ESM = 'ESM',
  CSGO_FRLG = 'FRLG',
  CSGO_GCLS = 'GCLS',
  CSGO_GM8 = 'GM8',
  CSGO_IEM = 'IEM',
  CSGO_IEMSYD = 'IEM SYD',
  CSGO_IESF = 'IESF',
  CSGO_KaBuM = 'KaBuM',
  CSGO_LLP = 'LLP',
  CSGO_PCV = 'PC V',
  CSGO_PGL = 'PGL',
  CSGO_PWAP = 'PWAP',
  CSGO_RBC = 'RBC',
  CSGO_RCL = 'RCL',
  CSGO_SKYM = 'SKY M',
  CSGO_TPEU = 'TP EU',
  CSGO_TPNA = 'TP NA',
  CSGO_TPWC = 'TPWC',

  // DOTA
  DOTA2_ASIAN = 'ASIAN',
  DOTA2_MSMS = 'MSMS',
  DOTA2_PGS = 'PGS',
  DOTA2_RMS = 'RMS',
  DOTA2_TI = 'TI',

  // League of Legends
  LOL_CBLOL = 'CBLOL',
  LOL_CBLOLA = 'CBLOLA',
  LOL_EBL = 'EBL',
  LOL_EMEA = 'EMEA',
  LOL_ESS = 'ESS',
  LOL_GLL = 'GLL',
  LOL_HPM = 'HPM',
  LOL_LCK = 'LCK',
  LOL_LCKCL = 'LCK CL',
  LOL_LCO = 'LCO',
  LOL_LCS = 'LCS',
  LOL_LDL = 'LDL',
  LOL_LECEMEA = 'LEC.EMEA',
  LOL_LFL = 'LFL',
  LOL_LJL = 'LJL',
  LOL_LLA = 'LLA',
  LOL_LPL = 'LPL',
  LOL_LPLOL = 'LPLOL',
  LOL_LVPSL = 'LVP SL',
  LOL_NACL = 'NACL',
  LOL_NLC = 'NLC',
  LOL_PCS = 'PCS',
  LOL_PGN = 'PGN',
  LOL_PRM = 'PRM',
  LOL_TAL = 'TAL',
  LOL_TCL = 'TCL',
  LOL_UL = 'UL',
  LOL_VCS = 'VCS',
  LOL_Worlds = 'Worlds',
}

export type ApiCommonLeague = `${ApiCommonLeagueEnum}`;
