import { FC, forwardRef } from 'react';

import { clsx } from 'clsx';

import { SliderProps } from './types';
import { UIKitSlider, sliderClasses } from './styled';

export const Slider: FC<SliderProps> = forwardRef<HTMLSpanElement, SliderProps>(
  ({ className, flexItem, ...props }, ref) => {
    return (
      <UIKitSlider
        className={clsx({ [sliderClasses.flexItem]: flexItem }, className)}
        {...props}
        ref={ref}
      />
    );
  }
);
