import { forwardRef } from 'react';

import { DividerProps } from './types';
import { UIKitDividerRoot } from './styled';

export const Divider = forwardRef<HTMLDivElement, DividerProps>(
  ({ orientation = 'horizontal', ...props }, ref) => {
    return (
      <UIKitDividerRoot
        component="hr"
        {...props}
        orientation={orientation}
        aria-orientation={orientation}
        ref={ref}
      />
    );
  }
);
