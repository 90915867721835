import { useEffect } from 'react';

import { useNotificationsModel } from '~entities/notifications';
import { useViewerModel } from '~entities/viewer';

export const useNotificationsInitialization = () => {
  const { sessionTokenChecked, id } = useViewerModel();
  const { init } = useNotificationsModel();

  useEffect(() => {
    if (sessionTokenChecked && id) {
      init();
    }
  }, [id, init, sessionTokenChecked]);
};
