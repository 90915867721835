import { ApiCommonLeagueEnum, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from '../types';

export const CSGOLeaguesConfig: { [key in ApiCommonLeagueEnum]?: SportItemConfig } = {
  [ApiCommonLeagueEnum.CSGO_ESLDA]: {
    id: 'CSGO_ESLDA',
    label: 'ESL DA',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESLDA,
    dates: [
      { startDate: new Date('2023-12-15T00:00:00Z'), endDate: new Date('2023-12-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESLDA,
    tournament: ApiCommonLeagueEnum.CSGO_ESLDA,
  },

  [ApiCommonLeagueEnum.CSGO_IEM]: {
    id: 'CSGO_IEM',
    label: 'IEM',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_IEM,
    dates: [
      { startDate: new Date('2023-07-25T00:00:00Z'), endDate: new Date('2023-08-08T23:59:00Z') },
      { startDate: new Date('2023-10-16T00:00:00Z'), endDate: new Date('2023-10-22T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_IEM,
    tournament: ApiCommonLeagueEnum.CSGO_IEM,
  },

  [ApiCommonLeagueEnum.CSGO_ESLPRO]: {
    id: 'CSGO_ESLPRO',
    label: 'ESL PRO',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESLPRO,
    dates: [
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-09-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESLPRO,
    tournament: ApiCommonLeagueEnum.CSGO_ESLPRO,
  },

  [ApiCommonLeagueEnum.CSGO_ESLDW]: {
    id: 'CSGO_ESLDW',
    label: 'ESL DW',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESLDW,
    dates: [
      { startDate: new Date('2023-11-24T00:00:00Z'), endDate: new Date('2023-11-28T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESLDW,
    tournament: ApiCommonLeagueEnum.CSGO_ESLDW,
  },

  [ApiCommonLeagueEnum.CSGO_FRLG]: {
    id: 'CSGO_FRLG',
    label: 'FRLG',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_FRLG,
    dates: [
      { startDate: new Date('2023-10-11T00:00:00Z'), endDate: new Date('2023-10-15T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_FRLG,
    tournament: ApiCommonLeagueEnum.CSGO_FRLG,
  },

  [ApiCommonLeagueEnum.CSGO_BLAST]: {
    id: 'CSGO_BLAST',
    label: 'BLAST',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_BLAST,
    dates: [
      { startDate: new Date('2023-12-12T00:00:00Z'), endDate: new Date('2023-12-17T23:59:00Z') },
      { startDate: new Date('2023-11-22T00:00:00Z'), endDate: new Date('2023-11-26T23:59:00Z') },
      { startDate: new Date('2023-10-04T00:00:00Z'), endDate: new Date('2023-10-08T23:59:00Z') },
      { startDate: new Date('2023-10-04T00:00:00Z'), endDate: new Date('2023-11-08T23:59:00Z') },
      { startDate: new Date('2023-07-13T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_BLAST,
    tournament: ApiCommonLeagueEnum.CSGO_BLAST,
  },

  [ApiCommonLeagueEnum.CSGO_IESF]: {
    id: 'CSGO_IESF',
    label: 'IESF',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_IESF,
    dates: [
      { startDate: new Date('2023-08-25T00:00:00Z'), endDate: new Date('2023-09-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_IESF,
    tournament: ApiCommonLeagueEnum.CSGO_IESF,
  },

  [ApiCommonLeagueEnum.CSGO_GM8]: {
    id: 'CSGO_GM8',
    label: 'GM8',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_GM8,
    dates: [
      { startDate: new Date('2023-05-17T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_GM8,
    tournament: ApiCommonLeagueEnum.CSGO_GM8,
  },

  [ApiCommonLeagueEnum.CSGO_ESL]: {
    id: 'CSGO_ESL',
    label: 'ESL',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESL,
    dates: [
      { startDate: new Date('2023-12-15T00:00:00Z'), endDate: new Date('2023-12-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESL,
    tournament: ApiCommonLeagueEnum.CSGO_ESL,
  },

  [ApiCommonLeagueEnum.CSGO_PGL]: {
    id: 'CSGO_PGL',
    label: 'PGL',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_PGL,
    dates: [
      { startDate: new Date('2024-03-17T00:00:00Z'), endDate: new Date('2024-03-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_PGL,
    tournament: ApiCommonLeagueEnum.CSGO_PGL,
  },

  [ApiCommonLeagueEnum.CSGO_RBC]: {
    id: 'CSGO_RBC',
    label: 'RBC',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_RBC,
    dates: [
      { startDate: new Date('2023-10-26T00:00:00Z'), endDate: new Date('2023-11-08T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_RBC,
    tournament: ApiCommonLeagueEnum.CSGO_RBC,
  },

  [ApiCommonLeagueEnum.CSGO_EME]: {
    id: 'CSGO_EME',
    label: 'EME',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_EME,
    dates: [
      { startDate: new Date('2023-11-29T00:00:00Z'), endDate: new Date('2023-12-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_EME,
    tournament: ApiCommonLeagueEnum.CSGO_EME,
  },

  [ApiCommonLeagueEnum.CSGO_ESLAPAC]: {
    id: 'CSGO_ESLAPAC',
    label: 'ESL APAC',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESLAPAC,
    dates: [
      { startDate: new Date('2023-08-15T00:00:00Z'), endDate: new Date('2023-09-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESLAPAC,
    tournament: ApiCommonLeagueEnum.CSGO_ESLAPAC,
  },

  [ApiCommonLeagueEnum.CSGO_ESLEU]: {
    id: 'CSGO_ESLEU',
    label: 'ESL EU',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESLEU,
    dates: [
      { startDate: new Date('2023-10-03T00:00:00Z'), endDate: new Date('2023-12-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESLEU,
    tournament: ApiCommonLeagueEnum.CSGO_ESLEU,
  },

  [ApiCommonLeagueEnum.CSGO_ESLNA]: {
    id: 'CSGO_ESLNA',
    label: 'ESL NA',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESLNA,
    dates: [
      { startDate: new Date('2023-08-15T00:00:00Z'), endDate: new Date('2023-09-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESLNA,
    tournament: ApiCommonLeagueEnum.CSGO_ESLNA,
  },

  [ApiCommonLeagueEnum.CSGO_ESM]: {
    id: 'CSGO_ESM',
    label: 'ESM',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESM,
    dates: [
      { startDate: new Date('2023-08-03T00:00:00Z'), endDate: new Date('2023-08-05T15:30:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESM,
    tournament: ApiCommonLeagueEnum.CSGO_ESM,
  },

  [ApiCommonLeagueEnum.CSGO_TPWC]: {
    id: 'CSGO_TPWC',
    label: 'TPWC',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_TPWC,
    dates: [
      { startDate: new Date('2023-10-27T00:00:00Z'), endDate: new Date('2023-11-05T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_TPWC,
    tournament: ApiCommonLeagueEnum.CSGO_TPWC,
  },

  [ApiCommonLeagueEnum.CSGO_TPNA]: {
    id: 'CSGO_TPNA',
    label: 'TP NA',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_TPNA,
    dates: [
      { startDate: new Date('2023-08-21T00:00:00Z'), endDate: new Date('2023-08-29T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_TPNA,
    tournament: ApiCommonLeagueEnum.CSGO_TPNA,
  },

  [ApiCommonLeagueEnum.CSGO_TPEU]: {
    id: 'CSGO_TPEU',
    label: 'TP EU',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_TPEU,
    dates: [
      { startDate: new Date('2023-07-27T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
      { startDate: new Date('2023-09-04T00:00:00Z'), endDate: new Date('2023-09-18T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_TPEU,
    tournament: ApiCommonLeagueEnum.CSGO_TPEU,
  },

  [ApiCommonLeagueEnum.CSGO_CBCS]: {
    id: 'CSGO_CBCS',
    label: 'CBCS',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_CBCS,
    dates: [
      { startDate: new Date('2023-08-17T00:00:00Z'), endDate: new Date('2023-08-27T23:59:00Z') },
      { startDate: new Date('2023-09-13T00:00:00Z'), endDate: new Date('2023-09-24T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_CBCS,
    tournament: ApiCommonLeagueEnum.CSGO_CBCS,
  },

  [ApiCommonLeagueEnum.CSGO_1xP]: {
    id: 'CSGO_1xP',
    label: '1xP',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_1xP,
    dates: [
      { startDate: new Date('2023-09-25T00:00:00Z'), endDate: new Date('2023-10-06T23:59:00Z') },
      { startDate: new Date('2023-10-23T00:00:00Z'), endDate: new Date('2023-11-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_1xP,
    tournament: ApiCommonLeagueEnum.CSGO_1xP,
  },

  [ApiCommonLeagueEnum.CSGO_PWAP]: {
    id: 'CSGO_PWAP',
    label: 'PWAP',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_PWAP,
    dates: [
      { startDate: new Date('2023-08-29T00:00:00Z'), endDate: new Date('2023-09-03T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_PWAP,
    tournament: ApiCommonLeagueEnum.CSGO_PWAP,
  },

  [ApiCommonLeagueEnum.CSGO_CCTNE]: {
    id: 'CSGO_CCTNE',
    label: 'CCT NE',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_CCTNE,
    dates: [
      { startDate: new Date('2023-07-12T00:00:00Z'), endDate: new Date('2023-07-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_CCTNE,
    tournament: ApiCommonLeagueEnum.CSGO_CCTNE,
  },

  [ApiCommonLeagueEnum.CSGO_CCTCEU]: {
    id: 'CSGO_CCTCEU',
    label: 'CCT CEU',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_CCTCEU,
    dates: [
      { startDate: new Date('2023-07-17T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_CCTCEU,
    tournament: ApiCommonLeagueEnum.CSGO_CCTCEU,
  },

  [ApiCommonLeagueEnum.CSGO_EPLDiv1]: {
    id: 'CSGO_EPLDiv1',
    label: 'EPL Div 1',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_EPLDiv1,
    dates: [
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-07-28T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_EPLDiv1,
    tournament: ApiCommonLeagueEnum.CSGO_EPLDiv1,
  },

  [ApiCommonLeagueEnum.CSGO_SKYM]: {
    id: 'CSGO_SKYM',
    label: 'SKY M',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_SKYM,
    dates: [
      { startDate: new Date('2023-08-26T00:00:00Z'), endDate: new Date('2023-08-27T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_SKYM,
    tournament: ApiCommonLeagueEnum.CSGO_SKYM,
  },

  [ApiCommonLeagueEnum.CSGO_IEMSYD]: {
    id: 'CSGO_IEMSYD',
    label: 'IEM SYD',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_IEMSYD,
    dates: [
      { startDate: new Date('2023-08-09T00:00:00Z'), endDate: new Date('2023-10-22T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_IEMSYD,
    tournament: ApiCommonLeagueEnum.CSGO_IEMSYD,
  },

  [ApiCommonLeagueEnum.CSGO_GCLS]: {
    id: 'CSGO_GCLS',
    label: 'GCLS',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_GCLS,
    dates: [
      { startDate: new Date('2023-07-20T00:00:00Z'), endDate: new Date('2023-07-21T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_GCLS,
    tournament: ApiCommonLeagueEnum.CSGO_GCLS,
  },

  [ApiCommonLeagueEnum.CSGO_LLP]: {
    id: 'CSGO_LLP',
    label: 'LLP',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_LLP,
    dates: [
      { startDate: new Date('2023-07-21T00:00:00Z'), endDate: new Date('2023-07-29T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_LLP,
    tournament: ApiCommonLeagueEnum.CSGO_LLP,
  },

  [ApiCommonLeagueEnum.CSGO_KaBuM]: {
    id: 'CSGO_KaBuM',
    label: 'KaBuM',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_KaBuM,
    dates: [
      { startDate: new Date('2023-07-21T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_KaBuM,
    tournament: ApiCommonLeagueEnum.CSGO_KaBuM,
  },

  [ApiCommonLeagueEnum.CSGO_ESEAPSCNA]: {
    id: 'CSGO_ESEAPSCNA',
    label: 'ESEA PSC NA',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_ESEAPSCNA,
    dates: [
      { startDate: new Date('2023-07-20T00:00:00Z'), endDate: new Date('2023-07-22T23:59:00Z') },
      { startDate: new Date('2023-07-21T00:00:00Z'), endDate: new Date('2023-07-22T23:59:00Z') },
      { startDate: new Date('2023-07-22T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
      { startDate: new Date('2023-07-23T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
      { startDate: new Date('2023-07-24T00:00:00Z'), endDate: new Date('2023-07-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_ESEAPSCNA,
    tournament: ApiCommonLeagueEnum.CSGO_ESEAPSCNA,
  },

  [ApiCommonLeagueEnum.CSGO_CCTEE]: {
    id: 'CSGO_CCTEE',
    label: 'CCT EE',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_CCTEE,
    dates: [
      { startDate: new Date('2023-08-01T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_CCTEE,
    tournament: ApiCommonLeagueEnum.CSGO_CCTEE,
  },

  [ApiCommonLeagueEnum.CSGO_CCTSA]: {
    id: 'CSGO_CCTSA',
    label: 'CCT SA',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_CCTSA,
    dates: [
      { startDate: new Date('2023-07-31T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-07-23T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_CCTSA,
    tournament: ApiCommonLeagueEnum.CSGO_CCTSA,
  },

  [ApiCommonLeagueEnum.CSGO_RCL]: {
    id: 'CSGO_RCL',
    label: 'RCL',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_RCL,
    dates: [
      { startDate: new Date('2023-07-29T00:00:00Z'), endDate: new Date('2023-07-30T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_RCL,
    tournament: ApiCommonLeagueEnum.CSGO_RCL,
  },

  [ApiCommonLeagueEnum.CSGO_PCV]: {
    id: 'CSGO_PCV',
    label: 'PC V',
    icon: null,
    illustration: '',
    route: routes.sports.CSGO_PCV,
    dates: [
      { startDate: new Date('2023-08-01T00:00:00Z'), endDate: new Date('2023-08-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.CSGO,
    league: ApiCommonLeagueEnum.CSGO_PCV,
    tournament: ApiCommonLeagueEnum.CSGO_PCV,
  },
};
