import { Tab as MuiBaseTab, tabClasses as muiBaseTabClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const headingTabClasses = {
  ...muiBaseTabClasses,
};

export const UIKitHeadingTabRoot = styled(MuiBaseTab, { name: 'HeadingTab' })(({ theme }) => ({
  padding: 0,
  margin: 0,
  background: 'none',
  border: 'none',

  color: theme.palette.grey.A50,
  transition: theme.transitions.create(['color', 'font-size']),
  fontWeight: 600,
  letterSpacing: -0.2,

  [`&.${headingTabClasses.selected}`]: {
    color: theme.palette.text.primary,
  },

  [`&.${headingTabClasses.disabled}`]: {
    color: theme.palette.grey.A10,
    cursor: 'not-allowed',
  },

  [`&:not(.${headingTabClasses.selected}):hover`]: {
    color: theme.palette.grey.A75,
  },

  ['&:not(:last-child)']: {
    marginRight: 20,
  },
}));
