import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from '~shared/lib/hooks';
import { Nft } from '~shared/api';
import { getProvider } from '~shared/lib/utils';

import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  fetchNftInfoFromChain,
  nftActions,
  useNftSelector,
} from '~entities/nft';

import { useNftBetsQuery, useNftCallsQuery } from './hooks';
import { NftCardDetailedInfoTab } from './types';

export const useNftCardDetailedInfoModel = () => {
  const dispatch = useDispatch();
  const nftBetsQuery = useNftBetsQuery();
  const nftCallsQuery = useNftCallsQuery();
  const [tab, setTab] = useState<NftCardDetailedInfoTab>(NftCardDetailedInfoTab.Battles);
  const {
    nfts,
    detailedInfo: { tokenId, open, nickname, avatarUrl, walletAddress, viewMode },
  } = useNftSelector();

  const [nft, setNft] = useState<Nft | null>(null);

  const nftStats = useMemo(() => {
    if (nftBetsQuery.passedList && nftCallsQuery.passedList) {
      let winsAmount = 0;
      let losesAmount = 0;

      nftBetsQuery.passedList.forEach((battle) => {
        if (battle.result === battle.choiceId) {
          winsAmount++;
        } else {
          losesAmount++;
        }
      });

      nftCallsQuery.passedList.forEach((call) => {
        const userChoice =
          call.firstParticipant?.address.toLowerCase() === walletAddress.toLowerCase()
            ? call.firstParticipant?.choiceId
            : call.secondParticipant?.choiceId;

        if (call.result === userChoice) {
          winsAmount++;
        } else {
          losesAmount++;
        }
      });

      return {
        winsAmount,
        losesAmount,
      };
    }

    return { winsAmount: null, losesAmount: null };
  }, [walletAddress, nftBetsQuery.passedList, nftCallsQuery.passedList]);

  const maxNftLives = NFT_RARITY_TO_MAX_LIVES_MAP[nft?.rarity!]!;
  const maxWinStreak = NFT_RARITY_TO_MAX_WIN_STREAK[nft?.rarity!]!;

  const handleTabChange = (_: SyntheticEvent | null, tab: number | string | null) => {
    setTab(Number(tab));
  };

  const handleClose = () => {
    dispatch(nftActions.resetDetailedInfo());
  };

  const getNft = useCallback(async () => {
    if (!viewMode) {
      const nft = nfts.find((nft) => nft.token_id === tokenId);

      if (nft) {
        setNft(nft);
      }

      return;
    }

    const provider = getProvider();

    const nft = await fetchNftInfoFromChain({ provider, nft: { token_id: tokenId } as Nft });

    setNft(nft);
  }, [nfts, tokenId, viewMode]);

  useEffect(() => {
    if (Boolean(tokenId)) {
      getNft();
    }
  }, [tokenId, getNft]);

  return {
    open,
    nft,
    maxNftLives,
    maxWinStreak,
    avatarUrl,
    nickname,
    nftBetsQuery,
    nftCallsQuery,
    nftStats,
    tab,
    viewMode,

    handleTabChange,
    handleClose,
  };
};
