import { FC } from 'react';
import { clsx } from 'clsx';

import {
  NotificationsListDelimiterClasses,
  NotificationsListDelimiterHr,
  NotificationsListDelimiterLabel,
  NotificationsListDelimiterRoot,
} from './styled';

import { NotificationsListDelimiterProps } from './types';

export const NotificationsListDelimiter: FC<NotificationsListDelimiterProps> = ({
  children,
  active = false,
}) => {
  return (
    <NotificationsListDelimiterRoot
      className={clsx({
        [NotificationsListDelimiterClasses.active]: active,
      })}
    >
      <NotificationsListDelimiterLabel className={NotificationsListDelimiterClasses.label}>
        {children}
      </NotificationsListDelimiterLabel>

      <NotificationsListDelimiterHr className={NotificationsListDelimiterClasses.hr} />
    </NotificationsListDelimiterRoot>
  );
};
