import { LegacyRef, MutableRefObject, RefCallback } from 'react';

export const mergeRefs =
  <T extends any = any>(
    refs: Array<MutableRefObject<T> | LegacyRef<T> | null | undefined>
  ): RefCallback<T> =>
  (value) => {
    refs
      .filter((ref) => !!ref)
      .forEach((ref) => {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref !== null) {
          (ref as MutableRefObject<T | null>).current = value;
        }
      });
  };
