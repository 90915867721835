export const AuthRoutes = {
  // Google OAuth
  authGoogle: () => '/auth/google',

  // Identification
  checkEmail: () => '/check_email',

  // Sign Up
  signUp: () => '/signup',
  signUpVerification: () => '/signup_verification',
  resendAuthCode: () => '/resend_auth_code',

  // Sign In
  signIn: () => '/signin',

  // Sign Out
  signOut: () => '/logout',
};
