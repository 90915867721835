import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ContactUsState } from './type';

const initialState: ContactUsState = {
  isOpen: false,
};

export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: initialState,
  reducers: {
    setToggle: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOpen: action.payload,
    }),
  },
});

export const contactUsReducer = contactUsSlice.reducer;
export const contactUsActions = contactUsSlice.actions;
