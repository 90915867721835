import { createSlice } from '@reduxjs/toolkit';

import { AccountState } from './types';

const initialState: AccountState = {
  open: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    open: (state) => ({ ...state, open: true }),
    close: (state) => ({ ...state, open: false }),
  },
});

export const accountReducer = accountSlice.reducer;
export const accountActions = accountSlice.actions;
