import { NftRarity } from '~shared/api';

import { NFT_RARITY_TO_READABLE_KEY_MAP } from '~entities/nft';

import {
  MARKETPLACE_MYSTERY_BOX_IMAGE_DIR,
  MARKETPLACE_NFT_IMAGE_URL_DIR,
  MARKETPLACE_UNFREEZE_IMAGE_URL,
} from '~entities/marketplace';

interface GetMarketplaceAssetParams {
  rarity?: NftRarity;
  isMysteryBox: boolean;
}

export const getMarketplaceAsset = ({ rarity, isMysteryBox }: GetMarketplaceAssetParams) => {
  if (typeof rarity === 'undefined') {
    return MARKETPLACE_UNFREEZE_IMAGE_URL;
  }

  const readableRarity = NFT_RARITY_TO_READABLE_KEY_MAP[rarity];

  if (isMysteryBox) {
    return `${MARKETPLACE_MYSTERY_BOX_IMAGE_DIR}/${readableRarity}.png`;
  }

  return `${MARKETPLACE_NFT_IMAGE_URL_DIR}/${readableRarity}.png`;
};
