import { useTranslation } from 'react-i18next';

import { Stack } from '~shared/ui';

import { DashboardAuction } from '~entities/auction';
import { DashboardTournaments } from '~entities/tournament';
import { DashboardBattles } from '~entities/bets';
import { DashboardCalls } from '~entities/calls';
import { useMediaQuery } from '~shared/lib/hooks';
import { DashboardRanking } from '~entities/ranking';
import { DashboardFinanceCard } from '~entities/transaction';

import { ProfileDashboardHorizontalList } from './ProfileDashboardHorizontalList';
import { BattlesAndCallsContainer, Content } from './styled';

export const ProfileDashboard = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  return (
    <Stack spacing={24 / 8} pt={isMobile ? 0 : '4px'} pb={isMobile ? '20px' : '24px'}>
      {/* {isConnected ? <DashboardFinances /> : <DashboardNoWalletConnected />} */}

      <Content>
        <ProfileDashboardHorizontalList>
          <DashboardRanking />
          <DashboardFinanceCard type="income" name={`${t('Other.income')}`} />
          <DashboardFinanceCard type="outcome" name={`${t('Other.outcome')}`} />
        </ProfileDashboardHorizontalList>

        <Stack spacing={16 / 8}>
          <BattlesAndCallsContainer>
            <DashboardBattles />
            <DashboardCalls />
          </BattlesAndCallsContainer>

          <DashboardAuction />
          <DashboardTournaments />
        </Stack>
      </Content>
    </Stack>
  );
};
