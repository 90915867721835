import { FC, ReactNode } from 'react';

import { Stack } from '~shared/ui';

import {
  BattleEventTab,
  BattleEventTypeTab,
  BattleListHeading,
  useBattleListSettings,
} from '~entities/battle';

import { ViewerBattlesList } from '~widgets/viewer-battles-list';
import { ViewerCallsList } from '~widgets/viewer-calls-list';
import { BattleList } from '~widgets/battle-list';
import { CallsList } from '~widgets/calls-list';

import { CommonBattleListProps } from './types';

export const CommonEventsList: FC<CommonBattleListProps> = ({ sport, league, label }) => {
  const {
    /***/
    eventTypeTab,
    onChangeEventTypeTab,
    eventTab,
    onChangeEventTab,
  } = useBattleListSettings();

  const tabs: Record<BattleEventTypeTab, Record<BattleEventTab, ReactNode>> = {
    [BattleEventTypeTab.Battles]: {
      [BattleEventTab.AllEvents]: <BattleList sport={sport} league={league} />,
      [BattleEventTab.MyEvents]: <ViewerBattlesList sport={[sport]} league={league} divider />,
    },
    [BattleEventTypeTab.Calls]: {
      [BattleEventTab.AllEvents]: <CallsList sport={[sport]} league={league} />,
      [BattleEventTab.MyEvents]: <ViewerCallsList sport={[sport]} league={league} divider />,
    },
  };

  return (
    <Stack flex={1} spacing={12 / 8}>
      <BattleListHeading
        eventTypeTab={eventTypeTab}
        onChangeEventTypeTab={onChangeEventTypeTab}
        eventTab={eventTab}
        onChangeEventTab={onChangeEventTab}
        label={label}
      />

      {tabs[eventTypeTab][eventTab]}
    </Stack>
  );
};
