import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const Root = styled(Box, {
  name: 'ProfileAccount',
  slot: 'root',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  paddingBottom: '14px',
});

export const AccountPanelWrapper = styled(Box, {
  name: 'ProfileAccount',
  slot: 'accountPanelWrapper',
})({
  display: 'grid',
  gap: 30,
  padding: 24,
  gridTemplateRows: 'max-content',
});

export const AccountHeader = styled(Box, { name: 'ProfileAccount', slot: 'accountHeader' })(
  ({ theme }) => ({
    background: theme.palette.grey.A5,
    borderRadius: '8px',
    padding: '20px',
    paddingBottom: 0,
    display: 'grid',
    gap: '18px',

    [theme.breakpoints.down('mobile')]: {
      padding: '14px 16px',
      paddingBottom: 0,
      gap: '14px',
    },
  })
);
