import { FC } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { htmlDefaultLayoutMainAnchorId } from '~shared/config';
import { Stack } from '~shared/ui';

import { BattleShimmer } from '../misc';
import { BattleShellSizeMap, useBattleAutoStyle } from '../../../lib';

import { BattleVerticalListProps } from './types';
import { BattleVirtuosoVerticalList } from './styled';

export const BattleVerticalList: FC<BattleVerticalListProps> = ({
  children,
  loading,
  scrollParent = htmlDefaultLayoutMainAnchorId,
  virtualize = true,
}) => {
  const { size, variant } = useBattleAutoStyle('line');

  if (loading) {
    return (
      <Stack spacing={12 / 8}>
        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
      </Stack>
    );
  }

  if (virtualize) {
    const customScrollParent =
      typeof scrollParent === 'string' ? document.getElementById(scrollParent)! : scrollParent;

    const fixedItemHeight =
      variant === 'line' ? BattleShellSizeMap['line'][size].height + 12 : undefined;

    return (
      <Virtuoso
        totalCount={children.length}
        itemContent={(index) => children[index]}
        components={{ List: BattleVirtuosoVerticalList }}
        customScrollParent={customScrollParent}
        fixedItemHeight={fixedItemHeight}
      />
    );
  }

  return <Stack spacing={12 / 8}>{children}</Stack>;
};
