import { FC, SyntheticEvent } from 'react';
import { Portal } from '@mui/base';
import { AnimatePresence } from 'framer-motion';

import { htmlDefaultLayoutBottomAnchorId } from '~shared/config';

import { Root } from './styled';
import { MobileDialogProps } from './types';

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

export const MobileDialog: FC<MobileDialogProps> = ({
  children,
  disableTransition,
  sx,
  open,
  className,
}) => {
  const handlePropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  if (!open) {
    return null;
  }

  if (disableTransition) {
    return (
      <Portal container={document.getElementById(htmlDefaultLayoutBottomAnchorId)}>
        <Root className={className} sx={sx} onClick={handlePropagation}>
          {children}
        </Root>
      </Portal>
    );
  }

  return (
    <Portal container={document.getElementById(htmlDefaultLayoutBottomAnchorId)}>
      <AnimatePresence>
        <Root
          className={className}
          onClick={handlePropagation}
          variants={paperMotionVariants}
          initial={AnimationVariant.Hidden}
          animate={AnimationVariant.Visible}
          exit={AnimationVariant.Hidden}
          sx={sx}
        >
          {children}
        </Root>
      </AnimatePresence>
    </Portal>
  );
};

const paperMotionVariants = {
  [AnimationVariant.Hidden]: {
    opacity: 0,
    y: -5,
    scale: 0.95,
    transition: { duration: 0.2, type: 'tween' },
  },
  [AnimationVariant.Visible]: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.2, type: 'tween' },
  },
};
