import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const Root = styled(Box, { name: 'MarketplaceItem', slot: 'root' })({
  background: '#1A1D20',
  borderRadius: 12,
  padding: '20px 16px 20px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 260,
});

export const IconContainer = styled(Box, { name: 'MarketplaceItem', slot: 'iconContainer' })({
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  height: 140,
});

export const ItemName = styled(Typography, { name: 'MarketplaceItem', slot: 'itemName' })(
  ({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 18 / 16,
    letterSpacing: -0.2,
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: 24,
  })
);

export const ItemDescription = styled(Typography, {
  name: 'MarketplaceItem',
  slot: 'itemDescription',
})(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
  textAlign: 'center',
  color: theme.palette.grey.A75,
  marginTop: 20,
}));

export const ItemParamsContainer = styled(Box, {
  name: 'MarketplaceItem',
  slot: 'itemParamsContainer',
})({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: 6,
  marginTop: 24,
});

export const ItemParam = styled(Box, {
  name: 'MarketplaceItem',
  slot: 'itemParam',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

export const ItemParamLabel = styled(Typography, {
  name: 'MarketplaceItem',
  slot: 'itemParamLabel',
})(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.text.secondary,
}));

export const ItemParamValue = styled(Typography, {
  name: 'MarketplaceItem',
  slot: 'itemParamValue',
})({
  fontSize: 13,
  fontWeight: 600,
  lineHeight: 21 / 13,
});

export const ItemBottom = styled(Box, { name: 'MarketplaceItem', slot: 'itemBottom' })({
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'stretch',
  justifyContent: 'flex-end',
  flex: 1,
  flexDirection: 'column',
  marginTop: 24,
});
