import { styled } from '~shared/lib/styles';
import { Dialog } from '~shared/ui';

export const StyledDialog = styled(Dialog, {
  name: 'SellCardDialog',
  slot: 'dialog',
})(({ theme }) => ({
  '& .Dialog-content': {
    background: theme.palette.paper.secondary,
  },
}));
