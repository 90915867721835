import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// todo: maybe refactoring
export const useSearchParamsState = <S extends string>(
  searchParamName: string,
  defaultValue: S
): readonly [
  searchParamsState: S,
  setSearchParamsState: (newState: S) => void,
  omitSearchParam: VoidFunction,
] => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName) as S;
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = useCallback(
    (newState: S) => {
      const next = Object.assign(
        {},
        [...Array.from(searchParams.entries())].reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value }),
          {}
        ),
        { [searchParamName]: newState }
      );

      // directly set pathname from window.location
      // cause of location.pathname collision in navigate()
      navigate({
        search: new URLSearchParams(next).toString(),
        pathname: window.location.pathname,
      });
    },
    [navigate, searchParamName, searchParams]
  );

  const omitSearchParam = useCallback(() => {
    const searchParamsEntries = searchParams.entries();
    const searchParamsEntriesArray = Array.from(searchParamsEntries);

    navigate({
      search: new URLSearchParams(
        searchParamsEntriesArray
          .filter((entry) => entry[0] !== searchParamName)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      ).toString(),
      pathname: window.location.pathname,
    });
  }, [navigate, searchParamName, searchParams]);

  return [searchParamsState, setSearchParamsState, omitSearchParam];
};
