import { styled } from '~shared/lib/styles';

import { Typography } from '../../dataDisplay';
import { Box } from '../../layout';

export const headingClasses = {
  root: 'Heading-root',

  section: 'Heading-section',

  navigation: 'Heading-navigation',
  typography: 'Heading-typography',
};

export const UIKitHeadingRoot = styled(Box, { name: 'Heading' })({
  display: 'flex',

  [`&.${headingClasses.section}`]: {
    padding: '14px 16px',
    borderBottom: '1px solid rgba(255, 255, 255, .1)',
  },
});

export const UIKitHeadingNavigation = styled('div', { name: 'Heading', slot: 'navigation' })({
  marginRight: 12,
});

export const UIKitHeadingTypography = styled(Typography, { name: 'Heading', slot: 'typography' })(
  {}
);
