import { FocusEvent, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';

import { Nft } from '~shared/api';
import { useDispatch, useSnackbar } from '~shared/lib/hooks';

import { nftActions, useNftSelector } from '~entities/nft';

import { SelectValue } from '~shared/ui';

import { useGetBalance, useWriteContract } from '~entities/wallet';

import { MainToken__factory, NFT__factory } from '~shared/contracts';

import { getProvider } from '~shared/lib/utils';

import { FixCardCurrency, FixPrice } from './types';

const getNotEnoughFundsError = (currency: FixCardCurrency) =>
  `Looks like you don't have enough ${currency} in your wallet. Try paying with ${
    currency === FixCardCurrency.Matic ? 'MCN' : 'Matic'
  }.`;

export const useFixCardDialogModel = () => {
  const { t } = useTranslation();

  const [currency, setCurrency] = useState<FixCardCurrency>(FixCardCurrency.Matic);
  const { account } = useWeb3React();

  const {
    dialogs: {
      repair: {
        open,
        nfts: [nft],
      },
    },
  } = useNftSelector();

  const { openSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const getBalance = useGetBalance();

  const [fixPrice, setFixPrice] = useState<FixPrice>({
    matic: 0,
    mcn: 0,
  });

  const { write: approveTokens } = useWriteContract({
    contract: 'MainToken',
    method: 'approve',
    transactionName: 'Approve access to tokens',
    successMessage: 'Approving access to tokens was successful',
    errorMessage: `${t('Errors.ApprovingAccess')}`,
  });

  const { write: restoreLiveMcn } = useWriteContract({
    contract: 'NFT',
    method: 'restoreLive',
    transactionName: 'Fixing card',
    successMessage: `${t('Alerts.successfulFix')}`,
    errorMessage: `${'Errors.fixFailed'}`,
  });

  const { write: restoreLiveMatic } = useWriteContract({
    contract: 'NFT',
    method: 'restoreLiveMatic',
    transactionName: 'Fixing card',
    successMessage: `${t('Alerts.successfulFix')}`,
    errorMessage: `${'Errors.fixFailed'}`,
  });

  const handleCurrencyChange = (
    _: MouseEvent | KeyboardEvent | FocusEvent | null,
    value: SelectValue | null
  ) => {
    setCurrency(value as any);
  };

  const handleClose = () => {
    dispatch(
      nftActions.setDialogs({
        action: 'repair',
        dialog: {
          open: false,
          nfts: [],
        },
      })
    );
  };

  const checkAllowance = async () => {
    if (!account) {
      return;
    }

    const provider = getProvider();
    const mainTokenContract = MainToken__factory.connect(
      process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
      provider
    );

    const allowanceInWei = await mainTokenContract.allowance(
      account,
      process.env.REACT_APP_ADDRESS_SK_CARD
    );

    const allowance = Number(ethers.utils.formatEther(allowanceInWei));

    return allowance >= fixPrice.mcn;
  };

  const checkIfUserHasEnoughFunds = async () => {
    if (!account) {
      return;
    }

    const provider = getProvider();

    if (currency === FixCardCurrency.Matic) {
      const maticBalanceInWei = await provider.getBalance(account);
      const maticBalance = Number(ethers.utils.formatEther(maticBalanceInWei));

      return maticBalance >= fixPrice.matic;
    }

    if (currency === FixCardCurrency.MCN) {
      const mcnContract = MainToken__factory.connect(
        process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
        provider
      );

      const mcnBalanceInWei = await mcnContract.balanceOf(account);
      const mcnBalance = Number(ethers.utils.formatEther(mcnBalanceInWei));

      return mcnBalance >= fixPrice.mcn;
    }
  };

  const handleFixCard = async () => {
    try {
      const areEnoughFunds = await checkIfUserHasEnoughFunds();

      if (!areEnoughFunds) {
        const notEnoughFundsErrorMessage = getNotEnoughFundsError(currency);
        openSnackbar({ type: 'error', message: notEnoughFundsErrorMessage });

        return;
      }

      const tokenId = nft.token_id;

      const shouldRecoverWithMCN = currency === FixCardCurrency.MCN;

      if (shouldRecoverWithMCN) {
        const isEnoughAllowance = await checkAllowance();

        if (!isEnoughAllowance) {
          await approveTokens({
            args: [
              process.env.REACT_APP_ADDRESS_SK_CARD,
              ethers.utils.parseEther(String(fixPrice.mcn)),
            ],
          });
        }

        await restoreLiveMcn({ args: [tokenId] });
      } else {
        await restoreLiveMatic({
          args: [tokenId],
          value: ethers.utils.parseEther(String(fixPrice.matic)),
        });
      }

      dispatch(nftActions.repairNft(nft.token_id));
      handleClose();
      getBalance();
    } catch (e) {
      console.error(e);
    }
  };

  const getRecoveryCost = useCallback(async () => {
    if (!nft || !account) {
      return;
    }

    const provider = getProvider();
    const nftContract = NFT__factory.connect(process.env.REACT_APP_ADDRESS_SK_CARD, provider);
    const recoverCostForMaticInWei = await nftContract.recoveryMatic(Number(nft.token_id));
    const recoverCostForMatic = Number(ethers.utils.formatEther(recoverCostForMaticInWei));

    const recoverCostForMCNInWei = await nftContract.recoveryMaintokens(Number(nft.token_id));

    const recoverCostForMCN = Number(ethers.utils.formatEther(recoverCostForMCNInWei));

    setFixPrice({ mcn: recoverCostForMCN, matic: recoverCostForMatic });
  }, [nft, account]);

  useEffect(() => {
    getRecoveryCost();
  }, [getRecoveryCost]);

  return {
    open,
    nft,
    fixPrice,
    handleFixCard,
    handleCurrencyChange,
    handleClose,
  };
};

export const useFixCardModel = (nft: Nft) => {
  const dispatch = useDispatch();

  const handleOpenFixCardDialog = () => {
    dispatch(
      nftActions.setDialogs({
        action: 'repair',
        dialog: {
          open: true,
          nfts: [nft],
        },
      })
    );
  };

  const handleCloseFixCardDialog = () => {
    dispatch(
      nftActions.setDialogs({
        action: 'repair',
        dialog: {
          open: false,
          nfts: [],
        },
      })
    );
  };

  return {
    handleOpenFixCardDialog,
    handleCloseFixCardDialog,
  };
};
