import { FC } from 'react';

import { CircularProgress } from '../CircularProgress';

import { CircularProgressViewProps } from './types';
import { UIKitCircularProgressViewRoot } from './styled';

export const CircularProgressView: FC<CircularProgressViewProps> = ({ ...props }) => {
  return (
    <UIKitCircularProgressViewRoot>
      <CircularProgress {...props} />
    </UIKitCircularProgressViewRoot>
  );
};
