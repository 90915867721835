import { FC, Fragment, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  CreatePassword,
  DialogTitle,
  Link,
  RepeatPassword,
  Stack,
  Typography,
} from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { useAuthModel } from '~widgets/auth/model/model';

import { PasswordCreationForm } from './types';

export const PasswordCreation: FC = () => {
  const { t } = useTranslation();
  const submitting = useSwitcher(false);

  const form = useForm<PasswordCreationForm>({});
  const { setStep, signUp, email, nickname } = useAuthModel();

  const handleSubmit: SubmitHandler<PasswordCreationForm> = useCallback(
    async ({ password }) => {
      submitting.switchOn();

      const captchaToken = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY,
        { action: 'sign_up_click' }
      );

      signUp({ password, email, nickname, captchaToken }).then(() => {
        submitting.switchOff();
        setStep((steps) => steps.VerificationCode);
      });
    },
    [submitting, signUp, email, nickname, setStep]
  );

  return (
    <Fragment>
      <DialogTitle>{t('Other.createPass')}</DialogTitle>

      <FormProvider {...form}>
        <Stack component="form" spacing={24 / 8} onSubmit={form.handleSubmit(handleSubmit)}>
          <CreatePassword autoFocus />
          <RepeatPassword />

          <Button type="submit" disabled={submitting.value}>
            {t('Other.createPass')}
          </Button>

          <Typography align="center" fontSize={12}>
            <Trans i18nKey="Other.recaptchaTermsOfService" t={t}>
              This site is protected by reCAPTCHA and the Google{' '}
              <Link to="https://policies.google.com/privacy" target="_blank">
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link to="https://policies.google.com/terms" target="_blank">
                Terms of Service
              </Link>{' '}
              apply.
            </Trans>
          </Typography>
        </Stack>
      </FormProvider>
    </Fragment>
  );
};
