import { ReactElement, createElement } from 'react';

import { ApiGetBattlesMappedData, ApiGetBattlesResponseData } from '~shared/api';

import { useBattleAutoStyle } from '~entities/battle';

import { Battle } from '../ui';

interface UseBattleListProps {
  list?: ApiGetBattlesResponseData;
}

interface UseBattleList {
  (props: UseBattleListProps): {
    list: Array<ReactElement>;
    renderBattle: (battle: ApiGetBattlesMappedData) => ReactElement;
    renderSquareBattle: (battle: ApiGetBattlesMappedData) => ReactElement;
  };
}

export const useBattleList: UseBattleList = ({ list = [] }) => {
  const { size, variant } = useBattleAutoStyle('line');

  const renderBattle = (battle: ApiGetBattlesMappedData) => {
    return createElement(Battle, { ...battle, key: battle.id, variant, size });
  };

  const renderSquareBattle = (battle: ApiGetBattlesMappedData) => {
    return createElement(Battle, { ...battle, key: battle.id, variant: 'square', size });
  };

  return {
    list: list.map(renderBattle),

    renderBattle,
    renderSquareBattle,
  };
};
