import { DragEventHandler, useEffect } from 'react';

import { useSwitcher } from '~shared/lib/hooks';

export const useBattleDragHighlight = (disabled?: boolean) => {
  const highlighted = useSwitcher(false);

  const onDragOver: DragEventHandler = () => {
    highlighted.switchOn();
  };

  const onDragLeave: DragEventHandler = () => {
    highlighted.switchOff();
  };

  useEffect(() => {
    if (disabled) {
      highlighted.switchOff();
    }
  }, [disabled, highlighted]);

  if (disabled) {
    return {};
  }

  return { highlight: highlighted.value, onDragOver, onDragLeave };
};
