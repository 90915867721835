import { ethers } from 'ethers';

export const isWeb3Injected = (): boolean =>
  typeof window.web3 !== 'undefined' && typeof window.web3.currentProvider !== 'undefined';

export const isEthereumInjected = (): boolean => typeof window.ethereum !== 'undefined';

export const isMetamaskInjected = (): boolean =>
  isEthereumInjected() || (isWeb3Injected() && window.web3.currentProvider.isMetaMask);

export const isCoinbaseInjected = (): boolean =>
  isWeb3Injected() && window.web3.currentProvider.isCoinbaseWallet;

// account is not optional
export const getSigner = (
  provider: ethers.providers.JsonRpcProvider,
  account: string
): ethers.providers.JsonRpcSigner => {
  return provider.getSigner(account).connectUnchecked();
};

// account is optional
export const getProviderOrSigner = (
  provider: ethers.providers.JsonRpcProvider,
  account?: string
): ethers.providers.JsonRpcProvider | ethers.providers.JsonRpcSigner => {
  return account ? getSigner(provider, account) : provider;
};

type GetContractParams = {
  address: string;
  abi: any;
  provider: ethers.providers.JsonRpcProvider;
  account?: string;
};

// account is optional
export const getContract = ({
  address,
  abi,
  provider,
  account,
}: GetContractParams): ethers.Contract => {
  return new ethers.Contract(address, abi, getProviderOrSigner(provider, account) as any);
};
