import { Navigate, Outlet } from 'react-router';

import { useMediaQuery } from '~shared/lib/hooks';
import { routes } from '~shared/config';

export const MobileRoute = () => {
  const { isMobile } = useMediaQuery();

  if (!isMobile) {
    return <Navigate to={routes.index} />;

    // todo: fix
    // if (location.key === 'default') {
    //   return <Navigate to={routes.index} />;
    // }
    //
    // return <Navigate to={-1 as To} />;
  }

  return <Outlet />;
};
