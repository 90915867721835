import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const UserSidebarRoot = styled(Box, {
  name: 'UserSidebar',
  slot: 'root',
})(({ theme }) => ({
  minWidth: '320px',
  maxWidth: '320px',
  backgroundColor: theme.palette.paper.semi,
  padding: '20px 16px 14px',
  display: 'flex',
  flexDirection: 'column',
}));
