import { useQuery } from 'react-query';

import { getMarketplaceCards } from '~shared/api';

export const useMarketplaceItemsUSDInfoQuery = () => {
  const { isLoading, data } = useQuery(['marketplace-cards'], () => {
    return getMarketplaceCards();
  });

  return {
    data,
    isLoading,
  };
};
