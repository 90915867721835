import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router';

import { Button, ButtonIcon, Typography } from '~shared/ui';

import { useNotificationsModel } from '../../../../model';

import { ActiveAmount, Amount, Root } from './styled';
import { routes } from '~shared/config';

interface NotificationCenterTopProps {
  backNavigation?: boolean;
}

export const NotificationCenterTop: FC<NotificationCenterTopProps> = ({ backNavigation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    service,

    newNotificationsCount,
    notificationsCount,
  } = useNotificationsModel();

  const handleGoBack = () => {
    if (location.key === 'default') {
      navigate(-1);
    } else {
      navigate(routes.index);
    }
  };

  return (
    <Root>
      {backNavigation && (
        <ButtonIcon name="chevron-left" variant="plain" size={24} onClick={handleGoBack} />
      )}

      <Typography component="span">
        {t('Notifications.notifications')}
        <Amount>
          <ActiveAmount>{newNotificationsCount}</ActiveAmount>
          &nbsp;/&nbsp;
          <Typography component="span">{notificationsCount}</Typography>
        </Amount>
      </Typography>

      <Button
        ml="auto"
        size="sm"
        variant="outlined"
        onClick={service?.readAllNotifications}
        disabled={!newNotificationsCount}
      >
        {t('Notifications.readAll')}
      </Button>
    </Root>
  );
};
