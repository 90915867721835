import { FC } from 'react';

import { Icon } from '~shared/ui';

import { NftMintingLabel, NftMintingRoot } from './styled';

export const NftMinting: FC = () => {
  return (
    <NftMintingRoot>
      <Icon name="time" mb={1} />
      <NftMintingLabel>
        MINTING
        <br />A CARD
      </NftMintingLabel>
    </NftMintingRoot>
  );
};
