import { apiInstance } from './../apiInstance';
import { ReferralRoutes } from './routes';
import { ApiPostCreateReferral } from './types';

export const postCreateReferral: ApiPostCreateReferral = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ReferralRoutes.createReferral(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
