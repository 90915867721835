import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  HeadingTab,
  HeadingTabs,
  HeadingTabsList,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonIcon,
  ToggleButtonMenu,
} from '~shared/ui';

import { useMediaQuery } from '~shared/lib/hooks';

import { BattlePeriod, BattleView, BattleEventTypeTab, BattleEventTab } from '../../../../lib';

import {
  BattleListHeadingLabel,
  BattleListHeadingRoot,
  BattleListHeadingRootSettings,
} from './styled';

import { BattleListHeadingProps } from './types';

const periodOptions = [
  { label: 'Future', value: BattlePeriod.Future },
  { label: 'Past', value: BattlePeriod.Past },
];

const useTranslateMainTab = () => {
  const { t } = useTranslation();

  const eventTypeTabOptions = [
    { label: t('Tabs.myBattles'), value: BattleEventTypeTab.Battles },
    { label: t('Tabs.myCalls'), value: BattleEventTypeTab.Calls },
  ];

  return eventTypeTabOptions;
};

const useTranslationEvents = () => {
  const { t } = useTranslation();

  const eventTabOptions = [
    { label: t('Other.allEvents'), value: BattleEventTab.AllEvents },
    { label: t('Other.myEvents'), value: BattleEventTab.MyEvents },
  ];

  return eventTabOptions;
};

export const BattleListHeading: FC<BattleListHeadingProps> = ({
  label,
  mobile,

  periodDisabled,
  onChangePeriod,
  period,

  viewDisabled,
  onChangeView,
  view,

  eventTypeTabDisabled,
  onChangeEventTypeTab,
  eventTypeTab,

  eventTab,
  onChangeEventTab,
  eventTabDisabled,
}) => {
  const { isMobile } = useMediaQuery();
  const eventTypeTabOptions = useTranslateMainTab();
  const eventTabOptions = useTranslationEvents();

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={16 / 8}>
        <BattleListHeadingLabel>{label}</BattleListHeadingLabel>

        <HeadingTabs
          value={eventTypeTab}
          onChange={onChangeEventTypeTab}
          gutters={!isMobile}
          size="md"
        >
          <HeadingTabsList>
            {eventTypeTabOptions.map((tab) => (
              <HeadingTab key={tab.value} value={tab.value} disabled={eventTypeTabDisabled}>
                {tab.label}
              </HeadingTab>
            ))}
          </HeadingTabsList>
        </HeadingTabs>
      </Stack>

      <Divider mb={0} mt={isMobile ? 12 / 8 : 0} />

      <BattleListHeadingRoot>
        <HeadingTabs value={eventTab} onChange={onChangeEventTab} size={isMobile ? 'sm' : 'md'}>
          <HeadingTabsList>
            {eventTabOptions.map((tab) => (
              <HeadingTab key={tab.value} value={tab.value} disabled={eventTabDisabled}>
                {tab.label}
              </HeadingTab>
            ))}
          </HeadingTabsList>
        </HeadingTabs>

        <BattleListHeadingRootSettings spacing={14 / 8} direction="row">
          {onChangeView || viewDisabled || view ? (
            <Stack spacing={10 / 8} direction="row">
              <ToggleButtonGroup value={view} onChange={onChangeView}>
                <ToggleButtonIcon
                  value={BattleView.Grid}
                  name="view-grid"
                  disabled={viewDisabled}
                />
                <ToggleButtonIcon
                  value={BattleView.List}
                  name="view-line"
                  disabled={viewDisabled}
                />
              </ToggleButtonGroup>
            </Stack>
          ) : null}

          {onChangePeriod || periodDisabled || period ? (
            mobile ?? isMobile ? (
              <ToggleButtonMenu
                value={period}
                options={periodOptions}
                onChange={onChangePeriod as any}
                disabled={periodDisabled}
              />
            ) : (
              <Stack spacing={1} direction="row">
                <ToggleButtonGroup value={period} onChange={onChangePeriod}>
                  {periodOptions.map((option) => (
                    <ToggleButton value={option.value} key={option.value} disabled={periodDisabled}>
                      {option.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            )
          ) : null}
        </BattleListHeadingRootSettings>
      </BattleListHeadingRoot>
    </Box>
  );
};
