import { ApiCommonLeagueEnum, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from '../types';

export const DotaLeaguesConfig: { [key in ApiCommonLeagueEnum]?: SportItemConfig } = {
  [ApiCommonLeagueEnum.DOTA2_TI]: {
    id: 'DOTA2_TI',
    label: 'TI',
    icon: null,
    illustration: '',
    route: routes.sports.DOTA2_TI,
    dates: [
      { startDate: new Date('2023-08-15T00:00:00Z'), endDate: new Date('2023-10-29T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.Dota,
    league: ApiCommonLeagueEnum.DOTA2_TI,

    tournament: ApiCommonLeagueEnum.DOTA2_TI,
  },

  [ApiCommonLeagueEnum.DOTA2_RMS]: {
    id: 'DOTA2_RMS',
    label: 'RMS',
    icon: null,
    illustration: '',
    route: routes.sports.DOTA2_RMS,
    dates: [
      { startDate: new Date('2023-07-19T00:00:00Z'), endDate: new Date('2023-07-30T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.Dota,
    league: ApiCommonLeagueEnum.DOTA2_RMS,

    tournament: ApiCommonLeagueEnum.DOTA2_RMS,
  },

  [ApiCommonLeagueEnum.DOTA2_ASIAN]: {
    id: 'DOTA2_ASIAN',
    label: 'ASIAN',
    icon: null,
    illustration: '',
    route: routes.sports.DOTA2_ASIAN,
    dates: [
      { startDate: new Date('2023-09-10T00:00:00Z'), endDate: new Date('2023-09-25T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.Dota,
    league: ApiCommonLeagueEnum.DOTA2_ASIAN,

    tournament: ApiCommonLeagueEnum.DOTA2_ASIAN,
  },

  [ApiCommonLeagueEnum.DOTA2_MSMS]: {
    id: 'DOTA2_MSMS',
    label: 'MSMS',
    icon: null,
    illustration: '',
    route: routes.sports.DOTA2_MSMS,
    dates: [
      { startDate: new Date('2023-07-10T00:00:00Z'), endDate: new Date('2023-07-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.Dota,
    league: ApiCommonLeagueEnum.DOTA2_MSMS,

    tournament: ApiCommonLeagueEnum.DOTA2_MSMS,
  },

  [ApiCommonLeagueEnum.DOTA2_PGS]: {
    id: 'DOTA2_PGS',
    label: 'PGS',
    icon: null,
    illustration: '',
    route: routes.sports.DOTA2_PGS,
    dates: [
      { startDate: new Date('2023-07-24T00:00:00Z'), endDate: new Date('2023-07-27T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.Dota,
    league: ApiCommonLeagueEnum.DOTA2_PGS,
    tournament: ApiCommonLeagueEnum.DOTA2_PGS,
  },
};
