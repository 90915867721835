import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField } from '~shared/ui';

import { BaseTextFieldProps } from './types';

export const BaseTextField: FC<BaseTextFieldProps> = ({
  required,
  name,
  defaultValue,
  helperText,
  onChange,
  rules,
  ...props
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const computedRequired = useMemo(() => {
    if (!required) {
      return false;
    }

    if (typeof required === 'string') {
      return required;
    }

    return `${t('Other.fieldIsEmpty')}`;
  }, [required, t]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field: { ref, ...field }, fieldState }) => (
        <TextField
          {...props}
          {...field}
          inputRef={ref}
          error={props.error ?? !!fieldState.error}
          helperText={fieldState.error?.message || helperText}
          onChange={(event) => {
            field.onChange(event);
            onChange && onChange(event);
          }}
        />
      )}
      rules={{
        required: computedRequired,
        ...rules,
      }}
    />
  );
};
