import { apiInstance } from '~shared/api';

import { BlockchainRoutes } from './routes';
import { ApiPostPermit, ApiPostPermitResponseData } from './types';

export const postPermit: ApiPostPermit = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiPostPermitResponseData>(BlockchainRoutes.permit(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
