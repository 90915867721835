import { styled } from '~shared/lib/styles';

export const LeaderboardMobileNicknameContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: 55,
  alignItems: 'center',
  marginTop: '20px',
}));

export const LeaderboardMobileAvatarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const LeaderboardMobileInfoContainer = styled('div')({});

export const LeaderboardMobileInfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '15px 0px',
});

export const LeaderboardRowName = styled('p')({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '24px',
});
