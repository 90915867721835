import { Tabs as MuiBaseTabs, tabsClasses as muiBaseTabsClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';
import { headingTabClasses } from '~shared/ui';

export const headingTabsClasses = {
  ...muiBaseTabsClasses,

  sm: 'HeadingTabs-sm',
  md: 'HeadingTabs-md',
  lg: 'HeadingTabs-lg',
  responsive: 'HeadingTabs-responsive',
  gutters: 'HeadingTabs-gutters',
};

export const UIKitHeadingTabsRoot = styled(MuiBaseTabs)(({ theme }) => {
  const mdStyles = {
    fontSize: 18,
    lineHeight: 32 / 18,
  } as const;

  const lgStyles = {
    fontSize: 26,
    lineHeight: 39 / 26,
  } as const;

  const smStyles = {
    fontSize: 14,
    lineHeight: 14 / 14,
  } as const;

  return {
    [`&.${headingTabsClasses.sm} .${headingTabClasses.root}`]: smStyles,

    [`&.${headingTabsClasses.md} .${headingTabClasses.root}`]: mdStyles,

    [`&.${headingTabsClasses.lg} .${headingTabClasses.root}`]: lgStyles,

    [`&.${headingTabsClasses.gutters}`]: {
      paddingTop: 20,
      paddingBottom: 14,
    },

    [`&.${headingTabsClasses.responsive} .${headingTabClasses.root}`]: {
      ...lgStyles,

      [theme.breakpoints.down('md')]: mdStyles,
    },
  };
});
