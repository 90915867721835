import { SelectOption } from '@mui/base';
import { isValidElement } from 'react';
import { Modifier } from '@popperjs/core/lib/types';

import { SelectValue } from './types';

export const defaultOptionStringifier = (option: SelectOption<SelectValue>) => {
  const { label, value } = option;

  if (typeof label === 'string') {
    return label;
  }

  if (typeof value === 'string') {
    return value;
  }

  // Fallback string representation
  return String(option);
};

export const getOptionsFromChildren = (children: any): SelectOption<SelectValue>[] => {
  if (children.every(isValidElement)) {
    return children.map((value: Record<string, any>) => {
      return {
        label: value.props.label || value.props.children,
        value: value.props.value,
        disabled: value.props.disabled,
      };
    });
  }

  return [];
};

export function defaultRenderSingleValue(selectedOption: SelectOption<SelectValue> | null) {
  return selectedOption?.label ?? '';
}

export const defaultFormValueProvider = (selectedOption: SelectOption<SelectValue> | null) => {
  if (selectedOption?.value === null || selectedOption === null) {
    return '';
  }

  if (typeof selectedOption.value === 'string' || typeof selectedOption.value === 'number') {
    return selectedOption.value;
  }

  return JSON.stringify(selectedOption.value);
};

export const popperModifiers: Partial<Modifier<any, any>>[] = [
  { name: 'offset', options: { offset: [0, 3] } },
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      // @ts-ignore
      const offsetWidth = state.elements.reference.offsetWidth;

      state.elements.popper.style.minWidth = `${offsetWidth}px`;
    },
  },
];
