import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const ConnectYourWalletRoot = styled(Box, { name: 'ConnectYourWallet', slot: 'root' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    paddingTop: '20px',

    [theme.breakpoints.down('mobile')]: {
      padding: '30px 20px',
      justifyContent: 'flex-start',
      gap: '20px',
    },
  })
);

export const ConnectYourWalletPromoContainer = styled(Box, {
  name: 'ConnectYourWallet',
  slot: 'promoContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '0 25px',
  alignItems: 'center',
});

export const ConnectYourWalletPromoImage = styled('img', {
  name: 'ConnectYourWallet',
  slot: 'promoImage',
})({
  maxWidth: '280px',
  height: '259px',
});
