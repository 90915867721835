import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';

import { useMediaQuery, useSelector } from '~shared/lib/hooks';
import { leaderBoardActions } from '~widgets/leaderboard-list/model';

import { LeaderboardViewerRow } from './LeaderboardViewerRow/LeaderboardViewerRow';

import { LeaderboardTableRowProps } from './types';

export const LeaderboardTableRow: FC<LeaderboardTableRowProps> = ({ leaderboard }) => {
  const { nickname } = useSelector((state) => state.viewer);
  const dispatch = useDispatch();
  const { isMobile } = useMediaQuery();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      dispatch(leaderBoardActions.setShowLine(false));
    } else if (leaderboard.nickname === nickname && !inView) {
      dispatch(leaderBoardActions.setShowLine(true));
    }
  }, [inView, nickname, leaderboard.nickname, dispatch]);

  useEffect(() => {
    if (leaderboard.nickname === nickname) {
      dispatch(leaderBoardActions.setLeaderboard(leaderboard));
    }
  }, [nickname, leaderboard, dispatch]);

  return (
    <LeaderboardViewerRow
      leaderboard={leaderboard}
      isMobile={isMobile}
      ref={leaderboard.nickname === nickname ? ref : null}
    />
  );
};
