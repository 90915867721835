import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

import { Orientation } from './types';

export interface DividerRootProps {
  orientation?: Orientation;
  flexItem?: boolean;
}

export const UIKitDividerRoot = styled(Box, {
  name: 'Divider',
  shouldForwardProp: (propName: keyof DividerRootProps) => {
    switch (propName as keyof DividerRootProps) {
      case 'flexItem':
      case 'orientation':
        return false;
      default:
        return true;
    }
  },
})<DividerRootProps>(({ theme, orientation, flexItem }) => ({
  color: theme.palette.grey.A10,

  borderColor: 'currentcolor',
  borderStyle: 'solid',
  borderWidth: 0,

  borderBottomWidth: orientation === 'vertical' ? 0 : 1,
  borderRightWidth: orientation === 'horizontal' ? 0 : 1,
  height: orientation === 'vertical' ? (flexItem ? 'auto' : '100%') : 0,
  width: orientation === 'horizontal' ? (flexItem ? 'auto' : '100%') : 0,

  alignSelf: 'stretch',
  flexShrink: 0,
}));
