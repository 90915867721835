import { FC, useCallback, useEffect } from 'react';

import { Button, DialogTitle, Typography } from '~shared/ui';

import { useSwitcher, useTimer } from '~shared/lib/hooks';

import {
  TIMER_ESTIMATE_DELAY,
  TIMER_ESTIMATE_RECORD_KEY,
  getTimerEstimateRecordDelay,
} from '../../../lib';

import { useAuthModel } from '../../../model';

import { TextAlignCenter } from '../styled';

export const RecoveryLink: FC = () => {
  const { email, recoveryPassword, setStep } = useAuthModel();

  const { createTimer, timer } = useTimer({ delay: TIMER_ESTIMATE_DELAY });

  const submitting = useSwitcher(false);

  const handleClick = useCallback(() => {
    submitting.switchOn();

    recoveryPassword({ email }).then(() => {
      createTimer(getTimerEstimateRecordDelay(TIMER_ESTIMATE_RECORD_KEY));
      submitting.switchOff();
    });
  }, [createTimer, email, recoveryPassword, submitting]);

  const handleReturnToLogin = () => {
    setStep((steps) => steps.Authentication);
  };

  useEffect(() => {
    createTimer(getTimerEstimateRecordDelay(TIMER_ESTIMATE_RECORD_KEY));
  }, [createTimer]);

  return (
    <TextAlignCenter>
      <DialogTitle>Recovery link</DialogTitle>

      <Typography padding={10 / 8} color="secondary" mb={24 / 8}>
        We have sent a link to reset your password to your email address <strong>{email}</strong>.
        If the link didn't come, try checking your Spam folder or request the link again.
      </Typography>

      {timer ? (
        <Typography fontWeight={500} color="grey.A30" textAlign="center">
          Request link ... in {timer} sec
        </Typography>
      ) : (
        <Button onClick={handleClick} disabled={submitting.value} variant="text" fullWidth>
          Re-send a recovery link
        </Button>
      )}

      <Button onClick={handleReturnToLogin} variant="text" mt={24 / 8}>
        Return to login
      </Button>
    </TextAlignCenter>
  );
};
