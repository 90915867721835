import { FC, Fragment } from 'react';

import {
  MarketplaceItem,
  MarketplaceItemType,
  useMarketplaceItemsQuery,
} from '~entities/marketplace';

import {
  MarketplaceItemMysteryBox,
  MarketplaceItemNft,
  MarketplaceItemShimmer,
  MarketplaceItemUnfreeze,
} from './items';

import { MarketplaceBestAuction } from './MarketplaceBestAuction';

import { MarketplaceListGrid } from './styled';

export const MarketplaceList: FC = () => {
  const { data: marketplaceItems = [], isLoading } = useMarketplaceItemsQuery();

  const renderMarketplaceItem = (item: MarketplaceItem) => {
    switch (item.type) {
      case MarketplaceItemType.Nft:
        return <MarketplaceItemNft key={`${item.type}__${item.rarity}`} marketplaceNft={item} />;

      case MarketplaceItemType.MysteryBox:
        return (
          <MarketplaceItemMysteryBox
            key={`${item.type}__${item.rarity}`}
            marketplaceMysteryBox={item}
          />
        );

      case MarketplaceItemType.Unfreeze:
        return <MarketplaceItemUnfreeze key={item.type} marketplaceUnfreeze={item} />;
    }
  };

  return (
    <Fragment>
      {/* <MarketplaceListSettings /> */}

      <MarketplaceBestAuction />

      <MarketplaceListGrid>
        {isLoading ? itemsShimmer : marketplaceItems.map(renderMarketplaceItem)}
      </MarketplaceListGrid>
    </Fragment>
  );
};

const itemsShimmer = Array.from(Array(12).keys()).map((key) => (
  <MarketplaceItemShimmer key={key} />
));
