import { createElement, useCallback } from 'react';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';

import { NotificationConstructor } from '../ui/public/NotificationConstructor';

import { useNotificationsSelector } from './selectors';
import { notificationsActions } from './slice';
import { NotificationsService } from './service';
import { NotificationEvent } from './types';

export const useNotificationsModel = () => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const { service, notifications } = useNotificationsSelector();
  const { id: userId } = useViewerModel();

  const handleNotification = (notification: NotificationEvent) => {
    openSnackbar({
      element: createElement(NotificationConstructor, { place: 'snackbar', notification }),
    });
  };

  const handleNotifications = (notifications: NotificationEvent[]) => {
    dispatch(notificationsActions.updateNotifications(notifications));
  };

  const init = useCallback(async () => {
    service?.close();

    if (userId) {
      const service = new NotificationsService({
        userId,
        handleNotification,
        handleNotifications,
      });

      await service.init();

      dispatch(notificationsActions.setService(service));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const newNotifications = notifications.filter((notification) => !notification.read);
  const readNotifications = notifications.filter((notification) => notification.read);

  const notificationsCount = notifications.length;
  const newNotificationsCount = newNotifications.length;
  const readNotificationsCount = readNotifications.length;

  return {
    service,
    init,

    notifications,
    newNotifications,
    readNotifications,

    newNotificationsCount,
    readNotificationsCount,
    notificationsCount,
  };
};
