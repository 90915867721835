import { useQuery } from 'react-query';

import { ApiCommonLeague, ApiCommonSport, getBets } from '~shared/api';
import { useViewerSelector } from '~entities/viewer';
import { useProfileModel } from '~entities/profile';

interface UseProfileBattlesQueryParams {
  sport?: Array<ApiCommonSport>;
  league?: ApiCommonLeague;
}

export const useProfileBattlesQuery = ({ sport, league }: UseProfileBattlesQueryParams = {}) => {
  const { wallet: address } = useViewerSelector();
  const { profileBattlesQueryKey } = useProfileModel();

  const { data, isLoading } = useQuery(
    [profileBattlesQueryKey, ...(sport ?? []), league],
    () =>
      getBets({ address, sport, league }).then((bets) =>
        bets.filter((bet) => bet.isTakenBeforeMatchStarted !== 1)
      ),
    { enabled: address.length > 0 }
  );

  return {
    isLoading: isLoading,
    isEmptyList: (isLoading ? false : data?.length === 0) || address.length === 0,
    list: data,
  };
};
