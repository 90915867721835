import { ApiCommonLeagueEnum, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from '../types';

export const TennisLeaguesConfig: { [key in ApiCommonLeagueEnum]?: SportItemConfig } = {
  [ApiCommonLeagueEnum.Legends]: {
    id: 'tennis-legends',
    label: 'Legends',
    icon: 'illustration-tennis-legends',
    illustration: '',
    route: routes.sports.legends,
    sport: ApiCommonSportEnum.Tennis,
    league: ApiCommonLeagueEnum.Legends,
    dates: null,
    tournament: null,
  },
  [ApiCommonLeagueEnum.WTA125K]: {
    id: 'tennis-wta-125k',
    label: 'WTA 125K',
    icon: 'illustration-tennis-wta-125k',
    illustration: '',
    route: routes.sports.wta125K,
    sport: ApiCommonSportEnum.Tennis,
    league: ApiCommonLeagueEnum.WTA125K,
    dates: null,
    tournament: null,
  },
  [ApiCommonLeagueEnum.WTA]: {
    id: 'tennis-wta',
    label: 'WTA',
    icon: 'illustration-tennis-wta',
    illustration: '',
    route: routes.sports.wta,
    sport: ApiCommonSportEnum.Tennis,
    league: ApiCommonLeagueEnum.WTA,
    dates: null,
    tournament: null,
  },
  [ApiCommonLeagueEnum.ATP]: {
    id: 'tennis-atp',
    label: 'ATP',
    icon: 'illustration-tennis-atp',
    illustration: '',
    route: routes.sports.atp,
    sport: ApiCommonSportEnum.Tennis,
    league: ApiCommonLeagueEnum.ATP,
    dates: null,
    tournament: null,
  },
};
