export type NftCardSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface NftCardSizeValue {
  height: string;
  width: string;
}

export const NFT_CARD_SIZE_VALUES: Record<NftCardSize, NftCardSizeValue> = {
  xs: {
    height: '45px',
    width: '32px',
  },
  sm: {
    height: '76px',
    width: '52px',
  },
  md: {
    height: '106px',
    width: '72px',
  },
  lg: {
    height: '135px',
    width: '92px',
  },
  xl: {
    height: '150px',
    width: '102px',
  },
};
