import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

interface UseControlledProps<T> {
  controlled?: T;
  default: T;
}

interface UseControlled<T> {
  (props: UseControlledProps<T>): [T, Dispatch<SetStateAction<T>>];
}

export const useControlled = <T>({
  controlled,
  default: defaultProp,
}: UseControlledProps<T>): ReturnType<UseControlled<T>> => {
  const { current: isControlled } = useRef(controlled !== undefined);

  const [valueState, setValue] = useState<T>(defaultProp);
  const value = isControlled && controlled !== undefined ? controlled : valueState;

  const setValueIfUncontrolled = useCallback(
    (newValue: SetStateAction<T>) => {
      if (!isControlled) {
        setValue(newValue);
      }
    },
    [isControlled]
  );

  return [value, setValueIfUncontrolled];
};
