import { CSSProperties, RefObject, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { sleep } from '~shared/lib/utils';

export interface UseTabIndicatorReturn {
  position: CSSProperties;
  onChange: ChangeHandler;
}

type ChangeHandler = (event: SyntheticEvent | null, value: number | string | null) => void;

type UseTabIndicator = {
  (props: {
    ref: RefObject<HTMLElement>;
    selector: string;
    onChange?: ChangeHandler;
    value?: any;
  }): UseTabIndicatorReturn;
};

export const useTabIndicator: UseTabIndicator = ({ ref, selector, onChange, value }) => {
  const [position, setPosition] = useState<CSSProperties>({});
  const [valueHandler, setValueHandler] = useState<any>();

  useEffect(() => {
    // todo: maybe better solution
    sleep(50).then(() => {
      if (ref.current) {
        const selectedTab = ref.current.querySelector(selector) as HTMLSpanElement;

        if (selectedTab) {
          setPosition({
            width: selectedTab.clientWidth,
            transform: `translateX(${selectedTab.offsetLeft}px)`,
          });
        }
      }
    });
  }, [ref, selector, valueHandler, value]);

  const handleChange: ChangeHandler = useCallback(
    (e, value) => {
      if (onChange) {
        onChange(e, value);
      }

      setValueHandler(value);
    },
    [onChange]
  );

  return { onChange: handleChange, position };
};
