import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LinearProgress, Stack } from '~shared/ui';

// todo: fix FSD `features -> entities` import collision
import { useAuctionAcceptBetTimer } from '~features/auction';

import { useMediaQuery } from '~shared/lib/hooks';

import { NftCardInfoStatusAuctionText } from './styled';
import { NftCardInfoStatusAuctionProps } from './types';

export const NftCardInfoStatusAuction: FC<NftCardInfoStatusAuctionProps> = ({ relatedAuction }) => {
  const { t } = useTranslation();

  const { isMobile } = useMediaQuery();
  const { hoursLeft, minutesLeft, progress } = useAuctionAcceptBetTimer(
    relatedAuction.betsAcceptedUntil
  );

  return (
    <Stack spacing={6 / 8} alignItems={isMobile ? 'center' : 'flex-start'} mt="6px">
      <NftCardInfoStatusAuctionText>
        {t('Events.BlockedAuction')} {hoursLeft}hr:{minutesLeft}min
      </NftCardInfoStatusAuctionText>
      <LinearProgress progress={progress} progressColor="primary" height={4} />
    </Stack>
  );
};
