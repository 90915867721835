import { useMemo } from 'react';

import { NftRarity } from '~shared/api';

import { MarketplaceItemType, useMarketplaceItemsQuery } from '~entities/marketplace';

import {
  CurrencyOption,
  CurrencyOptionMatic,
  CurrencyOptionPayPal,
  MarketplaceCurrencyValue,
  getCurrencyLabel,
} from '../../lib';

import { useMarketplaceItemsUSDInfoQuery } from './useMarketplaceItemsUSDInfoQuery';

interface UseMarketplaceItemPaymentInfo {
  (params: { rarity: NftRarity; type: MarketplaceItemType; currency: MarketplaceCurrencyValue }): {
    currencyOptions: Array<CurrencyOption>;
    currencyChangeAvailable: boolean;

    currentCurrencyItemInfo: { price: number; label: string; id: any };
  };
}

export const useMarketplaceItemPaymentInfo: UseMarketplaceItemPaymentInfo = ({
  rarity,
  type,
  currency,
}) => {
  const itemsUSDInfoQuery = useMarketplaceItemsUSDInfoQuery();
  const itemsQuery = useMarketplaceItemsQuery();

  const foundUSDItem = useMemo(() => {
    if (itemsUSDInfoQuery.isLoading) {
      return null;
    }

    if (itemsUSDInfoQuery.data) {
      return itemsUSDInfoQuery.data
        .filter((item) =>
          item.isMystery
            ? type === MarketplaceItemType.MysteryBox
            : type === MarketplaceItemType.Nft
        )
        .find((item) => {
          return item.rarity === rarity;
        });
    }

    return null;
  }, [itemsUSDInfoQuery.data, itemsUSDInfoQuery.isLoading, rarity, type]);

  const foundMaticItem = useMemo(() => {
    if (itemsQuery.isLoading) {
      return null;
    }

    if (itemsQuery.data) {
      return itemsQuery.data.find((item) => {
        switch (item.type) {
          case MarketplaceItemType.Nft:
          case MarketplaceItemType.MysteryBox:
            return item.type === type && item.rarity === rarity;
          default:
          case MarketplaceItemType.Unfreeze:
            return false;
        }
      });
    }

    return null;
  }, [itemsQuery.data, itemsQuery.isLoading, rarity, type]);

  const currencyInfoMap = {
    [MarketplaceCurrencyValue.USD]: {
      id: foundUSDItem?.id ?? null,
      price: Number(foundUSDItem?.priceUsd ?? 0),
      label: getCurrencyLabel(MarketplaceCurrencyValue.USD),
    },

    [MarketplaceCurrencyValue.Matic]: {
      id: null,
      price: foundMaticItem?.price ?? 0,
      label: getCurrencyLabel(MarketplaceCurrencyValue.Matic),
    },
  };

  if (foundUSDItem) {
    return {
      currencyOptions: [CurrencyOptionMatic, CurrencyOptionPayPal],
      currencyChangeAvailable: true,

      currentCurrencyItemInfo: currencyInfoMap[currency],
    };
  }

  return {
    currencyOptions: [CurrencyOptionMatic],
    currencyChangeAvailable: false,

    currentCurrencyItemInfo: currencyInfoMap[currency],
  };
};
