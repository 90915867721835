import { FC, Fragment } from 'react';

import { Divider, Stack } from '~shared/ui';

import { TournamentPrizesProps } from './types';
import { CardPrize, CardPrizeDescription } from './styled';

export const TournamentPrizes: FC<TournamentPrizesProps> = ({ prizes }) => {
  const prizePlaceLabel = ['1st place', '2st place', '3st place'];

  return (
    <Stack spacing={24 / 8} direction="row">
      {prizePlaceLabel.map((label, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && <Divider orientation="vertical" flexItem />}

          <Stack spacing={6 / 8}>
            <CardPrizeDescription>{label}</CardPrizeDescription>
            <CardPrize>
              {prizes[idx].value} {prizes[idx].currency}
            </CardPrize>
          </Stack>
        </Fragment>
      ))}
    </Stack>
  );
};
