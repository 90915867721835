import { useState } from 'react';

import { SelectValue } from '~shared/ui';

import { BattlesDashboardPeriod } from './types';

export const useDashboardBattlesModel = () => {
  const [period, setPeriod] = useState<BattlesDashboardPeriod>('all');

  const handleChangePeriod: any = (
    _: MouseEvent | KeyboardEvent | FocusEvent | null,
    value: SelectValue | null
  ) => {
    setPeriod(value as any);
  };

  return {
    period,
    handleChangePeriod,
  };
};
