import { Route } from 'react-router-dom';

import { SportItemConfig } from '~shared/config/sport';
import { Container, Stack } from '~shared/ui';
import { ApiCommonSport } from '~shared/api';

import { Announcements } from '~widgets/announcements';
import { CommonEventsList } from '~widgets/common-events-list';

export const leaguePageFabric = (leagues: SportItemConfig[], sport: ApiCommonSport) => {
  return leagues.map((league) => (
    <Route
      key={league.route}
      path={league.route}
      element={
        <Stack spacing={12 / 8} flex={1}>
          <Announcements />

          {/* todo: common realization for fill available space */}
          <Container flex={1} display="flex" flexDirection="column">
            <CommonEventsList sport={sport} league={league.league!} label={league.label} />
          </Container>
        </Stack>
      }
    />
  ));
};
