import { styled } from '~shared/lib/styles';

export const DisconnectWalletDialogIllustration = styled('img', {
  name: 'DisconnectWallet',
  slot: 'illustration',
})(({ theme }) => ({
  width: '100%',
  height: 128,

  [theme.breakpoints.down('xs')]: {
    height: 92,
  },
}));
