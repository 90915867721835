import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Nft } from '~shared/api';

import { NftTabRarity } from './types';

interface UserCardsState {
  selectedCards: Map<string, Nft>;
  rarityTab: NftTabRarity;
}

const initialState: UserCardsState = {
  selectedCards: new Map(),
  rarityTab: NftTabRarity.All,
};

export const userCardsSlice = createSlice({
  name: 'userCards',
  initialState,
  reducers: {
    selectRarityTab: (state, action: PayloadAction<NftTabRarity>) => {
      state.rarityTab = action.payload;
    },
    toggleSelectedCard: (state, action: PayloadAction<Nft>) => {
      const nft = action.payload;

      state.selectedCards.has(nft.token_id)
        ? state.selectedCards.delete(nft.token_id)
        : state.selectedCards.set(nft.token_id, nft);
    },
    clearSelectedCards: (state) => {
      state.selectedCards.clear();
    },
  },
});
