import { Swiper, SwiperSlide } from 'swiper/react';

import { Box, Link } from '~shared/ui';
import { styled } from '~shared/lib/styles';

export const HorizontalListRoot = styled(Box, { name: 'HorizontalList' })(() => ({
  display: 'flex',
  alignItems: 'stretch',
}));

export const HorizontalListPill = styled('div', { name: 'HorizontalList', slot: 'pill' })(
  ({ theme }) => ({
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    position: 'relative',
    zIndex: 2,

    textAlign: 'center',
    padding: '0 6px',
    borderRadius: 12,
    background: '#242424', // theme.palette.grey.A10,
    marginRight: 16,

    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1,

    [theme.breakpoints.down('sm')]: {
      marginRight: 12,
    },
  })
);

interface HorizontalListSwiperProps {
  $zIndex?: number;
}

export const HorizontalListSwiper = styled(Swiper)<HorizontalListSwiperProps>(
  ({ theme, $zIndex = 1 }) => ({
    ['&.swiper']: {
      width: '100%',

      // Space for shadow when drag card on Battle
      marginTop: -14,
      marginBottom: -14,
      marginLeft: -6,

      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 6,

      // Smooth edge shadow when Battle appears partially
      borderTopLeftRadius: 24,
      borderBottomLeftRadius: 24,

      // Repeat <Container /> padding for scroll
      paddingRight: 30,

      zIndex: $zIndex,

      [theme.breakpoints.down('mobile')]: {
        paddingRight: 16,
      },
    },
  })
);

export const HorizontalListSlide = styled(SwiperSlide)(() => ({
  ['&.swiper-slide']: {
    width: 'max-content',
  },
}));

export const HorizontalListSlideNavigation = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  background: theme.palette.paper.dark,
  borderRadius: 12,

  padding: 24,

  height: '100%',
  width: '100%',
  gap: 10,

  textAlign: 'center',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  transition: theme.transitions.create('background'),

  ['&:hover']: {
    background: theme.palette.paper.semi,
  },

  fontSize: 14,
  lineHeight: 1,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 12,
    lineHeight: 14 / 12,
  },

  [theme.breakpoints.down('desktop')]: {
    fontSize: 13,
    lineHeight: 14 / 13,
  },
}));

HorizontalListSlide.displayName = 'SwiperSlide';
