import { FC, forwardRef } from 'react';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';

import { PaperProps } from './types';
import { UIKitPaper } from './styled';

export const Paper: FC<PaperProps> = forwardRef<HTMLDivElement, PaperProps>(
  ({ component, ...props }, ref) => {
    return <UIKitPaper as={component} {...props} {...extendSxProp(props as {})} ref={ref} />;
  }
);
