import { FC } from 'react';
import { useTheme } from '@mui/system';

import { useDispatch, useMediaQuery } from '~shared/lib/hooks';
import { MobileDialog } from '~shared/ui';
import { Theme } from '~shared/lib/styles';

import { nftActions, useNftSelector } from '~entities/nft';

import { NftCardDetailedInfoContent } from './NftCardDetailedInfoContent';

import { NftCardDetailedInfoDialog } from './styled';
import { NftCardDetailedInfoProps } from './types';

export const NftCardDetailedInfo: FC<NftCardDetailedInfoProps> = () => {
  // todo: own useTheme hook
  const theme = useTheme<Theme>();
  const { isMobile } = useMediaQuery();

  const {
    detailedInfo: { open },
  } = useNftSelector();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(nftActions.resetDetailedInfo());
  };

  const content = <NftCardDetailedInfoContent />;

  if (isMobile) {
    return (
      <MobileDialog open={open} sx={{ background: theme.palette.paper.semi }}>
        {content}
      </MobileDialog>
    );
  }

  return (
    <NftCardDetailedInfoDialog open={open} onClose={handleClose} width={780}>
      {content}
    </NftCardDetailedInfoDialog>
  );
};
