import { styled } from '~shared/lib/styles';

import { Chip } from '~shared/ui';

export const AuctionSellCardRoot = styled('div')(({ theme }) => ({
  minWidth: 260,
  background: theme.palette.paper.dark,
  borderRadius: 12,
  padding: 16,
  position: 'relative',
}));

export const AuctionSellCardBrief = styled('div')({
  textAlign: 'center',
});

export const AuctionSellCardProperties = styled('div')({
  margin: '20px 0',
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: 4,
});

export const AuctionSellCardPropertiesRow = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 13,
});

export const AuctionSellCardPropertiesLabel = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const AuctionSellCardPropertiesValue = styled('div')({
  fontWeight: 600,
});

export const AuctionSellCardBets = styled('div')({
  width: '100%',
  textAlign: 'center',
  padding: '12px 0px',
});

export const AuctionSellCardBet = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const AuctionBettorBrief = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledChip = styled(Chip, { name: 'NftLive', slot: 'chip' })({
  position: 'absolute',
  right: 8,
  top: 10,
  fontSize: '11px',
  lineHeight: '12px',
  padding: '7px',
});
