import { FC } from 'react';

import { Stack } from '~shared/ui';

import { useMediaQuery } from '~shared/lib/hooks';

import { PersonalDataAvatar } from './PersonalDataAvatar';
import { PersonalDataForm } from './PersonalDataForm';

export const PersonalData: FC = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Stack spacing={40 / 8} mt={isMobile ? 0 : '20px'}>
      <Stack direction={isMobile ? 'column-reverse' : 'row'} spacing={40 / 8}>
        <PersonalDataForm />
        <PersonalDataAvatar />
      </Stack>
    </Stack>
  );
};
