import { FC, Fragment, useContext } from 'react';
import { clsx } from 'clsx';

import { BattleShellContext } from '../../BattleShellContext';

import {
  BattleVersusBadge,
  BattleVersusContent,
  BattleVersusDivider,
  BattleVersusRoot,
  BattleVersusSlot,
  BattleVersusSlotUserAvatar,
  battleVersusClasses,
} from './styled';

import { BattleVersusProps } from './types';

export const BattleVersus: FC<BattleVersusProps> = ({ slotUserAvatar, slot, badge, score }) => {
  const { variant, size } = useContext(BattleShellContext);

  const computedBadge = badge ? (
    <BattleVersusBadge className={battleVersusClasses.badge}>{badge}</BattleVersusBadge>
  ) : (
    <span />
  );

  const computedSlotUserAvatar = slotUserAvatar ? (
    <BattleVersusSlotUserAvatar
      className={battleVersusClasses.slotUserAvatar}
      src={slotUserAvatar}
      size={24}
    />
  ) : null;

  const computedContent = (
    <BattleVersusContent className={battleVersusClasses.content}>
      {score ? score.join(':') : 'VS'}
    </BattleVersusContent>
  );

  const content = (() => {
    switch (variant) {
      case 'line':
      case 'square':
        return (
          <Fragment>
            {computedBadge}

            {slot ? (
              <BattleVersusSlot className={battleVersusClasses.slot}>
                {slot}
                {computedSlotUserAvatar}
              </BattleVersusSlot>
            ) : (
              computedContent
            )}
          </Fragment>
        );

      case 'compact':
        return (
          <Fragment>
            <BattleVersusDivider />
            {computedContent}
            {computedBadge}
            <BattleVersusDivider />

            <BattleVersusSlot className={battleVersusClasses.slot}>
              {computedSlotUserAvatar}
              {slot}
            </BattleVersusSlot>
          </Fragment>
        );
    }
  })();

  return (
    <BattleVersusRoot
      className={clsx(battleVersusClasses.root, {
        [battleVersusClasses.line]: variant === 'line',
        [battleVersusClasses.square]: variant === 'square',
        [battleVersusClasses.compact]: variant === 'compact',
        [battleVersusClasses.hasScore]: Boolean(score),
        [battleVersusClasses.sm]: size === 'sm',
      })}
    >
      {content}
    </BattleVersusRoot>
  );
};
