import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarEditor, Box, Heading, Icon, Tooltip, Typography } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';
import { useSnackbar } from '~shared/lib/hooks';

export const PersonalDataAvatar: FC = () => {
  const { t } = useTranslation();

  const {
    avatar,
    customAvatar,
    defaultAvatars,

    updateAvatar,
    postCustomAvatar,

    fetchProfile,
  } = useViewerModel();

  const { openSnackbar } = useSnackbar();

  const handleChangeAvatar = useCallback(
    (avatar: Blob | string, onUploadProgress?: (progress: number) => void) => {
      const callSuccessSnackbar = () => {
        openSnackbar({ message: 'Profile avatar set successfully', autoHideDelay: 3000 });
      };

      if (avatar instanceof Blob) {
        return postCustomAvatar(avatar, onUploadProgress)
          .then(fetchProfile)
          .then(callSuccessSnackbar);
      }

      return updateAvatar({ avatar }).then(fetchProfile).then(callSuccessSnackbar);
    },
    [fetchProfile, openSnackbar, postCustomAvatar, updateAvatar]
  );

  return (
    <Box>
      <Heading variant="h3" gutterBottom>
        {t('Account.change')}
        <Tooltip
          title={
            <Fragment>
              <Typography gutterBottom>Avatar rules</Typography>
              <Typography component="ul" color="secondary" fontSize={13} lineHeight={16 / 13}>
                <li>JPG or PNG</li>
                <li>{t('Account.size')}</li>
                <li>{t('Account.obscene')}</li>
              </Typography>
            </Fragment>
          }
        >
          <Icon ml={1} name="question" size={20} />
        </Tooltip>
      </Heading>

      <Box overflow="auto" m={-16 / 8}>
        <Box p={16 / 8}>
          <AvatarEditor
            customSrc={customAvatar?.src}
            customValue={customAvatar?.value}
            value={avatar?.value}
            onChange={handleChangeAvatar}
            defaults={defaultAvatars ?? []}
          />
        </Box>
      </Box>
    </Box>
  );
};
