import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CodeInput, DialogTitle, Stack, Typography } from '~shared/ui';
import { useSwitcher, useTimer } from '~shared/lib/hooks';

import { useAuthModel } from '~widgets/auth';

const RESEND_DELAY = process.env.NODE_ENV === 'development' ? 5 : 45;

export const VerificationCode: FC = () => {
  const { t } = useTranslation();

  const loading = useSwitcher(false);
  const resendDisabled = useSwitcher(false);
  const error = useSwitcher(false);
  const [authCode, setAuthCode] = useState('');
  const { timer, createTimer } = useTimer({ delay: RESEND_DELAY });

  const { verification, resendVerification, email, onClose: closeAuthConstructor } = useAuthModel();

  useEffect(() => {
    error.switchOff();

    if (authCode.length === 6) {
      loading.switchOn();

      verification({ authCode: Number(authCode), email }).then((valid) => {
        loading.switchOff();

        if (valid) {
          closeAuthConstructor();
        } else {
          error.switchOn();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, email, verification]);

  useEffect(() => {
    createTimer();
  }, [createTimer]);

  const handleResend = useCallback(() => {
    resendDisabled.switchOn();

    resendVerification({ email }).then(() => {
      createTimer();
      resendDisabled.switchOff();
    });
  }, [createTimer, email, resendDisabled, resendVerification]);

  return (
    <Fragment>
      <DialogTitle>{t('Other.verify')}</DialogTitle>

      <Stack spacing={24 / 8}>
        <Typography color="secondary" align="center">
          {t('Other.sent')} <strong>{email}</strong>. {t('Other.enterCode')}
        </Typography>

        <CodeInput
          value={authCode}
          onChange={setAuthCode}
          disabled={loading.value}
          error={error.value}
          autoFocus
        />

        {timer ? (
          <Typography fontWeight={500} color="grey.A30" textAlign="center">
            {t('Other.resendIn')} {timer} sec
          </Typography>
        ) : (
          <Button onClick={handleResend} variant="text" fullWidth disabled={resendDisabled.value}>
            {t('Other.resend')}
          </Button>
        )}
      </Stack>
    </Fragment>
  );
};
