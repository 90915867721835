import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const NftNewRoot = styled(Box, { name: 'NftNew', slot: 'root' })({
  position: 'absolute',
  width: 'calc(100% - 2px)',
  height: 'calc(100% - 2px)',
  borderRadius: '10px',
});

export const NftNewContainer = styled(Box, { name: 'NftNew', slot: 'container' })(({ theme }) => ({
  background: theme.palette.info.main,
  borderRadius: '38px',
  transform: 'rotate(26.93deg)',
  maxWidth: '49.98px',
  maxHeight: '24.7px',
  padding: '10px',
  position: 'absolute',
  right: '-10px',
  top: '-5px',
}));

export const NftNewText = styled(Typography, { name: 'NftNew', slot: 'text' })({
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '4px',
  textAlign: 'center',
});
