import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const UserCardsHeaderRoot = styled(Box, { name: 'UserCards', slot: 'root' })(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,

    [theme.breakpoints.down('mobile')]: {
      marginBottom: 4,
    },
  })
);

export const UserCardsHeaderUnfreezeContainer = styled(Box, {
  name: 'UserCardsHeader',
  slot: 'unfreezeContainer',
})({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, auto)',
  gap: 6,
  alignItems: 'center',
});

export const UserCardsHeaderAvailableUnfreezeAmount = styled(Typography, {
  name: 'UserCardsHeader',
  slot: 'availableUnfreezeAmount',
})({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: 21 / 13,
});
