import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';

import { Divider, Stack } from '~shared/ui';

import {
  DashboardBattlesTableCell,
  DashboardBattlesTableHeading,
  DashboardBattlesTableRoot,
} from './styled';

import { DashboardBattlesTableProps } from './types';

export const DashboardBattlesTable: FC<DashboardBattlesTableProps> = ({
  total,
  wins,
  lose,
  future,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('sm');

  const divider = !isMobile && <Divider orientation="vertical" />;

  return (
    <DashboardBattlesTableRoot>
      <Stack>
        <DashboardBattlesTableHeading>{t('Other.total')}</DashboardBattlesTableHeading>
        <DashboardBattlesTableCell>{total}</DashboardBattlesTableCell>
      </Stack>

      {divider}

      <Stack>
        <DashboardBattlesTableHeading>{t('Other.future')}</DashboardBattlesTableHeading>
        <DashboardBattlesTableCell>{future}</DashboardBattlesTableCell>
      </Stack>

      {divider}

      <Stack>
        <DashboardBattlesTableHeading>{t('Other.wins')}</DashboardBattlesTableHeading>
        <DashboardBattlesTableCell>{wins}</DashboardBattlesTableCell>
      </Stack>

      {divider}

      <Stack>
        <DashboardBattlesTableHeading>{t('Other.loses')}</DashboardBattlesTableHeading>
        <DashboardBattlesTableCell>{lose}</DashboardBattlesTableCell>
      </Stack>
    </DashboardBattlesTableRoot>
  );
};
