import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { StaticNftCard } from '~entities/nft';

import {
  Notification,
  NotificationCaption,
  NotificationIconFactory,
  NotificationMessage,
} from '../../../factory';

import { NotificationCardLivesChangedProps } from './types';

export const NotificationCardLivesChanged = forwardRef<
  HTMLDivElement,
  NotificationCardLivesChangedProps
>(({ notification, place }, ref) => {
  const { t } = useTranslation();

  return (
    <Notification
      ref={ref}
      date={notification.date}
      read={notification.read}
      place={place}
      icon={
        <NotificationIconFactory type="card-state" state="repair">
          <StaticNftCard tokenId={notification.payload.tokenId} />
        </NotificationIconFactory>
      }
    >
      <NotificationMessage>{t('Notifications.yourCardNeedsToBeRepaired')}</NotificationMessage>
      <NotificationCaption>{t('Notifications.repairThisCardAsSoonAsPossible')}</NotificationCaption>
    </Notification>
  );
});
