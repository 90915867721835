import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';

import { useSnackbar } from '~shared/lib/hooks';
import { NftRarity } from '~shared/api';

import { useGetBalance, useWriteContract } from '~entities/wallet';

import { checkIsEnoughMatic } from '../utils';

interface UseBuyCardForMaticProps {
  price: number;
  rarity: NftRarity;
}

export const useBuyCardForMatic = ({ price, rarity }: UseBuyCardForMaticProps) => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();

  const { account } = useWeb3React();
  const getBalance = useGetBalance();

  const { write: buyCard } = useWriteContract({
    contract: 'NFT',
    method: 'mint',
    transactionName: 'Minting card',
    successMessage: `${t('Alerts.successfulMint')}`,
    errorMessage: `${t('Errors.cardPurchaseFailed')}`,
  });

  const handleBuyForMatic = async (address: string) => {
    const priceInWei = ethers.utils.parseEther(String(price));

    const isEnoughMatic = await checkIsEnoughMatic(address, price);

    if (!isEnoughMatic) {
      openSnackbar({ type: 'error', message: `${t('Errors.dontHaveMatic')}` });

      return;
    }

    try {
      await buyCard({ args: [rarity], value: priceInWei });

      openSnackbar({
        type: 'info',
        message: `${t('Alerts.wait')}`,
      });
    } catch {}
  };

  const handleBuyCard = async () => {
    if (!account) {
      openSnackbar({ type: 'error', message: 'Connect your wallet' });

      return;
    }

    await handleBuyForMatic(account);
    await getBalance();
  };

  return {
    handleBuyCard,
  };
};
