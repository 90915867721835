import { FC } from 'react';

import { useOption } from '@mui/base';

import { Icon } from '~shared/ui';

import { SelectOptionProps } from './types';
import { UIKitOptionContent, UIKitSelectOptionRoot } from './styled';

export const SelectOption: FC<SelectOptionProps> = ({
  value,
  label,
  disabled = false,
  children,
  ...props
}) => {
  const { selected, rootRef, getRootProps } = useOption({ value, label, disabled });

  const optionProps = getRootProps();

  return (
    <UIKitSelectOptionRoot {...props} {...optionProps} ref={rootRef}>
      <UIKitOptionContent>{children}</UIKitOptionContent>

      {selected && <Icon name="check" size={20} />}
    </UIKitSelectOptionRoot>
  );
};
