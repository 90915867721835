import { ApiCommonSportEnum } from '~shared/api';

export const SportToLabelMap: Record<ApiCommonSportEnum, string> = {
  [ApiCommonSportEnum.AmericanFootball]: 'LeftSideBar.nfl',
  [ApiCommonSportEnum.Baseball]: 'LeftSideBar.baseball',
  [ApiCommonSportEnum.Basketball]: 'LeftSideBar.nba',
  [ApiCommonSportEnum.Cricket]: 'LeftSideBar.cricket',
  [ApiCommonSportEnum.Football]: 'LeftSideBar.football',
  [ApiCommonSportEnum.MMA]: 'LeftSideBar.mma',
  [ApiCommonSportEnum.Tennis]: 'LeftSideBar.tennis',
  [ApiCommonSportEnum.Hockey]: 'LeftSideBar.nhl',
  [ApiCommonSportEnum.CSGO]: 'CS GO',
  [ApiCommonSportEnum.Dota]: 'Dota 2',
  [ApiCommonSportEnum.LeagueOfLegends]: 'League of Legends',
};
