import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';

import { NftRarity } from '~shared/api';
import { useSnackbar } from '~shared/lib/hooks';

import { useGetBalance, useWriteContract } from '~entities/wallet';

import { checkIsEnoughMatic } from '../utils';

interface UseBuyMysteryBoxProps {
  price: number;
  rarity: NftRarity;
}

export const useBuyMysteryBoxForMatic = ({ price, rarity }: UseBuyMysteryBoxProps) => {
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { account } = useWeb3React();
  const getBalance = useGetBalance();

  const { write: openMysteryBox } = useWriteContract({
    contract: 'MagicBox',
    method: 'openBox',
    transactionName: 'Opening mystery box',
    successMessage: `${t('Alerts.successfulOpenBox')}`,
    errorMessage: `${t('Errors.openMysteryBoxFailed')}`,
  });

  const handleBuyMysteryBox = async () => {
    if (!account) {
      openSnackbar({ type: 'error', message: 'Connect your wallet' });

      return;
    }

    const isEnoughMatic = await checkIsEnoughMatic(account, price);

    if (!isEnoughMatic) {
      openSnackbar({ type: 'error', message: `${t('Errors.dontHaveMatic')}` });

      return;
    }

    const priceInWei = ethers.utils.parseEther(String(price));

    try {
      await openMysteryBox({ args: [rarity], value: priceInWei });

      openSnackbar({
        type: 'info',
        message: `${t('Alerts.wait')}`,
      });

      getBalance();
    } catch {}
  };

  return {
    handleBuyMysteryBox,
  };
};
