import { FC, useMemo } from 'react';

import { Backdrop, makeBackdropTransition } from '../Backdrop';

import { UIKitModal, UIKitModalRoot } from './styled';
import { ModalProps } from './types';

export const Modal: FC<ModalProps> = ({
  children,
  slots = {},
  disableTransition,
  backdropInitialTransition = true,
  ...props
}) => {
  const SlotBackdrop = useMemo(() => {
    const backdrop = slots?.backdrop ?? Backdrop;

    return disableTransition
      ? backdrop
      : makeBackdropTransition(backdrop, backdropInitialTransition);
  }, [backdropInitialTransition, disableTransition, slots?.backdrop]);

  return (
    <UIKitModal
      {...props}
      slots={{
        ...slots,
        backdrop: SlotBackdrop,
        root: slots?.root ?? UIKitModalRoot,
      }}
      closeAfterTransition
    >
      {children}
    </UIKitModal>
  );
};
