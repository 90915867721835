import { FC, Fragment, cloneElement } from 'react';

import { useTranslation } from 'react-i18next';

import { BattleResult } from '~shared/api';
import { useMediaQuery } from '~shared/lib/hooks';

import { isDrawSlotAvailable } from '~entities/battle';
import { useViewerModel } from '~entities/viewer';
import { useEventModel } from '~entities/event';

import { Typography } from '~shared/ui';

import { UserCaptionVote } from './UserCaptionVote';

import { Root, TeamCaption } from './styled';
import { EventUserCaptionsProps } from './types';

export const EventUserCaptions: FC<EventUserCaptionsProps> = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const { event, choice, isCall, isMakeCall, callCreator, callAcceptor } = useEventModel();
  const { nickname, avatar } = useViewerModel();

  if (!event) {
    return null;
  }

  const isDrawResultAvailable = isDrawSlotAvailable(event.sport);

  const blankCaption = (
    <TeamCaption align="right" justifySelf="right">
      {t('Other.ifNoOpponent')}
      <br />
      {!isMobile && 'You can’t remove card from this event'}
    </TeamCaption>
  );

  const getViewerCaption = (result: BattleResult | null) => {
    switch (result) {
      case BattleResult.Draw:
        return `${t('Other.draw')}`;
      case BattleResult.HomeTeamWon:
        return (
          <Fragment>
            <Typography component="span" color="grey.A50">
              {t('BattlePopUp.onlyWin')}
            </Typography>
            &nbsp;
            {event.homeTeam.players.map((player) => player.shortName).join(', ')}
          </Fragment>
        );
      case BattleResult.AwayTeamWon:
        return (
          <Fragment>
            <Typography component="span" color="grey.A50">
              {t('BattlePopUp.onlyWin')}
            </Typography>
            &nbsp;
            {event.awayTeam.players.map((player) => player.shortName).join(', ')}
          </Fragment>
        );
    }
  };

  if (!isCall && !isMakeCall) {
    if (!choice) {
      return (
        <Root>
          <TeamCaption align="left" justifySelf="start">
            {getViewerCaption(BattleResult.HomeTeamWon)}
          </TeamCaption>
          {isDrawResultAvailable && (
            <TeamCaption align="center" justifySelf="center">
              {getViewerCaption(BattleResult.Draw)}
            </TeamCaption>
          )}
          <TeamCaption align="right" justifySelf="end">
            {getViewerCaption(BattleResult.AwayTeamWon)}
          </TeamCaption>
        </Root>
      );
    }

    return (
      <Root>
        <UserCaptionVote
          avatar={avatar?.src}
          nickname={nickname}
          caption={<TeamCaption>{getViewerCaption(choice ?? null)}</TeamCaption>}
        />
      </Root>
    );
  }

  if (isMakeCall) {
    return (
      <Root>
        <UserCaptionVote
          avatar={avatar?.src}
          nickname={nickname}
          caption={<TeamCaption>{getViewerCaption(choice)}</TeamCaption>}
        />

        <UserCaptionVote caption={blankCaption} fromRight />
      </Root>
    );
  }

  if (isCall) {
    const participants = [
      <UserCaptionVote
        key="creator"
        avatar={callCreator?.avatar}
        nickname={callCreator?.nickname}
        caption={<TeamCaption>{getViewerCaption(callCreator?.choiceId ?? null)}</TeamCaption>}
      />,
      <UserCaptionVote
        key="acceptor"
        avatar={callAcceptor?.avatar}
        nickname={callAcceptor?.nickname}
        caption={
          <TeamCaption>
            {getViewerCaption(callAcceptor?.card ? callAcceptor.choiceId! : choice ?? null)}
          </TeamCaption>
        }
      />,
    ];

    if (
      callCreator?.choiceId === BattleResult.AwayTeamWon ||
      [choice, callAcceptor?.choiceId].includes(BattleResult.HomeTeamWon)
    ) {
      participants.reverse();
    }

    const left = participants[0];
    const right = cloneElement(participants[1], { fromRight: true });

    return (
      <Root>
        {left}
        {right}
      </Root>
    );
  }

  return null;
};
