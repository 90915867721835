import { useCreateSwitcher, useDispatch, useSnackbar } from '~shared/lib/hooks';
import { ApiPostFeedbackRequestData, postFeedback } from '~shared/api';

import { contactUsActions } from './store';
import { useContactUsSelector } from './selectors';

export const useContactUsModel = () => {
  const { isOpen } = useContactUsSelector();
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const contactUsDialog = useCreateSwitcher(isOpen, (value) => {
    dispatch(contactUsActions.setToggle(value));
  });

  const handlePostFeedback = (params: ApiPostFeedbackRequestData) => {
    return postFeedback(params)
      .then(() => {
        openSnackbar({ type: 'info', message: 'You have successfully sent feedback' });
      })
      .catch(() => {
        openSnackbar({ type: 'error', message: 'Unsuccessful attempt to send feedback' });
      })
      .then(contactUsDialog.switchOff);
  };

  return { isOpen, contactUsDialog, handlePostFeedback };
};
