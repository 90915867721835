import { useQuery } from 'react-query';

import { useViewerSelector } from '~entities/viewer';
import { getLeaderboardByWallet } from '~shared/api';

export const useLeaderboardByWalletQuery = (wallet?: string) => {
  const viewer = useViewerSelector();

  const walletAddress = wallet || viewer.wallet;

  const query = useQuery(
    ['leaderboardByWallet', walletAddress],
    () => {
      return getLeaderboardByWallet(walletAddress);
    },
    {
      enabled: !!walletAddress,
    }
  );

  return query;
};
