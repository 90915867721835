import { useQuery } from 'react-query';

import { ApiCommonLeague, ApiCommonSport, getBattles } from '~shared/api';

import { useSuppliedNftBattleList } from './useSuppliedNftBattleList';

export const useBattlesListQuery = ({
  sport,
  league,
}: {
  sport: Array<ApiCommonSport>;
  league?: ApiCommonLeague;
}) => {
  const { data, isLoading } = useQuery(
    ['battles', sport, league],
    () => getBattles({ sport, league }),
    {
      staleTime: STALE_TIME,
    }
  );

  const { auditionedList } = useSuppliedNftBattleList(data ?? []);

  return {
    list: auditionedList,
    isEmptyList: isLoading ? false : auditionedList?.length === 0,
    isLoading,
  };
};

const STALE_TIME = 5 * 60 * 1000;
