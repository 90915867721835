import { useEffect } from 'react';

import { useDispatch } from '~shared/lib/hooks';

import { useEventModel } from './model';
import { eventActions } from './slice';

export const useEventEffects = () => {
  const dispatch = useDispatch();

  const { cards, isMakeCall, onToggleMakeCall } = useEventModel();

  useEffect(() => {
    if (cards.length === 0) {
      dispatch(eventActions.set({ choice: null }));
    }
  }, [dispatch, cards.length]);

  useEffect(() => {
    if (cards.length > 1 && isMakeCall) {
      onToggleMakeCall();
    }
  }, [cards.length, isMakeCall, onToggleMakeCall]);
};
