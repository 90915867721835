import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const EventDragCellsContainer = styled(Box, {
  name: 'EventDialogContent',
  slot: 'eventDragCellsContainer',
})(({ theme }) => ({
  flex: 1,

  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(1px, 1fr))',
  alignItems: 'start',
  gap: 16,

  [theme.breakpoints.down('mobile')]: {
    gap: '20px',
  },
}));
