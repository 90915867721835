import { styled } from '~shared/lib/styles';
import { Typography } from '~shared/ui';

export const NftCardInfoStatusAuctionText = styled(Typography, {
  name: 'NftCardInfoStatusAuction',
  slot: 'text',
})(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 14 / 12,
  color: theme.palette.grey.A50,
}));
