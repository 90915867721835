import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~shared/ui';

import { useNftCardModel } from '~entities/nft';

import { useMergeModel } from '../model';

import { MergeCardsDialog, RepairBeforeMergeDialog, SuccessMergeDialog } from './dialogs';

export const MergeCards = () => {
  const { t } = useTranslation();

  const { isSelectionMode } = useNftCardModel();
  const { openMergeDialog, isMergeAvailable } = useMergeModel();

  if (!isMergeAvailable) {
    return null;
  }

  return (
    <Fragment>
      {!isSelectionMode && (
        <Button
          my={16 / 8}
          size="sm"
          color="success"
          alignSelf="flex-start"
          onClick={openMergeDialog}
        >
          {t('Other.mergeHead')}
        </Button>
      )}

      <MergeCardsDialog />
      <RepairBeforeMergeDialog />
      <SuccessMergeDialog />
    </Fragment>
  );
};
