import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseTextField } from '~shared/ui';

import { RepeatPasswordFieldProps } from './types';

export const RepeatPassword: FC<RepeatPasswordFieldProps> = ({
  name = 'repeatPassword',
  masterPasswordFieldName = 'password',
}) => {
  const { t } = useTranslation();

  const createPasswordValue = useWatch({ name: masterPasswordFieldName });

  return (
    <BaseTextField
      name={name}
      label={t('Other.repeatPassword')}
      type="password"
      required
      rules={{
        validate: (repeatPassword) =>
          repeatPassword === createPasswordValue || t('Other.passwordDoesntMatch'),
      }}
    />
  );
};
