import { styled } from '~shared/lib/styles';

import { avatarClasses } from '../../dataDisplay';
import { Box } from '../../layout';

export const avatarActionClasses = {
  root: 'AvatarAction-root',
  editWrapper: 'AvatarAction-editWrapper',

  edit: 'AvatarAction-edit',
  selected: 'AvatarAction-selected',
  selectable: 'AvatarAction-selectable',
  interactive: 'AvatarAction-interactive',
  clickable: 'AvatarAction-clickable',
};

export const UIKitAvatarActionRoot = styled(Box, { name: 'AvatarAction' })(({ theme }) => ({
  position: 'relative',

  ['&::before']: {
    content: '""',
    inset: -4,
    position: 'absolute',
    borderRadius: '100%',
    transition: theme.transitions.create(['box-shadow']),
  },

  [`&.${avatarActionClasses.clickable}`]: {
    cursor: 'pointer',
  },

  [`&.${avatarActionClasses.selectable}:hover::before`]: {
    cursor: 'pointer',
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  },

  [`&.${avatarActionClasses.selected}::before`]: {
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  },

  [`&.${avatarActionClasses.interactive} .${avatarClasses.root}`]: {
    cursor: 'pointer',
    transition: theme.transitions.create(['background', 'color']),

    ['&:hover']: {
      background: theme.palette.primary.main,
      color: theme.palette.text.primary,

      [`&.${avatarActionClasses.edit}`]: {
        background: theme.palette.paper.main,
      },
    },
  },

  [`&.${avatarActionClasses.edit}:hover`]: {
    background: theme.palette.paper.main,

    [`&.${avatarActionClasses.interactive}`]: {
      background: theme.palette.paper.main,
    },

    [`& .${avatarClasses.img}`]: {
      opacity: 0.1,
    },

    [`& .${avatarActionClasses.editWrapper}`]: {
      opacity: 1,
    },
  },
}));

export const UIKitAvatarEditWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  pointerEvents: 'none',

  transition: theme.transitions.create('opacity'),
  opacity: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
