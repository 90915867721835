import { CSSObject } from '@mui/system';
import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';
import { Badge, badgeClasses } from '~shared/ui';

export const notificationClasses = {
  root: 'Notification-root',

  list: 'Notification-list',
  snackbar: 'Notification-snackbar',
};

export const NotificationRoot = styled(motion.div)(({ theme, onClick }) => {
  const actionStyles: CSSObject = {
    cursor: 'pointer',
    transition: theme.transitions.create(['background']),

    ['&:hover']: {
      background: theme.palette.grey.A5,
    },
  };

  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    position: 'relative',
    padding: 8,
    borderRadius: 8,

    [`& .${badgeClasses.root}`]: {
      position: 'absolute',
      top: 4,
      right: 4,
    },

    [`&.${notificationClasses.snackbar}`]: {
      padding: 8 + 10,
      background: theme.palette.paper.grey,
      boxShadow: '0px 0px 12px 2px rgb(0 0 0 / 60%)',

      [`& .${badgeClasses.root}`]: {
        position: 'absolute',
        top: 8,
        right: 8,
      },
    },

    ...(onClick ? actionStyles : null),
  };
});

export const NotificationBadge = styled(Badge)({
  [`&.${badgeClasses.dot} .${badgeClasses.badge}`]: {
    width: 7,
    height: 7,
    borderWidth: 1,
  },
});

export const NotificationContent = styled('div')({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: 4,

  alignSelf: 'stretch',
  alignContent: 'center',
  marginLeft: 10,
  flexGrow: 1,
});

export const NotificationTime = styled('div')(({ theme }) => ({
  flexShrink: 0,
  fontSize: 11,
  fontWeight: 400,
  lineHeight: 16 / 11,
  color: theme.palette.grey.A50,
  marginLeft: 10,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: 40,
  height: 40,
}));
