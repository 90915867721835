import { FC, forwardRef } from 'react';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';
import { clsx } from 'clsx';

import { UIKitContainer, containerClasses } from './styled';
import { ContainerProps } from './types';

export const Container: FC<ContainerProps> = forwardRef<HTMLDivElement, ContainerProps>(
  ({ negative, className, ...props }, ref) => {
    return (
      <UIKitContainer
        maxWidth={false}
        className={clsx(
          containerClasses.root,
          { [containerClasses.negative]: negative },
          className
        )}
        {...extendSxProp(props as {})}
        ref={ref}
      />
    );
  }
);
