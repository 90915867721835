import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DeleteAccountButton } from '~features/viewer/delete-account';

import { SignOutButtonWrapper } from '~features/viewer/sign-out';
import { useMediaQuery } from '~shared/lib/hooks';

import { BaseTextField, Button, NicknameField, Stack } from '~shared/ui';

import { usePersonalDataForm } from '../../../../model';

import { ButtonsContainer } from './styled';

export const PersonalDataForm: FC = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const { handleSubmit, isSubmitAvailable, currentNickname, form } = usePersonalDataForm();

  return (
    <FormProvider {...form}>
      <Stack
        spacing={20 / 8}
        component="form"
        alignItems="start"
        width={isMobile ? 'initial' : 320}
        onSubmit={handleSubmit}
      >
        <NicknameField
          name="nickname"
          label={t('Account.nickname')}
          currentNickname={currentNickname}
          required
        />

        <BaseTextField name="email" label="Email" disabled required />

        {isMobile && <DeleteAccountButton />}

        <ButtonsContainer>
          <Button
            width="max-content"
            type="submit"
            fullWidth={isMobile}
            disabled={!isSubmitAvailable}
          >
            {t('Account.save')}
          </Button>

          <SignOutButtonWrapper>
            <Button fullWidth={isMobile} />
          </SignOutButtonWrapper>
        </ButtonsContainer>
      </Stack>
    </FormProvider>
  );
};
