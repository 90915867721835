import { ApiGetBattlesByIdsMappedData, ApiGetBattlesMappedData } from '~shared/api';

import { EVENT_STATISTIC_KEYS, EventStatisticKeys } from './config';

export interface EventStatistic {
  homeTeam: number;
  awayTeam: number;
}

export const getEventStatisticRowsData = (
  event: ApiGetBattlesMappedData | ApiGetBattlesByIdsMappedData
): Record<string, EventStatistic> => {
  const { homeTeam, awayTeam } = event;

  const statisticKeysSet = new Set<EventStatisticKeys>();

  Object.keys(homeTeam).forEach((teamObjectKey) => {
    if (EVENT_STATISTIC_KEYS.includes(teamObjectKey)) {
      statisticKeysSet.add(teamObjectKey as keyof typeof homeTeam);
    }
  });

  const statisticValuesMap = new Map<EventStatisticKeys, EventStatistic>();

  statisticKeysSet.forEach((key) => {
    statisticValuesMap.set(key, { homeTeam: homeTeam[key], awayTeam: awayTeam[key] });
  });

  return Object.fromEntries(statisticValuesMap);
};
