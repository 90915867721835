import { Box } from '@mui/system';
import { Tabs as MuiBaseTab, tabsClasses as muiBaseTabsClasses } from '@mui/base';

import { noScrollbar, styled } from '~shared/lib/styles';

import { menuTabClasses } from '../MenuTab';
import { menuTabsListClasses } from '../MenuTabsList';

export const menuTabsClasses = {
  ...muiBaseTabsClasses,
  default: 'MenuTabs-default',
  secondary: 'MenuTabs-secondary',
  sidebar: 'MenuTabs-sidebar',
  indicator: 'MenuTabs-indicator',
};

export const UIKitMenuTabs = styled(MuiBaseTab)({
  display: 'flex',
});

export const UIKitMenuTabsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignSelf: 'stretch',
  display: 'flex',
  position: 'relative',
  minHeight: 50,
  overflow: 'auto',

  ...noScrollbar,

  [`&.${menuTabsClasses.default}`]: {
    background: 'none',
  },
  [`&.${menuTabsClasses.secondary}`]: {
    background: theme.palette.paper.dark,
    padding: '0 10px',
  },
  [`&.${menuTabsClasses.sidebar}`]: {
    background: theme.palette.paper.blue,
    width: 80,

    [`& .${menuTabsClasses.indicator}`]: {
      display: 'none',
    },

    [`& .${menuTabsClasses.root}`]: {
      [`& .${menuTabsListClasses.root}`]: {
        flexDirection: 'column',
      },

      [`& .${menuTabClasses.root}`]: {
        flexDirection: 'column',
        paddingTop: 16,
        paddingLeft: 0,
        paddingRight: 0,
        margin: 0,
        height: 80,
        width: 80,

        [`&:hover:not(.${menuTabClasses.disabled}), &.${menuTabClasses.hovered}`]: {
          background: theme.palette.primary.light,

          [`& .${menuTabClasses.label}`]: {
            color: theme.palette.text.primary,
            fontWeight: 500,
          },
        },

        [`&.${menuTabClasses.selected}`]: {
          background: theme.palette.primary.main,

          [`& .${menuTabClasses.label}`]: {
            color: theme.palette.text.primary,
            fontWeight: 500,
          },
        },

        [`& .${menuTabClasses.label}`]: {
          marginTop: 8,
          color: theme.palette.grey.A50,

          fontSize: 12,
          fontWeight: 400,
          lineHeight: 14 / 12,
        },
        [`& .${menuTabClasses.icon}`]: {
          margin: 0,
          color: theme.palette.text.primary,
        },
      },
    },
  },
}));

export const MenuTabIndicator = styled('span')(({ theme }) => ({
  height: 3,
  background: theme.palette.primary.main,
  bottom: 0,
  left: 0,
  position: 'absolute',
  transition: theme.transitions.create('all', { duration: 250 }),
}));
