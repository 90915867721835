import { useTranslation } from 'react-i18next';

import { useConnect } from '~entities/wallet/model';
import { useViewerModel } from '~entities/viewer';

import { Button } from '~shared/ui';

import * as S from './ConnectWalletOptions.styled';

export const ConnectWalletOptions = () => {
  const { t } = useTranslation();

  const { openConnectWalletDialogAndOnboarding } = useConnect();
  const { openSignOut } = useViewerModel();

  const handleChangeWallet = async () => {
    try {
      await openSignOut();
    } catch {
      console.error('Failed to change wallet');
    }
  };

  return (
    <S.ConnectWalletOptionsRoot>
      <S.ConnectWalletOptionContainer>
        <S.ConnectWalletOptionTitle>{t('disconnectWallet.backToGame')}</S.ConnectWalletOptionTitle>
        <S.ConnectWalletOptionDescription>
          {t('disconnectWallet.cards')}
        </S.ConnectWalletOptionDescription>
        <Button fullWidth onClick={openConnectWalletDialogAndOnboarding}>
          {t('disconnectWallet.connect')}
        </Button>
      </S.ConnectWalletOptionContainer>
      <S.ConnectWalletOptionContainer>
        <S.ConnectWalletOptionTitle>{t('disconnectWallet.newWallet')}</S.ConnectWalletOptionTitle>
        <S.ConnectWalletOptionDescription>
          {t('disconnectWallet.register')}
        </S.ConnectWalletOptionDescription>
        <Button fullWidth onClick={handleChangeWallet}>
          {t('disconnectWallet.signUp')}
        </Button>
      </S.ConnectWalletOptionContainer>
    </S.ConnectWalletOptionsRoot>
  );
};
