import { styled } from '~shared/lib/styles';

export const SportsMenuIconFallbackRoot = styled('div')({
  width: 0,
  height: 36,
  fontSize: 16,
  lineHeight: 36 / 16,
  fontWeight: 600,
  textAlign: 'center',
});
