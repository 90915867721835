import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BaseTextField, Button, Dialog, Stack, Typography } from '~shared/ui';

import { useContactUsModel } from '../model';

import { TextAreaContactUs } from './styled';

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Wrong email format'),
  subject: yup.string().required('Subject is required'),
  text: yup.string().required('Message is required'),
});

export const ContactUs = () => {
  const { t } = useTranslation();

  const { contactUsDialog, handlePostFeedback } = useContactUsModel();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '', subject: '', text: '' },
  });

  return (
    <Dialog open={contactUsDialog.open} onClose={contactUsDialog.switchOff} width={440}>
      <Stack spacing={24 / 8}>
        <Typography variant="h1" textAlign="center">
          {t('ContactUs.provide')}
        </Typography>
        <Typography color="secondary" align="center">
          {t('ContactUs.text')}
        </Typography>
        <FormProvider {...form}>
          <Stack component="form" spacing={24 / 8} onSubmit={form.handleSubmit(handlePostFeedback)}>
            <BaseTextField name="subject" placeholder="Subject" />
            <BaseTextField name="email" placeholder="Email" />
            {/* todo: BaseTextField support textarea */}
            <TextAreaContactUs placeholder={t('ContactUs.start')} {...form.register('text')} />

            <Button type="submit">{t('ContactUs.send')}</Button>
          </Stack>
        </FormProvider>

        <Typography color="secondary" align="center">
          {/* Send us your questions or suggestions by email{' '}
          <Link to="mailto:support@maincard.io" color={(theme) => theme.palette.primary.main}>
            support@maincard.io
          </Link> */}
          {t('ContactUs.bottomText')}
        </Typography>
      </Stack>
    </Dialog>
  );
};
