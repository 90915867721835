import { FC, forwardRef } from 'react';

import { Box } from '~shared/ui';

import { UIKitPill, UIKitPillAppend, UIKitPillPrepend, pillClasses } from './styled';
import { PillProps } from './types';

export const Pill: FC<PillProps> = forwardRef<HTMLButtonElement, PillProps>(
  ({ prepend, append, children, ...props }, ref) => {
    return (
      <UIKitPill {...props} ref={ref}>
        {prepend && <UIKitPillPrepend className={pillClasses.prepend}>{prepend}</UIKitPillPrepend>}
        <Box>{children}</Box>
        {append && <UIKitPillAppend className={pillClasses.append}>{append}</UIKitPillAppend>}
      </UIKitPill>
    );
  }
);
