import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Dialog, Stack, Typography } from '~shared/ui';

import { useEventModel } from '~entities/event';

import { CallsDetailedBannerImage } from './styled';
import { EventDialogCallsBannerDetailedInfoProps } from './types';

export const EventDialogCallsBannerDetailedInfo: FC<
  EventDialogCallsBannerDetailedInfoProps
> = () => {
  const { bannerDetailedDialogInfo } = useEventModel();
  const { t } = useTranslation();

  return (
    <Dialog
      open={bannerDetailedDialogInfo.open}
      onClose={bannerDetailedDialogInfo.switchOff}
      width={440}
    >
      <Stack spacing={24 / 8} alignItems="center">
        <Typography variant="h1" textAlign="center">
          {t('TryCalls.title')}
        </Typography>

        <CallsDetailedBannerImage
          src={process.env.PUBLIC_URL + '/assets/event/calls/try-calls-detailed.png'}
          alt="Try calls"
        />

        <Typography color="secondary" textAlign="center">
          {t('TryCalls.upMainText')}
          <br />
          {t('TryCalls.centerText')}
        </Typography>
        <Typography color="secondary" textAlign="center">
          {t('TryCalls.bottomMainText')}
        </Typography>
      </Stack>
    </Dialog>
  );
};
