import { FC, Fragment } from 'react';

import { Button } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { JoinTournamentDialog } from '../JoinTournamentDialog';

import { JoinTournamentProps } from './types';

export const JoinTournamentButton: FC<JoinTournamentProps> = ({ price, ...buttonProps }) => {
  const open = useSwitcher(false);

  return (
    <Fragment>
      <Button onClickCapture={open.switchOn} {...buttonProps}>
        Join! for {price} matic
      </Button>

      <JoinTournamentDialog
        disableRestoreFocus
        cardsCount={4}
        open={open.value}
        onClose={open.switchOff}
      />
    </Fragment>
  );
};
