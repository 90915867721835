import { useWeb3React } from '@web3-react/core';

import { useIsAnyNftDialogOpen, useNftSelector } from '~entities/nft';
import { useViewerSelector } from '~entities/viewer';

export const useUserSidebarModel = () => {
  const isConnected = !!useWeb3React().account;
  const isAnyNftDialogOpen = useIsAnyNftDialogOpen();
  const { wallet } = useViewerSelector();
  const { isDropZoneOpen } = useNftSelector();

  const isModalOverlap = isAnyNftDialogOpen || (isDropZoneOpen && !isAnyNftDialogOpen);
  const isWalletBoundToEmail = Boolean(wallet);

  return {
    isConnected,
    isWalletBoundToEmail,
    isModalOverlap,
  };
};
