import { createContext } from 'react';

import { SnackbarContextValue } from './types';

const initialState: SnackbarContextValue = {
  closeSnackbar: () => undefined,
  openSnackbar: () => '',
  reset: () => undefined,
  snackbars: {},
};

export const SnackbarContext = createContext<SnackbarContextValue>(initialState);
