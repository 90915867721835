import { paramsSerializerWithArray } from '~shared/lib/utils';

import { apiInstance } from '../apiInstance';

import { mapGetAuctionResponse } from './mappers';
import {
  ApiGetAuctionCardRawData,
  ApiGetAuctionCards,
  ApiPostPlaceBidToAuction,
  ApiPostPlaceBidToAuctionResponseData,
  ApiPostPlaceCardToAuction,
  ApiPostPlaceCardToAuctionResponseData,
} from './types';
import { AuctionRoutes } from './routes';

export const getAuctionCards: ApiGetAuctionCards = (params?) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<Array<ApiGetAuctionCardRawData>>(AuctionRoutes.auction(), {
        params,
        paramsSerializer: paramsSerializerWithArray,
      })
      .then(({ data }) => resolve(mapGetAuctionResponse(data)))
      .catch(reject);
  });
};

export const postPlaceCardToAuction: ApiPostPlaceCardToAuction = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiPostPlaceCardToAuctionResponseData>(AuctionRoutes.placeCardToAuction(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postPlaceBidToAuction: ApiPostPlaceBidToAuction = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiPostPlaceBidToAuctionResponseData>(AuctionRoutes.placeBidToAuction(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
