import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const EventCard = styled(Box, { name: 'NftCardInfoRelatedEvent', slot: 'eventCard' })(
  ({ theme }) => ({
    padding: '12px',
    background: theme.palette.grey.A5,
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'grid',
    gap: '8px',
  })
);

export const LiveStatusContainer = styled(Box, {
  name: 'NftCardInfoRelatedEvent',
  slot: 'liveStatusContainer',
})(({ theme }) => ({
  background: theme.palette.violet.main,
  padding: '3px 10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  borderRadius: '4px',
  width: 'fit-content',
}));

export const LiveStatusText = styled(Typography, {
  name: 'NftCardInfoRelatedEvent',
  slot: 'liveStatusText',
})({
  fontSize: '10px',
  lineHeight: '12px',
  textTransform: 'uppercase',
});
