import { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useState } from 'react';

interface UseLoadedProps
  extends Pick<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'srcSet' | 'referrerPolicy' | 'crossOrigin'
  > {
  src: string | undefined | null;
}

interface UseLoaded {
  (props: UseLoadedProps): 'loaded' | 'error' | false;
}

export const useLoaded: UseLoaded = ({ src, srcSet, crossOrigin, referrerPolicy }) => {
  const [loaded, setLoaded] = useState<'loaded' | 'error' | false>(false);

  useEffect(() => {
    if (!src && !srcSet) {
      return undefined;
    }

    setLoaded(false);
    let active = true;
    const image = new Image();

    image.onload = () => {
      if (!active) {
        return;
      }

      setLoaded('loaded');
    };

    image.onerror = () => {
      if (!active) {
        return;
      }

      setLoaded('error');
    };

    image.crossOrigin = crossOrigin as typeof image.crossOrigin;
    image.referrerPolicy = referrerPolicy as typeof image.referrerPolicy;
    image.src = src as typeof image.src;

    if (srcSet) {
      image.srcset = srcSet;
    }

    return () => {
      active = false;
    };
  }, [crossOrigin, referrerPolicy, src, srcSet]);

  return loaded;
};
