import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LinearProgress, Stack } from '~shared/ui';

// todo: fix FSD `features -> entities` import collision
import { useAuctionAcceptBetTimer } from '~features/auction';

import { useMediaQuery } from '~shared/lib/hooks';

import { NftCardInfoStatusFrozenText } from './styled';
import { NftCardInfoStatusFrozenProps } from './types';

export const NftCardInfoStatusFrozen: FC<NftCardInfoStatusFrozenProps> = ({ frozenUntil }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const { hoursLeft, minutesLeft, progress } = useAuctionAcceptBetTimer(frozenUntil);

  return (
    <Stack spacing={6 / 8} alignItems={isMobile ? 'center' : 'flex-start'} mt="6px">
      <NftCardInfoStatusFrozenText>
        {t('Other.frozen')} {hoursLeft}hr:{minutesLeft}min
      </NftCardInfoStatusFrozenText>
      <LinearProgress progress={progress} progressColor="primary" height={4} />
    </Stack>
  );
};
