import { Route } from 'react-router-dom';

import { SportItemConfig } from '~shared/config/sport';
import { Container, Stack } from '~shared/ui';

import { Announcements } from '~widgets/announcements';
import { CommonEventsList } from '~widgets/common-events-list';

export const sportPageFabric = (sports: SportItemConfig[]) => {
  return sports.map((sport) => (
    <Route
      key={sport.route}
      path={sport.route}
      element={
        <Stack spacing={12 / 8} flex={1}>
          <Announcements />

          {/* todo: common realization for fill available space */}
          <Container flex={1} display="flex" flexDirection="column">
            <CommonEventsList sport={sport.sport} label={sport.label} />
          </Container>
        </Stack>
      }
    />
  ));
};
