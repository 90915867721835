import { FC, forwardRef } from 'react';
import { clsx } from 'clsx';

import { BadgeProps } from './types';
import { UIKitBadge, badgeClasses } from './styled';

export const Badge: FC<BadgeProps> = forwardRef(
  ({ className, color = 'error', variant = 'default', ...props }, ref) => {
    if (variant === 'dot') {
      props.badgeContent = null;
    }

    return (
      <UIKitBadge
        {...props}
        className={clsx(
          {
            [badgeClasses.error]: color === 'error',
            [badgeClasses.info]: color === 'info',

            [badgeClasses.default]: variant === 'default',
            [badgeClasses.dot]: variant === 'dot',
          },
          className
        )}
        ref={ref}
      />
    );
  }
);
