import { FC } from 'react';
import { clsx } from 'clsx';

import { NotificationIconPlateRoot, notificationIconPlateClasses } from './styled';

import { NotificationIconPlateProps } from './types';

export const NotificationIconPlate: FC<NotificationIconPlateProps> = ({
  color = 'paper',
  className,
  ...htmlAttributes
}) => {
  return (
    <NotificationIconPlateRoot
      className={clsx(
        notificationIconPlateClasses.root,
        {
          [notificationIconPlateClasses.paper]: color === 'paper',
          [notificationIconPlateClasses.primary]: color === 'primary',
          [notificationIconPlateClasses.error]: color === 'error',
          [notificationIconPlateClasses.violet]: color === 'violet',
        },
        className
      )}
      {...htmlAttributes}
    />
  );
};
