import { useCallback, useEffect, useRef, useState } from 'react';

type UseTimer = {
  (props: { delay: number }): { timer: number; createTimer: (customDelay?: number) => void };
  (): { timer: number; createTimer: () => void };
};

const DEFAULT_DELAY = 45;

export const useTimer: UseTimer = ({ delay } = { delay: DEFAULT_DELAY }) => {
  const interval = useRef<ReturnType<typeof setInterval>>();
  const [timer, setTimer] = useState<number>(delay);

  const createTimer = useCallback(
    (customDelay?: number) => {
      setTimer(customDelay || delay);
      clearInterval(interval.current);

      interval.current = setInterval(() => {
        setTimer((timer) => {
          if (timer) {
            return timer - 1;
          }

          clearInterval(interval.current);

          return 0;
        });
      }, 1000);
    },
    [delay]
  );

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return { timer, createTimer };
};
