import { configureStore } from '@reduxjs/toolkit';

import { notificationsReducer } from '~entities/notifications';
import { walletReducer } from '~entities/wallet';
import { viewerReducer } from '~entities/viewer';
import { eventReducer } from '~entities/event';
import { nftReducer } from '~entities/nft';

import { auctionReducer } from '~features/auction';
import { mergeReducer } from '~features/nft';

import { leaderBoardReducer } from '~widgets/leaderboard-list';
import { recoveryReducer } from '~widgets/password-recovery';
import { accountReducer } from '~widgets/profile-account';
import { contactUsReducer } from '~widgets/contact-us';
import { userCardsSlice } from '~widgets/user-cards';
import { authReducer } from '~widgets/auth';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    viewer: viewerReducer,
    wallet: walletReducer,
    nft: nftReducer,
    event: eventReducer,
    account: accountReducer,
    auction: auctionReducer,
    recovery: recoveryReducer,
    leaderBoard: leaderBoardReducer,
    contactUs: contactUsReducer,
    notifications: notificationsReducer,
    merge: mergeReducer,
    userCards: userCardsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
