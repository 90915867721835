import { NftCard } from '~entities/nft';
import { useWinstreakWarningDialog } from '~features/event-dialog/model';
import { palette } from '~shared/lib/styles/palette';

import { Box, Button, Dialog, DontShowWindowInFuture, Icon, Stack, Typography } from '~shared/ui';

import { WinstreakIllustrationContainer } from './styled';

export const FullWinstreakWarningDialog = () => {
  const { open, nft, betNft, closeWinstreakWarningDialog, handleChangeWinstreakDialogShown } =
    useWinstreakWarningDialog();

  if (!nft) {
    return null;
  }

  return (
    <Dialog
      title="Warning"
      open={open}
      backgroundColor={palette.paper.main}
      width={440}
      onClose={closeWinstreakWarningDialog}
    >
      <Stack spacing={24 / 8} alignItems="center">
        <Box sx={{ position: 'relative', marginBottom: '30px' }}>
          <NftCard nft={nft} isHighlighted highlightVariant="large" />
          <WinstreakIllustrationContainer>
            <Typography color="secondary">Win streak</Typography>
            <Icon name="illustration-winstreak" size={{ width: 50, height: 20 }} />
          </WinstreakIllustrationContainer>
        </Box>
        <Typography color="secondary">
          You bet a card with a full winstreak.
          <br />
          If you lose, the Winstreak will be lost.
          <br />
          Are you sure you want to bet a card?
        </Typography>
        <Stack direction="row" spacing={12 / 8}>
          <Button variant="outlined" onClick={closeWinstreakWarningDialog}>
            I changed my mind
          </Button>
          <Button onClick={betNft}>Bet card</Button>
        </Stack>
        <DontShowWindowInFuture onChange={handleChangeWinstreakDialogShown} />
      </Stack>
    </Dialog>
  );
};
