import { FC } from 'react';
import { clsx } from 'clsx';

import { ButtonIcon } from '../../controls';

import { Link } from '../../navigation';

import {
  UIKitHeadingNavigation,
  UIKitHeadingRoot,
  UIKitHeadingTypography,
  headingClasses,
} from './styled';

import { HeadingLayoutVariant, HeadingProps } from './types';

export const Heading: FC<HeadingProps> = ({
  layoutVariant = 'default',

  navigationOnClick,
  navigationPath,
  navigationProps = {},

  rootProps = {},

  variant = 'h2',
  ...props
}) => {
  const computedNavigationProps = navigationPath
    ? { ...navigationProps, as: Link, underline: false, to: navigationPath }
    : {};

  return (
    <UIKitHeadingRoot
      {...rootProps}
      className={clsx(
        resolveHeadingLayoutVariant(layoutVariant),
        headingClasses.root,
        props.className
      )}
    >
      {navigationPath || navigationOnClick ? (
        <UIKitHeadingNavigation
          {...computedNavigationProps}
          className={clsx(headingClasses.navigation, navigationProps?.className)}
        >
          <ButtonIcon onClick={navigationOnClick} variant="plain" name="arrow-left" size={24} />
        </UIKitHeadingNavigation>
      ) : null}

      <UIKitHeadingTypography
        {...props}
        className={clsx(headingClasses.typography)}
        variant={variant}
      />
    </UIKitHeadingRoot>
  );
};

const resolveHeadingLayoutVariant = (variant: HeadingLayoutVariant) => {
  switch (variant) {
    case 'section':
      return headingClasses.section;
  }
};
