import { customScrollbar, styled } from '~shared/lib/styles';
import { Dialog, dialogClasses } from '~shared/ui';
import { ApiCommonSport } from '~shared/api';
import { SportToAssetMaps } from '~shared/lib/assets';

interface TournamentDialogRootProps {
  $sport: ApiCommonSport;
}

export const TournamentDialogRoot = styled(Dialog)<TournamentDialogRootProps>(
  ({ $sport, theme }) => ({
    [`& .${dialogClasses.paper}`]: {
      backgroundColor: theme.palette.paper.black,
      backgroundImage: `url(${SportToAssetMaps[$sport]})`,
      backgroundPositionY: 'top',
      backgroundPositionX: 'center',
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
    },
  })
);

// todo: common mobile dialog
export const TournamentMobileDialogRoot = styled(Dialog)<TournamentDialogRootProps>(
  ({ $sport, theme }) => ({
    position: 'absolute',

    [`& .${dialogClasses.scrollPaper}`]: {
      display: 'block',
      overflowY: 'auto',

      ...customScrollbar,
    },

    [`& .${dialogClasses.paper}`]: {
      backgroundColor: theme.palette.paper.black,
      backgroundImage: `url(${SportToAssetMaps[$sport]})`,
      backgroundPositionY: 'top',
      backgroundPositionX: 'center',
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',

      // overrides
      borderRadius: 0,
      margin: 0,
      maxWidth: 'unset',
      maxHeight: 'unset',
    },

    [`& .${dialogClasses.content}`]: {
      padding: '20px 16px',
      flex: 1,
    },
  })
);

export const TournamentDescriptionContainer = styled('div')(() => ({
  paddingTop: 60,
}));

export const TournamentDescriptionCaption = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.text.secondary,
}));
