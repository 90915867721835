import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { ClickAwayListenerProps } from './types';

export const ClickAwayListener: FC<PropsWithChildren<ClickAwayListenerProps>> = ({
  children,
  onClickAway,
}) => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onClickAway();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickAway]);

  return <div ref={containerRef}>{children}</div>;
};
