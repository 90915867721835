export type NftReadableRarity = 'Common' | 'Rare' | 'Epic' | 'Legendary' | 'Mythic';
export enum NftRarity {
  Common,
  Rare,
  Epic,
  Legendary,
  Mythic,
}
export const NFT_RARITY_TO_READABLE_RARITY_NAME_MAP: Record<NftRarity, NftReadableRarity> = {
  [NftRarity.Common]: 'Common',
  [NftRarity.Rare]: 'Rare',
  [NftRarity.Epic]: 'Epic',
  [NftRarity.Legendary]: 'Legendary',
  [NftRarity.Mythic]: 'Mythic',
};

export const NFT_READABLE_RARITIES = ['All', 'Common', 'Rare', 'Epic', 'Legendary', 'Mythic'];

export const NFT_RARITY_TO_MAX_LIVES_MAP: Record<NftRarity, number> = {
  [NftRarity.Common]: 2,
  [NftRarity.Rare]: 3,
  [NftRarity.Epic]: 4,
  [NftRarity.Legendary]: 5,
  [NftRarity.Mythic]: 10,
};

export const NFT_RARITY_TO_MAX_WIN_STREAK: Record<NftRarity, number> = {
  [NftRarity.Common]: 3,
  [NftRarity.Rare]: 6,
  [NftRarity.Epic]: 12,
  [NftRarity.Legendary]: 25,
  [NftRarity.Mythic]: 50,
};

export const NFT_RARITY_TO_RECOVERY_AMOUNT_OF_HOURS_AFTER_WRONG_VOTE: Record<NftRarity, number> = {
  [NftRarity.Common]: 24,
  [NftRarity.Rare]: 12,
  [NftRarity.Epic]: 6,
  [NftRarity.Legendary]: 3,
  [NftRarity.Mythic]: 1,
};
