import { Icon } from '~shared/ui';

import * as S from './styled';

export const NftFreezed = () => {
  return (
    <S.NftFreezedRoot>
      <Icon name="snowflake" size={25} />
      <S.NftFreezedText>Freezed</S.NftFreezedText>
    </S.NftFreezedRoot>
  );
};
