import { FC } from 'react';

import { Typography } from '~shared/ui';

import { PrivacyPolicyRoot, PrivacyPolicyTable, PrivacyPolicyTableWrapper } from './styled';

export const PrivacyPolicy: FC = () => {
  return (
    <PrivacyPolicyRoot>
      <Typography variant="h3" gutterBottom>
        August 2022 v 1.0
      </Typography>

      <Typography variant="h2" gutterBottom>
        1. Foreword
      </Typography>

      <Typography variant="body" paragraph>
        The Company enables users (“<strong>User</strong>”) registered on the Website Maincard.io (“
        <strong>Website</strong>”) to benefit from an enhanced user interface. Maincard is a fantasy
        management platform to educate the new generation of players on what smart contracts, NFT,
        and Crypto are in the most fun, engaging, and safe way. The Website provides a marketplace
        for registered users to acquire, collect, and exchange collectibles and their underlying
        non-fungible tokens based on blockchain technology (collectively, the “
        <strong>Services</strong>”).
      </Typography>

      <Typography variant="body" paragraph>
        To enable Users to benefit from the Services offered by the Website{' '}
        <a href="https://maincard.io/">https://maincard.io/</a> such as its consultation,
        optimization of its use or personalization according to the User, the Website uses cookies.
      </Typography>

      <Typography variant="body" paragraph>
        You can deactivate cookies to which you have consented at any time, free of charge, using
        the deactivation options offered to you and listed below.
      </Typography>

      <Typography variant="h2" gutterBottom>
        2. What is a cookie?
      </Typography>
      <Typography component="div">
        <Typography paragraph>
          <strong>2.1</strong> When you browse out the Website, information relating to the
          navigation of your terminal (computer, tablet, smartphone, etc.) on the Website may be
          recorded in text files called "cookies", placed on your browser. Cookies will be used to
          recognize your browser for the duration of the cookie's validity and return certain
          information to the Website (such as a session identifier or language choice).
        </Typography>
        <Typography paragraph>
          <strong>2.2</strong> Only the sender of a cookie can read or modify the information
          contained in it.
        </Typography>
        <Typography>
          <strong>2.3</strong> There are different types of cookies:
        </Typography>
        <Typography component="ul" paragraph>
          <Typography component="li">
            session cookies that disappear as soon as you leave the Website;
          </Typography>
          <Typography component="li">
            permanent cookies that remain on your terminal until their lifetime expires or until you
            delete them using your browser's functions.
          </Typography>
        </Typography>
        <Typography paragraph>
          <strong>2.4</strong> You are informed that, during your visits to the Website, cookies may
          be placed on your terminal equipment.
        </Typography>
      </Typography>

      <Typography variant="h2" gutterBottom>
        3. Purposes of cookies placed on terminal
      </Typography>
      <Typography paragraph>
        <strong>3.1</strong> Some cookies are essential to the use of the Website: these are
        technical cookies used by the Website host for the technical management of the network.
      </Typography>
      <Typography>
        <strong>3.2</strong> Others are used to analyze the frequentation and the use made of the
        Website, in order:
      </Typography>
      <Typography component="ul" gutterBottom>
        <Typography component="li">
          to make it evolve in the sense of an improvement of the experience of navigation, in the
          final objective to propose you a Website always more adapted; to conduct studies;
        </Typography>
        <Typography component="li">
          to memorize your browser's display preferences (language used, display parameters,
          operating system used, etc.) and to adapt the presentation of the Website to the time of
          your visits, according to the hardware and software of visualization or reading that your
          terminal equipment includes and which are used for navigation on our Website;
        </Typography>
        <Typography component="li">to implement security measures.</Typography>
      </Typography>

      <Typography variant="h2" gutterBottom>
        4. Personal Data processed
      </Typography>
      <Typography>
        <strong>4.1</strong> Cookies used on our Website are:
      </Typography>
      <Typography component="ul" paragraph>
        <Typography component="li">Maincard’s cookies;</Typography>
        <Typography component="li">
          third party cookies chosen by Maincard Company in order to achieve certain objectives
        </Typography>
      </Typography>

      <Typography paragraph>
        <strong>4.2</strong> Cookies placed by Maincard and third parties are the following:
      </Typography>

      <PrivacyPolicyTableWrapper>
        <PrivacyPolicyTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Purpose</th>
              <Typography component="th" minWidth={200}>
                Retention period
              </Typography>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>__stripe_mid</td>
              <td>
                This cookie is set by Stripe to ensure that the service works properly, detect and
                prevent fraud, and understand how people interact with it. More information on the{' '}
                <a href="https://stripe.com/cookies-policy/legal">
                  https://stripe.com/cookies-policy/legal
                </a>{' '}
                (Stripe cookie policy).
              </td>
              <td>1 year</td>
            </tr>

            <tr>
              <td>__stripe_sid</td>
              <td>
                This cookie is set by Stripe to ensure that the service works properly, detect and
                prevent fraud, and understand how people interact with it. More information on the{' '}
                <a href="https://stripe.com/cookies-policy/legal">
                  https://stripe.com/cookies-policy/legal
                </a>{' '}
                (Stripe cookie policy).
              </td>
              <td>30 minutes</td>
            </tr>

            <tr>
              <td>ajs_anonymous_id</td>
              <td>
                This cookie is set by Segment.io to anonymously identify visitors to measure the
                update of information and potential conversion to registered users. More information
                on{' '}
                <a href="https://segment.com/legal/privacy/">https://segment.com/legal/privacy/</a>{' '}
                (Segment privacy policy).
              </td>
              <td>6 months</td>
            </tr>

            <tr>
              <td>ajs_user_id</td>
              <td>
                This cookie is set by Segment.io to anonymously identify visitors to measure the
                update of information and potential conversion to registered users. More information
                on{' '}
                <a href="https://segment.com/legal/privacy/">https://segment.com/legal/privacy/</a>{' '}
                (Segment privacy policy).
              </td>
              <td>6 months</td>
            </tr>
            <tr>
              <td>scid</td>
              <td>
                This cookie is set by Snap to anonymously identify visitors to measure the update of
                information and potential conversion to registered users through Snapchat. More
                information on{' '}
                <a href="https://snap.com/en-GB/privacy/privacy-policy">
                  https://snap.com/en-GB/privacy/privacy-policy
                </a>{' '}
                (Snap privacy policy).
              </td>
              <td>6 months</td>
            </tr>

            <tr>
              <td>ab.storage</td>
              <td>
                This cookie is set by Braze anonymously identify visitors to measure the update of
                information and potential conversion to registered users. More information on{' '}
                <a href="https://www.braze.com/company/legal/privacy">
                  https://www.braze.com/company/legal/privacy
                </a>{' '}
                (Braze privacy policy).
              </td>
              <td>6 months</td>
            </tr>

            <tr>
              <td>ga</td>
              <td>
                This cookie is set by Google Analytics to anonymously identify visitors to measure
                the update of information and potential conversion to registered users. More
                information on{' '}
                <a href="https://policies.google.com/technologies/cookies/">
                  https://policies.google.com/technologies/cookies/
                </a>{' '}
                (Google privacy policy).
              </td>
              <td>6 months</td>
            </tr>

            <tr>
              <td>gcl_au</td>
              <td>
                This cookie is set by Google Tag Manager to anonymously identify visitors to measure
                the update of information and potential conversion to registered users. More
                information on{' '}
                <a href="https://policies.google.com/technologies/cookies/">
                  https://policies.google.com/technologies/cookies/
                </a>{' '}
                (Google privacy policy).
              </td>
              <td>3 months</td>
            </tr>

            <tr>
              <td>gid</td>
              <td>
                This cookie is set by Google Analytics to anonymously identify visitors to measure
                the update of information and potential conversion to registered users. More
                information on{' '}
                <a href="https://policies.google.com/technologies/cookies/">
                  https://policies.google.com/technologies/cookies/
                </a>{' '}
                (Google privacy policy).
              </td>
              <td>1 day</td>
            </tr>

            <tr>
              <td>hjAbsoluteSessionInProgress</td>
              <td>
                This cookie is set by Hotjar to anonymously identify visitors to measure the update
                of information and potential conversion to registered users. More information on{' '}
                <a href="https://www.hotjar.com/legal/policies/privacy/">
                  https://www.hotjar.com/legal/policies/privacy/
                </a>{' '}
                (Hotjar privacy policy).
              </td>
              <td>30 minutes</td>
            </tr>

            <tr>
              <td>hjFirstSeen</td>
              <td>
                This cookie is set by Hotjar to anonymously identify visitors to measure the update
                of information and potential conversion to registered users. More information on{' '}
                <a href="https://www.hotjar.com/legal/policies/privacy/">
                  https://www.hotjar.com/legal/policies/privacy/
                </a>{' '}
                (Hotjar privacy policy).
              </td>
              <td>30 minutes</td>
            </tr>

            <tr>
              <td>hjIncludedInSessionSample</td>
              <td>
                This cookie is set by Hotjar to anonymously identify visitors to measure the update
                of information and potential conversion to registered users. More information on{' '}
                <a href="https://www.hotjar.com/legal/policies/privacy/">
                  https://www.hotjar.com/legal/policies/privacy/
                </a>{' '}
                (Hotjar privacy policy).
              </td>
              <td>30 minutes</td>
            </tr>

            <tr>
              <td>amplitude_id</td>
              <td>
                This cookie is set by Amplitude to anonymously visitors to measure the update of
                information and potential conversion to registered users. More information on{' '}
                <a href="https://amplitude.com/privacy/">https://amplitude.com/privacy/</a>{' '}
                (Amplitude privacy policy).
              </td>
              <td>Session</td>
            </tr>

            <tr>
              <td>xsrftoken</td>
              <td>This cookie is set by Maincard as a security measure.</td>
              <td>Session</td>
            </tr>

            <tr>
              <td>_Maincard_session</td>
              <td>
                This cookie is set by Maincard when you are logged in order to remember the current
                user.
              </td>
              <td>Session</td>
            </tr>
          </tbody>
        </PrivacyPolicyTable>
      </PrivacyPolicyTableWrapper>

      <Typography gutterBottom>
        <strong>4.3</strong> The placement and use of cookies by these companies is subject to their
        own terms of use, which you can view on their respective websites.
      </Typography>

      <Typography variant="h2" gutterBottom>
        5. Sharing your terminal use with others
      </Typography>
      <Typography paragraph>
        <strong>5.1</strong> If your terminal is used by several persons, and when a single terminal
        has multiple browsers, we cannot be certain that the services and advertisements directed to
        your terminal equipment correspond to your own use of that terminal equipment and not to
        that of another User of that terminal equipment.
      </Typography>
      <Typography paragraph>
        <strong>5.2</strong> Sharing with others the use of your terminal equipment and configuring
        your browser settings with respect to cookies is your free choice and responsibility.
      </Typography>

      <Typography variant="h2" gutterBottom>
        6. Consent
      </Typography>
      <Typography paragraph>
        <strong>6.1</strong> When you first visit our Website, you will be asked to accept,
        configure or refuse the use of some cookies.
      </Typography>
      <Typography paragraph>
        <strong>6.2</strong> If you do not want cookies to be placed or read on your terminal
        equipment, a refusal cookie will be placed on your equipment so that Maincard can record the
        information according to which you have refused the use of cookies. If you delete this
        refusal cookie, it will no longer be possible to identify you as having refused the use of
        cookies.
      </Typography>
      <Typography paragraph>
        <strong>6.3</strong> Also, when you accept the deposit of cookies, a consent cookie is
        placed.
      </Typography>
      <Typography paragraph>
        <strong>6.4</strong> Refusal or consent cookies must remain on your terminal equipment. You
        can change your choices at any time by clicking here.
      </Typography>

      <Typography variant="h2" gutterBottom>
        7. Cookie management
      </Typography>
      <Typography>
        <strong>7.1</strong> You can manage and modify the use of cookies at any time according to
        the possibilities listed below:
      </Typography>
      <Typography component="ul" paragraph>
        <Typography component="li">
          directly on our Website, in the module provided for this purpose, by clicking here, or
        </Typography>
        <Typography component="li">from your browser, or</Typography>
        <Typography component="li">from interprofessional opposition platforms.</Typography>
      </Typography>
      <Typography fontWeight={700} paragraph>
        <strong>
          7.2 Please note your refusal to place a cookie is based on the deposit of a cookie.
          Therefore, if you deactivate all cookies on your terminal or if you change your terminal,
          we will no longer know that you have chosen this option.
        </strong>
      </Typography>
      <Typography paragraph>
        <strong>7.3 Cookie management module</strong>
      </Typography>
      <Typography paragraph>
        <strong>7.4</strong> A module allows you to choose which cookies you wish to accept and
        which you wish to reject on our Website.
      </Typography>
      <Typography paragraph>
        <strong>7.5</strong> At any time, by clicking here you can access the module and change your
        preferences.
      </Typography>
      <Typography paragraph>
        <strong>7.6 Navigation software settings</strong>
      </Typography>
      <Typography paragraph>
        <strong>7.7</strong> You can configure your browser so that cookies are stored in your
        terminal equipment or are rejected, either systematically or according to their sender.
      </Typography>
      <Typography paragraph>
        <strong>7.8</strong> You can also regularly delete cookies from your terminal via your
        browser.
      </Typography>
      <Typography paragraph>
        <strong>7.9</strong> However, don't forget to configure all the browsers on your various
        terminals (tablets, smartphones, computers...).
      </Typography>
      <Typography>
        <strong>7.10</strong> Each browser is configured differently in order to manage cookies and
        your choices. It is described in the help menu of your browser, which will allow you to know
        how to modify your choices regarding cookies. For example:
      </Typography>
      <Typography component="ul" paragraph>
        <Typography component="li">
          for Internet Explorer™:{' '}
          <a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies;">
            http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies;
          </a>
        </Typography>
        <Typography component="li">
          for Safari™:{' '}
          <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac;">
            https://support.apple.com/fr-fr/guide/safari/sfri11471/mac;
          </a>
        </Typography>
        <Typography component="li">
          for Chrome™:{' '}
          <a href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647;">
            http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647;
          </a>
        </Typography>
        <Typography component="li">
          for Firefox™:{' '}
          <a href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies;">
            http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies;
          </a>
        </Typography>
        <Typography component="li">
          for Opera™:{' '}
          <a href="http://help.opera.com/Windows/10.20/fr/cookies.html.">
            http://help.opera.com/Windows/10.20/fr/cookies.html.
          </a>
        </Typography>
      </Typography>

      <Typography paragraph>
        <strong>7.11</strong> Please note, however, that by setting your browser to reject cookies,
        certain features, pages and areas of the Website that require the use of cookies will not be
        accessible, for which we cannot be responsible.
      </Typography>
      <Typography paragraph>
        <strong>7.13 Interprofessional opposition platforms</strong>
      </Typography>
      <Typography paragraph>
        <strong>7.14</strong> Several advertising professionals' platforms also offer you the
        possibility to refuse or accept cookies used by the member companies. These centralized
        mechanisms do not block the display of advertisements but only prevent the installation of
        cookies that allow the adaptation of advertisements to your interests and behavior.
      </Typography>
      <Typography paragraph>
        <strong>7.15</strong> For example, you can connect to the site www.youronlinechoices.com in
        order to prevent these cookies from being placed on your terminal. This Website is proposed
        by the professionals of digital advertising gathered within the European association EDAA
        (European Digital Advertising Alliance) and managed in France by the Interactive Advertising
        Bureau France.
      </Typography>
      <Typography paragraph>
        <strong>7.16</strong> You will be able to find out which companies are registered with this
        platform and which offer you the possibility of refusing or accepting the cookies they use
        to adapt, according to the data they collect, the advertisements that may be displayed on
        your browser: www.youronlinechoices.com/fr/controler-ses-cookies.
      </Typography>
      <Typography paragraph>
        <strong>7.17</strong> This European platform is shared by hundreds of Internet advertising
        professionals and constitutes a centralized interface allowing you to express your refusal
        or acceptance of cookies as specified above.
      </Typography>

      <Typography variant="h2" gutterBottom>
        8. Personal Data Protection
      </Typography>

      <Typography paragraph>
        <strong>8.1</strong> Within the framework of the use of cookies as described on the present
        page, the Maincard Company is likely to process Personal Data concerning you, such as IP
        addresses, in its capacity as data controller.
      </Typography>
      <Typography paragraph>
        <strong>8.2</strong> Third-party cookies on the Website are set by external processors,
        which are likely, if you accept these cookies, to process Personal Data about you.
      </Typography>
      <Typography paragraph>
        <strong>8.3</strong> The issuance and use of these cookies by third parties is subject to
        their own privacy policies. For more information about these processes, you can refer to
        their privacy policies.
      </Typography>
      <Typography paragraph>
        <strong>8.4</strong> The data collected is essential to achieve the objectives pursued by
        each cookie. It is only intended for the authorized departments of Maincard and/or the
        Company issuing the third-party cookies.
      </Typography>
      <Typography paragraph>
        <strong>8.5</strong> Personal Data about you may be transmitted to service providers located
        in third countries outside the European Union.
      </Typography>
      <Typography paragraph>
        <strong>8.6</strong> These data transfers are governed by a cross-border flow agreement
        established in accordance with the contractual clauses issued by the European Commission and
        currently in force.
      </Typography>
      <Typography paragraph>
        <strong>8.7</strong> Personal Data collected via cookies is never kept longer than necessary
        to achieve the purpose of the cookie and, in any case, not more than 13 months.
      </Typography>
      <Typography>
        <strong>8.8</strong> For more information, including how to exercise your rights, see our
        Privacy Policy .
      </Typography>
    </PrivacyPolicyRoot>
  );
};
