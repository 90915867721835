import { FC, Fragment, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery, useSwitcher } from '~shared/lib/hooks';
import { SportToTournamentBackgroundMap } from '~shared/lib/assets';
import { Stack } from '~shared/ui';

import { NftCardsStack, StaticNftCard } from '~entities/nft';

import { SportToLabelMap, formatLocaleDate, getBadgeType } from '../../lib';

import { TournamentPrizes } from '../TournamentPrizes';
import { TournamentBadge } from '../TournamentBadge';
import { TournamentDialog } from '../TournamentDialog';

import {
  CardBadgeContainer,
  CardImage,
  CardInfo,
  CardInfoDescriptionElement,
  CardInfoHeading,
  CardInfoTop,
  CardRoot,
} from './styled';

import { TournamentCardProps } from './types';

export const TournamentCard: FC<TournamentCardProps> = ({ ...tournament }) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const open = useSwitcher(false);

  const { name, sport, cards, prizes, joinButton } = tournament;
  const { startDate, endDate } = tournament;
  const { players, playersLimit } = tournament;

  const playersCount = players.length;

  /** Common elements */
  const prizesElements = <TournamentPrizes prizes={prizes} />;
  const statusBadgeElement = <TournamentBadge type={getBadgeType(startDate, endDate)} />;
  const datesElement = `${formatLocaleDate(startDate)} — ${formatLocaleDate(endDate)}`;
  const cardImageElement = <CardImage src={SportToTournamentBackgroundMap[sport]} />;

  const playersElement = (
    <CardInfoHeading limitReached={playersCount === playersLimit}>
      {playersCount}/{playersLimit}
    </CardInfoHeading>
  );

  const cardsStackElement = (
    <NftCardsStack cardsToShow={6}>
      {cards.map((card) => (
        <StaticNftCard key={card} tokenId={card} height={32} />
      ))}
    </NftCardsStack>
  );

  const dialogElement = (
    <TournamentDialog {...tournament} open={open.value} onClose={open.switchOff} />
  );

  const mobileCardElement = (
    <CardRoot>
      {cardImageElement}

      <CardBadgeContainer>
        {statusBadgeElement}
        <TournamentBadge type="date" startDate={startDate} endDate={endDate} />
      </CardBadgeContainer>

      <CardInfo>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <CardInfoHeading onClick={open.switchOn}>{name}</CardInfoHeading>

          <Stack spacing={2 / 8} alignItems="flex-end" direction="column-reverse">
            {playersElement}
            <CardInfoDescriptionElement>{t('Tournaments.players')}</CardInfoDescriptionElement>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={2 / 8}>
            <CardInfoDescriptionElement>{t(SportToLabelMap[sport])}</CardInfoDescriptionElement>
            <CardInfoDescriptionElement>Rare weekly 6-cards</CardInfoDescriptionElement>
          </Stack>

          {cardsStackElement}
        </Stack>

        {prizesElements}

        {joinButton}
      </CardInfo>
    </CardRoot>
  );

  const desktopCardElement = (
    <CardRoot>
      <CardBadgeContainer>{statusBadgeElement}</CardBadgeContainer>

      <CardInfo>
        <CardInfoTop>
          <Stack spacing={6 / 8}>
            <CardInfoHeading onClick={open.switchOn}>{name}</CardInfoHeading>

            <Stack spacing={14 / 8} direction="row">
              <CardInfoDescriptionElement>{datesElement}</CardInfoDescriptionElement>
              <CardInfoDescriptionElement>{t(SportToLabelMap[sport])}</CardInfoDescriptionElement>
              <CardInfoDescriptionElement>Rare weekly 6-cards</CardInfoDescriptionElement>
            </Stack>
          </Stack>

          <Stack spacing={2 / 8} alignItems="flex-end">
            {playersElement}
            <CardInfoDescriptionElement>{t('Tournaments.players')}</CardInfoDescriptionElement>
          </Stack>
        </CardInfoTop>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {prizesElements}
          {cardsStackElement}
        </Stack>

        {cloneElement(joinButton, { justifySelf: 'flex-start', alignSelf: 'start' })}
      </CardInfo>

      {cardImageElement}
    </CardRoot>
  );

  return (
    <Fragment>
      {isMobile ? mobileCardElement : desktopCardElement}
      {dialogElement}
    </Fragment>
  );
};
