import { NftRarity } from '~shared/api';

import { MarketplaceItem, MarketplaceItemType } from './types';

export const MARKETPLACE_PAGE_TAB_SEARCH_PARAM_KEY = 'tab';

export const MARKETPLACE_AVAILABLE_RARITIES = [
  NftRarity.Common,
  NftRarity.Rare,
  NftRarity.Epic,
  NftRarity.Legendary,
];

export const MARKETPLACE_ITEMS: Array<MarketplaceItem> = [
  {
    type: MarketplaceItemType.Unfreeze,
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Common,
    maximumNumberOfRecoveries: 2,
    recoveryCost: '1',
    recoveryPeriod: 3,
    rewardTokensAmount: 3,
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Common,
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Rare,
    maximumNumberOfRecoveries: 3,
    recoveryCost: '10',
    recoveryPeriod: 6,
    rewardTokensAmount: 15,
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Rare,
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Epic,
    price: 1000,
    maximumNumberOfRecoveries: 5,
    recoveryCost: '20',
    recoveryPeriod: 12,
    rewardTokensAmount: 75,
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Epic,
  },
  {
    type: MarketplaceItemType.Nft,
    rarity: NftRarity.Legendary,
    maximumNumberOfRecoveries: 4,
    recoveryCost: '75',
    recoveryPeriod: 24,
    rewardTokensAmount: 500,
  },
  {
    type: MarketplaceItemType.MysteryBox,
    rarity: NftRarity.Legendary,
  },
];

/** Images */
const BASE_MARKETPLACE_IMAGE_URL = `${process.env.PUBLIC_URL}/assets/marketplace`;

export const MARKETPLACE_NFT_IMAGE_URL_DIR = `${BASE_MARKETPLACE_IMAGE_URL}/card`;
export const MARKETPLACE_MYSTERY_BOX_IMAGE_DIR = `${BASE_MARKETPLACE_IMAGE_URL}/mystery-box`;
export const MARKETPLACE_UNFREEZE_IMAGE_URL = `${BASE_MARKETPLACE_IMAGE_URL}/other/unfreeze.png`;
