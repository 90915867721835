import { FC } from 'react';
import { clsx } from 'clsx';

import { NotificationTagProps } from './types';
import { NotificationTagRoot, notificationTagClasses } from './styled';

export const NotificationTag: FC<NotificationTagProps> = ({
  color = 'primary',
  className,
  ...htmlAttributes
}) => {
  return (
    <NotificationTagRoot
      className={clsx(
        notificationTagClasses.root,
        {
          [notificationTagClasses.primary]: color === 'primary',
          [notificationTagClasses.success]: color === 'success',
        },
        className
      )}
      {...htmlAttributes}
    />
  );
};
