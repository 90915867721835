import { FC, ReactNode } from 'react';

import { ButtonIcon, DateLabel, Icon, Stack } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { leagueToLogoMap } from '~entities/battle';
import { useNftSelector } from '~entities/nft';
import { EventDialogTab, useEventModel } from '~entities/event';

import { EventDialogChoiceResult } from './EventDialogChoiceResult';
import { EventDialogStatistics } from './EventDialogStatistics';
import { EventDialogTeams } from './EventDialogTeams';
import { EventDialogCards } from './EventDialogCards';
import { EventDialogTabs } from './EventDialogTabs';

import {
  EventContentInnerContainer,
  EventContentMobileInnerContainer,
  EventMobileDateLabel,
  EventTabsContainer,
  EventTabsMobileContainer,
} from './styled';

import { EventDialogContentProps } from './types';

export const EventDialogContent: FC<EventDialogContentProps> = () => {
  const { isMobile } = useMediaQuery();

  const {
    event,
    tab,

    isCall,
    isPassedEvent,
    isViewerCall,
    result,
    choice,

    onClose,
  } = useEventModel();

  const { detailedInfo } = useNftSelector();

  const isCorrectChoice = result === choice;
  const isBattleViewer = !detailedInfo.viewMode;

  if (!event) {
    return null;
  }

  const tabs: Record<EventDialogTab, ReactNode> = {
    [EventDialogTab.Cards]: <EventDialogCards />,
    [EventDialogTab.Statistics]: <EventDialogStatistics />,
    [EventDialogTab.Stream]: null,
  };

  const logo = leagueToLogoMap[event.league];

  if (isMobile) {
    return (
      <EventContentMobileInnerContainer>
        <EventTabsMobileContainer spacing={6 / 8} direction="row">
          <ButtonIcon size={24} name="arrow-left" variant="plain" onClick={onClose} />

          <EventDialogTabs />
        </EventTabsMobileContainer>

        <Stack alignItems="center" direction="row" paddingY={10 / 8}>
          <Stack direction="row" alignItems="center" spacing={10 / 8}>
            <EventMobileDateLabel date={event.date!} defaultState="planned" size="md" />

            {isPassedEvent && (isCall ? isViewerCall : isBattleViewer) && (
              <EventDialogChoiceResult isCorrectChoice={isCorrectChoice} />
            )}
          </Stack>

          {logo && <Icon name={logo} size={48} marginLeft="auto" />}
        </Stack>

        <EventDialogTeams />

        {tabs[tab]}
      </EventContentMobileInnerContainer>
    );
  }

  return (
    <EventContentInnerContainer spacing={20 / 8}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" alignSelf="stretch">
        <Stack direction="row" alignItems="center" spacing={8 / 8}>
          <DateLabel date={event.date!} defaultState="planned" size="md" />

          {logo && <Icon name={logo} size={48} />}
        </Stack>

        {isPassedEvent && (isCall ? isViewerCall : isBattleViewer) && (
          <EventDialogChoiceResult isCorrectChoice={isCorrectChoice} />
        )}
      </Stack>

      <EventTabsContainer alignItems="center">
        <EventDialogTabs />
      </EventTabsContainer>

      <EventDialogTeams />

      {tabs[tab]}
    </EventContentInnerContainer>
  );
};
