import { styled } from '~shared/lib/styles';
import { Box, Checkbox } from '~shared/ui';

import { NFT_CARD_SIZE_VALUES, NftCardSize } from '../../lib';

const HIGHLIGHT_VARIANT_TO_BOX_SHADOW = {
  small: '0px 0px 11px 1px ',
  large: '0px 0px 31px 1px ',
};

interface SizeProps {
  $size: NftCardSize;
}

interface NftCardRootProps extends SizeProps {
  $areDetailsShown: boolean;
  $isHighlighted: boolean;
  $highlightVariant: 'small' | 'large';
  $isDragging: boolean;
}

export const NftCardRoot = styled(Box, {
  name: 'NftCard',
  slot: 'root',
})<NftCardRootProps>(
  ({
    theme,
    $areDetailsShown,
    $isDragging,
    $isHighlighted,
    $highlightVariant,
    $size,
    onClick,
  }) => ({
    position: 'relative',
    borderRadius: '10px',
    cursor: onClick ? 'pointer' : $areDetailsShown ? 'grab' : 'initial',
    opacity: $isDragging ? 0.5 : 1,
    boxShadow: $isHighlighted
      ? `${HIGHLIGHT_VARIANT_TO_BOX_SHADOW[$highlightVariant]} ${theme.palette.primary.light}`
      : '0 0 0 0 transparent',
    userSelect: 'none',
    overflow: 'hidden',
    transition: theme.transitions.create(),

    ...NFT_CARD_SIZE_VALUES[$size],
  })
);

interface NftCardImageProps extends SizeProps {
  $isHidden?: boolean;
}

export const NftCardImage = styled('img', {
  name: 'NftCard',
  slot: 'image',
})<NftCardImageProps>(({ theme, $isHidden, $size }) => ({
  display: $isHidden ? 'none' : 'inline-block',
  borderRadius: '10px',
  border: `1px solid ${theme.palette.grey.A50}`,
  ...NFT_CARD_SIZE_VALUES[$size],
}));

export const StyledCheckbox = styled(Checkbox, {
  name: 'UserCardsList',
  slot: 'checkbox',
})(({ theme }) => ({
  position: 'absolute',
  bottom: '8px',
  left: '9px',
  zIndex: theme.zIndex.alert,
}));
