import { FC, useRef } from 'react';
import { clsx } from 'clsx';

import { useTabIndicator } from '~shared/lib/hooks';

import { tabClasses } from '../../tabs';

import { MenuTabsProps } from './types';
import { MenuTabIndicator, UIKitMenuTabs, UIKitMenuTabsContainer, menuTabsClasses } from './styled';

const selector = `.${tabClasses.selected}`;

export const MenuTabs: FC<MenuTabsProps> = ({ variant = 'default', className, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { position, onChange } = useTabIndicator({
    ref,
    selector,
    onChange: props?.onChange,
    value: props?.value,
  });

  const containerClassName = clsx(
    {
      [menuTabsClasses.secondary]: variant === 'secondary',
      [menuTabsClasses.sidebar]: variant === 'sidebar',
      [menuTabsClasses.default]: variant === 'default',
    },
    className
  );

  return (
    <UIKitMenuTabsContainer ref={ref} className={containerClassName}>
      <UIKitMenuTabs {...props} onChange={onChange} />

      {variant !== 'sidebar' && props.value !== null && (
        <MenuTabIndicator className={menuTabsClasses.indicator} style={position} />
      )}
    </UIKitMenuTabsContainer>
  );
};
