import { Switch as MuiSwitchBase, switchClasses as muiBaseSwitchClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

const switchClasses = {
  ...muiBaseSwitchClasses,
};

export const UIKitSwitchRoot = styled(MuiSwitchBase)(({ theme }) => ({
  width: 40,
  height: 20,

  fontSize: 0,
  position: 'relative',
  display: 'inline-block',
  cursor: 'pointer',
  borderRadius: 100,
  transition: theme.transitions.create('box-shadow'),

  [`&.${switchClasses.disabled}`]: {
    opacity: 0.3,
    cursor: 'not-allowed',
  },

  [`& .${switchClasses.track}`]: {
    background: theme.palette.grey.A10,
    borderRadius: 100,
    display: 'block',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },

  [`& .${switchClasses.thumb}`]: {
    display: 'block',
    width: 20,
    height: 20,
    top: 0,
    left: 0,
    borderRadius: 100,
    backgroundColor: theme.palette.grey.A75,
    position: 'relative',
    transition: theme.transitions.create('all'),
  },

  [`&.${switchClasses.focusVisible}`]: {
    boxShadow: '0px 0px 8px #42BAFF',
  },

  [`&.${switchClasses.checked}`]: {
    [`& .${switchClasses.thumb}`]: {
      left: 20,
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${switchClasses.input}`]: {
    cursor: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    opacity: 0,
    margin: '0',
  },
}));
