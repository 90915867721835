import { BigNumber, ethers } from 'ethers';

import { Arena__factory } from '~shared/contracts';
import { getBattlesByIds } from '~shared/api';

export const getViewerBetCards = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const arenaContract = Arena__factory.connect(process.env.REACT_APP_ADDRESS_SK_ARENA, provider);

  const activeBetsAmount = await arenaContract
    .betsByAddressCount(address)
    .then((amount) => amount.toNumber());

  const enumeratedAmountArray = Array.from(Array(activeBetsAmount).keys());

  const betsCardIds = await Promise.all(
    enumeratedAmountArray.map(async (index) => {
      const cardId = await arenaContract.betsByUser(address, index);

      return cardId.toString();
    })
  );

  const activeBets = await Promise.all(
    betsCardIds.map(async (cardId) => {
      const { eventId, choice } = await arenaContract.bets(BigNumber.from(cardId));

      return {
        eventId: eventId._hex.slice(2),
        cardId,
        choice,
      };
    })
  );

  const activeBetEvents = await getBattlesByIds({
    ids: activeBets.map((activeBet) => activeBet.eventId),
  });

  const betCardIds = activeBets.map((activeBet) => activeBet.cardId);

  return {
    betCardIds,
    activeBets,
    activeBetEvents,
  };
};
