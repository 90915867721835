import { Button as MuiBaseButton } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const bottomNavigationActionClasses = {
  selected: 'BottomNavigationAction-selected',
  indicator: 'BottomNavigationAction-indicator',
  disabled: 'BottomNavigationAction-disabled',
};

export const UIKitBottomNavigationActionRoot = styled(MuiBaseButton, {
  name: 'BottomNavigationAction',
})(({ theme }) => ({
  position: 'relative',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 9,
  paddingBottom: 15,

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  background: 'none',
  border: 'none',
  color: theme.palette.grey.A50,

  overflow: 'hidden',
  transition: theme.transitions.create('color'),

  [`&.${bottomNavigationActionClasses.disabled}`]: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  [`&:hover:not(:disabled), &.${bottomNavigationActionClasses.selected}`]: {
    color: theme.palette.text.primary,

    [`& .${bottomNavigationActionClasses.indicator}`]: {
      background: theme.palette.primary.main,
    },
  },
}));

export const UIKitBottomNavigationActionLabel = styled('span', {
  name: 'BottomNavigationAction-label',
})({
  marginTop: 2,

  fontSize: 10,
  fontWeight: 400,
  lineHeight: 14 / 10,
});

export const UIKitBottomNavigationActionIndicator = styled('span', {
  name: 'BottomNavigationAction-indicator',
})(({ theme }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  left: -10,
  right: -10,
  bottom: 2 - 25,
  height: 25,
  transition: theme.transitions.create('background'),
  background: 'transparent',
  filter: 'blur(7px)',
}));
