import { NftRarity } from '~shared/api';

export const NFT_RARITY_TO_MAX_LIVES_MAP: Record<NftRarity, number> = {
  [NftRarity.Common]: 2,
  [NftRarity.Rare]: 3,
  [NftRarity.Epic]: 4,
  [NftRarity.Legendary]: 5,
  [NftRarity.Mythic]: 10,
};

export const NFT_RARITY_TO_MAX_WIN_STREAK = {
  [NftRarity.Common]: 3,
  [NftRarity.Rare]: 6,
  [NftRarity.Epic]: 12,
  [NftRarity.Legendary]: 25,
  [NftRarity.Mythic]: null,
};

export const NFT_RARITY_TO_READABLE_RARITY_NAME_MAP = {
  [NftRarity.Common]: 'Sidebar.common',
  [NftRarity.Rare]: 'Sidebar.rare',
  [NftRarity.Epic]: 'Sidebar.epic',
  [NftRarity.Legendary]: 'Sidebar.legendary',
  [NftRarity.Mythic]: 'Sidebar.mythic',
};

export const NFT_RARITY_TO_READABLE_KEY_MAP = {
  [NftRarity.Common]: 'common',
  [NftRarity.Rare]: 'rare',
  [NftRarity.Epic]: 'epic',
  [NftRarity.Legendary]: 'legendary',
  [NftRarity.Mythic]: 'mythic',
};

export const NFT_RARITIES = [
  NftRarity.Common,
  NftRarity.Rare,
  NftRarity.Epic,
  NftRarity.Legendary,
  NftRarity.Mythic,
];

export const NftStatusText = {
  Normal: 'Events.Normal',
  BlockedForEvent: 'Events.blocked',
  OnEvent: 'Events.OnEvent',
  Freezed: 'Events.Freezed',
  ShouldBeRecovered: 'Events.ShouldBeRecovered',
  AvailableForMerge: 'Events.AvailableForMerge',
};
