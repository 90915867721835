import { useTranslation } from 'react-i18next';

import { NftCard } from '~entities/nft';

import { Stack, Typography } from '~shared/ui';

import { useSellCardDialogModel } from '../../model';

import { SellCardForm } from './SellCardForm';
import { StyledDialog } from './styled';

export const SellCardDialog = () => {
  const { t } = useTranslation();

  const { open, nft, form, handleCloseSellCardDialog, handleSubmit } = useSellCardDialogModel();

  if (!nft) {
    return null;
  }

  return (
    <StyledDialog open={open} width={440} onClose={handleCloseSellCardDialog}>
      <Stack spacing={24 / 8} alignItems="center">
        <Typography variant="h1">{t('Other.sellHead')}</Typography>
        <NftCard nft={nft} isHighlighted highlightVariant="small" />
        <Typography color="secondary" textAlign="center">
          {t('Other.sellText')}
        </Typography>
        <SellCardForm form={form} onSubmit={handleSubmit} />
      </Stack>
    </StyledDialog>
  );
};
