import { styled } from '~shared/lib/styles';
import { Stack, Typography } from '~shared/ui';

export const BattleListHeadingLabel = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 600,
  lineHeight: 39 / 26,
  letterSpacing: -0.2,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 18,
    lineHeight: 30 / 18,
  },
}));

export const BattleListHeadingRoot = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  paddingBottom: 12,
  paddingTop: 12,
}));

export const BattleListHeadingRootSettings = styled(Stack)({
  display: 'flex',
});
