import { styled } from '~shared/lib/styles';

import { ToggleButton, toggleButtonClasses } from '../ToggleButton';

export const UIKitToggleButtonIcon = styled(ToggleButton, { name: 'ToggleButtonIcon' })(
  ({ theme }) => ({
    padding: 0,
    background: 'none',
    color: theme.palette.grey.A50,

    [`&:hover:not(.${toggleButtonClasses.disabled}):not(.${toggleButtonClasses.selected})`]: {
      background: 'none',
      color: theme.palette.text.secondary,
    },

    [`&.${toggleButtonClasses.selected}`]: {
      background: 'none',
      color: theme.palette.text.primary,
    },

    [`&.${toggleButtonClasses.disabled}`]: {
      opacity: 0.3,
    },
  })
);
