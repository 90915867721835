import { styled } from '~shared/lib/styles';
import { Avatar, avatarClasses } from '~shared/ui';

export const battleVersusClasses = {
  root: 'BattleVersus-root',

  square: 'BattleVersus-square',
  line: 'BattleVersus-line',
  compact: 'BattleVersus-compact',

  sm: 'BattleVersus-sm',

  hasScore: 'BattleVersus-hasScore',

  slot: 'BattleVersus-slot',
  slotUserAvatar: 'BattleVersus-slotUserAvatar',
  content: 'BattleVersus-content',
  badge: 'BattleVersus-badge',
};

export const BattleVersusRoot = styled('div')(({ theme }) => ({
  [`&.${battleVersusClasses.compact}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto 1fr',
    alignItems: 'center',
    position: 'relative',

    [`& .${battleVersusClasses.content}`]: {
      color: theme.palette.grey.A50,

      fontSize: 16,
      fontWeight: 600,
      lineHeight: 18 / 16,
      letterSpacing: -0.2,

      padding: '6px 14px',

      display: 'flex',
      alignItems: 'center',
    },

    [`& .${battleVersusClasses.badge}`]: {
      marginRight: 14,
      marginLeft: -8,
    },

    [`& .${battleVersusClasses.slot}`]: {
      position: 'absolute',
      height: 1,
      display: 'flex',
      alignItems: 'center',
      right: 0,

      [`& .${battleVersusClasses.slotUserAvatar}`]: {
        marginRight: 8,
      },
    },
  },

  [`&.${battleVersusClasses.line}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',

    [`& .${battleVersusClasses.badge}`]: {
      position: 'absolute',
      top: -2,
      zIndex: 1,
    },

    [`& .${battleVersusClasses.slot}`]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',

      [`& .${battleVersusClasses.slotUserAvatar}`]: {
        position: 'absolute',
        bottom: -6,
      },
    },
  },

  [`&.${battleVersusClasses.square}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
    position: 'relative',

    [`&.${battleVersusClasses.sm}`]: {
      width: 40,
      height: 40,
    },

    [`&:not(.${battleVersusClasses.sm})`]: {
      width: 48,
      height: 48,
    },

    [`& .${battleVersusClasses.badge}`]: {
      position: 'absolute',
      top: -32,
      zIndex: 1,
    },

    [`& .${battleVersusClasses.slot}`]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',

      [`& .${battleVersusClasses.slotUserAvatar}`]: {
        position: 'absolute',
        bottom: -6,
      },
    },
  },

  [`&.${battleVersusClasses.hasScore}`]: {
    [`& .${battleVersusClasses.content}`]: {
      color: theme.palette.text.primary,
    },
  },
}));

export const BattleVersusContent = styled('div')(() => ({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: 24 / 18,
  letterSpacing: -0.2,
}));

export const BattleVersusSlot = styled('div')(() => ({}));

export const BattleVersusSlotUserAvatar = styled(Avatar)(({ theme }) => ({
  position: 'relative',
  boxShadow: '0px 0px 4px 0px rgba(16, 16, 16, 0.95)',

  [`&.${avatarClasses.hasSource}`]: {
    background: 'white',
  },

  [`& .${avatarClasses.img}`]: {
    objectFit: 'cover',
  },

  ['&::after']: {
    content: '""',
    display: 'block',
    position: 'absolute',
    inset: 0,
    borderRadius: '100%',
    boxShadow: `inset 0 0 0 1.5px ${theme.palette.grey.A30}`,
  },
}));

export const BattleVersusBadge = styled('div')(() => ({}));

export const BattleVersusDivider = styled('div', {
  name: 'BattleVersus',
  slot: 'dividerStart',
})(({ theme }) => ({
  background: theme.palette.grey.A10,
  height: 1,
  flex: 1,
}));
