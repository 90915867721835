import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';

import { ApiGetAuctionCardMappedData } from '~shared/api';
import { ContentPlaceholder, WalletPlaceholder } from '~shared/ui';

import { useAuctionCardsListEffects, useAuctionModel } from '~features/auction';

import { AuctionTab } from '../types';

import { AuctionSellCard } from './AuctionSellCard';
import { AuctionSellCardShimmer } from './AuctionSellCardShimmer';
import { AuctionListRoot } from './styled';
import { AuctionCardsTypeProps } from './types';

export const AuctionsListContainer: FC<AuctionCardsTypeProps> = ({ auctionCards, type }) => {
  const { isLoading, handleOpenCardDetailedInfo } = useAuctionModel();
  const { account } = useWeb3React();
  const isConnected = Boolean(account);
  const TYPE_TO_PLACEHOLDER_LABELS_MAP = useTranslationAuction();

  useAuctionCardsListEffects();

  const renderAuctionCard = (card: ApiGetAuctionCardMappedData) => {
    return (
      <AuctionSellCard key={card.tokenId} card={card} onOpenCardInfo={handleOpenCardDetailedInfo} />
    );
  };

  const hasCards = auctionCards.length !== 0;

  switch (true) {
    case isLoading:
      return <AuctionListRoot>{cardsShimmer}</AuctionListRoot>;
    case !isConnected && type !== AuctionTab.All:
      return <WalletPlaceholder />;
    case hasCards:
      return <AuctionListRoot>{auctionCards.map(renderAuctionCard)}</AuctionListRoot>;
    default:
      return <ContentPlaceholder {...TYPE_TO_PLACEHOLDER_LABELS_MAP[type]} />;
  }
};

const cardsShimmer = Array.from(Array(12).keys()).map((key) => (
  <AuctionSellCardShimmer key={key} />
));

// export const TYPE_TO_PLACEHOLDER_LABELS_MAP: Record<
//   AuctionTab,
//   { heading: string; message: string }
// > = {
//   [AuctionTab.All]: {
//     heading: "There's nothing here",
//     message: 'No one has put any cards up for sale yet',
//   },
//   [AuctionTab.MyBids]: {
//     heading: "There's nothing here",
//     message: "You haven't placed a bids yet",
//   },
//   [AuctionTab.MySales]: {
//     heading: "There's nothing here",
//     message: "You don't have any sales yet",
//   },
// };

export const useTranslationAuction = () => {
  const { t } = useTranslation();

  const TYPE_TO_PLACEHOLDER_LABELS_MAP: Record<AuctionTab, { heading: string; message: string }> = {
    [AuctionTab.All]: {
      heading: `${t('Marketplace.nothingHere')}`,
      message: `${t('Marketplace.textNothing')}`,
    },
    [AuctionTab.MyBids]: {
      heading: `${t('Marketplace.nothingHere')}`,
      message: `${t('Marketplace.youHavent')}`,
    },
    [AuctionTab.MySales]: {
      heading: `${t('Marketplace.nothingHere')}`,
      message: `${t('Other.SalesYet')}`,
    },
  };

  return TYPE_TO_PLACEHOLDER_LABELS_MAP;
};
