import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentPlaceholder } from '~shared/ui';

import { BattleSeparatedHorizontalList, useCallList } from '~entities/battle';
import { useCallsListQuery } from '~entities/calls';

import { useViewerSelector } from '~entities/viewer';

import { ViewerCallsListProps } from './types';

export const ViewerCallsList: FC<ViewerCallsListProps> = ({ sport, league, divider }) => {
  const { t } = useTranslation();

  const { wallet } = useViewerSelector();

  const callsListQuery = useCallsListQuery({ address: wallet, sport, league });
  const { passedList, currentList } = useCallList({ list: callsListQuery.list });

  if (callsListQuery.isEmptyList) {
    return (
      <ContentPlaceholder
        heading={t('Other.NoCalls')}
        message={sport ? `${t('Other.notHave')}` : `${t('Other.NoBetYet')}`}
      />
    );
  }

  return (
    <BattleSeparatedHorizontalList
      loading={callsListQuery.isLoading}
      passedList={passedList}
      currentList={currentList}
      divider={divider}
    />
  );
};
