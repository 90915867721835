import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

export const dateLabelClasses = {
  root: 'DateLabel-root',

  primary: 'DateLabel-primary',
  active: 'DateLabel-active',
  live: 'DateLabel-live',

  sm: 'DateLabel-sm',
  md: 'DateLabel-md',
  lg: 'DateLabel-lg',
};

export const UIKitDateLabelRoot = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  userSelect: 'none',

  paddingLeft: 10,
  paddingRight: 10,
  height: 44,

  fontWeight: 500,
  fontSize: 13,
  lineHeight: 14 / 13,

  borderRadius: 8,

  [`&.${dateLabelClasses.sm}`]: {
    height: 24,

    fontSize: 10,
    lineHeight: 12 / 10,
    textTransform: 'uppercase',
    borderRadius: 4,
  },

  [`&.${dateLabelClasses.md}`]: {
    height: 32,
    paddingLeft: 12,
    paddingRight: 12,

    fontSize: 13,
    lineHeight: 14 / 13,
  },

  [`&.${dateLabelClasses.primary}`]: {
    background: theme.palette.grey.B90,
  },

  [`&.${dateLabelClasses.active}`]: {
    background: theme.palette.error.main,
  },

  [`&.${dateLabelClasses.live}`]: {
    background: theme.palette.violet.main,
  },
}));
