import { BigNumber, ethers } from 'ethers';

import { Nft, PartialNft } from '~shared/api';
import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';

export const mapNftInfoFromMulticallRequest = (
  nftsList: Array<Partial<Nft> & Pick<Nft, 'token_id'>>,
  nftsMulticallRequestsResponse: string[]
): Array<PartialNft> => {
  const result = nftsMulticallRequestsResponse;

  return nftsList.map((nft, index) => {
    let i = index * 7;

    const winStreak = Number(BigNumber.from(result[i++]));
    const rarity = Number(BigNumber.from(result[i++]));
    const livesRemaining = Number(BigNumber.from(result[i++]));
    const rewardForCorrectVote = Number(ethers.utils.formatEther(BigNumber.from(result[i++])));
    const instantRestorationCost = Number(ethers.utils.formatEther(BigNumber.from(result[i++])));
    const availableAmountOfRecoveries = Number(BigNumber.from(result[i++]));

    const frozenUntil = new Date(
      ethers.BigNumber.from('0x' + result[i++].slice(66)).toNumber() * 1000
    );

    const readableRarity = NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[rarity];
    const isFreezed = new Date() < frozenUntil;

    return {
      ...nft,
      winStreak,
      rarity,
      livesRemaining,
      rewardForCorrectVote,
      instantRestorationCost,
      availableAmountOfRecoveries,
      readableRarity,
      isFreezed,
      frozenUntil,
    };
  });
};
