import { styled } from '~shared/lib/styles';

export const AuctionListRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: 20,

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },

  [theme.breakpoints.down(928)]: {
    gridTemplateColumns: '1fr 1fr',
  },

  [theme.breakpoints.down(674)]: {
    gridTemplateColumns: '1fr',
  },
}));
