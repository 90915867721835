import { useMemo } from 'react';

import { subMonths, subWeeks } from 'date-fns';

import { CallsDashboardPeriod, useCallsListQuery } from '~entities/calls';
import { useViewerSelector } from '~entities/viewer';

import { isEventCurrent, isEventNotStarted, isEventPassed } from '~entities/battle';

export const useCallsDashboardQuery = (period: CallsDashboardPeriod) => {
  const { wallet } = useViewerSelector();

  const callsQuery = useCallsListQuery({ address: wallet });

  const dashboard = useMemo(() => {
    const dashboard = {
      total: 0,
      future: 0,
      wins: 0,
      loses: 0,
      accuracy: 0,
    };

    if (callsQuery.list) {
      const currentDate = new Date();
      const weekAgoDate = subWeeks(currentDate, 1);
      const monthAgoDate = subMonths(currentDate, 1);

      const calls =
        period === 'all'
          ? callsQuery.list
          : callsQuery.list.filter((call) => {
              if (period === 'month') {
                const isCallMadeInLastMonth = call.date > monthAgoDate;

                return isCallMadeInLastMonth;
              }

              const isCallMadeInLastWeek = call.date > weekAgoDate;

              return isCallMadeInLastWeek;
            });

      calls.forEach((call) => {
        if (isEventPassed(call.result)) {
          dashboard.total++;

          const viewerChoice =
            call.firstParticipant?.address.toLowerCase() === wallet.toLowerCase()
              ? call.firstParticipant.choiceId
              : call.secondParticipant?.choiceId;

          if (call.result === viewerChoice) {
            dashboard.wins++;
          } else {
            dashboard.loses++;
          }
        } else if (isEventNotStarted(call.date) || isEventCurrent(call.result)) {
          dashboard.future++;
        }
      });
    }

    if (dashboard.wins !== 0) {
      dashboard.accuracy = dashboard.wins / dashboard.total;
    }

    return dashboard;
  }, [callsQuery.list, period, wallet]);

  return {
    isLoading: callsQuery.isLoading,
    isEmpty: callsQuery.isEmptyList,
    dashboard,
  };
};
