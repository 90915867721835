import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseTextField } from '~shared/ui';
import { validateEmail } from '~shared/lib/validation';

import { EmailFieldProps } from './types';

export const EmailField: FC<EmailFieldProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <BaseTextField
      name="email"
      label="Email"
      required
      {...props}
      rules={{
        validate: (email) => validateEmail(email) || t('Other.wrongEmail'),
        ...props?.rules,
      }}
    />
  );
};
