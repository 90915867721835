import { Fragment, ReactNode } from 'react';

import { Link } from '../../ui/navigation';

interface WrapTransactionMessage {
  (message?: string): ReactNode;
  (message?: (link: ReactNode, inCaseMessage: ReactNode) => ReactNode): ReactNode;
}

export const wrapTransactionMessage: WrapTransactionMessage = (message) => {
  if (typeof message === 'function') {
    return message(link, inCaseMessage);
  }

  if (!message) {
    return <Fragment>There is some error during transaction. {defaultMessage}</Fragment>;
  }

  if (message && message.split('').reverse()[0] !== '.') {
    message = `${message}.`;
  }

  return (
    <Fragment>
      {message} {defaultMessage}
    </Fragment>
  );
};

const link = (
  <strong>
    <Link to="mailto:support@maincard.io" color="text.primary">
      support@maincard.io
    </Link>
  </strong>
);

const defaultMessage = (
  <Fragment>
    Try again. In case you face the same problem again, contact support team: {link}.
  </Fragment>
);

const inCaseMessage = (
  <Fragment>In case you face the same problem again, contact support team: {link}.</Fragment>
);
