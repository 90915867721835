export const isValueInObjectRecursive = (object: Object, value: unknown): boolean => {
  for (const key in object) {
    if (object[key] === value) {
      return true;
    }

    if (typeof object[key] === 'object') {
      const result = isValueInObjectRecursive(object[key], value);

      if (result) {
        return result;
      }
    }
  }

  return false;
};
