import { ConnectDropTarget, useDrop } from 'react-dnd';

import { useSnackbar } from '~shared/lib/hooks';
import { ApiGetBattlesMappedData, BattleResult, Nft } from '~shared/api';

import {
  NftDragItem,
  NftDragItemType,
  getNftByTokenId,
  isNftAvailableForMerge,
  useNftSelector,
} from '~entities/nft';
import { useEventModel } from '~entities/event';

// todo: fsd
import {
  checkWinstreakWarnDialogNotShown,
  useWinstreakWarningDialog,
} from '~features/event-dialog';

import { isEventPassed } from '../../lib';

interface UseDropBet {
  (params: {
    event: ApiGetBattlesMappedData;
    result: BattleResult;
    tokenIds: Array<string | number>;
    choice?: BattleResult;
  }): {
    highlight: boolean;
    isOverlapBackdropAvailable: boolean;
    ref: ConnectDropTarget;
  };
}

export const useDropBet: UseDropBet = ({ event, choice, tokenIds, result }) => {
  const { openWinstreakWarningDialog } = useWinstreakWarningDialog();
  const { openSnackbar } = useSnackbar();
  const { nfts } = useNftSelector();
  const { openEvent } = useEventModel();

  const handleOpenEvent = (nft: Nft) => {
    const cards = tokenIds.map((tokenId) => getNftByTokenId(nfts, tokenId.toString())!);

    openEvent(event, { choice, cards, additionalCards: [nft], isViewMode: true });
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    drop: async (item: NftDragItem) => {
      const nft = getNftByTokenId(nfts, item.tokenId);

      if (nft) {
        const isZeroLivesRemaining = nft.livesRemaining === 0;

        // todo: make shared checks (useDropBattle, useDropBattleSlot, useDropBet have same validation)
        if (isZeroLivesRemaining) {
          openSnackbar({ type: 'error', message: "You can't bet card with 0 lives remaining" });

          return;
        }

        const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

        // todo: make shared checks (useDropBattle, useDropBattleSlot, useDropBet have same validation)
        if (!isWinstreakDialogNotShown && isNftAvailableForMerge(nft).isAvailable) {
          openWinstreakWarningDialog(nft, choice!, () => {
            handleOpenEvent(nft);
          });

          return;
        }

        handleOpenEvent(nft);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: !isEventPassed(result),
      };
    },
  });

  return {
    highlight: canDrop && isOver,
    isOverlapBackdropAvailable: !isEventPassed(result),
    ref: dragRef,
  };
};
