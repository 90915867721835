import { Fragment } from 'react';

import { theme } from '~shared/lib/styles';
import { useDebouncedBooleanTransition } from '~shared/lib/hooks';

import { ConnectWalletOptions, ConnectYourWallet } from '~entities/wallet';

import { UserCards } from '~widgets/user-cards';

import { useUserSidebarModel } from '../model';

import { UserSidebarRoot } from './styled';

export const UserSidebar = () => {
  const { isConnected, isWalletBoundToEmail, isModalOverlap } = useUserSidebarModel();

  const debouncedIsModalOverlap = useDebouncedBooleanTransition(isModalOverlap, {
    strategy: 'end',
  });

  return (
    <UserSidebarRoot
      style={{ zIndex: debouncedIsModalOverlap ? theme.zIndex.modalOverlap : 'initial' }}
    >
      {isConnected ? (
        <UserCards />
      ) : (
        <Fragment>
          {isWalletBoundToEmail ? <ConnectWalletOptions /> : <ConnectYourWallet />}
        </Fragment>
      )}
    </UserSidebarRoot>
  );
};
