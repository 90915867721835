import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Nft, NftRelatedBet, NftRelatedCall, PartialNft } from '~shared/api';

import { NFT_RARITY_TO_MAX_LIVES_MAP, NftPreviewInfo, NftSelectionModeType } from '~entities/nft';

import {
  NftActionDialog,
  NftActionDialogSetPayload,
  NftDetailedInfo,
  NftState,
  NftToMergeSerialNumber,
  SetNftHiddenPayload,
  SetNftsToMergePayload,
} from './types';

const initialDialogState: NftActionDialog = {
  nfts: [],
  open: false,
};

const initialState: NftState = {
  nfts: [],
  nftsToMerge: [null, null],
  isDropZoneOpen: false,
  isSelectionMode: false,
  selectionModeType: 'bet',

  dialogs: {
    sell: initialDialogState,
    repair: initialDialogState,
    merge: initialDialogState,
  },
  previewInfo: {
    open: false,
    nft: null,
  },
  detailedInfo: {
    open: false,
    tokenId: '',
    avatarUrl: '',
    nickname: '',
    walletAddress: '',
    viewMode: false,
  },
};

export const nftSlice = createSlice({
  name: 'wallet',
  initialState: initialState,
  reducers: {
    setNfts: (state, action: PayloadAction<Array<Nft>>) => {
      state.nfts = action.payload;
    },
    setNftsToMerge: (state, { payload }: PayloadAction<SetNftsToMergePayload>) => {
      if (state.nftsToMerge[0]) {
        state.nftsToMerge[1] = payload.nft;
      } else {
        state.nftsToMerge[0] = payload.nft;
      }

      state.nfts = state.nfts.map((nft) => {
        if (nft.token_id === payload.nft.token_id) {
          return {
            ...nft,
            isHidden: true,
          };
        }

        return nft;
      });
    },
    removeNftToMerge: (state, { payload }: PayloadAction<Array<NftToMergeSerialNumber>>) => {
      payload.forEach((serialNumber) => {
        state.nftsToMerge[serialNumber] = null;
      });

      state.isDropZoneOpen = false;
    },
    mergeCards: (state, { payload }: PayloadAction<Array<Nft>>) => {
      state.nftsToMerge = [null, null];

      state.nfts = state.nfts.filter(
        (nft) => !payload.some((card) => card.token_id === nft.token_id)
      );

      state.isDropZoneOpen = false;
    },
    removeNft: (state, { payload }: PayloadAction<string>) => {
      state.nfts = state.nfts.filter((nft) => nft.token_id !== payload);
    },
    takeCardFromAuction: (state, { payload: tokenId }: PayloadAction<string>) => {
      state.nfts = state.nfts.map((nft) => {
        if (nft.token_id === tokenId) {
          return {
            ...nft,
            relatedAuction: undefined,
            isOnAuction: false,
          };
        }

        return nft;
      });
    },

    repairNft: (state, { payload }: PayloadAction<string>) => {
      state.nfts = state.nfts.map((nft) => {
        if (nft.token_id === payload) {
          return {
            ...nft,
            livesRemaining: NFT_RARITY_TO_MAX_LIVES_MAP[nft.rarity],
            availableAmountOfRecoveries:
              nft.availableAmountOfRecoveries === 0 ? 0 : nft.availableAmountOfRecoveries - 1,
          };
        }

        return nft;
      });
    },
    setDialogs: (state, { payload }: PayloadAction<NftActionDialogSetPayload>) => {
      state.dialogs[payload.action] = {
        ...state.dialogs[payload.action],
        ...payload.dialog,
      };
    },
    setMergeDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.dialogs.merge.open = action.payload;
    },
    setDropZone: (state, { payload }: PayloadAction<boolean>) => {
      state.isDropZoneOpen = payload;
    },
    clearEventCards: (state, { payload }: PayloadAction<{ cards: Array<Nft | PartialNft> }>) => {
      state.nfts = state.nfts.map((nft) => {
        const isCardToUnhide = payload.cards.some((card) => card.token_id === nft.token_id);

        if (isCardToUnhide) {
          return { ...nft, isHidden: false };
        }

        return nft;
      });
    },
    removeAllCardsFromEvent: (state, { payload }: PayloadAction<string>) => {
      state.nfts = state.nfts.map((nft) => {
        if (nft.relatedEvent?.id === payload) {
          return {
            ...nft,
            isBlockedForEvent: false,
            isOnEvent: false,
            relatedEvent: undefined,
            isHidden: false,
          };
        }

        return nft;
      });
    },
    setNftHidden: (state, { payload }: PayloadAction<SetNftHiddenPayload>) => {
      state.nfts = state.nfts.map((nft) => {
        if (payload.nftIds.includes(nft.token_id)) {
          return {
            ...nft,
            isHidden: payload.isHidden,
          };
        }

        return nft;
      });
    },
    addNft: (state, { payload }: PayloadAction<Nft>) => {
      state.nfts = [payload, ...state.nfts];
    },
    sellCard: (state, { payload }: PayloadAction<string>) => {
      state.nfts = state.nfts.map((nft) => {
        if (nft.token_id === payload) {
          return {
            ...nft,
            isOnAuction: true,
          };
        }

        return nft;
      });
    },
    makeBet: (
      state,
      {
        payload,
      }: PayloadAction<{
        event: NftRelatedBet | NftRelatedCall;
        cardIds: Array<string>;
      }>
    ) => {
      const relatedEventNfts = state.nfts
        .filter((nft) => payload.cardIds.includes(nft.token_id))
        .map((nft) => ({ ...nft, isOnEvent: true }));

      const relatedEvent = { ...payload.event, cards: relatedEventNfts };

      // @ts-ignore
      state.nfts = state.nfts.map((nft) => {
        const isCardToBet = payload.cardIds.includes(nft.token_id);

        if (isCardToBet) {
          return {
            ...nft,
            relatedEvent,
            isOnEvent: true,
          };
        }

        return nft;
      });
    },
    removeCardFromEvent: (
      state,
      { payload }: PayloadAction<{ tokenId: string; eventId: string }>
    ) => {
      let relatedEventCardsWithoutRemovedCard: Nft[] = [];

      state.nfts = state.nfts.map((nft) => {
        if (nft.token_id === payload.tokenId) {
          return {
            ...nft,
            isBlockedForEvent: false,
            isOnEvent: false,
            relatedEvent: undefined,
          };
        } else if (nft.relatedEvent && nft.relatedEvent.id === payload.eventId) {
          if (relatedEventCardsWithoutRemovedCard.length === 0) {
            relatedEventCardsWithoutRemovedCard = nft.relatedEvent.cards.filter(
              (card) => card.token_id !== payload.tokenId
            ) as Nft[];
          }

          nft.relatedEvent.cards = relatedEventCardsWithoutRemovedCard;
        }

        return nft;
      });
    },
    setPreviewInfo: (state, { payload }: PayloadAction<Partial<NftPreviewInfo>>) => {
      state.previewInfo = { ...state.previewInfo, ...payload };
    },
    setDetailedInfo: (state, { payload }: PayloadAction<Partial<NftDetailedInfo>>) => {
      state.detailedInfo = { ...state.detailedInfo, ...payload };
    },
    resetEventDialog: (state) => {
      state.nfts = state.nfts.map((nft) => ({ ...nft, isHidden: false }));
    },
    resetDetailedInfo: (state) => {
      state.detailedInfo = {
        open: false,
        tokenId: '',
        nickname: '',
        avatarUrl: '',
        walletAddress: '',
        viewMode: false,
      };
    },
    setSelectionMode: (state, action: PayloadAction<boolean>) => {
      state.isSelectionMode = action.payload;
    },
    setSelectionModeType: (state, action: PayloadAction<NftSelectionModeType>) => {
      state.selectionModeType = action.payload;
    },
  },
});

export const nftReducer = nftSlice.reducer;
export const nftActions = nftSlice.actions;
