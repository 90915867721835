import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from '~entities/nft';
import { MarketplaceItemShell } from '~entities/marketplace';

import { MarketplaceBuyMysteryBoxForm, MarketplaceCurrencyValue } from '~features/marketplace';

import { MarketplaceItemImage } from '../MarketplaceItemImage';

import { MarketplaceItemMysteryBoxProps } from './types';

export const MarketplaceItemMysteryBox: FC<MarketplaceItemMysteryBoxProps> = ({
  marketplaceMysteryBox,
}) => {
  const { t } = useTranslation();

  const [currency, setCurrency] = useState<MarketplaceCurrencyValue>(
    MarketplaceCurrencyValue.Matic
  );

  const readableRarity = t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[marketplaceMysteryBox.rarity]);

  return (
    <MarketplaceItemShell
      icon={<MarketplaceItemImage src={marketplaceMysteryBox.imagePath} alt="Mystery box" />}
      name={`${readableRarity} Mystery box`}
      description={<Fragment>{t('Marketplace.mysteryBoxDescription')}</Fragment>}
    >
      <MarketplaceBuyMysteryBoxForm
        rarity={marketplaceMysteryBox.rarity}
        currency={currency}
        onChangeCurrency={setCurrency}
      />
    </MarketplaceItemShell>
  );
};
