import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';

import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
  StaticNftCard,
} from '~entities/nft';
import { useAuctionAcceptBetTimer, useAuctionModel } from '~features/auction';

import { getAvatarAbsoluteUri } from '~shared/lib/utils';
import { Avatar, Divider, LinearProgress, Typography } from '~shared/ui';

import {
  AuctionBettorBrief,
  AuctionSellCardBets,
  BestAuctionListContainer,
  BestAuctionRightContainer,
  BidButton,
  BidContainer,
  CardInfo,
  CardInfoBlock,
  RareInfoCard,
} from './styled';
import { BestAuctionProps } from './types';

export const BestAuctionList: FC<BestAuctionProps> = ({ card, onOpenCardInfo }) => {
  const { t } = useTranslation();

  const { hoursLeft, minutesLeft, progress, accepted } = useAuctionAcceptBetTimer(
    card.betsAcceptedUntil
  );

  const { account } = useWeb3React();
  const bet = Number(ethers.utils.formatEther(card.bestBet));
  const startingPrice = Number(ethers.utils.formatEther(card.startingPrice));
  const betPrice = bet === 0 ? startingPrice + 1 : bet + 1;

  const avatars = card.bestBettorAvatar.includes('default_avatars')
    ? getAvatarAbsoluteUri(card.bestBettorAvatar.replace(' ', '%20'))
    : getAvatarAbsoluteUri(card.bestBettorAvatar);

  const { handlePermitPlaceBid, handlePlaceBid } = useAuctionModel();

  const handleSubmit = async () => {
    try {
      await handlePermitPlaceBid(String(betPrice));
      await handlePlaceBid(card.tokenId, String(betPrice));
    } catch {}
  };

  return (
    <BestAuctionListContainer>
      <StaticNftCard
        sx={{ cursor: 'pointer' }}
        tokenId={card.tokenId}
        height={125}
        width="max-content"
        mx="auto"
        marginTop={2}
        marginLeft={2}
        mr={2}
        onClick={onOpenCardInfo(
          card.creatorNickname,
          card.creatorAvatar,
          String(card.tokenId),
          card.creator
        )}
      />
      <BestAuctionRightContainer>
        <RareInfoCard>
          <Typography mt={10 / 8} fontWeight={600} fontSize={16} mr={1}>
            {t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[card.rarity])}
          </Typography>
          <Typography color="grey.A50" mt={10 / 8}>
            {accepted ? `${t('Marketplace.timeLeft')}` : null}{' '}
            <Typography fontWeight={500} component="span">
              {accepted ? `${hoursLeft} hr ${minutesLeft} min` : `${t('Marketplace.finished')}`}
            </Typography>
          </Typography>
        </RareInfoCard>
        <LinearProgress progress={progress} mt={12 / 8} height={4} progressColor="primary" />
        <CardInfo>
          <CardInfoBlock>
            <Typography color="grey.A50" fontSize={12}>
              Price
            </Typography>
            <Typography fontSize={15} fontWeight={600}>
              {card.bestBet === '0'
                ? Number(card.startingPrice) / 1e18
                : Number(card.bestBet) / 1e18}{' '}
              MCN
            </Typography>
          </CardInfoBlock>

          <Divider orientation="vertical" flexItem />

          <CardInfoBlock>
            <Typography color="grey.A50" fontSize={12}>
              Lives
            </Typography>
            <Typography fontSize={15} fontWeight={600}>
              {card.lives}/{NFT_RARITY_TO_MAX_LIVES_MAP[card.rarity]}
            </Typography>
          </CardInfoBlock>

          <Divider orientation="vertical" flexItem />

          <CardInfoBlock>
            <Typography color="grey.A50" fontSize={12}>
              Win streak
            </Typography>
            <Typography fontSize={15} fontWeight={600}>
              {card.winStreak}/{NFT_RARITY_TO_MAX_WIN_STREAK[card.rarity]}
            </Typography>
          </CardInfoBlock>
        </CardInfo>
        <Divider />
        <AuctionSellCardBets>
          {Number(card.bestBet) !== 0 ? (
            <BidContainer>
              <Typography color="secondary" fontSize={14}>
                Best bid
              </Typography>
              <AuctionBettorBrief>
                <Avatar src={avatars} size={20} />
                <Typography variant="h3" ml={0.5}>
                  {bet.toFixed(2)} MCN
                </Typography>
                <BidButton onClick={handleSubmit}>Bid</BidButton>
              </AuctionBettorBrief>
            </BidContainer>
          ) : (
            <BidContainer>
              {account !== card.creator ? (
                <>
                  <Typography color="secondary" fontSize={14}>
                    No bids yet
                  </Typography>
                </>
              ) : (
                <Typography color="secondary" marginTop={2} fontSize={14}>
                  No bids yet
                </Typography>
              )}
              <BidButton onClick={handleSubmit}>Bid</BidButton>
            </BidContainer>
          )}
        </AuctionSellCardBets>
      </BestAuctionRightContainer>
    </BestAuctionListContainer>
  );
};
