import { Swiper } from 'swiper/react';
import 'swiper/css';

import { styled } from '~shared/lib/styles';

import { slideClasses } from '../Slide';

export const slidesClasses = {
  root: 'Slides-root',
  container: 'Slides-container',

  sm: 'Slides-sm',
  md: 'Slides-md',
  lg: 'Slides-lg',
  responsive: 'Slides-responsive',
};

interface UIKitSlidesRootProps {
  slideHeight?: number;
}

export const UIKitSlidesRoot = styled(Swiper, { name: 'Slides' })<UIKitSlidesRootProps>(
  ({ theme, height }) => ({
    // selector override priority
    ['&.swiper']: {
      width: '100%',
      maxWidth: '100%',
      zIndex: 0,

      ['& .swiper-wrapper']: {
        zIndex: 0,
      },

      [`&.${slidesClasses.container}`]: {
        paddingLeft: 30,
        paddingRight: 30,

        [theme.breakpoints.down('mobile')]: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },

      [`& .${slideClasses.root}`]: {
        [`& .${slideClasses.img}`]: {
          height: height,
        },
      },

      [`&.${slidesClasses.sm}`]: {
        [`& .${slideClasses.root}`]: {
          [`& .${slideClasses.img}`]: {
            height: 157.5,
          },

          borderRadius: 12,
        },
      },

      [`&.${slidesClasses.md}`]: {
        [`& .${slideClasses.root}`]: {
          [`& .${slideClasses.img}`]: {
            height: 210,
          },

          borderRadius: 16,
        },
      },

      [`&.${slidesClasses.lg}`]: {
        [`& .${slideClasses.root}`]: {
          [`& .${slideClasses.img}`]: {
            height: 280,
          },

          borderRadius: 20,
        },
      },
    },
  })
);
