import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';
import { Dialog } from '../Dialog';

export const TransactionStatusDialogRoot = styled(Dialog)(({ theme }) => ({
  zIndex: theme.zIndex.transactionStatusDialog,
}));

export const Paper = styled(Box, {
  name: 'TransactionStatusDialog',
  slot: 'content',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
});
