import { useTranslation } from 'react-i18next';

/**
 * Event statistics
 */
export type EventStatisticKeys = 'wins' | 'goals' | 'losses' | string;

export const STATISTIC_SCORE_KEY_TO_LABEL_MAP: Record<EventStatisticKeys, string> = {
  wins: 'Win',
  goals: 'Goals',
  losses: 'Lose',
  draws: 'Draw',
};

export const useTranslationLabelMap = () => {
  const { t } = useTranslation();

  const STATISTIC_SCORE_KEY_TO_LABEL_MAP: Record<EventStatisticKeys, string> = {
    wins: `${t('BattlePopUp.onlyWin')}`,
    goals: `${t('BattlePopUp.goals')}`,
    losses: `${t('BattlePopUp.lose')}`,
    draws: `${t('Other.draw')}`,
  };

  return STATISTIC_SCORE_KEY_TO_LABEL_MAP;
};

export const EVENT_STATISTIC_KEYS: Array<EventStatisticKeys> = ['wins', 'goals', 'losses', 'draws'];
