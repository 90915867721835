import { styled } from '~shared/lib/styles';

export const LeaderboardHeadTable = styled('div')(({ theme }) => ({
  width: '100%',
  height: 44,
  display: 'grid',
  gridTemplateColumns: '65% repeat(3, 1fr)',
  marginTop: 24,
  alignContent: 'center',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
