import { styled } from '~shared/lib/styles';

import { Box } from '../../../layout';

export const UIKitBottomNavigationRoot = styled(Box, { name: 'BottomNavigation' })(({ theme }) => ({
  background: theme.palette.paper.blue,
  display: 'grid',
  gridAutoColumns: '1fr',
  gridAutoFlow: 'column',
  boxShadow: '0px -3px 12px rgba(0, 0, 0, 0.55)',
  position: 'relative',
  zIndex: theme.zIndex.bottomNavigation,
}));
