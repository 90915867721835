import { NFT_RARITY_TO_MAX_WIN_STREAK } from '~entities/nft';
import { Nft, NftRarity } from '~shared/api';

export const checkIsMergeAvailable = (nfts: Array<Nft>) => {
  let nftsWithWinStreak = 0;

  for (const nft of nfts) {
    if (nftsWithWinStreak === 2) {
      break;
    }

    if (nft.rarity !== NftRarity.Mythic) {
      const hasWinStreak = nft.winStreak >= NFT_RARITY_TO_MAX_WIN_STREAK[nft.rarity];

      if (hasWinStreak) {
        nftsWithWinStreak++;
      }
    }
  }

  return nftsWithWinStreak === 2;
};
