import { styled } from '~shared/lib/styles';
import { SportToAssetMaps } from '~shared/lib/assets';
import { Dialog, dialogClasses } from '~shared/ui';
import { ApiCommonSport } from '~shared/api';

interface StyledDesktopEventDialogProps {
  $sport: ApiCommonSport;
}

export const StyledDesktopEventDialog = styled(Dialog)<StyledDesktopEventDialogProps>(
  ({ theme, $sport }) => ({
    [`& .${dialogClasses.content}`]: {
      padding: 0,
      backgroundImage: `url(${SportToAssetMaps[$sport]})`,
      backgroundPositionY: 'top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },

    [`& .${dialogClasses.paper}`]: {
      backgroundColor: theme.palette.paper.secondary,
    },

    [`& .${dialogClasses.scrollPaper}`]: {
      marginRight: 320,
    },
  })
);
