import { Box, Divider } from '~shared/ui';

import { NftCardInfoHeader } from './NftCardInfoHeader';
import { NftCardInfoContent } from './NftCardInfoContent';
import { NftCardInfoActions } from './NftCardInfoActions';

import { NftCardInfoRelatedEvent } from './NftCardInfoRelatedEvent';

import { NftCardInfoRoot } from './styled';
import { NftCardInfoProps } from './types';

export const NftCardInfo = ({ nft }: NftCardInfoProps) => {
  const hasNotRelatedEvent = !Boolean(nft.relatedEvent);

  return (
    <NftCardInfoRoot spacing={20 / 8}>
      <NftCardInfoHeader nft={nft} />
      <NftCardInfoContent nft={nft} spacing={20 / 8} mt={1} p="0 16px" />

      <Box padding="0 16px">
        <NftCardInfoRelatedEvent nft={nft} />
      </Box>

      {hasNotRelatedEvent && (
        <Box p="0 16px">
          <Divider />
        </Box>
      )}

      <NftCardInfoActions nft={nft} />
    </NftCardInfoRoot>
  );
};
