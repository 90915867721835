import { FC, Fragment } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';

import {
  Avatar,
  BaseTextField,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '~shared/ui';

import { StaticNftCard } from '~entities/nft';

import { useAuctionAcceptBetTimer, useAuctionFunctions, useQueryParams } from '~features/auction';

import { getAvatarAbsoluteUri } from '~shared/lib/utils';

import { AuctionTab } from '../../types';

import {
  AuctionBettorBrief,
  AuctionSellCardBet,
  AuctionSellCardBets,
  AuctionSellCardBrief,
  AuctionSellCardProperties,
  AuctionSellCardPropertiesLabel,
  AuctionSellCardPropertiesRow,
  AuctionSellCardPropertiesValue,
  AuctionSellCardRoot,
  StyledChip,
} from './styled';

import { AuctionSellCardProps } from './types';
import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
} from './constants';

// todo: Refactor in same way as MarketplaceItem
export const AuctionSellCard: FC<AuctionSellCardProps> = ({ card, onOpenCardInfo }) => {
  const { t } = useTranslation();

  const query = useQueryParams();
  const tab = query.get('auctionTab');
  const { hoursLeft, minutesLeft, progress, accepted } = useAuctionAcceptBetTimer(
    card.betsAcceptedUntil
  );

  const avatars = card.bestBettorAvatar.includes('default_avatars')
    ? getAvatarAbsoluteUri(card.bestBettorAvatar.replace(' ', '%20'))
    : getAvatarAbsoluteUri(card.bestBettorAvatar);

  const { form, handleSubmit } = useAuctionFunctions(
    card.tokenId,
    card.bestBet,
    card.startingPrice,
    tab,
    accepted
  );

  const { account } = useWeb3React();

  const price = Number(ethers.utils.formatEther(card.startingPrice));
  const bet = Number(ethers.utils.formatEther(card.bestBet));
  const readableRarity = NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[card.rarity];

  return (
    <AuctionSellCardRoot>
      {account === card.creator && <StyledChip label="Your card" color="secondary" />}
      <AuctionSellCardBrief>
        {/* todo: resolve sizing (width, height) in StaticNftCard */}
        <StaticNftCard
          sx={{ cursor: 'pointer' }}
          tokenId={card.tokenId}
          height={128}
          width="max-content"
          mx="auto"
          onClick={onOpenCardInfo(
            card.creatorNickname,
            card.creatorAvatar,
            String(card.tokenId),
            card.creator
          )}
        />

        <Typography variant="h2" mt={20 / 8}>
          {readableRarity}
        </Typography>

        <Typography color="grey.A50" mt={10 / 8}>
          {accepted ? `${t('Marketplace.timeLeft')}` : null}{' '}
          <Typography fontWeight={500} component="span">
            {accepted ? `${hoursLeft} hr ${minutesLeft} min` : `${t('Marketplace.finished')}`}
          </Typography>
        </Typography>

        <LinearProgress progress={progress} mt={12 / 8} height={4} progressColor="primary" />
      </AuctionSellCardBrief>

      <AuctionSellCardProperties>
        <AuctionSellCardPropertiesRow>
          <AuctionSellCardPropertiesLabel>
            {' '}
            {t('Marketplace.reservePrice')}
          </AuctionSellCardPropertiesLabel>
          <AuctionSellCardPropertiesValue>{price.toFixed(2)} MCN</AuctionSellCardPropertiesValue>
        </AuctionSellCardPropertiesRow>

        <AuctionSellCardPropertiesRow>
          <AuctionSellCardPropertiesLabel>{t('Marketplace.lives')}</AuctionSellCardPropertiesLabel>
          <AuctionSellCardPropertiesValue>
            {card.lives}/{NFT_RARITY_TO_MAX_LIVES_MAP[Number(card.rarity)]}
          </AuctionSellCardPropertiesValue>
        </AuctionSellCardPropertiesRow>

        <AuctionSellCardPropertiesRow>
          <AuctionSellCardPropertiesLabel>
            {t('Marketplace.winStreak')}
          </AuctionSellCardPropertiesLabel>
          <AuctionSellCardPropertiesValue>
            {card.winStreak}/{NFT_RARITY_TO_MAX_WIN_STREAK[Number(card.rarity)]}
          </AuctionSellCardPropertiesValue>
        </AuctionSellCardPropertiesRow>
      </AuctionSellCardProperties>

      <Divider />

      <AuctionSellCardBets>
        {Number(card.bestBet) !== 0 ? (
          <AuctionSellCardBet>
            <AuctionBettorBrief>
              <Avatar src={avatars} size={37} />
              <Typography color="secondary" marginLeft={1}>
                {card.bestBettorNickname}
              </Typography>
            </AuctionBettorBrief>

            <Typography variant="h3">{bet.toFixed(2)} MCN</Typography>
          </AuctionSellCardBet>
        ) : (
          <Fragment>
            {account !== card.creator ? (
              <>
                <Typography color="secondary">{t('Other.noBets')}</Typography>
              </>
            ) : (
              <Typography color="secondary" marginTop={2}>
                {t('Marketplace.Nobets')}
              </Typography>
            )}
          </Fragment>
        )}
      </AuctionSellCardBets>

      <Divider />

      <FormProvider {...form}>
        <Stack
          alignItems="flex-start"
          onSubmit={handleSubmit}
          component="form"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={14 / 8}
          marginTop={20 / 8}
        >
          {(tab === AuctionTab.All || tab === null || (tab === AuctionTab.MyBids && accepted)) &&
            account !== card.creator && (
              <>
                <BaseTextField
                  type="number"
                  name="price"
                  endAdornment="MCN"
                  placeholder={`${bet ? bet + 1 : 10}`}
                />
                <Button type="submit">{t('Marketplace.placeABit')}</Button>
              </>
            )}
        </Stack>
      </FormProvider>
    </AuctionSellCardRoot>
  );
};
