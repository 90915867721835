import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, Icon, Typography } from '~shared/ui';
import { routes } from '~shared/config';

import { useUserCardsModel } from '../../model';

import {
  UserCardsHeaderAvailableUnfreezeAmount,
  UserCardsHeaderRoot,
  UserCardsHeaderUnfreezeContainer,
} from './styled';

import { UserCardsHeaderProps } from './types';

export const UserCardsHeader: FC<UserCardsHeaderProps> = () => {
  const { t } = useTranslation();

  const { selectionModeType } = useUserCardsModel();

  switch (selectionModeType) {
    case 'bet':
      return (
        <UserCardsHeaderRoot>
          <Typography variant="h2">{t('Sidebar.myCards')}</Typography>
          <UserCardsHeaderUnfreezeContainer>
            <Icon name="snowflake" />
            {/* TODO: add available unfreeze amount */}
            <UserCardsHeaderAvailableUnfreezeAmount>10/10</UserCardsHeaderAvailableUnfreezeAmount>
            <Link to={routes.marketplace}>
              <ButtonIcon size={20} name="plus" disabled />
            </Link>
          </UserCardsHeaderUnfreezeContainer>
        </UserCardsHeaderRoot>
      );
    case 'merge':
      return (
        <UserCardsHeaderRoot>
          <Typography variant="h2">Select cards to merge</Typography>
        </UserCardsHeaderRoot>
      );
  }
};
