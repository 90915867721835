import { styled } from '~shared/lib/styles';

interface NftStackRootProps {
  spacing: number;
}

export const NftStackRoot = styled('div', { name: 'NftStack' })<NftStackRootProps>(
  ({ spacing }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    ['& > *:not(:first-child)']: {
      marginLeft: -spacing,
    },
  })
);
