import { useDrop } from 'react-dnd';

import { ConnectDropTarget } from 'react-dnd/src/types';

import { ApiGetBattlesMappedData, BattleResult, Nft, PartialNft } from '~shared/api';
import { useSnackbar } from '~shared/lib/hooks';

import {
  NftDragItem,
  NftDragItemType,
  getNftByTokenId,
  isNftAvailableForMerge,
  useNftSelector,
} from '~entities/nft';
import { useEventModel } from '~entities/event';

// todo: fsd
import {
  checkWinstreakWarnDialogNotShown,
  useWinstreakWarningDialog,
} from '~features/event-dialog';

interface UseDropBattle {
  (props: {
    event: ApiGetBattlesMappedData;
    enabled: boolean;
    choice?: BattleResult;
    cards?: Array<Nft | PartialNft>;
  }): {
    dragRef: ConnectDropTarget;
    isOver: boolean;
    canDrop: boolean;
  };
}

export const useDropBattle: UseDropBattle = ({ event, enabled, choice, cards = [] }) => {
  const { openSnackbar } = useSnackbar();

  const { openWinstreakWarningDialog } = useWinstreakWarningDialog();
  // todo: useWalletModel
  const { nfts } = useNftSelector();
  const { openEvent } = useEventModel();

  const handleOpenEvent = (nft: Nft) => {
    openEvent(event, { choice, cards, additionalCards: [nft], isViewMode: true });
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    canDrop: () => enabled,
    drop: async (item: NftDragItem) => {
      const nft = getNftByTokenId(nfts, item.tokenId);

      if (nft) {
        const isZeroLivesRemaining = nft.livesRemaining === 0;

        // todo: make shared checks (useDropBattle, useDropBattleSlot, useDropBet have same validation)
        if (isZeroLivesRemaining) {
          openSnackbar({ type: 'error', message: "You can't bet card with 0 lives remaining" });

          return;
        }

        const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

        // todo: make shared checks (useDropBattle, useDropBattleSlot, useDropBet have same validation)
        if (!isWinstreakDialogNotShown && isNftAvailableForMerge(nft).isAvailable) {
          openWinstreakWarningDialog(nft, choice!, () => {
            handleOpenEvent(nft);
          });

          return;
        }

        handleOpenEvent(nft);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: enabled,
      };
    },
  });

  return {
    dragRef,

    isOver,
    canDrop,
  };
};
