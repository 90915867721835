import { ChangeEvent, FC, KeyboardEvent, useCallback, useRef, useState } from 'react';

import { Stack } from '~shared/ui';

import { CodeInputInput } from './styled';
import { CodeInputProps } from './types';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

const makeInputsByValue = (length: number, value: string) => {
  const val = [];

  for (let i = 0; i < length; i += 1) {
    val.push(value[i] || '');
  }

  return val;
};

export const CodeInput: FC<CodeInputProps> = ({
  length = 6,
  forceUppercase,
  type,
  autoFocus,

  ...props
}) => {
  const [input, setInput] = useState<any[]>(makeInputsByValue(length, props.value ?? ''));

  const refs = useRef<HTMLInputElement[] | []>([]);
  const textInput = refs.current;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!(e.target instanceof HTMLInputElement && e.target.dataset.id)) {
        return;
      }

      const targetId = Number(e.target.dataset.id);

      let value = String(e.target.value);

      if (forceUppercase) {
        value = value.toUpperCase();
      }

      if (type === 'number') {
        value = value.replace(/\D/g, '');
      }

      let lastTouchedId = targetId;

      if (value !== '') {
        const _input = input.slice();

        if (value.length > 1) {
          value.split('').map((chart, i) => {
            if (targetId + i < length) {
              _input[targetId + i] = chart;
              lastTouchedId = targetId + i;
            }

            return false;
          });
        } else {
          _input[targetId] = value;
          lastTouchedId = Math.min(lastTouchedId + 1, length);
        }

        _input.map((s, i) => {
          if (textInput[i]) {
            textInput[i].value = s;
          }

          return false;
        });

        const newTarget = textInput[lastTouchedId];

        if (newTarget) {
          newTarget.focus();
        }

        setInput(_input);

        if (props.onChange) {
          props.onChange(_input.join(''));
        }
      }
    },
    [forceUppercase, input, length, props, textInput, type]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }

      const target = Number(e.target.dataset.id);
      const nextTarget = textInput[target + 1];
      const prevTarget = textInput[target - 1];

      let inputs;
      let value;

      switch (e.keyCode) {
        case BACKSPACE_KEY:
          e.preventDefault();
          textInput[target].value = '';
          inputs = input.slice();
          inputs[target] = '';
          value = inputs.join('');

          setInput(inputs);

          if (textInput[target].value === '') {
            if (prevTarget) {
              prevTarget.focus();
              prevTarget.select();
            }
          }

          if (props.onChange) {
            props.onChange(value);
          }

          break;

        case LEFT_ARROW_KEY:
          e.preventDefault();

          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }

          break;

        case RIGHT_ARROW_KEY:
          e.preventDefault();

          if (nextTarget) {
            nextTarget.focus();
            nextTarget.select();
          }

          break;

        case UP_ARROW_KEY:
        case DOWN_ARROW_KEY:
          e.preventDefault();
          break;

        // This case needs to be handled because of
        // https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        case E_KEY:
          if (e.target.type === 'number') {
            e.preventDefault();
          }

          break;

        default:
          break;
      }
    },
    [input, props, textInput]
  );

  return (
    <Stack spacing={14 / 8} direction="row" justifyContent="center" width={1}>
      {input.map((value, i) => {
        const handleFocus = () => {
          const currentInput = textInput[i];

          if (currentInput) {
            currentInput.select();
          }
        };

        const handleRef = (ref: HTMLInputElement | null) => {
          return ref && (refs.current[i] = ref);
        };

        return (
          <CodeInputInput
            {...props}
            key={i}
            autoFocus={i === 0 ? autoFocus : undefined}
            data-id={i}
            value={value}
            inputRef={handleRef}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        );
      })}
    </Stack>
  );
};
