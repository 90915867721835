import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgressView, ContentPlaceholder, Heading, Stack } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';
import { ApiGetCallsMappedData } from '~shared/api';

import { BattleCall } from '~entities/battle';

import { EventsContainer } from './styled';
import { NftCardDetailedInfoCallsProps } from './types';

export const NftCardDetailedInfoCalls: FC<NftCardDetailedInfoCallsProps> = ({ nftCallsQuery }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const { currentList, passedList, isLoading, isEmptyList } = nftCallsQuery;

  const hasCurrentEvents = currentList && currentList.length > 0;
  const hasPastEvents = passedList && passedList.length > 0;

  const renderCall = (call: ApiGetCallsMappedData) => (
    <BattleCall key={call.callId} {...call} size="sm" variant={isMobile ? 'compact' : 'line'} />
  );

  if (isEmptyList) {
    return (
      <ContentPlaceholder
        heading={t('Other.NoCalls')}
        message={t('Other.noCallsThisCard')}
        mobile
      />
    );
  }

  if (isLoading) {
    return <CircularProgressView />;
  }

  return (
    <EventsContainer>
      <Stack spacing={10 / 8}>
        {hasCurrentEvents && (
          <Heading variant="h1" fontSize={18} lineHeight={24 / 18} pb={12 / 8}>
            {t('Profile.futureAndLive')}
          </Heading>
        )}

        {currentList?.map(renderCall)}

        {hasPastEvents && (
          <Heading variant="h1" fontSize={18} lineHeight={24 / 18} py={12 / 8}>
            {t('Profile.past')}
          </Heading>
        )}

        {passedList?.map(renderCall)}
      </Stack>
    </EventsContainer>
  );
};
