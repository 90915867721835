import { BattleChoiceId, BattleResult } from '~shared/api';

import { BattleShellStatus } from '../ui';

export const getBattleShellStatus = ({
  result,
  choice,
}: {
  result: BattleResult;
  choice: BattleChoiceId;
}): BattleShellStatus => {
  switch (result) {
    case BattleResult.Cancelled:
    case BattleResult.InProgress:
      return 'future';
  }

  return result.valueOf() === choice.valueOf() ? 'win' : 'lose';
};
