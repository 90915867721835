import { Swiper, SwiperSlide } from 'swiper/react';

import { styled } from '~shared/lib/styles';

export const HorizontalListRoot = styled('div', { name: 'HorizontalList' })(() => ({
  display: 'flex',
  alignItems: 'stretch',
  overflow: 'hidden',

  ['& .swiper-wrapper']: {
    flexWrap: 'nowrap',
  },
}));

export const HorizontalListSwiper = styled(Swiper)({});

export const HorizontalListSlide = styled(SwiperSlide)(() => ({
  ['&.swiper-slide']: {
    width: 'max-content',
  },
}));

HorizontalListSlide.displayName = 'SwiperSlide';
