import { FC, Fragment } from 'react';
import { Outlet } from 'react-router';
import { useThrottle } from 'react-use';

import { Preloader } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

import { useReferralVerification } from '~features/referral';

import { useViewerInitialization } from '~processes/viewer';
import { useNftInitialization } from '~processes/nft';
import { useNotificationsInitialization } from '~processes/notifications';

export const PrivateRoute: FC = () => {
  useNftInitialization();
  useNotificationsInitialization();
  useViewerInitialization();
  useReferralVerification();

  const { sessionTokenChecked } = useViewerModel();

  // smooth disappear animation
  const preloaderOpen = useThrottle(!sessionTokenChecked, 150);

  return (
    <Fragment>
      <Outlet />

      <Preloader open={preloaderOpen} />
    </Fragment>
  );
};
