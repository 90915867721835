import { FC, MouseEvent, useCallback } from 'react';
import { clsx } from 'clsx';

import { htmlDefaultLayoutBottomAnchorId } from '~shared/config';

import { Modal } from '../Modal';
import { ButtonIcon } from '../../controls';

import {
  UIKItDrawerCloseContainer,
  UIKitDrawerBackdrop,
  UIKitDrawerRoot,
  drawerClasses,
} from './styled';

import { DrawerPaper } from './DrawerPaper';
import { DrawerProps } from './types';

export const Drawer: FC<DrawerProps> = ({
  open,
  onClose,
  children,
  roundCorners = true,
  showCloseButton = true,
  paperBackground = 'paper.dark',
  className,
  slots = {},
  disableClickAway,

  ...props
}) => {
  const handleCloseCurry = useCallback(
    (reason?: 'clickAway') =>
      (event: MouseEvent | TouchEvent, varianceReason?: 'escapeKeyDown' | 'backdropClick') => {
        if (reason === 'clickAway' && disableClickAway) {
          return;
        }

        const finalReason = reason || varianceReason || ('closeClick' as const);

        if (finalReason === 'backdropClick') {
          return;
        }

        if (onClose) {
          onClose(event, finalReason);
        }
      },
    [disableClickAway, onClose]
  );

  return (
    <Modal
      {...props}
      open={open}
      onClose={handleCloseCurry()}
      container={document.getElementById(htmlDefaultLayoutBottomAnchorId)}
      slots={{ backdrop: UIKitDrawerBackdrop, root: UIKitDrawerRoot, ...slots }}
      disableAutoFocus
      className={clsx(
        drawerClasses.root,
        { [drawerClasses.roundCorners]: roundCorners },
        className
      )}
    >
      <DrawerPaper in={open} onClickAway={handleCloseCurry('clickAway')} bgcolor={paperBackground}>
        {children}

        {showCloseButton && onClose && (
          <UIKItDrawerCloseContainer>
            <ButtonIcon size={24} name="close-2" variant="plain" onClick={handleCloseCurry()} />
          </UIKItDrawerCloseContainer>
        )}
      </DrawerPaper>
    </Modal>
  );
};
