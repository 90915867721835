import { FC } from 'react';

import { Button, Dialog, Stack, Typography } from '~shared/ui';

import { ConfirmationDialogProps } from './types';

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  children,
  targetButton,
  cancelButton,
  cancelButtonText = 'No thanks',
  targetButtonText = 'Confirm',
  title,
  width = 330,
  onClose,
  onConfirm,
  ...props
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    onClose();
  };

  return (
    <Dialog width={width} {...props} onClose={onClose}>
      <Typography textAlign="center" variant="h1" mb="16px">
        {title}
      </Typography>
      <Typography gutterBottom align="center" color="secondary">
        {children}
      </Typography>

      <Stack spacing={12 / 8} direction="row" justifyContent="center">
        {targetButton ?? (
          <Button onClick={handleConfirm} color="error">
            {targetButtonText}
          </Button>
        )}
        {cancelButton ?? (
          <Button variant="outlined" onClick={onClose}>
            {cancelButtonText}
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};
