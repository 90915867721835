import { Slider as MuiBaseSlider, sliderClasses as muiSliderClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';
import { candy } from '~shared/lib/assets';

export const sliderClasses = {
  ...muiSliderClasses,
  flexItem: 'Slider-flexItem',
};

export const UIKitSlider = styled(MuiBaseSlider)(({ theme }) => ({
  height: 8,
  width: '100%',
  position: 'relative',
  display: 'inline-block',
  cursor: 'pointer',
  touchAction: 'none',

  [`&.${sliderClasses.flexItem}`]: {
    width: 'initial',
  },

  [`& .${sliderClasses.rail}`]: {
    position: 'absolute',
    display: 'block',
    width: 'inherit',
    height: 'inherit',

    background: theme.palette.grey.A5,
    borderRadius: '100px',
  },

  [`& .${sliderClasses.track}`]: {
    height: 'inherit',
    display: 'block',
    position: 'absolute',

    borderRadius: '100px',
    background: candy,
    backgroundPositionX: 'right',
    transition: theme.transitions.create('width', { duration: 15 }),
  },

  [`& .${sliderClasses.thumb}`]: {
    position: 'absolute',
    width: 16,
    height: 16,

    marginTop: -4,
    marginLeft: -8,
    display: 'block',

    border: `1.5px solid ${theme.palette.text.primary}`,
    background: theme.palette.primary.main,
    boxShadow: '0px 0px 14px #000000',
    borderRadius: '100%',
    transition: theme.transitions.create('left', { duration: 15 }),
  },

  [`&.${sliderClasses.dragging}`]: {
    [`& .${sliderClasses.track} & .${sliderClasses.thumb}`]: {
      transition: 'none',
    },
  },

  [`&.${sliderClasses.vertical}`]: {
    height: '100%',
    width: 8,

    [`&.${sliderClasses.flexItem}`]: {
      height: 'initial',
      width: 8,
    },

    [`& .${sliderClasses.rail}`]: {
      height: '100%',
    },

    [`& .${sliderClasses.track}`]: {
      width: 8,
    },

    [`& .${sliderClasses.thumb}`]: {
      marginBottom: -8,
      marginLeft: -4,
    },
  },
}));
