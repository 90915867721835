import { BattleShellSize, BattleShellVariant } from '~entities/battle';
import { useMediaQuery } from '~shared/lib/hooks';

export const useBattleAutoStyle = (
  variant: BattleShellVariant,
  size?: BattleShellSize
): { size: BattleShellSize; variant: BattleShellVariant } => {
  const { isMobile, isDesktop } = useMediaQuery();
  const isXS = useMediaQuery(860);

  if (size) {
    return { size, variant };
  }

  if (variant === 'line') {
    if (isXS) {
      return { size: 'sm', variant: 'compact' };
    }

    if (isMobile) {
      return { size: 'sm', variant: 'line' };
    }

    if (isDesktop) {
      return { size: 'md', variant: 'line' };
    }

    return { size: 'lg', variant: 'line' };
  }

  if (isXS || isMobile) {
    return { size: 'sm', variant };
  }

  if (isDesktop) {
    return { size: 'md', variant };
  }

  return { size: 'lg', variant };
};
