import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

import { alertClasses } from '../Alert';

export const alertSnackbarClasses = {
  ...alertClasses,
  alertRoot: alertClasses.root,
  root: 'AlertSnackbar-root',
};

export const AlertSnackbarRoot = styled(motion.div, { name: 'AlertSnackbar' })({});
