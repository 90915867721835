import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Notification,
  NotificationCaption,
  NotificationIconFactory,
  NotificationMessage,
} from '../../../factory';
import { formatTeamName } from '../../../../lib';

import { NotificationEventSoonProps } from './types';

export const NotificationEventSoon = forwardRef<HTMLDivElement, NotificationEventSoonProps>(
  ({ notification, place }, ref) => {
    const { t } = useTranslation();

    return (
      <Notification
        date={notification.date}
        read={notification.read}
        place={place}
        ref={ref}
        icon={
          <NotificationIconFactory type="event" color="error" sport={notification.payload.sport}>
            {t('Notifications.5min')}
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>{t('Notifications.5minutesToEvent')}</NotificationMessage>
        <NotificationCaption>
          “{formatTeamName(notification.payload.homeTeam)} vs{' '}
          {formatTeamName(notification.payload.awayTeam)}“
        </NotificationCaption>
      </Notification>
    );
  }
);
