/* eslint-disable react/jsx-key,no-alert */
import { ReactNode, cloneElement } from 'react';

import { Box, CodeInput, SocialTextField, Stack, TextField, Typography } from '~shared/ui';

export const InputsTab = () => {
  const row = (name: string, elements: ReactNode[]) => (
    <Stack direction="row" alignItems="center" spacing={2} minHeight={44}>
      <Typography minWidth={150}>{name}</Typography>

      {elements.map((element) => (
        // @ts-ignore
        <Box width={320}>{cloneElement(element, { ...element.props, autocomplete: 'off' })}</Box>
      ))}
    </Stack>
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography
          borderBottom="1px solid #4A4A4A"
          paddingBottom={1}
          sx={{ fontSize: 20, fontWeight: 500 }}
        >
          Input
        </Typography>

        {[row('plain', [<TextField defaultValue="Klimov Pavel" />, <TextField />])]}
        {[
          row('endAdornment', [
            <TextField defaultValue="10" endAdornment="MATIC" />,
            <TextField endAdornment="MATIC" />,
          ]),
        ]}
        {[
          row('with label', [
            <TextField label="Name" defaultValue="Klimov Pavel" />,
            <TextField label="Name" />,
          ]),
        ]}
        {[
          row('with placeholder', [
            <TextField placeholder="Your name" defaultValue="Klimov Pavel" />,
            <TextField placeholder="Your name" />,
          ]),
        ]}
        {[
          row('password', [
            <TextField label="Password" type="password" defaultValue="Klimov Pavel" />,
            <TextField label="Password" type="password" />,
          ]),
        ]}
        {[
          row('validation', [
            <TextField
              label="Email"
              defaultValue="sobaka@com.google"
              error
              helperText="Error in email"
            />,
            <TextField label="Email" error helperText="No email provided" />,
          ]),
        ]}
        {[
          row('disabled', [
            <TextField label="Email" defaultValue="sobaka@com.google" disabled />,
            <TextField label="Email" disabled />,
          ]),
        ]}
        {[
          row('social', [
            <SocialTextField
              iconName="social-google"
              label="Email"
              defaultValue="user@gmail.com"
              disabled
              onUnlink={() => alert('deattach')}
            />,
            <SocialTextField iconName="social-google" label="Email" />,
          ]),
        ]}

        {[row('code', [<CodeInput />])]}
      </Stack>
    </Stack>
  );
};
