import { styled } from '~shared/lib/styles';
import { Dialog, dialogClasses } from '~shared/ui';

export const NftCardDetailedInfoDialog = styled(Dialog, { name: 'NftCardDetailedInfoDialog' })(
  () => ({
    [`& .${dialogClasses.scrollPaper}`]: {
      // sidebar moment
      marginRight: 320,
    },
  })
);
