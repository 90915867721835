import { Children, FC, Fragment, cloneElement, isValidElement } from 'react';

import { ToggleButtonGroupProps } from './types';

export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({ children, value, onChange }) => {
  return (
    <Fragment>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child, {
          ...child.props,
          selected: value === child.props.value,
          onChange,
        });
      })}
    </Fragment>
  );
};
