import { FC, ReactElement, cloneElement, isValidElement } from 'react';

import { BottomNavigationActionProps } from '../BottomNavigationAction';

import { UIKitBottomNavigationRoot } from './styled';
import { BottomNavigationProps } from './types';

export const BottomNavigation: FC<BottomNavigationProps> = ({
  children,
  onChange,
  value,
  ...props
}) => {
  return (
    <UIKitBottomNavigationRoot {...props}>
      {Array.isArray(children)
        ? children.map((menuItem: ReactElement, index) => {
            if (isValidElement<BottomNavigationActionProps>(menuItem)) {
              const menuItemValue = menuItem.props.value ?? index;

              return cloneElement(menuItem, {
                ...menuItem.props,
                key: menuItem.props.key ?? index,
                selected: value === menuItemValue,
                value: menuItemValue,
                onChange,
              });
            }

            return null;
          })
        : null}
    </UIKitBottomNavigationRoot>
  );
};
