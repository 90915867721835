import { FC, PropsWithChildren } from 'react';

import { AnimatePresence } from 'framer-motion';

import { Box } from '~shared/ui';

export const SportsMenuSidebarSlidesContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box position="relative" height={1} zIndex={100}>
      <Box position="absolute" height={1} display="flex">
        <AnimatePresence>{children}</AnimatePresence>
      </Box>
    </Box>
  );
};
