import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Dialog, Icon, Stack, Typography } from '~shared/ui';
import { theme } from '~shared/lib/styles';

import { OnboardingSteps, useOnboardingEffects, useOnboardingModel } from '../model';

import { OnboardingStepsContent } from './assets';
import { OnboardingImage } from './styled';

import { OnboardingProps } from './types';

export const Onboarding: FC<OnboardingProps> = () => {
  const { t } = useTranslation();

  const {
    /***/
    onClose,
    onOnboardingSubmit,
    stepParam,
    onPreviousStep,
    onNextStep,
    open,
  } = useOnboardingModel();

  useOnboardingEffects();

  const nextStepButton = (
    <Button endAdornment={<Icon name="arrow-right" />} onClick={onNextStep}>
      {t('onBoarding.next')}
    </Button>
  );

  const previousStepButton = (
    <Button variant="outlined" startAdornment={<Icon name="arrow-left" />} onClick={onPreviousStep}>
      {t('onBoarding.back')}
    </Button>
  );

  const steps = {
    [OnboardingSteps.ConnectWallet]: {
      ...OnboardingStepsContent[OnboardingSteps.ConnectWallet],
      backButton: <span />,
      nextButton: nextStepButton,
    },
    [OnboardingSteps.FreeMaincard]: {
      ...OnboardingStepsContent[OnboardingSteps.FreeMaincard],
      backButton: previousStepButton,
      nextButton: nextStepButton,
    },
    [OnboardingSteps.Bet]: {
      ...OnboardingStepsContent[OnboardingSteps.Bet],
      backButton: previousStepButton,
      nextButton: nextStepButton,
    },
    [OnboardingSteps.CashPrizes]: {
      ...OnboardingStepsContent[OnboardingSteps.CashPrizes],
      backButton: previousStepButton,
      nextButton: nextStepButton,
    },
    [OnboardingSteps.Repair]: {
      ...OnboardingStepsContent[OnboardingSteps.Repair],
      backButton: previousStepButton,
      nextButton: nextStepButton,
    },
    [OnboardingSteps.Merge]: {
      ...OnboardingStepsContent[OnboardingSteps.Merge],
      backButton: previousStepButton,
      nextButton: <Button onClick={onOnboardingSubmit}>Go to platform</Button>,
    },
  };

  const numericStep = Number(stepParam) as unknown as OnboardingSteps;
  const currentStep = steps[numericStep || OnboardingSteps.ConnectWallet];

  return (
    <Dialog open={open} width={500} style={{ zIndex: theme.zIndex.modalOverlap }}>
      <Stack spacing={24 / 8}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography color="secondary">
              {numericStep} of {Object.values(steps).length}
            </Typography>

            <Typography ml={10 / 8} fontWeight={500}>
              {t('onBoarding.welcone')}
            </Typography>
          </Box>

          <Button variant="text" onClick={onClose}>
            {t('onBoarding.skip')}
          </Button>
        </Box>

        <OnboardingImage src={currentStep.illustration} alt={currentStep.title} />

        <div>
          <Typography variant="h2" gutterBottom fontSize={24} lineHeight={36 / 24}>
            {t(currentStep.title)}
          </Typography>

          <Typography color="secondary" component="div">
            {dynamicContentHeightEqualizer(
              t(steps[OnboardingSteps.ConnectWallet].text),
              t(currentStep.text)
            )}
          </Typography>
        </div>

        <Box display="flex" justifyContent="space-between">
          {currentStep.backButton}
          {currentStep.nextButton}
        </Box>
      </Stack>
    </Dialog>
  );
};

/* eslint-disable react-extra/no-inline-styles */
const dynamicContentHeightEqualizer = (longestContent: any, children: any) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute' }}>{children}</div>
      <div style={{ opacity: 0 }}>{longestContent}</div>
    </div>
  );
};
