import { FC, forwardRef } from 'react';

import { FormControl } from '../FormControl';
import { FormHelperText } from '../FormHelperText';
import { Input } from '../Input';

import { InputLabel } from './InputLabel';

import { TextFieldProps } from './types';

export const TextField: FC<TextFieldProps> = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      className,
      defaultValue,
      disabled,
      error,
      onChange,
      required,
      value,
      inputRef,

      label,
      labelProps,

      helperText,
      helperTextProps,

      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        onChange={onChange}
        required={required}
        value={value}
      >
        <InputLabel {...labelProps}>{label}</InputLabel>
        <Input {...props} labeled={!!label} ref={ref} inputRef={inputRef} />
        {helperText && (
          <FormHelperText {...helperTextProps} error={error}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
