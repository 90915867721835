import { useQueryNfts } from '~entities/nft';

import { MergeCards } from '~features/nft';

import { ReturnCardDropZone } from './ReturnCardDropZone';
import { UserCardsActions } from './UserCardsActions';
import { UserCardsHeader } from './UserCardsHeader';
import { UserCardsList } from './UserCardsList';
import { UserCardsTabs } from './UserCardsTabs';
import { NoCards } from './NoCards';

import { UserCardsContentContainer, UserCardsRoot } from './styled';

export const UserCards = () => {
  const { nfts, isFetched } = useQueryNfts();

  const hasCardsCondition = !nfts.length && isFetched;

  return (
    <UserCardsRoot>
      {hasCardsCondition ? (
        <NoCards />
      ) : (
        <UserCardsContentContainer>
          <UserCardsHeader />
          <UserCardsTabs />

          <MergeCards />
          <ReturnCardDropZone />

          <UserCardsList />
        </UserCardsContentContainer>
      )}

      <UserCardsActions />
    </UserCardsRoot>
  );
};
