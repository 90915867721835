import { Fragment } from 'react';

import { EventDialog } from '~features/event-dialog';
import { FullWinstreakWarningDialog } from '~features/event-dialog';
import { FixCardDialog, SellCardDialog } from '~features/nft';

import { NftCardDetailedInfo } from './NftCardDetailedInfo';

export const NftConstructor = () => {
  return (
    <Fragment>
      <FullWinstreakWarningDialog />
      <NftCardDetailedInfo />
      <FixCardDialog />
      <SellCardDialog />
      <EventDialog />
    </Fragment>
  );
};
