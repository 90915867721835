import { useTranslation } from 'react-i18next';

import { Stack } from '~shared/ui';

import { Root, StyledDescription, StyledTitle } from './styled';

export const DashboardAuction = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Stack spacing={6 / 8}>
        <StyledTitle textAlign="center"> {t('Tabs.auction')}</StyledTitle>
        <StyledDescription color="secondary" textAlign="center">
          {t('Other.comingSoon')}
        </StyledDescription>
      </Stack>
    </Root>
  );
};
