import { Children, cloneElement, FC, ReactElement } from 'react';
import { FreeMode, Mousewheel, Virtual } from 'swiper/modules';

import { useMediaQuery, useResizeObserver } from '~shared/lib/hooks';
import { Icon } from '~shared/ui';
import { theme } from '~shared/lib/styles';

import { useBattleModel } from '../../../../model';
import { BattleShellSizeMap, useBattleAutoStyle } from '../../../../lib';

import {
  HorizontalListPill,
  HorizontalListRoot,
  HorizontalListSlide,
  HorizontalListSlideNavigation,
  HorizontalListSwiper,
} from './styled';

import { BattlesHorizontalVirtualListProps } from './types';

export const BattlesHorizontalVirtualList: FC<BattlesHorizontalVirtualListProps> = ({
  label,
  children,

  navigation,
  navigationContent,
}) => {
  const isSmall = useMediaQuery('sm');
  const { isMobile } = useMediaQuery();

  const { isOverlapBackdrop } = useBattleModel();
  const { size: battleShellSize } = useBattleAutoStyle('square');

  const [rootRef, { width: rootWidth }] = useResizeObserver<HTMLDivElement>();

  const dragZIndex = isOverlapBackdrop ? theme.zIndex.modalOverlap : undefined;

  const pillWidth = label ? 25 + (isSmall ? 12 : 16) : 0;
  const rootNegativeMargin = isMobile ? 16 : 30;

  const wrapperWidth = rootWidth - pillWidth - rootNegativeMargin;

  const slidesPerView = wrapperWidth / (BattleShellSizeMap.square[battleShellSize].width! + 16);

  return (
    <HorizontalListRoot ref={rootRef} minHeight={BattleShellSizeMap.square[battleShellSize].height}>
      {label && <HorizontalListPill>{label}</HorizontalListPill>}

      <HorizontalListSwiper
        slidesPerView={slidesPerView}
        freeMode={{ sticky: true }}
        mousewheel={{ forceToAxis: true }}
        modules={[Mousewheel, FreeMode, Virtual]}
        $zIndex={dragZIndex}
        virtual
      >
        {Children.map(children, (child, index) => (
          <HorizontalListSlide virtualIndex={index}>
            {cloneElement(child as ReactElement, { variant: 'square' })}
          </HorizontalListSlide>
        ))}

        {navigation && (
          <HorizontalListSlide virtualIndex={Children.count(children) + 1}>
            <HorizontalListSlideNavigation to={navigation} underline={false}>
              <Icon name="arrow-right" />
              <div>{navigationContent ?? 'See more'}</div>
            </HorizontalListSlideNavigation>
          </HorizontalListSlide>
        )}
      </HorizontalListSwiper>
    </HorizontalListRoot>
  );
};
