export interface AuthState {
  step: AuthSteps;
  open: boolean;

  email: string;
  nickname: string;
  avatar: string;
  walletAddress: string;
}

export enum AuthSteps {
  Identification,
  Authentication,
  CreatePassword,
  IntroduceYourself,
  VerificationCode,
  ForgotPassword,
  RecoveryLink,
}
