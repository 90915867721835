import { Fragment } from 'react';

import { withProviders } from './providers';
import { Routing } from './routing';
import { AppWidgets } from './widgets';

// eslint-disable-next-line no-console
console.log('ПЕРЕМЕННЫЕ ОКРУЖЕНИЯ', process.env);

export const App = withProviders(() => {
  return (
    <Fragment>
      <Routing />
      <AppWidgets />
    </Fragment>
  );
});
