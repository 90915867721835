import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const NftFreezedRoot = styled(Box, { name: 'NftFreezed--root' })({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  alignItems: 'center',
});

export const NftFreezedText = styled(Typography, { name: 'NftFreezed--text' })({});
