import { FC } from 'react';

import { Shimmer } from '~shared/ui';

export const MarketplaceItemShimmer: FC = () => {
  return (
    <Shimmer width="100%" height={350}>
      <rect x="0" y="0" rx="12" ry="12" width="100%" height="350" />
    </Shimmer>
  );
};
