import { clsx } from 'clsx';
import { FC } from 'react';

import { StyledDragCell, dragCellClasses } from './styled';
import { DragCellProps } from './types';

export const DragCell: FC<DragCellProps> = ({
  className,
  isHighlighted,
  isPlacedToCell,
  removeBoxShadow,
  ...otherProps
}) => {
  const computedClassName = clsx(
    resolveBackgroundClassName(isHighlighted),
    resolveBoxShadowClassName(isHighlighted, isPlacedToCell, removeBoxShadow),
    className
  );

  return <StyledDragCell {...otherProps} className={computedClassName} />;
};

const resolveBoxShadowClassName = (
  isHighlighted: boolean | undefined,
  isPlacedToCell: boolean | undefined,
  removeBoxShadow: boolean | undefined
) => {
  if (isPlacedToCell || isHighlighted) {
    return dragCellClasses.highlightedBoxShadow;
  }

  if (!removeBoxShadow) {
    return dragCellClasses.transparentBoxShadow;
  }
};

const resolveBackgroundClassName = (isHighlighted: boolean | undefined) => {
  if (isHighlighted) {
    return dragCellClasses.highlightedBackground;
  }

  return dragCellClasses.transparentBackground;
};
