import { styled } from '~shared/lib/styles';

interface DraggableHorizontalListProps {
  $isDown?: boolean;
  $isMove?: boolean;
}

export const DraggableHorizontalListRoot = styled('div')<DraggableHorizontalListProps>(
  ({ $isDown, $isMove }) => ({
    cursor: $isDown ? 'grab' : 'initial',
    overflowX: 'auto',

    ['& > *']: {
      pointerEvents: $isMove ? 'none' : 'auto',
    },
  })
);
