import { FC, MouseEvent } from 'react';
import { motion } from 'framer-motion';

import { ClickAwayListener } from '../../common';

import { PopperProps } from './types';
import { UIKitPopperRoot } from './styled';

export const Popper: FC<PopperProps> = ({
  placement = 'top',
  open,
  content,
  anchorEl,
  onClose = () => undefined,
}) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <UIKitPopperRoot open={open} onClick={handleClick} anchorEl={anchorEl} placement={placement}>
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.2 }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <div>{content}</div>
        </ClickAwayListener>
      </motion.div>
    </UIKitPopperRoot>
  );
};
