import { FC, useState } from 'react';

import { Box } from '~shared/ui/layout';
import { Icon, IconAssetNamesType, TextField, assets } from '~shared/ui';

import { UIKitDemoIconGrid, UIKitDemoIconPaper } from './styled';

export const IconsTab: FC = () => {
  const [search, setSearch] = useState('');

  return (
    <Box>
      <Box my={2}>
        <TextField
          type="text"
          value={search}
          label="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>

      <UIKitDemoIconGrid>
        {Object.keys(assets)
          .filter((assetKey) => assetKey.toLowerCase().includes(search.toLowerCase()))
          .map((assetKey) => (
            <UIKitDemoIconPaper key={assetKey}>
              <Box my={2}>
                <Icon name={assetKey as IconAssetNamesType} size={24} />
              </Box>

              <div>{assetKey} 24px</div>
            </UIKitDemoIconPaper>
          ))}
      </UIKitDemoIconGrid>
    </Box>
  );
};
