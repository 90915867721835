import { useEffect, useRef, useState } from 'react';

const AUCTION_BETS_ACCEPTING_DURATION = 2 * 24 * 60 * 60 * 1000; // 2 days

export interface UseAuctionAcceptBetTimerType {
  (betsAcceptedUntil: Date): {
    hoursLeft: string;
    minutesLeft: string;
    progress: number;
    accepted: boolean;
  };
}

export const useAuctionAcceptBetTimer: UseAuctionAcceptBetTimerType = (betsAcceptedUntil: Date) => {
  const interval = useRef<NodeJS.Timeout>();
  const [remainingTime, setRemainingTime] = useState<number>(getRemainingTime(betsAcceptedUntil)); // in ms

  useEffect(() => {
    if (remainingTime > 0) {
      clearInterval(interval.current);

      interval.current = setInterval(() => {
        setRemainingTime(getRemainingTime(betsAcceptedUntil));
      }, 60 * 1000); // 1 minute
    }
  }, [betsAcceptedUntil, remainingTime]);

  return {
    hoursLeft:
      getRemainingTime(betsAcceptedUntil) > 0
        ? String(Math.floor(remainingTime / 1000 / 60 / 60))
        : '0',
    minutesLeft:
      getRemainingTime(betsAcceptedUntil) > 0
        ? formatMinutes(Math.floor((remainingTime / 1000 / 60) % 60))
        : '00',
    progress: getRemainingTime(betsAcceptedUntil) > 0 ? getProgress(remainingTime) : 100,
    accepted: remainingTime > 0,
  };
};

const getRemainingTime = (acceptedUntil: Date) => {
  const dateNow = new Date();

  return acceptedUntil.getTime() - dateNow.getTime();
};

const getProgress = (timeLeft: number): number => {
  const passedTime = AUCTION_BETS_ACCEPTING_DURATION - timeLeft;

  return (passedTime / AUCTION_BETS_ACCEPTING_DURATION) * 100;
};

const formatMinutes = (minutes: number): string => {
  if (minutes > 9) {
    return `${minutes}`;
  }

  if (minutes === 0) {
    return '00';
  }

  return `0${minutes}`;
};
