import { useDrop } from 'react-dnd';

import { NftDragItem, NftDragItemType, useNftCardModel } from '~entities/nft';

import { useMergeModel } from '../model';

export const useDropForMerge = () => {
  const {
    nftsToMerge: [firstNftToMerge, secondNftToMerge],
  } = useNftCardModel();

  const { handleAddNftToMerge } = useMergeModel();

  const [collectedProps, dropRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
    drop: (item) => handleAddNftToMerge([item.tokenId]),
    hover(item) {
      const isBothCellsPlaced = Boolean(firstNftToMerge) && Boolean(secondNftToMerge);
      const isPlacingAlreadyPlacedNft =
        (Boolean(firstNftToMerge) && firstNftToMerge?.token_id === item.tokenId) ||
        (Boolean(secondNftToMerge) && secondNftToMerge?.token_id === item.tokenId);

      if (isPlacingAlreadyPlacedNft || isBothCellsPlaced) {
        return;
      }
    },
  });

  const isFirstCellHighlighted = collectedProps.isOver && !Boolean(firstNftToMerge);

  const isSecondCellHighlighted =
    collectedProps.isOver && !Boolean(secondNftToMerge) && Boolean(firstNftToMerge);

  return {
    dropRef,
    isFirstCellHighlighted,
    isSecondCellHighlighted,
  };
};
