import { styled } from '~shared/lib/styles';

export const EventCardsWalletInitializationRoot = styled('div')(({ theme }) => ({
  flex: 1,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  border: `1px dashed ${theme.palette.grey.A30}`,
  borderRadius: '8px',
  height: '172px',
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    height: '130px',
  },
}));

export const EventCardsWalletInitializationLabel = styled('div')(() => ({
  marginLeft: 12,
  fontWeight: 500,
}));
