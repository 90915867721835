import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FC, Fragment } from 'react';
import { useSet } from 'react-use';

import { Box, ButtonIcon, Drawer, Icon, Stack } from '~shared/ui';

import { SportsMenuList, SportsMenuListItem } from '../model';
import { isShouldShowMenuItem } from '../lib';

import {
  SportsMenuDrawer,
  SportsMenuItem,
  SportsMenuItemLabel,
  SportsMenuLevelHeading,
  SportsMenuSubmenuHeading,
} from './styled';

import { SportsMenuIconFallback } from './SportsMenuIconFallback';
import { SportsMenuLine } from './SportsMenuLine';

import { SportsMenuProps } from './types';

export const SportsMenu: FC<SportsMenuProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeMenus, activeMenusActions] = useSet<SportsMenuListItem>();

  const handleNavigation = (item: SportsMenuListItem) => () => {
    if (item.items) {
      activeMenusActions.add(item as Required<SportsMenuListItem>);
    } else if (item.route) {
      onClose();
      activeMenusActions.reset();
      navigate(item.route);
    }
  };

  const handleBackNavigation = (currentItem: SportsMenuListItem) => () => {
    setTimeout(() => {
      activeMenusActions.remove(currentItem as Required<SportsMenuListItem>);
    });
  };

  return (
    <Fragment>
      <Drawer open={open} onClose={onClose} disableClickAway={Boolean(activeMenus.size)}>
        <SportsMenuLevelHeading marginBottom={20 / 8}>
          {t('LeftSideBar.sections')}
        </SportsMenuLevelHeading>

        <Box display="grid" gridTemplateColumns={['1fr', '1fr 1fr']} gap={10 / 8}>
          {SportsMenuList.map((item) => (
            <SportsMenuLine
              key={item.id}
              name={t(item.label)}
              illustration={item.illustration}
              onClick={handleNavigation(item)}
              hasItems={!!item.items}
            />
          ))}
        </Box>
      </Drawer>

      {flatSportMenus(SportsMenuList).map((item) => {
        const { items } = item;
        const isLastItem = Array.from(activeMenus).reverse()[0] === item;

        return (
          <SportsMenuDrawer
            key={item.id}
            open={activeMenus.has(item)}
            onClose={handleBackNavigation(item)}
            disableClickAway={!isLastItem}
          >
            <Stack direction="row" alignItems="center" spacing={12 / 8} paddingLeft={16 / 8}>
              <ButtonIcon
                size={24}
                name="arrow-left"
                variant="plain"
                onClick={handleBackNavigation(item)}
              />
              <SportsMenuSubmenuHeading>{item.label}</SportsMenuSubmenuHeading>
            </Stack>

            {items && (
              <Stack mt={18 / 8}>
                {items
                  .filter((item) => isShouldShowMenuItem(item.dates))
                  .map((item) => (
                    <SportsMenuItem key={item.id} onClick={handleNavigation(item)}>
                      {item.icon ? (
                        <Icon size={36} name={item.icon} pr={6 / 8} />
                      ) : (
                        <SportsMenuIconFallback />
                      )}
                      <SportsMenuItemLabel>
                        {item.sectionLabel ? (
                          <Trans i18nKey="LeftSideBar.allEvents" t={t}>
                            All {{ sport: item.sectionLabel }} events
                          </Trans>
                        ) : (
                          item.label
                        )}
                      </SportsMenuItemLabel>
                      {item.items?.length && <Icon ml="auto" size={20} name="chevron-right" />}
                    </SportsMenuItem>
                  ))}
              </Stack>
            )}
          </SportsMenuDrawer>
        );
      })}
    </Fragment>
  );
};

const flatSportMenus = (menus: SportsMenuListItem[]) => {
  const result: SportsMenuListItem[] = [];

  menus.forEach((item) => {
    result.push(item);

    if (item.items) {
      result.push(...flatSportMenus(item.items));
    }
  });

  return result;
};
