import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BigNumber, Signature, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import { ApiPostPlaceCardToAuctionRequestData, Nft, postPlaceCardToAuction } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { nftActions, useNftSelector } from '~entities/nft';

// todo: feature <- pages, feature <- widgets

import { signGaslessTxMessage, useCallGasless } from '~entities/wallet';

import { AuctionForMCN__factory } from '~shared/contracts';

import { SellInitialPriceFormValues } from './types';
import { validationSchema } from './config';

export const useSellCardModel = (nft: Nft) => {
  const dispatch = useDispatch();

  const handleSellCard = () => {
    dispatch(nftActions.setDialogs({ action: 'sell', dialog: { nfts: [nft], open: true } }));
  };

  return { handleSellCard };
};

export const useSellCardDialogModel = () => {
  const { t } = useTranslation();

  const {
    dialogs: {
      sell: { nfts, open },
    },
  } = useNftSelector();

  const nft = nfts[0] || null;

  const dispatch = useDispatch();
  const form = useForm<SellInitialPriceFormValues>({
    resolver: yupResolver(validationSchema),
  });

  // TODO: Add ability to place card for Matic???

  const { account, provider } = useWeb3React();

  const handleCloseSellCardDialog = () => {
    dispatch(nftActions.setDialogs({ action: 'sell', dialog: { nfts: [], open: false } }));
  };

  //   TODO: Uncomment this
  const signSellCardMessage = async (
    cardId: BigNumber,
    startingPrice: BigNumber,
    address: string
  ): Promise<Signature | null> => {
    const signer = provider?.getSigner();

    if (!signer) {
      return null;
    }

    const types: Array<'uint256'> = ['uint256', 'uint256', 'uint256'];

    const auctionContract = AuctionForMCN__factory.connect(
      process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN,
      provider?.getSigner()!
    );

    const gasFreeOpCounter = await auctionContract.gasFreeOpCounter(address);

    const values: Array<BigNumber | string> = [cardId, startingPrice, gasFreeOpCounter];

    const signedMessage = await signGaslessTxMessage({ types, values, signer });

    return signedMessage;
  };

  const gaslessSellCardCall = useCallGasless<ApiPostPlaceCardToAuctionRequestData>({
    callback: postPlaceCardToAuction,
    transactionName: 'Putting the Card up for auction',
    successMessage: `${t('Alerts.alertAuctionPurCard')}`,
    errorMessage: `${t('Errors.auctionFailedPut')}`,
  });

  const handleSubmit = form.handleSubmit(async ({ price }: SellInitialPriceFormValues) => {
    if (nft && account) {
      const cardId = nft.token_id;

      try {
        // TODO: Uncomment this
        const signedMessage = await signSellCardMessage(
          BigNumber.from(cardId),
          ethers.utils.parseEther(String(price)),
          account
        );

        if (!signedMessage) {
          return;
        }

        const { r, s, v } = signedMessage;

        // Uncomment if you want to call contract DIRECTLY
        // await AuctionForMCN__factory.connect(
        //   process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN,
        //   provider?.getSigner()!
        // ).placeCardToAuctionGasFree(
        //   cardId,
        //   ethers.utils.parseEther(String(price)),
        //   account,
        //   v,
        //   r,
        //   s
        // );

        await gaslessSellCardCall({
          cardId: Number(cardId),
          startingPrice: Number(price),
          sender: account,
          r,
          s,
          v,
        });

        dispatch(nftActions.sellCard(cardId));
        handleCloseSellCardDialog();
      } catch (e) {
        console.error(e);
      }
    }
  });

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [open, form]);

  return {
    open,
    nft,
    form,
    handleCloseSellCardDialog,
    handleSubmit,
  };
};
