import { ApiCommonSport } from '../shared';

import {
  ApiGetBattlesByIdsMappedData,
  ApiGetBattlesByIdsRawData,
  ApiGetBattlesMappedData,
  ApiGetBattlesRawData,
  ApiGetBattlesRawTeam,
  ApiGetBattlesTeam,
} from './types';

export const mapGetBattlesResponse = (
  data: Array<Array<ApiGetBattlesRawData>>
): Array<ApiGetBattlesMappedData> => {
  return data
    .flat()
    .map(({ date, homeTeam, awayTeam, sport, ...battle }) => {
      return {
        ...battle,
        homeTeam: mapBattleTeam(homeTeam, sport),
        awayTeam: mapBattleTeam(awayTeam, sport),
        date: new Date(date),
        sport,
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const mapGetBattlesByIdsResponse = (
  data: Array<Array<ApiGetBattlesByIdsRawData>>
): Array<ApiGetBattlesByIdsMappedData> => {
  return data
    .flat()
    .map(({ date, sport, homeTeam, awayTeam, ...battle }) => {
      return {
        ...battle,
        homeTeam: mapBattleTeam(homeTeam, sport),
        awayTeam: mapBattleTeam(awayTeam, sport),
        date: new Date(date),
        sport,
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const mapBattleTeam = (
  team: ApiGetBattlesRawTeam,
  sport: ApiCommonSport
): ApiGetBattlesTeam => {
  if (sport === 'tennis') {
    return {
      ...team,
      name: team.players!.map((player) => getTeamFormattedName(player.name)).join(','),
      logo: team.players![0].logo,

      players: team.players!.map((player) => ({
        ...player,
        name: getTeamFormattedName(player.name),
        shortName: getTeamShortName(player.name),
      })),
    };
  }

  return {
    ...team,
    name: getTeamFormattedName(team.name),
    players: [
      {
        name: getTeamFormattedName(team.name),
        shortName: getTeamShortName(team.name),
        logo: team.logo,
      },
    ],
  };
};

// todo: maybe fix on backend
export const getTeamFormattedName = (name: string): string => {
  if (name.includes(',')) {
    return name.split(',').reverse().join(' ').trim();
  }

  return name;
};

const getTeamShortName = (name: string): string => {
  if (name.includes(',')) {
    const [lastName, firstName] = name.split(',').map((str) => str.trim());

    return `${firstName.charAt(0)}. ${lastName}`;
  }

  return name;
};
