import { Slide, Slides } from '~shared/ui';

import { useAnnouncementsRandomSlides } from '../model';

export const Announcements = () => {
  const slides = useAnnouncementsRandomSlides();

  return (
    <Slides container autoplay={{ delay: 7000 }} speed={1500} loop>
      {slides.map((slide) => (
        <Slide key={slide.src} {...slide} />
      ))}
    </Slides>
  );
};
