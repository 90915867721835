import { FC, forwardRef } from 'react';

import { CircularProgress, Logo } from '~shared/ui';

import { PreloaderProps } from './types';
import { LogoWrapper, PreloaderBackdrop, PreloaderPaper, PreloaderRoot } from './styled';

export const Preloader: FC<PreloaderProps> = ({ open }) => {
  return (
    <PreloaderRoot
      open={open}
      slots={{ backdrop: PreloaderBackdrop }}
      backdropInitialTransition={false}
    >
      <PreloaderAnimated in={open}>
        <LogoWrapper>
          <Logo size={54} />
          <CircularProgress size={32} />
        </LogoWrapper>
      </PreloaderAnimated>
    </PreloaderRoot>
  );
};

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

const preloaderMotionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, transition: { duration: 0.2, type: 'tween' } },
  [AnimationVariant.Visible]: { opacity: 1, transition: { duration: 0.2, type: 'tween' } },
};

const PreloaderAnimated = forwardRef(({ in: open, onExited, onEnter, ...props }: any, ref) => {
  return (
    <PreloaderPaper
      variants={preloaderMotionVariants}
      animate={open ? AnimationVariant.Visible : AnimationVariant.Hidden}
      initial={AnimationVariant.Visible}
      onAnimationStart={open ? onEnter : undefined}
      onAnimationComplete={!open ? onExited : undefined}
      {...props}
      ref={ref}
    />
  );
});
