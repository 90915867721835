import { FC, Fragment } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';

import { useMediaQuery } from '~shared/lib/hooks';
import { ContentPlaceholder } from '~shared/ui';

import { NftCard, NftCardShimmer, useNftPreviewInfo, useQueryNfts } from '~entities/nft';

import { useMergeModel } from '~features/nft';

import { useIsAvailableToSelect, useUserCardsModel } from '../../model';
import { CARDS_TABS_MAP } from '../../lib';

import {
  HiddenNftContainer,
  UserCardsListGrid,
  UserCardsListPaddingSpace,
  UserCardsListShimmerContainer,
  UserCardsListVirtuosoGridScroller,
} from './styled';

import { UserCardsListProps } from './types';

export const UserCardsList: FC<UserCardsListProps> = () => {
  const { isMobile } = useMediaQuery();

  const { open: previewOpen, nft: previewNft, closePreview } = useNftPreviewInfo();
  const { rarityTab, isCardSelected, onNftClick, nfts } = useUserCardsModel();
  const { isLoading } = useQueryNfts();
  const { isAvailableToSelect } = useIsAvailableToSelect();
  const { isMergeAvailable } = useMergeModel();

  const hasCards = nfts.length !== 0;
  const cardSize = isMobile ? 'lg' : 'md';

  const renderNftByIndex = (index: number) => {
    const nft = nfts[index];

    if (nft.isHidden) {
      return (
        <HiddenNftContainer key={nft.token_id}>
          <NftCard nft={nft} size={cardSize} />
        </HiddenNftContainer>
      );
    }

    const areDetailsShown = previewOpen ? previewNft?.token_id === nft.token_id : false;

    return (
      <NftCard
        key={nft.token_id}
        onClick={onNftClick(nft, isMobile)}
        isSelectionMode={isAvailableToSelect(nft) || isCardSelected(nft)}
        checked={isCardSelected(nft)}
        areDetailsShown={areDetailsShown}
        nft={nft}
        size={cardSize}
      />
    );
  };

  const handleIsScrolling = (isScrolling: boolean) => {
    if (isScrolling) {
      closePreview();
    }
  };

  if (!isLoading && hasCards) {
    return (
      <VirtuosoGrid
        totalCount={nfts.length}
        itemContent={renderNftByIndex}
        isScrolling={handleIsScrolling}
        components={{
          List: UserCardsListGrid,
          Scroller: UserCardsListVirtuosoGridScroller,
          // handle MergeCards button margins
          Header: isMergeAvailable ? null : UserCardsListPaddingSpace,
          Footer: UserCardsListPaddingSpace,
        }}
        // conflicted with EventDialog
        tabIndex={undefined}
      />
    );
  }

  if (isLoading) {
    return (
      <UserCardsListShimmerContainer pt={isMergeAvailable ? 0 : 16 / 8}>
        {[...Array(9)].map((_, key) => (
          <NftCardShimmer key={key} size={cardSize} />
        ))}
      </UserCardsListShimmerContainer>
    );
  }

  return (
    <ContentPlaceholder
      heading="No cards"
      message={
        <Fragment>
          You have no <strong>{CARDS_TABS_MAP[rarityTab]}</strong> cards
        </Fragment>
      }
    />
  );
};
