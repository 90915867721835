import { Modal as MuiBaseModal } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const UIKitModal = styled(MuiBaseModal)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

export const UIKitModalRoot = styled('div', { name: 'Root' })({
  position: 'fixed',
  inset: '0',
});
