import { Fragment } from 'react';

import { useInitWallet } from '../model';

import { WalletConnectedDialog } from './WalletConnectedDialog';
import { ConnectWalletWarnDialog } from './ConnectWalletWarnDialog';
import { ConnectWalletDialog } from './ConnectWalletDialog';

export const WalletConstructor = () => {
  useInitWallet();

  return (
    <Fragment>
      <ConnectWalletDialog />
      <ConnectWalletWarnDialog />
      <WalletConnectedDialog />
    </Fragment>
  );
};
