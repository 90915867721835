import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ConfirmationDialog } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

export const SignOutDialog: FC = () => {
  const { t } = useTranslation();

  const { signOut, closeSignOut, signOutDialogOpen } = useViewerModel();

  return (
    <ConfirmationDialog
      open={signOutDialogOpen}
      onClose={closeSignOut}
      title={t('SignOut.signOut')}
      cancelButtonText={t('SignOut.no')}
      targetButton={
        <Button color="error" onClick={signOut}>
          {t('SignOut.signOut')}
        </Button>
      }
    >
      {t('SignOut.really')}
    </ConfirmationDialog>
  );
};
