import { createContext } from 'react';

import { ApiCommonSport, ApiCommonSportEnum } from '~shared/api';

import { BattleShellSize, BattleShellVariant } from './types';

interface BattleShellContextValue {
  variant: BattleShellVariant;
  size: BattleShellSize;

  sport: ApiCommonSport;
}

export const battleShellContextDefaultValue: BattleShellContextValue = {
  variant: 'line',
  size: 'md',

  sport: ApiCommonSportEnum.MMA,
};

export const BattleShellContext = createContext<BattleShellContextValue>(
  battleShellContextDefaultValue
);
