import { FC } from 'react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icon, IconAssetNamesType, Stack, Typography } from '~shared/ui';

import {
  DashboardFinanceCardDarkShadow,
  DashboardFinanceCardIconWrapper,
  DashboardFinanceCardLightShadow,
  DashboardFinanceCardRoot,
  DashboardFinanceCardShadowWrapper,
  dashboardFinanceCardClasses,
} from './styled';

import {
  DashboardFinanceCardProps,
  DashboardFinanceCardType,
  DashboardFinanceCardVariant,
} from './types';

export const DashboardFinanceCard: FC<DashboardFinanceCardProps> = ({ type, name, className }) => {
  const { t } = useTranslation();

  const contentUseTranslation = useTranslationContentType();
  const content = contentUseTranslation[type];

  const computedRootClassName = clsx(
    dashboardFinanceCardClasses.root,
    {
      [dashboardFinanceCardClasses.primary]: content.variant === 'primary',
      [dashboardFinanceCardClasses.error]: content.variant === 'error',
      [dashboardFinanceCardClasses.paper]: content.variant === 'paper',
    },
    className
  );

  return (
    <DashboardFinanceCardRoot className={computedRootClassName}>
      <Stack direction="row" spacing={12 / 8} alignItems="center">
        <DashboardFinanceCardIconWrapper className={dashboardFinanceCardClasses.iconWrapper}>
          <Icon name={content.icon} />
        </DashboardFinanceCardIconWrapper>
        <Typography variant="h2">{name}</Typography>
      </Stack>

      <Stack spacing={3 / 8}>
        <Typography textAlign="center">{t('Other.comingSoon')} </Typography>
      </Stack>

      <DashboardFinanceCardShadowWrapper>
        <DashboardFinanceCardLightShadow className={dashboardFinanceCardClasses.lightShadow} />
        <DashboardFinanceCardDarkShadow className={dashboardFinanceCardClasses.darkShadow} />
      </DashboardFinanceCardShadowWrapper>
    </DashboardFinanceCardRoot>
  );
};

type ContentTypeMap = Record<
  DashboardFinanceCardType,
  {
    icon: IconAssetNamesType;
    label: string;
    variant: DashboardFinanceCardVariant;
  }
>;

const useTranslationContentType = () => {
  const { t } = useTranslation();

  const contentTypeMap: ContentTypeMap = {
    income: { icon: 'wallet-income', label: `${t('Other.income')}`, variant: 'primary' },
    outcome: { icon: 'wallet-outcome', label: `${t('Other.outcome')}`, variant: 'error' },
    total: { icon: 'wallet-money', label: `${t('Other.total')}`, variant: 'paper' },
  };

  return contentTypeMap;
};
