import { apiInstance } from '../apiInstance';

import { ViewerRoutes } from './routes';

import {
  ApiGetDefaultAvatars,
  ApiGetViewerProfile,
  ApiPostCheckNickname,
  ApiPostOnboardingPassed,
  ApiPostRecoveryPassword,
  ApiPostSetPassword,
  ApiPostSetWallet,
  ApiPostUpdatePassword,
  ApiPostViewerAvatar,
  ApiPutNickname,
  ApiPutViewerAvatar,
} from './types';

export const getDefaultAvatars: ApiGetDefaultAvatars = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(ViewerRoutes.defaultAvatars())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getViewerProfile: ApiGetViewerProfile = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(ViewerRoutes.profile())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postUpdatePassword: ApiPostUpdatePassword = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.updatePassword(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postViewerAvatar: ApiPostViewerAvatar = (data, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.userAvatar(), data, { onUploadProgress })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const putViewerAvatar: ApiPutViewerAvatar = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .put(ViewerRoutes.userAvatar(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const putNickname: ApiPutNickname = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .put(ViewerRoutes.setNickname(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postRecoveryPassword: ApiPostRecoveryPassword = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.recoveryPassword(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSetPassword: ApiPostSetPassword = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setPassword(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSetWallet: ApiPostSetWallet = async (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setWallet(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postCheckNickname: ApiPostCheckNickname = (data, signal) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.checkNickname(), data, { signal })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postOnboardingPassed: ApiPostOnboardingPassed = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ViewerRoutes.setOnboardingPassed(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
