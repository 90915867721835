import { useCallback, useEffect } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { useMount, usePrevious } from 'react-use';
import { Trans, useTranslation } from 'react-i18next';
import localforage from 'localforage';

import {
  getProvider,
  openDeepLink,
  openLink,
  sliceHash,
  wrapTransactionMessage,
} from '~shared/lib/utils';
import { postSetWallet } from '~shared/api';
import {
  useDispatch,
  useMediaQuery,
  useSnackbar,
  useTransactionStatusDialog,
} from '~shared/lib/hooks';
import {
  Arena__factory,
  AuctionForMCN__factory,
  AuctionForMatic__factory,
  MagicBox__factory,
  MainToken__factory,
  Multicall__factory,
  NFT__factory,
} from '~shared/contracts';

import { useViewerSelector, viewerActions } from '~entities/viewer';

import { useOnboardingModel } from '~features/onboarding';

import { StorageKeys } from '~shared/api/localforage';

import { useWalletSelector } from '~entities/wallet';

import { getContract } from '../lib';

import { walletActions } from './slice';
import { ConnectionType, TransactionError } from './types';
import { connections } from './config';

const WALLET_ALREADY_TAKEN_SERVER_ERROR_MESSAGE = 'wallet already taken';

export const getMaticBalance = async (address: string) => {
  const balance = await getProvider().getBalance(address);

  // Balance is rounded at 2 decimals instead of 18, to simplify the UI
  return (
    ethers.BigNumber.from(balance).div(ethers.BigNumber.from('10000000000000000')).toNumber() / 100
  );
};

export const useGetBalance = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();

  const getBalance = useCallback(async () => {
    if (!account) {
      return;
    }

    const maticBalance = await getMaticBalance(account);

    const provider = getProvider();
    const mcnContract = MainToken__factory.connect(
      process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
      provider
    );

    const mcnBalanceInWei = await mcnContract.balanceOf(account);
    const mcnBalance = Number(ethers.utils.formatEther(mcnBalanceInWei));

    dispatch(
      walletActions.setWallet({
        balance: {
          mcn: mcnBalance,
          native: maticBalance,
        },
      })
    );
  }, [account, dispatch]);

  return getBalance;
};

export const useBalance = () => {
  const { account } = useWeb3React();
  const getBalance = useGetBalance();

  useEffect(() => {
    if (account) {
      getBalance();
    }
  }, [account, getBalance]);
};

export const useConnect = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQuery();
  const viewer = useViewerSelector();

  const connect = async (connection: ConnectionType) => {
    const { downloadLink, deepLink, installed, connector } = connections[connection];
    const shouldOpenDownloadLink = !isMobile && !installed && downloadLink;
    const shouldOpenDeepLink = isMobile && !installed && deepLink;

    if (shouldOpenDownloadLink) {
      openLink(downloadLink);

      return;
    } else if (shouldOpenDeepLink) {
      openDeepLink(deepLink);

      return;
    }

    try {
      await connector.activate();
      localforage.setItem(StorageKeys.AutoConnectDisabled, false);
    } catch (e) {}
  };

  const openConnectWalletDialog = () => {
    dispatch(walletActions.setWallet({ isConnectWalletDialogOpen: true }));
  };

  const openConnectWalletDialogAndOnboarding = () => {
    if (!viewer.onboarding) {
      dispatch(walletActions.setWallet({ isOnboardingShownAfterConnect: true }));
    }

    dispatch(walletActions.setWallet({ isConnectWalletDialogOpen: true }));
  };

  const openConnectWalletWarnDialog = () => {
    dispatch(walletActions.setWarnDialog(true));
  };

  return {
    connect,
    openConnectWalletDialog,
    openConnectWalletWarnDialog,
    openConnectWalletDialogAndOnboarding,
  };
};

export const useDisconnect = () => {
  const dispatch = useDispatch();
  const { connector } = useWeb3React();

  const disconnect = () => {
    connector?.deactivate?.();
    connector?.resetState();
    dispatch(walletActions.resetWallet());
    localforage.setItem(StorageKeys.AutoConnectDisabled, true);
  };

  return disconnect;
};

const useIsCurrentChainSupported = () => {
  const { chainId } = useWeb3React();
  const disconnect = useDisconnect();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (chainId && chainId !== Number(process.env.REACT_APP_NODE_CHAIN_ID)) {
      disconnect();

      openSnackbar({
        type: 'error',
        message:
          'Currently this app only supported on Polygon Network. Please, switch your network to Polygon in your wallet and try to connect again.',
      });
    }
  }, [chainId, disconnect, openSnackbar]);
};

const useCheckIfEmailIsBoundedToWallet = () => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const viewer = useViewerSelector();
  const { account } = useWeb3React();
  const disconnect = useDisconnect();

  const checkIfEmailAndWalletShouldBeBound = async () => {
    const isAuthenticated = Boolean(viewer.email);
    const isConnected = !!account;

    if (!isConnected) {
      return;
    }

    if (isAuthenticated) {
      const hasUserBoundWalletToEmail = Boolean(viewer.wallet);

      if (!hasUserBoundWalletToEmail) {
        try {
          await postSetWallet({ email: viewer.email, wallet: account });
          dispatch(walletActions.setWalletConnectedDialog(true));
          dispatch(viewerActions.updateData({ ...viewer, wallet: account }));
        } catch (e) {
          disconnect();

          if (e.response?.data?.result === WALLET_ALREADY_TAKEN_SERVER_ERROR_MESSAGE) {
            dispatch(walletActions.setWallet({ isCorrectWalletConnected: false }));

            openSnackbar({
              type: 'error',
              message: `${t('Other.thisWallet')}`,
            });
          }
        }

        return;
      }

      const isConnectingWithBoundWalletAddress = viewer.wallet === account;

      if (!isConnectingWithBoundWalletAddress) {
        dispatch(walletActions.setWallet({ isCorrectWalletConnected: false }));
        disconnect();

        openSnackbar({
          type: 'error',
          message: (
            <Trans i18nKey="Alerts.addressIsNotBound">
              {{ hash: sliceHash(account) }} address is not bound to {{ email: viewer.email }}.
              Please, try to connect with {{ ownHash: sliceHash(viewer.wallet) }} address.
            </Trans>
          ),
        });

        return;
      }

      dispatch(walletActions.setWallet({ isCorrectWalletConnected: true }));
    }
  };

  useEffect(() => {
    if (!!account) {
      checkIfEmailAndWalletShouldBeBound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, viewer.wallet, viewer.email]);
};

const useConnectToCachedWallet = () => {
  const { connector, isActive } = useWeb3React();

  useMount(async () => {
    const isAutoConnectDisabled = await localforage.getItem<boolean | null>(
      StorageKeys.AutoConnectDisabled
    );

    if (connector && !isActive && !isAutoConnectDisabled) {
      connector.activate();
    }
  });
};

const useWatchEvents = () => {
  const disconnect = useDisconnect();
  const { chainId, account } = useWeb3React();

  const previousChainId = usePrevious(chainId);
  const previousAccount = usePrevious(account);

  useEffect(() => {
    const isChainChanged =
      previousChainId && previousChainId !== Number(process.env.REACT_APP_NODE_CHAIN_ID);

    const isAccountChanged = previousAccount && previousAccount !== account;

    if (isChainChanged || isAccountChanged) {
      disconnect();
    }
  }, [chainId, account, disconnect, previousChainId, previousAccount]);
};

const useShowOnboardingAfterConnect = () => {
  const { account } = useWeb3React();
  const { isOnboardingShownAfterConnect, isCorrectWalletConnected } = useWalletSelector();
  const { onOpen: openOnboarding } = useOnboardingModel();

  const isConnected = Boolean(account);

  useEffect(() => {
    if (isConnected && isOnboardingShownAfterConnect && isCorrectWalletConnected) {
      setTimeout(() => {
        openOnboarding('1');
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isOnboardingShownAfterConnect, isCorrectWalletConnected]);
};

export const useInitWallet = () => {
  useIsCurrentChainSupported();
  useBalance();
  useConnectToCachedWallet();
  useCheckIfEmailIsBoundedToWallet();
  useWatchEvents();
  useShowOnboardingAfterConnect();
};

type Contract =
  | 'NFT'
  | 'AuctionForMCN'
  | 'AuctionForMatic'
  | 'Arena'
  | 'MagicBox'
  | 'Multicall'
  | 'MainToken';

const contractNameToFactoryMap = {
  NFT: NFT__factory,
  AuctionForMCN: AuctionForMCN__factory,
  AuctionForMatic: AuctionForMatic__factory,
  Arena: Arena__factory,
  MagicBox: MagicBox__factory,
  Multicall: Multicall__factory,
  MainToken: MainToken__factory,
};

const contractNameToAddressMap = {
  NFT: process.env.REACT_APP_ADDRESS_SK_CARD,
  AuctionForMCN: process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN,
  AuctionForMatic: process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MATIC,
  Arena: process.env.REACT_APP_ADDRESS_SK_ARENA,
  MagicBox: process.env.REACT_APP_ADDRESS_SK_MAGIC_BOX,
  Multicall: process.env.REACT_APP_ADDRESS_MULTICALL,
  MainToken: process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
};

interface UseWriteContractParams {
  contract: Contract;
  method: string;
  transactionName?: string;
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: VoidFunction;
  onError?: VoidFunction;
}

export const useWriteContract = ({
  contract,
  method,
  transactionName,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
}: UseWriteContractParams) => {
  const { openSnackbar } = useSnackbar();
  const { openTransactionStatusDialog, closeTransactionStatusDialog } =
    useTransactionStatusDialog();

  const { openConnectWalletWarnDialog } = useConnect();

  const { account, provider } = useWeb3React<ethers.providers.JsonRpcProvider>();

  const write = async ({ args, value }: { args: Array<unknown>; value?: BigNumber }) => {
    if (!account) {
      openConnectWalletWarnDialog();

      return;
    }

    const contractFactory = contractNameToFactoryMap[contract];
    const contractAddress = contractNameToAddressMap[contract];

    const contractInstance = getContract({
      abi: contractFactory.abi,
      address: contractAddress,
      // @ts-ignore
      provider,
      account,
    });

    const additionalArgs = [];

    if (value) {
      additionalArgs.push({ value });
    }

    try {
      openTransactionStatusDialog(transactionName);

      // @ts-ignore
      const tx = await contractInstance[method](...args, ...additionalArgs);

      await tx.wait();

      closeTransactionStatusDialog();
      onSuccess?.();
      openSnackbar({ type: 'info', message: successMessage || 'Transaction is successful!' });
    } catch (err) {
      closeTransactionStatusDialog();

      if (err.code === TransactionError.Rejected) {
        openSnackbar({
          type: 'error',
          message: 'You declined the transaction',
        });

        throw err;
      }

      openSnackbar({
        type: 'error',
        message: wrapTransactionMessage(errorMessage || 'Transaction is failed!'),
      });

      onError?.();
      throw err;
    }
  };

  return {
    write,
  };
};

interface UseCallGaslessParams<TCallbackParams> {
  callback: (params: TCallbackParams) => Promise<{ message: string; hash: string }>;
  transactionName?: string;
  successMessage?: string;
  errorMessage?: string;
}

export function useCallGasless<TCallbackParams>({
  callback,
  transactionName,
  successMessage,
  errorMessage,
}: UseCallGaslessParams<TCallbackParams>) {
  const { openSnackbar } = useSnackbar();
  const { openTransactionStatusDialog, closeTransactionStatusDialog } =
    useTransactionStatusDialog();

  const { openConnectWalletWarnDialog } = useConnect();

  const { account, provider } = useWeb3React<ethers.providers.JsonRpcProvider>();

  const write = async (params: TCallbackParams) => {
    if (!account) {
      openConnectWalletWarnDialog();

      return;
    }

    try {
      openTransactionStatusDialog(transactionName);

      const response = await callback(params);

      const receipt = await provider?.waitForTransaction(response.hash);

      if (receipt?.status !== 1) {
        throw new Error();
      }

      openSnackbar({ type: 'info', message: successMessage || 'Transaction is successful!' });
    } catch (err) {
      openSnackbar({
        type: 'error',
        message: wrapTransactionMessage(errorMessage || 'Transaction is failed!'),
      });

      throw err;
    } finally {
      closeTransactionStatusDialog();
    }
  };

  return write;
}
