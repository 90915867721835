import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BigNumber, ethers } from 'ethers';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';
import { NFT__factory } from '~shared/contracts';
import { getProvider } from '~shared/lib/utils';

import {
  NftToMergeSerialNumber,
  nftActions,
  useNftCardModel,
  useQueryLastAddedNft,
} from '~entities/nft';

import { useWalletSelector, useWriteContract } from '~entities/wallet';

import { OnMergeSuccess, useMergeModel } from '~features/nft';

const CARD_MINT_TIME_IN_MS = 20000;

export const useMerge = (onMergeSuccess: OnMergeSuccess) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [mergePriceInWei, setMergePriceInWei] = useState<BigNumber | null>();

  const queryLastAddedNft = useQueryLastAddedNft();
  const { balance } = useWalletSelector();
  const { closeMergeDialog } = useMergeModel();

  const {
    nftsToMerge: [firstNftToMerge, secondNftToMerge],
  } = useNftCardModel();

  const { write: upgrade } = useWriteContract({
    contract: 'NFT',
    method: 'upgrade',
    transactionName: 'Merging NFTs',
    successMessage: `${t('Alerts.mergeNft')}`,
    errorMessage: `${t('Errors.mergeNftFailed')}`,
  });

  const isMergePriceFetched = Boolean(mergePriceInWei);
  const isSameRarity = firstNftToMerge?.rarity === secondNftToMerge?.rarity;
  const isMergeAvailable = isMergePriceFetched && isSameRarity;

  const mergePrice = useMemo(() => {
    if (mergePriceInWei) {
      return ethers.utils.formatEther(mergePriceInWei);
    }

    return '';
  }, [mergePriceInWei]);

  const merge = useCallback(async () => {
    if (!firstNftToMerge || !secondNftToMerge || !mergePriceInWei) {
      return;
    }

    const mergePrice = Number(ethers.utils.formatEther(mergePriceInWei));
    const isEnoughFunds = balance.native >= mergePrice;

    if (!isEnoughFunds) {
      const remainingMaticToMergeCards = mergePrice - balance.native;

      openSnackbar({
        type: 'error',
        message: `You don't have enough funds to merge these cards. Add ${remainingMaticToMergeCards} MATIC to your wallet`,
      });

      return;
    }

    try {
      await upgrade({
        args: [firstNftToMerge.token_id, secondNftToMerge.token_id],
        value: mergePriceInWei,
      });

      openSnackbar({
        type: 'info',
        message: `${t('Alerts.wait')}`,
      });

      setTimeout(async () => {
        const lastAddedNft = await queryLastAddedNft();

        if (lastAddedNft) {
          onMergeSuccess(lastAddedNft);
        }
      }, CARD_MINT_TIME_IN_MS);

      closeMergeDialog();

      dispatch(nftActions.mergeCards([firstNftToMerge, secondNftToMerge]));
    } catch {}
  }, [
    balance.native,
    closeMergeDialog,
    dispatch,
    firstNftToMerge,
    mergePriceInWei,
    onMergeSuccess,
    openSnackbar,
    queryLastAddedNft,
    secondNftToMerge,
    t,
    upgrade,
  ]);

  const getMergePrice = useCallback(async () => {
    const rarity = firstNftToMerge?.rarity;
    const provider = getProvider();

    if (rarity !== undefined) {
      const nftContract = NFT__factory.connect(process.env.REACT_APP_ADDRESS_SK_CARD, provider);

      const mergePrice = await nftContract.upgradePrice(rarity);

      setMergePriceInWei(mergePrice);
    }
  }, [firstNftToMerge]);

  useEffect(() => {
    const isBothNftsPlacedToCells = Boolean(firstNftToMerge) && Boolean(secondNftToMerge);
    const isNotSameRarity = firstNftToMerge?.rarity !== secondNftToMerge?.rarity;

    if (isBothNftsPlacedToCells && isNotSameRarity) {
      openSnackbar({ type: 'info', message: `${t('Alerts.mergeNftRarity')}` });
      dispatch(nftActions.removeNftToMerge([NftToMergeSerialNumber.Second]));
      setMergePriceInWei(null);
    }
  }, [
    firstNftToMerge?.rarity,
    openSnackbar,
    secondNftToMerge?.rarity,
    dispatch,
    firstNftToMerge,
    secondNftToMerge,
    t,
  ]);

  useEffect(() => {
    const isBothNftsPlacedToCells = Boolean(firstNftToMerge) && Boolean(secondNftToMerge);

    if (isBothNftsPlacedToCells) {
      getMergePrice();

      return;
    }

    setMergePriceInWei(null);
  }, [firstNftToMerge, secondNftToMerge, getMergePrice]);

  return {
    mergePrice,
    merge,

    isMergeAvailable,
  };
};
