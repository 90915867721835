import { Fragment, forwardRef } from 'react';
import { format, isThisWeek, isToday, isYesterday } from 'date-fns';
import { clsx } from 'clsx';

import {
  NotificationBadge,
  NotificationContent,
  NotificationRoot,
  NotificationTime,
  notificationClasses,
} from './styled';

import { NotificationProps } from './types';

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

const transition = { duration: 0.2, type: 'tween' } as const;

const paperMotionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, y: -5, scale: 0.98, transition },
  [AnimationVariant.Visible]: { opacity: 1, y: 0, scale: 1, transition },
};

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (
    {
      date,
      icon,
      read,
      place = 'list',

      children,
      className,
    },
    ref
  ) => {
    return (
      <NotificationRoot
        ref={ref}
        className={clsx(
          notificationClasses.root,
          {
            [notificationClasses.list]: place === 'list',
            [notificationClasses.snackbar]: place === 'snackbar',
          },
          className
        )}
        layout={place === 'snackbar'}
        variants={paperMotionVariants}
        initial={AnimationVariant.Hidden}
        animate={AnimationVariant.Visible}
        exit={AnimationVariant.Hidden}
      >
        {icon}

        <NotificationContent>{children}</NotificationContent>

        <NotificationTime>{getFormattedTime(date)}</NotificationTime>

        {!read && <NotificationBadge variant="dot" color="info" />}
      </NotificationRoot>
    );
  }
);

enum TimeFormatType {
  Today,
  Yesterday,
  ThisWeek,
  Date,
}

const getTimeFormatType = (date: Date): TimeFormatType => {
  if (isToday(date)) {
    return TimeFormatType.Today;
  }

  if (isYesterday(date)) {
    return TimeFormatType.Yesterday;
  }

  if (isThisWeek(date)) {
    return TimeFormatType.ThisWeek;
  }

  return TimeFormatType.Date;
};

const getFormattedTime = (date: Date) => {
  const formatType = getTimeFormatType(date);

  const formattedTime = format(date, 'HH:mm');

  switch (formatType) {
    case TimeFormatType.Today:
      return formattedTime;
    case TimeFormatType.Yesterday:
      return (
        <Fragment>
          Yestr.
          <br />
          {formattedTime}
        </Fragment>
      );
    case TimeFormatType.ThisWeek:
      return (
        <Fragment>
          {format(date, 'eee')}
          <br />
          {formattedTime}
        </Fragment>
      );
    case TimeFormatType.Date:
      return (
        <Fragment>
          {format(date, 'd MMM')}
          <br />
          {formattedTime}
        </Fragment>
      );
  }
};
