import { FC } from 'react';

import { isTest, routes } from '~shared/config';
import { Link, Logo } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NotificationsControl } from '~entities/notifications';

import { SwitchLanguage } from '~features/switch-language';

import { NavMenu } from './NavMenu';
import { UserArea } from './UserArea';
import { Help } from './Help';
import { Wallet } from './Wallet';

import { HeaderLineContainer, HeaderLineEnd, HeaderLogoWrapper, HeaderRoot } from './styled';

import { HeaderProps } from './types';

export const Header: FC<HeaderProps> = () => {
  const isSecondaryBreakpointView = useMediaQuery(980);
  const isSmallView = useMediaQuery('sm');

  return (
    <HeaderRoot>
      <HeaderLineContainer>
        <HeaderLogoWrapper>
          <Link to={routes.index}>
            <Logo interactive beta={isTest()} />
          </Link>
        </HeaderLogoWrapper>

        {!isSecondaryBreakpointView && <NavMenu />}

        <HeaderLineEnd>
          <SwitchLanguage />
          <NotificationsControl />

          <Wallet dropdown={isSmallView} />

          <UserArea size={isSmallView ? 32 : 48} showOnboardingMenuItem={isSmallView} />

          {!isSmallView && <Help />}
        </HeaderLineEnd>
      </HeaderLineContainer>
    </HeaderRoot>
  );
};
