import { SwiperSlide } from 'swiper/react';

import { styled } from '~shared/lib/styles';

export const slideClasses = {
  root: 'Slide-root',
  img: 'Slide-img',

  interactive: 'Slide-interactive',
};

export const UIKitSlideRoot = styled(SwiperSlide, { name: 'Slide' })(() => ({
  ['&.swiper-slide']: {
    width: 'max-content',
    overflow: 'hidden',
    borderRadius: 12,

    [`&.${slideClasses.interactive}`]: {
      cursor: 'pointer',
    },
  },
}));

export const UIKitSlideImg = styled('img', { name: 'Slide', slot: 'img' })(() => ({
  display: 'block',
}));
