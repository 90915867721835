import { styled } from '~shared/lib/styles';

import { FormLabel } from '../../FormLabel';

export const inputLabelClasses = {
  focused: 'MuiInputLabel-focused',
  filled: 'MuiInputLabel-filled',
  disabled: 'MuiInputLabel-disabled',
};

export const UIKitLabel = styled(FormLabel)(({ theme }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: 1,

  fontSize: 14,
  fontWeight: 400,
  lineHeight: '14px',

  color: '#686868',
  transform: 'translate(10px, 15.5px) scale(1)',
  transformOrigin: 'left top',
  transition: theme.transitions.create('transform'),

  [`&.${inputLabelClasses.focused}, &.${inputLabelClasses.filled}`]: {
    transform: 'translate(10px, 9.5px) scale(0.714)',
  },

  [`&.${inputLabelClasses.disabled}`]: {
    color: theme.palette.grey.A50,
  },
}));
