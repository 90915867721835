import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Container, ContentPlaceholder, Stack } from '~shared/ui';
import { SportsConfig } from '~shared/config/sport';
import { ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import {
  BattleHorizontalList,
  BattleVerticalList,
  useBattleList,
  useMultipleTypeBattlesListQuery,
} from '~entities/battle';

import { MultipleBattleListProps } from './types';

export const MultipleBattleList: FC<MultipleBattleListProps> = ({ type, sport }) => {
  const { t } = useTranslation();

  const multipleTypeBattlesListQuery = useMultipleTypeBattlesListQuery(sport);

  const { list, renderSquareBattle } = useBattleList({ list: multipleTypeBattlesListQuery.list });

  if (multipleTypeBattlesListQuery.isEmptyList) {
    return (
      <ContentPlaceholder
        heading="No available events for the moment"
        message="Please comeback later"
      />
    );
  }

  switch (type) {
    case 'list':
      return (
        <BattleVerticalList loading={multipleTypeBattlesListQuery.isLoading}>
          {list}
        </BattleVerticalList>
      );
    case 'grid':
      return (
        <Container negative disableRightGutters>
          <Stack spacing={12 / 8}>
            {sport.map((sport) => {
              const list = multipleTypeBattlesListQuery.lists[sport] ?? [];

              return (
                <BattleHorizontalList
                  key={sport}
                  loading={multipleTypeBattlesListQuery.isLoading}
                  {...SportsDataMap[sport]}
                  navigationContent={
                    <Trans i18nKey="Other.goToAllMatches" t={t}>
                      Go to all {{ sport: SportsConfig[sport].label }} matches
                    </Trans>
                  }
                >
                  {list.slice(0, 10).map(renderSquareBattle)}
                </BattleHorizontalList>
              );
            })}
          </Stack>
        </Container>
      );
  }
};

// todo: common data collection for each sport
const SportsDataMap: Record<
  ApiCommonSportEnum,
  {
    label: string;
    navigation: string;
    navigationContent: string;
  }
> = {
  [ApiCommonSportEnum.Basketball]: {
    label: 'NBA',
    navigation: routes.sports.basketball,
    navigationContent: 'Go to all NBA matches',
  },
  [ApiCommonSportEnum.Football]: {
    label: 'Football',
    navigation: routes.sports.football,
    navigationContent: 'Go to all football matches',
  },
  [ApiCommonSportEnum.Hockey]: {
    label: 'NHL',
    navigation: routes.sports.basketball,
    navigationContent: 'Go to all NHL matches',
  },
  [ApiCommonSportEnum.MMA]: {
    label: 'MMA',
    navigation: routes.sports.mma,
    navigationContent: 'Go to all MMA matches',
  },
  [ApiCommonSportEnum.Cricket]: {
    label: 'Cricket',
    navigation: routes.sports.cricket,
    navigationContent: 'Go to all cricket matches',
  },
  [ApiCommonSportEnum.Baseball]: {
    label: 'Baseball',
    navigation: routes.sports.baseball,
    navigationContent: 'Go to all baseball matches',
  },
  [ApiCommonSportEnum.Dota]: {
    label: 'Dota 2',
    navigation: routes.sports.dota,
    navigationContent: 'Go to all Dota 2 matches',
  },
  [ApiCommonSportEnum.CSGO]: {
    label: 'CS GO',
    navigation: routes.sports.csgo,
    navigationContent: 'Go to all CS GO matches',
  },
  [ApiCommonSportEnum.LeagueOfLegends]: {
    label: 'League of Legends',
    navigation: routes.sports.leagueOfLegends,
    navigationContent: 'Go to all League of Legends matches',
  },
  [ApiCommonSportEnum.Tennis]: {
    label: 'Tennis',
    navigation: routes.sports.tennis,
    navigationContent: 'Go to all tennis matches',
  },
  [ApiCommonSportEnum.AmericanFootball]: {
    label: 'NFL',
    navigation: routes.sports.americanFootball,
    navigationContent: 'Go to all NFL matches',
  },
};
