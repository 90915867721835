import { FC, Fragment, cloneElement, forwardRef } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { BreadcrumbsProps } from './types';
import { BreadcrumbsRoot, BreadcrumbsSeparator, breadcrumbsClasses } from './styled';

export const Breadcrumbs: FC<BreadcrumbsProps> = forwardRef(
  ({ children, separator, indent = true, className, ...props }, ref) => {
    const Separator = separator ?? <Icon name="chevron-right" size={18} />;

    return (
      <BreadcrumbsRoot
        className={clsx({ [breadcrumbsClasses.indent]: indent }, className)}
        ref={ref}
        {...props}
      >
        {children?.map((breadcrumb, index) => {
          return (
            <Fragment key={`separator-${index}`}>
              {index !== 0 && <BreadcrumbsSeparator>{Separator}</BreadcrumbsSeparator>}
              {cloneElement(breadcrumb)}
            </Fragment>
          );
        })}
      </BreadcrumbsRoot>
    );
  }
);
