import { styled } from '~shared/lib/styles';

export const CallsBannerImage = styled('img', {
  name: 'EventDialogCallsBanner',
  slot: 'callsBannerImage',
})({
  pointerEvents: 'all',
  cursor: 'pointer',
  width: '100%',
});
