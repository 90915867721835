import { FC } from 'react';

import { Avatar, Icon } from '~shared/ui';

import { NftCardsStack, StaticNftCard } from '~entities/nft';

import { useViewerModel } from '~entities/viewer';

import {
  TournamentPlayerBrief,
  TournamentPlayerName,
  TournamentPlayerPlace,
  TournamentPlayerRoot,
} from './styled';
import { TournamentPlayerProps } from './types';

export const TournamentPlayer: FC<TournamentPlayerProps> = ({
  cards = [1234, 1235, 1236, 1237, 1238],
  name,
  place,
  avatar,
  isViewer = false,
}) => {
  const { defaultAvatars } = useViewerModel();

  return (
    <TournamentPlayerRoot active={isViewer}>
      <TournamentPlayerBrief>
        <TournamentPlayerPlace>{place}</TournamentPlayerPlace>

        <Avatar
          src={avatar || defaultAvatars?.[Math.floor(Math.random() * 6)]?.src || ''}
          size={32}
        />
        <TournamentPlayerName>{name}</TournamentPlayerName>

        {place === 1 && <Icon name="crown" />}
      </TournamentPlayerBrief>

      <NftCardsStack cardsToShow={10} spacing="spacious">
        {cards.map((tokenId) => (
          <StaticNftCard key={tokenId} tokenId={1234} />
        ))}
      </NftCardsStack>
    </TournamentPlayerRoot>
  );
};
