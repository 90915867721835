import { FC } from 'react';
import { AnimatePresence, AnimationProps } from 'framer-motion';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { useBattleDragHighlight } from '~entities/battle';

import { useUserCardsReturnCard } from '../../model';

import { Root, returnCardDropZoneClasses } from './styled';
import { DropZoneProps } from './types';

export const ReturnCardDropZone: FC<DropZoneProps> = () => {
  const { highlight, ...highlightProps } = useBattleDragHighlight();

  const { dropRef, isDropZoneOpen } = useUserCardsReturnCard();

  return (
    <AnimatePresence>
      {isDropZoneOpen && (
        <Root
          ref={dropRef}
          className={clsx(returnCardDropZoneClasses.root, {
            [returnCardDropZoneClasses.highlight]: highlight,
          })}
          {...highlightProps}
          {...animationProps}
        >
          <Icon name="back" size={24} />
        </Root>
      )}
    </AnimatePresence>
  );
};

const animationProps: AnimationProps = {
  initial: {
    height: 0,
    opacity: 0,
    marginTop: 0,
  },
  animate: {
    height: 100,
    opacity: 1,
    marginTop: 24,
    transition: { duration: 0.2, type: 'tween' },
  },
  exit: {
    height: 0,
    opacity: 0,
    marginTop: 0,
    transition: { duration: 0.2, type: 'tween' },
  },
};
