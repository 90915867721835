import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { palette } from '~shared/lib/styles/palette';

import { getNftStatus } from '../../../lib';

import { NftFreezed } from './NftFreezed';
import { NftShouldBeRecovered } from './NftShouldBeRecovered';
import { NftBlockedForEvent } from './NftBlockedForEvent';
import { NftStatusBadge } from './NftStatusBadge';

import { NftCardStatesRoot } from './styled';
import { NftCardStatesProps } from './types';

export const NftCardStates: FC<NftCardStatesProps> = ({ nft, size }) => {
  const { t } = useTranslation();

  const hasBackdrop = nft.isFreezed || nft.isBlockedForEvent;
  const status = useMemo(() => getNftStatus(nft), [nft]);

  const getStatusNode = () => {
    switch (true) {
      case status.isBlockedForEvent:
        return (
          <Fragment>
            <NftBlockedForEvent />
            {nft.isLive && (
              <NftStatusBadge background={palette.violet.main}>{t('State.live')}</NftStatusBadge>
            )}
          </Fragment>
        );
      case status.isOnAuction:
        return (
          <Fragment>
            <NftStatusBadge background={palette.paper.main}>{t('State.auction')}</NftStatusBadge>
            {status.isShouldBeRecovered && <NftShouldBeRecovered />}
          </Fragment>
        );
      case status.isOnEvent:
        return (
          <NftStatusBadge background={palette.paper.main}>{t('State.onEvent')}</NftStatusBadge>
        );
      case status.isFreezed:
        return <NftFreezed />;
      case status.isShouldBeRecovered:
        return <NftShouldBeRecovered />;
      case status.isAvailableForMerge:
        return (
          <NftStatusBadge background={palette.success.main}>{t('State.merge')}</NftStatusBadge>
        );
    }
  };

  const statusNode = getStatusNode();

  return (
    <NftCardStatesRoot $size={size} $hasBackdrop={hasBackdrop}>
      {statusNode}
    </NftCardStatesRoot>
  );
};
