import { ElementType, forwardRef, useMemo } from 'react';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';

import { UIKitTypography } from './styled';
import { TypographyProps, TypographyVariant } from './types';

const variantElementMapping: Record<TypographyVariant, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  body: 'p',
};

const colorTransformationMapping = {
  primary: 'text.main',
  secondary: 'text.secondary',
};

export const Typography = forwardRef<HTMLElement, TypographyProps>(
  ({ variant = 'body', children, component, color, ...props }, ref) => {
    const extendedSxProps = useMemo(
      () =>
        extendSxProp({
          ...props,
          color: colorTransformationMapping[color as string] || color,
        }),
      [color, props]
    );

    return (
      <UIKitTypography
        as={component || variantElementMapping[variant]}
        variant={variant}
        ref={ref}
        {...extendedSxProps}
      >
        {children}
      </UIKitTypography>
    );
  }
);
