import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NotificationsService } from './service';
import { NotificationEvent, NotificationsState } from './types';

const initialState: NotificationsState = {
  service: null,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<NotificationsService>) => ({
      ...state,
      service: action.payload,
    }),

    updateNotifications: (state, action: PayloadAction<NotificationEvent[]>) => ({
      ...state,
      notifications: action.payload,
    }),
  },
});

export const notificationsReducer = notificationsSlice.reducer;
export const notificationsActions = notificationsSlice.actions;
