import { ApiGetBetsBetMappedData, ApiGetCallsMappedData } from '~shared/api';

export enum NftCardDetailedInfoTab {
  Battles,
  Calls,
  Transactions,
}

export interface NftStats {
  winsAmount: number | null;
  losesAmount: number | null;
}

interface Query<T> {
  isLoading: boolean;
  isEmptyList: boolean | undefined;
  list: T[] | undefined;
  passedList: T[] | undefined;
  currentList: T[] | undefined;
}

export interface NftBetsQuery extends Query<ApiGetBetsBetMappedData> {}

export interface NftCallsQuery extends Query<ApiGetCallsMappedData> {}
