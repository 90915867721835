import { styled } from '~shared/lib/styles';

import { Icon } from '../../dataDisplay';

interface LogoRootProps {
  $interactive?: boolean;
}

export const LogoRoot = styled(Icon)<LogoRootProps>(({ $interactive, theme }) => {
  if ($interactive) {
    return {
      color: theme.palette.text.primary,
      transition: theme.transitions.create('color'),

      ['&:hover']: {
        color: theme.palette.grey.A50,
      },
    };
  }

  return {};
});
