import { styled } from '~shared/lib/styles';

export const DialogCaption = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 18 / 14,
}));

export const ActionContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '128px 150px',
  gap: 16,
  justifyContent: 'center',
}));

interface DragCellContainerProps {
  count: number;
}

export const DragCellContainer = styled('div')<DragCellContainerProps>(({ theme, count }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${count}, 72px)`,
  gap: 16,
  alignSelf: 'center',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `repeat(${Math.floor(count / 2)}, 72px)`,
  },
}));
