import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NFT_RARITY_TO_MAX_LIVES_MAP,
  NFT_RARITY_TO_MAX_WIN_STREAK,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
} from '~entities/nft';
import { MarketplaceItemShell } from '~entities/marketplace';

import {
  MarketplaceBuyNFTForm,
  MarketplaceCurrencyValue,
  useMarketplaceItemPaymentInfo,
} from '~features/marketplace';

import { MarketplaceItemImage } from '../MarketplaceItemImage';

import { MarketplaceItemNftProps } from './types';

export const MarketplaceItemNft: FC<MarketplaceItemNftProps> = ({ marketplaceNft }) => {
  const { t } = useTranslation();

  const [currency, setCurrency] = useState<MarketplaceCurrencyValue>(
    MarketplaceCurrencyValue.Matic
  );

  const { currentCurrencyItemInfo } = useMarketplaceItemPaymentInfo({
    rarity: marketplaceNft.rarity,
    type: marketplaceNft.type,
    currency,
  });

  const readableRarity = t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[marketplaceNft.rarity]);
  const maxLives = NFT_RARITY_TO_MAX_LIVES_MAP[marketplaceNft.rarity];
  const neededWinStreak = NFT_RARITY_TO_MAX_WIN_STREAK[marketplaceNft.rarity];

  return (
    <MarketplaceItemShell
      icon={<MarketplaceItemImage src={marketplaceNft.imagePath} alt="Card" />}
      name={`${readableRarity} card`}
      params={[
        { label: `${t('Marketplace.lives')} `, value: `${maxLives}/${maxLives}` },
        { label: `${t('Marketplace.winStreak')} `, value: neededWinStreak },
        {
          label: `${t('Marketplace.winReward')} `,
          value: `${marketplaceNft.rewardTokensAmount} MCN`,
        },
        { label: `${t('Marketplace.freezeTime')} `, value: '-' },
        {
          label: `${t('Marketplace.price')} `,
          value: `${currentCurrencyItemInfo.price} ${currentCurrencyItemInfo.label}`,
        },
      ]}
    >
      <MarketplaceBuyNFTForm
        rarity={marketplaceNft.rarity}
        currency={currency}
        onChangeCurrency={setCurrency}
      />
    </MarketplaceItemShell>
  );
};
