import { FC } from 'react';

import { Dialog } from '~shared/ui';

import { AuthSteps, useAuthModel } from '../model';

import { AuthConstructorProps } from './types';
import { AuthBackdrop, Paper } from './styled';

import {
  Authentication,
  ForgotPassword,
  Identification,
  IntroduceYourself,
  PasswordCreation,
  RecoveryLink,
  VerificationCode,
} from './steps';

const steps = {
  [AuthSteps.Identification]: Identification,

  [AuthSteps.Authentication]: Authentication,

  [AuthSteps.IntroduceYourself]: IntroduceYourself,
  [AuthSteps.CreatePassword]: PasswordCreation,
  [AuthSteps.VerificationCode]: VerificationCode,

  [AuthSteps.ForgotPassword]: ForgotPassword,
  [AuthSteps.RecoveryLink]: RecoveryLink,
};

export const AuthConstructor: FC<AuthConstructorProps> = () => {
  const { step, open } = useAuthModel();
  const Step = steps[step];

  return (
    <Dialog
      open={open}
      slots={{ paper: Paper, backdrop: AuthBackdrop }}
      backdropInitialTransition={false}
    >
      <Step />
    </Dialog>
  );
};
