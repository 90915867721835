import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  gap: '12px',

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    paddingTop: '14px',
    width: '100%',
  },
}));
