import { PartialNft } from '~shared/api';

import { NFT_RARITIES } from '../../lib';

export const getSortedNftsByRarity = (nfts: Array<PartialNft>): Array<PartialNft> => {
  const sortedNftsMatrix: Array<Array<PartialNft>> = [...NFT_RARITIES.map(() => [])];

  nfts.forEach((nft) => {
    if (sortedNftsMatrix[nft.rarity]) {
      sortedNftsMatrix[nft.rarity].push(nft);
    }
  });

  const sortedNfts = sortedNftsMatrix.reduce((acc, value) => [...acc, ...value], []);

  return sortedNfts;
};
