import { styled } from '~shared/lib/styles';
import { Box, SelectField } from '~shared/ui';

export const Root = styled(Box, { name: 'DashboardBattles', slot: 'root' })(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr',
  background: theme.palette.grey.A5,
  padding: '20px',
  borderRadius: '8px',

  [theme.breakpoints.down('sm')]: {
    gap: '20px',
  },
}));

export const StyledSelectField = styled(SelectField, {
  name: 'DashboardBattles',
  slot: 'styledSelectField',
})({
  maxHeight: '32px',
  minWidth: '95px',
});
