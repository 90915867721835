import { CSSObject } from '@mui/styled-engine';

import { styled } from '~shared/lib/styles';
import { Avatar, avatarClasses } from '~shared/ui';
import { BattleShellSize, BattleShellVariant } from '~entities/battle';

export const battleTeamClasses = {
  root: 'BattleTeam-root',

  slot: 'BattleTeam-slot',
  slotContainer: 'BattleTeam-slotContainer',
  slotUserAvatar: 'BattleTeam-slotUserAvatar',
  avatarContainer: 'BattleTeam-avatarContainer',
  nameContainer: 'BattleTeam-name-container',

  fromLeft: 'BattleTeam-fromLeft',
  fromRight: 'BattleTeam-fromRight',

  severalPlayers: 'BattleTeam-severalPlayers',

  sm: 'BattleTeam-sm',
  md: 'BattleTeam-md',
  lg: 'BattleTeam-lg',

  line: 'BattleTeam-line',
  square: 'BattleTeam-square',
  compact: 'BattleTeam-compact',
};

/**
 * Root
 */
export const BattleTeamRoot = styled('div', { name: 'BattleTeam' })(() => {
  return {
    display: 'grid',

    [`&.${battleTeamClasses.square}`]: {
      gap: 8,

      [`& .${battleTeamClasses.avatarContainer}`]: {
        flexDirection: 'column',

        ['& > *:nth-child(n+3)']: { marginTop: -12 },
      },

      [`&.${battleTeamClasses.severalPlayers} .${battleTeamClasses.avatarContainer}`]: {
        marginTop: -16,
      },

      [`&.${battleTeamClasses.fromLeft}`]: {
        gridTemplateAreas: "'slot-container avatar-container' 'name-container name-container'",
        gridTemplateColumns: '1fr auto',

        textAlign: 'right',
      },

      [`&.${battleTeamClasses.fromRight}`]: {
        gridTemplateAreas: "'avatar-container slot-container' 'name-container name-container'",
        gridTemplateColumns: 'auto 1fr',

        textAlign: 'left',
      },

      [`&.${battleTeamClasses.sm}`]: {
        [`& .${battleTeamClasses.slot}`]: { width: 40, height: 40 },
      },

      [`&.${battleTeamClasses.md}, &.${battleTeamClasses.lg}`]: {
        [`& .${battleTeamClasses.slot}`]: { width: 48, height: 48 },
      },
    },

    [`&.${battleTeamClasses.line}`]: {
      justifySelf: 'stretch',

      [`& .${battleTeamClasses.avatarContainer}`]: {
        ['& > *:nth-child(n+3)']: {
          marginLeft: -16,
        },
      },

      [`&.${battleTeamClasses.fromLeft}`]: {
        gridTemplateAreas: "'slot-container name-container avatar-container'",
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        textAlign: 'right',

        marginLeft: 8,

        [`&.${battleTeamClasses.sm}, &.${battleTeamClasses.md}`]: {
          [`& .${battleTeamClasses.nameContainer}`]: {
            marginRight: 16,
          },
        },
        [`&.${battleTeamClasses.lg}`]: {
          [`& .${battleTeamClasses.nameContainer}`]: {
            marginRight: 34,
          },
        },
      },

      [`&.${battleTeamClasses.fromRight}`]: {
        gridTemplateAreas: "'avatar-container name-container slot-container'",
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        textAlign: 'left',

        marginRight: 8,

        [`&.${battleTeamClasses.sm}, &.${battleTeamClasses.md}`]: {
          [`& .${battleTeamClasses.nameContainer}`]: {
            marginLeft: 16,
          },
        },
        [`&.${battleTeamClasses.lg}`]: {
          [`& .${battleTeamClasses.nameContainer}`]: {
            marginLeft: 34,
          },
        },
      },
    },

    [`&.${battleTeamClasses.compact}`]: {
      gridTemplateAreas: "'avatar-container name-container slot-container'",
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',

      [`& .${battleTeamClasses.avatarContainer}`]: {
        ['& > *:nth-child(n+3)']: {
          marginLeft: -16,
        },
      },

      [`& .${battleTeamClasses.nameContainer}`]: {
        marginLeft: 10,
      },

      [`& .${battleTeamClasses.slotContainer}`]: {
        flexDirection: 'row-reverse',

        [`& .${battleTeamClasses.slotUserAvatar}`]: {
          marginRight: 8,
          position: 'static',
        },
      },
    },
  };
});

/**
 * Avatar
 */
export const BattleTeamAvatarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  gridArea: 'avatar-container',
});

export const BattleTeamBadgeContainer = styled('div')({
  position: 'absolute',
  inset: '-10px 0 auto 0',
  zIndex: 1,

  display: 'flex',
  justifyContent: 'center',
});

export const BattleTeamAvatar = styled(Avatar)(({ theme }) => ({
  position: 'relative',
  boxShadow: '0px 0px 4px 0px rgba(16, 16, 16, 0.95)',

  [`&.${avatarClasses.hasSource}`]: {
    background: 'white',
  },

  [`& .${avatarClasses.img}`]: {
    objectFit: 'cover',
  },

  ['&:after']: {
    content: '""',
    display: 'block',
    position: 'absolute',
    inset: 0,
    borderRadius: '100%',
    boxShadow: `inset 0 0 0 1.5px ${theme.palette.grey.A30}`,
  },
}));

/**
 * Slot
 */
export const BattleTeamSlotContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  gridArea: 'slot-container',

  position: 'relative',
});

export const BattleTeamSlotUserAvatar = styled(Avatar)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  position: 'absolute',
  bottom: 0,
}));

export const BattleTeamSlot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

/**
 * Name
 */
interface BattleTeamNameContainerProps {
  $size: BattleShellSize;
  $variant: BattleShellVariant;
}

export const BattleTeamNameContainer = styled('div')<BattleTeamNameContainerProps>(({
  $size,
  $variant,
}) => {
  const variantStyles: Record<BattleShellVariant, Record<BattleShellSize, CSSObject>> = {
    square: {
      sm: { fontSize: 12, lineHeight: 14 / 12, maxHeight: 14 * 3 },
      md: { fontSize: 13, lineHeight: 14 / 13, maxHeight: 14 * 3 },
      lg: { fontSize: 14, lineHeight: 14 / 14, maxHeight: 14 * 3 },
    },

    line: {
      sm: { fontSize: 13, lineHeight: 14 / 12 },
      md: { fontSize: 14, lineHeight: 14 / 13 },
      lg: { fontSize: 15, lineHeight: 19 / 14 },
    },

    compact: {
      sm: { fontSize: 13, lineHeight: 14 / 13 },
      md: { fontSize: 13, lineHeight: 14 / 13 },
      lg: { fontSize: 13, lineHeight: 14 / 13 },
    },
  };

  return {
    gridArea: 'name-container',

    ...variantStyles[$variant][$size],
  };
});

export const BattleTeamName = styled('div')(() => ({}));
