import { Fragment, SyntheticEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, Menu, MenuItem } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';
import { useOnboardingModel } from '~features/onboarding';

export const Help = () => {
  const { t } = useTranslation();
  const helpMenu = useSwitcher(false);
  const helpRef = useRef<HTMLButtonElement>(null);

  const { onOpen: openOnboarding } = useOnboardingModel();

  const handleMenuItemClick = useCallback(
    (callback?: Function) => (event: SyntheticEvent) => {
      helpMenu.switchOff();

      if (callback) {
        return callback(event);
      }
    },
    [helpMenu]
  );

  return (
    <Fragment>
      <ButtonIcon
        name="question"
        variant="plain"
        size={24}
        onClick={helpMenu.switchOn}
        ref={helpRef}
      />

      <Menu
        anchorEl={helpRef.current}
        open={helpMenu.value}
        onClose={helpMenu.switchOff}
        slotProps={menuSlotProps}
      >
        <MenuItem onClick={handleMenuItemClick(() => openOnboarding('1'))}>
          {t('Other.showOnBoarding')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const menuSlotProps = { root: { modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] } };
