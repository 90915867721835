import { useEffect, useState } from 'react';

export const useCountdown = (targetDate: Date) => {
  const countDownDateTs = targetDate.getTime();

  const [countDown, setCountDown] = useState(() => countDownDateTs - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDateTs - new Date().getTime());
    }, 1000);

    return () => {
      return clearInterval(interval);
    };
  }, [countDownDateTs]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDownTs: number) => {
  // calculate time left
  const days = Math.floor(countDownTs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDownTs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  const minutes = Math.floor((countDownTs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDownTs % (1000 * 60)) / 1000);

  const isExpired = days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0;

  return { days, hours, minutes, seconds, isExpired };
};
