import { styled } from '~shared/lib/styles';

export const TextAreaContactUs = styled('textarea')({
  maxWidth: '400px',
  width: '100%',
  height: '143px',
  borderRadius: '8px',
  background: '#282828',
  border: '1.5px solid #282828',
  resize: 'none',
  color: '#EFEFEF',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '1',
  padding: '10px',
  marginTop: '24px',
});
