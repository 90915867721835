import { FC } from 'react';

import { Stack, Typography } from '~shared/ui';

import { NftCardInfoContentEntryProps } from './types';

export const NftCardInfoContentEntry: FC<NftCardInfoContentEntryProps> = ({ label, value }) => {
  const valueType = typeof value;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography>{label}</Typography>
      {valueType === 'string' || valueType === 'number' ? (
        <Typography fontWeight={500}>{value}</Typography>
      ) : (
        value
      )}
    </Stack>
  );
};
