import { styled } from '~shared/lib/styles';

export const UIKitFormLabel = styled('label')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,

  marginBottom: 4,
  color: theme.palette.text.secondary,
  display: 'block',
}));
