import { styled } from '~shared/lib/styles';

import { iconClasses } from '../../dataDisplay';

export const UIKitSelectOptionRoot = styled('li')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  height: 44,
  paddingLeft: 10,
  paddingRight: 10,

  fontSize: 14,
  fontWeight: 400,
  lineHeight: 22 / 14,

  listStyle: 'none',
  userSelect: 'none',
  transition: theme.transitions.create('background'),

  ['&[aria-selected=true]']: {
    background: theme.palette.grey.A5,
  },

  ['&[aria-disabled=true], &:disabled']: {
    cursor: 'not-allowed',
    color: theme.palette.grey.A30,
  },

  ['&:hover:not([aria-disabled=true])']: {
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },

  [`& .${iconClasses.root}`]: {
    marginLeft: 5,
  },
}));

export const UIKitOptionContent = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
