import { apiInstance } from '../apiInstance';

import { NotificationsRoutes } from './routes';
import {
  ApiGetNotificationToken,
  ApiGetNotificationTokenResponseData,
  ApiGetNotificationsList,
  ApiGetNotificationsListResponseData,
} from './types';

export const getNotificationToken: ApiGetNotificationToken = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<ApiGetNotificationTokenResponseData>(NotificationsRoutes.signin(), {
        baseURL: process.env.REACT_APP_BACKEND_NOTIFICATIONS_API,
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const getNotificationsList: ApiGetNotificationsList = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<ApiGetNotificationsListResponseData>(NotificationsRoutes.list())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
