import { FC } from 'react';
import { motion } from 'framer-motion';

import { Box } from '~shared/ui';

import { SportsMenuSidebarSlideProps } from './types';

export const SportsMenuSidebarSlide: FC<SportsMenuSidebarSlideProps> = ({
  children,
  direction = 'horizontal',
  ...props
}) => {
  enum AnimationVariant {
    Visible = 'visible',
    Hidden = 'hidden',
  }

  const transition = { duration: 0.2, type: 'tween' };

  const verticalVariants = {
    [AnimationVariant.Hidden]: { opacity: 0, y: 10, transition },
    [AnimationVariant.Visible]: { opacity: 1, y: 0, transition },
  };

  const horizontalVariants = {
    [AnimationVariant.Hidden]: { opacity: 0, x: -10, transition },
    [AnimationVariant.Visible]: { opacity: 1, x: 0, transition },
  };

  return (
    <Box
      component={motion.div}
      variants={direction === 'horizontal' ? horizontalVariants : verticalVariants}
      animate={AnimationVariant.Visible}
      initial={AnimationVariant.Hidden}
      exit={AnimationVariant.Hidden}
      {...props}
    >
      {children}
    </Box>
  );
};
