import { motion } from 'framer-motion';
import { Menu as MuiBaseMenu } from '@mui/base';
import { Popper as MuiBasePopper } from '@mui/base';

import { noScrollbar, styled } from '~shared/lib/styles';

export const UIKitMenu = styled(MuiBaseMenu)({});

export const UIKitMenuListbox = styled(motion.ul)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 100,
  overflow: 'auto',

  boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 1)',
  background: theme.palette.paper.semi,
  padding: '4px 0',
  borderRadius: 16,
  userSelect: 'none',

  ...noScrollbar,
}));

export const UIKitMenuPopper = styled(MuiBasePopper)({
  zIndex: 10,
});
