import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '~shared/ui';

import { useEventModel, useTranslationLabelMap } from '~entities/event';

import { EventDialogStatisticsRow } from './EventDialogStatisticsRow';

import { EventDialogStatisticsProps } from './types';

export const EventDialogStatistics: FC<EventDialogStatisticsProps> = () => {
  const { t } = useTranslation();

  const STATISTIC_SCORE_KEY_TO_LABEL_MAP = useTranslationLabelMap();
  const { eventStatisticsEntries } = useEventModel();

  if (eventStatisticsEntries.length === 0) {
    return null;
  }

  return (
    <Stack spacing={20 / 8} pb={4}>
      <Typography align="center" lineHeight={21 / 13} fontSize={13} fontWeight={600}>
        {t('Tabs.main')}
      </Typography>

      {eventStatisticsEntries.map(([key, scores]) => (
        <EventDialogStatisticsRow
          key={key}
          leftColumnScore={scores.homeTeam}
          rightColumnScore={scores.awayTeam}
          label={STATISTIC_SCORE_KEY_TO_LABEL_MAP[key]}
        />
      ))}
    </Stack>
  );
};
