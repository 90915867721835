import { BigNumber, ethers } from 'ethers';

import { AuctionForMCN__factory, AuctionForMatic__factory } from '~shared/contracts';

const mergeAuctionCardData = (cardIds: Array<BigNumber>, untils: Array<BigNumber>) =>
  cardIds.map((cardId, index) => ({
    cardId: BigNumber.from(cardId).toString(),
    betsAcceptedUntil: BigNumber.from(untils[index]).toString(),
  }));

export const getViewerAuctionCards = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  // prettier-ignore
  const auctionMaticContract = AuctionForMatic__factory.connect(process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MATIC, provider);
  // prettier-ignore
  const auctionMCNContract = AuctionForMCN__factory.connect(process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN, provider);

  const auctionCards = await Promise.all([
    auctionMaticContract.auctionsForUser(address, BigNumber.from(0)),
    auctionMCNContract.auctionsForUser(address, BigNumber.from(0)),
  ]).then(([maticData, mcnData]) => {
    const { cardIds: maticCardIds, untils: maticCardUntils } = maticData;
    const { cardIds: mcnCardIds, untils: mcnCardUntils } = mcnData;

    const maticAuctionCards = mergeAuctionCardData(maticCardIds, maticCardUntils);
    const mcnAuctionCards = mergeAuctionCardData(mcnCardIds, mcnCardUntils);

    return [...maticAuctionCards, ...mcnAuctionCards]
      .filter(({ cardId, betsAcceptedUntil }) => cardId !== '0' && betsAcceptedUntil !== '0')
      .map((auctionCard) => ({
        ...auctionCard,
        betsAcceptedUntil: new Date(Number(auctionCard.betsAcceptedUntil) * 1000),
      }));
  });

  return auctionCards;
};
