import { FC } from 'react';

import { CircularProgressProps } from './types';
import {
  UIKitCircularProgressCircleProgress,
  UIKitCircularProgressCircleTrack,
  UIKitCircularProgressRoot,
  UIKitCircularProgressSVG,
} from './styled';

export const CircularProgress: FC<CircularProgressProps> = ({ progress = 35, size = 24 }) => {
  return (
    <UIKitCircularProgressRoot size={size}>
      <UIKitCircularProgressSVG>
        <UIKitCircularProgressCircleTrack />
        <UIKitCircularProgressCircleProgress progress={progress} />
      </UIKitCircularProgressSVG>
    </UIKitCircularProgressRoot>
  );
};
