import { styled } from '~shared/lib/styles';

import { Select, selectClasses } from '../Select';

export const UIKitToggleMenuSelect = styled(Select, { name: 'ToggleMenuSelect', slot: 'root' })(
  ({ theme }) => ({
    [`& .${selectClasses.chevron}`]: {
      marginLeft: 4,
      width: 24,
      height: 24,
      color: theme.palette.grey.A50,
    },
  })
);
