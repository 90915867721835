import { Fragment } from 'react';
import { ThemeProvider } from '@mui/system';

import { globalStyles, theme } from '~shared/lib/styles';

export const withTheme = (children: Function) => () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        {globalStyles}
        {children()}
      </Fragment>
    </ThemeProvider>
  );
};
