import { Container, Stack } from '~shared/ui';

import { LeaderboardList } from '~widgets/leaderboard-list';
import { Announcements } from '~widgets/announcements';

export const RankingsPage = () => {
  return (
    <Stack spacing={12 / 8} flex={1}>
      <Announcements />

      <Container flex={1} display="flex" flexDirection="column" position="relative">
        <LeaderboardList />
      </Container>
    </Stack>
  );
};
