import { useTranslation } from 'react-i18next';

import { NftCard } from '~entities/nft';

import { Stack, Typography } from '~shared/ui';

import { useFixCardDialogModel } from '../../model';

import { StyledDialog } from './styled';
import { FixCardDialogForm } from './FixCardDialogForm';

export const FixCardDialog = () => {
  const { t } = useTranslation();

  const { open, nft, fixPrice, handleClose, handleCurrencyChange, handleFixCard } =
    useFixCardDialogModel();

  const isPriceNotCountedYet = fixPrice.mcn === 0 || fixPrice.matic === 0;

  if (!nft || !open || isPriceNotCountedYet) {
    return null;
  }

  return (
    <StyledDialog open={open} width={440} onClose={handleClose}>
      <Stack spacing={24 / 8} alignItems="center">
        <Typography variant="h1">{t('Other.fixHead')}</Typography>
        <NftCard nft={nft} isHighlighted highlightVariant="small" />
        <Typography color="secondary" textAlign="center">
          {t('Other.fixText')}
        </Typography>
        <FixCardDialogForm
          price={fixPrice}
          onFixCard={handleFixCard}
          onCurrencyChange={handleCurrencyChange}
        />
      </Stack>
    </StyledDialog>
  );
};
