import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~shared/ui';

import { MarketplaceItemShell } from '~entities/marketplace';

import { MarketplaceItemImage } from '../MarketplaceItemImage';

import { MarketplaceItemUnfreezeProps } from './types';

export const MarketplaceItemUnfreeze: FC<MarketplaceItemUnfreezeProps> = ({
  marketplaceUnfreeze,
}) => {
  const { t } = useTranslation();

  return (
    <MarketplaceItemShell
      name="Unfreeze"
      description={<Fragment>{t('Marketplace.TextUnfreeze')}</Fragment>}
      icon={<MarketplaceItemImage src={marketplaceUnfreeze.imagePath} alt="Unfreeze" />}
    >
      <Button disabled>Buy</Button>
    </MarketplaceItemShell>
  );
};
