import { FC } from 'react';

import { Icon } from '~shared/ui';

import { ToggleButtonIconProps } from './types';
import { UIKitToggleButtonIcon } from './styled';

export const ToggleButtonIcon: FC<ToggleButtonIconProps> = ({ name, size, ...props }) => {
  return (
    <UIKitToggleButtonIcon {...props}>
      <Icon name={name} size={size} />
    </UIKitToggleButtonIcon>
  );
};
