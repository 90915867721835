import { FC } from 'react';

import { Avatar, Icon } from '~shared/ui';

import { useViewerModel } from '~entities/viewer';

import { ParticipantName, ParticipantPlace, ParticipantRoot } from './styled';
import { TournamentParticipantProps } from './types';

export const TournamentParticipant: FC<TournamentParticipantProps> = ({ name, place, avatar }) => {
  const { defaultAvatars } = useViewerModel();

  return (
    <ParticipantRoot spacing={10 / 8} direction="row">
      <ParticipantPlace>{place}</ParticipantPlace>
      {/* todo: remove mock after backend integration */}
      <Avatar src={avatar ?? defaultAvatars?.[Math.floor(Math.random() * 5)].src ?? ''} size={24} />
      <ParticipantName>{name}</ParticipantName>
      {place === 1 && <Icon name="crown" />}
    </ParticipantRoot>
  );
};
