import { FC } from 'react';

import { Icon, Stack, Typography } from '~shared/ui';

import { WalletCardProps } from './types';
import { Root } from './styled';

export const WalletCard: FC<WalletCardProps> = ({ icon, name, onClick }) => {
  return (
    <Root onClick={onClick}>
      <Stack direction="row" alignItems="center" spacing={18 / 8}>
        <Icon name={icon} size={48} />
        <Typography variant="h3">{name}</Typography>
      </Stack>
      <Icon name="chevron-right" size={20} color={(theme) => theme.palette.grey.A50} />
    </Root>
  );
};
