import { Button as MuiBaseButtonProps } from '@mui/base';

import { styled } from '~shared/lib/styles';

import { Box } from '../../../layout';

export const verticalPanelActionClasses = {
  selected: 'VerticalPanelAction-selected',
  disabled: 'VerticalPanelAction-disabled',

  arrow: 'VerticalPanelAction-arrow',
  icon: 'VerticalPanelAction-icon',
  content: 'VerticalPanelAction-content',
  count: 'VerticalPanelAction-count',
};

export const UIKItVerticalPanelAction = styled(MuiBaseButtonProps, { name: 'VerticalPanelAction' })(
  ({ theme }) => ({
    border: 'none',
    background: 'none',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color'),

    display: 'flex',
    alignItems: 'center',

    [`&:hover, &.${verticalPanelActionClasses.selected}`]: {
      color: theme.palette.text.primary,

      [`& .${verticalPanelActionClasses.arrow}`]: {
        color: theme.palette.text.primary,
      },
    },
  })
);

export const UIKItVerticalPanelActionIcon = styled(Box, {
  name: 'VerticalPanelAction-icon',
})({
  display: 'flex',
  marginRight: 8,
});

export const UIKItVerticalPanelActionContent = styled(Box, {
  name: 'VerticalPanelAction-content',
})({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1,
  textAlign: 'start',

  flex: 1,
});

export const UIKItVerticalPanelActionCount = styled(Box, { name: 'VerticalPanelAction-count' })(
  () => ({
    marginRight: 8,
    marginLeft: 4,
  })
);

export const UIKItVerticalPanelActionArrow = styled(Box, {
  name: 'VerticalPanelAction-arrow',
})(({ theme }) => ({
  display: 'flex',
  color: theme.palette.grey.A30,
  transition: theme.transitions.create('color'),
}));
