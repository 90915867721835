import { BigNumber, ethers } from 'ethers';

import { Multicall__factory } from '~shared/contracts';
import { Nft } from '~shared/api';

import {
  GetNftInfoFromChainParams,
  NFT_RARITY_TO_READABLE_RARITY_NAME_MAP,
  getNftInfoMulticallRequests,
} from '~entities/nft';

export const fetchNftInfoFromChain = async ({
  nft,
  provider,
}: GetNftInfoFromChainParams): Promise<Nft> => {
  const multicallRequests = await getNftInfoMulticallRequests({ tokenId: nft.token_id, provider });

  const multiCallContract = Multicall__factory.connect(
    process.env.REACT_APP_ADDRESS_MULTICALL,
    provider
  );

  const multiCallContractAttached = multiCallContract.attach(
    process.env.REACT_APP_ADDRESS_MULTICALL
  );

  const result = await multiCallContractAttached.callStatic.multicall(multicallRequests);

  let counter = 0;

  const winStreak = Number(BigNumber.from(result[counter++]));
  const rarity = Number(BigNumber.from(result[counter++]));
  const livesRemaining = Number(BigNumber.from(result[counter++]));
  const rewardForCorrectVote = Number(ethers.utils.formatEther(BigNumber.from(result[counter++])));

  const instantRestorationCost = Number(
    ethers.utils.formatEther(BigNumber.from(result[counter++]))
  );

  const availableAmountOfRecoveries = Number(BigNumber.from(result[counter++]));
  const readableRarity = NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[rarity];

  const nftWithAdditionalProperties = {
    ...nft,
    winStreak,
    rarity,
    rewardForCorrectVote,
    instantRestorationCost,
    availableAmountOfRecoveries,
    readableRarity,
    livesRemaining,
  };

  return nftWithAdditionalProperties;
};
