import { styled } from '~shared/lib/styles';

export const NftMintingRoot = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.grey.B50,
}));

export const NftMintingLabel = styled('div')(() => ({
  textAlign: 'center',
  fontSize: 12,
  lineHeight: 16 / 12,
  fontWeight: 600,
}));
