import { styled } from '~shared/lib/styles';

export const WalletFinanceCardRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 12,
  background: theme.palette.grey.A5,
  padding: '18px 20px',
  borderRadius: 8,
}));

export const WalletFinanceCardRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const WalletFinanceCardRowLabel = styled('div')(({ theme }) => ({
  color: theme.palette.grey.A75,
  fontSize: 13,
}));

interface WalletFinanceCardRowCommonProps {
  empty: boolean;
}

export const WalletFinanceCardRowIncome = styled('div')<WalletFinanceCardRowCommonProps>(
  ({ theme, empty }) => ({
    color: empty ? theme.palette.grey.A50 : theme.palette.primary.light,
    fontWeight: 600,
  })
);

export const WalletFinanceCardRowOutcome = styled('div')<WalletFinanceCardRowCommonProps>(
  ({ theme, empty }) => ({
    color: empty ? theme.palette.grey.A50 : theme.palette.error.main,
    fontWeight: 600,
  })
);

export const WalletFinanceCardTotal = styled(WalletFinanceCardRow)(() => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 18 / 16,
  letterSpacing: -0.2,
}));

export const WalletFinanceCardTotalValue = styled('div')(() => ({
  lineHeight: 30 / 20,
  fontSize: 20,
}));
