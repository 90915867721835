import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSwitcher } from '~shared/lib/hooks';
import { Icon, IconAssetNamesType, Menu, MenuItem, Typography } from '~shared/ui';
import { Language } from '~shared/config/i18n';

import { SwitchLanguageButton } from './styled';

export const SwitchLanguage = () => {
  const open = useSwitcher(false);
  const buttonRef = useRef(null);

  const { i18n } = useTranslation();

  const handleChangeLanguageCurry = (language: Language) => () => {
    open.toggle();
    i18n.changeLanguage(language);
  };

  const iconName = options.find((option) => option.value === i18n.language)!.icon;

  return (
    <Fragment>
      <SwitchLanguageButton
        name={iconName}
        onClick={open.toggle}
        $open={open.value}
        variant="plain"
        size={22}
        ref={buttonRef}
      />

      <Menu
        open={open.value}
        onClose={open.switchOff}
        anchorEl={buttonRef.current}
        slotProps={{ root: { placement: 'bottom-end' } }}
      >
        {options.map((option) => (
          <MenuItem
            onClick={handleChangeLanguageCurry(option.value)}
            key={option.value}
            selected={i18n.language === option.value}
          >
            <Icon name={option.icon} marginRight={1} />
            <Typography color="secondary">{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

const options: Array<{ value: Language; label: string; icon: IconAssetNamesType }> = [
  { value: Language.EN, label: 'English', icon: 'flag-uk' },
  { value: Language.ES, label: 'Espanol', icon: 'flag-sp' },
];
