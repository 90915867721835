import { customScrollbar, styled } from '~shared/lib/styles';

export const EventsContainer = styled('div', {
  name: 'NftCardDetailedInfoEvents',
  slot: 'eventsContainer',
})(({ theme }) => ({
  ...customScrollbar,

  flex: 1,
  maxHeight: 400,
  overflowY: 'auto',
  marginRight: -10,
  paddingRight: '10px',
  marginTop: 24,

  [theme.breakpoints.down('mobile')]: {
    maxHeight: 'unset',
  },
}));
