import { ReactNode, createElement } from 'react';

import { ApiGetCallsMappedData, ApiGetCallsResponseData } from '~shared/api';

import { useBattleAutoStyle } from '~entities/battle';

import { BattleCall } from '../ui';

import { isEventCurrent, isEventPassed } from './lib';

interface UseCallListProps {
  list?: ApiGetCallsResponseData;
}

interface UseCallList {
  (params: UseCallListProps): {
    list: Array<ReactNode>;
    passedList: Array<ReactNode>;
    currentList: Array<ReactNode>;
  };
}

export const useCallList: UseCallList = ({ list = [] }) => {
  const style = useBattleAutoStyle('line');

  const renderCall = (call: ApiGetCallsMappedData) => {
    return createElement(BattleCall, { ...call, key: call.callId, ...style });
  };

  return {
    list: list.map(renderCall),
    passedList: list.filter((call) => isEventPassed(call.result)).map(renderCall),
    currentList: list.filter((call) => isEventCurrent(call.result)).map(renderCall),
  };
};
