import { styled } from '~shared/lib/styles';
import { iconClasses } from '~shared/ui';

export const notificationTransactionStatusClasses = {
  root: 'NotificationTransactionStatus-root',

  success: 'NotificationTransactionStatus-success',
  primary: 'NotificationTransactionStatus-primary',
  error: 'NotificationTransactionStatus-error',
};

export const NotificationTransactionStatusRoot = styled('div')(({ theme }) => ({
  fontSize: 10,
  fontWeight: 600,
  lineHeight: 16 / 10,
  display: 'flex',
  alignItems: 'center',

  [`& .${iconClasses.root}`]: {
    marginRight: 4,
  },

  [`&.${notificationTransactionStatusClasses.success}`]: {
    color: theme.palette.success.main,
  },

  [`&.${notificationTransactionStatusClasses.primary}`]: {
    color: theme.palette.primary.light,
  },

  [`&.${notificationTransactionStatusClasses.error}`]: {
    color: theme.palette.error.main,
  },
}));
