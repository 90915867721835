import { styled } from '~shared/lib/styles';

export const SnackbarsContainer = styled('div')(({ theme }) => ({
  pointerEvents: 'none',
  position: 'fixed',
  inset: 10,
  zIndex: theme.zIndex.notification,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',

  ['& > *']: {
    pointerEvents: 'auto',
    maxWidth: 360,

    ['&:not(:last-child)']: {
      marginBottom: 12,
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));
