import { ApiCommonSport, ApiGetBattlesMappedData } from '~shared/api';

import { useBattlesListQuery } from './useBattlesListQuery';

export const useMultipleTypeBattlesListQuery = (sports: ApiCommonSport[]) => {
  const { list, isLoading, isEmptyList } = useBattlesListQuery({ sport: sports });

  const lists: Partial<Record<ApiCommonSport, Array<ApiGetBattlesMappedData>>> = {};

  list.forEach((battle) => {
    if (battle.sport in lists) {
      lists[battle.sport]!.push(battle);
    } else {
      lists[battle.sport] = [battle];
    }
  });

  return {
    list,
    lists,

    isLoading,
    isEmptyList,
  };
};
