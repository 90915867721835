import { FC, Fragment, PropsWithChildren } from 'react';

import { Button } from '~shared/ui';

import { useSellCardModel } from '../model';

import { SellCardProps } from './types';

export const SellCard: FC<PropsWithChildren<SellCardProps>> = ({
  nft,
  children,
  ...buttonProps
}) => {
  const { handleSellCard } = useSellCardModel(nft);

  return (
    <Fragment>
      <Button {...buttonProps} onClick={handleSellCard}>
        {children}
      </Button>
    </Fragment>
  );
};
