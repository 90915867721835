import { FC } from 'react';
import { clsx } from 'clsx';

import { UIKitHeadingTabsRoot, headingTabsClasses } from './styled';

import { HeadingTabsProps } from './types';

export const HeadingTabs: FC<HeadingTabsProps> = ({
  size = 'responsive',
  className,
  gutters,
  ...props
}) => {
  return (
    <UIKitHeadingTabsRoot
      className={clsx(
        headingTabsClasses.root,
        {
          [headingTabsClasses.sm]: size === 'sm',
          [headingTabsClasses.md]: size === 'md',
          [headingTabsClasses.lg]: size === 'lg',
          [headingTabsClasses.responsive]: size === 'responsive',
          [headingTabsClasses.gutters]: gutters,
        },
        className
      )}
      {...props}
    />
  );
};
