import { FC } from 'react';

import { Paper } from '~shared/ui';

import { PasswordRecovery } from '~widgets/password-recovery';

export const PasswordRecoveryPage: FC = () => {
  return (
    <Paper maxWidth={380} width={1}>
      <PasswordRecovery />
    </Paper>
  );
};
