import { useTranslation } from 'react-i18next';

import { CircularProgress } from '~shared/ui';

import { EventCardsWalletInitializationLabel, EventCardsWalletInitializationRoot } from './styled';

export const EventCardsWalletInitialization = () => {
  const { t } = useTranslation();

  return (
    <EventCardsWalletInitializationRoot>
      <CircularProgress />
      <EventCardsWalletInitializationLabel>
        {t('Other.waitingForWalletInitialization')}
      </EventCardsWalletInitializationLabel>
    </EventCardsWalletInitializationRoot>
  );
};
