import { NavLink } from 'react-router-dom';

import { styled } from '~shared/lib/styles';

export const navLinkClasses = {
  underline: 'Link-underline',
};

export const UIKitNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.grey.A50,
  textDecoration: 'none',

  ['&:visited']: {
    color: 'initial',
  },

  ['&:hover']: {
    textDecoration: 'none',

    [`&.${navLinkClasses.underline}`]: {
      textDecoration: 'underline',
    },
  },
}));
