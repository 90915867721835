import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, DialogTitle, Divider, Stack } from '~shared/ui';
import { htmlDefaultLayoutBottomAnchorId } from '~shared/config';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCardsStack, StaticNftCard } from '~entities/nft';

import { SportToLabelMap, getBadgeType } from '../../lib';

import { TournamentBadge } from '../TournamentBadge';
import { TournamentPrizes } from '../TournamentPrizes';

import { TournamentPlayers } from './TournamentPlayers';

import {
  TournamentDescriptionCaption,
  TournamentDescriptionContainer,
  TournamentDialogRoot,
  TournamentMobileDialogRoot,
} from './styled';

import { TournamentDialogProps } from './types';

export const TournamentDialog: FC<TournamentDialogProps> = ({
  startDate,
  endDate,

  name,

  sport,

  cards,

  prizes,
  players,

  ...dialogProps
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const DialogSlot = isMobile ? TournamentMobileDialogRoot : TournamentDialogRoot;

  return (
    <DialogSlot
      $sport={sport}
      width={720}
      disableTransition={isMobile}
      hideBackdrop={isMobile}
      container={isMobile ? document.getElementById(htmlDefaultLayoutBottomAnchorId) : undefined}
      slots={isMobile ? { closeButton: <Fragment /> } : {}}
      {...dialogProps}
    >
      <Stack spacing={24 / 8} direction="row" alignItems="center">
        {isMobile && (
          <ButtonIcon
            name="arrow-left"
            variant="plain"
            size={24}
            onClick={dialogProps.onClose as any}
          />
        )}

        <Stack direction="row" justifyContent="space-between" alignSelf="stretch" flex={1}>
          <TournamentBadge type="date" startDate={startDate} endDate={endDate} />
          <TournamentBadge type={getBadgeType(startDate, endDate)} />
        </Stack>
      </Stack>

      <TournamentDescriptionContainer>
        <Stack spacing={24 / 8}>
          <Stack spacing={6 / 8}>
            <DialogTitle align="left" mb={0}>
              {name}
            </DialogTitle>

            <Stack spacing={6 / 8} direction="row">
              <TournamentDescriptionCaption>
                {t(SportToLabelMap[sport])}
              </TournamentDescriptionCaption>
              <Divider orientation="vertical" flexItem />
              <TournamentDescriptionCaption>Rare weekly 6 cards</TournamentDescriptionCaption>
            </Stack>
          </Stack>

          <Stack spacing={32 / 8} direction="row" alignItems="center">
            <NftCardsStack>
              {cards.map((tokenId) => (
                <StaticNftCard key={tokenId} tokenId={tokenId} />
              ))}
            </NftCardsStack>

            <TournamentPrizes prizes={prizes} />
          </Stack>
        </Stack>
      </TournamentDescriptionContainer>

      <Divider my={20 / 8} />

      <TournamentPlayers players={players} playersLimit={50} />
    </DialogSlot>
  );
};
