import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';
import { Nft } from '~shared/api';
import { routes } from '~shared/config';

import {
  getCallSameRarityMessage,
  isNftAvailableForMerge,
  useNftCardModel,
  useNftPreviewInfo,
} from '~entities/nft';
import { useEventModel } from '~entities/event';

import { useMergeModel } from '~features/nft';

import { NftTabRarity, userCardsSlice } from '../model';

import { useUserCardsSelector } from './selectors';
import { useSelectCard } from './hooks';

export const useUserCardsModel = () => {
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedCards, rarityTab } = useUserCardsSelector();

  const { open: isNftPreviewOpen, openPreview, closePreview } = useNftPreviewInfo();

  const { openMergeDialog, handleAddNftToMerge } = useMergeModel();
  const { isSelectionMode, selectionModeType, nfts, setSelectionMode, nftsToMerge } =
    useNftCardModel();

  const { callAcceptableRarity, cards, isCall, isMakeCall, openActiveEvent, isViewMode } =
    useEventModel();

  const { selectCard } = useSelectCard();

  const onRarityTabChange = useCallback(
    (_: SyntheticEvent | null, rarity: number | string | boolean | null) => {
      dispatch(userCardsSlice.actions.selectRarityTab(Number(rarity)));
      closePreview();
    },
    [dispatch, closePreview]
  );

  const isAvailableToSelect = (nft: Nft) => {
    if (selectedCards.has(nft.token_id)) {
      return true;
    }

    if (selectionModeType === 'bet') {
      if (isCall || isMakeCall) {
        if (selectedCards.size < 1) {
          if (isCall) {
            if (callAcceptableRarity !== null) {
              return callAcceptableRarity === nft.rarity;
            }
          }

          return true;
        }

        return false;
      }

      return true;
    }

    if (selectionModeType === 'merge') {
      if (selectedCards.has(nft?.token_id)) {
        return true;
      }

      const nftsToMergeQuantity = nftsToMerge.filter((nft) => nft !== null).length;

      switch (nftsToMergeQuantity) {
        case 0:
          return selectedCards.size < 2;
        case 1:
          return selectedCards.size < 1;
        default:
          return false;
      }
    }
  };

  const onNftClick = (nft: Nft, isMobile?: boolean) => () => {
    if (isSelectionMode) {
      if (!isAvailableToSelect(nft)) {
        if (isCall) {
          openSnackbar({
            message: getCallSameRarityMessage(callAcceptableRarity!, t),
          });
        }

        return;
      }

      return selectCard(nft);
    }

    if (isNftPreviewOpen) {
      closePreview();

      return;
    }

    if (isMobile) {
      // todo: make an anchor tag
      navigate(routes.card.replace(':tokenId', nft.token_id));
    } else {
      openPreview(nft);
    }
  };

  const clearSelectedCards = useCallback(() => {
    dispatch(userCardsSlice.actions.clearSelectedCards());
  }, [dispatch]);

  const confirmSelectedCards = useCallback(() => {
    switch (selectionModeType) {
      case 'bet':
        navigate(routes.index);

        const payload = isViewMode
          ? { additionalCards: Array.from(selectedCards.values()) }
          : { cards: [...cards, ...Array.from(selectedCards.values())] };

        openActiveEvent(payload);
        clearSelectedCards();
        setSelectionMode(false);

        break;

      case 'merge':
        selectedCards.forEach((selectedCard) => {
          handleAddNftToMerge([selectedCard.token_id]);
        });

        openMergeDialog();
        clearSelectedCards();
        setSelectionMode(false);

        break;
    }
  }, [
    cards,
    clearSelectedCards,
    handleAddNftToMerge,
    isViewMode,
    navigate,
    openActiveEvent,
    openMergeDialog,
    selectedCards,
    selectionModeType,
    setSelectionMode,
  ]);

  const cancelSelectCardToMerge = useCallback(() => {
    clearSelectedCards();
    openMergeDialog();
  }, [clearSelectedCards, openMergeDialog]);

  const offSelectionMode = useCallback(() => {
    setSelectionMode(false);
  }, [setSelectionMode]);

  const isCardSelected = useCallback(
    (card: Nft) => {
      return selectedCards.has(card.token_id);
    },
    [selectedCards]
  );

  const filteredNfts = useMemo(() => {
    let filteredNfts = nfts;

    if (isSelectionMode) {
      filteredNfts = filteredNfts.filter((nft) => {
        const isPlacedToEvent = !!cards.find((card) => card.token_id === nft.token_id);

        return !isPlacedToEvent;
      });
    }

    if (selectionModeType === 'merge') {
      filteredNfts = filteredNfts.filter((nft) => isNftAvailableForMerge(nft).isAvailable);
    }

    if (rarityTab !== NftTabRarity.All) {
      filteredNfts = filteredNfts.filter((nft) => nft.rarity === Number(rarityTab - 1));
    }

    return filteredNfts;
  }, [nfts, isSelectionMode, selectionModeType, rarityTab, cards]);

  const isConfirmForMergeAvailable = useMemo(() => {
    if (selectionModeType === 'merge') {
      const nftsToMergeQuantity = nftsToMerge.filter((nft) => nft !== null).length;

      if (nftsToMergeQuantity === 0) {
        return selectedCards.size === 2;
      }

      if (nftsToMergeQuantity === 1) {
        return selectedCards.size === 1;
      }
    }
  }, [nftsToMerge, selectedCards.size, selectionModeType]);

  return {
    isSelectionMode,
    selectionModeType,
    selectedCards,
    selectedCardsAmount: selectedCards.size,
    confirmSelectedCards,
    cancelSelectCardToMerge,
    offSelectionMode,
    clearSelectedCards,
    nfts: filteredNfts,

    onNftClick,

    rarityTab,
    onRarityTabChange,

    isCardSelected,
    isConfirmAvailable: true,
    isConfirmForMergeAvailable,
  };
};
