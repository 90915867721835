import { ReactElement, forwardRef } from 'react';

import { ApiCommonSportEnum } from '~shared/api';

import { BattleShellVariant } from '../types';

import { BattleShellContext } from '../BattleShellContext';

import { BattleShellCompact, BattleShellLine, BattleShellSquare } from './variants';

import { BattleShellFactoryProps } from './types';

export const BattleShellFactory = forwardRef<HTMLDivElement, BattleShellFactoryProps>(
  ({ variant = 'line', size = 'sm', sport = ApiCommonSportEnum.MMA, ...props }, ref) => {
    const views: Record<BattleShellVariant, ReactElement> = {
      compact: <BattleShellCompact {...props} ref={ref} />,
      line: <BattleShellLine {...props} ref={ref} />,
      square: <BattleShellSquare {...props} ref={ref} background={sport} />,
    };

    return (
      <BattleShellContext.Provider value={{ variant: variant, size: size, sport }}>
        {views[variant]}
      </BattleShellContext.Provider>
    );
  }
);
