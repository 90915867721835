import { Icon } from '~shared/ui';

import * as S from './styled';

export const NftShouldBeRecovered = () => {
  return (
    <S.NftShouldBeRecoveredRoot>
      <Icon size={18} name="fix" />
    </S.NftShouldBeRecoveredRoot>
  );
};
