import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';

import { TournamentCard, TournamentParticipant } from '~entities/tournament';
// todo: remove
import { TournamentCardProps } from '~entities/tournament/ui/TournamentCard/types';

import { Button, Container, Heading, Stack, Tab, Tabs, TabsList } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';
import { JoinTournamentButton } from '~features/tournament';
import { ApiGetTournamentPlayer } from '~shared/api';

export const TournamentsPage: FC = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <Container>
      <Heading
        layoutVariant={isMobile ? 'section' : 'default'}
        variant="h1"
        fontSize={isMobile ? 20 : 34}
        lineHeight={isMobile ? 30 / 20 : 51 / 34}
        mb={isMobile ? 0 : 10 / 8}
        mt={isMobile ? -10 / 8 : 0}
      >
        {t('Other.tournaments')}
      </Heading>

      <Tabs value={0}>
        <TabsList>
          <Tab value={0}>All</Tab>
          <Tab>Upcoming</Tab>
          <Tab>Active</Tab>
          <Tab>Past</Tab>
        </TabsList>
      </Tabs>

      <Stack mt={24 / 8} spacing={24 / 8}>
        {tournamentsMock.map((tournamentProps) => (
          <TournamentCard key={tournamentProps.name} {...tournamentProps} />
        ))}
      </Stack>
    </Container>
  );
};

const tournamentPlayersMock: ApiGetTournamentPlayer[] = [
  { place: 1, name: 'Paul', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 2, name: 'Mark', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 3, name: 'Alfons', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 4, name: 'Bogdan', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 5, name: 'Viktor', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 6, name: 'Abel', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 7, name: 'Albin', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 8, name: 'Alfred', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 9, name: 'Anselmo', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 10, name: 'Vito', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 11, name: 'Nestor', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
  { place: 12, name: 'Avery', avatar: '', cards: [1234, 1235, 1236, 1237, 1238, 1239] },
];

const tournamentsMock: TournamentCardProps[] = [
  {
    name: 'NFL Weekly tournament',
    prizes: [
      { value: 2000, currency: 'USD' },
      { value: 300, currency: 'Matic' },
      { value: 20, currency: 'Matic' },
    ],
    sport: 'american_football',
    startDate: add(new Date(), { days: 20 }),
    endDate: add(new Date(), { days: 27 }),
    cards: [1234, 1235, 1236, 1237, 1238, 1239],
    playersLimit: 50,
    players: tournamentPlayersMock,
    joinButton: <JoinTournamentButton price={20} />,
  },

  {
    name: 'NBA Weekly tournament',
    prizes: [
      { value: 2000, currency: 'USD' },
      { value: 300, currency: 'Matic' },
      { value: 20, currency: 'Matic' },
    ],
    sport: 'basketball',
    startDate: add(new Date(), { days: 13 }),
    endDate: add(new Date(), { days: 20 }),
    cards: [1234, 1235, 1236, 1237],
    playersLimit: 50,
    players: tournamentPlayersMock,
    joinButton: (
      <Button variant="outlined" disabled>
        Already joined
      </Button>
    ),
  },

  {
    name: 'NHL Weekly tournament',
    prizes: [
      { value: 2000, currency: 'USD' },
      { value: 300, currency: 'Matic' },
      { value: 20, currency: 'Matic' },
    ],
    sport: 'hockey',
    startDate: add(new Date(), { days: -1 }),
    endDate: add(new Date(), { days: 6 }),
    cards: [1234, 1235, 1236, 1237],
    playersLimit: 50,
    players: tournamentPlayersMock,
    joinButton: (
      <Button variant="outlined" disabled>
        Tournament is full
      </Button>
    ),
  },

  {
    name: 'Tennis Weekly tournament',
    prizes: [
      { value: 2000, currency: 'USD' },
      { value: 300, currency: 'Matic' },
      { value: 20, currency: 'Matic' },
    ],
    sport: 'tennis',
    startDate: add(new Date(), { days: -8 }),
    endDate: add(new Date(), { days: -1 }),
    cards: [1234, 1235],
    playersLimit: 50,
    players: tournamentPlayersMock,
    joinButton: <TournamentParticipant place={1} name="Denis" />,
  },

  {
    name: 'Cricket Weekly tournament',
    prizes: [
      { value: 2000, currency: 'USD' },
      { value: 300, currency: 'Matic' },
      { value: 20, currency: 'Matic' },
    ],
    sport: 'american_football',
    startDate: add(new Date(), { days: -15 }),
    endDate: add(new Date(), { days: -8 }),
    cards: [1234, 1235, 1236, 1237, 1238],
    playersLimit: 50,
    players: tournamentPlayersMock,
    joinButton: <TournamentParticipant place={16} name="Oleg" />,
  },
];
