import { FC, PropsWithChildren } from 'react';

import { Typography } from '~shared/ui';

import { Root } from './styled';
import { NftStatusBadgeProps } from './types';

export const NftStatusBadge: FC<PropsWithChildren<NftStatusBadgeProps>> = ({
  background,
  children,
}) => (
  <Root background={background}>
    <Typography whiteSpace="nowrap">{children}</Typography>
  </Root>
);
