import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BigNumber, Signature, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import {
  AuctionForMCN__factory,
  MainToken__factory,
  Multicall__factory,
  NFT__factory,
} from '~shared/contracts';
import {
  ApiGetAuctionCardsResponseData,
  ApiPostPlaceBidToAuctionRequestData,
  postPlaceBidToAuction,
} from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';

import { useAuctionSelector } from '~features/auction';
import { nftActions } from '~entities/nft';
import { getProvider } from '~shared/lib/utils';
import { signGaslessTxMessage, useCallGasless, useWriteContract } from '~entities/wallet';
import { ApiPostPermitRequestData, postPermit } from '~shared/api/blockchain';

import { AUCTION_MULTICALL_GAS_LIMIT } from './config';

export const useAuctionModel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { auctionCards, isLoading } = useAuctionSelector();
  const { account, provider } = useWeb3React();
  const { wallet: viewerAddress } = useViewerModel();

  const gaslessPlaceBidCall = useCallGasless<ApiPostPlaceBidToAuctionRequestData>({
    callback: postPlaceBidToAuction,
    transactionName: 'Place Bid',
    successMessage: `${t('Alerts.successfulBid')}`,
    errorMessage: `${t('Errors.errorBid')}`,
  });

  const gaslessPermitPlaceBidCall = useCallGasless<ApiPostPermitRequestData>({
    callback: postPermit,
    transactionName: 'Approving MCN',
    successMessage: `${t('Alerts.approveMCN')}`,
    errorMessage: `${t('Errors.approveMCN')}`,
  });

  const { write: takeCard } = useWriteContract({
    contract: 'AuctionForMCN',
    method: 'takeCard',
    transactionName: 'Take Card',
    successMessage: `${t('Alerts.takeCard')}`,
    errorMessage: `${t('Errors.errorTakeCard')}`,
  });

  const signPlaceBidMessage = async (
    cardId: BigNumber,
    amount: BigNumber,
    address: string
  ): Promise<Signature | null> => {
    const signer = provider?.getSigner();

    if (!signer) {
      return null;
    }

    const types: Array<'uint256' | 'address'> = ['uint256', 'uint256', 'uint256'];

    const auctionContract = AuctionForMCN__factory.connect(
      process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN,
      signer
    );

    const gasFreeOpCounter = await auctionContract.gasFreeOpCounter(address);

    const values: Array<BigNumber> = [cardId, amount, gasFreeOpCounter];

    const signedMessage = await signGaslessTxMessage({ types, values, signer });

    return signedMessage;
  };

  const signPermitPlaceBidMessage = async (
    amount: BigNumber,
    spender: string,
    sender: string
  ): Promise<Signature | null> => {
    const signer = provider?.getSigner();

    if (!signer) {
      return null;
    }

    const types: Array<'uint256' | 'address'> = ['uint256', 'address', 'uint256'];

    const mainTokenContract = MainToken__factory.connect(
      process.env.REACT_APP_ADDRESS_SK_TOKEN_MAINTOKEN,
      signer
    );

    const permitOpsCounter = await mainTokenContract.permitOpsCounter(sender);

    const values: Array<BigNumber | string> = [permitOpsCounter, spender, amount];

    const signedMessage = await signGaslessTxMessage({ types, values, signer });

    return signedMessage;
  };

  const handlePermitPlaceBid = async (price: string) => {
    if (!account) {
      return;
    }

    const signedMessage = await signPermitPlaceBidMessage(
      ethers.utils.parseEther(price),
      process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN,
      account
    );

    if (!signedMessage) {
      return;
    }

    const { r, s, v } = signedMessage;

    await gaslessPermitPlaceBidCall({
      amount: Number(price),
      sender: account,
      spender: process.env.REACT_APP_ADDRESS_SK_AUCTION_FOR_MCN,
      r,
      s,
      v,
    });
  };

  const handlePlaceBid = async (tokenId: string, price: string) => {
    if (!account) {
      return;
    }

    const priceInWei = ethers.utils.parseEther(price);

    const signedMessage = await signPlaceBidMessage(BigNumber.from(tokenId), priceInWei, account);

    if (!signedMessage) {
      return;
    }

    const { r, s, v } = signedMessage;

    await gaslessPlaceBidCall({
      cardId: Number(tokenId),
      amount: Number(price),
      sender: account,
      r,
      s,
      v,
    });
  };

  const timeNow = Date.now();

  const handleOpenCardDetailedInfo =
    (nickname: string, avatarUrl: string, tokenId: string, creatorWalletAddress: string) => () => {
      dispatch(
        nftActions.setDetailedInfo({
          open: true,
          tokenId,
          nickname,
          avatarUrl,
          walletAddress: creatorWalletAddress,
          viewMode: true,
        })
      );
    };

  const auctionCardsMySales = useMemo(
    () =>
      auctionCards.filter((card) => {
        return viewerAddress === card.creator;
      }),
    [auctionCards, viewerAddress]
  );

  const auctionCardsMyBetsWithoutSort = useMemo(
    () =>
      auctionCards.filter((card) => {
        return viewerAddress === card.bestBettor;
      }),
    [auctionCards, viewerAddress]
  );

  const auctionCardsMyBets = useMemo(
    () =>
      auctionCardsMyBetsWithoutSort.sort(
        (a: any, b: any) => Date.parse(b.betsAcceptedUntil) - Date.parse(a.betsAcceptedUntil)
      ),
    [auctionCardsMyBetsWithoutSort]
  );

  const auctionCardsAll = useMemo(
    () =>
      auctionCards.filter((card) => {
        return card.betsAcceptedUntil.getTime() > timeNow;
      }),
    [auctionCards, timeNow]
  );

  return {
    isLoading,
    auctionCardsAll,
    auctionCardsMyBets,
    auctionCardsMySales,

    handleOpenCardDetailedInfo,
    handlePermitPlaceBid,
    handlePlaceBid,
    handleTakeCard: takeCard,
  };
};

export const getAuctionCardMulticallRequests = async (
  tokenId: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const requestsForOneNft = [];

  const nftContract = NFT__factory.connect(process.env.REACT_APP_ADDRESS_SK_CARD, provider);

  const nftContractAttached = nftContract.attach(process.env.REACT_APP_ADDRESS_SK_CARD);

  const overrideOptions = {
    gasLimit: AUCTION_MULTICALL_GAS_LIMIT,
  };

  let tx = await nftContractAttached.populateTransaction.livesRemaining(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.getLastConsequentWins(
    tokenId,
    overrideOptions
  );

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.getRarity(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  return requestsForOneNft;
};

export const getAuctionAdditionalNftInfo = async (auctionCards: ApiGetAuctionCardsResponseData) => {
  const provider = getProvider();

  const multiCallContract = Multicall__factory.connect(
    process.env.REACT_APP_ADDRESS_MULTICALL,
    provider
  );

  const multiCallContractAttached = multiCallContract.attach(
    process.env.REACT_APP_ADDRESS_MULTICALL
  );

  const requests = [];

  for (const auctionCard of auctionCards) {
    const requestsForSpecificNft = await getAuctionCardMulticallRequests(
      auctionCard.tokenId,
      provider
    );

    requests.push(...requestsForSpecificNft);
  }

  const result = await multiCallContractAttached.callStatic.multicall(requests);

  let i = 0;

  return auctionCards.map((card) => {
    try {
      const lives = Number(BigNumber.from(result[i++]));
      const winStreak = Number(BigNumber.from(result[i++]));
      const rarity = Number(BigNumber.from(result[i++]));

      return {
        ...card,
        lives,
        winStreak,
        rarity,
      };
    } catch {
      return { ...card, isBurned: true };
    }
  });
};
