import { styled } from '~shared/lib/styles';
import { Stack, Typography } from '~shared/ui';
import { palette } from '~shared/lib/styles/palette';

export const Root = styled(Stack, {
  name: 'NftCardDetailedInfo',
  slot: 'root',
})(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    padding: '16px 16px 0 16px',
    position: 'fixed',
    background: palette.paper.dark,
    width: '100%',
    zIndex: theme.zIndex.alert,
  },
}));

interface EntryValueText {
  color?: string;
}

export const EntryValueText = styled(Typography, {
  name: 'NftCardDetailedInfo',
  slot: 'entryValueText',
})<EntryValueText>(({ color }) => ({
  color,
  fontSize: '16px',
  fontWeight: 600,
}));
