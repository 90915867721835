import { FC } from 'react';

import { Icon, Stack, Typography } from '~shared/ui';

import { useEventModel } from '~entities/event';

import { useNftPreviewInfo } from '../../../model';
import { formatRelatedEventDate } from '../../../lib';

import { EventCard, LiveStatusContainer, LiveStatusText } from './styled';

import { NftCardInfoRelatedEventProps } from './types';

export const NftCardInfoRelatedEvent: FC<NftCardInfoRelatedEventProps> = ({ nft }) => {
  const { closePreview } = useNftPreviewInfo();
  const { openCallEvent, openEvent } = useEventModel();

  // todo: move to model
  const handleClick = () => {
    closePreview();

    if (nft.relatedEvent) {
      closePreview();

      if (nft.relatedEvent.isCall) {
        openCallEvent(nft.relatedEvent);
      } else {
        const { cards, choice, ...event } = nft.relatedEvent;

        openEvent(event, {
          isViewMode: true,
          choice,
          cards,
        });
      }
    }
  };

  if (!nft.relatedEvent) {
    return null;
  }

  const { homeTeam, awayTeam, date } = nft.relatedEvent;

  return (
    <EventCard onClick={handleClick}>
      {nft.isLive && (
        <LiveStatusContainer>
          <LiveStatusText>LIVE</LiveStatusText>
          <Icon name="play" size={18} color={(theme) => theme.palette.text.primary} />
        </LiveStatusContainer>
      )}

      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={4 / 8}>
          <Typography color="secondary">
            <Typography component="span" fontWeight={600}>
              {homeTeam.players.map(({ shortName }) => shortName).join(', ')}
            </Typography>
            &nbsp;vs&nbsp;
            <Typography component="span" fontWeight={600}>
              {awayTeam.players.map(({ shortName }) => shortName).join(', ')}
            </Typography>
          </Typography>
          <Typography color="secondary">{formatRelatedEventDate(date)}</Typography>
        </Stack>

        <Icon name="chevron-right" color={(theme) => theme.palette.text.secondary} />
      </Stack>
    </EventCard>
  );
};
