import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const Content = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: '360px 1fr',
  [theme.breakpoints.down(1620)]: {
    gridTemplateColumns: '1fr',
  },
}));

export const BattlesAndCallsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));
