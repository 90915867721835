import { styled } from '~shared/lib/styles';

export const LeaderboardRowsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 60,
  borderRadius: 8,
  background: theme.palette.grey.A5,
  display: 'grid',
  gridTemplateColumns: '65% repeat(3, 1fr)',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '12px 0px',
  },
}));

export const LeaderboardRowColumnInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 21,
});

export const LeaderboardRowColumn = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '14px',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LeaderboardRowName = styled('p')({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
});

export const LeaderboardArrowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginRight: '20px',
});
