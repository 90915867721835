import { FC, useRef } from 'react';

import { useSwitcher } from '~shared/lib/hooks';

import { DraggableHorizontalListRoot } from './styled';
import { DraggableHorizontalListProps } from './types';

export const DraggableHorizontalList: FC<DraggableHorizontalListProps> = ({ ...props }) => {
  const isMouseDown = useSwitcher(false);
  const isMouseMove = useSwitcher(false);

  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <DraggableHorizontalListRoot
      {...props}
      $isDown={isMouseDown.value}
      $isMove={isMouseMove.value}
      ref={ref}
      onMouseDown={(event: MouseEvent) => {
        isMouseDown.switchOn();
        startX.current = event.pageX - ref.current!.offsetLeft;
        scrollLeft.current = ref.current!.scrollLeft;
      }}
      onMouseLeave={() => {
        isMouseDown.switchOff();
        isMouseMove.switchOff();
      }}
      onMouseUp={() => {
        isMouseMove.switchOff();
        isMouseDown.switchOff();
      }}
      onMouseMove={(event: MouseEvent) => {
        if (!isMouseDown.value) {
          return;
        }

        event.preventDefault();

        isMouseMove.switchOn();

        const x = event.pageX - ref.current!.offsetLeft;
        const walk = x - startX.current;

        ref.current!.scrollLeft = scrollLeft.current - walk;
      }}
    />
  );
};
