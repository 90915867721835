import { ApiGetAuctionCardRawData, ApiGetAuctionCardsResponseData } from './types';

export const mapGetAuctionResponse = (
  data: Array<ApiGetAuctionCardRawData>
): ApiGetAuctionCardsResponseData => {
  return data.map((auctionCard) => {
    return {
      tokenId: auctionCard.TokenId.toString(),
      bestBet: auctionCard.BestBet,
      startingPrice: auctionCard.StartingPrice,
      betsAcceptedUntil: new Date(auctionCard.BetsAcceptedUntil),
      bestBettor: auctionCard.BestBettor,
      bestBettorNickname: auctionCard.BestBettorNickname,
      bestBettorAvatar: auctionCard.BestBettorAvatar,
      creator: auctionCard.Creator,
      creatorNickname: auctionCard.CreatorNickname,
      creatorAvatar: auctionCard.CreatorAvatar,
      lives: auctionCard.lives,
      winStreak: auctionCard.winStreak,
      rarity: auctionCard.rarity,
      isBurned: auctionCard.isBurned,
    };
  });
};
