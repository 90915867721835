import { ethers } from 'ethers';

import { Nft, NftRarity } from '~shared/api';

type NftAction = 'merge' | 'sell' | 'repair';

export type NftSelectionModeType = 'bet' | 'merge';

export interface NftActionDialog {
  nfts: Array<Nft>;
  open: boolean;
}

export interface NftPreviewInfo {
  open: boolean;
  nft: Nft | null;
}

export interface NftDetailedInfo {
  open: boolean;
  tokenId: string;
  avatarUrl: string;
  nickname: string;
  walletAddress: string;
  viewMode: boolean;
}

export interface NftState {
  nfts: Array<Nft>;
  nftsToMerge: [Nft | null, Nft | null];

  dialogs: Record<NftAction, NftActionDialog>;

  isDropZoneOpen: boolean;
  isSelectionMode: boolean;

  selectionModeType: NftSelectionModeType;

  previewInfo: NftPreviewInfo;
  detailedInfo: NftDetailedInfo;
}

export enum NftToMergeSerialNumber {
  First,
  Second,
}

/**
 * business logic function params
 */

export interface GetNftInfoFromChainParams {
  nft: Nft;
  provider: ethers.providers.JsonRpcProvider;
}

export interface GetNftInfoMulticallRequestsParams {
  tokenId: string;
  provider: ethers.providers.JsonRpcProvider;
}

/**
 * actions payload types
 */

export interface SetNftsToMergePayload {
  nft: Nft;
  serialNumber: NftToMergeSerialNumber;
}

export interface SetNftHiddenPayload {
  nftIds: Array<string>;
  isHidden: boolean;
}

export interface NftActionDialogSetPayload {
  action: NftAction;
  dialog: Partial<NftActionDialog>;
}

/**
 * NFT Drag-and-Drop types
 */

export interface NftDragItem {
  tokenId: string;
  rarity: NftRarity;
  winStreak: number;
}

export enum NftDragItemType {
  Leave = 'leave',
  Place = 'place',
  MoveOrLeave = 'move-or-leave',
}
