import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SlideProps } from '~shared/ui';
import { Language } from '~shared/config/i18n';

import { getKeyInKeysByHash } from '~shared/lib/utils';
import { AssetMap, PathsMap } from '~widgets/announcements';

export const useAnnouncementsRandomSlides = (): SlideProps[] => {
  const language = useTranslation().i18n.language as Language;

  const getRandomAssets = useCallback(() => {
    const result: Array<SlideProps> = [];

    const values = Object.values(AssetMap);

    for (const slides of values) {
      if (slides[language]) {
        const timestamp = new Date().toString();

        const slide = { ...getKeyInKeysByHash<SlideProps>(timestamp, slides[language]!) };

        slide.src = PathsMap[language] + slide.src;

        result.push(slide);
      }
    }

    return result;
  }, [language]);

  const [assets, setAssets] = useState(getRandomAssets());

  useEffect(() => {
    setAssets(getRandomAssets());
  }, [getRandomAssets, language]);

  return assets;
};
