import { styled } from '~shared/lib/styles';

import { Input, inputClasses } from '../Input';

export const CodeInputInput = styled(Input)({
  width: 38,

  [`& .${inputClasses.input}`]: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 24 / 18,
    letterSpacing: '-0.20000000298023224px',
    textAlign: 'center',

    ['&::-webkit-outer-spin-button, &::-webkit-inner-spin-button']: {
      ['-webkit-appearance']: 'none',
      margin: 0,
    },

    ['&[type=number]']: {
      ['-moz-appearance']: 'textfield',
    },
  },
});
