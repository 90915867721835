import { styled } from '~shared/lib/styles';

export const dashboardFinanceCardClasses = {
  root: 'DashboardFinanceCard-root',
  iconWrapper: 'DashboardFinanceCard-iconWrapper',

  primary: 'DashboardFinanceCard-primary',
  error: 'DashboardFinanceCard-error',
  paper: 'DashboardFinanceCard-paper',

  lightShadow: 'DashboardFinanceCard-lightShadow',
  darkShadow: 'DashboardFinanceCard-darkShadow',
};

export const DashboardFinanceCardRoot = styled('div')(({ theme }) => ({
  padding: '12px 12px 14px 12px',
  borderRadius: 12,
  position: 'relative',
  userSelect: 'none',
  minWidth: 200,

  height: '100%',
  display: 'grid',
  alignItems: 'flex-start',

  [`&.${dashboardFinanceCardClasses.paper}`]: {
    background: theme.palette.grey.A5,

    [`& .${dashboardFinanceCardClasses.darkShadow}`]: {
      background: 'rgba(0, 0, 0, 0.22)',
      filter: 'blur(32px)',
    },
    [`& .${dashboardFinanceCardClasses.lightShadow}`]: {
      background: 'rgba(255, 255, 255, 0.08)',
      filter: 'blur(32px)',
    },

    [`& .${dashboardFinanceCardClasses.iconWrapper}`]: {
      background: theme.palette.grey.A5,
    },
  },
  [`&.${dashboardFinanceCardClasses.error}`]: {
    background: theme.palette.error.main,

    [`& .${dashboardFinanceCardClasses.lightShadow}`]: {
      background: 'rgba(255, 255, 255, 0.12)',
      filter: 'blur(32px)',
    },
    [`& .${dashboardFinanceCardClasses.darkShadow}`]: {
      background: 'rgba(0, 0, 0, 0.15)',
      filter: 'blur(32px)',
    },
  },

  [`&.${dashboardFinanceCardClasses.primary}`]: {
    background: theme.palette.primary.main,

    [`& .${dashboardFinanceCardClasses.lightShadow}`]: {
      background: 'rgba(255, 255, 255, 0.2)',
      filter: 'blur(32px)',
    },
    [`& .${dashboardFinanceCardClasses.darkShadow}`]: {
      background: 'rgba(0, 0, 0, 0.15)',
      filter: 'blur(32px)',
    },
  },
}));

export const DashboardFinanceCardShadowWrapper = styled('div')({
  position: 'absolute',
  inset: 0,
  pointerEvents: 'none',
  overflow: 'hidden',
  borderRadius: 12,
  zIndex: 0,
});

export const DashboardFinanceCardLightShadow = styled('div')(() => ({
  position: 'absolute' as const,

  width: 128,
  height: 128,
  right: -32,
  top: -64,
}));

export const DashboardFinanceCardDarkShadow = styled('div')(() => ({
  background: 'rgba(0, 0, 0, 0.15)',
  position: 'absolute' as const,
  filter: 'blur(32px)',

  width: 256,
  height: 256,
  bottom: -128,
  left: -128,
}));

export const DashboardFinanceCardIconWrapper = styled('div')(({ theme }) => ({
  padding: 6,
  background: theme.palette.grey.A20,
  borderRadius: '100%',
}));
