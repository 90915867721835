import { Popper as MuiBasePopper } from '@mui/base';
import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

import { iconClasses } from '../../dataDisplay';

export const selectClasses = {
  root: 'Select-root',
  placeholder: 'Select-placeholder',
  content: 'Select-content',
  chevron: 'Select-chevron',

  error: 'Select-error',
  focusVisible: 'Select-focusVisible',
  active: 'Select-active',
  disabled: 'Select-disabled',
};

export const UIKitSelectRoot = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  background: theme.palette.paper.semi,
  border: `1.5px solid ${theme.palette.paper.semi}`,
  borderRadius: 8,
  transition: theme.transitions.create(['box-shadow', 'border-color']),
  color: theme.palette.text.primary,
  overflow: 'hidden',

  width: '100%',
  height: 44,
  paddingLeft: 10,
  paddingRight: 10,

  ['&:disabled']: {
    opacity: 0.3,
    cursor: 'not-allowed',
  },

  [`&:hover:not(:disabled), &.${selectClasses.focusVisible}`]: {
    boxShadow: '0px 0px 8px #42BAFF',
  },

  [`& .${iconClasses.root}`]: {
    transition: theme.transitions.create('transform'),
    marginLeft: 5,
  },

  ['&[aria-expanded=true]']: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 0px 8px #42BAFF',

    [`& .${iconClasses.root}`]: {
      transform: 'rotate(180deg)',
    },
  },

  [`&.${selectClasses.error}`]: {
    border: `1.5px solid ${theme.palette.error.main}`,

    [`&:hover, &[aria-expanded=true], &.${selectClasses.focusVisible}`]: {
      boxShadow: '0px 0px 8px #FF7043',
    },
  },
}));

export const UIKitSelectContent = styled('span')(
  () =>
    ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    }) as const
);

export const UIKitSelectPlaceholder = styled('span')(({ theme }) => ({
  color: theme.palette.grey.A50,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const UIKitSelectPopper = styled(MuiBasePopper)(() => ({
  maxWidth: 300,
}));

export const UIKitSelectListBox = styled(motion.ul)(({ theme }) => ({
  maxHeight: 380,
  overflow: 'auto',
  padding: '2px 0',

  background: theme.palette.paper.light,
  borderRadius: 8,
  boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 1)',
}));
