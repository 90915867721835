import { FC } from 'react';

import { Stack, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCardDetailedInfoHeaderEntryProps } from './types';

export const NftCardDetailedInfoHeaderEntry: FC<NftCardDetailedInfoHeaderEntryProps> = ({
  label,
  value,
}) => {
  const { isMobile } = useMediaQuery();
  const direction = isMobile ? 'row' : 'column';

  return (
    <Stack direction={direction} spacing={isMobile ? 12 / 8 : 4 / 8}>
      <Typography color="secondary">{label}</Typography>
      {value}
    </Stack>
  );
};
