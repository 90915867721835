import { FC } from 'react';

import { DialogProps } from '~shared/ui';
import { ApiCommonSportEnum } from '~shared/api';

import { useEventModel } from '~entities/event';

import { StyledDesktopEventDialog } from './styled';

export const EventDialogDesktop: FC<DialogProps> = ({ children, onClose, ...dialogProps }) => {
  const { event } = useEventModel();

  return (
    <StyledDesktopEventDialog
      {...dialogProps}
      $sport={event?.sport ?? ApiCommonSportEnum.Football}
      onClose={onClose}
      width={720}
    >
      {children}
    </StyledDesktopEventDialog>
  );
};
