import { useMemo } from 'react';

import { useNftSelector } from '~entities/nft';
import { useCallsListQuery } from '~entities/calls';
import { isEventCurrent, isEventPassed } from '~entities/battle';
import { useBetsQuery } from '~entities/bets';

export const useNftCallsQuery = () => {
  const {
    detailedInfo: { tokenId, walletAddress },
  } = useNftSelector();

  const { list, isLoading } = useCallsListQuery({ address: walletAddress });

  const sortedCalls = useMemo(() => {
    if (list && tokenId) {
      return list
        .filter((call) => {
          const isCurrentNft =
            call.firstParticipant?.card === tokenId || call.secondParticipant?.card === tokenId;

          return isCurrentNft;
        })
        .sort((a, b) => b.date.getTime() - a.date.getTime());
    }
  }, [list, tokenId]);

  return {
    isLoading,
    isEmptyList: sortedCalls && sortedCalls.length === 0,

    list: sortedCalls,
    passedList: sortedCalls?.filter((call) => isEventPassed(call.result)),
    currentList: sortedCalls?.filter((call) => isEventCurrent(call.result)),
  };
};

export const useNftBetsQuery = () => {
  const {
    detailedInfo: { tokenId, walletAddress },
  } = useNftSelector();

  const betsQuery = useBetsQuery({
    wallet: walletAddress,
    queryKey: ['bets', walletAddress, tokenId],
    filterCallback: (bet) =>
      bet.isTakenBeforeMatchStarted !== 1 &&
      bet.tokenIds.map((tokenId) => String(tokenId)).includes(tokenId),
  });

  return betsQuery;
};
