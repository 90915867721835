import { FC, useCallback } from 'react';

import { DateLabel } from '~shared/ui';
import { BattleChoiceId } from '~shared/api';

import { NftCardsStack, StaticNftCard } from '~entities/nft';
import { useViewerModel } from '~entities/viewer';
import { useEventModel } from '~entities/event';

import {
  getBattleShellStatus,
  leagueToLogoMap,
  renderResultBadge,
  useBattleAutoStyle,
} from '../../../lib';
import { BattleShellFactory, BattleTeam, BattleVersus } from '../../factory';
import { useDropBet } from '../../../model';

import { BattleBetProps } from './types';

export const BattleBet: FC<BattleBetProps> = ({
  size,
  variant,

  onClick,
  className,

  ...bet
}) => {
  const { homeTeam, awayTeam, league, date, result, choiceId: choice, tokenIds, eventId } = bet;

  const { avatar } = useViewerModel();

  const { openBetEvent } = useEventModel();

  const dropProps = useDropBet({
    event: { ...bet, id: eventId },
    choice: choice,
    tokenIds,
    result,
  });

  const handleOpenEvent = useCallback(() => {
    openBetEvent(bet);
  }, [openBetEvent]);

  const renderSlotByChoiceId = (slot: BattleChoiceId) => {
    if (slot.valueOf() === choice.valueOf()) {
      return (
        <NftCardsStack>
          {tokenIds.map((tokenId) => (
            <StaticNftCard key={tokenId} tokenId={tokenId} />
          ))}
        </NftCardsStack>
      );
    }
  };

  const getSlotUserAvatar = (slot: BattleChoiceId) => {
    if (slot.valueOf() === choice.valueOf()) {
      return avatar?.src;
    }
  };

  return (
    <BattleShellFactory
      {...dropProps}
      overlapBackdropAvailable={dropProps.isOverlapBackdropAvailable}
      className={className}
      variant={variant}
      size={size}
      onClick={handleOpenEvent}
      homeTeam={
        <BattleTeam
          players={homeTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          badge={renderResultBadge(result, BattleChoiceId.HomeTeam)}
          slot={renderSlotByChoiceId(BattleChoiceId.HomeTeam)}
          slotUserAvatar={getSlotUserAvatar(BattleChoiceId.HomeTeam)}
        />
      }
      awayTeam={
        <BattleTeam
          players={awayTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          badge={renderResultBadge(result, BattleChoiceId.AwayTeam)}
          slot={renderSlotByChoiceId(BattleChoiceId.AwayTeam)}
          slotUserAvatar={getSlotUserAvatar(BattleChoiceId.AwayTeam)}
        />
      }
      versus={
        <BattleVersus
          badge={renderResultBadge(result, BattleChoiceId.Draw)}
          slot={renderSlotByChoiceId(BattleChoiceId.Draw)}
          slotUserAvatar={getSlotUserAvatar(BattleChoiceId.Draw)}
        />
      }
      sport={bet.sport}
      date={<DateLabel date={new Date(date)} />}
      leagueLogo={leagueToLogoMap[league]}
      status={getBattleShellStatus({ result, choice })}
    />
  );
};
