import { ethers } from 'ethers';

import { getProvider } from '~shared/lib/utils';

export const checkIsEnoughMatic = async (address: string, neededBalance: number) => {
  const balanceInWei = await getProvider().getBalance(address);
  const balance = Number(ethers.utils.formatEther(balanceInWei));

  return balance >= neededBalance;
};
