import { styled } from '~shared/lib/styles';

export const BestAuctionListContainer = styled('div')(() => ({
  width: '394px',
  height: '176px',
  background: '#1A1D20',
  borderRadius: '12px',
  overflow: 'hidden',
  display: 'flex',
  paddingTop: '10px',
}));

export const BestAuctionImg = styled('img')({
  width: '86px',
  height: '125px',
  marginLeft: '10px',
});

export const BestAuctionRightContainer = styled('div')({
  width: '62%',
  marginRight: '10px',
});

export const AuctionSellCardBets = styled('div')({
  width: '100%',
  marginTop: '10px',
});

export const AuctionSellCardBet = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const AuctionBettorBrief = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const RareInfoCard = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const CardInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  marginBottom: '10px',
});

export const CardInfoBlock = styled('div')({});

export const BidButton = styled('p')({
  color: '#2EB2FF',
  lineHeight: '21px',
  fontSize: '14px',
  fontWeight: '600',
  cursor: 'pointer',
  marginLeft: '10px',
});

export const BidContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
