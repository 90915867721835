import { FC, cloneElement, isValidElement } from 'react';

import { Avatar, Stack, TypographyProps } from '~shared/ui';

import { UserVoteCaptionName } from './styled';
import { UserCaptionVoteProps } from './types';

export const UserCaptionVote: FC<UserCaptionVoteProps> = ({
  avatar,
  caption,
  fromRight,
  nickname,
}) => {
  const captionProps = fromRight ? ({ align: 'right', justifySelf: 'end' } as const) : undefined;

  if (nickname) {
    return (
      <Stack direction={fromRight ? 'row-reverse' : 'row'} alignItems="center" spacing={8 / 8}>
        <Avatar size={24} src={avatar} />

        <Stack spacing={2 / 8}>
          <UserVoteCaptionName align={fromRight ? 'right' : 'left'}>{nickname}</UserVoteCaptionName>
          {isValidElement(caption) && cloneElement<TypographyProps>(caption, captionProps)}
        </Stack>
      </Stack>
    );
  }

  return isValidElement(caption) ? cloneElement<TypographyProps>(caption, captionProps) : null;
};
