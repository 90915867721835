import { ReactElement } from 'react';

import { PasswordRecoverySteps, useRecoveryModel } from '../model';

import { RecoveryChangePassword } from './RecoveryChangePassword';
import { PasswordChanged } from './RecoverySuccess';

export const PasswordRecovery = () => {
  const { step } = useRecoveryModel();

  const view: Record<PasswordRecoverySteps, ReactElement> = {
    [PasswordRecoverySteps.ChangePassword]: <RecoveryChangePassword />,
    [PasswordRecoverySteps.PasswordChanged]: <PasswordChanged />,
  };

  return view[step];
};
