import { styled } from '~shared/lib/styles';
import { SelectField } from '~shared/ui';

export const StyledSelectField = styled(SelectField, {
  name: 'FixCardDialogForm',
  slot: 'selectField',
})(({ theme }) => ({
  border: `1.5px solid ${theme.palette.paper.semi}`,
  minWidth: '128px',
}));
