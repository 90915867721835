import { FC } from 'react';

import { NftCard } from '~entities/nft';

import { Button, Dialog, Stack, Typography } from '~shared/ui';

import { useMergeModel } from '../../../model';

export const RepairBeforeMergeDialog: FC = () => {
  const { cancelRepairBeforeMerge, targetNftToRepair, repairBeforeMergeDialogOpen } =
    useMergeModel();

  return (
    <Dialog open={repairBeforeMergeDialogOpen} onClose={cancelRepairBeforeMerge} width={440}>
      <Stack alignItems="center" spacing={24 / 8}>
        <Typography variant="h1" mb="24px" textAlign="center">
          Fix merge card
        </Typography>

        {targetNftToRepair && (
          <NftCard nft={targetNftToRepair} isHighlighted highlightVariant="large" />
        )}

        <Typography textAlign="center" mt="24px">
          Card is ready for merge <br />
          But before merge you need to fix card
        </Typography>

        <Stack direction="row" justifyContent="center" spacing={12 / 8}>
          <Button variant="outlined" onClick={cancelRepairBeforeMerge}>
            I’ll do this later
          </Button>
          <Button>Fix a card</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
