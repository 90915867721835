import { styled } from '~shared/lib/styles';

import { IconProps } from './types';

export const iconClasses = {
  root: 'Icon-root',
};

export const UIKitIcon = styled('svg')<Pick<IconProps, 'size'>>(({ size }) => ({
  width: typeof size === 'object' ? size.width : size,
  height: typeof size === 'object' ? size.height : size,
  display: 'inline-block',
  flexShrink: 0,
  verticalAlign: 'middle',
}));
