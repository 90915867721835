import { ApiCommonSportEnum } from '~shared/api';

const path = process.env.PUBLIC_URL + '/assets/event/background/';

export const SportToAssetMaps: Record<ApiCommonSportEnum, string> = {
  [ApiCommonSportEnum.Tennis]: path + 'tennis.jpg',
  [ApiCommonSportEnum.Basketball]: path + 'basketball.jpg',
  [ApiCommonSportEnum.Football]: path + 'football.jpg',
  [ApiCommonSportEnum.MMA]: path + 'mma.jpg',
  [ApiCommonSportEnum.Baseball]: path + 'baseball.jpg',

  [ApiCommonSportEnum.CSGO]: path + 'esports.jpg',
  [ApiCommonSportEnum.LeagueOfLegends]: path + 'esports.jpg',
  [ApiCommonSportEnum.Dota]: path + 'esports.jpg',

  // common
  [ApiCommonSportEnum.Hockey]: path + 'football.jpg',
  [ApiCommonSportEnum.Cricket]: path + 'football.jpg',
  [ApiCommonSportEnum.AmericanFootball]: path + 'football.jpg',
};

const battlePath = '/assets/battle/';

export const SportToTournamentBackgroundMap: Record<ApiCommonSportEnum, string> = {
  [ApiCommonSportEnum.Tennis]: battlePath + 'tennis/battle-tennis-1.jpg',
  [ApiCommonSportEnum.Basketball]: battlePath + 'basketball/battle-basketball-1.jpg',
  [ApiCommonSportEnum.Football]: battlePath + 'football/battle-football-1.jpg',
  [ApiCommonSportEnum.MMA]: battlePath + 'mma/battle-mma-1.jpg',
  [ApiCommonSportEnum.Baseball]: battlePath + 'baseball/battle-baseball-1.jpg',
  [ApiCommonSportEnum.Hockey]: battlePath + 'hockey/battle-hockey-1.jpg',
  [ApiCommonSportEnum.Cricket]: battlePath + 'cricket/battle-cricket-1.jpg',
  // prettier-ignore
  [ApiCommonSportEnum.AmericanFootball]: battlePath + 'american-football/battle-american-football-1.jpg',

  [ApiCommonSportEnum.CSGO]: battlePath + 'csgo/battle-csgo-1.jpg',
  // prettier-ignore
  [ApiCommonSportEnum.LeagueOfLegends]: battlePath + 'league-of-legends/battle-league-of-legends-1.jpg',
  [ApiCommonSportEnum.Dota]: battlePath + 'dota/battle-dota-1.jpg',
};
