import { getNotificationToken } from '~shared/api';

import {
  NotificationClientEventType,
  NotificationEvent,
  NotificationEventHistoryResponse,
  NotificationRawEvent,
  NotificationRawHistoryEvent,
  NotificationServerEventType,
} from './types';

export class NotificationsService {
  constructor(params: {
    userId: number;
    handleNotification: (notification: NotificationEvent) => void;
    handleNotifications: (notifications: NotificationEvent[]) => void;
  }) {
    this.userId = params.userId;
    this.handleNotification = params.handleNotification;
    this.handleNotifications = params.handleNotifications;

    this.init = this.init.bind(this);
    this.send = this.send.bind(this);
    this.readNotification = this.readNotification.bind(this);
    this.readAllNotifications = this.readAllNotifications.bind(this);
    this.addToReadStack = this.addToReadStack.bind(this);
    this.applyReadStack = this.applyReadStack.bind(this);
    this.close = this.close.bind(this);
    this.isNotificationShouldBeShown = this.isNotificationShouldBeShown.bind(this);
  }

  private readonly handleNotifications: (notification: NotificationEvent[]) => void;
  private readonly handleNotification: (notification: NotificationEvent) => void;

  private readonly userId: number;
  private token: string | null = null;

  private notificationsMap: Map<number, NotificationEvent> = new Map();
  private readStack: Set<number> = new Set();
  private socket: WebSocket | null = null;

  // Notifications types that should be shown to viewer
  private notificationTypeToHandle: NotificationServerEventType[] = [
    NotificationServerEventType.NotificationsHistoryResponse,
    NotificationServerEventType.CardAdded,
    NotificationServerEventType.CardLivesChanged,
    NotificationServerEventType.SetWalletNotification,
    NotificationServerEventType.EventSoon,
    NotificationServerEventType.EventLive,
  ];

  /** Methods */
  private mapNotification(
    data: NotificationRawEvent | NotificationRawHistoryEvent
  ): NotificationEvent {
    return {
      // todo: better solution
      id: data.notificationId ?? Math.floor(new Date().getTime() * Math.random() * 100),
      read: Boolean('status' in data ? --data.status : false),
      date: data.creationDate ? new Date(data.creationDate * 1000) : new Date(),
      payload: typeof data.payload === 'string' ? JSON.parse(data.payload) : data.payload,
      type: data.type,
    };
  }

  private receive(event: MessageEvent) {
    const notification = this.mapNotification(JSON.parse(event.data));

    switch (notification.type) {
      case NotificationServerEventType.NotificationsHistoryResponse:
        this.handleNotificationsHistory(notification);
        break;
      default:
        this.handleNotification(notification);
        this.notificationsMap.set(notification.id, notification);
        this.handleNotifications(this.notifications);
    }
  }

  private handleNotificationsHistory(notifications: NotificationEventHistoryResponse) {
    if (notifications.payload.History) {
      notifications.payload.History.forEach((notification) => {
        const mappedNotification = this.mapNotification(notification);
        this.notificationsMap.set(mappedNotification.id, mappedNotification);
      });

      this.handleNotifications(this.notifications);
    }
  }

  private isNotificationShouldBeShown(notification: NotificationEvent) {
    return this.notificationTypeToHandle.includes(notification.type);
  }

  public async init() {
    const { token } = await getNotificationToken();
    this.token = token;

    this.socket = new WebSocket(process.env.REACT_APP_BACKEND_NOTIFICATIONS_WS);
    this.socket.onmessage = (...args) => this.receive(...args);

    this.socket.onopen = () => {
      this.send(NotificationClientEventType.SetUser, { userId: this.userId });

      this.send(NotificationClientEventType.NotificationsHistory, {
        startTime: 0,
        endTime: new Date().getTime(),
      });
    };
  }

  public async close() {
    this.socket?.close();
  }

  public send(type: NotificationClientEventType, payload: Object) {
    const event = { type, payload: { ...payload, token: this.token } };

    this.socket?.send(JSON.stringify(event));
  }

  public addToReadStack(id: number) {
    this.readStack.add(id);
  }

  public applyReadStack() {
    this.readStack.forEach((id) => {
      this.send(NotificationClientEventType.ConfirmNotification, { notificationId: id });
      this.notificationsMap.set(id, { ...this.notificationsMap.get(id)!, read: true });
    });

    this.handleNotifications(this.notifications);

    this.readStack.clear();
  }

  public readNotification(id: number) {
    this.send(NotificationClientEventType.ConfirmNotification, { notificationId: id });
    this.notificationsMap.set(id, { ...this.notificationsMap.get(id)!, read: true });
    this.handleNotifications(this.notifications);
  }

  public readAllNotifications() {
    this.unreadNotifications.forEach((notification) => {
      this.send(NotificationClientEventType.ConfirmNotification, {
        notificationId: notification.id,
      });

      this.notificationsMap.set(notification.id, {
        ...this.notificationsMap.get(notification.id)!,
        read: true,
      });
    });

    this.handleNotifications(this.notifications);
  }

  /** Getters */
  get notifications() {
    const notifications = Array.from(this.notificationsMap.values());

    return notifications
      .filter(this.isNotificationShouldBeShown)
      .sort((a, b) => b.date.getTime() - a.date.getTime());
  }

  get unreadNotifications() {
    return this.notifications.filter((notification) => !notification.read);
  }
}
