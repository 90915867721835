import { FC, cloneElement } from 'react';
import { TabsContext, useTabs } from '@mui/base';
import { clsx } from 'clsx';

import { VerticalPanelProps } from './types';
import {
  UIKitVerticalPanelHeading,
  UIKitVerticalPanelList,
  UIKitVerticalPanelRoot,
  verticalPanelClasses,
} from './styled';

export const VerticalPanel: FC<VerticalPanelProps> = ({
  children,
  heading,
  value,
  onChange,
  defaultValue,

  ...props
}) => {
  const { contextValue } = useTabs({ value, onChange, defaultValue });

  return (
    <TabsContext.Provider value={contextValue}>
      <UIKitVerticalPanelRoot
        {...props}
        className={clsx(verticalPanelClasses.root, props.className)}
      >
        {heading && (
          <UIKitVerticalPanelHeading className={verticalPanelClasses.heading}>
            {heading}
          </UIKitVerticalPanelHeading>
        )}

        <UIKitVerticalPanelList className={verticalPanelClasses.list}>
          {children?.map((action, index) => {
            const actionValue = action.props.value === undefined ? index : action.props.value;

            return cloneElement(action, {
              ...action.props,
              selected: value === actionValue,
              value: actionValue,
            });
          })}
        </UIKitVerticalPanelList>
      </UIKitVerticalPanelRoot>
    </TabsContext.Provider>
  );
};
