import { Icon } from '~shared/ui';

import * as S from './styled';

export const NftBlockedForEvent = () => {
  return (
    <S.NftBlockedForEventRoot>
      <Icon name="lock" size={25} />
      <S.NftBlockedForEventText>
        Blocked <br /> for event
      </S.NftBlockedForEventText>
    </S.NftBlockedForEventRoot>
  );
};
