import { FC } from 'react';

import { Root, StyledTypography } from './styled';
import { EventDialogChoiceResultProps } from './types';

export const EventDialogChoiceResult: FC<EventDialogChoiceResultProps> = ({ isCorrectChoice }) => {
  return (
    <Root $isCorrectChoice={isCorrectChoice}>
      <StyledTypography>{isCorrectChoice ? 'You win' : 'You lose'}</StyledTypography>
    </Root>
  );
};
