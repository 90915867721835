import { FC, ReactElement, useCallback } from 'react';

import { DateLabel } from '~shared/ui';
import { ApiGetCallDataParticipant, BattleChoiceId, BattleResult } from '~shared/api';

import { StaticNftCard } from '~entities/nft';
import { useViewerModel } from '~entities/viewer';
import { useEventModel } from '~entities/event';

import {
  isEventPassed,
  leagueToLogoMap,
  renderResultBadge,
  useBattleAutoStyle,
  useBattleDragHighlight,
} from '../../../lib';

import {
  BattleShellFactory,
  BattleShellStatus,
  BattleSlot,
  BattleTeam,
  BattleVersus,
} from '../../factory';

import { useBattleModel } from '../../../model';

import { BattleCallProps } from './types';

export const BattleCall: FC<BattleCallProps> = ({
  variant = 'line' as const,
  size,

  ...event
}) => {
  const { firstParticipant, secondParticipant, result, homeTeam, awayTeam, league, date } = event;
  const { wallet } = useViewerModel();

  const { isOverlapBackdrop } = useBattleModel();
  const { openCallEvent } = useEventModel();

  const viewerParticipant =
    [firstParticipant, secondParticipant].find((participant) => {
      return participant?.address === wallet;
    }) ?? null;

  const isViewerCall = !!viewerParticipant;
  const isCallPassed = isEventPassed(result);

  const handleOpenCallEvent = useCallback(() => {
    openCallEvent(event);
  }, [event]);

  const { homeTeamCallUser, awayTeamCallUser } = getCallParticipantLocations([
    firstParticipant,
    secondParticipant,
  ]);

  const { homeTeamSlot, awayTeamSlot, versusSlot } = getCallCardsLocations([
    firstParticipant,
    secondParticipant,
  ]);

  const availableToDrop = (() => {
    // todo: temp cause of result=0 on past calls
    if ([homeTeamSlot, awayTeamSlot, versusSlot].filter((test) => !!test).length >= 2) {
      return false;
    }

    if (isViewerCall) {
      return false;
    }

    return true;
  })();

  const useHighlightProps = useBattleDragHighlight(!availableToDrop || !isOverlapBackdrop);

  const getStatus = (): BattleShellStatus => {
    if (isViewerCall && isCallPassed) {
      return viewerParticipant.choiceId === result ? 'win' : 'lose';
    }

    return 'future';
  };

  const renderSlot = (
    slot: BattleResult.HomeTeamWon | BattleResult.AwayTeamWon | BattleResult.Draw,
    card?: ReactElement
  ) => {
    if (card) {
      return card;
    }

    if (!availableToDrop) {
      return;
    }

    if (result === BattleResult.InProgress) {
      return <BattleSlot slot={slot} call event={event} />;
    }
  };

  return (
    <BattleShellFactory
      {...useHighlightProps}
      overlapBackdropAvailable={availableToDrop}
      variant={variant}
      size={size}
      onClick={handleOpenCallEvent}
      homeTeam={
        <BattleTeam
          players={homeTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          slot={renderSlot(BattleResult.HomeTeamWon, homeTeamSlot)}
          slotUserAvatar={homeTeamCallUser?.avatar}
          badge={renderResultBadge(result, BattleChoiceId.HomeTeam)}
        />
      }
      awayTeam={
        <BattleTeam
          players={awayTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          slot={renderSlot(BattleResult.AwayTeamWon, awayTeamSlot)}
          slotUserAvatar={awayTeamCallUser?.avatar}
          badge={renderResultBadge(result, BattleChoiceId.AwayTeam)}
        />
      }
      versus={
        <BattleVersus
          slot={isOverlapBackdrop ? renderSlot(BattleResult.Draw, versusSlot) : null}
          badge={renderResultBadge(result, BattleChoiceId.Draw)}
        />
      }
      sport={event.sport}
      leagueLogo={leagueToLogoMap[league]}
      date={<DateLabel date={date} />}
      status={getStatus()}
      call
    />
  );
};

const getCallCardsLocations = (
  participants: Array<ApiGetCallDataParticipant | null>
): { homeTeamSlot?: ReactElement; awayTeamSlot?: ReactElement; versusSlot?: ReactElement } => {
  const homeTeamParticipant = participants.find((p) => p?.choiceId === BattleChoiceId.HomeTeam);
  const awayTeamParticipant = participants.find((p) => p?.choiceId === BattleChoiceId.AwayTeam);
  const drawParticipant = participants.find((p) => p?.choiceId === BattleChoiceId.Draw);

  return {
    homeTeamSlot: homeTeamParticipant?.card ? (
      <StaticNftCard tokenId={homeTeamParticipant.card} />
    ) : undefined,
    awayTeamSlot: awayTeamParticipant?.card ? (
      <StaticNftCard tokenId={awayTeamParticipant.card} />
    ) : undefined,
    versusSlot: drawParticipant?.card ? (
      <StaticNftCard tokenId={drawParticipant.card} />
    ) : undefined,
  };
};

interface BattlePlayerCallUser {
  name: string;
  avatar: string;
  draw?: boolean;
}

const getCallParticipantLocations = (
  participants: Array<ApiGetCallDataParticipant | null>
): {
  homeTeamCallUser?: BattlePlayerCallUser;
  awayTeamCallUser?: BattlePlayerCallUser;
} => {
  const homeTeamParticipant = participants.find((p) => p?.choiceId === BattleChoiceId.HomeTeam);
  const awayTeamParticipant = participants.find((p) => p?.choiceId === BattleChoiceId.AwayTeam);
  const drawParticipant = participants.find((p) => p?.choiceId === BattleChoiceId.Draw);

  const participantToCallUser = (
    participant?: ApiGetCallDataParticipant | null
  ): BattlePlayerCallUser | undefined => {
    if (participant) {
      return {
        name: participant.nickname,
        avatar: participant.avatar,
        draw: participant.choiceId === BattleChoiceId.Draw,
      };
    }
  };

  if (drawParticipant) {
    if (awayTeamParticipant) {
      return {
        homeTeamCallUser: participantToCallUser(drawParticipant),
        awayTeamCallUser: participantToCallUser(awayTeamParticipant),
      };
    } else if (homeTeamParticipant) {
      return {
        homeTeamCallUser: participantToCallUser(homeTeamParticipant),
        awayTeamCallUser: participantToCallUser(drawParticipant),
      };
    }
  }

  return {
    homeTeamCallUser: participantToCallUser(homeTeamParticipant),
    awayTeamCallUser: participantToCallUser(awayTeamParticipant),
  };
};
