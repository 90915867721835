import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

import { NftCardSize } from '../../../lib';

type NftCardStatesRootProps = {
  $hasBackdrop: boolean;
  $size: NftCardSize;
};

const NFT_SIZE_TO_FONT_SIZE: Record<NftCardSize, string> = {
  xs: '8px',
  sm: '8px',
  md: '10px',
  lg: '12px',
  xl: '14px',
};

export const NftCardStatesRoot = styled(Box, {
  name: 'NftCardStates',
})<NftCardStatesRootProps>(({ $hasBackdrop, $size }) => ({
  position: 'absolute',
  inset: 0,
  background: $hasBackdrop ? 'rgba(0, 0, 0, 0.5)' : '',
  borderRadius: '10px',
  fontSize: NFT_SIZE_TO_FONT_SIZE[$size],
  fontWeight: 500,
  lineHeight: '10px',

  ['.NftOnEvent__root']: {
    padding: $size !== 'sm' && $size !== 'xs' ? '2px 4px' : '0',
    top: $size !== 'sm' && $size !== 'xs' ? 10 : 5,
  },
}));
