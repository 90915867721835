import { FC } from 'react';

import {
  IconContainer,
  ItemBottom,
  ItemDescription,
  ItemName,
  ItemParam,
  ItemParamLabel,
  ItemParamValue,
  ItemParamsContainer,
  Root,
} from './styled';

import { MarketplaceItemShellProps } from './types';

export const MarketplaceItemShell: FC<MarketplaceItemShellProps> = ({
  name,
  description,
  icon,
  params = [],
  children,
  ...otherProps
}) => {
  return (
    <Root {...otherProps}>
      {icon && <IconContainer>{icon}</IconContainer>}

      <ItemName>{name}</ItemName>

      {description && <ItemDescription>{description}</ItemDescription>}

      {params.length > 0 && (
        <ItemParamsContainer>
          {params.map(({ label, value }, key) => (
            <ItemParam key={key}>
              <ItemParamLabel>{label}</ItemParamLabel>
              <ItemParamValue>{value}</ItemParamValue>
            </ItemParam>
          ))}
        </ItemParamsContainer>
      )}

      <ItemBottom>{children}</ItemBottom>
    </Root>
  );
};
