export const paramsSerializerWithArray = (params: Record<string, any>): string => {
  const paramsMap = new Map();
  const entries = Object.entries(params);

  for (const [key, value] of entries) {
    if (Array.isArray(value)) {
      paramsMap.set(key, value.join(','));
    } else if (value) {
      paramsMap.set(key, value);
    }
  }

  return new URLSearchParams(Object.fromEntries(paramsMap)).toString();
};
