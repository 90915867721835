import { useTranslation } from 'react-i18next';

import { palette } from '~shared/lib/styles/palette';
import { Dialog, Stack, Tooltip, Typography } from '~shared/ui';

import { connections, useConnectWalletDialogModel } from '../../../model';

import { StyledButtonIcon, WalletsContainer } from './styled';

export const ConnectWalletDialogDesktop = () => {
  const { t } = useTranslation();

  const { open, handleConnect, handleClose } = useConnectWalletDialogModel();

  return (
    <Dialog open={open} width={440} backgroundColor={palette.paper.secondary} onClose={handleClose}>
      <Stack spacing={24 / 8} alignItems="center">
        <Typography variant="h1">{t('Other.connect')}</Typography>
        <Typography color="secondary">{t('Other.connectWallet')}</Typography>
        <WalletsContainer>
          {Object.values(connections).map(({ name, icon, type }) => (
            <Tooltip key={name} title={name}>
              <StyledButtonIcon name={icon} size={48} onClick={handleConnect(type)} />
            </Tooltip>
          ))}
        </WalletsContainer>
      </Stack>
    </Dialog>
  );
};
