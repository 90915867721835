import { Popper as MuiBasePopper } from '@mui/base';
import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

export const tooltipClasses = {
  arrow: 'Tooltip-arrow',
};

export const UIKitTooltipPopper = styled(MuiBasePopper)(({ theme }) => ({
  zIndex: 10,

  [`&[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
    top: 0,
    marginTop: '-0.71em',

    ['&::before']: {
      transformOrigin: '0 100%',
    },
  },

  [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
    bottom: 0,
    marginBottom: '-0.71em',

    ['&::before']: {
      transformOrigin: '100% 0',
    },
  },

  [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
    left: 0,
    marginLeft: '-0.71em',

    height: '1em',
    width: '0.71em',

    ['&::before']: {
      transformOrigin: '100% 100%',
    },
  },

  [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
    right: 0,
    marginRight: '-0.71em',
    height: '1em',
    width: '0.71em',

    ['&::before']: {
      transformOrigin: '0 0',
    },
  },

  [`& .${tooltipClasses.arrow}`]: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',

    ['&::before']: {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      background: theme.palette.paper.semi,
      boxShadow: `0 0 12px 0 ${theme.palette.paper.dark}`,
      transform: 'rotate(45deg)',
    },
  },
}));

export const UIKitTooltipPaper = styled(motion.div)(({ theme }) => ({
  background: theme.palette.paper.semi,
  boxShadow: `0 0 12px 0 ${theme.palette.paper.dark}`,
  padding: '16px 12px',
  borderRadius: 8,

  fontSize: 14,
  lineHeight: 1,
}));
