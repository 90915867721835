import { FC, forwardRef } from 'react';
import { clsx } from 'clsx';

import { useLoaded } from '~shared/lib/hooks';

import { Icon } from '../Icon';

import { UIKitAvatarFallback, UIKitAvatarImage, UIKitAvatarRoot, avatarClasses } from './styled';
import { AvatarProps } from './types';

export const Avatar: FC<AvatarProps> = forwardRef<unknown, AvatarProps>(
  (
    {
      size = 48,

      src,
      srcSet,
      referrerPolicy,
      crossOrigin,
      alt,

      children: childrenProp,

      className,

      ...props
    },
    ref
  ) => {
    const loaded = useLoaded({ src, srcSet, referrerPolicy, crossOrigin });

    const hasImg = src || srcSet;
    const hasImgNotFailing = hasImg && loaded !== 'error';

    let children;

    if (hasImgNotFailing) {
      children = (
        <UIKitAvatarImage
          src={src ?? undefined}
          srcSet={srcSet}
          referrerPolicy={referrerPolicy}
          crossOrigin={crossOrigin}
          alt={alt}
          className={avatarClasses.img}
        />
      );
    } else if (childrenProp) {
      children = childrenProp;
    } else {
      children = (
        <UIKitAvatarFallback className={avatarClasses.fallback}>
          <Icon name="calls" />
        </UIKitAvatarFallback>
      );
    }

    const computedClassName = clsx(
      avatarClasses.root,
      { [avatarClasses.hasSource]: hasImgNotFailing },
      className
    );

    return (
      <UIKitAvatarRoot className={computedClassName} size={size} ref={ref} {...props}>
        {children}
      </UIKitAvatarRoot>
    );
  }
);
