import { FC, Fragment, forwardRef, useMemo, useRef, useState } from 'react';
import { SelectProvider as MuiBaseSelectProvider, PopperChildrenProps, useSelect } from '@mui/base';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';
import { mergeRefs } from '~shared/lib/utils';

import { theme } from '~shared/lib/styles';

import {
  UIKitSelectContent,
  UIKitSelectListBox,
  UIKitSelectPlaceholder,
  UIKitSelectPopper,
  UIKitSelectRoot,
  selectClasses,
} from './styled';

import {
  defaultFormValueProvider,
  defaultOptionStringifier,
  defaultRenderSingleValue,
  getOptionsFromChildren,
  popperModifiers,
} from './utils';

import { SelectProps } from './types';

export const Select: FC<SelectProps> = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      placeholder = 'Select an option',
      error,
      value: valueProp,
      disabled: disabledProp,
      defaultValue,
      onChange,
      listboxId,
      getOptionAsString = defaultOptionStringifier,
      getSerializedValue = defaultFormValueProvider,
      renderValue = defaultRenderSingleValue,
      defaultListboxOpen,
      name,
      className,
      slots = {},
      slotProps = {},
      ...props
    },
    forwardedRef
  ) => {
    const [open, onOpenChange] = useState(defaultListboxOpen);
    const [buttonDefined, setButtonDefined] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const listboxRef = useRef<HTMLUListElement>(null);

    const options = getOptionsFromChildren(props.children);

    const {
      getButtonProps,
      getListboxProps,
      disabled,
      value,
      contextValue,
      buttonFocusVisible,
      buttonActive,
      ...selectProps
    } = useSelect({
      ...props,
      value: valueProp,
      disabled: disabledProp,
      defaultValue,
      buttonRef: mergeRefs([
        buttonRef,
        forwardedRef,
        (element) => setButtonDefined(element !== null),
      ]),
      onChange,
      listboxId,
      getOptionAsString,
      options: options as any, // todo: resolve
      onOpenChange,
      open,
      listboxRef,
    });

    const selectedOption = useMemo(() => {
      return options.find((o) => value === o.value) ?? null;
    }, [options, value]);

    const Root = slots.root ?? UIKitSelectRoot;

    return (
      <Fragment>
        <Root
          {...props}
          {...getButtonProps()}
          {...slotProps.root}
          className={clsx(
            selectClasses.root,
            {
              [selectClasses.error]: error,
              [selectClasses.focusVisible]: buttonFocusVisible,
              [selectClasses.active]: buttonActive,
              [selectClasses.disabled]: disabled,
            },
            className
          )}
        >
          <UIKitSelectContent className={selectClasses.content}>
            {renderValue(selectedOption) || (
              <UIKitSelectPlaceholder className={selectClasses.placeholder}>
                {placeholder}
              </UIKitSelectPlaceholder>
            )}
          </UIKitSelectContent>
          <Icon name="chevron-down" size={20} className={selectClasses.chevron} />
        </Root>

        {buttonDefined && (
          <UIKitSelectPopper
            open={selectProps.open}
            anchorEl={buttonRef.current}
            transition
            placement="bottom-start"
            modifiers={popperModifiers}
            sx={{ zIndex: theme.zIndex.modalOverlap }}
          >
            {({ TransitionProps }: PopperChildrenProps) => {
              const { in: open, onEnter, onExited } = TransitionProps ?? {};
              const animateVariant = open ? 'visible' : 'hidden';

              return (
                <UIKitSelectListBox
                  variants={motionListBoxVariants}
                  animate={animateVariant}
                  initial="hidden"
                  onAnimationStart={open ? onEnter : undefined}
                  onAnimationComplete={!open ? onExited : undefined}
                  {...getListboxProps()}
                >
                  <MuiBaseSelectProvider value={contextValue}>
                    {props.children}
                  </MuiBaseSelectProvider>
                </UIKitSelectListBox>
              );
            }}
          </UIKitSelectPopper>
        )}

        {name && <input type="hidden" name={name} value={getSerializedValue(selectedOption)} />}
      </Fragment>
    );
  }
);

const motionListBoxVariants = {
  hidden: { opacity: 0, y: -4, height: '10px', transition: { duration: 0.2, type: 'tween' } },
  visible: { opacity: 1, y: 0, height: '100%', transition: { duration: 0.2, type: 'tween' } },
};
