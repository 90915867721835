import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  PercentCrop,
  PixelCrop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';

import { Box, Button, Slider, SliderProps, Stack } from '~shared/ui';

import { createImage, fileToBase64, getCroppedImg } from '../../utils';

import { CropViewProps } from './types';
import { CroppedImage, Cropper } from './styled';

const defaultCrop = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  unit: '%',
} as const;

export const CropView: FC<CropViewProps> = ({ uploadedFile, onChooseFile, onSave, onDelete }) => {
  const [crop, setCrop] = useState<PercentCrop>(defaultCrop);
  const [rawImage, setRawImage] = useState<string>();

  const cropRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (uploadedFile) {
      fileToBase64(uploadedFile).then(async (imageString) => {
        const { width, height } = (await createImage(imageString)) as HTMLImageElement;

        const predefinedAspectCrop = makeAspectCrop({ unit: '%', height: 75 }, 1, width, height);
        const predefinedCenterCrop = centerCrop(predefinedAspectCrop, width, height);

        setCrop(predefinedCenterCrop);
        setRawImage(imageString);
      });
    }
  }, [uploadedFile]);

  const handleChangeCrop = useCallback<Required<SliderProps>['onChange']>((event, value) => {
    const size = value as number;
    const { width, height } = cropRef.current as HTMLImageElement;

    setCrop((crop) => {
      const handlePercentageOverflow = (number: number) => {
        return Math.max(Math.min(number, 100), 0);
      };

      const cropDiff = size - crop.height;

      const x = handlePercentageOverflow(crop.x - cropDiff / 2);
      const y = handlePercentageOverflow(crop.y - cropDiff / 2);

      return makeAspectCrop({ unit: '%', height: size, x, y }, 1, width, height);
    });
  }, []);

  const handleSave = useCallback(async () => {
    const { width, height } = (await createImage(rawImage as string)) as HTMLImageElement;

    getCroppedImg(rawImage as string, convertToPixelCrop(crop, width, height)).then(onSave);
  }, [crop, onSave, rawImage]);

  return (
    <Stack spacing={12 / 8} direction="row" alignItems="stretch" height={1} maxHeight={180}>
      <Box>
        <Cropper
          crop={crop}
          onChange={(pixelCrop: PixelCrop, percentCrop: PercentCrop) => setCrop(percentCrop)}
          minWidth={128}
          minHeight={128}
          aspect={1}
          keepSelection
        >
          <CroppedImage src={rawImage} ref={cropRef} />
        </Cropper>
      </Box>

      <Slider
        value={crop.height}
        min={25}
        max={100}
        step={1}
        orientation="vertical"
        onChange={handleChangeCrop}
        flexItem
      />

      <Stack spacing={12 / 8}>
        <Button size="sm" onClick={handleSave}>
          Save
        </Button>
        <Button size="sm" variant="outlined" onClick={onChooseFile}>
          Change
        </Button>
        <Button size="sm" variant="outlined" color="error" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};
