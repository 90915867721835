import { FC } from 'react';
import { clsx } from 'clsx';

import { MenuTabProps } from './types';
import { MenuTabIcon, MenuTabLabel, UIKitMenuTab, menuTabClasses } from './styled';

export const MenuTab: FC<MenuTabProps> = ({ icon, children, className, ...props }) => {
  return (
    <UIKitMenuTab {...props} className={clsx(menuTabClasses.root, className)}>
      <MenuTabIcon className={menuTabClasses.icon}>{icon}</MenuTabIcon>
      <MenuTabLabel className={menuTabClasses.label}>{children}</MenuTabLabel>
    </UIKitMenuTab>
  );
};
