import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { Button, Link } from '~shared/ui';
import { routes } from '~shared/config';

import { useNftCardModel } from '~entities/nft';

import { useUserCardsModel } from '~widgets/user-cards';

import { Root } from './styled';
import { UserCardsActionsProps } from './types';

export const UserCardsActions: FC<UserCardsActionsProps> = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const { isSelectionMode } = useNftCardModel();

  const {
    selectedCardsAmount,
    confirmSelectedCards,
    clearSelectedCards,
    cancelSelectCardToMerge,
    isConfirmAvailable,
    isConfirmForMergeAvailable,
    selectionModeType,
  } = useUserCardsModel();

  const buttonSize = isMobile ? 'sm' : 'lg';

  if (isSelectionMode) {
    switch (selectionModeType) {
      case 'bet':
        return (
          <Root>
            <Button size={buttonSize} variant="outlined" onClick={clearSelectedCards}>
              Clear all
            </Button>
            <Button size={buttonSize} onClick={confirmSelectedCards} disabled={!isConfirmAvailable}>
              Confirm ({selectedCardsAmount})
            </Button>
          </Root>
        );
      case 'merge':
        return (
          <Root>
            <Button size={buttonSize} variant="outlined" onClick={cancelSelectCardToMerge}>
              Cancel
            </Button>
            <Button
              size={buttonSize}
              onClick={confirmSelectedCards}
              disabled={!isConfirmForMergeAvailable}
            >
              Select ({selectedCardsAmount})
            </Button>
          </Root>
        );
    }
  }

  return (
    <Root>
      <Link to={routes.marketplace} fullWidth>
        <Button size={buttonSize} fullWidth>
          {t('Sidebar.buyCards')}
        </Button>
      </Link>
    </Root>
  );
};
