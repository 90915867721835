import { FC } from 'react';
import { clsx } from 'clsx';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';

import {
  UIKitButton,
  UIKitButtonContent,
  UIKitButtonInner,
  UIKitEndAdornment,
  UIKitStartAdornment,
  buttonClasses,
} from './styled';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = ({
  variant = 'contained',
  size = 'lg',
  color = 'primary',
  className,
  children,
  fullWidth,
  startAdornment,
  endAdornment,
  ...props
}) => {
  const computedClassName = clsx(
    buttonClasses.root,
    {
      [buttonClasses.fullWidth]: fullWidth,

      [buttonClasses.sm]: size === 'sm',
      [buttonClasses.md]: size === 'md',
      [buttonClasses.lg]: size === 'lg',

      [buttonClasses.outlined]: variant === 'outlined',
      [buttonClasses.contained]: variant === 'contained',
      [buttonClasses.text]: variant === 'text',

      [buttonClasses.primary]: color === 'primary',
      [buttonClasses.error]: color === 'error',
      [buttonClasses.success]: color === 'success',
    },
    className
  );

  return (
    <UIKitButton {...extendSxProp(props)} className={computedClassName}>
      <UIKitButtonInner className={clsx(buttonClasses.inner)}>
        {startAdornment && <UIKitStartAdornment>{startAdornment}</UIKitStartAdornment>}
        <UIKitButtonContent>{children}</UIKitButtonContent>
        {endAdornment && <UIKitEndAdornment>{endAdornment}</UIKitEndAdornment>}
      </UIKitButtonInner>
    </UIKitButton>
  );
};
