import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  price: yup
    .string()
    .required('Price is required')
    .matches(/^(\d+)$/, 'Fractional values are not allowed')
    .matches(/^(?!0)\d+$/, 'Minimum value is 1 MCN'),
});

export const AUCTION_MULTICALL_GAS_LIMIT = 100000;
