// Follow https://material.google.com/motion/duration-easing.html#duration-easing-natural-easing-curves
// to learn the context in which each easing should be used.
export const easing = {
  // This is the most common easing curve.
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  // Objects enter the screen at full velocity from off-screen and
  // slowly decelerate to a resting point.
  easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
  // Objects leave the screen at full velocity. They do not decelerate when off-screen.
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  // The sharp curve is used by objects that may return to the screen at any time.
  sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
};

// Follow https://m2.material.io/guidelines/motion/duration-easing.html#duration-easing-common-durations
// to learn when use what timing
export const duration = {
  shortest: 150,
  shorter: 200,
  short: 250,
  // most basic recommended timing
  standard: 300,
  // this is to be used in complex animations
  complex: 375,
  // recommended when something is entering screen
  enteringScreen: 225,
  // recommended when something is leaving screen
  leavingScreen: 195,
};

function formatMs(milliseconds: number) {
  return `${Math.round(milliseconds)}ms`;
}

interface CreateOptions
  extends Partial<{ duration: number | string; easing: string; delay: number | string }> {}

function getAutoHeightDuration(height: number) {
  if (!height) {
    return 0;
  }

  const constant = height / 36;

  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

const create = (props = ['all'], options: CreateOptions = {}) => {
  const {
    duration: durationOption = duration.shorter,
    easing: easingOption = easing.easeInOut,
    delay = 0,
    ...other
  } = options;

  if (process.env.NODE_ENV !== 'production') {
    const isString = (value: any): value is string => typeof value === 'string';
    // IE11 support, replace with Number.isNaN
    // eslint-disable-next-line no-restricted-globals
    const isNumber = (value: any): value is number => !isNaN(parseFloat(value));

    if (!isString(props) && !Array.isArray(props)) {
      console.error('Theme Transitions: Argument "props" must be a string or Array.');
    }

    if (!isNumber(durationOption) && !isString(durationOption)) {
      console.error(
        `Theme Transitions: Argument "duration" must be a number or a string but found ${durationOption}.`
      );
    }

    if (!isString(easingOption)) {
      console.error('Theme Transitions: Argument "easing" must be a string.');
    }

    if (!isNumber(delay) && !isString(delay)) {
      console.error('Theme Transitions: Argument "delay" must be a number or a string.');
    }

    if (Object.keys(other).length !== 0) {
      console.error(
        `Theme Transitions: Unrecognized argument(s) [${Object.keys(other).join(',')}].`
      );
    }
  }

  return (Array.isArray(props) ? props : [props])
    .map(
      (animatedProp) =>
        `${animatedProp} ${
          typeof durationOption === 'string' ? durationOption : formatMs(durationOption)
        } ${easingOption} ${typeof delay === 'string' ? delay : formatMs(delay)}`
    )
    .join(',');
};

export const transitions = {
  getAutoHeightDuration,
  create,
  easing: easing,
  duration: duration,
} as const;
