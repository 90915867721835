import { Children, FC, PropsWithChildren, useRef } from 'react';

import { useMediaQuery } from '~shared/lib/hooks';

import {
  GridContainer,
  HorizontalListRoot,
  HorizontalListSlide,
  HorizontalListSwiper,
} from './styled';

// TODO: Use shared horizontall swiper list
export const ProfileDashboardHorizontalList: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useMediaQuery();
  const ref = useRef(null);

  if (!isMobile) {
    return <GridContainer>{children}</GridContainer>;
  }

  return (
    <HorizontalListRoot>
      <HorizontalListSwiper ref={ref} spaceBetween={14} slidesPerView={1.4} navigation>
        {Children.map(children, (child) => (
          <HorizontalListSlide>{child}</HorizontalListSlide>
        ))}
      </HorizontalListSwiper>
    </HorizontalListRoot>
  );
};
