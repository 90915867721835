import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Notification,
  NotificationCaption,
  NotificationIconFactory,
  NotificationMessage,
} from '../../../factory';
import { formatTeamName } from '../../../../lib';

import { NotificationEventLiveProps } from './types';

export const NotificationEventLive = forwardRef<HTMLDivElement, NotificationEventLiveProps>(
  ({ notification, place }, ref) => {
    const { t } = useTranslation();

    return (
      <Notification
        date={notification.date}
        read={notification.read}
        place={place}
        ref={ref}
        icon={
          <NotificationIconFactory type="event" color="violet" sport={notification.payload.sport}>
            {t('Notifications.live').toUpperCase()}
          </NotificationIconFactory>
        }
      >
        <NotificationMessage>{t('Notifications.eventLive')}</NotificationMessage>
        <NotificationCaption>
          “{formatTeamName(notification.payload.homeTeam)} vs{' '}
          {formatTeamName(notification.payload.awayTeam)}“
        </NotificationCaption>
      </Notification>
    );
  }
);
