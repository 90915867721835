import { useIsAnyNftDialogOpen, useNftSelector } from '~entities/nft';

export const useBattleModel = (): {
  isOverlapBackdrop: boolean;
} => {
  const isAnyNftDialogOpen = useIsAnyNftDialogOpen();
  const { isDropZoneOpen } = useNftSelector();

  const overlapBackdrop = isDropZoneOpen && !isAnyNftDialogOpen;

  return {
    isOverlapBackdrop: overlapBackdrop,
  };
};
