import { styled } from '~shared/lib/styles';

import { Box } from '../../../layout';

export const verticalPanelClasses = {
  root: 'VerticalPanel-root',
  list: 'VerticalPanel-list',
  heading: 'VerticalPanel-heading',
};

export const UIKitVerticalPanelRoot = styled(Box, { name: verticalPanelClasses.root })({
  display: 'flex',
  flexDirection: 'column',
});

export const UIKitVerticalPanelList = styled(Box, { name: verticalPanelClasses.list })({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: 17,
});

export const UIKitVerticalPanelHeading = styled(Box, { name: verticalPanelClasses.heading })({
  marginBottom: 30,
});
