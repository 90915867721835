import { BattleShellSize, BattleShellVariant } from '~entities/battle';

export const BattleShellSizeMap: Record<
  BattleShellVariant,
  Record<BattleShellSize, { width: number | null; height: number }>
> = {
  compact: {
    sm: { height: 180, width: null },
    md: { height: 180, width: null },
    lg: { height: 180, width: null },
  },
  line: {
    sm: { height: 70, width: null },
    md: { height: 88, width: null },
    lg: { height: 94, width: null },
  },
  square: {
    sm: { width: 288, height: 178 },
    md: { width: 320, height: 200 },
    lg: { width: 350, height: 222 },
  },
} as const;
