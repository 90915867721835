import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiGetLeaderboardMappedData } from '~shared/api';

import { InitialStateType } from './types';

const initialState: InitialStateType = {
  showLine: true,
  leaderboard: null,
};

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setShowLine: (state, { payload }: PayloadAction<boolean>) => {
      state.showLine = payload;
    },
    setLeaderboard: (state, { payload }: PayloadAction<ApiGetLeaderboardMappedData>) => {
      state.leaderboard = payload;
    },
  },
});

export const leaderBoardReducer = leaderboardSlice.reducer;
export const leaderBoardActions = leaderboardSlice.actions;
