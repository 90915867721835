import { Fragment } from 'react';
import { useWeb3React } from '@web3-react/core';

import { ConnectWalletOptions, ConnectYourWallet } from '~entities/wallet';
import { useViewerSelector } from '~entities/viewer';

import { UserCards } from '~widgets/user-cards';

import { CardsPageRoot } from './styled';

export const CardsPage = () => {
  const { account } = useWeb3React();
  const { wallet, authorized } = useViewerSelector();
  const isWalletBoundToEmail = Boolean(wallet);

  const isConnected = !!account;

  return (
    <CardsPageRoot>
      {!isConnected && (
        <Fragment>
          {authorized && !isWalletBoundToEmail ? <ConnectYourWallet /> : <ConnectWalletOptions />}
        </Fragment>
      )}

      {isConnected && <UserCards />}
    </CardsPageRoot>
  );
};
