import { styled } from '~shared/lib/styles';

import { TextField } from '../TextField';

export const UnlinkButton = styled('button', { name: 'UnlinkButton' })(({ theme }) => ({
  background: 'none',
  border: 'none',
  color: theme.palette.primary.main,

  ['&:hover:not([disabled])']: {
    textDecoration: 'underline',
  },
}));

export const StyledTextField = styled(TextField)({
  flex: 1,
});
