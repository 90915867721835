export const ViewerRoutes = {
  // Default set of avatars
  defaultAvatars: () => '/user/avatars/default',

  // Post custom user avatar
  userAvatar: () => '/user/avatar',

  // Update nickname
  setNickname: () => '/user/nickname',

  // Get viewer profile data
  profile: () => '/profile',

  // Password restoration
  recoveryPassword: () => '/recovery_password',
  setPassword: () => '/set_password', // Set password on recovery

  // Update password in profile
  updatePassword: () => '/update_password',

  checkNickname: () => '/check_nickname',

  // Bind wallet address to email
  setWallet: () => '/set_wallet',

  // Set that user passed onboarding
  setOnboardingPassed: () => '/user/onboarding',
};
