import { styled } from '~shared/lib/styles';
import { palette } from '~shared/lib/styles/palette';

import { Box } from '../../layout';
import { Typography } from '../Typography';

import { ChipColor } from './types';

const CHIP_COLOR: Record<ChipColor, string> = {
  primary: palette.primary.darker,
  secondary: palette.violet.main,
  success: palette.success.main,
};

type ChipRootProps = {
  color: ChipColor;
};

export const ChipRoot = styled(Box, { name: 'Chip', slot: 'root' })<ChipRootProps>(({ color }) => ({
  padding: '1px 6px',
  backgroundColor: CHIP_COLOR[color],
  boxShadow: '0px 4.19688px 4.19688px rgba(0, 0, 0, 0.25)',
  borderRadius: '12.5px',
  display: 'inline-block',
  margin: 0,
}));

export const ChipLabel = styled(Typography, { name: 'Chip', slot: 'label' })(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
