import { FC } from 'react';

import { theme } from '~shared/lib/styles';
import { Avatar, Divider, Drawer, Typography } from '~shared/ui';

import {
  LeaderboardMobileAvatarContainer,
  LeaderboardMobileInfoContainer,
  LeaderboardMobileInfoRow,
  LeaderboardMobileNicknameContainer,
  LeaderboardRowName,
} from './styled';
import { LeaderboardDrawerProps } from './types';

export const LeaderboardDrawer: FC<LeaderboardDrawerProps> = ({ open, onClose, leaderboard }) => {
  return (
    <Drawer open={open} onClose={onClose}>
      <LeaderboardMobileNicknameContainer>
        <LeaderboardMobileAvatarContainer>
          <Avatar size={32} marginRight={1} src={leaderboard.avatar} />
          <LeaderboardRowName>{leaderboard.nickname}</LeaderboardRowName>
        </LeaderboardMobileAvatarContainer>
        <Typography variant="h2">1</Typography>
      </LeaderboardMobileNicknameContainer>
      <Divider />
      <LeaderboardMobileInfoContainer>
        <LeaderboardMobileInfoRow>
          <Typography color={theme.palette.grey.A50} fontSize={16}>
            Accuracy
          </Typography>
          <Typography fontSize={16}>{leaderboard.winRate}%</Typography>
        </LeaderboardMobileInfoRow>
        <LeaderboardMobileInfoRow>
          <Typography color={theme.palette.grey.A50} fontSize={16}>
            Votes
          </Typography>
          <Typography fontSize={16}>{leaderboard.votes}</Typography>
        </LeaderboardMobileInfoRow>
        <LeaderboardMobileInfoRow>
          <Typography color={theme.palette.grey.A50} fontSize={16}>
            MCN
          </Typography>
          <Typography fontSize={16}>{leaderboard.coins}</Typography>
        </LeaderboardMobileInfoRow>
      </LeaderboardMobileInfoContainer>
    </Drawer>
  );
};
