import { useCallback, useEffect } from 'react';

import { useViewerModel } from '~entities/viewer';
import { useAuthModel } from '~widgets/auth';

export const useViewerInitialization = () => {
  const {
    checkSessionToken,
    sessionTokenChecked,
    authorized,
    fetchProfile,
    defaultAvatars,
    fetchDefaultAvatars,
  } = useViewerModel();

  const { onOpen: openAuth, setStep: setAuthStep } = useAuthModel();

  const processIdentification = useCallback(() => {
    setAuthStep((steps) => steps.Identification);
    openAuth();
  }, [openAuth, setAuthStep]);

  useEffect(() => {
    if (!sessionTokenChecked) {
      checkSessionToken().then(({ authorized }) => {
        if (authorized) {
          fetchProfile();
        }
      });
    }
  }, [
    checkSessionToken,
    fetchProfile,
    processIdentification,
    openAuth,
    sessionTokenChecked,
    setAuthStep,
  ]);

  useEffect(() => {
    if (!authorized && sessionTokenChecked) {
      processIdentification();
    }
  }, [authorized, processIdentification, sessionTokenChecked]);

  useEffect(() => {
    if (authorized) {
      fetchProfile();
    }
  }, [authorized, fetchProfile]);

  useEffect(() => {
    if (authorized && defaultAvatars === null) {
      fetchDefaultAvatars();
    }
  }, [authorized, defaultAvatars, fetchDefaultAvatars]);
};
