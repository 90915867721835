import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '~shared/ui';
import { routes } from '~shared/config';

export const PasswordChanged: FC = () => {
  return (
    <Fragment>
      <Typography variant="h1" align="center" gutterBottom>
        Password changed
      </Typography>

      <Stack spacing={24 / 8}>
        <Typography color="secondary" textAlign="center">
          Password successfully changed
        </Typography>

        <Link to={routes.index}>
          <Button fullWidth>Go to Sign In</Button>
        </Link>
      </Stack>
    </Fragment>
  );
};
