import { Language } from '~shared/config/i18n';
import { SlideProps } from '~shared/ui';
import { routes } from '~shared/config';

import { BattleEventTypeTab, EVENT_TYPE_TAB_SEARCH_PARAM_KEY } from '~entities/battle';
import { MARKETPLACE_PAGE_TAB_SEARCH_PARAM_KEY, MarketplacePageTab } from '~entities/marketplace';

export const PathsMap: Record<Language, string> = {
  [Language.ES]: process.env.PUBLIC_URL + '/assets/banners/es/',
  [Language.EN]: process.env.PUBLIC_URL + '/assets/banners/en/',
};

// todo: make more representative
const auctionLink = `${routes.marketplace}?${new URLSearchParams([
  [MARKETPLACE_PAGE_TAB_SEARCH_PARAM_KEY, MarketplacePageTab.Auction.toString()],
]).toString()}`;

// todo: make more representative
const callsLink = `${routes.index}?${new URLSearchParams([
  [EVENT_TYPE_TAB_SEARCH_PARAM_KEY, BattleEventTypeTab.Calls],
]).toString()}`;

export const AssetMap: Record<string, Record<Language, Array<SlideProps> | null>> = {
  tournaments: {
    [Language.EN]: [
      { src: 'tournament-1.png', to: null },
      { src: 'tournament-2.png', to: null },
    ],
    [Language.ES]: [{ src: 'tournament-1.png', to: null }],
  },
  gasless: {
    [Language.EN]: [{ src: 'gasless.png', to: routes.ranking }],
    [Language.ES]: [{ src: 'spain-locale.png', to: null }],
  },
  spainLocale: {
    [Language.EN]: [{ src: 'spain-locale.png', to: null }],
    [Language.ES]: [{ src: 'gasless.png', to: routes.ranking }],
  },
  welcomeToTestnet: {
    [Language.EN]: [{ src: 'welcome-to-testnet.png', to: 'https://discord.com/invite/WR8MvhP3uV' }],
    [Language.ES]: [{ src: 'welcome-to-testnet.png', to: 'https://discord.com/invite/WR8MvhP3uV' }],
  },
  joinSocial: {
    [Language.EN]: [
      {
        src: 'join-telegram.png',
        to: 'https://t.me/MaincardOfficial',
        linkProps: { target: '_blank', rel: 'noreferrer' },
      },
      {
        src: 'join-discord.png',
        to: 'https://discord.com/invite/WR8MvhP3uV',
        linkProps: { target: '_blank', rel: 'noreferrer' },
      },
    ],
    [Language.ES]: [
      {
        src: 'join-telegram.png',
        to: 'https://t.me/MaincardOfficial',
        linkProps: { target: '_blank', rel: 'noreferrer' },
      },
      {
        src: 'join-discord.png',
        to: 'https://discord.com/invite/WR8MvhP3uV',
        linkProps: { target: '_blank', rel: 'noreferrer' },
      },
    ],
  },
  marketplace: {
    [Language.EN]: [
      { src: 'marketplace-1.png', to: routes.marketplace },
      { src: 'marketplace-2.png', to: routes.marketplace },
    ],
    [Language.ES]: [{ src: 'marketplace-1.png', to: routes.marketplace }],
  },
  calls: {
    [Language.EN]: [{ src: 'calls.png', to: callsLink }],
    [Language.ES]: [{ src: 'calls.png', to: callsLink }],
  },
  auction: {
    [Language.EN]: [{ src: 'auction.png', to: auctionLink }],
    [Language.ES]: [{ src: 'auction.png', to: auctionLink }],
  },
};
