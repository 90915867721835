import { Button as MuiBaseButton, buttonClasses as muiButtonClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const buttonIconClasses = {
  ...muiButtonClasses,
  contained: 'MuiButton-contained',
  outlined: 'MuiButton-outlined',
  plain: 'MuiButton-plain',
  square: 'MuiButton-square',

  icon: 'MuiButton-icon',
};

export const UIKitButtonIcon = styled(MuiBaseButton, { name: 'ButtonIcon' })(({ theme }) => ({
  borderRadius: '100%',
  flexShrink: 0,
  color: theme.palette.text.primary,
  border: 'none',
  padding: 0,
  display: 'flex',
  overflow: 'hidden',
  appearance: 'none',

  transition: theme.transitions.create(['background', 'box-shadow', 'color']),

  [`&.${buttonIconClasses.disabled}`]: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  [`&.${buttonIconClasses.contained}`]: {
    background: theme.palette.primary.main,

    [`&:hover:not(.${buttonIconClasses.disabled})`]: {
      boxShadow: '0px 0px 8px 0px #42BAFF',
    },
  },

  [`&.${buttonIconClasses.outlined}`]: {
    background: 'none',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    padding: 9,

    [`&:hover:not(.${buttonIconClasses.disabled})`]: {
      background: theme.palette.primary.light,
    },
  },

  [`&.${buttonIconClasses.plain}`]: {
    background: 'none',
    border: 'none',
    padding: 0,
    color: theme.palette.text.primary,

    [`&:hover:not(.${buttonIconClasses.disabled})`]: {
      color: theme.palette.primary.light,
    },
  },

  [`&.${buttonIconClasses.square}`]: {
    padding: 10,
    background: theme.palette.grey.A10,
    borderRadius: 8,

    [`&:hover:not(.${buttonIconClasses.disabled})`]: {
      background: theme.palette.grey.A20,
    },
  },
}));
