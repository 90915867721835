import { cloneElement, forwardRef, isValidElement, useContext } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { BattleShellContext } from '../../../BattleShellContext';

import {
  BattleShellLineGrid,
  BattleShellLineGridCenter,
  BattleShellLineRightSide,
  BattleShellLineRoot,
  BattleShellLineStatus,
  battleShellLineClasses,
} from './styled';

import { BattleShellLineProps } from './types';

export const BattleShellLine = forwardRef<HTMLDivElement, BattleShellLineProps>(
  (
    {
      status,
      date,
      leagueLogo,
      call,

      homeTeam,
      awayTeam,
      versus,

      ...rootProps
    },
    ref
  ) => {
    const { size } = useContext(BattleShellContext);

    return (
      <BattleShellLineRoot
        {...rootProps}
        className={clsx(
          battleShellLineClasses.root,
          {
            [battleShellLineClasses.sm]: size === 'sm',
            [battleShellLineClasses.md]: size === 'md',
            [battleShellLineClasses.lg]: size === 'lg',

            [battleShellLineClasses.call]: call,
          },
          rootProps.className
        )}
        ref={ref}
      >
        <BattleShellLineStatus
          className={clsx(battleShellLineClasses.status, {
            [battleShellLineClasses.statusDefault]: status === 'future',
            [battleShellLineClasses.statusWin]: status === 'win',
            [battleShellLineClasses.statusLose]: status === 'lose',
          })}
        />

        <BattleShellLineGrid className={battleShellLineClasses.grid}>
          <div>{cloneElement(date, { size: size === 'sm' ? 'md' : 'lg' })}</div>

          <BattleShellLineGridCenter className={battleShellLineClasses.gridCenter}>
            {isValidElement(homeTeam) && cloneElement(homeTeam, { direction: 'fromLeft' })}

            {versus}

            {isValidElement(awayTeam) && cloneElement(awayTeam, { direction: 'fromRight' })}
          </BattleShellLineGridCenter>

          <BattleShellLineRightSide>
            {leagueLogo ? <Icon name={leagueLogo} size={52} /> : <span />}
          </BattleShellLineRightSide>
        </BattleShellLineGrid>
      </BattleShellLineRoot>
    );
  }
);
