import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { isNftAvailableForSell } from '~entities/nft';

import { SellCard } from '~features/nft';

import { NftCardDetailedInfoTab, useNftCardDetailedInfoModel } from '../../../model';

import { NftCardDetailedInfoCalls } from './NftCardDetailedInfoCalls';
import { NftCardDetailedInfoTabs } from './NftCardDetailedInfoTabs';
import { NftCardDetailedInfoContentHeader } from './NftCardDetailedInfoContentHeader';
import { NftCardDetailedInfoBattles } from './NftCardDetailedInfoBattles';

import { ContentContainer, TabContentContainer } from './styled';

export const NftCardDetailedInfoContent = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const {
    nft,
    maxNftLives,
    maxWinStreak,
    nickname,
    avatarUrl,
    nftStats,
    nftBetsQuery,
    nftCallsQuery,
    tab,
    viewMode,
    handleClose,
    handleTabChange,
  } = useNftCardDetailedInfoModel();

  if (!nft) {
    return (
      <Box
        sx={{
          minHeight: isMobile ? '100%' : '523px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={32} />
      </Box>
    );
  }

  const isAbleToSell = isNftAvailableForSell(nft);

  const tabs: Record<NftCardDetailedInfoTab, ReactNode> = {
    [NftCardDetailedInfoTab.Battles]: <NftCardDetailedInfoBattles nftBetsQuery={nftBetsQuery} />,
    [NftCardDetailedInfoTab.Calls]: <NftCardDetailedInfoCalls nftCallsQuery={nftCallsQuery} />,
    [NftCardDetailedInfoTab.Transactions]: null,
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <NftCardDetailedInfoContentHeader
        nft={nft}
        maxNftLives={maxNftLives}
        maxWinStreak={maxWinStreak}
        nickname={nickname}
        avatarUrl={avatarUrl}
        nftStats={nftStats}
        isNftAvailableForSell={isAbleToSell}
        viewMode={viewMode}
        onChange={handleTabChange}
        onClose={handleClose}
      />

      <ContentContainer>
        {!isMobile && <NftCardDetailedInfoTabs onChange={handleTabChange} />}

        <TabContentContainer>{tabs[tab]}</TabContentContainer>

        {isMobile && !viewMode && isAbleToSell && (
          <SellCard nft={nft} mt="10px">
            {t('Other.sellCard')}
          </SellCard>
        )}
      </ContentContainer>
    </Box>
  );
};
