import { styled } from '~shared/lib/styles';

import { BattleShellSizeMap } from '~entities/battle';

import { BattleShell } from '../../BattleShell';

export const battleShellSquareClasses = {
  root: 'BattleShellSquare-root',
  top: 'BattleShellSquare-top',
  league: 'BattleShellSquare-league',
  center: 'BattleShellSquare-center',

  statusDefault: 'BattleShellSquare-statusDefault',
  statusWin: 'BattleShellSquare-statusWin',
  statusLose: 'BattleShellSquare-statusLose',

  sm: 'BattleShellSquare-sm',
  md: 'BattleShellSquare-md',
  lg: 'BattleShellSquare-lg',
};

export const BattleShellSquareRoot = styled(BattleShell)(({ theme }) => ({
  width: '100%',
  background: theme.palette.paper.main,
  zIndex: 0,

  position: 'relative',
  overflow: 'hidden',
  borderRadius: 12,

  [`&.${battleShellSquareClasses.sm}`]: {
    minWidth: BattleShellSizeMap.square.sm.width,
    maxWidth: BattleShellSizeMap.square.sm.width,
    minHeight: BattleShellSizeMap.square.sm.height,
    maxHeight: BattleShellSizeMap.square.sm.height,
    padding: '8px 18px',

    [`& .${battleShellSquareClasses.top}`]: {
      alignItems: 'flex-start',
      marginBottom: 18,

      [`& .${battleShellSquareClasses.league}`]: {
        marginRight: -10,
      },
    },
  },

  [`&.${battleShellSquareClasses.md}`]: {
    minWidth: BattleShellSizeMap.square.md.width,
    maxWidth: BattleShellSizeMap.square.md.width,
    minHeight: BattleShellSizeMap.square.md.height,
    maxHeight: BattleShellSizeMap.square.md.height,
    padding: '16px 16px 20px',

    [`& .${battleShellSquareClasses.top}`]: {
      marginBottom: 20,

      [`& .${battleShellSquareClasses.league}`]: {
        marginRight: -8,
        marginTop: -8,
      },
    },
  },

  [`&.${battleShellSquareClasses.lg}`]: {
    minWidth: BattleShellSizeMap.square.lg.width,
    maxWidth: BattleShellSizeMap.square.lg.width,
    minHeight: BattleShellSizeMap.square.lg.height,
    maxHeight: BattleShellSizeMap.square.lg.height,
    padding: '16px 26px 24px',

    [`& .${battleShellSquareClasses.top}`]: {
      marginBottom: 28,

      [`& .${battleShellSquareClasses.league}`]: {
        marginRight: -14,
        marginTop: -8,
      },
    },
  },
}));

export const BattleShellSquareContent = styled('div')({
  zIndex: 1,
});

export const BattleShellSquareBackground = styled('div')<{ src: string }>(({ src }) => ({
  background: `url(${src})`,
  backgroundSize: 'cover',
  inset: 0,
  position: 'absolute',
  opacity: 0.45,
  zIndex: -1,
}));

export const BattleShellSquareStatus = styled('div')(({ theme }) => ({
  width: 2,
  position: 'absolute',
  inset: '0 auto 0 0',
  height: '100%',
  background: theme.palette.grey.A5,

  [`&.${battleShellSquareClasses.statusLose}`]: {
    background: theme.palette.error.main,
  },

  [`&.${battleShellSquareClasses.statusWin}`]: {
    background: theme.palette.primary.main,
  },
}));

export const BattleShellSquareTop = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const BattleShellSquareCenter = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 64px 1fr',
}));
