import { forwardRef } from 'react';
import { ClickAwayListener } from '@mui/base';

import { UIKitDrawerPaper, UIKitDrawerScrollPaper, drawerClasses } from './styled';
import { DrawerPaperProps } from './types';

export const DrawerPaper = forwardRef<HTMLDivElement, DrawerPaperProps>(
  ({ in: open, onEnter, onExited, onClickAway, children, ...props }, ref) => {
    return (
      <ClickAwayListener onClickAway={onClickAway} touchEvent={false}>
        <UIKitDrawerScrollPaper
          ref={ref}
          variants={paperMotionVariants}
          animate={open ? AnimationVariant.Visible : AnimationVariant.Hidden}
          initial={AnimationVariant.Hidden}
          onAnimationStart={open ? onEnter : undefined}
          onAnimationComplete={!open ? onExited : undefined}
          tabIndex={-1}
        >
          <UIKitDrawerPaper className={drawerClasses.paper} {...props}>
            {children}
          </UIKitDrawerPaper>
        </UIKitDrawerScrollPaper>
      </ClickAwayListener>
    );
  }
);

const AnimationVariant = {
  Visible: 'visible',
  Hidden: 'hidden',
};

const transition = { duration: 0.15, type: 'tween' };

const paperMotionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, y: 20, transition },
  [AnimationVariant.Visible]: { opacity: 1, y: 0, transition },
};
