import { ChangeEvent, FC, useState } from 'react';

import { Typography } from '../../dataDisplay';
import { Checkbox } from '../../controls';
import { Stack } from '../../layout';

import { DontShowWindowInFutureProps } from './types';

export const DontShowWindowInFuture: FC<DontShowWindowInFutureProps> = ({ onChange }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, value: boolean) => {
    onChange(value);
    setChecked(value);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={12 / 8}>
      <Checkbox checked={checked} onChange={handleChange} />
      <Typography color="secondary">Don't show this window in future</Typography>
    </Stack>
  );
};
