import { customScrollbar, styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

import { NFT_CARD_SIZE_VALUES } from '~entities/nft';

export const UserCardsListVirtuosoGridScroller = styled('div', {
  name: 'UserCardsList',
  slot: 'virtuosoGridScroller',
})(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: -16,
  marginRight: -16,

  ...customScrollbar,

  ['& > div']: {
    width: 'calc(100% - 32px) !important',
  },
}));

export const UserCardsListPaddingSpace = styled('div', {
  name: 'UserCardsList',
  slot: 'paddingSpace',
})(() => ({
  paddingTop: 16,
}));

export const UserCardsListGrid = styled(Box, {
  name: 'UserCardsList',
  slot: 'grid',
})(({ theme }) => ({
  display: 'grid',
  alignContent: 'baseline',
  justifyContent: 'space-between',

  gridTemplateColumns: `repeat(3, ${NFT_CARD_SIZE_VALUES.md.width})`,
  gridAutoRows: `${NFT_CARD_SIZE_VALUES.md.height}`,
  rowGap: 20,

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: `repeat(3, ${NFT_CARD_SIZE_VALUES.lg.width})`,
    gridAutoRows: `${NFT_CARD_SIZE_VALUES.lg.height}`,
  },
}));

export const UserCardsListShimmerContainer = styled(UserCardsListGrid, {
  name: 'UserCardsList',
  slot: 'shimmerContainer',
})(() => ({
  paddingBottom: 16,
}));

export const HiddenNftContainer = styled(Box, {
  name: 'UserCardsList',
  slot: 'hiddenNftContainer',
})({
  position: 'relative',

  ['&:after']: {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: 10,
    opacity: 0.9,
    zIndex: 1,
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/nfts/nft-dragged.png)`,
    backgroundSize: 'cover',
  },
});
