import { FC } from 'react';

import { Box, Icon, Typography } from '~shared/ui';

import { getNftUri } from '../../lib';

import { StaticNftCardProps } from './types';
import { StaticNftImage, StaticNftRoot, staticNftClasses } from './styled';

export const StaticNftCard: FC<StaticNftCardProps> = ({
  tokenId,
  height = 50,
  blockedForEvent,
  ...props
}) => {
  const nftUri = getNftUri(tokenId);

  return (
    <StaticNftRoot className={staticNftClasses.root} height={height} fontSize={height} {...props}>
      <StaticNftImage src={nftUri} />

      {/* todo: refactor */}
      {blockedForEvent && (
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          bgcolor="rgba(0,0,0,.5)"
          // todo: refactor
          sx={{ inset: 0, color: '#EFEFEF' }}
        >
          <Icon name="lock" size={18} />
          <Typography component="div" fontSize={12} align="center" mt={1}>
            Blocked <Typography whiteSpace="nowrap">for event</Typography>
          </Typography>
        </Box>
      )}
    </StaticNftRoot>
  );
};
