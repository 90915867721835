import { mapBattleTeam } from '../battle';

import { ApiGetBetsBetMappedData, ApiGetBetsBetRawData } from './types';

export const mapGetBets = (
  data: Array<Array<ApiGetBetsBetRawData>>
): Array<ApiGetBetsBetMappedData> => {
  return data
    .flat()
    .map(({ date, tokenIds, homeTeam, awayTeam, sport, ...bet }) => {
      return {
        ...bet,
        tokenIds: tokenIds,
        date: new Date(date),
        homeTeam: mapBattleTeam(homeTeam, sport),
        awayTeam: mapBattleTeam(awayTeam, sport),
        sport: sport,
      };
    })
    .sort((a, b) => getTimeStamp(b.date) - getTimeStamp(a.date));
};

const getTimeStamp = (date: Date) => date.getTime();
