import { styled } from '~shared/lib/styles';
import { avatarClasses } from '~shared/ui';

export const NotificationUserRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',

  [`& .${avatarClasses.root}`]: {
    marginRight: 4,
  },
}));
