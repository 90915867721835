import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const UserProfileRowRoot = styled(Box)({
  background: '#282828',
  borderRadius: 8,
  padding: '14px 10px',

  display: 'flex',
  alignItems: 'center',

  textAlign: 'start',
});

export const UserProfileRowContent = styled(Box)({
  marginLeft: 10,
  display: 'grid',
  gap: 6,
});
