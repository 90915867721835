import { noScrollbar, styled } from '~shared/lib/styles';
import { DraggableHorizontalList, Link } from '~shared/ui';

export const BattleHorizontalListRoot = styled('div')(() => ({
  display: 'flex',
}));

export const BattleHorizontalListContainer = styled(DraggableHorizontalList)({
  display: 'flex',
  flexWrap: 'nowrap',
  flex: 1,
  flexDirection: 'row',
  userSelect: 'none',

  marginTop: -14,
  marginBottom: -14,
  marginLeft: -6,

  paddingTop: 14,
  paddingBottom: 14,
  paddingLeft: 6,

  // Smooth edge shadow when Battle appears partially
  borderTopLeftRadius: 24,
  borderBottomLeftRadius: 24,

  // Repeat <Container /> padding for scroll
  paddingRight: 30,
  // marginRight: -30,

  ...noScrollbar,

  ['& > *:not(:last-child)']: {
    marginRight: 12,
    flexShrink: 0,
  },
});

export const BattleHorizontalListLabel = styled('div')(({ theme }) => ({
  writingMode: 'vertical-lr',
  transform: 'rotate(180deg)',
  position: 'relative',
  zIndex: 2,

  textAlign: 'center',
  padding: '0 6px',
  borderRadius: 12,
  background: '#242424', // theme.palette.grey.A10,
  marginRight: 16,

  fontSize: 13,
  fontWeight: 400,
  lineHeight: 1,

  [theme.breakpoints.down('sm')]: {
    marginRight: 12,
  },
}));

export const BattleHorizontalListNavigation = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  background: theme.palette.paper.dark,
  borderRadius: 12,

  padding: 24,

  height: '100%',
  minWidth: 256,
  gap: 10,

  textAlign: 'center',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  transition: theme.transitions.create('background'),

  ['&:hover']: {
    background: theme.palette.paper.semi,
  },

  fontSize: 14,
  lineHeight: 1,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 12,
    lineHeight: 14 / 12,
  },

  [theme.breakpoints.down('desktop')]: {
    fontSize: 13,
    lineHeight: 14 / 13,
  },
}));
