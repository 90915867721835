import { FC } from 'react';

import { DialogProps } from '~shared/ui';
import { ApiCommonSportEnum } from '~shared/api';

import { useEventModel } from '~entities/event';

import { EventDialogMobileRoot } from './styled';

export const EventDialogMobile: FC<DialogProps> = ({ children, open }) => {
  const { event } = useEventModel();

  return (
    <EventDialogMobileRoot $sport={event?.sport ?? ApiCommonSportEnum.Football} open={open}>
      {children}
    </EventDialogMobileRoot>
  );
};
