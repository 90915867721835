import { FC, Fragment, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, DialogTitle, FormWithCheckNickname, NicknameField, Stack } from '~shared/ui';

import { useAuthModel } from '~widgets/auth';

type Form = FormWithCheckNickname<{
  nickname: string;
}>;

export const IntroduceYourself: FC = () => {
  const { t } = useTranslation();

  const form = useForm<FormWithCheckNickname<Form>>({ mode: 'onChange' });
  const { submitNickname } = useAuthModel();

  const handleSubmit: SubmitHandler<Form> = useCallback(
    ({ nickname }) => {
      submitNickname(nickname);
    },
    [submitNickname]
  );

  const checking = form.watch(NicknameField.checkingNicknameFieldName);

  return (
    <Fragment>
      <DialogTitle>{t('Other.introduce')}</DialogTitle>

      <FormProvider {...form}>
        <Stack spacing={24 / 8} component="form" onSubmit={form.handleSubmit(handleSubmit)}>
          <NicknameField autoFocus />

          <Button type="submit" disabled={checking}>
            {t('Other.continue')}
          </Button>
        </Stack>
      </FormProvider>
    </Fragment>
  );
};
