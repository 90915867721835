import { useQuery } from 'react-query';

import { ApiCommonLeague, ApiCommonSport, getCalls } from '~shared/api';

interface UseCallsListQueryParams {
  address?: string;
  sport?: Array<ApiCommonSport>;
  league?: ApiCommonLeague;
}

export const useCallsListQuery = ({ sport, address, league }: UseCallsListQueryParams) => {
  const { data, isLoading } = useQuery(
    [address, ...(sport ?? []), league],
    () => getCalls({ userAddress: address, sport, league }),
    {
      enabled: typeof address !== 'undefined' ? !!address : true,
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    }
  );

  const isAddressEmpty = typeof address !== 'undefined' && address.length === 0;

  return {
    list: data,
    isEmptyList: (isLoading ? false : data?.length === 0) || isAddressEmpty,
    isLoading,
  };
};

const STALE_TIME = 60 * 1000;
const CACHE_TIME = 0;
