import { clsx } from 'clsx';
import { Fragment, MouseEvent, forwardRef } from 'react';

import { Avatar, Icon, Typography } from '~shared/ui';

import { useSwitcher } from '~shared/lib/hooks';

import {
  LeaderboardArrowContainer,
  LeaderboardRowColumn,
  LeaderboardRowColumnInfo,
  LeaderboardRowName,
} from '../styled';

import { LeaderboardDrawer } from '../LeaderboardDrawer';

import { LeaderboardContainer, leaderboardRowClasses } from './styled';
import { LeaderBoardViewerRowState } from './types';

export const LeaderboardViewerRow = forwardRef<any, LeaderBoardViewerRowState>(
  ({ leaderboard, isViewer, isMobile }, ref) => {
    const drawerOpen = useSwitcher();

    const serializedClassName = clsx({ [leaderboardRowClasses.viewer]: isViewer });

    const handleCloseDrawer = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      drawerOpen.switchOff();
    };

    if (isMobile) {
      return (
        <Fragment>
          <LeaderboardDrawer
            open={drawerOpen.value}
            leaderboard={leaderboard}
            onClose={handleCloseDrawer}
          />
          <LeaderboardContainer ref={ref} onClick={drawerOpen.switchOn}>
            <LeaderboardRowColumnInfo>
              <Typography variant="h2">{leaderboard.place}</Typography>
              <Avatar size={32} marginRight="12px" marginLeft="14px" src={leaderboard.avatar} />
              <LeaderboardRowName>{leaderboard.nickname}</LeaderboardRowName>
            </LeaderboardRowColumnInfo>
            <LeaderboardArrowContainer>
              <Icon name="arrow-right" />
            </LeaderboardArrowContainer>
          </LeaderboardContainer>
        </Fragment>
      );
    }

    return (
      <LeaderboardContainer className={serializedClassName} ref={ref}>
        <LeaderboardRowColumnInfo>
          <Typography variant="h2">{leaderboard.place}</Typography>
          <Avatar size={32} marginRight="12px" marginLeft="14px" src={leaderboard.avatar} />
          <LeaderboardRowName>{leaderboard.nickname}</LeaderboardRowName>
        </LeaderboardRowColumnInfo>

        <LeaderboardRowColumn>{leaderboard.winRate}%</LeaderboardRowColumn>
        <LeaderboardRowColumn>{leaderboard.votes}</LeaderboardRowColumn>
        <LeaderboardRowColumn>{leaderboard.coins}</LeaderboardRowColumn>
      </LeaderboardContainer>
    );
  }
);
