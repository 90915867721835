import { FC, cloneElement, forwardRef } from 'react';
import { useTab } from '@mui/base';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { VerticalPanelActionProps } from './types';
import {
  UIKItVerticalPanelAction,
  UIKItVerticalPanelActionArrow,
  UIKItVerticalPanelActionContent,
  UIKItVerticalPanelActionCount,
  UIKItVerticalPanelActionIcon,
  verticalPanelActionClasses,
} from './styled';

export const VerticalPanelAction: FC<VerticalPanelActionProps> = forwardRef<
  HTMLButtonElement,
  VerticalPanelActionProps
>(
  (
    {
      icon,
      children,
      className,

      value,
      onChange,
      onClick,
      count,
      disabled: disabledProp,

      ...props
    },
    forwardedRef
  ) => {
    const { getRootProps, selected } = useTab({
      value,
      onChange,
      onClick,
      disabled: disabledProp,
      rootRef: forwardedRef,
    });

    return (
      <UIKItVerticalPanelAction
        {...props}
        {...getRootProps()}
        className={clsx(
          {
            [verticalPanelActionClasses.selected]: selected,
            [verticalPanelActionClasses.disabled]: getRootProps().disabled,
          },
          className
        )}
      >
        <UIKItVerticalPanelActionIcon className={verticalPanelActionClasses.icon}>
          {cloneElement(icon, { ...icon?.props, size: 20 })}
        </UIKItVerticalPanelActionIcon>

        <UIKItVerticalPanelActionContent className={verticalPanelActionClasses.content}>
          {children}
        </UIKItVerticalPanelActionContent>

        {count && (
          <UIKItVerticalPanelActionCount className={verticalPanelActionClasses.count}>
            {count}
          </UIKItVerticalPanelActionCount>
        )}
        <UIKItVerticalPanelActionArrow className={verticalPanelActionClasses.arrow}>
          <Icon name="chevron-right" size={20} />
        </UIKItVerticalPanelActionArrow>
      </UIKItVerticalPanelAction>
    );
  }
);
