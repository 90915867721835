import { styled } from '~shared/lib/styles';

export const CardRoot = styled('div')(({ theme }) => ({
  borderRadius: 12,
  background: theme.palette.paper.semi,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}));

export const CardInfo = styled('div')(({ theme }) => ({
  flex: 1,
  padding: '24px 30px 20px 24px',
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: 24,

  [theme.breakpoints.down('mobile')]: {
    padding: '20px 16px 20px 16px',
  },
}));

export const CardInfoTop = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

interface CardInfoHeadingProps {
  limitReached?: boolean;
}

export const CardInfoHeading = styled('div')<CardInfoHeadingProps>(({ theme, limitReached }) => ({
  fontSize: 22,
  fontWeight: 600,
  lineHeight: 30 / 22,
  letterSpacing: -0.2,
  color: limitReached ? theme.palette.error.main : theme.palette.text.primary,
}));

export const CardInfoDescriptionElement = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A50,
}));

export const CardImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  height: 150,

  [theme.breakpoints.up('mobile')]: {
    width: 280,
    height: 'unset',
  },
}));

export const CardBadgeContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: '10px 10px auto auto',

  [theme.breakpoints.down('mobile')]: {
    inset: '10px 10px auto 10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
