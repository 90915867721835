import { FC, cloneElement } from 'react';
import { clsx } from 'clsx';

import {
  UIKitFormControlLabelRoot,
  UIKitFormControlLabelTypography,
  formControlLabelClasses,
} from './styled';

import { FormControlLabelProps } from './types';

export const FormControlLabel: FC<FormControlLabelProps> = ({
  control,
  label,

  className,

  ...props
}) => {
  const controlProps = {};

  ['checked', 'name', 'onChange', 'value', 'inputRef', 'disabled'].forEach((key) => {
    if (typeof control.props[key] === 'undefined' && typeof props[key] !== 'undefined') {
      controlProps[key] = props[key];
    }
  });

  return (
    <UIKitFormControlLabelRoot
      className={clsx(
        formControlLabelClasses.root,
        {
          [formControlLabelClasses.disabled]: props.disabled,
        },
        className
      )}
    >
      {cloneElement(control, controlProps)}
      {label && (
        <UIKitFormControlLabelTypography
          component="div"
          className={formControlLabelClasses.typography}
        >
          {label}
        </UIKitFormControlLabelTypography>
      )}
    </UIKitFormControlLabelRoot>
  );
};
