import { FC } from 'react';

import { Shimmer } from '~shared/ui';

import { NFT_CARD_SIZE_VALUES } from '../../lib';

import { NftCardShimmerProps } from './types';

export const NftCardShimmer: FC<NftCardShimmerProps> = ({ size }) => {
  const { width, height } = NFT_CARD_SIZE_VALUES[size];

  return (
    <Shimmer
      width={width}
      height={height}
      viewBox={`0 0 ${width.replace('px', '')} ${height.replace('px', '')}`}
    >
      <rect x="0" y="0" rx="12" ry="12" width={width} height={height} />
    </Shimmer>
  );
};
