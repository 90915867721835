import { FC } from 'react';

import {
  WalletFinanceCardRoot,
  WalletFinanceCardRow,
  WalletFinanceCardRowIncome,
  WalletFinanceCardRowLabel,
  WalletFinanceCardRowOutcome,
  WalletFinanceCardTotal,
  WalletFinanceCardTotalValue,
} from './styled';

import { WalletFinanceCardProps, WalletFinanceCardType } from './types';

export const WalletFinanceCard: FC<WalletFinanceCardProps> = ({ type, total, outcome, income }) => {
  return (
    <WalletFinanceCardRoot>
      <WalletFinanceCardTotal>
        <span>{contentTypeMap[type]}</span>
        <WalletFinanceCardTotalValue>{total}</WalletFinanceCardTotalValue>
      </WalletFinanceCardTotal>

      <WalletFinanceCardRow>
        <WalletFinanceCardRowLabel>Income</WalletFinanceCardRowLabel>
        <WalletFinanceCardRowIncome empty={income === 0}>
          {shouldRenderSign(income, '+')}
          {formatDigits(income)}
        </WalletFinanceCardRowIncome>
      </WalletFinanceCardRow>

      <WalletFinanceCardRow>
        <WalletFinanceCardRowLabel>Outcome</WalletFinanceCardRowLabel>
        <WalletFinanceCardRowOutcome empty={outcome === 0}>
          {shouldRenderSign(outcome, '-')}
          {formatDigits(outcome)}
        </WalletFinanceCardRowOutcome>
      </WalletFinanceCardRow>
    </WalletFinanceCardRoot>
  );
};

const shouldRenderSign = (amount: number, sign: any) => {
  return amount === 0 ? null : sign;
};

const formatDigits = (number: number) => {
  return number
    .toLocaleString('de', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      notation: 'standard',
    })
    .replace('.', ' ');
};

const contentTypeMap: Record<WalletFinanceCardType, string> = {
  maincoin: 'MCN',
  matic: 'MATIC',
};
