import { FC, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';

import { useEventModel } from '~entities/event';

import { Box, Typography } from '~shared/ui';

import { EventDialogCallsBannerDetailedInfo } from './EventDialogCallsBannerDetailedInfo';

import { CallsBannerImage } from './styled';
import { EventDialogCallsBannerProps } from './types';

export const EventDialogCallsBanner: FC<EventDialogCallsBannerProps> = () => {
  const { isMobile } = useMediaQuery();
  const { bannerDetailedDialogInfo, isCallsBannerVisible } = useEventModel();
  const { t } = useTranslation();

  return (
    <Fragment>
      <EventDialogCallsBannerDetailedInfo />

      {!isMobile && isCallsBannerVisible && (
        <Box sx={{ position: 'relative' }}>
          <Typography
            sx={{ position: 'absolute', left: 20, top: 18, fontSize: '16px', fontWeight: 700 }}
          >
            {t('TryCalls.tryCallsBanner')}
          </Typography>
          <CallsBannerImage
            src={process.env.PUBLIC_URL + '/assets/event/calls/try-calls.png'}
            alt="Try calls"
            onClick={bannerDetailedDialogInfo.switchOn}
          />
        </Box>
      )}
    </Fragment>
  );
};
