import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { WalletPlaceholderProps } from './types';

export const WalletPlaceholder: FC<WalletPlaceholderProps> = ({ mobile }) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const computedMobile = mobile ?? isMobile;

  return (
    <Box
      width={1}
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h1"
        fontSize={computedMobile ? 18 : 22}
        lineHeight={computedMobile ? 24 / 18 : 30 / 22}
        paddingX={16 / 8}
        align="center"
      >
        {t('Wallet.isNotConnected')}
      </Typography>
    </Box>
  );
};
