import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const Root = styled(Box, { name: 'DashboardAuction', slot: 'root' })(({ theme }) => ({
  background: theme.palette.grey.A5,
  padding: '30px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
}));

export const StyledTitle = styled(Typography, { name: 'DashboardAuction', slot: 'styledTitle' })({
  fontSize: '20px',
  lineHeight: '30px',
  fontWeight: 600,
});

export const StyledDescription = styled(Typography, {
  name: 'DashboardAuction',
  slot: 'styledDescription',
})({
  fontSize: '13px',
  lineHeight: '14px',
});
