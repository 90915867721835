import { styled } from '~shared/lib/styles';

export const CardDatesBadge = styled('div')(({ theme }) => ({
  padding: '8px 10px',

  fontSize: 12,
  fontWeight: 500,
  lineHeight: 14 / 12,
  borderRadius: 8,

  background: theme.palette.grey.B50,
}));

export const CardStatusActiveBadge = styled(CardDatesBadge)(({ theme }) => ({
  background: theme.palette.violet.main,
}));

export const CardStatusSoonBadge = styled(CardDatesBadge)(({ theme }) => ({
  background: theme.palette.error.main,
}));

export const CardStatusPassedBadge = styled(CardDatesBadge)(({ theme }) => ({
  background: theme.palette.grey.B90,
}));
