import { FC, forwardRef } from 'react';

import { UIKitTab, UIKitTabHidden, UIKitTabIndicator, tabClasses } from './styled';
import { TabProps } from './types';

export const Tab: FC<TabProps> = forwardRef(({ children, ...props }, ref) => {
  return (
    <UIKitTab {...props} ref={ref}>
      {children}
      <UIKitTabHidden className={tabClasses.hidden}>{children}</UIKitTabHidden>
      <UIKitTabIndicator className={tabClasses.indicator} />
    </UIKitTab>
  );
});
