import { inputClasses as muiBaseInputClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

import { ButtonIcon } from '../ButtonIcon';

export const inputClasses = {
  ...muiBaseInputClasses,
  filled: 'MuiInput-filled',
  labeled: 'MuiInput-labeled',
};

export const UIKitInputRoot = styled('div', { name: 'Input' })(({ theme }) => ({
  borderRadius: 8,
  background: theme.palette.paper.semi,
  border: `1.5px solid ${theme.palette.paper.semi}`,
  height: 44,
  transition: theme.transitions.create('all'),
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  paddingRight: 10 - 1.5,
  paddingLeft: 10 - 1.5,
  cursor: 'text',

  [`&:hover:not(.${inputClasses.disabled}), &.${inputClasses.focused}`]: {
    boxShadow: '0px 0px 8px #42BAFF',
  },

  [`&.${inputClasses.focused}`]: {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },

  [`&.${inputClasses.disabled}`]: {
    background: theme.palette.grey.A5,
    borderColor: 'transparent',
    boxShadow: 'none',
    cursor: 'default',

    [`& .${inputClasses.input}`]: {
      color: '#7c7c7c',
    },
  },

  [`&.${inputClasses.labeled} .${inputClasses.input}`]: {
    paddingTop: 19 - 1.5,
  },

  [`&.${inputClasses.error}:not(.${inputClasses.disabled})`]: {
    border: `1.5px solid ${theme.palette.error.main}`,

    [`&:hover, &.${inputClasses.focused}`]: {
      boxShadow: '0px 0px 8px #FF7043',
    },
  },
}));

export const UIKitInput = styled('input', { name: 'Input', slot: 'input' })(({ theme }) => ({
  border: 'none',
  background: 'none',
  outline: 'none',
  padding: 0,
  width: '100%',

  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1,

  ['&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus']: {
    transition: theme.transitions.create(['background-color', 'color'], {
      delay: 5000000000,
      duration: 0,
    }),
  },

  ['&::placeholder']: {
    color: '#686868',
    opacity: 1, // firefox fix
  },
  ['&::-webkit-outer-spin-button']: {
    ['-webkit-appearance']: 'none',
    margin: 0,
  },
  ['&::-webkit-inner-spin-button']: {
    ['-webkit-appearance']: 'none',
    margin: 0,
  },

  /* Firefox */
  ['&[type=number]']: {
    ['-moz-appearance']: 'textfield',
  },
}));

export const UIKitInputEndAdornment = styled('div')(({ theme }) => ({
  marginLeft: 6,
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  pointerEvents: 'none',
  userSelect: 'none',

  ['& > *']: {
    pointerEvents: 'auto',
  },
}));

interface UIKitInputPasswordButtonIconProps {
  passwordVisibility: boolean;
}

export const UIKitInputPasswordButtonIcon = styled(ButtonIcon)<UIKitInputPasswordButtonIconProps>(
  ({ passwordVisibility, theme }) => ({
    ['&&']: {
      color: passwordVisibility ? theme.palette.primary.light : theme.palette.grey.A50,
    },
  })
);
