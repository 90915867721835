import { Fragment } from 'react';

import { BattleGlobalStyles } from '~entities/battle';
import { WalletConstructor } from '~entities/wallet';
import { NftDragLayer } from '~entities/nft';

import { Onboarding } from '~features/onboarding';

import { NftDragBackdropConstructor } from '~widgets/nft-drag-backdrop';
import { SignOutDialog } from '~widgets/viewer/sign-out-dialog';
import { ContactUs } from '~widgets/contact-us';
import { AuthConstructor } from '~widgets/auth';
import { NftConstructor } from '~widgets/nft';

export const AppWidgets = () => {
  return (
    <Fragment>
      <BattleGlobalStyles />

      <Onboarding />
      <AuthConstructor />
      <WalletConstructor />
      <SignOutDialog />
      <NftConstructor />
      <NftDragBackdropConstructor />
      <NftDragLayer />
      <ContactUs />
    </Fragment>
  );
};
