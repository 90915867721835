import { cloneElement, forwardRef, isValidElement, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { clsx } from 'clsx';

import { Box, Icon } from '~shared/ui';

import { BattleShellContext } from '../../../BattleShellContext';

import {
  BattleShellSquareBackground,
  BattleShellSquareCenter,
  BattleShellSquareContent,
  BattleShellSquareRoot,
  BattleShellSquareStatus,
  BattleShellSquareTop,
  battleShellSquareClasses,
} from './styled';

import { battleShellSquareBackgrounds, getRandomBackground, isRandomBackground } from './assets';

import { BattleShellSquareProps } from './types';

export const BattleShellSquare = forwardRef<HTMLDivElement, BattleShellSquareProps>(
  (
    {
      status,
      leagueLogo,
      date,
      background = defaultBackground,
      eventId,

      homeTeam,
      awayTeam,
      versus,

      ...rootProps
    },
    ref
  ) => {
    const { size } = useContext(BattleShellContext);

    const [computedBackground] = useState(
      isRandomBackground(background)
        ? getRandomBackground(background, eventId ?? uuid())
        : battleShellSquareBackgrounds[background] ?? defaultBackground
    );

    return (
      <BattleShellSquareRoot
        {...rootProps}
        className={clsx(
          battleShellSquareClasses.root,
          {
            [battleShellSquareClasses.sm]: size === 'sm',
            [battleShellSquareClasses.md]: size === 'md',
            [battleShellSquareClasses.lg]: size === 'lg',
          },
          rootProps.className
        )}
        ref={ref}
      >
        <BattleShellSquareBackground src={computedBackground} />

        <BattleShellSquareStatus
          className={clsx({
            [battleShellSquareClasses.statusDefault]: status === 'future',
            [battleShellSquareClasses.statusWin]: status === 'win',
            [battleShellSquareClasses.statusLose]: status === 'lose',
          })}
        />

        <BattleShellSquareContent>
          <BattleShellSquareTop className={battleShellSquareClasses.top}>
            {cloneElement(date, { ...date.props, size: size === 'sm' ? 'sm' : 'md' })}

            {leagueLogo ? (
              <Icon
                className={battleShellSquareClasses.league}
                name={leagueLogo}
                size={size === 'sm' ? 48 : 52}
              />
            ) : (
              <Box height={size === 'sm' ? 48 : 52} />
            )}
          </BattleShellSquareTop>

          <BattleShellSquareCenter className={battleShellSquareClasses.center}>
            {isValidElement(homeTeam) && cloneElement(homeTeam, { direction: 'fromLeft' })}

            {versus}

            {isValidElement(awayTeam) && cloneElement(awayTeam, { direction: 'fromRight' })}
          </BattleShellSquareCenter>
        </BattleShellSquareContent>
      </BattleShellSquareRoot>
    );
  }
);

const defaultBackground = battleShellSquareBackgrounds['mma-1'];
