import { apiInstance } from '../apiInstance';

import { MarketplaceRoutes } from './routes';
import {
  ApiGetMarketplaceCards,
  ApiPostMarketplaceOrder,
  ApiPostMarketplaceOrderCapture,
} from './types';

export const getMarketplaceCards: ApiGetMarketplaceCards = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(MarketplaceRoutes.cards())
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postMarketplaceOrder: ApiPostMarketplaceOrder = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(MarketplaceRoutes.order(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postMarketplaceOrderCapture: ApiPostMarketplaceOrderCapture = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(MarketplaceRoutes.orderCapture(data.orderId))
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
