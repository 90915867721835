import { FC, forwardRef } from 'react';

import { Badge } from '../../dataDisplay';

import { LogoRoot } from './styled';
import { LogoProps } from './types';

const logoAspectRatio = 2.4814814815;

export const Logo: FC<LogoProps> = forwardRef<SVGSVGElement, LogoProps>(
  ({ size, interactive, beta, ...props }, ref) => {
    const computedSize =
      typeof size === 'number' ? { width: size * logoAspectRatio, height: size } : size;

    const root = (
      <LogoRoot
        {...props}
        $interactive={interactive}
        name="illustration-logo"
        size={computedSize}
        ref={ref}
      />
    );

    if (beta) {
      return (
        <Badge badgeContent="beta" color="info">
          {root}
        </Badge>
      );
    }

    return root;
  }
);

Logo.defaultProps = {
  size: { width: 67, height: 27 },
};
