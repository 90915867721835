import { ApiCommonLeagueEnum, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from '../types';

export const LeagueOfLegendsLeaguesConfig: { [key in ApiCommonLeagueEnum]?: SportItemConfig } = {
  [ApiCommonLeagueEnum.LOL_LDL]: {
    id: 'LOL_LDL',
    label: 'LDL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LDL,
    dates: [
      { startDate: new Date('2023-02-04T00:00:00Z'), endDate: new Date('2023-08-13T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LDL,
    tournament: ApiCommonLeagueEnum.LOL_LDL,
  },

  [ApiCommonLeagueEnum.LOL_TAL]: {
    id: 'LOL_TAL',
    label: 'TAL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_TAL,
    dates: [
      { startDate: new Date('2023-08-15T00:00:00Z'), endDate: new Date('2023-08-17T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_TAL,
    tournament: ApiCommonLeagueEnum.LOL_TAL,
  },

  [ApiCommonLeagueEnum.LOL_PRM]: {
    id: 'LOL_PRM',
    label: 'PRM',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_PRM,
    dates: [
      { startDate: new Date('2023-06-01T00:00:00Z'), endDate: new Date('2023-08-12T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_PRM,
    tournament: ApiCommonLeagueEnum.LOL_PRM,
  },

  [ApiCommonLeagueEnum.LOL_LFL]: {
    id: 'LOL_LFL',
    label: 'LFL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LFL,
    dates: [
      { startDate: new Date('2023-05-31T00:00:00Z'), endDate: new Date('2023-08-11T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LFL,
    tournament: ApiCommonLeagueEnum.LOL_LFL,
  },

  [ApiCommonLeagueEnum.LOL_PCS]: {
    id: 'LOL_PCS',
    label: 'PCS',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_PCS,
    dates: [
      { startDate: new Date('2023-06-22T00:00:00Z'), endDate: new Date('2023-09-09T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_PCS,
    tournament: ApiCommonLeagueEnum.LOL_PCS,
  },

  [ApiCommonLeagueEnum.LOL_LJL]: {
    id: 'LOL_LJL',
    label: 'LJL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LJL,
    dates: [
      { startDate: new Date('2023-06-10T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LJL,
    tournament: ApiCommonLeagueEnum.LOL_LJL,
  },

  [ApiCommonLeagueEnum.LOL_LCS]: {
    id: 'LOL_LCS',
    label: 'LCS',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LCS,
    dates: [
      { startDate: new Date('2023-06-15T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LCS,
    tournament: ApiCommonLeagueEnum.LOL_LCS,
  },

  [ApiCommonLeagueEnum.LOL_LPL]: {
    id: 'LOL_LPL',
    label: 'LPL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LPL,
    dates: [
      { startDate: new Date('2023-05-29T00:00:00Z'), endDate: new Date('2023-08-05T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LPL,
    tournament: ApiCommonLeagueEnum.LOL_LPL,
  },

  [ApiCommonLeagueEnum.LOL_LCK]: {
    id: 'LOL_LCK',
    label: 'LCK',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LCK,
    dates: [
      { startDate: new Date('2023-06-07T00:00:00Z'), endDate: new Date('2023-08-20T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LCK,
    tournament: ApiCommonLeagueEnum.LOL_LCK,
  },

  [ApiCommonLeagueEnum.LOL_UL]: {
    id: 'LOL_UL',
    label: 'UL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_UL,
    dates: [
      { startDate: new Date('2023-05-29T00:00:00Z'), endDate: new Date('2023-08-12T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_UL,
    tournament: ApiCommonLeagueEnum.LOL_UL,
  },

  [ApiCommonLeagueEnum.LOL_LVPSL]: {
    id: 'LOL_LVPSL',
    label: 'LVP SL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LVPSL,
    dates: [
      { startDate: new Date('2023-05-29T00:00:00Z'), endDate: new Date('2023-08-12T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LVPSL,
    tournament: ApiCommonLeagueEnum.LOL_LVPSL,
  },

  [ApiCommonLeagueEnum.LOL_Worlds]: {
    id: 'LOL_Worlds',
    label: 'Worlds',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_Worlds,
    dates: [
      { startDate: new Date('2023-10-10T00:00:00Z'), endDate: new Date('2023-11-19T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_Worlds,
    tournament: ApiCommonLeagueEnum.LOL_Worlds,
  },

  [ApiCommonLeagueEnum.LOL_LECEMEA]: {
    id: 'LOL_LECEMEA',
    label: 'LEC.EMEA',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LECEMEA,
    dates: [
      { startDate: new Date('2023-06-17T00:00:00Z'), endDate: new Date('2023-07-30T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LECEMEA,
    tournament: ApiCommonLeagueEnum.LOL_LECEMEA,
  },

  [ApiCommonLeagueEnum.LOL_ESS]: {
    id: 'LOL_ESS',
    label: 'ESS',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_ESS,
    dates: [
      { startDate: new Date('2023-06-06T00:00:00Z'), endDate: new Date('2023-08-12T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_ESS,
    tournament: ApiCommonLeagueEnum.LOL_ESS,
  },

  [ApiCommonLeagueEnum.LOL_CBLOLA]: {
    id: 'LOL_CBLOLA',
    label: 'CBLOLA',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_CBLOLA,
    dates: [
      { startDate: new Date('2023-06-12T00:00:00Z'), endDate: new Date('2023-09-02T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_CBLOLA,
    tournament: ApiCommonLeagueEnum.LOL_CBLOLA,
  },

  [ApiCommonLeagueEnum.LOL_CBLOL]: {
    id: 'LOL_CBLOL',
    label: 'CBLOL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_CBLOL,
    dates: [
      { startDate: new Date('2023-06-10T00:00:00Z'), endDate: new Date('2023-09-09T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_CBLOL,
    tournament: ApiCommonLeagueEnum.LOL_CBLOL,
  },

  [ApiCommonLeagueEnum.LOL_EMEA]: {
    id: 'LOL_EMEA',
    label: 'EMEA',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_EMEA,
    dates: [
      { startDate: new Date('2023-08-14T00:00:00Z'), endDate: new Date('2023-09-08T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_EMEA,
    tournament: ApiCommonLeagueEnum.LOL_EMEA,
  },

  [ApiCommonLeagueEnum.LOL_LCKCL]: {
    id: 'LOL_LCKCL',
    label: 'LCK CL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LCKCL,
    dates: [
      { startDate: new Date('2023-06-05T00:00:00Z'), endDate: new Date('2023-08-22T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LCKCL,
    tournament: ApiCommonLeagueEnum.LOL_LCKCL,
  },

  [ApiCommonLeagueEnum.LOL_NACL]: {
    id: 'LOL_NACL',
    label: 'NACL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_NACL,
    dates: [
      { startDate: new Date('2023-06-10T00:00:00Z'), endDate: new Date('2023-08-09T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_NACL,
    tournament: ApiCommonLeagueEnum.LOL_NACL,
  },

  [ApiCommonLeagueEnum.LOL_TCL]: {
    id: 'LOL_TCL',
    label: 'TCL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_TCL,
    dates: [
      { startDate: new Date('2023-06-14T00:00:00Z'), endDate: new Date('2023-08-12T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_TCL,
    tournament: ApiCommonLeagueEnum.LOL_TCL,
  },

  [ApiCommonLeagueEnum.LOL_LLA]: {
    id: 'LOL_LLA',
    label: 'LLA',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LLA,
    dates: [
      { startDate: new Date('2023-06-07T00:00:00Z'), endDate: new Date('2023-08-26T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LLA,
    tournament: ApiCommonLeagueEnum.LOL_LLA,
  },

  [ApiCommonLeagueEnum.LOL_LCO]: {
    id: 'LOL_LCO',
    label: 'LCO',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LCO,
    dates: [
      { startDate: new Date('2023-06-05T00:00:00Z'), endDate: new Date('2023-07-30T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LCO,
    tournament: ApiCommonLeagueEnum.LOL_LCO,
  },

  [ApiCommonLeagueEnum.LOL_PGN]: {
    id: 'LOL_PGN',
    label: 'PGN',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_PGN,
    dates: [
      { startDate: new Date('2023-06-08T00:00:00Z'), endDate: new Date('2023-07-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_PGN,
    tournament: ApiCommonLeagueEnum.LOL_PGN,
  },

  [ApiCommonLeagueEnum.LOL_NLC]: {
    id: 'LOL_NLC',
    label: 'NLC',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_NLC,
    dates: [
      { startDate: new Date('2023-06-07T00:00:00Z'), endDate: new Date('2023-08-05T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_NLC,
    tournament: ApiCommonLeagueEnum.LOL_NLC,
  },

  [ApiCommonLeagueEnum.LOL_GLL]: {
    id: 'LOL_GLL',
    label: 'GLL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_GLL,
    dates: [
      { startDate: new Date('2023-06-05T16:00:00Z'), endDate: new Date('2023-08-06T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_GLL,
    tournament: ApiCommonLeagueEnum.LOL_GLL,
  },

  [ApiCommonLeagueEnum.LOL_LPLOL]: {
    id: 'LOL_LPLOL',
    label: 'LPLOL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_LPLOL,
    dates: [
      { startDate: new Date('2023-06-05T00:00:00Z'), endDate: new Date('2023-08-05T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_LPLOL,
    tournament: ApiCommonLeagueEnum.LOL_LPLOL,
  },

  [ApiCommonLeagueEnum.LOL_EBL]: {
    id: 'LOL_EBL',
    label: 'EBL',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_EBL,
    dates: [
      { startDate: new Date('2023-06-08T00:00:00Z'), endDate: new Date('2023-08-12T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_EBL,
    tournament: ApiCommonLeagueEnum.LOL_EBL,
  },

  [ApiCommonLeagueEnum.LOL_HPM]: {
    id: 'LOL_HPM',
    label: 'HPM',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_HPM,
    dates: [
      { startDate: new Date('2023-06-06T00:00:00Z'), endDate: new Date('2023-08-06T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_HPM,
    tournament: ApiCommonLeagueEnum.LOL_HPM,
  },

  [ApiCommonLeagueEnum.LOL_VCS]: {
    id: 'LOL_VCS',
    label: 'VCS',
    icon: null,
    illustration: '',
    route: routes.sports.LOL_VCS,
    dates: [
      { startDate: new Date('2023-06-23T00:00:00Z'), endDate: new Date('2023-08-31T23:59:00Z') },
    ],
    sport: ApiCommonSportEnum.LeagueOfLegends,
    league: ApiCommonLeagueEnum.LOL_VCS,
    tournament: ApiCommonLeagueEnum.LOL_VCS,
  },
};
