import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useWeb3React } from '@web3-react/core';

import { useDispatch } from '~shared/lib/hooks';
import { Nft, PartialNft, fetchNfts } from '~shared/api';
import { Multicall__factory } from '~shared/contracts';
import { getProvider } from '~shared/lib/utils';

import { eventActions, useEventModel } from '~entities/event';
import { useViewerSelector } from '~entities/viewer';
import { useNftCardModel } from '~entities/nft';

import { isNftRelatedBet } from '../lib';

import {
  fetchNftInfoFromChain,
  getActiveNftStatuses,
  getNftInfoMulticallRequests,
  getSortedNftsByRarity,
  getViewerAuctionCards,
  getViewerBetCards,
  getViewerCallCards,
  getViewerWalletCards,
  mapNftInfoFromMulticallRequest,
} from './helpers';

import { useNftSelector } from './selectors';
import { nftActions } from './slice';

export const useQueryNfts = () => {
  const dispatch = useDispatch();

  const { account } = useWeb3React();
  const { authorized } = useViewerSelector();

  const { nfts } = useNftSelector();

  const queryClient = useQueryClient();

  const queryNfts = async (): Promise<Array<PartialNft>> => {
    if (!account) {
      return [];
    }

    /**
     * Initialize contract
     */
    const provider = getProvider();
    // prettier-ignore
    const multicallContract = Multicall__factory.connect(process.env.REACT_APP_ADDRESS_MULTICALL, provider);
    // prettier-ignore
    const multicallContractAttached = multicallContract.attach(process.env.REACT_APP_ADDRESS_MULTICALL);

    /**
     * Getting all NFTs on wallet and active NFT IDs in one promise
     */
    const [
      { walletCards },
      { betCardIds, activeBets, activeBetEvents },
      { callCardIds, activeCalls, activeCallEvents },
      auctionCards,
    ] = await Promise.all([
      // Getting viewer's wallet NFTs
      getViewerWalletCards(account, provider, multicallContractAttached),

      // Getting all active NFT IDs and related events or auctions
      getViewerBetCards(account, provider),
      getViewerCallCards(account, provider),
      getViewerAuctionCards(account, provider),
    ]);

    // TODO: find out why we have duplicates in call card ids
    const callCardIdsWithoutDuplicates = Array.from(new Set(callCardIds));

    const auctionCardIds = auctionCards.map(({ cardId }) => cardId);
    const activeCardIds = [...betCardIds, ...callCardIdsWithoutDuplicates, ...auctionCardIds];

    /**
     * Requesting specific NFT info for active NFTs
     */
    const activeNftsMulticallRequestPromises = activeCardIds.map((cardId) => {
      return getNftInfoMulticallRequests({ tokenId: cardId, provider });
    });

    const activeNftsMulticallRequests = await Promise.all(activeNftsMulticallRequestPromises).then(
      (requests) => requests.flatMap((request) => request)
    );

    // prettier-ignore
    const activeNftsMulticallResult = await multicallContractAttached.callStatic.multicall(activeNftsMulticallRequests);

    const activeNfts = mapNftInfoFromMulticallRequest(
      activeCardIds.map((cardId) => ({ token_id: cardId })),
      activeNftsMulticallResult
    );

    /**
     * Mapping active NFTs with related events/auctions
     */
    const activeNftsWithEvents = activeNfts.map((activeNft): PartialNft => {
      const tokenId = activeNft.token_id;

      const foundBet = activeBets.find((bet) => bet.cardId === tokenId);
      const foundCall = activeCalls.find((call) => call.cardId === tokenId);
      const foundAuction = auctionCards.find((auctionCard) => auctionCard.cardId === tokenId);

      if (foundAuction) {
        return {
          ...activeNft,
          relatedAuction: foundAuction,
          isOnAuction: true,
        };
      }

      if (foundBet) {
        const foundBetEvent = activeBetEvents.find((betEvent) => betEvent.id === foundBet.eventId);

        if (foundBetEvent) {
          const foundBetCardIds = activeBets
            .filter((activeBet) => activeBet.eventId === foundBetEvent.id)
            .map((activeBet) => activeBet.cardId);

          return {
            ...activeNft,
            ...getActiveNftStatuses(foundBetEvent.result, foundBetEvent.date),
            relatedEvent: {
              ...foundBetEvent,
              choice: foundBet.choice,
              isCall: false,
              cards: foundBetCardIds.map((cardId) => {
                const foundActiveNft = activeNfts.find(
                  (activeNft) => activeNft.token_id === cardId
                )!;

                return {
                  ...foundActiveNft,
                  ...getActiveNftStatuses(foundBetEvent.result, foundBetEvent.date),
                };
              }),
            },
          };
        }
      }

      if (foundCall) {
        const foundCallEvent = activeCallEvents.find(
          (callEvent) => callEvent.callId.toString() === foundCall.callId
        )!;

        if (foundCallEvent) {
          return {
            ...activeNft,
            ...getActiveNftStatuses(foundCallEvent.result, foundCallEvent.date),
            relatedEvent: {
              ...foundCallEvent,
              cards: [],
              isCall: true,
            },
          };
        }

        return activeNft;
      }

      return activeNft;
    });

    const filteredWalletCards = walletCards.filter((nft) => {
      return !activeCardIds.includes(nft.token_id);
    });

    return getSortedNftsByRarity([...activeNftsWithEvents, ...filteredWalletCards]);
  };

  const isConnected = !!account;

  const { isLoading, isFetched } = useQuery(
    ['nfts', account],
    () => {
      return queryNfts();
    },
    {
      onSuccess: (nfts: Array<Nft>) => {
        dispatch(nftActions.setNfts(nfts));
      },
      onError: () => {
        console.error('Failed to query NFTs');
      },
      enabled: isConnected && authorized,
      staleTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  useEffect(() => {
    const shouldRefetchNfts = isConnected && authorized && account;

    if (shouldRefetchNfts) {
      queryClient.invalidateQueries({ queryKey: ['nfts', account] });
    }
  }, [account, authorized, isConnected, queryClient]);

  return { nfts, isLoading, isFetched };
};

export const useQueryLastAddedNft = () => {
  const { nfts } = useNftSelector();
  const { account } = useWeb3React();

  const dispatch = useDispatch();

  const queryLastAddedNft = async () => {
    const provider = getProvider();

    try {
      let recentlyAddedNft: Nft | null = null;

      const updatedNfts = await fetchNfts({ walletAddress: account! });

      for (const nft of updatedNfts) {
        const foundNft = nfts.find((previousNft) => previousNft.token_id === nft.token_id);

        if (!foundNft) {
          recentlyAddedNft = nft;
          break;
        }
      }

      if (recentlyAddedNft) {
        const nftWithAdditionalProperties = await fetchNftInfoFromChain({
          nft: recentlyAddedNft,
          provider,
        });

        if (recentlyAddedNft) {
          dispatch(nftActions.addNft(nftWithAdditionalProperties));
        }

        return nftWithAdditionalProperties;
      }
    } catch {
      console.error('Failed to query last added nft');
    }
  };

  return queryLastAddedNft;
};

export const useIsAnyNftDialogOpen = () => {
  const { isOpen: eventDialogOpen, isPassedEvent } = useEventModel();
  const { mergeDialogOpen } = useNftCardModel();

  return [mergeDialogOpen, eventDialogOpen && !isPassedEvent].some((state) => state);
};

export const useNftPreviewInfo = () => {
  const { previewInfo } = useNftSelector();
  const dispatch = useDispatch();

  const openPreview = useCallback(
    (nft: Nft) => {
      dispatch(nftActions.setPreviewInfo({ open: true, nft }));
    },
    [dispatch]
  );

  const closePreview = useCallback(() => {
    if (previewInfo.open) {
      dispatch(nftActions.setPreviewInfo({ open: false, nft: null }));
    }
  }, [dispatch, previewInfo.open]);

  return {
    open: previewInfo.open,
    nft: previewInfo.nft,
    openPreview: openPreview,
    closePreview: closePreview,
  };
};

export const useWrapNftAction = () => {
  const { closePreview } = useNftPreviewInfo();
  const dispatch = useDispatch();

  const handleWrapNftActions = () => {
    closePreview();
    // todo: make all changes to another state trough model (useEventModel)
    dispatch(eventActions.reset());
  };

  return { handleWrapNftActions };
};

/**
 * This hook fix the race condition between wallet initialization (fetch nfts)
 * and already opened EventDialog e.g for `/event/:eventId` route
 */
export const useSynchronizeEventDialogBet = () => {
  const dispatch = useDispatch();

  const { isFetched, nfts } = useQueryNfts();
  const { event, isOpen, isCall } = useEventModel();

  useEffect(() => {
    const eventDialogEventId = event?.id;

    if (eventDialogEventId && isOpen && isFetched && !isCall) {
      const relatedNft = nfts.find((nft) => nft.relatedEvent?.id === eventDialogEventId);
      const relatedEvent = relatedNft?.relatedEvent;

      if (relatedEvent && isNftRelatedBet(relatedEvent)) {
        // todo: make all changes to another state trough model (useEventModel)
        dispatch(
          eventActions.set({
            cards: relatedEvent.cards as Nft[],
            choice: relatedEvent.choice,
            isViewMode: true,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);
};
