import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const ConnectWalletOptionsRoot = styled(Box, { name: 'ConnectWalletOptions--root' })({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  alignItems: 'center',
});

export const ConnectWalletOptionContainer = styled(Box, {
  name: 'ConnectWalletOptions--option_container',
})({
  border: '1.5px solid #434343',
  borderRadius: '8px',
  padding: '30px 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
});

export const ConnectWalletOptionTitle = styled(Typography, {
  name: 'ConnectWalletOptions--option_title',
})({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '18px',
  maxWidth: '240px',
  textAlign: 'center',
});

export const ConnectWalletOptionDescription = styled(Typography, {
  name: 'ConnectWalletOptions--option_description',
})({
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '16px',
  textAlign: 'center',
  maxWidth: '421px',
});
