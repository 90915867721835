import { useMediaQuery } from '~shared/lib/hooks';

import { ConnectWalletDialogMobile } from './ConnectWalletDialogMobile';
import { ConnectWalletDialogDesktop } from './ConnectWalletDialogDesktop';

export const ConnectWalletDialog = () => {
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return <ConnectWalletDialogMobile />;
  }

  return <ConnectWalletDialogDesktop />;
};
