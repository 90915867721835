import { styled } from '~shared/lib/styles';

import { Typography } from '../../dataDisplay';

export const UIKitDialogTitle = styled(Typography, { name: 'DialogTitle' })(({ align }) => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: -0.2,
  textAlign: align || 'center',
  marginBottom: 24,
}));
