import { FC } from 'react';
import { clsx } from 'clsx';

import { useBattleModel, useDropBattleSlot } from '~entities/battle';

import { Icon } from '~shared/ui';

import { BattleCallSlotRoot, battleCallSlotRootClasses } from './styled';

import { BattleSlotProps } from './types';

export const BattleSlot: FC<BattleSlotProps> = ({ slot, event, call, show }) => {
  const { isOverlapBackdrop } = useBattleModel();

  const { isOver, dragRef, canDrop } = useDropBattleSlot({
    slot,
    event,
    call,
  });

  return (
    <BattleCallSlotRoot
      className={clsx({
        [battleCallSlotRootClasses.isOver]: isOver,
        [battleCallSlotRootClasses.hidden]: typeof show === 'boolean' ? !show : !isOverlapBackdrop,
        [battleCallSlotRootClasses.disabled]: !canDrop,
      })}
      ref={dragRef}
    >
      <Icon name={canDrop ? 'plus' : 'x'} size={12} />
    </BattleCallSlotRoot>
  );
};
