/* eslint-disable react/jsx-key,no-alert */
import { ReactNode, useEffect, useState } from 'react';

import {
  Alert,
  Avatar,
  AvatarAction,
  AvatarEditor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  ButtonIcon,
  CircularDiagram,
  Dialog,
  Icon,
  LinearProgress,
  Link,
  Logo,
  Stack,
  TextField,
  Typography,
} from '~shared/ui';

import { useSwitcher } from '~shared/lib/hooks';
import { Heading } from '~shared/ui/common/Heading/Heading';
import { useViewerModel } from '~entities/viewer';
import { NotificationsControl } from '~entities/notifications';
import { routes } from '~shared/config';

export const CommonTab = () => {
  const [progress, setProgress] = useState(0);

  const { defaultAvatars, fetchDefaultAvatars } = useViewerModel();

  useEffect(() => {
    fetchDefaultAvatars();
  }, [fetchDefaultAvatars]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }

        const diff = Math.random() * 10;

        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const dialog = useSwitcher(false);

  const row = (name: string, elements: ReactNode[]) => (
    <Stack direction="column" spacing={2} minHeight={44}>
      <Typography
        minWidth={150}
        borderBottom="1px solid #4A4A4A"
        paddingBottom={1}
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        {name}
      </Typography>
      {elements}
    </Stack>
  );

  const anotherRow = (name: string, elements: ReactNode[]) => (
    <Stack direction="row" alignItems="center" spacing={2} minHeight={44}>
      <Typography minWidth={180}>{name}</Typography>
      {elements}
    </Stack>
  );

  return (
    <Stack spacing={4}>
      {row('CircularDiagram', [
        <Stack spacing={12 / 8} direction="row">
          <CircularDiagram label="Precision" />
          <CircularDiagram label="Win" value={0.345} />
          <CircularDiagram label="Lose" value={0.76} />
        </Stack>,
      ])}

      {row('Notification center', [
        <div>
          <NotificationsControl />
        </div>,
      ])}

      {row('Notification center mobile', [<Link to={routes.notifications}>Open</Link>])}

      {row('Logo', [<Logo />])}
      {row('Badge', [
        <Stack direction="row" spacing={24 / 8}>
          <Badge badgeContent={1}>
            <Icon name="bell" />
          </Badge>
          <Badge badgeContent={10}>
            <Icon name="bell" />
          </Badge>
          <Badge badgeContent={300}>
            <Icon name="bell" />
          </Badge>
        </Stack>,
        <Stack direction="row" spacing={24 / 8}>
          <Badge color="error" badgeContent={1}>
            <Icon name="bell" />
          </Badge>
          <Badge color="error" badgeContent={10}>
            <Icon name="bell" />
          </Badge>
          <Badge color="error" badgeContent={300}>
            <Icon name="bell" />
          </Badge>
        </Stack>,
      ])}
      {row('Dialog', [
        <Box>
          <Button onClick={dialog.toggle}>Open dialog</Button>
          <Dialog open={dialog.value} onClose={dialog.toggle} title="Welcome">
            <Stack spacing={24 / 8} minWidth={340} alignItems="stretch" textAlign="center">
              <Typography>Sign in by email</Typography>
              <TextField label="Email" />
              <Typography>Or use our socials</Typography>
              <Stack spacing={1} direction="row" justifyContent="center">
                <ButtonIcon variant="outlined" name="social-google" />
                <ButtonIcon variant="outlined" name="social-facebook" />
                <ButtonIcon variant="outlined" name="social-apple" />
              </Stack>
              <Button>Let’s get started</Button>
            </Stack>
          </Dialog>
        </Box>,
      ])}

      {row('Avatar', [
        anotherRow('Add', [
          <AvatarAction size={60} interactive>
            <Icon name="plus" size={24} />
          </AvatarAction>,
        ]),

        anotherRow('Fallback (loading error)', [<Avatar size={60} />]),

        anotherRow('Defaults', [
          <AvatarEditor onChange={() => {}} defaults={defaultAvatars ?? []} />,
        ]),
      ])}

      {row('Linear Progress', [
        <Stack spacing={24 / 8} maxWidth={500}>
          <LinearProgress progress={progress} />
          <LinearProgress height={6} progressColor="primary" progress={progress} />
          <LinearProgress height={6} progressColor="error" progress={progress} />
          <LinearProgress height={24} progress={progress} />
        </Stack>,
      ])}

      {row('Alert', [
        <Box maxWidth={320} position="relative">
          <Alert onClose={() => alert('closed')}>Password successfully changed</Alert>
        </Box>,
      ])}

      <Box mt={200} />

      {row('Heading', [
        <Box maxWidth={500}>
          <Heading>Welcome</Heading>
        </Box>,
        <Box maxWidth={500}>
          <Heading layoutVariant="section">Welcome</Heading>
        </Box>,
      ])}

      {row('Breadcrumbs', [
        <Breadcrumbs>
          <Link to="Mma">Mma</Link>
          <Link to="UFC">UFC</Link>
          <Link to="UFC 200">UFC 200</Link>
          <Link to="Spain">Spain</Link>
        </Breadcrumbs>,
      ])}
    </Stack>
  );
};
