/* eslint-disable react/jsx-key */
import { FC, ReactNode } from 'react';

import {
  Box,
  Checkbox,
  FormControlLabel,
  SelectField,
  SelectOption,
  Slider,
  Stack,
  Switch,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  ToggleButton,
  ToggleButtonMenu,
  Typography,
} from '~shared/ui';

import { Balance } from '~widgets/balance';

export const SelectorsTab: FC = () => {
  const row = (name: string, elements: ReactNode[]) => (
    <Stack direction="column" spacing={2} minHeight={44}>
      <Typography
        minWidth={150}
        borderBottom="1px solid #4A4A4A"
        paddingBottom={1}
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        {name}
      </Typography>
      {elements}
    </Stack>
  );

  const label = 'Don’t show this window in future';

  return (
    <Stack spacing={4}>
      {row('Switcher', [
        <Stack spacing={2} alignItems="flex-start">
          <Switch />
          <Switch defaultChecked />
          <Switch disabled />
          <FormControlLabel label="Switch label" control={<Switch />} />
        </Stack>,
      ])}

      {row('Checkbox', [
        <Stack spacing={2} alignItems="flex-start">
          <FormControlLabel
            label={
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias assumenda, autem
                consequatur distinctio, doloribus eveniet ipsam praesentium quae quasi sed vel
                vitae! Cum deserunt dolore eos inventore libero, perspiciatis voluptas.
              </div>
            }
            control={<Checkbox />}
          />
          <FormControlLabel label={label} control={<Checkbox disabled />} />
          <FormControlLabel label={label} control={<Checkbox defaultChecked />} />

          <br />

          <FormControlLabel label={label} control={<Checkbox round />} />
          <FormControlLabel label={label} control={<Checkbox round disabled />} />
          <FormControlLabel label={label} control={<Checkbox round defaultChecked />} />
        </Stack>,
      ])}
      {row('Toggle button', [
        <Stack spacing={4} alignItems="flex-start">
          <ToggleButton>Future</ToggleButton>
          <ToggleButton disabled>Future</ToggleButton>
          <ToggleButton value>Future</ToggleButton>

          <ToggleButtonMenu
            defaultValue={1}
            options={[
              { label: 'Future', value: 1 },
              { label: 'Past', value: 2 },
            ]}
          />
        </Stack>,
      ])}
      {row('Select', [
        <Box sx={{ display: 'grid', gap: 2 }} maxWidth={300}>
          <SelectField defaultValue="disabled">
            <SelectOption value={0}>Veryyyyyyyyyyyyyy long option name</SelectOption>
            <SelectOption value={1}>First</SelectOption>
            <SelectOption value={2}>Second</SelectOption>
            <SelectOption value={3}>Third</SelectOption>
            <SelectOption value="disabled" disabled>
              Disabled
            </SelectOption>
          </SelectField>

          <SelectField error defaultValue="disabled" helperText="Error in field">
            <SelectOption value={1}>First</SelectOption>
            <SelectOption value={2}>Second</SelectOption>
            <SelectOption value={3}>Third</SelectOption>
            <SelectOption value="disabled" disabled>
              Disabled
            </SelectOption>
          </SelectField>

          <SelectField disabled>
            <SelectOption value={1}>First</SelectOption>
            <SelectOption value={2}>Second</SelectOption>
            <SelectOption value={3}>Third</SelectOption>
          </SelectField>

          <SelectField disabled value={1}>
            <SelectOption value={1}>First</SelectOption>
            <SelectOption value={2}>Second</SelectOption>
            <SelectOption value={3}>Third</SelectOption>
          </SelectField>
        </Box>,
      ])}

      {row('Balance', [
        <Stack spacing={4} alignItems="baseline">
          <Balance variant="group" maincoinBalance={15} maticBalance={10} />
          <Balance variant="dropdown" maincoinBalance={15} maticBalance={10} />
        </Stack>,
      ])}

      {row('Slider', [
        <Box maxWidth={200}>
          <Slider defaultValue={10} />
        </Box>,
      ])}

      {row('Tabs', [
        <Tabs defaultValue={0}>
          <TabsList>
            <Tab value={0}>Common</Tab>
            <Tab value={1}>Rare</Tab>
            <Tab value={2} disabled>
              Epic
            </Tab>
            <Tab value={3} disabled>
              Legendary
            </Tab>
            <Tab value={4}>Mystic</Tab>
          </TabsList>

          <TabPanel value={0} />
          <TabPanel value={1} />
          <TabPanel value={2} />
          <TabPanel value={3} />
          <TabPanel value={4} />
        </Tabs>,
      ])}
    </Stack>
  );
};
