import React, { FC } from 'react';

import { Shimmer } from '~shared/ui';

import { useBattleAutoStyle } from '../../../../lib';

import { BattleShimmerProps } from './types';

export const BattleShimmer: FC<BattleShimmerProps> = ({ size, variant = 'line' }) => {
  const { size: computedSize, variant: computedVariant } = useBattleAutoStyle(variant, size);

  if (computedVariant === 'square') {
    switch (computedSize) {
      case 'sm':
        return (
          <Shimmer width={288} height={178} viewBox="0 0 288 178">
            <rect x="0" y="0" rx="12" ry="12" width="288" height="178" />
          </Shimmer>
        );

      case 'md':
        return (
          <Shimmer width={320} height={200} viewBox="0 0 320 200">
            <rect x="0" y="0" rx="12" ry="12" width="320" height="200" />
          </Shimmer>
        );
      case 'lg':
        return (
          <Shimmer width={350} height={222} viewBox="0 0 350 222">
            <rect x="0" y="0" rx="12" ry="12" width="350" height="222" />
          </Shimmer>
        );
    }
  }

  const compact = (
    <Shimmer width="100%" height={165}>
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="164" />
    </Shimmer>
  );

  switch (computedVariant) {
    case 'compact':
      return compact;

    case 'line':
      switch (computedSize) {
        case 'lg':
          return (
            <Shimmer width="100%" height={95}>
              <rect x="0" y="0" rx="16" ry="16" width="100%" height="94" />
            </Shimmer>
          );
        case 'md':
          return (
            <Shimmer width="100%" height={89}>
              <rect x="0" y="0" rx="16" ry="16" width="100%" height="88" />
            </Shimmer>
          );
        case 'sm':
          return (
            <Shimmer width="100%" height={70}>
              <rect x="0" y="0" rx="16" ry="16" width="100%" height="70" />
            </Shimmer>
          );

        default:
          return compact;
      }
  }
};
