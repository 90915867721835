import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

interface RootProps {
  background: string;
}

export const Root = styled(Box, { name: 'NftStatusBadge', slot: 'root' })<RootProps>(
  ({ background }) => ({
    background,
    position: 'absolute',
    left: 6,
    top: 6,
    padding: '2px 6px',
    borderRadius: '12px',
  })
);
