import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';

import { ApiGetBattlesResponseData } from '~shared/api';

import { useNftSelector } from '~entities/nft';

export const useSuppliedNftBattleList = (list: ApiGetBattlesResponseData) => {
  const { account } = useWeb3React();
  const { nfts } = useNftSelector();

  const auditionedList = useMemo(() => {
    if (Boolean(nfts) && Boolean(list) && Boolean(account)) {
      const nftsWithRelatedEvents = nfts.filter((nft) => Boolean(nft.relatedEvent));

      return list.map((battle) => {
        const relatedNft = nftsWithRelatedEvents.find((nft) => nft.relatedEvent?.id === battle.id);

        if (relatedNft?.relatedEvent && !relatedNft.relatedEvent.isCall) {
          return {
            ...battle,
            relatedNft: {
              choice: relatedNft.relatedEvent.choice,
              cards: relatedNft.relatedEvent.cards,
            },
          };
        }

        return battle;
      });
    }

    return list;
  }, [account, list, nfts]);

  return {
    auditionedList: auditionedList,
  };
};
