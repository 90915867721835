import { FC } from 'react';

import { NftStackRoot } from './styled';
import { NftCardsStackProps, NftCardsStackSpacing } from './types';

// todo: maybe improve
export const NftCardsStack: FC<NftCardsStackProps> = ({
  children,
  cardsToShow = 3,
  spacing = 'tight',
}) => {
  const childrenToShow = children?.slice(0, cardsToShow);

  return (
    <NftStackRoot spacing={SpacingTypeToValue[spacing] ?? spacing}>{childrenToShow}</NftStackRoot>
  );
};

const SpacingTypeToValue: Record<NftCardsStackSpacing, number> = {
  tight: 28,
  spacious: 10,
};
