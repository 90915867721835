import { BattleResult, Nft } from '~shared/api';

export const getActiveNftStatuses = (result: BattleResult, date: Date): Partial<Nft> => {
  const currentDateTs = new Date().getTime();
  const eventStartDateTs = date.getTime();

  return {
    isBlockedForEvent:
      (result === BattleResult.InProgress && currentDateTs >= eventStartDateTs) ||
      // In case if event is finished but card is not returned back to user's wallet
      (result !== BattleResult.InProgress && currentDateTs > eventStartDateTs),
    isOnEvent: result === BattleResult.InProgress && currentDateTs < eventStartDateTs,
    isLive: result === BattleResult.InProgress && currentDateTs >= eventStartDateTs,
  };
};
