/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers';
import type { Provider } from '@ethersproject/providers';
import type { Arena, ArenaInterface } from '../Arena';

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'wallet',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
    ],
    name: 'CallAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'wallet',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'enum IArena.MatchResult',
        name: 'choiceId',
        type: 'uint8',
      },
    ],
    name: 'CallAccepted_v2',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'maintokensReceived',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'takenAfterMatch',
        type: 'bool',
      },
    ],
    name: 'CardTakenFromBet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'taker',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
    ],
    name: 'CardTakenFromCall',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'enum IArena.MatchResult',
        name: 'choiceId',
        type: 'uint8',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'potentialRewardMaintokens',
        type: 'uint256',
      },
    ],
    name: 'NewBet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'enum ICard.CardRarity',
        name: 'rarity',
        type: 'uint8',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'enum IArena.MatchResult',
        name: 'choiceId',
        type: 'uint8',
      },
    ],
    name: 'NewCall',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choice',
        type: 'uint8',
      },
    ],
    name: 'acceptCall',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
    ],
    name: 'acceptCall',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choice',
        type: 'uint8',
      },
      {
        internalType: 'address',
        name: 'caller',
        type: 'address',
      },
      {
        internalType: 'uint8',
        name: '_v',
        type: 'uint8',
      },
      {
        internalType: 'bytes32',
        name: '_r',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: '_s',
        type: 'bytes32',
      },
    ],
    name: 'acceptCallGasFree',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'bets',
    outputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choice',
        type: 'uint8',
      },
      {
        internalType: 'address',
        name: 'cardOwner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'offset',
        type: 'uint256',
      },
    ],
    name: 'betsByAddressAndIndex',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'eventId',
            type: 'uint256',
          },
          {
            internalType: 'enum IArena.MatchResult',
            name: 'choice',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'cardOwner',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'betId',
            type: 'uint256',
          },
        ],
        internalType: 'struct Arena.BetInfo[10]',
        name: '',
        type: 'tuple[10]',
      },
      {
        internalType: 'uint256[10]',
        name: '',
        type: 'uint256[10]',
      },
      {
        internalType: 'enum IArena.MatchResult[10]',
        name: '',
        type: 'uint8[10]',
      },
      {
        internalType: 'uint256[10]',
        name: '',
        type: 'uint256[10]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'betsByAddressCount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'betsByUser',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'calls',
    outputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choice',
        type: 'uint8',
      },
      {
        internalType: 'address',
        name: 'firstParticipantAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'firstParticipantCard',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'secondParticipantAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'secondParticipantCard',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'offset',
        type: 'uint256',
      },
    ],
    name: 'callsByAddressAndIndex',
    outputs: [
      {
        internalType: 'uint256[10]',
        name: 'callIds',
        type: 'uint256[10]',
      },
      {
        internalType: 'uint256[10]',
        name: '',
        type: 'uint256[10]',
      },
      {
        internalType: 'enum IArena.MatchResult[10]',
        name: '',
        type: 'uint8[10]',
      },
      {
        internalType: 'enum IArena.MatchResult[10]',
        name: '',
        type: 'uint8[10]',
      },
      {
        internalType: 'uint256[10]',
        name: 'cardIds',
        type: 'uint256[10]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'callsByAddressCount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'callsByUser',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'callId',
        type: 'uint256',
      },
    ],
    name: 'claimCall',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choiceId',
        type: 'uint8',
      },
    ],
    name: 'createCall',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choiceId',
        type: 'uint8',
      },
      {
        internalType: 'address',
        name: 'caller',
        type: 'address',
      },
      {
        internalType: 'uint8',
        name: '_v',
        type: 'uint8',
      },
      {
        internalType: 'bytes32',
        name: '_r',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: '_s',
        type: 'bytes32',
      },
    ],
    name: 'createCallGasFree',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'betsAcceptedUntilTs',
        type: 'uint256',
      },
      {
        internalType: 'bytes32',
        name: 'descriptionHash',
        type: 'bytes32',
      },
    ],
    name: 'createEvent',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
    ],
    name: 'eventExists',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'eventInfos',
    outputs: [
      {
        internalType: 'uint256',
        name: 'betsAcceptedUntilTs',
        type: 'uint256',
      },
      {
        internalType: 'bytes32',
        name: 'descriptionHash',
        type: 'bytes32',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'result',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'gasFreeOpCounter',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'getMyCallCards',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'choiceId',
        type: 'uint8',
      },
    ],
    name: 'makeBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: 'eventIds',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'cardIds',
        type: 'uint256[]',
      },
      {
        internalType: 'enum IArena.MatchResult[]',
        name: 'choiceIds',
        type: 'uint8[]',
      },
      {
        internalType: 'address',
        name: 'caller',
        type: 'address',
      },
      {
        internalType: 'uint8',
        name: '_v',
        type: 'uint8',
      },
      {
        internalType: 'bytes32',
        name: '_r',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: '_s',
        type: 'bytes32',
      },
    ],
    name: 'makeBetsGasFree',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: 'eventId',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'cardId',
        type: 'uint256[]',
      },
      {
        internalType: 'enum IArena.MatchResult[]',
        name: 'choiceId',
        type: 'uint8[]',
      },
    ],
    name: 'massMakeBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'uint256[]',
        name: 'cardIds',
        type: 'uint256[]',
      },
    ],
    name: 'massTakeCard',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'onERC721Received',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ICard',
        name: '_card',
        type: 'address',
      },
    ],
    name: 'setCardAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'eventId',
        type: 'uint256',
      },
      {
        internalType: 'enum IArena.MatchResult',
        name: 'resultChoiceId',
        type: 'uint8',
      },
    ],
    name: 'setEventResult',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract MainToken',
        name: '_maintoken',
        type: 'address',
      },
    ],
    name: 'setMainToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
    ],
    name: 'takeCard',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'cardId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'betId',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'onlyIfEventCompleted',
        type: 'bool',
      },
    ],
    name: 'takeCardSafe',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

export class Arena__factory {
  static readonly abi = _abi;
  static createInterface(): ArenaInterface {
    return new utils.Interface(_abi) as ArenaInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Arena {
    return new Contract(address, _abi, signerOrProvider) as Arena;
  }
}
