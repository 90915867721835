import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SetNftHiddenPayload } from '~entities/nft';

import { EventDialogTab } from './config';
import { EventState } from './types';

const initialState: EventState = {
  tab: EventDialogTab.Cards,
  closingConfirmationDialogOpen: false,
  bannerDetailedInfoDialogOpen: false,

  event: null,

  choice: null,
  result: null,
  cards: [],
  additionalCards: [],
  callId: null,
  callCreator: null,
  callAcceptor: null,
  callAcceptableRarity: null,

  isViewMode: false,
  isPassedEvent: false,
  isViewerBattle: false,
  isMakeCall: false,
  isCall: false,
  isViewerCall: false,

  nftWithWinstreakDialog: {
    nft: null,
    open: false,
    result: null,
    onBetCard: null,
  },
};

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    setClosingConfirmationDialogOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      closingConfirmationDialogOpen: action.payload,
    }),
    setBannerDetailedInfoDialogOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      bannerDetailedInfoDialogOpen: action.payload,
    }),

    clearEventCards: (state, action: PayloadAction<{ cardsKey: 'cards' | 'additionalCards' }>) => ({
      ...state,
      [action.payload.cardsKey]: [],
    }),

    setNftHidden: (state, { payload }: PayloadAction<SetNftHiddenPayload>) => {
      if (!payload.isHidden) {
        state.cards = state.cards.filter((card) => !payload.nftIds.includes(card.token_id));

        state.additionalCards = state.additionalCards.filter(
          (card) => !payload.nftIds.includes(card.token_id)
        );
      }
    },

    setTab: (state, action: PayloadAction<EventDialogTab>) => ({
      ...state,
      tab: action.payload,
    }),

    set: (state, action: PayloadAction<Partial<EventState>>) => ({ ...state, ...action.payload }),

    reset: () => ({ ...initialState }),
  },
});

export const eventReducer = eventSlice.reducer;
export const eventActions = eventSlice.actions;
