import { FC } from 'react';

import { BattleResult } from '~shared/api';

import { NftCard, NftDragItemType, StaticNftCard } from '~entities/nft';
import { isDrawSlotAvailable } from '~entities/battle';
import { useEventModel } from '~entities/event';

import { useMediaQuery } from '~shared/lib/hooks';

import { EventChoice } from '../EventChoice';

import { useEventDrag } from '../../../../../model';

import { EventCallCardsProps } from './types';
import { Root } from './styled';

export const EventCallCards: FC<EventCallCardsProps> = () => {
  const { isMobile } = useMediaQuery();

  const {
    onAddCards,
    isViewMode,
    isPassedEvent,
    event,
    isCall,
    callCreator,
    callAcceptor,
    choice,
    cards,
  } = useEventModel();

  const homeTeamCell = useEventDrag({
    result: BattleResult.HomeTeamWon,
    viewMode: isViewMode || callCreator?.choiceId === BattleResult.HomeTeamWon,
  });

  const drawCell = useEventDrag({
    result: BattleResult.Draw,
    viewMode: isViewMode || callCreator?.choiceId === BattleResult.Draw,
  });

  const awayTeamCell = useEventDrag({
    result: BattleResult.AwayTeamWon,
    viewMode: isViewMode || callCreator?.choiceId === BattleResult.AwayTeamWon,
  });

  const getPropsForAppropriateCell = (result: BattleResult) => {
    if (callCreator?.choiceId === result) {
      return {
        nft: (
          <StaticNftCard
            tokenId={callCreator.card!}
            width={72}
            height={106}
            blockedForEvent={!isPassedEvent}
          />
        ),
        disabled: true,
      };
    }

    if (callAcceptor?.choiceId === result) {
      return {
        nft: (
          <StaticNftCard
            tokenId={callAcceptor.card!}
            width={72}
            height={106}
            blockedForEvent={!isPassedEvent}
          />
        ),
        disabled: true,
      };
    }

    if (result !== choice) {
      return { onAddCards: onAddCards(result, isMobile) };
    }

    if (cards[0]) {
      return {
        nft: <NftCard nft={cards[0]} dragKey={NftDragItemType.MoveOrLeave} hideStates />,
      };
    }

    return { onAddCards: onAddCards(result, isMobile) };
  };

  if (isViewMode) {
    return (
      <Root>
        {getPropsForAppropriateCell(BattleResult.HomeTeamWon).nft && (
          <EventChoice {...getPropsForAppropriateCell(BattleResult.HomeTeamWon)} />
        )}
        {getPropsForAppropriateCell(BattleResult.Draw).nft && (
          <EventChoice {...getPropsForAppropriateCell(BattleResult.Draw)} />
        )}
        {getPropsForAppropriateCell(BattleResult.AwayTeamWon).nft && (
          <EventChoice {...getPropsForAppropriateCell(BattleResult.AwayTeamWon)} />
        )}

        {!callAcceptor && <EventChoice icon="time" label="Waiting for an opponent" />}
      </Root>
    );
  }

  return (
    <Root>
      <EventChoice
        dropRef={homeTeamCell.dropRef}
        isHighlighted={homeTeamCell.isCellHighlighted}
        label={isCall ? `Win ${event?.homeTeam.name}` : null}
        disabled={!homeTeamCell.canDrop && homeTeamCell.isDragging}
        {...getPropsForAppropriateCell(BattleResult.HomeTeamWon)}
      />
      {isDrawSlotAvailable(event!.sport) && (
        <EventChoice
          dropRef={drawCell.dropRef}
          isHighlighted={drawCell.isCellHighlighted}
          label={isCall ? 'Win Draw' : null}
          disabled={!drawCell.canDrop && drawCell.isDragging}
          {...getPropsForAppropriateCell(BattleResult.Draw)}
        />
      )}
      <EventChoice
        dropRef={awayTeamCell.dropRef}
        isHighlighted={awayTeamCell.isCellHighlighted}
        label={isCall ? `Win ${event?.awayTeam.name}` : null}
        disabled={!awayTeamCell.canDrop && awayTeamCell.isDragging}
        {...getPropsForAppropriateCell(BattleResult.AwayTeamWon)}
      />
    </Root>
  );
};
