import { ElementType, FC, forwardRef } from 'react';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';

import { BackdropProps, BackdropTransitionProps } from './types';
import { UIKitBackdrop } from './styled';

export const Backdrop = forwardRef<HTMLDivElement, BackdropProps>(({ ...props }, ref) => {
  return <UIKitBackdrop ref={ref} {...extendSxProp(props as {})} />;
});

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

const motionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, transition: { duration: 0.2, type: 'tween' } },
  [AnimationVariant.Visible]: { opacity: 1, transition: { duration: 0.2, type: 'tween' } },
};

export const makeBackdropTransition = (
  Backdrop: ElementType,
  backdropInitialTransition: boolean
): FC<BackdropTransitionProps> => {
  return forwardRef<HTMLDivElement, BackdropTransitionProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ open, ...props }, ref) => {
      return (
        <Backdrop
          variants={motionVariants}
          animate={open ? AnimationVariant.Visible : AnimationVariant.Hidden}
          initial={backdropInitialTransition ? AnimationVariant.Hidden : AnimationVariant.Visible}
          ref={ref}
          {...props}
        />
      );
    }
  );
};
