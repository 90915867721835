import { motion } from 'framer-motion';
import { clsx } from 'clsx';
import {
  FC,
  HTMLProps,
  MouseEvent,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
} from 'react';

import { DialogContent } from '../DialogContent';
import { DialogTitle } from '../DialogTitle';

import {
  UIKitDialogCloseButton,
  UIKitDialogPaper,
  UIKitDialogRoot,
  UIKitDialogScrollPaper,
  dialogClasses,
} from './styled';
import { DialogProps } from './types';

export const Dialog: FC<DialogProps> = ({
  title,
  children,
  onClose,
  width = 380,
  slots: { content, scrollPaper, paper, closeButton, ...slots } = {},
  disableTransition,
  backgroundColor,
  className,
  ...props
}) => {
  const handleClose = useCallback(
    (event: MouseEvent) => {
      if (onClose) {
        onClose(event, 'closeClick');
      }
    },
    [onClose]
  );

  const SlotContent = content ?? DialogContent;
  const SlotScrollPaper = scrollPaper ?? UIKitDialogScrollPaper;
  const SlotPaper = paper ?? UIKitDialogPaper;
  const SlotCloseButton = onClose
    ? closeButton ?? <UIKitDialogCloseButton name="close-2" size={24} variant="plain" />
    : null;

  return (
    <UIKitDialogRoot
      {...props}
      className={clsx(dialogClasses.root, className)}
      slots={slots}
      onClose={onClose}
      disableTransition={disableTransition}
    >
      <DialogPaper
        in={props.open}
        width={width}
        ScrollPaper={SlotScrollPaper}
        Paper={SlotPaper}
        disableTransition={disableTransition}
        backgroundColor={backgroundColor}
        className={dialogClasses.paper}
      >
        <SlotContent className={dialogClasses.content}>
          {isValidElement<HTMLProps<HTMLElement>>(SlotCloseButton)
            ? cloneElement(SlotCloseButton, { ...SlotCloseButton?.props, onClick: handleClose })
            : SlotCloseButton}

          {title && <DialogTitle>{title}</DialogTitle>}
          {children}
        </SlotContent>
      </DialogPaper>
    </UIKitDialogRoot>
  );
};

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

const paperMotionVariants = {
  [AnimationVariant.Hidden]: {
    opacity: 0,
    y: -5,
    scale: 0.95,
    transition: { duration: 0.2, type: 'tween' },
  },
  [AnimationVariant.Visible]: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.2, type: 'tween' },
  },
};

const DialogPaper = forwardRef(
  (
    {
      in: open,
      onEnter,
      onExited,
      width,
      ScrollPaper,
      Paper,
      disableTransition,
      backgroundColor,
      ...props
    }: any,
    ref
  ) => {
    const paper = disableTransition ? (
      <Paper ref={ref} width={width} {...props} />
    ) : (
      <Paper
        variants={paperMotionVariants}
        animate={open ? AnimationVariant.Visible : AnimationVariant.Hidden}
        initial={AnimationVariant.Hidden}
        onAnimationStart={open ? onEnter : undefined}
        onAnimationComplete={!open ? onExited : undefined}
        ref={ref}
        width={width}
        component={motion.div}
        backgroundColor={backgroundColor}
        {...props}
      />
    );

    return <ScrollPaper className={dialogClasses.scrollPaper}>{paper}</ScrollPaper>;
  }
);
