import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiGetAuctionCardMappedData } from '~shared/api';

import { AuctionState } from './types';

const initialState: AuctionState = {
  auctionCards: [],
  isLoading: true,
};

export const auctionSlice = createSlice({
  name: 'auction',
  initialState: initialState,
  reducers: {
    setAuctionCards: (state, action: PayloadAction<Array<ApiGetAuctionCardMappedData>>) => ({
      ...state,
      auctionCards: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
});

export const auctionReducer = auctionSlice.reducer;
export const auctionActions = auctionSlice.actions;
