import { FC } from 'react';
import { useFormControlContext } from '@mui/base';
import { clsx } from 'clsx';

import { UIKitLabel, inputLabelClasses } from './styled';
import { InputLabelProps } from './types';

export const InputLabel: FC<InputLabelProps> = (props) => {
  const { disabled, focused, filled } = useFormControlContext() ?? {};

  return (
    <UIKitLabel
      {...props}
      className={clsx({
        [inputLabelClasses.disabled]: disabled,
        [inputLabelClasses.focused]: focused,
        [inputLabelClasses.filled]: filled,
      })}
    />
  );
};
