import { Container as MuiSystemContainer } from '@mui/system';

import { styled } from '~shared/lib/styles';

export const containerClasses = {
  root: 'Container-root',
  negative: 'Container-negative',
};

interface UIKitContainerProps {
  disableLeftGutters?: boolean;
  disableRightGutters?: boolean;
}

export const UIKitContainer = styled(MuiSystemContainer, {
  name: 'Container',
  shouldForwardProp: (prop: keyof UIKitContainerProps) => {
    switch (prop) {
      case 'disableLeftGutters':
      case 'disableRightGutters':
        return false;

      default:
        return true;
    }
  },
})<UIKitContainerProps>(({ theme, disableGutters, disableLeftGutters, disableRightGutters }) => {
  const gutters = {
    [theme.breakpoints.down('mobile')]: {
      paddingRight: disableRightGutters ? 0 : 16,
      paddingLeft: disableLeftGutters ? 0 : 16,
    },

    paddingRight: disableRightGutters ? 0 : 30,
    paddingLeft: disableLeftGutters ? 0 : 30,
  };

  return {
    paddingLeft: 'unset',
    paddingRight: 'unset',
    ...(disableGutters ? {} : gutters),

    [`&.${containerClasses.negative}`]: {
      marginLeft: -30,
      marginRight: -30,
      width: 'calc(100% + 30px + 30px)',

      [theme.breakpoints.down('mobile')]: {
        marginLeft: -16,
        marginRight: -16,
        width: 'calc(100% + 16px + 16px)',
      },
    },
  };
});
