import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import TiktokPixel from 'tiktok-pixel';

import { postOnboardingPassed } from '~shared/api';

import { useOnboardingModel } from './model';
import { OnboardingSteps } from './config';

export const useOnboardingPassedMutation = () => {
  return useMutation<unknown, AxiosError, boolean>((passed: boolean) => {
    return postOnboardingPassed({ isPassed: passed });
  });
};

export const useOnboardingEffects = () => {
  const { stepParam } = useOnboardingModel();

  useEffect(() => {
    if (stepParam === OnboardingSteps.Merge) {
      TiktokPixel.pageView();
    }
  }, [stepParam]);
};
