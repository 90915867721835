import { useTranslation } from 'react-i18next';

import { Button, Typography } from '~shared/ui';

import { useConnect } from '../../model';

import * as S from './styled';

export const ConnectYourWallet = () => {
  const { t } = useTranslation();

  const { openConnectWalletDialogAndOnboarding } = useConnect();

  return (
    <S.ConnectYourWalletRoot>
      <S.ConnectYourWalletPromoContainer>
        <Typography variant="h2" sx={{ textAlign: 'center', fontWeight: 600 }}>
          {t('onBoarding.titleStep1')} <br /> {t('Other.freeMain')}
        </Typography>
        <S.ConnectYourWalletPromoImage
          src="/assets/auth/connect-wallet-illustration.png"
          alt="Connect wallet to claim FREE card"
        />
        <Typography maxWidth="280px" textAlign="center" variant="body" color="grey.A75">
          {t('Other.oneStep')}
        </Typography>
      </S.ConnectYourWalletPromoContainer>

      <Button
        variant="contained"
        fullWidth
        onClick={openConnectWalletDialogAndOnboarding}
        maxWidth={320}
      >
        {t('onBoarding.titleStep1')}
      </Button>
    </S.ConnectYourWalletRoot>
  );
};
