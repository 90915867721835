import { useMutation } from 'react-query';

import { useSearchParamsState } from '~shared/lib/hooks';
import { ApiPostCreateReferralRequestData, postCreateReferral } from '~shared/api';

import { REFERRAL_CODE_SEARCH_PARAM_KEY } from '../../lib';

export const useCreateReferralMutation = () => {
  const [, , omitReferralCode] = useSearchParamsState(REFERRAL_CODE_SEARCH_PARAM_KEY, '');

  return useMutation((data: ApiPostCreateReferralRequestData) => postCreateReferral(data), {
    onSuccess: omitReferralCode,
  });
};
