import { useEffect, useRef, useState } from 'react';

import { DateLabelState, DateLabelStateMap } from '~shared/ui';

import { getStateByDate, getUpdateTimeout } from './utils';

interface UseDateLabel {
  (props: { live?: boolean; date: Date; defaultState?: DateLabelState }): DateLabelState;
}

export const useDateLabel: UseDateLabel = ({ live, date, defaultState }) => {
  const [state, setState] = useState<DateLabelState>(live ? 'live' : getStateByDate({ date }));

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const configureTimeouts = (date: Date) => {
      clearTimeout(timeout.current);

      const updateTimeout = getUpdateTimeout(date);

      if (updateTimeout > 0) {
        timeout.current = setTimeout(() => {
          setState(getStateByDate({ date }));

          configureTimeouts(date);
        }, updateTimeout);
      }
    };

    if (!defaultState) {
      configureTimeouts(date);
    }
  }, [date, defaultState, live]);

  useEffect(() => {
    if (live) {
      clearTimeout(timeout.current);
    }
  }, [live]);

  if (defaultState) {
    return defaultState;
  }

  return live ? DateLabelStateMap.Live : state;
};
