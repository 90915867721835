import { FC } from 'react';

import { CircularProgress } from '~shared/ui';

import { EventDialogPreloaderRoot } from './styled';

// todo: shimmer
export const EventDialogPreloader: FC = () => {
  return (
    <EventDialogPreloaderRoot>
      <CircularProgress size={48} />
    </EventDialogPreloaderRoot>
  );
};
