import { forwardRef } from 'react';

import { UIKitMenuItem } from './styled';
import { MenuItemProps } from './types';

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ color = 'text', selected, ...props }, ref) => {
    return <UIKitMenuItem color={color} $selected={selected} {...props} ref={ref} />;
  }
);
