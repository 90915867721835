import { FC } from 'react';

import { Avatar, Typography } from '~shared/ui';

import { useAuthSelector } from '~widgets/auth';

import { UserProfileRowContent, UserProfileRowRoot } from './styled';

import { UserProfileRowProps } from './types';

export const UserProfileRow: FC<UserProfileRowProps> = () => {
  const { email, avatar, nickname } = useAuthSelector();

  return (
    <UserProfileRowRoot>
      <Avatar src={avatar} size={36} />
      <UserProfileRowContent>
        <Typography fontWeight={600} lineHeight={1}>
          {nickname}
        </Typography>
        <Typography fontSize={12} lineHeight={14 / 12} color="secondary">
          {email}
        </Typography>
      </UserProfileRowContent>
    </UserProfileRowRoot>
  );
};
