import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

export const breadcrumbsClasses = {
  indent: 'Breadcrumb-indent',
};

export const BreadcrumbsRoot = styled(Box, { name: 'Breadcrumbs' })(({ theme }) => ({
  color: theme.palette.grey.A50,
  display: 'flex',
  alignItems: 'center',
  paddingTop: 10,
  paddingBottom: 10,

  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,

  [`&.${breadcrumbsClasses.indent}`]: {
    paddingLeft: 30,
  },
}));

export const BreadcrumbsSeparator = styled(Box, { name: 'Breadcrumbs-separator' })({
  color: '#545454',
  marginLeft: 4,
  marginRight: 4,
});
