import { styled } from '~shared/lib/styles';

import { BattleShellSizeMap } from '~entities/battle';

import { BattleShell } from '../../BattleShell';

export const battleShellLineClasses = {
  root: 'BattleLine-root',
  status: 'BattleLine-status',
  call: 'BattleLine-call',

  statusDefault: 'BattleLine-statusDefault',
  statusWin: 'BattleLine-statusWin',
  statusLose: 'BattleLine-statusLose',

  sm: 'BattleLine-sm',
  md: 'BattleLine-md',
  lg: 'BattleLine-lg',

  gridCenter: 'BattleLine-gridCenter',
  grid: 'BattleLine-grid',
};

export const BattleShellLineRoot = styled(BattleShell, { name: 'BattleLine' })(({ theme }) => ({
  width: '100%',

  borderRadius: 16,
  background: theme.palette.paper.blue,
  paddingLeft: 20,
  paddingRight: 10,
  height: BattleShellSizeMap.line.md.height,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',

  [`&.${battleShellLineClasses.sm}`]: {
    height: BattleShellSizeMap.line.sm.height,
  },

  [`&.${battleShellLineClasses.lg}`]: {
    height: BattleShellSizeMap.line.lg.height,

    [`& .${battleShellLineClasses.gridCenter}`]: {
      gap: 40,
    },

    [`&.${battleShellLineClasses.call}`]: {
      [`& .${battleShellLineClasses.gridCenter}`]: {
        gap: 24,
      },
    },
  },
}));

export const BattleShellLineGrid = styled('div', { name: 'BattleLine', slot: 'grid' })(() => ({
  flex: 1,
  display: 'grid',
  gap: 14,
  gridTemplateColumns: '120px auto 52px',
  alignItems: 'center',
}));

export const BattleShellLineGridCenter = styled('div', { name: 'BattleLine', slot: 'gridCenter' })(
  () => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 54px 1fr',
    gap: 14,
  })
);

export const BattleShellLineRightSide = styled('div', { name: 'BattleLine', slot: 'rightSide' })(
  () => ({
    width: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  })
);

export const BattleShellLineStatus = styled('div', { name: 'BattleLine', slot: 'status' })(
  ({ theme }) => ({
    width: 4,
    position: 'absolute',
    inset: '0 auto 0 0',
    height: '100%',
    background: theme.palette.grey.A5,

    [`&.${battleShellLineClasses.statusLose}`]: {
      background: theme.palette.error.main,
    },

    [`&.${battleShellLineClasses.statusWin}`]: {
      background: theme.palette.primary.main,
    },
  })
);
