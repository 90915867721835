import { ConnectDropTarget, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ApiGetBattlesMappedData, ApiGetCallsMappedData, BattleResult, Nft } from '~shared/api';
import { useSnackbar } from '~shared/lib/hooks';

import {
  NftDragItem,
  NftDragItemType,
  getCallSameRarityMessage,
  getNftByTokenId,
  isNftAvailableForMerge,
  useNftSelector,
} from '~entities/nft';
import { useEventModel } from '~entities/event';

// todo: fsd
import {
  checkWinstreakWarnDialogNotShown,
  useWinstreakWarningDialog,
} from '~features/event-dialog';

import { isCallEvent } from '../../lib';

interface UseDropBattleSlot {
  (props: {
    slot: BattleResult.HomeTeamWon | BattleResult.AwayTeamWon | BattleResult.Draw;
    event: ApiGetBattlesMappedData | ApiGetCallsMappedData;
    call?: boolean;
  }): {
    dragRef: ConnectDropTarget;
    isOver: boolean;
    canDrop: boolean;
  };
}

// todo: refactoring, maybe unite some logic from `useEventDrag` in model methods
export const useDropBattleSlot: UseDropBattleSlot = ({ slot, event, call }) => {
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { nfts } = useNftSelector();

  const { openCallEvent, openEvent } = useEventModel();
  const { openWinstreakWarningDialog } = useWinstreakWarningDialog();

  const acceptableRarity = call && isCallEvent(event) ? event.rarity : null;

  const handleOpenEvent = (nft: Nft) => {
    const cards = [nft];

    const instantParams = { choice: slot, cards };
    const battleParams = { ...instantParams, isViewMode: false };

    if (isCallEvent(event)) {
      openCallEvent(event, instantParams);
    } else {
      openEvent(event, battleParams);
    }
  };

  const [{ isOver, canDrop }, dragRef] = useDrop<NftDragItem, any, any>({
    accept: NftDragItemType.Place,
    drop: async (item: NftDragItem) => {
      const nft = getNftByTokenId(nfts, item.tokenId);

      if (nft) {
        const isZeroLivesRemaining = nft.livesRemaining === 0;

        // todo: make shared checks (useDropBattle, useDropBattleSlot, useDropBet have same validation)
        if (isZeroLivesRemaining) {
          openSnackbar({ type: 'error', message: "You can't bet card with 0 lives remaining" });

          return;
        }

        if (call) {
          if (typeof item.rarity === 'number' && acceptableRarity !== null) {
            if (item.rarity !== acceptableRarity) {
              openSnackbar({ message: getCallSameRarityMessage(acceptableRarity, t) });

              return;
            }
          }
        }

        const isWinstreakDialogNotShown = await checkWinstreakWarnDialogNotShown();

        // todo: make shared checks (useDropBattle, useDropBattleSlot, useDropBet have same validation)
        if (!isWinstreakDialogNotShown && isNftAvailableForMerge(nft).isAvailable) {
          openWinstreakWarningDialog(nft, slot, () => {
            handleOpenEvent(nft);
          });

          return;
        }

        handleOpenEvent(nft);
      }
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      let canDrop = true;

      if (typeof item?.rarity === 'number' && acceptableRarity !== null) {
        canDrop = item.rarity === acceptableRarity;
      }

      return {
        isOver: monitor.isOver(),
        canDrop,
      };
    },
  });

  return {
    isOver,
    dragRef,
    canDrop,
  };
};
