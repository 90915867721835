import { SyntheticEvent, useCallback } from 'react';

import { useSearchParamsState } from '~shared/lib/hooks';

import {
  EVENT_TAB_SEARCH_PARAM_KEY,
  EVENT_TYPE_TAB_SEARCH_PARAM_KEY,
  PERIOD_SEARCH_PARAM_KEY,
  VIEW_SEARCH_PARAM_KEY,
} from './config';

import { BattleEventTab, BattleEventTypeTab, BattlePeriod, BattleView } from './types';

const viewDefault = BattleView.Grid;
const periodDefault = BattlePeriod.Future;
const tabDefault = BattleEventTypeTab.Battles;

interface UseBattleListSettings {
  (): {
    view: BattleView;
    onChangeView: (event: SyntheticEvent | null, value: BattleView) => void;

    period: BattlePeriod;
    onChangePeriod: (event: SyntheticEvent | null, value: BattlePeriod) => void;

    eventTypeTab: BattleEventTypeTab;
    onChangeEventTypeTab: (event: SyntheticEvent | null, value: BattleEventTypeTab) => void;

    eventTab: BattleEventTab;
    onChangeEventTab: (event: SyntheticEvent | null, value: BattleEventTab) => void;
  };
}

export const useBattleListSettings: UseBattleListSettings = () => {
  const [view, setView] = useSearchParamsState<BattleView>(VIEW_SEARCH_PARAM_KEY, viewDefault);
  const [period, setPeriod] = useSearchParamsState<BattlePeriod>(
    PERIOD_SEARCH_PARAM_KEY,
    periodDefault
  );

  const [eventTypeTab, setEventTypeTab] = useSearchParamsState<BattleEventTypeTab>(
    EVENT_TYPE_TAB_SEARCH_PARAM_KEY,
    tabDefault
  );

  const [eventTab, setEventTab] = useSearchParamsState<BattleEventTab>(
    EVENT_TAB_SEARCH_PARAM_KEY,
    BattleEventTab.AllEvents
  );

  const onChangeView = useCallback(
    (event: SyntheticEvent | null, value: BattleView) => {
      setView(value);
    },
    [setView]
  );

  const onChangePeriod = useCallback(
    (event: SyntheticEvent | null, value: BattlePeriod) => {
      setPeriod(value);
    },
    [setPeriod]
  );

  const onChangeEventTypeTab = useCallback(
    (event: SyntheticEvent | null, value: BattleEventTypeTab) => {
      setEventTypeTab(value);
    },
    [setEventTypeTab]
  );

  const onChangeEventTab = useCallback(
    (event: SyntheticEvent | null, value: BattleEventTab) => {
      setEventTab(value);
    },
    [setEventTab]
  );

  return {
    view,
    onChangeView,

    period,
    onChangePeriod,

    eventTypeTab,
    onChangeEventTypeTab,

    eventTab,
    onChangeEventTab,
  };
};
