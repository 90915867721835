import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const UserCardsRoot = styled(Box, { name: 'UserCards', slot: 'root' })({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: 0,
  flexBasis: '100%',

  ['& > :not(:last-child)']: {
    marginBottom: '14px',
  },
});

export const UserCardsContentContainer = styled(Box, {
  name: 'UserCards',
  slot: 'contentContainer',
})({
  minHeight: 0,
  flexBasis: '100%',
  display: 'flex',
  flexDirection: 'column',
});
