import { FC } from 'react';
import { clsx } from 'clsx';

import { DateLabelShellProps } from '~shared/ui';

import { UIKitDateLabelRoot, dateLabelClasses } from './styled';

export const DateLabelShell: FC<DateLabelShellProps> = ({
  variant,
  size = 'lg',
  children,
  className,
  ...props
}) => {
  const computedClassName = clsx(
    dateLabelClasses.root,
    {
      [dateLabelClasses.active]: variant === 'active',
      [dateLabelClasses.live]: variant === 'live',
      [dateLabelClasses.primary]: variant === 'primary',

      [dateLabelClasses.sm]: size === 'sm',
      [dateLabelClasses.md]: size === 'md',
      [dateLabelClasses.lg]: size === 'lg',
    },
    className
  );

  return (
    <UIKitDateLabelRoot className={computedClassName} {...props}>
      {children}
    </UIKitDateLabelRoot>
  );
};
