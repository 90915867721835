import { Swiper, SwiperSlide } from 'swiper/react';

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const HorizontalListRoot = styled('div', { name: 'HorizontalList' })(() => ({
  display: 'flex',
  alignItems: 'stretch',
  overflow: 'hidden',
  ['& .swiper-wrapper']: {},
}));

export const HorizontalListSwiper = styled(Swiper)({});

export const HorizontalListSlide = styled(SwiperSlide)(() => ({
  '& > *': {
    height: '100%',
  },

  ['&.swiper-slide']: {
    width: 'max-content',
  },
}));

export const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '14px',

  [theme.breakpoints.down(1620)]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

HorizontalListSlide.displayName = 'SwiperSlide';
