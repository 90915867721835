import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

export const returnCardDropZoneClasses = {
  root: 'ReturnCardDropZone',
  highlight: 'ReturnCardDropZone-highlight',
};

export const Root = styled(motion.div, { name: 'ReturnCardDropZone' })(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey.A20}`,
    borderRadius: '6px',
    overflow: 'hidden',
    background: theme.palette.primary.light,
    zIndex: theme.zIndex.modalOverlap,
    transition: theme.transitions.create(['background', 'box-shadow']),
    marginBottom: '12px',
    flexShrink: '0',

    [`&.${returnCardDropZoneClasses.highlight}`]: {
      boxShadow: '0px 0px 9.75px rgba(46, 178, 255, 0.8)',
      background: theme.palette.primary.main,
    },
  };
});
