import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~shared/ui';

// todo: fsd
import { SellCard } from '~features/nft';
import { FixCard } from '~features/nft/fix-card';

import { useWriteContract } from '~entities/wallet';

import { useDispatch } from '~shared/lib/hooks';

import { isNftAvailableForSell, isNftShouldBeRecovered } from '../../../lib';
import { nftActions, useNftCardModel, useWrapNftAction } from '../../../model';

import { NftCardInfoActionsProps } from './types';
import { Root } from './styled';

export const NftCardInfoActions: FC<NftCardInfoActionsProps> = ({ nft, mobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onOpenCardInfo } = useNftCardModel();

  const { write: takeCard } = useWriteContract({
    contract: 'AuctionForMCN',
    method: 'takeCard',
    transactionName: 'Take Card',
    successMessage: `${t('Alerts.takeCard')}`,
    errorMessage: `${t('Errors.errorTakeCard')}`,
  });

  const handleTakeCard = async () => {
    await takeCard({ args: [nft.token_id] });
    dispatch(nftActions.takeCardFromAuction(nft.token_id));
  };

  const { handleWrapNftActions } = useWrapNftAction();

  const handleOpenCardInfo = () => {
    onOpenCardInfo(nft);
  };

  const buttonSize = mobile ? 'lg' : 'sm';

  if (nft.isOnAuction) {
    return (
      <Root onClick={handleWrapNftActions} $mobile={mobile}>
        {process.env.REACT_APP_FEATURE_FLAG_AUCTION_TAKE_CARD === 'true' &&
          nft.relatedAuction?.betsAcceptedUntil &&
          new Date() > nft.relatedAuction.betsAcceptedUntil && (
            <Button size={buttonSize} onClick={handleTakeCard}>
              {t('Wallet.takeCard')}
            </Button>
          )}
        <Button size={buttonSize} variant="outlined" onClick={handleOpenCardInfo}>
          {t('Other.info')}
        </Button>
      </Root>
    );
  }

  if (nft.isBlockedForEvent || nft.isOnEvent) {
    return (
      <Root onClick={handleWrapNftActions} $mobile={mobile}>
        <Button size={buttonSize} variant="outlined" onClick={handleOpenCardInfo}>
          {t('Other.info')}
        </Button>
      </Root>
    );
  }

  return (
    <Root onClick={handleWrapNftActions} $mobile={mobile}>
      {isNftAvailableForSell(nft) && (
        <SellCard size={buttonSize} nft={nft}>
          {t('Other.sell')}
        </SellCard>
      )}

      {isNftShouldBeRecovered(nft) && (
        <FixCard size={buttonSize} nft={nft}>
          {t('Other.fixBtn')}
        </FixCard>
      )}

      <Button size={buttonSize} variant="outlined" onClick={handleOpenCardInfo}>
        {t('Other.info')}
      </Button>

      {/* TODO: maybe we will have to get it back
       <RemoveCardFromEvent nft={nft} />
       */}
    </Root>
  );
};
