import { useCallback } from 'react';

import { useDispatch, useSearchParamsState } from '~shared/lib/hooks';

import { viewerActions } from '~entities/viewer';
import { walletActions } from '~entities/wallet';

import { ONBOARDING_STEP_PARAM_KEY } from '../lib';

import { useOnboardingPassedMutation } from './hooks';
import { OnboardingSteps } from './config';

export const useOnboardingModel = () => {
  const [stepParam, setStepParam, omitStepParam] = useSearchParamsState<OnboardingSteps | ''>(
    ONBOARDING_STEP_PARAM_KEY,
    ''
  );

  const { mutateAsync: setOnboardingPassed } = useOnboardingPassedMutation();
  const dispatch = useDispatch();

  const onOpen = useCallback(
    (step: any) => {
      setStepParam(step);
    },
    [setStepParam]
  );

  const onClose = useCallback(() => {
    omitStepParam();
  }, [omitStepParam]);

  const onOnboardingSubmit = useCallback(() => {
    onClose();

    setOnboardingPassed(true).then(() => {
      dispatch(viewerActions.updateData({ onboarding: true }));
      dispatch(walletActions.setWallet({ isOnboardingShownAfterConnect: false }));
    });
  }, [dispatch, onClose, setOnboardingPassed]);

  const onNextStep = useCallback(() => {
    const numericStep = Number(stepParam);

    setStepParam((numericStep + 1) as any as OnboardingSteps);
  }, [setStepParam, stepParam]);

  const onPreviousStep = useCallback(() => {
    const numericStep = Number(stepParam);

    setStepParam((numericStep - 1) as any as OnboardingSteps);
  }, [setStepParam, stepParam]);

  return {
    open: Object.values<any>(OnboardingSteps).includes(stepParam),

    stepParam,
    setStepParam,

    onOpen,
    onClose,
    onOnboardingSubmit,
    onNextStep,
    onPreviousStep,
  };
};
