import { Swiper, SwiperSlide } from 'swiper/react';

import { styled } from '~shared/lib/styles';

export const HorizontalListRoot = styled('div', { name: 'HorizontalList' })(() => ({
  display: 'flex',
  alignItems: 'stretch',
  overflow: 'hidden',
  marginTop: '30px',
  ['& .swiper-wrapper']: {},
}));

export const HorizontalListSwiper = styled(Swiper)({
  marginLeft: 0,
});

export const HorizontalListSlide = styled(SwiperSlide)(() => ({
  ['&.swiper-slide']: {
    width: 'max-content',
  },
}));

HorizontalListSlide.displayName = 'SwiperSlide';
