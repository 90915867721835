import { Tab as MuiBaseTab, tabClasses as muiBaseTabClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

import { Box } from '../../../layout';
import { iconClasses } from '../../../dataDisplay';

export const menuTabClasses = {
  ...muiBaseTabClasses,
  hovered: 'MenuTab-hovered',
  icon: 'MenuTab-icon',
  label: 'MenuTab-label',
};

export const UIKitMenuTab = styled(MuiBaseTab)(({ theme }) => ({
  background: 'none',
  border: 'none',

  display: 'inline-flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  flexShrink: 0,
  paddingLeft: 10,
  paddingRight: 10,
  color: theme.palette.grey.A50,
  cursor: 'pointer',
  transition: theme.transitions.create('all'),

  ['&:not(:first-child)']: {
    marginLeft: 6,
  },

  ['&:not(:last-child)']: {
    marginRight: 6,
  },

  [`&.${menuTabClasses.selected}, &:hover`]: {
    color: theme.palette.text.primary,
  },

  [`&.${menuTabClasses.disabled}`]: {
    color: theme.palette.grey.A20,
    cursor: 'default',
    opacity: 0.2,
  },

  [`&:hover:not(.${menuTabClasses.disabled}), 
    &:not(.${menuTabClasses.disabled}).${menuTabClasses.hovered}`]: {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const MenuTabLabel = styled(Box)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '14px',
});

export const MenuTabIcon = styled(Box)({
  marginRight: 6,

  [`& .${iconClasses.root}`]: {
    width: 28,
    height: 28,
  },
});
