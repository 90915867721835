import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

export const avatarClasses = {
  root: 'Avatar-root',
  img: 'Avatar-img',
  fallback: 'Avatar-fallback',

  hasSource: 'Avatar-hasSource',
};

interface UIKitAvatarRootProps {
  size?: number | string;
}

export const UIKitAvatarRoot = styled(Box, {
  name: 'Avatar',
  slot: 'root',
  shouldForwardProp(propName: keyof UIKitAvatarRootProps) {
    switch (propName) {
      case 'size':
        return false;
      default:
        return true;
    }
  },
})<UIKitAvatarRootProps>(({ size, theme }) => {
  return {
    background: theme.palette.paper.semi,
    color: theme.palette.grey.A50,
    lineHeight: 1,
    borderRadius: '100%',
    userSelect: 'none',

    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,

    width: size,
    height: size,
  };
});

export const UIKitAvatarImage = styled('img', { name: 'Avatar', slot: 'image' })(({ theme }) => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  color: 'transparent',
  borderRadius: '100%',
  transition: theme.transitions.create('opacity'),
}));

export const UIKitAvatarFallback = styled(Box, { name: 'Avatar', slot: 'fallback' })({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ['& > *']: {
    width: '50%',
    height: '50%',
  },
});
