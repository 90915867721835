/* eslint-disable react-extra/no-inline-styles */
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import { Box, Button, SelectField, SelectOption, Stack } from '~shared/ui';

import { MarketplaceItemType } from '~entities/marketplace';

import { MarketplaceCurrencyValue } from '../../lib';
import { MarketplaceCommonFormProps } from '../types';

import {
  useBuyCardForUSD,
  useBuyMysteryBoxForMatic,
  useMarketplaceItemPaymentInfo,
} from '../../model';

export const MarketplaceBuyMysteryBoxForm: FC<MarketplaceCommonFormProps> = ({
  rarity,
  currency,
  onChangeCurrency,
}) => {
  const { t } = useTranslation();

  const {
    /***/
    currencyOptions,
    currencyChangeAvailable,
    currentCurrencyItemInfo,
  } = useMarketplaceItemPaymentInfo({
    type: MarketplaceItemType.MysteryBox,
    currency,
    rarity,
  });

  const { initialOptions, ...payPalButtonProps } = useBuyCardForUSD({
    id: currentCurrencyItemInfo.id,
  });

  const { handleBuyMysteryBox } = useBuyMysteryBoxForMatic({
    price: currentCurrencyItemInfo.price,
    rarity,
  });

  const handleCurrencyChange = (event: any, value: any) => {
    if (onChangeCurrency) {
      onChangeCurrency(value);
    }
  };

  const renderPaymentButton = () => {
    switch (currency) {
      case MarketplaceCurrencyValue.Matic:
        return (
          <Button onClick={handleBuyMysteryBox} fullWidth>
            <Trans i18nKey="Other.buyFor" t={t}>
              Buy for {{ price: currentCurrencyItemInfo.price }}{' '}
              {{ currency: currentCurrencyItemInfo.label }}
            </Trans>
          </Button>
        );
      case MarketplaceCurrencyValue.USD:
        return (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              {...payPalButtonProps}
              style={{ color: 'blue', height: 44, label: 'buynow' }}
            />
          </PayPalScriptProvider>
        );
    }
  };

  return (
    <Stack spacing={14 / 8}>
      <SelectField
        value={currency}
        onChange={handleCurrencyChange}
        disabled={!currencyChangeAvailable}
      >
        {currencyOptions.map((currency) => (
          <SelectOption key={currency.value} value={currency.value}>
            {currency.label}
          </SelectOption>
        ))}
      </SelectField>

      <Box minHeight={44} maxHeight={44} position="relative" zIndex={0}>
        {renderPaymentButton()}
      </Box>
    </Stack>
  );
};
