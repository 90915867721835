import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

import { Backdrop } from '../Backdrop';
import { Paper } from '../Paper';

export const drawerClasses = {
  root: 'Drawer-root',
  paper: 'Drawer-paper',

  roundCorners: 'Drawer-roundCorners',
};

export const UIKitDrawerRoot = styled('div', { name: 'Drawer' })({
  position: 'absolute',
  inset: '0',

  [`&.${drawerClasses.roundCorners} .${drawerClasses.paper}`]: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});

export const UIKitDrawerBackdrop = styled(Backdrop, { name: 'Drawer', slot: 'backdrop' })({
  position: 'absolute',
  inset: 0,
});

export const UIKitDrawerScrollPaper = styled(motion.div, { name: 'Drawer', slot: 'scrollPaper' })({
  position: 'absolute',
  inset: 0,
  paddingTop: 48,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-end',

  pointerEvents: 'none',

  ['& > *']: {
    pointerEvents: 'all',
  },
});

export const UIKitDrawerPaper = styled(Paper, { name: 'Drawer', slot: 'paper' })({
  overflow: 'auto',
  position: 'relative',
  borderRadius: 0,
});

export const UIKItDrawerCloseContainer = styled('div', { name: 'Drawer', slot: 'closeContainer' })({
  position: 'absolute',
  top: 12,
  right: 12,
});
