import { noScrollbar, styled } from '~shared/lib/styles';

export const UserCardsTabsContainer = styled('div')(() => ({
  marginRight: -16,
  marginLeft: -16,
  paddingLeft: 16,
  flexShrink: 0,

  ['& > *']: {
    overflow: 'auto',
    ...noScrollbar,
  },
}));
