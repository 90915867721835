import { Fragment } from 'react';

import { Divider, Heading, Stack } from '~shared/ui';

import { useMediaQuery } from '~shared/lib/hooks';

import { slot } from '~pages/UIKitDemo/tabs/Battle';

import { WalletFilter } from './WalletFilter';
import { WalletContentPlaceholder } from './WalletContentPlaceholder';
import { WalletTableRow } from './WalletTableRow';

import { WalletFinanceCard, WalletHistoryRow } from './segments';

import { WalletHistoryRowProps } from './segments/WalletHistoryRow/types';

import { TableHeadingCell, WalletFinanceCardsContainer } from './styled';

export const ProfileWallet = () => {
  const isSmallView = useMediaQuery('sm');

  const hasContent = true;

  const content = (
    <Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Heading>History</Heading>

        <WalletFilter />
      </Stack>

      <WalletTableRow>
        <TableHeadingCell>Action</TableHeadingCell>
        <TableHeadingCell>Card</TableHeadingCell>
        {isSmallView ? null : <TableHeadingCell>Date</TableHeadingCell>}
        <TableHeadingCell>Income / outcome</TableHeadingCell>
      </WalletTableRow>

      <Divider />

      <Stack spacing={12 / 8}>
        {mockData.map((props, key) => (
          <WalletHistoryRow key={key} {...props} />
        ))}
      </Stack>
    </Fragment>
  );

  return (
    <Stack spacing={24 / 8} flex={1}>
      <WalletFinanceCardsContainer>
        <WalletFinanceCard type="maincoin" total={2300} income={3455} outcome={23444} />
        <WalletFinanceCard type="matic" total={2300} income={3455} outcome={23444} />
      </WalletFinanceCardsContainer>

      {hasContent ? content : <WalletContentPlaceholder />}
    </Stack>
  );
};

// prettier-ignore
const mockData: Array<WalletHistoryRowProps> = [
  { type: 'merge', cards: [slot, slot], date: new Date(), amount: 2, currency: 'maincoin', negative: true },
  { type: 'purchase', cards: slot, date: new Date(), amount: 4, currency: 'matic', negative: true },
  { type: 'auction', cards: slot, date: new Date(), amount: 4, currency: 'matic' },
  { type: 'unfreeze', cards: slot, date: new Date(), amount: 1, currency: 'unfreeze', negative: true },
  { type: 'freeze', cards: slot, date: new Date(), amount: 1, currency: 'unfreeze', negative: true },
  { type: 'repair', cards: slot, date: new Date(), amount: 10, currency: 'usdt', negative: true },
  { type: 'win', cards: slot, date: new Date(), amount: 12, currency: 'matic' },
  { type: 'lose', cards: slot, date: new Date(), amount: 12, currency: 'matic', negative: true },
];
