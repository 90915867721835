import { styled } from '~shared/lib/styles';
import { FormControlLabel, Icon, Typography } from '~shared/ui';

export const Root = styled('div', { name: 'EventDialogActions' })(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content auto max-content',
  alignItems: 'center',
  gap: 12,

  [theme.breakpoints.down('mobile')]: {
    gridTemplateColumns: '1fr',
    background: theme.palette.paper.main,
    position: 'sticky',
    bottom: 0,
    padding: '14px 16px',
    margin: '14px -16px 0',
    boxShadow: '0 0 16px 4px rgb(0 0 0 / 60%)',
    zIndex: 1,
  },
}));

/**
 * Make call
 */
export const MakeCallContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const MakeCallFormControlLabel = styled(FormControlLabel, {
  name: 'EventDialogActions',
  slot: 'makeCallFormControlLabel',
})(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'flex-start',
  },
}));

export const MakeCallQuestionMark = styled(Icon)(({ theme }) => ({
  cursor: 'help',
  color: theme.palette.text.secondary,
  marginTop: -0.25,
  marginLeft: 8,
}));

/**
 * Reward
 */
export const RewardBriefContainer = styled('div', {
  name: 'EventDialogActions',
  slot: 'RewardBriefContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: 5,

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
}));

export const YouCanWin = styled(Typography, {
  name: 'EventDialogActions',
  slot: 'youCanWin',
})(({ theme }) => ({
  fontSize: 13,
  lineHeight: 14 / 13,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 12,
    lineHeight: 14 / 12,
  },
}));

export const PotentialRewardAmount = styled(Typography, {
  name: 'EventDialogActions',
  slot: 'potentialRewardAmount',
})(({ theme }) => ({
  fontWeight: 500,
  lineHeight: 1,

  [theme.breakpoints.down('mobile')]: {
    fontSize: 12,
    lineHeight: 14 / 12,
  },
}));

/**
 * Buttons
 */
export const ButtonsContainer = styled('div', {
  name: 'EventDialogActions',
  slot: 'buttonsContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: 12,

  [theme.breakpoints.down('mobile')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));
