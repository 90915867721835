import { getAvatarAbsoluteUri } from '~shared/lib/utils';

import { ViewerAvatar } from '~entities/viewer';

export const transformAvatarRelativePathToViewerAvatar = (
  avatarRelativePath: string
): ViewerAvatar => {
  return {
    value: avatarRelativePath,
    src: getAvatarAbsoluteUri(avatarRelativePath),
  };
};
