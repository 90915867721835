import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '~shared/ui';

import { SellCardFormProps } from './types';
import { StyledBaseTextField } from './styled';

export const SellCardForm: FC<SellCardFormProps> = ({ form, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <FormProvider {...form}>
      <Stack
        onSubmit={onSubmit}
        component="form"
        direction="row"
        spacing={18 / 8}
        alignItems="start"
      >
        <StyledBaseTextField name="price" endAdornment="MCN" autoFocus type="number" />
        <Button type="submit">{t('Other.sellBtn')}</Button>
      </Stack>
    </FormProvider>
  );
};
