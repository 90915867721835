import { Box } from '~shared/ui';

import { styled } from '~shared/lib/styles';

export const battleCallSlotRootClasses = {
  isOver: 'BattleCallSlot-isOver',
  hidden: 'BattleCallSlot-hidden',
  disabled: 'BattleCallSlot-disabled',
};

export const BattleCallSlotRoot = styled(Box)(({ theme }) => ({
  width: 35,
  height: 50,
  border: `1px solid ${theme.palette.grey.A50}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  color: theme.palette.grey.A75,
  transition: theme.transitions.create(['background', 'border', 'opacity']),
  opacity: 1,

  [`&:not(.${battleCallSlotRootClasses.disabled}).${battleCallSlotRootClasses.isOver}`]: {
    background: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },

  [`&.${battleCallSlotRootClasses.hidden}`]: {
    opacity: 0,
  },

  [`&.${battleCallSlotRootClasses.disabled}`]: {
    opacity: 0.3,
  },
}));
