import { clsx } from 'clsx';
import { FC, forwardRef, useRef } from 'react';

import { useTabIndicator } from '~shared/lib/hooks';

import { tabClasses } from '../Tab/styled';

import { UIKitTabs, UIKitTabsContainer, UIKitTabsIndicator, tabsClasses } from './styled';
import { TabsProps } from './types';

const selector = `.${tabClasses.selected}`;

export const Tabs: FC<TabsProps> = forwardRef<HTMLDivElement, TabsProps>(
  ({ children, underlined = true, ...props }, containerRef) => {
    const ref = useRef<HTMLDivElement>(null);

    const computedClassName = clsx({
      [tabsClasses.underlined]: underlined,
    });

    const { position, onChange } = useTabIndicator({
      ref,
      onChange: props.onChange,
      value: props.value,
      selector,
    });

    return (
      <UIKitTabsContainer ref={containerRef} className={computedClassName}>
        <UIKitTabs {...props} ref={ref} onChange={onChange}>
          {children}
        </UIKitTabs>

        <UIKitTabsIndicator style={position} />
      </UIKitTabsContainer>
    );
  }
);
