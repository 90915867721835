import { customScrollbar, styled } from '~shared/lib/styles';

export const PrivacyPolicyRoot = styled('div')(({ theme }) => ({
  ['& p, ul, li']: {
    color: theme.palette.text.secondary,
  },

  a: {
    wordWrap: 'break-word',
  },
}));

export const PrivacyPolicyTableWrapper = styled('div')(() => ({
  overflowX: 'auto' as const,
  maxWidth: '100%',
  marginBottom: 20,

  ...customScrollbar,
}));

export const PrivacyPolicyTable = styled('table')(({ theme }) => ({
  minWidth: 700,
  textAlign: 'center',
  borderCollapse: 'collapse',

  ['& th']: {
    minWidth: 200,
  },

  ['& td, th']: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderColor: theme.palette.grey.A10,
    padding: 8,
  },
}));
