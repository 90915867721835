import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const MarketplaceListGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: 20,

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  [theme.breakpoints.down(928)]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [theme.breakpoints.down(674)]: {
    gridTemplateColumns: '1fr',
  },
}));
