import { apiInstance } from '../apiInstance';

import { mapGetBets } from './mappers';
import { BetsRoutes } from './routes';
import { ApiGetBets, ApiGetBetsBetRawData, ApiMakeBet, ApiMakeBetResponseData } from './types';

export const getBets: ApiGetBets = (params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get<Array<Array<ApiGetBetsBetRawData>>>(BetsRoutes.bets(), { params })
      .then(({ data }) => resolve(mapGetBets(data)))
      // .catch(reject);
      .catch((axiosError) => {
        if (axiosError.response.status === 400) {
          resolve(mapGetBets(axiosError.response.data));
        } else {
          reject(axiosError);
        }
      });
  });
};

export const postMakeBet: ApiMakeBet = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post<ApiMakeBetResponseData>(BetsRoutes.makeBet(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
