import { FC } from 'react';

import { format } from 'date-fns';

import { Icon, IconAssetNamesType, Stack, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { WalletTableRow } from '../../WalletTableRow';

import { WalletHistoryDate, WalletHistoryIconPlate, WalletHistoryRowAmount } from './styled';

import { WalletHistoryRowCurrencyType, WalletHistoryRowProps, WalletHistoryRowType } from './types';

export const WalletHistoryRow: FC<WalletHistoryRowProps> = ({
  type,
  currency,
  date,
  cards,
  amount,
  negative,
}) => {
  const isSmallView = useMediaQuery('sm');

  const dateContent = <WalletHistoryDate>{formatDate(date)}</WalletHistoryDate>;
  const labelContent = <Typography>{historyRowContentType[type].label}</Typography>;

  return (
    <WalletTableRow>
      <Stack direction="row" spacing={12 / 8} alignItems="center">
        <WalletHistoryIconPlate type={type}>
          <Icon name={historyRowContentType[type].icon} />
        </WalletHistoryIconPlate>
        {isSmallView ? (
          <Stack>
            {labelContent}
            {dateContent}
          </Stack>
        ) : (
          labelContent
        )}
      </Stack>
      <Stack direction="row" spacing={6 / 8}>
        {cards}
      </Stack>
      {isSmallView ? null : dateContent}
      <WalletHistoryRowAmount negative={negative}>
        <Typography component="span" fontWeight={400}>
          {negative ? '-' : '+'}
        </Typography>
        {amount} {historyRowCurrencyType[currency]}
      </WalletHistoryRowAmount>
    </WalletTableRow>
  );
};

const formatDate = (date: Date) => {
  return format(date, 'dd/MM/yyyy');
};

const historyRowCurrencyType: Record<WalletHistoryRowCurrencyType, string> = {
  maincoin: 'MCN',
  unfreeze: 'Unfreeze',
  matic: 'MATIC',
  usdt: 'USDT',
};

const historyRowContentType: Record<
  WalletHistoryRowType,
  { icon: IconAssetNamesType; label: string }
> = {
  merge: {
    icon: 'arrow-up',
    label: 'Merge cards',
  },
  purchase: {
    icon: 'wallet-marketplace',
    label: 'Buy card',
  },
  auction: {
    icon: 'wallet-marketplace',
    label: 'Buy card from auction',
  },
  unfreeze: {
    icon: 'snowflake',
    label: 'Unfreeze card',
  },
  freeze: {
    icon: 'wallet-marketplace',
    label: 'Buy freeze',
  },
  repair: {
    icon: 'fix',
    label: 'Repair card',
  },
  win: {
    icon: 'play',
    label: 'Battle won',
  },
  lose: {
    icon: 'play',
    label: 'Battle lost',
  },
};
