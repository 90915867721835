import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

export const Root = styled(motion.div, { name: 'MobileDialog', slot: 'root' })(({ theme }) => ({
  zIndex: 10,
  background: theme.palette.paper.secondary,
  position: 'absolute',
  inset: 0,
  overflowY: 'auto',
  maxHeight: '100%',
}));
