import { styled } from '~shared/lib/styles';

export const NotificationsListDelimiterClasses = {
  root: 'NotificationsListDelimiter-root',
  label: 'NotificationsListDelimiter-label',
  hr: 'NotificationsListDelimiter-hr',

  active: 'NotificationsListDelimiter-active',
};

export const NotificationsListDelimiterRoot = styled('div', { name: 'NotificationsListDelimiter' })(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'stretch',

    color: theme.palette.grey.A30,

    [`&.${NotificationsListDelimiterClasses.active}`]: {
      color: theme.palette.primary.light,
    },
  })
);

export const NotificationsListDelimiterLabel = styled('div', {
  name: 'NotificationsListDelimiter',
  slot: 'label',
})({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 16 / 12,
  whiteSpace: 'nowrap',
});

export const NotificationsListDelimiterHr = styled('div', {
  name: 'NotificationsListDelimiter',
  slot: 'hr',
})(() => ({
  marginLeft: 8,
  background: 'currentColor',
  height: 1,
  width: '100%',
}));
