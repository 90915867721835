import { styled } from '~shared/lib/styles';

import { LinearProgress, linearProgressClasses } from '../LinearProgress';

export const UIKitScaleRoot = styled(LinearProgress)(({ theme }) => ({
  width: 48,
  height: 18,
  borderRadius: 3,

  [`& .${linearProgressClasses.progress}`]: {
    borderRadius: 0,
    background: theme.palette.primary.main,
  },
}));
