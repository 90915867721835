import { useCallback } from 'react';

import { useDispatch } from '~shared/lib/hooks';

import { useAccountSelector } from './selectors';
import { accountActions } from './slice';

const { open, close } = accountActions;

export const useAccountModel = () => {
  const { ...state } = useAccountSelector();
  const dispatch = useDispatch();

  const onOpen = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  return { ...state, onOpen, onClose };
};
