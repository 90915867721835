import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const staticNftClasses = {
  root: 'StaticNft-root',
};

export const StaticNftRoot = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey.A50}`,
  borderRadius: 'calc(1em * 0.095)',
  minWidth: 35,
  minHeight: 50,
  display: 'flex',
  position: 'relative',
}));

export const StaticNftImage = styled('img')({
  width: 'auto',
  height: '100%',
  objectFit: 'contain',
});
