import { useDrop } from 'react-dnd';

import { useDispatch } from '~shared/lib/hooks';
import {
  NftDragItem,
  NftDragItemType,
  NftToMergeSerialNumber,
  nftActions,
  useNftCardModel,
} from '~entities/nft';

import { eventActions } from '~entities/event';

export const useUserCardsReturnCard = () => {
  const dispatch = useDispatch();

  const {
    isDropZoneOpen,
    nftsToMerge: [firstNftToMerge],
  } = useNftCardModel();

  const [, dropRef] = useDrop<NftDragItem, any, any>({
    accept: [NftDragItemType.MoveOrLeave, NftDragItemType.Leave],
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
    drop(item) {
      // todo: make all changes to another state trough model (useNftModel)
      dispatch(nftActions.setNftHidden({ nftIds: [item.tokenId], isHidden: false }));
      // todo: make all changes to another state trough model (useEventModel)
      dispatch(eventActions.setNftHidden({ nftIds: [item.tokenId], isHidden: false }));
      const isFirstNftRemovingFromMergeCell = item.tokenId === firstNftToMerge?.token_id;

      const nftToRemoveSerialNumber = isFirstNftRemovingFromMergeCell
        ? NftToMergeSerialNumber.First
        : NftToMergeSerialNumber.Second;

      dispatch(nftActions.removeNftToMerge([nftToRemoveSerialNumber]));
    },
  });

  return {
    dropRef,
    isDropZoneOpen,
  };
};
