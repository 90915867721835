import {
  OpenNotificationProps,
  OpenSnackbarMessageProps,
  OpenSnackbarWithOptionsProps,
} from '~shared/lib/complex';

export const isMessage = (
  data: OpenSnackbarMessageProps | OpenSnackbarWithOptionsProps | OpenNotificationProps
): data is string => {
  return typeof data === 'string';
};

export const isMessageWithOptions = (
  data: OpenSnackbarWithOptionsProps | OpenNotificationProps
): data is OpenSnackbarWithOptionsProps => {
  if (typeof data === 'object') {
    return 'message' in data;
  }

  return false;
};

export const isNotification = (
  data: OpenSnackbarWithOptionsProps | OpenNotificationProps
): data is OpenNotificationProps => {
  if (typeof data === 'object') {
    return 'element' in data;
  }

  return false;
};
