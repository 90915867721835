export enum MarketplaceCurrencyValue {
  Matic,
  USD,
}

export interface CurrencyOption {
  value: MarketplaceCurrencyValue;
  label: string;
}

export const getCurrencyLabel = (currency: MarketplaceCurrencyValue) => {
  switch (currency) {
    case MarketplaceCurrencyValue.Matic:
      return 'MATIC';
    case MarketplaceCurrencyValue.USD:
      return 'USD';
  }
};

export const CurrencyOptionMatic: CurrencyOption = {
  value: MarketplaceCurrencyValue.Matic,
  label: getCurrencyLabel(MarketplaceCurrencyValue.Matic),
} as const;

export const CurrencyOptionPayPal: CurrencyOption = {
  value: MarketplaceCurrencyValue.USD,
  label: getCurrencyLabel(MarketplaceCurrencyValue.USD),
} as const;
