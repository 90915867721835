import { Web3ReactHooks, initializeConnector } from '@web3-react/core';

import { WalletConnect } from '@web3-react/walletconnect';

import { Connector } from '@web3-react/types';

import { CoinbaseWallet } from '@web3-react/coinbase-wallet';

import { MetaMask } from '@web3-react/metamask';

import { isCoinbaseInjected, isMetamaskInjected } from '../lib';

import { Connection, ConnectionType, SupportedChainId } from './types';

export const RPC_URLS = {
  [SupportedChainId.Polygon]: [process.env.REACT_APP_NODE_RPC_URL],
};

// Avoid testing for the best URL by only passing a single URL per chain.
// Otherwise, WC will not initialize until all URLs have been tested (see getBestUrl in web3-react).
const RPC_URLS_WITHOUT_FALLBACKS = Object.entries(RPC_URLS).reduce(
  (map, [chainId, urls]) => ({
    ...map,
    [chainId]: urls[0],
  }),
  {}
);

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions })
);

export const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        qrcode: true,
        rpc: RPC_URLS_WITHOUT_FALLBACKS,
      },
    })
);

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: RPC_URLS[SupportedChainId.Polygon][0],
        // @ts-ignore
        appName: 'Maincard',
        reloadOnDisconnect: false,
      },
    })
);

export const connections: Record<ConnectionType, Connection> = {
  [ConnectionType.Injected]: {
    name: 'Metamask',
    connector: web3Injected,
    hooks: web3InjectedHooks,
    type: ConnectionType.Injected,
    icon: 'wallet-metamask',
    installed: isMetamaskInjected(),
    deepLink: 'https://metamask.app.link/dapp/app.maincard.io/',
    downloadLink:
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  },
  [ConnectionType.WalletConnect]: {
    name: 'WalletConnect',
    connector: web3WalletConnect,
    hooks: web3WalletConnectHooks,
    type: ConnectionType.WalletConnect,
    icon: 'wallet-walletconnect',
    installed: true,
  },
  [ConnectionType.Coinbase]: {
    name: 'Coinbase',
    connector: web3CoinbaseWallet,
    hooks: web3CoinbaseWalletHooks,
    type: ConnectionType.Coinbase,
    icon: 'wallet-coinbase',
    installed: isCoinbaseInjected(),
    deepLink: 'https://go.cb-w.com/dapp?cb_url=https://app.maincard.io/',
  },
};

export const connectors: Array<[Connector, Web3ReactHooks]> = Object.values(connections).map(
  ({ connector, hooks }) => [connector, hooks]
);
