import { styled } from '~shared/lib/styles';

import { ButtonIcon, buttonIconClasses } from '../../controls';
import { Box } from '../../layout';

export const alertClasses = {
  root: 'Alert-root',

  info: 'Alert-info',
  error: 'Alert-error',
};

export const UIKitAlertRoot = styled(Box, {
  name: 'Alert',
  slot: 'content',
})(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '16px 10px',
  borderRadius: 8,

  display: 'flex',
  alignItems: 'start',

  [`&.${alertClasses.info}`]: {
    background: theme.palette.info.main,
  },

  [`&.${alertClasses.error}`]: {
    background: theme.palette.error.main,
  },
}));

export const UIKitAlertIcon = styled(Box, { name: 'Alert', slot: 'icon' })({
  marginRight: 10,
  display: 'flex',
});

export const UIKitAlertMessage = styled(Box, { name: 'Alert', slot: 'message' })({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 20 / 14,
  overflow: 'hidden',
  wordBreak: 'break-word',

  flex: 1,
});

export const UIKitAlertAction = styled(Box, { name: 'Alert', slot: 'action' })({
  display: 'flex',
  cursor: 'pointer',
  flexShrink: 0,
  marginLeft: 12,
});

export const UIKitCloseButton = styled(ButtonIcon, { name: 'Alert', slot: 'closeButton' })(
  ({ theme }) => ({
    [`&.${buttonIconClasses.plain}`]: {
      color: theme.palette.text.secondary,

      [`&:hover:not(.${buttonIconClasses.disabled})`]: {
        color: theme.palette.text.primary,
      },
    },
  })
);
