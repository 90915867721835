import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardBattlesModel } from '~entities/bets/model/model';

import { useMediaQuery } from '~shared/lib/hooks';
import { CircularDiagram, Heading, SelectOption, Stack } from '~shared/ui';

import { useBetsDashboardQuery } from '../../model';

import { DashboardBattlesTable } from './DashboardBattlesTable';
import { Root, StyledSelectField } from './styled';
import { DashboardBattlesProps } from './types';

export const DashboardBattles: FC<DashboardBattlesProps> = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('sm');

  const { period, handleChangePeriod } = useDashboardBattlesModel();
  const { dashboard } = useBetsDashboardQuery(period);

  return (
    <Root>
      <Stack display="grid" gridTemplateColumns="auto 90px" justifyContent="space-between">
        <Heading>{t('Tabs.myBattles')}</Heading>
        <StyledSelectField defaultValue="all" onChange={handleChangePeriod}>
          <SelectOption value="all">{t('Tabs.all')}</SelectOption>
          <SelectOption value="week">{t('Other.week')}</SelectOption>
          <SelectOption value="month">{t('Other.month')}</SelectOption>
        </StyledSelectField>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={isMobile ? 'start' : 'center'}
        mt="10px"
      >
        <DashboardBattlesTable
          total={dashboard.total}
          wins={dashboard.wins}
          lose={dashboard.loses}
          future={dashboard.future}
        />
        <CircularDiagram label={t('Rankings.accuracy')} value={dashboard.accuracy} />
      </Stack>
    </Root>
  );
};
