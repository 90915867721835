import { FC, Fragment, cloneElement, useEffect, useMemo, useRef, useState } from 'react';

import { useSwitcher } from '~shared/lib/hooks';

import { TooltipProps } from './types';
import { UIKitTooltipPaper, UIKitTooltipPopper, tooltipClasses } from './styled';

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  modifiers = [],
  title,
  disablePortal = true,
}) => {
  const open = useSwitcher(false);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const childrenRef = useRef<HTMLElement>();

  useEffect(() => {
    const element = childrenRef.current;

    if (element) {
      element?.addEventListener('mouseover', open.switchOn);
      element?.addEventListener('mouseout', open.switchOff);
    }

    return () => {
      if (element) {
        element?.removeEventListener('mouseover', open.switchOn);
        element?.removeEventListener('mouseout', open.switchOff);
      }
    };
  }, [open.switchOff, open.switchOn]);

  const popperModifiers = useMemo(
    () => [
      { name: 'offset', options: { offset: [0, 7] } },
      { name: 'arrow', enabled: Boolean(arrowRef), options: { element: arrowRef, padding: 4 } },
      ...modifiers,
    ],
    [arrowRef, modifiers]
  );

  return (
    <Fragment>
      {cloneElement(children, { ...children?.props, ref: childrenRef })}

      <UIKitTooltipPopper
        placement="top"
        modifiers={popperModifiers}
        anchorEl={childrenRef.current}
        open={open.value}
        transition
        disablePortal={disablePortal}
      >
        {({ TransitionProps }: any) => {
          const { in: open, onEnter, onExited } = TransitionProps;

          return (
            <UIKitTooltipPaper
              variants={motionVariants}
              animate={open ? AnimationVariant.Visible : AnimationVariant.Hidden}
              initial={AnimationVariant.Hidden}
              onAnimationStart={open ? onEnter : undefined}
              onAnimationComplete={!open ? onExited : undefined}
            >
              {title}
              <div className={tooltipClasses.arrow} ref={setArrowRef}>
                1
              </div>
            </UIKitTooltipPaper>
          );
        }}
      </UIKitTooltipPopper>
    </Fragment>
  );
};

const motionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, y: 5, transition: { duration: 0.2, type: 'tween' } },
  [AnimationVariant.Visible]: { opacity: 1, y: 0, transition: { duration: 0.2, type: 'tween' } },
};
