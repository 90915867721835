import React, { FC, Fragment } from 'react';

import { Divider, Heading, Shimmer, Stack } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { BattleShimmer, BattleVerticalList } from '~entities/battle';

import { BattleSeparatedHorizontalListProps } from './types';

export const BattleSeparatedHorizontalList: FC<BattleSeparatedHorizontalListProps> = ({
  passedList,
  currentList,

  loading,
  divider,
}) => {
  const { isMobile } = useMediaQuery();

  const hasCurrentEvents = currentList.length > 0;
  const hasPastEvents = passedList.length > 0;

  if (loading) {
    const headingShimmer = (
      <Shimmer height={isMobile ? 24 : 30}>
        <rect x="0" y="0" rx="12" ry="12" width="164" height={isMobile ? 24 : 30} />
      </Shimmer>
    );

    return (
      <Stack spacing={14 / 8}>
        {headingShimmer}

        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />

        {headingShimmer}

        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
        <BattleShimmer variant="line" />
      </Stack>
    );
  }

  return (
    <Fragment>
      {divider && <Divider mt="0 !important" />}

      <Stack spacing={14 / 8}>
        {hasCurrentEvents && (
          <Heading
            variant="h1"
            fontSize={isMobile ? 18 : 22}
            lineHeight={isMobile ? 24 / 18 : 30 / 22}
          >
            Future & Live
          </Heading>
        )}

        <BattleVerticalList loading={false}>{currentList}</BattleVerticalList>

        {hasPastEvents && (
          <Heading
            variant="h1"
            fontSize={isMobile ? 18 : 22}
            lineHeight={isMobile ? 24 / 18 : 30 / 22}
          >
            Past
          </Heading>
        )}

        <BattleVerticalList loading={false}>{passedList}</BattleVerticalList>
      </Stack>
    </Fragment>
  );
};
