import { FC, forwardRef } from 'react';

import { FormControl, FormHelperText, FormLabel, Select } from '~shared/ui';

import { SelectFieldProps } from './types';

export const SelectField: FC<SelectFieldProps> = forwardRef(
  (
    {
      helperTextProps,
      helperText,

      label,
      labelProps,

      ...props
    },
    ref
  ) => {
    return (
      <FormControl>
        {label && <FormLabel {...labelProps}>{label}</FormLabel>}
        <Select ref={ref} {...props} />
        {helperText && (
          <FormHelperText {...helperTextProps} error={props.error}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
