import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

export const formHelperTextClasses = {
  error: 'MuiFormHelperText-error',
};

export const UIKitFormHelperText = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '14px',

  marginTop: 8,

  [`&.${formHelperTextClasses.error}`]: {
    color: theme.palette.error.main,
  },
}));
