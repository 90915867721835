import { FC } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';

import { BaseTextField, Icon, IconAssetNamesType, Stack, Typography } from '~shared/ui';
import { validatePassword } from '~shared/lib/validation';
import { Theme } from '~shared/lib/styles';

import { CreatePasswordFieldProps } from './types';

export const CreatePassword: FC<CreatePasswordFieldProps> = ({ name = 'password' }) => {
  const { t } = useTranslation();

  const createPasswordValue = useWatch({ name }) || '';
  const isValidating = !!useFormState().errors.password;
  const { palette } = useTheme<Theme>();
  const { passwordValidationMessage, passwordRules } = useTranslateValidationMassege();

  return (
    <Stack spacing={16 / 8}>
      <BaseTextField
        name={name}
        label={t('Other.createPassword')}
        type="password"
        required
        rules={{
          validate: (password) => validatePassword(password) || passwordValidationMessage,
        }}
      />

      <Stack spacing={14 / 8} fontSize={12} lineHeight={14 / 12}>
        <Stack spacing={4 / 8}>
          {passwordRules.map((rule, index) => {
            const valid = rule.validate(createPasswordValue);

            const icon: IconAssetNamesType = valid ? 'check' : 'bullet';
            const color = valid ? palette.success.main : palette.grey.A50;

            const validationIcon: IconAssetNamesType = valid ? 'check' : 'x';
            const validationColor = valid ? palette.success.main : palette.error.main;

            return (
              <Typography key={index} color={isValidating ? validationColor : color}>
                <Icon
                  name={isValidating ? validationIcon : icon}
                  size={18}
                  color={
                    isValidating
                      ? validationColor
                      : valid
                      ? palette.success.main
                      : palette.text.primary
                  }
                />
                {` ${rule.label}`}
              </Typography>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

const useTranslateValidationMassege = () => {
  const { t } = useTranslation();

  // todo: i18n interpolation
  const passwordValidationMessage = `${t(
    'Other.incorrectPassword'
  )} ! @ # $ % ^ * & ( ) { } [ ] : ; < > , . ? / ~ _ + - = | \\`;

  const passwordRules = [
    {
      label: `${t('Other.oneUppercase')}`,
      validate: (password: string) => /[A-Z]+/.test(password),
    },
    {
      label: `${t('Other.oneLowercase')}`,
      validate: (password: string) => /[a-z]+/.test(password),
    },
    {
      label: `${t('Other.oneDigit')}`,
      validate: (password: string) => /\d+/.test(password),
    },
    {
      label: `${t('Other.atLeast')}`,
      validate: (password: string) => password.length >= 8,
    },
  ];

  return {
    passwordValidationMessage,
    passwordRules,
  };
};
