import { styled } from '~shared/lib/styles';
import { Typography } from '~shared/ui';

export const EventDialogTeamsNameRoot = styled(Typography, {
  name: 'EventDialogContent',
  slot: 'eventTeamsName',
})(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 18 / 16,
  minHeight: 18,

  overflow: 'hidden',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.down('mobile')]: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 15 / 13,
    minHeight: 15,
  },
}));

interface EventDialogTeamsNameCommonProps {
  isOverflowing: boolean;
}

export const EventDialogTeamsNameDefaultName = styled('span')<EventDialogTeamsNameCommonProps>(
  ({ isOverflowing, theme }) => ({
    position: 'absolute',
    inset: 0,

    overflow: 'hidden',
    whiteSpace: 'nowrap',

    opacity: isOverflowing ? 0 : 1,

    height: 18,

    [theme.breakpoints.down('mobile')]: {
      height: 15,
    },
  })
);

export const EventDialogTeamsNameShortName = styled('span')<EventDialogTeamsNameCommonProps>(
  ({ isOverflowing }) => ({
    display: isOverflowing ? 'block' : 'none',
  })
);
