import { styled } from '~shared/lib/styles';

export const Root = styled('div')(({ theme }) => ({
  background: theme.palette.paper.secondary,
  display: 'grid',

  gridTemplateColumns: 'repeat(auto-fit, minmax(1px, 1fr))',
  gap: 10,

  padding: '14px 16px 14px',
  margin: '-14px -16px -14px',

  [theme.breakpoints.down('mobile')]: {
    padding: '14px 16px 14px',
    margin: '-14px -16px -14px',
  },
}));
