import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogTitle, DragCell, Icon, Stack, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCard, NftDragItemType } from '~entities/nft';

import { useDropForMerge, useMerge, useMergeModel } from '../../../model';

export const MergeCardsDialog: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const {
    /***/
    mergeDialogOpen,

    firstNftToMerge,
    secondNftToMerge,

    openCardsToMergeSelection,
    cancelCardsMerge,
    removeAllCards,

    handleSuccessMerge,
  } = useMergeModel();

  const { dropRef, isFirstCellHighlighted, isSecondCellHighlighted } = useDropForMerge();

  const { mergePrice, isMergeAvailable, merge } = useMerge(handleSuccessMerge);

  return (
    <Dialog open={mergeDialogOpen} onClose={cancelCardsMerge} width={440}>
      <Stack alignItems="center" spacing={(isMobile ? 20 : 24) / 8} ref={dropRef}>
        <DialogTitle>{t('Other.mergeHead')}</DialogTitle>

        <Typography textAlign="center">{t('Other.mergeText')}</Typography>

        <Stack spacing={56 / 8} direction="row" justifyContent="center" py={24 / 8}>
          <DragCell
            isHighlighted={isFirstCellHighlighted}
            isPlacedToCell={Boolean(firstNftToMerge)}
            onClick={openCardsToMergeSelection(isMobile)}
          >
            {firstNftToMerge ? (
              <NftCard dragKey={NftDragItemType.Leave} nft={firstNftToMerge} />
            ) : (
              <Icon opacity={0.75} name="plus" />
            )}
          </DragCell>

          <DragCell
            isHighlighted={isSecondCellHighlighted}
            isPlacedToCell={Boolean(secondNftToMerge)}
            onClick={openCardsToMergeSelection(isMobile)}
          >
            {secondNftToMerge ? (
              <NftCard dragKey={NftDragItemType.Leave} nft={secondNftToMerge} />
            ) : (
              <Icon opacity={0.75} name="plus" />
            )}
          </DragCell>
        </Stack>

        <Stack direction="row" spacing={12 / 8} justifyContent="center">
          <Button disabled={!isMergeAvailable} onClick={merge}>
            {t('Other.merge')} {mergePrice && isMergeAvailable ? `(${mergePrice} Matic)` : ''}
          </Button>
          <Button variant="outlined" onClick={cancelCardsMerge}>
            {t('Account.cancelBtn')}
          </Button>
        </Stack>

        {isMobile && (firstNftToMerge || secondNftToMerge) && (
          <Button variant="text" size="md" onClick={removeAllCards}>
            Remove all cards
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};
