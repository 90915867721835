import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisconnect } from '~entities/wallet';

import { palette } from '~shared/lib/styles/palette';
import { ConfirmationDialog, Stack, Typography } from '~shared/ui';

import { DisconnectWalletDialogIllustration } from './styled';
import { DisconnectWalletDialogProps } from './types';

export const DisconnectWalletDialog: FC<DisconnectWalletDialogProps> = ({ ...props }) => {
  const { t } = useTranslation();

  const disconnect = useDisconnect();

  return (
    <ConfirmationDialog
      {...props}
      backgroundColor={palette.paper.secondary}
      title={t('Other.disconnect')}
      targetButtonText={t('Other.disconnectBtn')}
      cancelButtonText={t('SignOut.no')}
      onConfirm={disconnect}
      width={440}
    >
      <Stack spacing={24 / 8}>
        <Typography>{t('Other.upText')}</Typography>

        <DisconnectWalletDialogIllustration
          src="/assets/auth/disconnect-wallet-illustration.png"
          alt="Disconnect wallet warning"
        />

        <Typography>{t('Other.bottomText')}</Typography>
      </Stack>
    </ConfirmationDialog>
  );
};
