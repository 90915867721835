import { Signature, Signer, ethers } from 'ethers';

import { useDispatch } from '~shared/lib/hooks';
import { NFT__factory } from '~shared/contracts';
import { getProvider } from '~shared/lib/utils';

import { useWalletSelector } from './selectors';
import { walletActions } from './slice';
import { useConnect } from './hooks';
import { ConnectionType } from './types';

type SignGaslessTxMessageParams<Value = any> = {
  types: Array<string>;
  values: Array<Value>;
  signer: Signer;
};

export const signGaslessTxMessage = async <Value>({
  types,
  values,
  signer,
}: SignGaslessTxMessageParams<Value>): Promise<Signature> => {
  const solidityPack = ethers.utils.solidityPack(types, values);
  const keccak256 = ethers.utils.keccak256(solidityPack);

  const message = ethers.utils.arrayify(keccak256);

  const signedMessage = ethers.utils.splitSignature(await signer.signMessage(message));

  return signedMessage;
};

export const isNftApproved = async (tokenId: string) => {
  const provider = getProvider();
  const nftContract = NFT__factory.connect(process.env.REACT_APP_ADDRESS_SK_CARD, provider);
  const approvedAddress = await nftContract.getApproved(tokenId);

  return Number(approvedAddress) !== 0;
};

export const useWalletConnectedDialogModel = () => {
  const { isWalletConnectedDialogOpen } = useWalletSelector();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(walletActions.setWalletConnectedDialog(false));
  };

  return {
    open: isWalletConnectedDialogOpen,
    handleClose,
  };
};

export const useConnectWalletDialogModel = () => {
  const { isConnectWalletDialogOpen: open } = useWalletSelector();
  const dispatch = useDispatch();
  const { connect } = useConnect();

  const handleConnect = (connection: ConnectionType) => async () => {
    await connect(connection);
    handleClose();
  };

  const handleClose = async () => {
    dispatch(walletActions.setWallet({ isConnectWalletDialogOpen: false }));
  };

  return {
    open,
    handleConnect,
    handleClose,
  };
};
