import { useState } from 'react';
import { useDebounce } from 'react-use';

interface UseDebouncedBooleanTransition {
  (value: boolean, config?: { strategy?: 'start' | 'end' | 'both'; timeout?: number }): boolean;
}

export const useDebouncedBooleanTransition: UseDebouncedBooleanTransition = (
  value: boolean,
  { timeout = 200, strategy = 'start' } = {}
) => {
  const [debouncedValue, setDebouncedValue] = useState<boolean>(value);

  useDebounce(() => setDebouncedValue(value), timeout, [value]);

  switch (strategy) {
    case 'start':
      return value ? debouncedValue : value;
    case 'end':
      return value ? value : debouncedValue;
    case 'both':
      return debouncedValue;
  }
};
