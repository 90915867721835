import { useTheme } from '@mui/system';

import { Backdrop, makeBackdropTransition } from '~shared/ui';
import { Theme } from '~shared/lib/styles';

import { useBattleModel } from '~entities/battle';
import { useDebouncedBooleanTransition } from '~shared/lib/hooks';

const BackdropTransition = makeBackdropTransition(Backdrop, true);

export const NftDragBackdropConstructor = () => {
  const theme = useTheme<Theme>();

  const debouncedIsOverlapBackdrop = useDebouncedBooleanTransition(
    useBattleModel().isOverlapBackdrop
  );

  return (
    <BackdropTransition
      open={debouncedIsOverlapBackdrop}
      style={{
        zIndex: theme.zIndex.modal,
        pointerEvents: debouncedIsOverlapBackdrop ? 'auto' : 'none',
      }}
    />
  );
};
