import { styled } from '~shared/lib/styles';

export const WalletConnectedDialogIllustration = styled('img', {
  name: 'WalletConnectedDialog',
  slot: 'illustration',
})(({ theme }) => ({
  width: '100%',
  height: '128px',
  [theme.breakpoints.down('xs')]: {
    height: '92px',
  },
}));
