import { FC, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs, TabsList } from '~shared/ui';
import { useSearchParamsState } from '~shared/lib/hooks';

import { useAuctionModel } from '~features/auction';

import { AuctionsListContainer } from './AuctionsListContainer';

import { AuctionTab } from './types';

export const AuctionsList: FC = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useSearchParamsState<AuctionTab>('auctionTab', AuctionTab.All);
  const { auctionCardsAll, auctionCardsMyBets, auctionCardsMySales } = useAuctionModel();

  const handleChangeTab = useCallback(
    (event: SyntheticEvent | null, value: any) => {
      setTab(value as AuctionTab);
    },
    [setTab]
  );

  const tabs = {
    [AuctionTab.All]: (
      <AuctionsListContainer auctionCards={auctionCardsAll} type={AuctionTab.All} />
    ),
    [AuctionTab.MyBids]: (
      <AuctionsListContainer auctionCards={auctionCardsMyBets} type={AuctionTab.MyBids} />
    ),
    [AuctionTab.MySales]: (
      <AuctionsListContainer auctionCards={auctionCardsMySales} type={AuctionTab.MySales} />
    ),
  };

  return (
    <Stack spacing={32 / 8} flex={1}>
      <Tabs value={tab} onChange={handleChangeTab}>
        <TabsList>
          <Tab value={AuctionTab.All}>{t('Tabs.all')}</Tab>
          <Tab value={AuctionTab.MyBids}>{t('Tabs.myBids')}</Tab>
          <Tab value={AuctionTab.MySales}>{t('Tabs.mySales')}</Tab>
        </TabsList>
      </Tabs>

      {tabs[tab]}
    </Stack>
  );
};
