import { FC } from 'react';

import { BattleResult } from '~shared/api';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCard, NftDragItemType } from '~entities/nft';
import { isDrawSlotAvailable } from '~entities/battle';
import { useEventModel } from '~entities/event';

import { useEventDrag } from '../../../../../model';

import { EventChoice } from '../EventChoice';

import { EventDragCellsContainer } from './styled';
import { EventSingleCardProps } from './types';

export const EventSingleCard: FC<EventSingleCardProps> = () => {
  const { isMobile } = useMediaQuery();

  const { event, choice, cards, onAddCards } = useEventModel();

  const homeTeamCell = useEventDrag({ result: BattleResult.HomeTeamWon });
  const drawCell = useEventDrag({ result: BattleResult.Draw });
  const awayTeamCell = useEventDrag({ result: BattleResult.AwayTeamWon });

  // todo: fix
  if (!event) {
    return null;
  }

  const isDrawResultAvailable = isDrawSlotAvailable(event.sport);

  const getNftForAppropriateCell = (result: BattleResult) => {
    if (result !== choice) {
      return null;
    }

    const nft = cards[0];

    if (nft) {
      return <NftCard nft={nft} dragKey={NftDragItemType.MoveOrLeave} />;
    }

    return null;
  };

  return (
    <EventDragCellsContainer>
      <EventChoice
        nft={getNftForAppropriateCell(BattleResult.HomeTeamWon)}
        dropRef={homeTeamCell.dropRef}
        isHighlighted={homeTeamCell.isCellHighlighted}
        onAddCards={onAddCards(BattleResult.HomeTeamWon, isMobile)}
        isReturn={homeTeamCell.isReturnCardToCell}
      />
      {isDrawResultAvailable && (
        <EventChoice
          nft={getNftForAppropriateCell(BattleResult.Draw)}
          dropRef={drawCell.dropRef}
          isHighlighted={drawCell.isCellHighlighted}
          onAddCards={onAddCards(BattleResult.Draw, isMobile)}
          isReturn={drawCell.isReturnCardToCell}
        />
      )}
      <EventChoice
        nft={getNftForAppropriateCell(BattleResult.AwayTeamWon)}
        dropRef={awayTeamCell.dropRef}
        isHighlighted={awayTeamCell.isCellHighlighted}
        onAddCards={onAddCards(BattleResult.AwayTeamWon, isMobile)}
        isReturn={awayTeamCell.isReturnCardToCell}
      />
    </EventDragCellsContainer>
  );
};
