import { styled } from '~shared/lib/styles';

export const NotificationCenterRoot = styled('div', { name: 'NotificationsCenter' })(
  ({ theme }) => ({
    padding: '20px 16px',
    justifyItems: 'baseline',
    flex: 1,

    display: 'flex',
    flexDirection: 'column',

    ['& > *:not(:first-child)']: {
      marginTop: 24,
    },
  })
);
