import { FC } from 'react';

import { DateLabel } from '~shared/ui';
import { BattleChoiceId, BattleResult, BattleResultChoice } from '~shared/api';

import { NftCardsStack, StaticNftCard } from '~entities/nft';
import { useEventModel } from '~entities/event';
import { useViewerModel } from '~entities/viewer';

import { isDrawSlotAvailable, leagueToLogoMap, useBattleDragHighlight } from '../../../lib';
import { BattleShellFactory, BattleSlot, BattleTeam, BattleVersus } from '../../factory';
import { useBattleModel, useDropBattle } from '../../../model';

import { BattleProps } from './types';

export const Battle: FC<BattleProps> = ({ variant = 'line', size, ...event }) => {
  const { date, league, homeTeam, awayTeam, id, sport, relatedNft } = event;

  const { isOverlapBackdrop } = useBattleModel();
  const highlightProps = useBattleDragHighlight(!isOverlapBackdrop);

  const { dragRef } = useDropBattle({
    enabled: !!relatedNft,
    choice: relatedNft?.choice,
    cards: relatedNft?.cards ?? [],
    event,
  });

  const { openEvent } = useEventModel();
  const { avatar } = useViewerModel();

  const handleOpenEvent = () => {
    const additionalParams = !!relatedNft
      ? { isViewMode: true, cards: relatedNft?.cards as any, choice: relatedNft?.choice }
      : { isViewMode: false };

    openEvent(event, additionalParams);
  };

  const renderStack = () => {
    if (relatedNft?.cards) {
      return (
        <NftCardsStack>
          {relatedNft.cards.map((card) => (
            <StaticNftCard key={card.token_id!} tokenId={card.token_id!} />
          ))}
        </NftCardsStack>
      );
    }
  };

  const renderSlotByChoiceId = (slot: BattleResultChoice) => {
    if (!relatedNft) {
      return <BattleSlot event={event} slot={slot} show={highlightProps.highlight} />;
    }

    if (slot === relatedNft.choice) {
      return renderStack();
    }
  };

  const getSlotUserAvatar = (slot: BattleResultChoice) => {
    if (relatedNft && slot === relatedNft.choice) {
      return avatar?.src;
    }
  };

  const renderVersusSlot = () => {
    if (relatedNft) {
      if (BattleChoiceId.Draw.valueOf() === relatedNft.choice.valueOf()) {
        return renderStack();
      }
    } else {
      if (isDrawSlotAvailable(sport)) {
        if (highlightProps.highlight) {
          return (
            <BattleSlot event={event} slot={BattleResult.Draw} show={highlightProps.highlight} />
          );
        }
      }

      return null;
    }
  };

  return (
    <BattleShellFactory
      {...highlightProps}
      overlapBackdropAvailable
      ref={dragRef}
      onClick={handleOpenEvent}
      variant={variant}
      size={size}
      eventId={id}
      homeTeam={
        <BattleTeam
          key="home"
          players={homeTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          slot={renderSlotByChoiceId(BattleResult.HomeTeamWon)}
          slotUserAvatar={getSlotUserAvatar(BattleResult.HomeTeamWon)}
        />
      }
      awayTeam={
        <BattleTeam
          key="away"
          players={awayTeam.players.map((player) => ({
            name: player.name,
            shortName: player.shortName,
            avatar: player.logo,
          }))}
          slot={renderSlotByChoiceId(BattleResult.AwayTeamWon)}
          slotUserAvatar={getSlotUserAvatar(BattleResult.AwayTeamWon)}
        />
      }
      versus={
        <BattleVersus
          slot={renderVersusSlot()}
          slotUserAvatar={getSlotUserAvatar(BattleResult.Draw)}
        />
      }
      date={<DateLabel date={date} />}
      leagueLogo={leagueToLogoMap[league]}
      status="future"
      sport={sport}
    />
  );
};
