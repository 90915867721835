import { FC } from 'react';

import { useWalletSelector } from '~entities/wallet';

import { Balance } from '~widgets/balance';

import { WalletProps } from './types';

export const Wallet: FC<WalletProps> = ({ dropdown = true }) => {
  const { balance } = useWalletSelector();

  return (
    <Balance
      maticBalance={balance.native}
      maincoinBalance={balance.mcn}
      variant={dropdown ? 'dropdown' : 'group'}
    />
  );
};
