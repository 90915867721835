import { styled } from '~shared/lib/styles';

import { Typography } from '../../dataDisplay';

export const formControlLabelClasses = {
  root: 'FormControlLabel-root',
  typography: 'FormControlLabel-typography',

  disabled: 'formControlLabel-disabled',
};

export const UIKitFormControlLabelRoot = styled('label', { name: 'FormControlLabel' })(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',

    [`&:hover:not(.${formControlLabelClasses.disabled})`]: {
      cursor: 'pointer',

      [`& .${formControlLabelClasses.typography}`]: {
        color: theme.palette.grey.A75,
      },
    },
  })
);

export const UIKitFormControlLabelTypography = styled(Typography, {
  name: 'FormControlLabel',
  slot: 'typography',
})(({ theme }) => ({
  marginLeft: 12,

  color: theme.palette.grey.A50,
  transition: theme.transitions.create('color', { duration: theme.transitions.duration.shortest }),

  lineHeight: 20 / 14,
}));
