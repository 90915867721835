import { FC, Fragment, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useViewerModel } from '~entities/viewer';

import { SignOutButtonWrapperProps } from './types';

export const SignOutButtonWrapper: FC<SignOutButtonWrapperProps> = ({ children }) => {
  const { t } = useTranslation();

  const { openSignOut } = useViewerModel();

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: openSignOut,
        color: 'error',
        variant: 'outlined',
        children: `${t('Account.out')}`,
      })}
    </Fragment>
  );
};
