import { styled } from '~shared/lib/styles';
import { ButtonIcon, buttonIconClasses } from '~shared/ui';

export interface NotificationsControlButtonProps {
  $active: boolean;
}

export const NotificationsControlButton = styled(ButtonIcon)<NotificationsControlButtonProps>(({
  theme,
  $active,
}) => {
  const activeStyles = {
    background: theme.palette.primary.main,

    [`& .${buttonIconClasses.icon}`]: {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  };

  return {
    [`&.${buttonIconClasses.outlined}`]: {
      background: theme.palette.paper.semi,
      border: 'none',
      padding: 4,

      [`& .${buttonIconClasses.icon}`]: {
        color: theme.palette.text.primary,
        opacity: 0.5,
      },

      ['&:hover:not(:disabled)']: {
        ...activeStyles,
      },

      ...($active ? activeStyles : null),
    },
  };
});
