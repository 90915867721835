export const normalizeMobileWindowHeight = () => {
  const setNormalizedHeightVariable = () => {
    const doc = document.documentElement;
    doc.style.setProperty(normalizeMobileWindowHeight.cssVariableName, `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setNormalizedHeightVariable);

  setNormalizedHeightVariable();

  return () => window.removeEventListener('resize', setNormalizedHeightVariable);
};

normalizeMobileWindowHeight.cssVariableName = '--app-height';
