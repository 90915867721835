import { styled } from '~shared/lib/styles';

export const battleShellRootClasses = {
  overlapBackdropAvailable: 'Battle-overlapBackdropAvailable',
  highlighted: 'BattleShell-highlighted',
  clickable: 'BattleShell-clickable',
};

export const BattleShellRoot = styled('div')(({ theme }) => {
  return {
    transition: theme.transitions.create('box-shadow'),

    [`&.${battleShellRootClasses.clickable}`]: {
      cursor: 'pointer',

      ['&:hover']: {
        boxShadow: `0px 0px 11px 1px ${theme.palette.primary.light}`,
      },
    },

    [`&.${battleShellRootClasses.highlighted}`]: {
      boxShadow: `0px 0px 11px 1px ${theme.palette.primary.light}`,
    },
  };
});
