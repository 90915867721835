import { useQuery } from 'react-query';

import { useSelector } from '~shared/lib/hooks';

import { getLeaderboard } from '~shared/api';

interface UseQueryLeaderboardParams {
  /**
   * Timestamp is seconds from which we start count ranking stats.
   * If not provided, ranking will be counted for last week
   */
  timestamp?: number;
}

export const useQueryLeaderboard = ({ timestamp }: UseQueryLeaderboardParams) => {
  return useQuery(['getLeaderboard', timestamp], () => {
    return getLeaderboard({ timestamp });
  });
};

export const useLeaderBoardSelector = () => {
  return useSelector((state) => state.leaderBoard);
};
