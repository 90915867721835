import { Tab, Tabs, TabsList } from '~shared/ui';

import { CARDS_TABS, NftTabRarity, useUserCardsModel } from '~widgets/user-cards';

import { UserCardsTabsContainer } from './styled';

import { UserCardsTabsProps } from './types';

export const UserCardsTabs = ({ ...props }: UserCardsTabsProps) => {
  const { rarityTab, onRarityTabChange } = useUserCardsModel();

  return (
    <UserCardsTabsContainer>
      <Tabs
        value={rarityTab}
        onChange={onRarityTabChange}
        defaultValue={NftTabRarity.All}
        {...props}
      >
        <TabsList>
          {CARDS_TABS.map(({ value, label }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsList>
      </Tabs>
    </UserCardsTabsContainer>
  );
};
