import { FC } from 'react';
import { clsx } from 'clsx';

import { FormHelperTextProps } from './types';
import { UIKitFormHelperText, formHelperTextClasses } from './styled';

export const FormHelperText: FC<FormHelperTextProps> = ({ className, error, ...props }) => {
  return (
    <UIKitFormHelperText
      {...props}
      className={clsx(className, {
        [formHelperTextClasses.error]: error,
      })}
    />
  );
};
