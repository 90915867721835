import { styled } from '~shared/lib/styles';

export const leaderboardRowClasses = {
  viewer: 'LeaderBoardRow-viewer',
};

export const LeaderboardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 60,
  borderRadius: 8,
  background: theme.palette.grey.A5,
  display: 'grid',
  gridTemplateColumns: '65% repeat(3, 1fr)',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '12px 0px',
  },

  [`&.${leaderboardRowClasses.viewer}`]: {
    background: theme.palette.paper.secondary,
    position: 'sticky',
    boxShadow: '0px 0px 11.85px #2EB2FF',
    bottom: '20px',
    left: 0,
    right: 0,
  },
}));
