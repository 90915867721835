import { forwardRef } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { AlertProps } from './types';
import {
  UIKitAlertAction,
  UIKitAlertIcon,
  UIKitAlertMessage,
  UIKitAlertRoot,
  UIKitCloseButton,
  alertClasses,
} from './styled';

import { ALERT_SEVERITY_TO_ICON_MAP } from './config';

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ onClose, children, action, severity = 'info', className, ...props }, ref) => {
    const alertIconName = ALERT_SEVERITY_TO_ICON_MAP[severity];

    return (
      <UIKitAlertRoot
        {...props}
        ref={ref}
        className={clsx(
          alertClasses.root,
          {
            [alertClasses.info]: severity === 'info',
            [alertClasses.error]: severity === 'error',
          },
          className
        )}
      >
        <UIKitAlertIcon>
          <Icon name={alertIconName} size={20} />
        </UIKitAlertIcon>
        <UIKitAlertMessage>{children}</UIKitAlertMessage>

        {action ? (
          <UIKitAlertAction>{action}</UIKitAlertAction>
        ) : onClose ? (
          <UIKitAlertAction>
            <UIKitCloseButton onClick={onClose} name="close" variant="plain" />
          </UIKitAlertAction>
        ) : null}
      </UIKitAlertRoot>
    );
  }
);
