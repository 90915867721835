import { styled } from '~shared/lib/styles';

import { BattleShell } from '../../BattleShell';

export const battleShellCompactClasses = {
  root: 'BattleShellCompact-root',

  statusFuture: 'BattleShellCompact-statusFuture',
  statusWin: 'BattleShellCompact-statusWin',
  statusLose: 'BattleShellCompact-statusLose',
};

export const BattleShellCompactRoot = styled(BattleShell)({
  width: '100%',

  position: 'relative',
  padding: '8px 16px',
  background: '#191919',
  borderRadius: 8,
  overflow: 'hidden',
});

export const BattleShellCompactStatus = styled('div')(({ theme }) => ({
  width: 2,
  position: 'absolute',
  inset: '0 auto 0 0',
  height: '100%',
  background: theme.palette.grey.A5,

  [`&.${battleShellCompactClasses.statusLose}`]: {
    background: theme.palette.error.main,
  },

  [`&.${battleShellCompactClasses.statusWin}`]: {
    background: theme.palette.primary.main,
  },
}));

export const BattleShellCompactTop = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 12,
}));

export const BattleShellCompactDateWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const BattleShellCompactPlayersContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
} as const);

export const BattleShellCompactVersusContainer = styled('div')(() => ({
  marginTop: -4,
  marginBottom: -4,
}));
