import { useCallback } from 'react';

import {
  postCheckEmail,
  postRecoveryPassword,
  postResendAuthCode,
  postSignUp,
  postSignUpVerification,
} from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { transformAvatarRelativePathToViewerAvatar, useViewerModel } from '~entities/viewer';

import {
  TIMER_ESTIMATE_DELAY,
  TIMER_ESTIMATE_RECORD_KEY,
  createTimerEstimateRecord,
  getTimerEstimateRecordDelay,
} from '../lib';

import { authActions } from './slice';
import { useAuthSelector } from './selectors';
import { AuthSteps } from './types';

const { open, close, updateData, changeStep } = authActions;

export const useAuthModel = () => {
  const { authorize, verification: viewerVerification } = useViewerModel();
  const { step, ...state } = useAuthSelector();
  const dispatch = useDispatch();

  const setStep = useCallback(
    (callback: (steps: typeof AuthSteps) => AuthSteps) => {
      dispatch(changeStep(callback(AuthSteps)));
    },
    [dispatch]
  );

  const onOpen = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const identify = useCallback(
    (data: { email: string }) => {
      dispatch(updateData({ email: data.email }));

      return postCheckEmail(data).then(({ registered, nickname, avatar }) => {
        dispatch(
          updateData({
            nickname,
            avatar: avatar ? transformAvatarRelativePathToViewerAvatar(avatar).src : '',
          })
        );

        if (registered) {
          setStep((steps) => steps.Authentication);
        } else {
          setStep((steps) => steps.IntroduceYourself);
        }
      });
    },
    [dispatch, setStep]
  );

  const signIn = useCallback(
    (data: { login: string; password: string }) => {
      return authorize(data)
        .then(({ verified }) => {
          if (verified) {
            onClose();
          } else {
            setStep((steps) => steps.VerificationCode);
          }
        })
        .catch(() => {
          return false;
        });
    },
    [authorize, onClose, setStep]
  );

  const signUp = useCallback(
    (data: { email: string; nickname: string; password: string; captchaToken: string }) => {
      return postSignUp(data).then(() =>
        authorize({
          login: data.email,
          password: data.password,
        })
      );
    },
    [authorize]
  );

  const recoveryPassword = useCallback(
    (data: { email: string }) => {
      if (!getTimerEstimateRecordDelay(TIMER_ESTIMATE_RECORD_KEY)) {
        return postRecoveryPassword(data).then(() => {
          createTimerEstimateRecord(TIMER_ESTIMATE_DELAY, TIMER_ESTIMATE_RECORD_KEY);
          setStep((steps) => steps.RecoveryLink);
        });
      }

      return new Promise((resolve) => resolve({})).then(() => {
        setStep((steps) => steps.RecoveryLink);
      });
    },
    [setStep]
  );

  const verification = useCallback(
    (data: { email: string; authCode: number }): Promise<boolean> => {
      return postSignUpVerification(data)
        .then(() => {
          viewerVerification();

          return true;
        })
        .catch(() => false);
    },
    [viewerVerification]
  );

  const resendVerification = useCallback((data: { email: string }) => {
    return postResendAuthCode(data);
  }, []);

  const submitNickname = useCallback(
    (nickname: string) => {
      dispatch(updateData({ nickname }));
      setStep((steps) => steps.CreatePassword);
    },
    [dispatch, setStep]
  );

  return {
    identify,
    signIn,
    signUp,
    recoveryPassword,
    verification,
    resendVerification,

    submitNickname,

    step,
    setStep,

    onOpen,
    onClose,

    ...state,
  };
};
