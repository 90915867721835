import { FC, useContext } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import { SportToIconMap } from '../../../../lib';
import { BattleShellContext } from '../../BattleShellContext';
import { BattleShellSize, BattleShellVariant } from '../../types';

import {
  BattleTeamAvatar,
  BattleTeamAvatarContainer,
  BattleTeamBadgeContainer,
  BattleTeamName,
  BattleTeamNameContainer,
  BattleTeamRoot,
  BattleTeamSlot,
  BattleTeamSlotContainer,
  BattleTeamSlotUserAvatar,
  battleTeamClasses,
} from './styled';
import { BattleTeamProps } from './types';

export const BattleTeam: FC<BattleTeamProps> = ({
  direction = 'fromLeft',
  badge,
  players,
  slot,
  slotUserAvatar,
}) => {
  const { variant, size, sport } = useContext(BattleShellContext);

  const shouldDisplayShortName = variant === 'square' && players.length > 1;

  return (
    <BattleTeamRoot
      className={clsx(battleTeamClasses.root, {
        [battleTeamClasses.fromLeft]: direction === 'fromLeft',
        [battleTeamClasses.fromRight]: direction === 'fromRight',

        [battleTeamClasses.severalPlayers]: players.length > 1,

        [battleTeamClasses.sm]: size === 'sm',
        [battleTeamClasses.md]: size === 'md',
        [battleTeamClasses.lg]: size === 'lg',

        [battleTeamClasses.line]: variant === 'line',
        [battleTeamClasses.square]: variant === 'square',
        [battleTeamClasses.compact]: variant === 'compact',
      })}
    >
      <BattleTeamSlotContainer className={battleTeamClasses.slotContainer}>
        <BattleTeamSlot className={battleTeamClasses.slot}>{slot}</BattleTeamSlot>

        {slotUserAvatar && (
          <BattleTeamSlotUserAvatar
            className={battleTeamClasses.slotUserAvatar}
            src={slotUserAvatar}
            size={24}
          />
        )}
      </BattleTeamSlotContainer>

      <BattleTeamAvatarContainer className={battleTeamClasses.avatarContainer}>
        <BattleTeamBadgeContainer>{badge}</BattleTeamBadgeContainer>

        {players.map((player) => (
          <BattleTeamAvatar
            key={player.name}
            src={player.avatar}
            size={BattleTeamAvatarSizeMap[variant][size]}
          >
            <Icon name={SportToIconMap[sport]} />
          </BattleTeamAvatar>
        ))}
      </BattleTeamAvatarContainer>

      <BattleTeamNameContainer
        className={battleTeamClasses.nameContainer}
        $size={size}
        $variant={variant}
      >
        {players.map((player) => (
          <BattleTeamName key={player.name}>
            {shouldDisplayShortName ? player.shortName : player.name}
          </BattleTeamName>
        ))}
      </BattleTeamNameContainer>
    </BattleTeamRoot>
  );
};

export const BattleTeamAvatarSizeMap: Record<
  BattleShellVariant,
  Record<BattleShellSize, number>
> = {
  compact: { sm: 40, md: 40, lg: 40 },
  line: { sm: 40, md: 48, lg: 48 },
  square: { sm: 40, md: 48, lg: 48 },
};
