import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';

import { useConnect } from '~entities/wallet';

import { useSwitcher } from '~shared/lib/hooks';

import {
  Box,
  ButtonIcon,
  Divider,
  MenuItem,
  Pill,
  PillGroup,
  PillMenu,
  Stack,
  Typography,
} from '~shared/ui';

import { DisconnectWalletDialog } from './DisconnectWalletDialog';

import { DropdownBalanceProps, GroupBalanceProps } from './types';

export const Balance: FC<GroupBalanceProps | DropdownBalanceProps> = ({
  maticBalance = 0,
  maincoinBalance = 0,
  maticProps = {},
  maincoinProps = {},
  variant = 'group',

  ...props
}) => {
  const { t } = useTranslation();

  const disconnectDialog = useSwitcher();

  const { openConnectWalletDialogAndOnboarding } = useConnect();
  const { account } = useWeb3React();
  const isConnected = !!account;

  const computedMaticBalance = formatDigits(maticBalance);
  const computedMaincoinBalance = formatDigits(maincoinBalance);

  if (!isConnected) {
    return (
      <PillGroup {...props}>
        <Pill
          onClick={openConnectWalletDialogAndOnboarding}
          append={<ButtonIcon name="plus" as="span" />}
          {...maticProps}
        >
          {t('disconnectWallet.wallet')}
        </Pill>
      </PillGroup>
    );
  }

  const commonMenuItems = (
    <Fragment>
      <MenuItem disabled {...maincoinProps}>
        {t('Balance.history')}
      </MenuItem>

      <Divider />

      <MenuItem color="error" onClick={disconnectDialog.switchOn} {...maticProps}>
        {t('Balance.disconnectWallet')}
      </MenuItem>
    </Fragment>
  );

  if (variant === 'dropdown') {
    return (
      <Fragment>
        <PillMenu {...props} label={`Matic ${computedMaticBalance}`}>
          <MenuItem {...maincoinProps}>
            <Box flex={1}>MCN</Box>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight={600}>{computedMaincoinBalance}</Typography>
            </Stack>
          </MenuItem>
          <MenuItem {...maticProps}>
            <Box flex={1}>MATIC</Box>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight={600}>{computedMaticBalance}</Typography>
            </Stack>
          </MenuItem>

          {commonMenuItems}
        </PillMenu>

        <DisconnectWalletDialog
          open={disconnectDialog.value}
          onClose={disconnectDialog.switchOff}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PillGroup {...props}>
        <Pill {...maincoinProps}>MCN {computedMaincoinBalance}</Pill>
        <Pill {...maticProps}>MATIC {computedMaticBalance}</Pill>

        <PillMenu {...props}>{commonMenuItems}</PillMenu>
      </PillGroup>

      <DisconnectWalletDialog open={disconnectDialog.value} onClose={disconnectDialog.switchOff} />
    </Fragment>
  );
};

const formatDigits = (number: number) => {
  return number.toLocaleString('de', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};
