import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box } from '~shared/ui';

import { CropView, InvalidView, ProgressView, SelectView } from './views';
import { AvatarEditorProps } from './types';

type View = 'select' | 'crop' | 'progress' | 'invalid';

export const AvatarEditor: FC<AvatarEditorProps> = ({
  onChange,
  customSrc,
  defaults,
  value,
  customValue,
}) => {
  const [view, setView] = useState<View>('select');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadingProgress, setUploadingProgress] = useState<number>(0);

  const handleDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      setUploadedFile(file);
      setView('crop');
    });
  };

  const dropzone = useDropzone({
    maxSize: 2097152, // 2mb
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] },
    onDropRejected: () => setView('invalid'),
    onDrop: handleDrop,
    multiple: false,
  });

  const handleCancel = () => {
    setView('select');
  };

  const handleSaveCrop = async (croppedImage: Blob) => {
    if (onChange) {
      setView('progress');
      await onChange(croppedImage, setUploadingProgress);
    }

    setView('select');
    setUploadingProgress(0);
  };

  const handleDelete = () => {
    setUploadedFile(null);
    setView('select');
  };

  const renderView = (view: View) => {
    switch (view) {
      case 'select':
        return (
          <SelectView
            onChooseFile={dropzone.open}
            onChoose={onChange}
            defaults={defaults}
            value={value}
            customSrc={customSrc}
            customValue={customValue}
          />
        );
      case 'invalid':
        return <InvalidView onChooseFile={dropzone.open} onCancel={handleCancel} />;
      case 'progress':
        return <ProgressView progress={uploadingProgress} />;
      case 'crop':
        return (
          <CropView
            uploadedFile={uploadedFile ?? undefined}
            onChooseFile={dropzone.open}
            onSave={handleSaveCrop}
            onDelete={handleDelete}
          />
        );
    }
  };

  return (
    <Box>
      <input {...dropzone.getInputProps()} />

      {renderView(view)}
    </Box>
  );
};
