import { styled } from '~shared/lib/styles';
import { MobileDialog } from '~shared/ui';
import { ApiCommonSport } from '~shared/api';
import { SportToAssetMaps } from '~shared/lib/assets';

interface StyledEventDialogMobileProps {
  $sport: ApiCommonSport;
}

export const EventDialogMobileRoot = styled(MobileDialog)<StyledEventDialogMobileProps>(
  ({ $sport }) => ({
    backgroundImage: `url(${SportToAssetMaps[$sport]})`,
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    display: 'flex',
    flexDirection: 'column',
  })
);
