import { FC, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, HeadingTab, HeadingTabs, HeadingTabsList, Stack } from '~shared/ui';
import { useSearchParamsState } from '~shared/lib/hooks';

import { MARKETPLACE_PAGE_TAB_SEARCH_PARAM_KEY, MarketplacePageTab } from '~entities/marketplace';

import { Announcements } from '~widgets/announcements';
import { AuctionsList } from '~widgets/auction-list';
import { MarketplaceList } from '~widgets/marketplace-list';

export const MarketplacePage: FC = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useSearchParamsState<MarketplacePageTab>(
    MARKETPLACE_PAGE_TAB_SEARCH_PARAM_KEY,
    MarketplacePageTab.Marketplace
  );

  const handleChangeTab = useCallback(
    (event: SyntheticEvent | null, value: any) => {
      setTab(value as MarketplacePageTab);
    },
    [setTab]
  );

  const tabs = {
    [MarketplacePageTab.Marketplace]: <MarketplaceList />,
    [MarketplacePageTab.Auction]: <AuctionsList />,
  };

  return (
    <Stack spacing={12 / 8} flex={1}>
      <Announcements />

      <Container flex={1} display="flex" flexDirection="column">
        <HeadingTabs
          defaultValue={MarketplacePageTab.Marketplace}
          value={tab}
          onChange={handleChangeTab}
          gutters
        >
          <HeadingTabsList>
            <HeadingTab value={MarketplacePageTab.Marketplace}>{t('Tabs.marketplace')}</HeadingTab>
            <HeadingTab value={MarketplacePageTab.Auction}>{t('Tabs.auction')}</HeadingTab>
          </HeadingTabsList>
        </HeadingTabs>

        {tabs[tab]}
      </Container>
    </Stack>
  );
};
