import { FC } from 'react';

import { useCountdown } from '~shared/lib/hooks';
import { LinearProgress } from '~shared/ui';

import { Root, Text } from './styled';
import { EventTimerProps } from './types';

const THIRTY_MINUTES_IN_MILLISECONDS = 1_800_000;
const MAX_TIME = THIRTY_MINUTES_IN_MILLISECONDS * 2 * 10;

export const EventTimer: FC<EventTimerProps> = ({ date }) => {
  const formattedDate = new Date(date.getTime() - THIRTY_MINUTES_IN_MILLISECONDS);
  const { hours, minutes, days, isExpired } = useCountdown(formattedDate);

  const diff = (formattedDate.getTime() - new Date().getTime()) / MAX_TIME;

  const progress = diff > 1 ? 100 : diff * 100;
  const progressColor = progress < 35 ? 'error' : 'primary';

  const getTime = (days: number, hours: number, minutes: number) => {
    let result = '';

    if (days > 0) {
      result += `${days}d:`;
    }

    if (hours > 0) {
      result += `${hours}hr:`;
    }

    result += `${minutes}m`;

    return result;
  };

  if (isExpired) {
    return null;
  }

  return (
    <Root>
      <Text>
        Time to change a bet <span>{getTime(days, hours, minutes)}</span>
      </Text>

      <LinearProgress progress={progress} progressColor={progressColor} height={4} />
    </Root>
  );
};
