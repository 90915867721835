import { createElement } from 'react';
import { Trans } from 'react-i18next';
import { FUNDING, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js';
import { useWeb3React } from '@web3-react/core';

import { useSnackbar } from '~shared/lib/hooks';
import { postMarketplaceOrder, postMarketplaceOrderCapture } from '~shared/api';

interface UseBuyCardForUSD {
  (params: { id: number }): {
    initialOptions: ReactPayPalScriptOptions;

    createOrder: PayPalButtonsComponentOptions['createOrder'];
    onApprove: PayPalButtonsComponentOptions['onApprove'];
  };
}

export const useBuyCardForUSD: UseBuyCardForUSD = ({ id }) => {
  const { openSnackbar } = useSnackbar();
  const { account } = useWeb3React();

  const initialOptions: ReactPayPalScriptOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  };

  const createOrder: PayPalButtonsComponentOptions['createOrder'] = async () => {
    if (!account) {
      openSnackbar({ type: 'error', message: 'Connect your wallet' });

      return new Promise((resolve, reject) => reject());
    }

    return postMarketplaceOrder({ marketplaceCardId: id }).then((response) => response.id);
  };

  const onApprove: PayPalButtonsComponentOptions['onApprove'] = async (data) => {
    return postMarketplaceOrderCapture({ orderId: data.orderID }).then(() => {
      openSnackbar({
        message: createElement(Trans, { i18nKey: 'Marketplace.successfulPaymentSnackbar' }, [
          'Payment was successful.',
          createElement('br'),
          'The card will soon appear in your wallet.',
        ]),
      });
    });
  };

  const onError = (e: Error) => {
    openSnackbar({
      type: 'error',
      message: createElement(Trans, { i18nKey: 'Marketplace.errorDuringPayment' }, [
        'There is error during payment.',
        createElement('br'),
        { error: String(e.message) } as any,
      ]),
    });
  };

  return {
    initialOptions,

    fundingSource: FUNDING.PAYPAL,

    createOrder,
    onApprove,
    onError,
  };
};
