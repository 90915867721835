import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ViewerState } from './types';

export const viewerInitialState: ViewerState = {
  registered: false,
  authorized: false,
  verified: false,
  sessionTokenChecked: false,

  id: null,
  email: '',
  wallet: '',
  nickname: '',
  avatar: null,
  customAvatar: null,
  defaultAvatars: null,

  onboarding: false,

  signOutDialogOpen: false,
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState: viewerInitialState,
  reducers: {
    updateData: (state, action: PayloadAction<Partial<ViewerState>>) => {
      return { ...state, ...action.payload };
    },

    openSignOutDialog: (state) => {
      return { ...state, signOutDialogOpen: true };
    },

    closeSignOutDialog: (state) => {
      return { ...state, signOutDialogOpen: false };
    },
  },
});

export const viewerReducer = viewerSlice.reducer;
export const viewerActions = viewerSlice.actions;
