import { FC } from 'react';
import { clsx } from 'clsx';

import {
  SportLineArrow,
  SportLineIllustration,
  SportLineLabel,
  SportLineRoot,
  sportLineClasses,
} from './styled';
import { SportsMenuLineProps } from './types';

export const SportsMenuLine: FC<SportsMenuLineProps> = ({
  name,
  illustration,
  disabled,
  hasItems,
  ...props
}) => {
  return (
    <SportLineRoot
      src={illustration}
      className={clsx({ [sportLineClasses.disabled]: disabled })}
      disabled={disabled}
      {...props}
    >
      <SportLineIllustration src={illustration} />
      <SportLineLabel>{name}</SportLineLabel>
      {hasItems && <SportLineArrow size={20} name="chevron-right" />}
    </SportLineRoot>
  );
};
