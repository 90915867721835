import { useCallback } from 'react';
import { useLocation } from 'react-router';

import { postSetPassword } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { recoveryActions } from './slice';
import { useRecoverySelector } from './selectors';
import { PasswordRecoverySteps } from './types';

export const useRecoveryModel = () => {
  const dispatch = useDispatch();
  const { step } = useRecoverySelector();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const email = searchParams.get('email') as string;
  const token = searchParams.get('tk') as string;

  const updatePassword = useCallback(
    ({ password }: { password: string }) => {
      return postSetPassword({ email, token, password }).then(() => {
        dispatch(recoveryActions.setStep(PasswordRecoverySteps.PasswordChanged));
      });
    },
    [dispatch, email, token]
  );

  return { updatePassword, step };
};
