import { forwardRef } from 'react';
import { clsx } from 'clsx';

import { Alert, alertSnackbarClasses } from '~shared/ui';

import { AlertSnackbarProps } from './types';
import { AlertSnackbarRoot } from './styled';

enum AnimationVariant {
  Visible = 'visible',
  Hidden = 'hidden',
}

const transition = { duration: 0.2, type: 'tween' } as const;

const paperMotionVariants = {
  [AnimationVariant.Hidden]: { opacity: 0, y: -5, scale: 0.98, transition },
  [AnimationVariant.Visible]: { opacity: 1, y: 0, scale: 1, transition },
};

export const AlertSnackbar = forwardRef<any, AlertSnackbarProps>(({ className, ...props }, ref) => {
  return (
    <AlertSnackbarRoot
      layout
      variants={paperMotionVariants}
      initial={AnimationVariant.Hidden}
      animate={AnimationVariant.Visible}
      exit={AnimationVariant.Hidden}
      ref={ref}
      className={clsx(alertSnackbarClasses.root, className)}
    >
      <Alert {...props} />
    </AlertSnackbarRoot>
  );
});
