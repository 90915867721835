import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';

import { useMediaQuery } from '~shared/lib/hooks';
import { normalizeMobileWindowHeight } from '~shared/lib/utils';

import { SportsMenuSidebar } from '~widgets/sports-menu';
import { UserSidebar } from '~widgets/user-sidebar';
import { TabBarMenu } from '~widgets/tab-bar-menu';
import { Header } from '~widgets/header';

import { htmlDefaultLayoutBottomAnchorId, htmlDefaultLayoutMainAnchorId } from '~shared/config';

import { DefaultLayoutBottomDesktop, DefaultLayoutMain, DefaultLayoutRoot } from './styled';

import { DefaultLayoutProps } from './types';

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  userSidebar = false,
  header = false,
  mainGutters = false,
  pinScrollbar = false,
}) => {
  const { isMobile, isDesktop } = useMediaQuery();

  useEffect(normalizeMobileWindowHeight, []);

  const main = (
    <DefaultLayoutMain
      $gutters={mainGutters}
      $pinScrollbar={pinScrollbar}
      id={htmlDefaultLayoutMainAnchorId}
    >
      <Outlet />
    </DefaultLayoutMain>
  );

  return (
    <DefaultLayoutRoot>
      {header && <Header />}

      <DefaultLayoutBottomDesktop id={htmlDefaultLayoutBottomAnchorId}>
        {isDesktop ? <SportsMenuSidebar /> : null}

        {main}

        {isDesktop ? userSidebar && <UserSidebar /> : null}
      </DefaultLayoutBottomDesktop>

      {isMobile ? <TabBarMenu /> : null}
    </DefaultLayoutRoot>
  );
};
