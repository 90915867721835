import { ApiCommonSportEnum } from '~shared/api';
import { IconAssetNamesType } from '~shared/ui';

export const SportToIconMap: Record<ApiCommonSportEnum, IconAssetNamesType> = {
  [ApiCommonSportEnum.AmericanFootball]: 'sport-american-football',
  [ApiCommonSportEnum.Baseball]: 'sport-baseball',
  [ApiCommonSportEnum.Basketball]: 'sport-basketball',
  [ApiCommonSportEnum.Cricket]: 'sport-cricket',
  [ApiCommonSportEnum.Football]: 'sport-football',
  [ApiCommonSportEnum.Hockey]: 'sport-hockey',
  [ApiCommonSportEnum.MMA]: 'sport-mma',
  [ApiCommonSportEnum.Tennis]: 'sport-tennis',

  [ApiCommonSportEnum.Dota]: 'sport-pad',
  [ApiCommonSportEnum.CSGO]: 'sport-pad',
  [ApiCommonSportEnum.LeagueOfLegends]: 'sport-pad',
};
