import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Tab, Tabs, TabsList } from '~shared/ui';

import { EventDialogTab, useEventModel } from '~entities/event';

import { EventDialogTabsProps } from './types';

export const EventDialogTabs: FC<EventDialogTabsProps> = ({ ...tabsProps }) => {
  const { tab, onTabChange, isStatisticsTabDisabled } = useEventModel();
  const { t } = useTranslation();

  return (
    <Tabs value={tab} onChange={onTabChange} {...tabsProps}>
      <TabsList>
        <Tab value={EventDialogTab.Cards}>{t('BattlePopUp.cards')}</Tab>
        <Tab value={EventDialogTab.Statistics} disabled={isStatisticsTabDisabled}>
          {t('BattlePopUp.statistics')}
        </Tab>
        <Tab value={EventDialogTab.Stream} disabled>
          {t('BattlePopUp.stream')}
        </Tab>
      </TabsList>
    </Tabs>
  );
};
