import { Children, FC } from 'react';
import { FreeMode, Mousewheel } from 'swiper/modules';

import { HorizontalListRoot, HorizontalListSlide, HorizontalListSwiper } from './styled';
import { EventCardsHorizontalListProps } from './types';

//TODO: add common element for horizontal list and use it here and in BattleHorizontalList
export const EventCardsHorizontalList: FC<EventCardsHorizontalListProps> = ({
  children,
  centeredSlides,
}) => {
  return (
    <HorizontalListRoot>
      <HorizontalListSwiper
        spaceBetween={16}
        slidesPerView="auto"
        freeMode={{ sticky: true }}
        mousewheel={{ forceToAxis: true }}
        modules={[Mousewheel, FreeMode]}
        centerInsufficientSlides={centeredSlides}
        centeredSlidesBounds={centeredSlides}
      >
        {Children.map(children, (child) => (
          <HorizontalListSlide>{child}</HorizontalListSlide>
        ))}
      </HorizontalListSwiper>
    </HorizontalListRoot>
  );
};
