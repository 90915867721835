import { FC, Fragment, useMemo } from 'react';

import { useMediaQuery } from '~shared/lib/hooks';

import { useQueryNfts } from '~entities/nft';
import { useEventModel } from '~entities/event';

import { getTeamByBetChoice } from '../../../../model';

import { EventUserCaptions } from './EventUserCaptions';
import { EventDialogActions } from './EventDialogActions';
import { EventMultipleCards } from './EventMultipleCards';
import { EventSingleCard } from './EventSingleCard';
import { EventCallCards } from './EventCallCards';
import { EventCardsWalletInitialization } from './EventDialogWalletInitialization';

import { EventDialogCardsContainer } from './styled';
import { EventDialogCardsProps } from './types';

export const EventDialogCards: FC<EventDialogCardsProps> = () => {
  const { isMobile } = useMediaQuery();

  const { isLoading } = useQueryNfts();

  const { isCall, isMakeCall, event, choice, cards, isViewMode } = useEventModel();

  const team = useMemo(() => getTeamByBetChoice({ event: event, result: choice }), [choice, event]);

  const isOneCardOrMorePlaced = cards.length >= 1;
  const isMoreThanOneCardPlaced = cards.length > 1;
  const isTeamChosen = team !== null;
  const shouldShowMultipleCardsView =
    isTeamChosen && (isViewMode || isMobile ? isOneCardOrMorePlaced : isMoreThanOneCardPlaced);

  const isCallCards = isCall || isMakeCall;

  const renderCards = () => {
    switch (true) {
      case isLoading:
        return <EventCardsWalletInitialization />;
      case isCallCards:
        return <EventCallCards />;
      case shouldShowMultipleCardsView:
        return <EventMultipleCards />;
      default:
        return <EventSingleCard />;
    }
  };

  return (
    <Fragment>
      <EventUserCaptions />

      <EventDialogCardsContainer>{renderCards()}</EventDialogCardsContainer>

      <EventDialogActions />
    </Fragment>
  );
};
