import { useCallback } from 'react';

import { Nft } from '~shared/api';

import { useEventModel } from '~entities/event';
import { useNftCardModel } from '~entities/nft';

import { useUserCardsModel } from '~widgets/user-cards';

export const useIsAvailableToSelect = () => {
  const { callAcceptableRarity, isCall, isMakeCall } = useEventModel();
  const { isSelectionMode, selectionModeType, nftsToMerge } = useNftCardModel();
  const { selectedCardsAmount, selectedCards } = useUserCardsModel();

  const isAvailableToSelect = useCallback(
    (nft: Nft) => {
      if (isSelectionMode) {
        if (selectedCards.has(nft.token_id)) {
          return true;
        }

        if (selectionModeType === 'bet') {
          if (isCall || isMakeCall) {
            if (selectedCardsAmount < 1) {
              if (isCall) {
                if (callAcceptableRarity !== null) {
                  return callAcceptableRarity === nft.rarity;
                }
              }

              return true;
            }

            return false;
          }

          return true;
        }

        if (selectionModeType === 'merge') {
          if (selectedCards.has(nft?.token_id)) {
            return true;
          }

          const nftsToMergeQuantity = nftsToMerge.filter((nft) => nft !== null).length;

          switch (nftsToMergeQuantity) {
            case 0:
              return selectedCardsAmount < 2;
            case 1:
              return selectedCardsAmount < 1;
            default:
              return false;
          }
        }
      }

      return false;
    },
    [
      callAcceptableRarity,
      isCall,
      isMakeCall,
      isSelectionMode,
      nftsToMerge,
      selectedCards,
      selectedCardsAmount,
      selectionModeType,
    ]
  );

  return {
    isAvailableToSelect,
  };
};
