import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import { enableMapSet } from 'immer';

import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

import './shared/config/i18n/i18n';
import './codeSplittingModules';

import { App } from './app';

TiktokPixel.init('CIJF0DJC77UD88O19UT0');
ReactPixel.init('963902214621802');

// ability to store Map and Set in redux store
enableMapSet();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1ab536ba545746f7809d0b2216baf3ed@o4504360326135808.ingest.sentry.io/4504360332165122',
    integrations: [new BrowserTracing()],

    maxBreadcrumbs: 50,
    debug: false,
    tracesSampleRate: 1,
  });
}

root.render(<App />);
