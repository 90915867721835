import { useCallback, useState } from 'react';

interface UseSwitcherToggle {
  (value: boolean): void;
  (): void;
}

interface UseSwitcher {
  (initialValue?: boolean): {
    value: boolean;
    switchOn: () => void;
    switchOff: () => void;
    toggle: UseSwitcherToggle;
  };
}

export const useSwitcher: UseSwitcher = (initialValue: boolean = false) => {
  const [value, setValue] = useState<typeof initialValue>(initialValue);

  const switchOn = useCallback(() => {
    setValue(true);
  }, []);

  const switchOff = useCallback(() => {
    setValue(false);
  }, []);

  const toggle: UseSwitcherToggle = useCallback((value?: boolean) => {
    setValue((valueState) => {
      if (typeof value === 'boolean') {
        return value;
      }

      return !valueState;
    });
  }, []);

  return { value, switchOn, switchOff, toggle };
};
