import { useTranslation } from 'react-i18next';

import { Button, Dialog, Typography } from '~shared/ui';
import { useDispatch, useSelector } from '~shared/lib/hooks';
import { palette } from '~shared/lib/styles/palette';

import { useConnect, walletActions } from '~entities/wallet/model';

import * as S from './ConnectWalletWarnDialog.styled';

export const ConnectWalletWarnDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openConnectWalletDialog } = useConnect();
  const isWarnDialogOpen = useSelector((state) => state.wallet.isWarnDialogOpen);

  const handleClose = () => {
    dispatch(walletActions.setWarnDialog(false));
  };

  const handleConnect = () => {
    handleClose();
    openConnectWalletDialog();
  };

  return (
    <Dialog
      backgroundColor={palette.paper.secondary}
      width={440}
      open={isWarnDialogOpen}
      onClose={handleClose}
    >
      <S.ConnectWalletWarnDialogRoot>
        <Typography textAlign="center" variant="h1">
          Connect a wallet
        </Typography>
        <Typography maxWidth="330px" textAlign="center" variant="body">
          {t('Other.upText')}
        </Typography>
        <S.ConnectWalletWarnDialogIllustration
          src="/assets/auth/connect-wallet-secondary-illustration.png"
          alt="Connect your wallet before do any action"
        />
        <Typography maxWidth="330px" textAlign="center" variant="body">
          You’re one step away from claiming your first Maincard. It’s on us! Use your card to start
          playing and earn tokens.
        </Typography>
        <Button onClick={handleConnect}>Connect a wallet</Button>
      </S.ConnectWalletWarnDialogRoot>
    </Dialog>
  );
};
