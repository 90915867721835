import { useTranslation } from 'react-i18next';

import { Button, Dialog, Stack, Typography } from '~shared/ui';
import { palette } from '~shared/lib/styles/palette';

import { useWalletConnectedDialogModel } from '../../model';

import * as S from './WalletConnectedDialog.styled';

export const WalletConnectedDialog = () => {
  const { t } = useTranslation();

  const { open, handleClose } = useWalletConnectedDialogModel();

  return (
    <Dialog backgroundColor={palette.paper.secondary} width={440} open={open} onClose={handleClose}>
      <Stack spacing={3} alignItems="center">
        <Typography textAlign="center" variant="h1">
          {t('Other.walletConnected')}
        </Typography>
        <Typography textAlign="center">{t('Other.yourWallet')}</Typography>
        <S.WalletConnectedDialogIllustration
          src="/assets/auth/wallet-connected-illustration.png"
          alt="Wallet connected"
        />
        <Typography maxWidth="330px" textAlign="center">
          {t('Other.FREEMaincard')}
        </Typography>
        <Button onClick={handleClose}>{t('Other.start')}</Button>
      </Stack>
    </Dialog>
  );
};
