import { FC } from 'react';

import { Icon, Stack } from '~shared/ui';

import { SocialTextFieldProps } from './types';
import { StyledTextField, UnlinkButton } from './styled';

export const SocialTextField: FC<SocialTextFieldProps> = ({
  iconName,
  onUnlink,
  unlinkProps,
  ...props
}) => {
  return (
    <Stack spacing={16 / 8} direction="row" alignItems="center">
      <Icon name={iconName} />
      <StyledTextField {...props} />
      {onUnlink && (
        <UnlinkButton onClick={onUnlink} {...unlinkProps}>
          De-attach
        </UnlinkButton>
      )}
    </Stack>
  );
};
