import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentPlaceholder,
  HeadingTab,
  HeadingTabs,
  HeadingTabsList,
  Stack,
  TabsProps,
} from '~shared/ui';
import { useSearchParamsState } from '~shared/lib/hooks';

import { RANKING_ALL_TIME_QUERY_PERIOD_TS, RANKING_TESTNET_QUERY_PERIOD_TS } from '../model';

import { useLeaderBoardSelector, useQueryLeaderboard } from '../model';

import { LeaderboardTableRow } from './LeaderboardTableRow';
import { LeaderboardRowShimmer } from './LeaderboardRowShimmer';
import { LeaderboardTableHead } from './LeaderboardTableHead';
import { LeaderboardViewerRow } from './LeaderboardTableRow/LeaderboardViewerRow/LeaderboardViewerRow';

export enum LeaderboardTab {
  Testnet = 'testnet',
  Total = 'main',
  CurrentWeek = 'currentWeek',
}

export const LeaderboardList = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useSearchParamsState<LeaderboardTab>('period', LeaderboardTab.Testnet);

  const { showLine, leaderboard: viewerLeaderboard } = useLeaderBoardSelector();

  const rankingQueryPeriodTimestamp = useMemo(() => {
    switch (tab) {
      case LeaderboardTab.Total:
        return RANKING_ALL_TIME_QUERY_PERIOD_TS;

      case LeaderboardTab.Testnet:
        return RANKING_TESTNET_QUERY_PERIOD_TS;

      default:
        return undefined;
    }
  }, [tab]);

  const { data: leaders = [], isLoading } = useQueryLeaderboard({
    timestamp: rankingQueryPeriodTimestamp,
  });

  const handleTabChange: TabsProps['onChange'] = (event, tab) => {
    setTab(tab as LeaderboardTab);
  };

  const hasLeaders = leaders.length !== 0;

  const renderLeaderboard = () => {
    switch (true) {
      case isLoading:
        return (
          <Stack spacing={14 / 8}>
            <LeaderboardTableHead />
            <LeaderboardShimmer />
          </Stack>
        );
      case hasLeaders:
        return (
          <Stack spacing={14 / 8}>
            <LeaderboardTableHead />

            {leaders.map((leaderboard) => (
              <LeaderboardTableRow key={leaderboard.place} leaderboard={leaderboard} />
            ))}

            {showLine && tab !== LeaderboardTab.CurrentWeek && !!viewerLeaderboard && (
              <LeaderboardViewerRow leaderboard={viewerLeaderboard} isViewer />
            )}
          </Stack>
        );

      default:
        return <ContentPlaceholder heading={t('Other.oops')} message={t('Other.leaders')} />;
    }
  };

  return (
    <Fragment>
      <HeadingTabs gutters value={tab} onChange={handleTabChange}>
        <HeadingTabsList>
          <HeadingTab value={LeaderboardTab.Testnet}>Testnet</HeadingTab>
          <HeadingTab value={LeaderboardTab.Total}>{t('Tabs.main')}</HeadingTab>
          <HeadingTab value={LeaderboardTab.CurrentWeek}>{t('Tabs.currentWeek')}</HeadingTab>
        </HeadingTabsList>
      </HeadingTabs>

      {renderLeaderboard()}
    </Fragment>
  );
};

const LeaderboardShimmer = () => (
  <Stack mt={14 / 8} spacing={14 / 8}>
    {Array.from(Array(6)).map((_, key) => (
      <LeaderboardRowShimmer key={key} />
    ))}
  </Stack>
);
