import { cloneElement, forwardRef } from 'react';
import { clsx } from 'clsx';

import { Icon } from '~shared/ui';

import {
  BattleShellCompactDateWrapper,
  BattleShellCompactPlayersContainer,
  BattleShellCompactRoot,
  BattleShellCompactStatus,
  BattleShellCompactTop,
  BattleShellCompactVersusContainer,
  battleShellCompactClasses,
} from './styled';

import { BattleShellCompactProps } from './types';

export const BattleShellCompact = forwardRef<HTMLDivElement, BattleShellCompactProps>(
  (
    {
      status,
      date,
      leagueLogo,

      homeTeam,
      awayTeam,

      versus,

      ...rootProps
    },
    ref
  ) => {
    return (
      <BattleShellCompactRoot {...rootProps} ref={ref}>
        <BattleShellCompactStatus
          className={clsx({
            [battleShellCompactClasses.statusFuture]: status === 'future',
            [battleShellCompactClasses.statusWin]: status === 'win',
            [battleShellCompactClasses.statusLose]: status === 'lose',
          })}
        />

        <BattleShellCompactTop>
          <BattleShellCompactDateWrapper>
            {cloneElement(date, { ...date.props, size: 'sm' })}
            {leagueLogo && <Icon name={leagueLogo} size={30} ml={1} />}
          </BattleShellCompactDateWrapper>
        </BattleShellCompactTop>

        <BattleShellCompactPlayersContainer>
          {homeTeam}

          <BattleShellCompactVersusContainer>{versus}</BattleShellCompactVersusContainer>

          {awayTeam}
        </BattleShellCompactPlayersContainer>
      </BattleShellCompactRoot>
    );
  }
);
