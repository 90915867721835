import { FC } from 'react';

import { AvatarAction, Icon, Stack } from '~shared/ui';

import { SelectProps } from './types';

export const SelectView: FC<SelectProps> = ({
  onChooseFile,
  onChoose,
  customSrc,
  customValue,
  defaults,
  value,
}) => {
  const isCustomSelected = customValue === value;

  const handleCustomClick = () => {
    if (isCustomSelected || !customValue) {
      onChooseFile();
    } else {
      onChoose(customValue);
    }
  };

  return (
    <Stack direction="row" spacing={16 / 8} alignItems="center">
      {customSrc ? (
        <AvatarAction
          src={customSrc}
          size={48}
          onClick={handleCustomClick}
          selected={isCustomSelected}
          edit={isCustomSelected}
          selectable
        />
      ) : (
        <AvatarAction size={60} interactive onClick={onChooseFile}>
          <Icon name="plus" size={24} />
        </AvatarAction>
      )}

      {defaults.map(({ src, value: defaultValue }) => {
        const handleChange = () => {
          onChoose(defaultValue);
        };

        return (
          <AvatarAction
            key={defaultValue}
            selected={value === defaultValue}
            selectable
            onClick={handleChange}
            size={48}
            src={src}
          />
        );
      })}
    </Stack>
  );
};
