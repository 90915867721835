import { useTranslation } from 'react-i18next';

import { Icon, Typography } from '../../dataDisplay';

import { Paper, TransactionStatusDialogRoot } from './styled';

type Props = {
  open: boolean;
  message: string;
  onClose: VoidFunction;
};

export const TransactionStatusDialog = ({ open, message, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <TransactionStatusDialogRoot open={open} onClose={onClose} width={380}>
      <Paper>
        <Typography variant="h1">{t('Other.pleaseWait')}</Typography>
        <Icon name="illustration-clock" size={72} />
        <Typography>{message}</Typography>
      </Paper>
    </TransactionStatusDialogRoot>
  );
};
