import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const Root = styled(Box, { name: 'DashboardRanking', slot: 'root' })(({ theme }) => ({
  background: theme.palette.grey.A5,
  padding: '16px',
  borderRadius: '8px',
  display: 'grid',
  gap: '30px',
}));

export const IconContainer = styled(Box, { name: 'DashboardRanking', slot: 'iconContainer' })(
  ({ theme }) => ({
    background: theme.palette.grey.A20,
    padding: '8px',
    width: '36px',
    height: '36px',
    borderRadius: '43px',
    color: '#fff',
  })
);
