import { createGlobalStyle } from 'styled-components';
import { battleShellRootClasses } from '~entities/battle/ui/factory/BattleShellFactory/styled';

interface BattleGlobalStyledProps {
  isOverlapBackdrop: boolean;
}

export const BattleGlobalStyled = createGlobalStyle<BattleGlobalStyledProps>(
  ({ theme, isOverlapBackdrop }) => {
    if (isOverlapBackdrop) {
      return {
        [`.${battleShellRootClasses.overlapBackdropAvailable}`]: {
          zIndex: theme.zIndex.modalOverlap + 1,
        },
      };
    }

    return {};
  }
);
