import { FC } from 'react';

import { DateLabelShell } from './DateLabelShell';
import { DateLabelConstructor } from './DateLabelConstructor';

import { useDateLabel } from './useDateLabel';

import { DateLabelProps, DateLabelState, DateLabelStateMap, DateLabelVariant } from './types';

export const DateLabel: FC<DateLabelProps> = ({
  size = 'lg',
  date,
  live = false,
  defaultState,
  ...props
}) => {
  const state = useDateLabel({ live, date, defaultState });

  return (
    <DateLabelShell {...props} variant={getVariantByState(state)} size={size}>
      <DateLabelConstructor date={date} size={size} state={state} />
    </DateLabelShell>
  );
};

export const getVariantByState = (state: DateLabelState): DateLabelVariant => {
  switch (state) {
    case DateLabelStateMap.Live:
      return 'live';

    case DateLabelStateMap.Upcoming:
    case DateLabelStateMap.Ready:
    case DateLabelStateMap.Today:
      return 'active';

    case DateLabelStateMap.Past:
    case DateLabelStateMap.Planned:
    default:
      return 'primary';
  }
};
