import { FC, forwardRef } from 'react';
import { LinkProps as BaseLinkProps } from 'react-router-dom';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';
import { clsx } from 'clsx';

import { LinkProps } from './types';
import { UIKitLinkRoot, linkClasses } from './styled';

export const Link: FC<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ underline = true, className, fullWidth, ...props }, ref) => {
    return (
      <UIKitLinkRoot
        {...(props as BaseLinkProps)}
        {...extendSxProp(props as {})}
        className={clsx(
          { [linkClasses.underline]: underline, [linkClasses.fullWith]: fullWidth },
          className
        )}
        ref={ref}
      />
    );
  }
);
