import { useTranslation } from 'react-i18next';

import { useAuctionCardsListEffects, useAuctionModel } from '~features/auction';

import { ApiGetAuctionCardMappedData } from '~shared/api';
import { Typography } from '~shared/ui';

import { BestAuctionList } from './BestAuctionList';
import { HorizontalBestList } from './HorizontalBestList';

import { BestAutionContainer } from './styled';

export const MarketplaceBestAuction = () => {
  const { t } = useTranslation();

  const { auctionCardsAll, handleOpenCardDetailedInfo } = useAuctionModel();
  useAuctionCardsListEffects();

  const renderAuctionCard = (card: ApiGetAuctionCardMappedData) => {
    return (
      <BestAuctionList key={card.tokenId} card={card} onOpenCardInfo={handleOpenCardDetailedInfo} />
    );
  };

  return (
    <BestAutionContainer>
      <Typography variant="h1">{t('Other.bestFromAuction')}</Typography>

      <HorizontalBestList>
        {auctionCardsAll.map((value: any) => renderAuctionCard(value))}
      </HorizontalBestList>
    </BestAutionContainer>
  );
};
