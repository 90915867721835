import { styled } from '~shared/lib/styles';

export const notificationIconPlateClasses = {
  root: 'NotificationIconPlate-root',

  paper: 'NotificationIconPlate-paper',
  primary: 'NotificationIconPlate-primary',
  error: 'NotificationIconPlate-error',
  violet: 'NotificationIconPlate-violet',
};

export const NotificationIconPlateRoot = styled('div')(({ theme }) => ({
  borderRadius: 8,
  width: 40,
  height: 40,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',

  [`&.${notificationIconPlateClasses.paper}`]: {
    background: theme.palette.grey.A10,
  },

  [`&.${notificationIconPlateClasses.primary}`]: {
    background: theme.palette.primary.light,
  },

  [`&.${notificationIconPlateClasses.error}`]: {
    background: theme.palette.error.main,
  },

  [`&.${notificationIconPlateClasses.violet}`]: {
    background: theme.palette.violet.main,
  },
}));
