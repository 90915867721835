import { NFT__factory } from '~shared/contracts';

import { GetNftInfoMulticallRequestsParams } from '~entities/nft';

export const getNftInfoMulticallRequests = async ({
  tokenId,
  provider,
}: GetNftInfoMulticallRequestsParams) => {
  const requestsForOneNft = [];

  const nftContract = NFT__factory.connect(process.env.REACT_APP_ADDRESS_SK_CARD, provider);

  const nftContractAttached = nftContract.attach(process.env.REACT_APP_ADDRESS_SK_CARD);

  const overrideOptions = {
    gasLimit: 100000,
  };

  let tx = await nftContractAttached.populateTransaction.getLastConsequentWins(
    tokenId,
    overrideOptions
  );

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.getRarity(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.livesRemaining(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.rewardMaintokens(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.recoveryMaintokens(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction.recoveriesRemaining(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  tx = await nftContractAttached.populateTransaction._cardInfos(tokenId, overrideOptions);

  requestsForOneNft.push({
    to: process.env.REACT_APP_ADDRESS_SK_CARD,
    data: tx.data!,
  });

  return requestsForOneNft;
};
