import { FC } from 'react';
import { Outlet } from 'react-router';

import { Logo } from '~shared/ui';

import { PaperLayoutLogoWrapper, PaperLayoutRoot } from './styled';
import { PaperLayoutProps } from './types';

export const PaperLayout: FC<PaperLayoutProps> = () => {
  return (
    <PaperLayoutRoot>
      <PaperLayoutLogoWrapper>
        <Logo />
      </PaperLayoutLogoWrapper>

      <Outlet />
    </PaperLayoutRoot>
  );
};
