import { ApiCommonLeagueEnum, ApiCommonSportEnum } from '~shared/api';
import { routes } from '~shared/config';

import { SportItemConfig } from '../types';

export const FootballLeaguesConfig: { [key in ApiCommonLeagueEnum]?: SportItemConfig } = {
  [ApiCommonLeagueEnum.UEFAChampions]: {
    id: 'UEFA-Champions-League',
    label: 'UEFA Champions League',
    icon: 'illustration-football-uefa-champions-league',
    illustration: '',
    route: routes.sports.uefaChampionsLeague,
    dates: null,
    league: ApiCommonLeagueEnum.UEFAChampions,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
  [ApiCommonLeagueEnum.UEFAEurope]: {
    id: 'UEFA-Europa-Conference-League',
    label: 'UEFA Europa Conference League',
    icon: 'illustration-football-uefa-europa-conference-league',
    illustration: '',
    route: routes.sports.uefaEuropaConferenceLeague,
    dates: null,
    league: ApiCommonLeagueEnum.UEFAEurope,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
  [ApiCommonLeagueEnum.EnglishLeague]: {
    id: 'English-Premier-League',
    label: 'English Premier League',
    icon: 'illustration-football-premier-league',
    illustration: '',
    route: routes.sports.englishPremierLeague,
    dates: null,
    league: ApiCommonLeagueEnum.EnglishLeague,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
  [ApiCommonLeagueEnum.FrenchLeague]: {
    id: 'Ligue-1',
    label: 'Ligue 1',
    icon: 'illustration-football-ligue-1',
    illustration: '',
    route: routes.sports.ligue1,
    dates: null,
    league: ApiCommonLeagueEnum.FrenchLeague,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
  [ApiCommonLeagueEnum.SpainLeague]: {
    id: 'LaLiga',
    label: 'LaLiga',
    icon: 'illustration-football-la-liga',
    illustration: '',
    route: routes.sports.laLiga,
    dates: null,
    league: ApiCommonLeagueEnum.SpainLeague,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
  [ApiCommonLeagueEnum.GermanLeague]: {
    id: 'Bundesliga',
    label: 'Bundesliga',
    icon: 'illustration-football-bundesliga',
    illustration: '',
    route: routes.sports.bundesliga,
    dates: null,
    league: ApiCommonLeagueEnum.GermanLeague,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
  [ApiCommonLeagueEnum.ItalyLeague]: {
    id: 'Serie-A',
    label: 'Serie A',
    icon: 'illustration-football-serie-a',
    illustration: '',
    route: routes.sports.serieA,
    dates: null,
    league: ApiCommonLeagueEnum.ItalyLeague,
    sport: ApiCommonSportEnum.Tennis,
    tournament: null,
  },
};
