import { FC } from 'react';

import { Typography } from '~shared/ui';

import { EventDialogStatisticsRowProps } from './types';
import {
  EventDialogStatisticsProgress,
  EventDialogStatisticsRowBottomContainer,
  EventDialogStatisticsRowContainer,
  EventDialogStatisticsRowTopContainer,
} from './styled';

export const EventDialogStatisticsRow: FC<EventDialogStatisticsRowProps> = ({
  leftColumnScore,
  rightColumnScore,
  label,
}) => {
  const overall = leftColumnScore + rightColumnScore;

  return (
    <EventDialogStatisticsRowContainer>
      <EventDialogStatisticsRowTopContainer>
        <Typography fontWeight={600} align="left">
          {leftColumnScore}
        </Typography>
        <Typography fontSize={13} color="secondary" align="center">
          {label}
        </Typography>
        <Typography fontWeight={600} align="right">
          {rightColumnScore}
        </Typography>
      </EventDialogStatisticsRowTopContainer>

      <EventDialogStatisticsRowBottomContainer>
        <EventDialogStatisticsProgress
          progress={getProgress(leftColumnScore, overall)}
          reverse
          $loses={leftColumnScore < rightColumnScore}
        />
        <EventDialogStatisticsProgress
          progress={getProgress(rightColumnScore, overall)}
          $loses={rightColumnScore < leftColumnScore}
        />
      </EventDialogStatisticsRowBottomContainer>
    </EventDialogStatisticsRowContainer>
  );
};

const getProgress = (score: number, overall: number) => {
  return (score / overall) * 100;
};
