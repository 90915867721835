import { ApiGetCallsMappedData } from '../calls';
import { ApiGetBattlesByIdsMappedData, BattleResult } from '../battle';

export interface NftRelatedCall extends ApiGetCallsMappedData {
  isCall: true;
  cards: Array<PartialNft>;
}

export interface NftRelatedBet extends ApiGetBattlesByIdsMappedData {
  isCall: false;
  cards: Array<PartialNft>;
  choice: BattleResult;
}

export type NftRelatedEvent = NftRelatedBet | NftRelatedCall;

export interface NftRelatedAuction {
  cardId: string;
  betsAcceptedUntil: Date;
}

export interface Nft {
  amount: string;
  block_number: string;
  block_number_minted: string;
  contract_type: string;
  last_metadata_sync: string;
  last_token_uri_sync: string;
  metadata: string | null;
  name: string;
  owner_of: string;
  symbol: string;
  token_address: string;
  token_hash: string;
  token_id: string;
  token_uri: string;
  minter_address: string;
  rarity: NftRarity;
  livesRemaining: number;
  winStreak: number;
  readableRarity: NftReadableRarity;
  rewardForCorrectVote: number;
  instantRestorationCost: number;
  availableAmountOfRecoveries: number;
  frozenUntil: Date;
  isFreezed: boolean;
  isOnEvent: boolean;
  isBlockedForEvent: boolean;
  isLive: boolean;
  isHidden: boolean;
  isOnAuction: boolean;
  relatedEvent?: NftRelatedEvent;
  relatedAuction?: NftRelatedAuction;
}

export enum NftRarity {
  Common,
  Rare,
  Epic,
  Legendary,
  Mythic,
}

export type NftReadableRarity = 'Common' | 'Rare' | 'Epic' | 'Legendary' | 'Mythic';

/**
 * Get maincard NFTs from moralis
 */
interface ApiGetNftsRequestData {
  walletAddress: string;
}

type ApiGetNftsResponseData = Array<Nft>;

export interface ApiGetNfts {
  (data: ApiGetNftsRequestData): Promise<ApiGetNftsResponseData>;
}

/**
 * NFT type with optional secondary properties
 */
export type PartialNft = Partial<Nft> &
  Pick<
    Nft,
    | 'winStreak'
    | 'rarity'
    | 'livesRemaining'
    | 'rewardForCorrectVote'
    | 'instantRestorationCost'
    | 'availableAmountOfRecoveries'
    | 'readableRarity'
    | 'token_id'
  >;
