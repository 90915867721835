import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, Divider, Stack } from '~shared/ui';

import { NftCard } from '../NftCard';
import { NftCardInfoStatus } from '../NftCardInfoStatus';

import { NftCardInfoActions } from './NftCardInfoActions';
import { NftCardInfoContent } from './NftCardInfoContent';
import { NftCardInfoRelatedEvent } from './NftCardInfoRelatedEvent';

import {
  NftCardInfoMobileActionsContainer,
  NftCardInfoMobileContainer,
  NftCardInfoMobileHeading,
} from './styled';

import { NftCardInfoMobileProps } from './types';

export const NftCardInfoMobile: FC<NftCardInfoMobileProps> = ({ onBack, nft }) => {
  const { t } = useTranslation();

  return (
    <NftCardInfoMobileContainer>
      <Stack direction="row" spacing={20 / 8} alignItems="center">
        <ButtonIcon variant="plain" name="chevron-left" onClick={onBack} />

        <NftCard nft={nft} hideStates size="sm" />

        <Stack spacing={5 / 8} alignItems="flex-start">
          <NftCardInfoMobileHeading>{t(nft.readableRarity)}</NftCardInfoMobileHeading>
          <NftCardInfoStatus nft={nft} />
        </Stack>
      </Stack>

      <Divider mt={14 / 8} />

      <NftCardInfoContent spacing={16 / 8} mt={20 / 8} flex={1} nft={nft} />

      <Stack mt={18 / 8}>
        <NftCardInfoRelatedEvent nft={nft} />
      </Stack>

      <NftCardInfoMobileActionsContainer>
        <NftCardInfoActions nft={nft} mobile />
      </NftCardInfoMobileActionsContainer>
    </NftCardInfoMobileContainer>
  );
};
