type Strategy = 'env' | 'origin';

export const isProduction = (strategy: Strategy = 'env') => {
  if (strategy === 'origin') {
    return !['beta', 'test', 'stage', 'localhost'].some((s) => window.location.origin.includes(s));
  }

  return process.env.REACT_APP_IS_PRODUCTION === 'true';
};

export const isTest = (strategy: Strategy = 'env') => {
  if (strategy === 'origin') {
    return !isProduction(strategy);
  }

  return process.env.REACT_APP_IS_BETA === 'true';
};
