import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from '@mui/system';

import { Theme } from '~shared/lib/styles';

import { ShimmerProps } from './types';

export const Shimmer: FC<ShimmerProps> = ({ ...props }) => {
  const { palette } = useTheme<Theme>();

  return (
    <ContentLoader
      speed={2}
      display="block"
      backgroundColor={palette.paper.dark}
      foregroundColor={palette.paper.semi}
      {...props}
    />
  );
};
