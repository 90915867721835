import { styled, theme } from '~shared/lib/styles';

import { WalletHistoryRowType } from './types';

interface WalletHistoryIconPlateProps {
  type: WalletHistoryRowType;
}

export const WalletHistoryIconPlate = styled('div')<WalletHistoryIconPlateProps>(({ type }) => ({
  background: getPlateBackgroundColor(type),
  padding: 8,
  borderRadius: 14,
}));

const getPlateBackgroundColor = (type: WalletHistoryRowType): string => {
  switch (type) {
    case 'merge':
      return theme.palette.success.main;
    case 'purchase':
    case 'auction':
    case 'freeze':
      return theme.palette.violet.main;
    case 'repair':
      return theme.palette.warn.secondary;
    case 'unfreeze':
    case 'lose':
    case 'win':
      return theme.palette.primary.light;
  }
};

export const WalletHistoryDate = styled('div')(({ theme }) => ({
  color: theme.palette.grey.A50,

  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

interface WalletHistoryRowAmountProps {
  negative?: boolean;
}

export const WalletHistoryRowAmount = styled('div')<WalletHistoryRowAmountProps>(
  ({ theme, negative }) => ({
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 14 / 14,
    letterSpacing: -0.2,
    color: negative ? theme.palette.error.main : theme.palette.primary.light,
    verticalAlign: 'middle',

    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  })
);
