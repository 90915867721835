import { styled } from '~shared/lib/styles';

export const WalletFinanceCardsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 20,

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const TableHeadingCell = styled('div')(({ theme }) => ({
  fontSize: 13,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A50,
}));
