import { FC, PropsWithChildren } from 'react';

import { Button } from '~shared/ui';

import { useFixCardModel } from '../model';

import { FixCardProps } from './types';

export const FixCard: FC<PropsWithChildren<FixCardProps>> = ({ nft, children, ...buttonProps }) => {
  const { handleOpenFixCardDialog } = useFixCardModel(nft);

  return (
    <Button {...buttonProps} onClick={handleOpenFixCardDialog}>
      {children}
    </Button>
  );
};
