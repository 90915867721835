import { forwardRef, useContext } from 'react';
import { clsx } from 'clsx';

import { useInView } from 'react-intersection-observer';

import { mergeRefs } from '~shared/lib/utils';
import { BattleShellContext } from '~entities/battle';

import { BattleShellRootProps } from './types';

import { BattleShellRoot, battleShellRootClasses } from './styled';

export const BattleShell = forwardRef<HTMLDivElement, BattleShellRootProps>(
  ({ className, overlapBackdropAvailable, highlight, children, ...props }, ref) => {
    const { variant } = useContext(BattleShellContext);
    const { ref: inViewRef, inView } = useInView();

    return (
      <BattleShellRoot
        className={clsx(className, {
          [battleShellRootClasses.overlapBackdropAvailable]: overlapBackdropAvailable,
          [battleShellRootClasses.highlighted]: highlight,
          [battleShellRootClasses.clickable]: props.onClick || props.onClickCapture,
        })}
        ref={mergeRefs([ref, inViewRef])}
        {...props}
      >
        {variant === 'square' ? (inView ? children : null) : children}
      </BattleShellRoot>
    );
  }
);
