import { styled } from '~shared/lib/styles';

interface TournamentPlayerRootProps {
  active: boolean;
}

export const TournamentPlayerRoot = styled('div')<TournamentPlayerRootProps>(
  ({ active, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '8px 14px',
    borderRadius: 8,

    background: active ? theme.palette.grey.A10 : 'transparent',
  })
);

export const TournamentPlayerBrief = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
}));

export const TournamentPlayerPlace = styled('div')(() => ({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: 24 / 18,
  letterSpacing: -0.2,
  minWidth: 24,
}));

export const TournamentPlayerName = styled('div')(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 14 / 14,
}));
