import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import { Nft } from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';
import { NftSelectionModeType, useNftSelector } from '~entities/nft';

import { routes } from '~shared/config';

import { nftActions } from './slice';

export const useNftCardModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    /***/
    isSelectionMode,
    selectionModeType,
    isDropZoneOpen,
    nfts,
    nftsToMerge,
    previewInfo,

    dialogs: {
      merge: { open: mergeDialogOpen },
    },
  } = useNftSelector();

  const { avatar, nickname, wallet } = useViewerModel();

  const onOpenCardInfo = useCallback(
    (nft: Nft) => {
      dispatch(
        nftActions.setDetailedInfo({
          open: true,
          tokenId: nft.token_id,
          avatarUrl: avatar?.src,
          nickname,
          walletAddress: wallet,
        })
      );
    },
    [avatar?.src, dispatch, nickname, wallet]
  );

  const setSelectionMode = useCallback(
    (enabled: boolean) => {
      dispatch(nftActions.setSelectionMode(enabled));

      if (!enabled) {
        dispatch(nftActions.setSelectionModeType('bet'));
      }
    },
    [dispatch]
  );

  const setSelectionModeType = useCallback(
    (type: NftSelectionModeType) => {
      dispatch(nftActions.setSelectionModeType(type));
    },
    [dispatch]
  );

  const onAddCardsToMerge = useCallback(() => {
    setSelectionModeType('merge');
    setSelectionMode(true);
    navigate(routes.cards);
  }, [navigate, setSelectionMode, setSelectionModeType]);

  const onCancelAddCardsToMerge = useCallback(() => {
    setSelectionModeType('bet');
    setSelectionMode(false);
  }, [setSelectionMode, setSelectionModeType]);

  const setMergeDialogOpen = useCallback(
    (open: boolean) => {
      dispatch(nftActions.setMergeDialogOpen(open));
    },
    [dispatch]
  );

  const setDropZoneOpen = useCallback(
    (open: boolean) => {
      dispatch(nftActions.setDropZone(open));
    },
    [dispatch]
  );

  return {
    nfts,
    nftsToMerge,
    previewInfo,

    isDropZoneOpen,
    setDropZoneOpen,

    setSelectionMode,
    setSelectionModeType,
    isSelectionMode,
    selectionModeType,

    onOpenCardInfo,
    onAddCardsToMerge,
    onCancelAddCardsToMerge,

    mergeDialogOpen,
    setMergeDialogOpen,
  };
};
