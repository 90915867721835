import { styled } from '~shared/lib/styles';

export const UIKitCircularProgressViewRoot = styled('div')({
  flex: 1,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  inset: 0,
});
