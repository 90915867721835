import { Web3ReactHooks } from '@web3-react/core';
import { Connector } from '@web3-react/types';

import { IconAssetNamesType } from '~shared/ui';

export enum ConnectorId {
  MetaMask = 'metaMask',
  WalletConnect = 'walletConnectLegacy',
  Coinbase = 'coinbaseWallet',
  Trust = 'trustWallet',
}

export type WalletBalance = {
  mcn: number;
  native: number;
};

export type WalletState = {
  isConnectWalletDialogOpen: boolean;
  balance: WalletBalance;
  isWarnDialogOpen: boolean;
  isWalletConnectedDialogOpen: boolean;
  isOnboardingShownAfterConnect: boolean;
  isCorrectWalletConnected: boolean;
};

export enum TransactionError {
  Rejected = 'ACTION_REJECTED',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
}

export enum ConnectionType {
  Injected = 'INJECTED',
  Coinbase = 'COINBASE_WALLET',
  WalletConnect = 'WALLET_CONNECT',
}

export interface Connection {
  name: string;
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
  icon: IconAssetNamesType;
  installed: boolean;
  downloadLink?: string;
  deepLink?: string;
}

export enum SupportedChainId {
  Polygon = 137,
}
