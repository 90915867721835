import { PartialNft } from '../nfts';

import { ApiCommonLeague, ApiCommonSport, ApiCommonSportEnum } from '../shared';

/**
 * Get battles
 */
export interface ApiGetBattlesRequestData {
  sport: Array<ApiCommonSport> | ApiCommonSport;
  league?: ApiCommonLeague;
}

export interface ApiGetBattlesResponseData extends Array<ApiGetBattlesMappedData> {}

export interface ApiGetBattles {
  (data: ApiGetBattlesRequestData): Promise<ApiGetBattlesResponseData>;
}

export interface ApiGetBattlesRawData {
  homeTeam: ApiGetBattlesRawTeam;
  awayTeam: ApiGetBattlesRawTeam;
  date: string;
  sport: ApiCommonSport;
  id: string;
  league: string;
}

export interface ApiGetBattlesMappedData {
  homeTeam: ApiGetBattlesTeam;
  awayTeam: ApiGetBattlesTeam;
  date: Date;
  sport: ApiCommonSport;
  relatedNft?: RelatedNft;
  id: string;
  league: string;
}

/** Raw team */
export type ApiGetBattlesRawTeam =
  | ApiGetBattlesRawCommonTeam
  | ApiGetBattlesRawFootballTeam
  | ApiGetBattlesRawTennisTeam;

interface ApiGetBattlesRawCommonTeam {
  id: string;
  name: string;
  logo: string;

  // Tennis
  players?: Array<ApiGetBattlesRawPlayer>;

  // Football
  wins?: number;
  goals?: number;
  losses?: number;
}

export interface ApiGetBattlesRawFootballTeam extends ApiGetBattlesRawCommonTeam {
  wins: number;
  goals: number;
  losses: number;
}

export interface ApiGetBattlesRawTennisTeam extends ApiGetBattlesRawCommonTeam {
  name: never;
  logo: never;
  players: Array<ApiGetBattlesTennisPlayer>;
}

/** Raw player */
type ApiGetBattlesRawPlayer = ApiGetBattlesRawCommonPlayer | ApiGetBattlesRawTennisPlayer;

interface ApiGetBattlesRawCommonPlayer {
  name: string;
  logo: string;

  /* Tennis */
  handedness?: 'left' | 'right';
  highestDoublesRanking?: number;
  highestSinglesRanking?: number;
  points?: number;
  rank?: number;
}

export interface ApiGetBattlesRawTennisPlayer extends ApiGetBattlesRawCommonPlayer {
  name: string;
  logo: string;

  handedness: 'left' | 'right';
  highestDoublesRanking: number;
  highestSinglesRanking: number;
  points: number;
  rank: number;
}

/** Team */
export type ApiGetBattlesTeam =
  | ApiGetBattlesCommonTeam
  | ApiGetBattlesFootballTeam
  | ApiGetBattlesTennisTeam;

interface ApiGetBattlesCommonTeam {
  id: string;
  name: string;
  logo: string;
  players: Array<ApiGetBattlesPlayer>;

  // Copying props from ApiGetBattles%SPORT%Team types
  wins?: number;
  goals?: number;
  losses?: number;
}

export interface ApiGetBattlesFootballTeam extends ApiGetBattlesCommonTeam {
  wins: number;
  goals: number;
  losses: number;
}

export interface ApiGetBattlesTennisTeam extends ApiGetBattlesCommonTeam {
  name: never;
  logo: never;
  players: Array<ApiGetBattlesTennisPlayer>;
}

/** Player */
type ApiGetBattlesPlayer = ApiGetBattlesCommonPlayer | ApiGetBattlesTennisPlayer;

interface ApiGetBattlesCommonPlayer {
  name: string;
  shortName: string;
  logo: string;

  /* Tennis */
  handedness?: 'left' | 'right';
  highestDoublesRanking?: number;
  highestSinglesRanking?: number;
  points?: number;
  rank?: number;
}

export interface ApiGetBattlesTennisPlayer extends ApiGetBattlesCommonPlayer {
  name: string;
  shortName: string;
  logo: string;

  handedness: 'left' | 'right';
  highestDoublesRanking: number;
  highestSinglesRanking: number;
  points: number;
  rank: number;
}

/**
 * Get battles by list of ids
 */
export interface ApiGetBattlesByIdsRequestData {
  ids: Array<string | number>;
}

export interface ApiGetBattlesByIdsResponseData extends Array<ApiGetBattlesByIdsMappedData> {}

// todo: maybe unite this request with original GetBattles
export interface ApiGetBattlesByIds {
  (data: ApiGetBattlesByIdsRequestData): Promise<ApiGetBattlesByIdsResponseData>;
}

export interface ApiGetBattlesByIdsRawData {
  homeTeam: ApiGetBattlesRawTeam;
  awayTeam: ApiGetBattlesRawTeam;
  date: string;
  sport: ApiCommonSportEnum;
  id: string;
  league: string;
  result: BattleResult;
}

export interface ApiGetBattlesByIdsMappedData {
  id: string;
  date: Date;
  sport: ApiCommonSport | ApiCommonSportEnum;
  homeTeam: ApiGetBattlesTeam;
  awayTeam: ApiGetBattlesTeam;
  result: BattleResult;
  league: string;
}

/**
 * Shared types
 */
export enum BattleChoiceId {
  HomeTeam = 2,
  Draw = 3,
  AwayTeam = 4,
}

export enum BattleResult {
  InProgress,
  Cancelled,
  HomeTeamWon,
  Draw,
  AwayTeamWon,
}

export type BattleResultChoice =
  | BattleResult.HomeTeamWon
  | BattleResult.Draw
  | BattleResult.AwayTeamWon;

interface RelatedNft {
  choice: BattleResult;
  cards: Array<PartialNft>;
}
