import { useUnmount } from 'react-use';

import { NotificationsCenter, useNotificationsModel } from '~entities/notifications';

import { NotificationsPageContainer } from './styled';

export const NotificationsPage = () => {
  const { service } = useNotificationsModel();

  // todo: move to somewhere else (no logic on Page slice)
  useUnmount(() => {
    service?.applyReadStack();
  });

  return (
    <NotificationsPageContainer>
      <NotificationsCenter backNavigation />
    </NotificationsPageContainer>
  );
};
