import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const NftShouldBeRecoveredRoot = styled(Box, { name: 'NftShouldBeRecovered', slot: 'root' })(
  {
    position: 'absolute',
    right: 7,
    bottom: 8,
    background: '#FF922D',
    borderRadius: '20px',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
);

export const NftShouldBeRecoveredIconContainer = styled(Box, {
  name: 'NftShouldBeRecovered',
  slot: 'iconContainer',
})({
  background: '#FF922D',
  borderRadius: '20px',
});
