import { FC } from 'react';

import { useDebouncedBooleanTransition } from '~shared/lib/hooks';

import { useBattleModel } from '~entities/battle';

import { BattleGlobalStyled } from './styled';

export const BattleGlobalStyles: FC = () => {
  const { isOverlapBackdrop } = useBattleModel();

  const debouncedIsOverlapBackdrop = useDebouncedBooleanTransition(isOverlapBackdrop, {
    strategy: 'end',
  });

  return <BattleGlobalStyled isOverlapBackdrop={debouncedIsOverlapBackdrop} />;
};
