import { TabsList as MuiBaseTabsList, tabsListClasses as muiBaseTabsListClasses } from '@mui/base';

import { styled } from '~shared/lib/styles';

export const menuTabsListClasses = {
  ...muiBaseTabsListClasses,
};

export const UIKitMenuTabsList = styled(MuiBaseTabsList)({
  display: 'flex',
});
