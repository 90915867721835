export const SportsIllustrations = {
  all: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-all.jpg',
  baseball: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-baseball.jpg',
  basketball: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-basketball.jpg',
  cricket: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-cricket.jpg',
  eSport: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-e-sport.jpg',
  football: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-football.jpg',
  hockey: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-hockey.jpg',
  mma: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-mma.jpg',
  tennis: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-tennis.jpg',
  americanFootball: process.env.PUBLIC_URL + '/assets/sport-line/sport-line-american-football.jpg',
};
