import { FC } from 'react';

import { SelectOption } from '../SelectOption';
import { ToggleButton } from '../ToggleButton';

import { ToggleButtonMenuProps } from './types';
import { UIKitToggleMenuSelect } from './styled';

export const ToggleButtonMenu: FC<ToggleButtonMenuProps> = ({ options, ...props }) => {
  return (
    <UIKitToggleMenuSelect
      slots={{ root: ToggleButton }}
      slotProps={{ root: { value: false as any } }}
      {...props}
    >
      {options?.map((option) => (
        <SelectOption key={option.value} value={option.value}>
          {option.label}
        </SelectOption>
      ))}
    </UIKitToggleMenuSelect>
  );
};
