import { styled } from '~shared/lib/styles';

export const Root = styled('button', { name: 'WalletCard', slot: 'root' })(({ theme }) => ({
  background: theme.palette.grey.A5,
  padding: '10px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  color: 'inherit',
  border: 'none',

  transition: theme.transitions.create(['box-shadow']),

  ['&:hover']: {
    boxShadow: '0px 0px 8px #42BAFF',
  },
}));
