import { FC } from 'react';

import { Stack } from '~shared/ui';

import { TournamentPlayer } from '../TournamentPlayer';

import { TournamentPlayersProps } from './types';
import { PlayersHeading } from './styled';

export const TournamentPlayers: FC<TournamentPlayersProps> = ({ players, playersLimit }) => {
  const playersCount = players.length;

  return (
    <Stack spacing={20 / 8}>
      <Stack direction="row" justifyContent="space-between">
        <PlayersHeading>Players</PlayersHeading>
        <PlayersHeading>
          {playersCount}/{playersLimit}
        </PlayersHeading>
      </Stack>

      <Stack>
        {players.map((player) => (
          <TournamentPlayer
            key={player.place}
            place={player.place}
            name={player.name}
            avatar={player.avatar}
            cards={player.cards}
            isViewer={player.place === 1}
          />
        ))}
      </Stack>
    </Stack>
  );
};
