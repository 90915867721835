import { customScrollbar, styled } from '~shared/lib/styles';

export const EventsContainer = styled('div', {
  name: 'NftCardDetailedInfoEvents',
  slot: 'eventsContainer',
})(({ theme }) => ({
  ...customScrollbar,

  flex: 1,
  maxHeight: 400,
  overflowY: 'auto',
  marginRight: -10,
  padding: '10px',
  marginTop: '14px',

  [theme.breakpoints.down('mobile')]: {
    maxHeight: 'unset',
    padding: '0 10px',
    marginLeft: '-16px',
  },
}));
