import { styled } from '~shared/lib/styles';

export const UIKitTagRoot = styled('div')(({ theme }) => ({
  padding: '6px 12px',
  background: theme.palette.violet.main,
  borderRadius: 32,
  userSelect: 'none',

  fontSize: 12,
  fontWeight: 500,
  lineHeight: 14 / 12,
}));
