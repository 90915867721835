import { OnboardingSteps } from '../model';

export const OnboardingStepsContent: Record<
  OnboardingSteps,
  { title: string; text: string; illustration: string }
> = {
  [OnboardingSteps.ConnectWallet]: {
    title: 'onBoarding.titleStep1',
    text: 'onBoarding.textStep1',
    illustration: process.env.PUBLIC_URL + '/assets/onboarding/step-1.png',
  },
  [OnboardingSteps.FreeMaincard]: {
    title: 'onBoarding.titleStep2',
    text: 'onBoarding.textStep2',
    illustration: process.env.PUBLIC_URL + '/assets/onboarding/step-2.png',
  },
  [OnboardingSteps.Bet]: {
    title: 'onBoarding.titleStep3',
    text: 'onBoarding.textStep3',
    illustration: process.env.PUBLIC_URL + '/assets/onboarding/step-3.png',
  },
  [OnboardingSteps.CashPrizes]: {
    title: 'onBoarding.titleStep4',
    text: 'onBoarding.textStep4',
    illustration: process.env.PUBLIC_URL + '/assets/onboarding/step-4.png',
  },
  [OnboardingSteps.Repair]: {
    title: 'onBoarding.titleStep5',
    text: 'onBoarding.textStep5',
    illustration: process.env.PUBLIC_URL + '/assets/onboarding/step-5.png',
  },
  [OnboardingSteps.Merge]: {
    title: 'onBoarding.titleStep6',
    text: 'onBoarding.textStep6',
    illustration: process.env.PUBLIC_URL + '/assets/onboarding/step-6.png',
  },
};
