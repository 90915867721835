import { queryClient } from '~shared/api';

import { useViewerModel } from '~entities/viewer';

export const useProfileModel = () => {
  const { wallet } = useViewerModel();

  const profileBattlesQueryKey = ['profileBattles', wallet];

  const invalidateProfileBattlesQuery = () => {
    queryClient.invalidateQueries(profileBattlesQueryKey);
  };

  return {
    invalidateProfileBattlesQuery,
    profileBattlesQueryKey,
  };
};
