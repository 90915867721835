import { useEffect } from 'react';

import { useSearchParamsState } from '~shared/lib/hooks';

import { useViewerModel } from '~entities/viewer';

import { REFERRAL_CODE_SEARCH_PARAM_KEY } from '../../lib';

import { useCreateReferralMutation } from './useCreateReferralMutation';

export const useReferralVerification = () => {
  const { authorized } = useViewerModel();
  const { mutate } = useCreateReferralMutation();
  const [referralCode] = useSearchParamsState(REFERRAL_CODE_SEARCH_PARAM_KEY, '');

  useEffect(() => {
    if (authorized && referralCode) {
      mutate({
        referralCode: referralCode,
        extra: '',
      });
    }
  }, [authorized, mutate, referralCode]);

  return;
};
