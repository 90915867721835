import { addDays, isWithinInterval } from 'date-fns';

import { SportItemConfig } from '~shared/config/sport/types';

export const isShouldShowMenuItem = (dates: SportItemConfig['dates']) => {
  if (!dates) {
    return true;
  }

  return dates.some((dates) => {
    const { startDate, endDate: end } = dates;

    const start = addDays(startDate, -7);

    return isWithinInterval(new Date(), { start, end });
  });
};
