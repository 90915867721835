import { css } from '@mui/system';

import { customScrollbar, styled } from '~shared/lib/styles';
import { normalizeMobileWindowHeight } from '~shared/lib/utils';

export const DefaultLayoutRoot = styled('div', { name: 'DefaultLayout' })(
  {
    display: 'flex',
    flexDirection: 'column',
  },
  css`
    height: calc(100vh);
    height: var(${normalizeMobileWindowHeight.cssVariableName});
  `
);

export const DefaultLayoutBottomDesktop = styled('div', {
  name: 'DefaultLayout',
  slot: 'bottomDesktop',
})(({ theme }) => ({
  display: 'flex',
  flexBasis: '100%',
  alignItems: 'stretch',
  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexBasis: '100%',

    alignItems: 'initial',
    height: 'initial',

    position: 'relative',
    overflow: 'hidden',
  },
}));

interface DefaultLayoutMainProps {
  $gutters: boolean;
  $pinScrollbar: boolean;
}

export const DefaultLayoutMain = styled('main', {
  name: 'DefaultLayout',
  slot: 'main',
})<DefaultLayoutMainProps>(({ theme, $gutters, $pinScrollbar }) => {
  const guttersStyles = {
    paddingTop: 20,
    paddingBottom: 20,

    [theme.breakpoints.down('mobile')]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  };

  return {
    // prevent layout shifts on content change
    overflowY: $pinScrollbar ? 'scroll' : 'auto',

    flexBasis: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    ...($gutters ? guttersStyles : null),

    ...customScrollbar,
  };
});
