import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';
import { ConfirmationDialog } from '~shared/ui';

import { useEventEffects, useEventModel } from '~entities/event';

import { EventDialogMobile } from './EventDialogMobile';
import { EventDialogDesktop } from './EventDialogDesktop';

import { EventDialogContent } from './EventDialogContent';

import { EventDialogCallsBanner } from './EventDialogCallsBanner';
import { EventDialogPreloader } from './EventDialogPreloader';

export const EventDialog = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const {
    closingConfirmationDialog,

    isLoading,
    isOpen,

    onClose,
    onConfirmCloseEvent,
  } = useEventModel();

  useEventEffects();

  const closeEventConfirmationDialog = (
    <ConfirmationDialog
      open={closingConfirmationDialog.open}
      title="Warning"
      onConfirm={onConfirmCloseEvent}
      onClose={closingConfirmationDialog.switchOff}
      cancelButtonText={t('SignOut.no')}
      targetButtonText={t('Other.confirm')}
    >
      {t('Other.areYouSure')}
      <br />
      {t('Other.yourVote')}
    </ConfirmationDialog>
  );

  const content = isLoading ? <EventDialogPreloader /> : <EventDialogContent />;

  if (isMobile) {
    return (
      <EventDialogMobile open={isOpen} onClose={onClose}>
        {closeEventConfirmationDialog}

        {content}

        <EventDialogCallsBanner />
      </EventDialogMobile>
    );
  }

  return (
    <EventDialogDesktop open={isOpen} onClose={onClose}>
      {closeEventConfirmationDialog}

      {content}

      <EventDialogCallsBanner />
    </EventDialogDesktop>
  );
};
