import { subMonths, subWeeks } from 'date-fns';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { isEventCurrent, isEventNotStarted, isEventPassed } from '~entities/battle';
import { useViewerSelector } from '~entities/viewer';

import { ApiGetBetsBetMappedData, BattleResult, getBets } from '~shared/api';

import { BattlesDashboardPeriod } from './types';

interface UseBetsQueryParams {
  wallet: string;
  queryKey?: Array<string>;

  filterCallback?: (
    value: ApiGetBetsBetMappedData,
    index?: number,
    array?: ApiGetBetsBetMappedData[]
  ) => boolean;
}

export const useBetsQuery = ({
  wallet,
  queryKey = ['bets', wallet],
  filterCallback,
}: UseBetsQueryParams) => {
  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      let bets = await getBets({ address: wallet }).then((bets) =>
        bets.sort((a, b) => b.date.getTime() - a.date.getTime())
      );

      if (filterCallback) {
        bets = bets.filter(filterCallback);
      }

      return bets;
    },
    { enabled: !!wallet }
  );

  return {
    isLoading,
    isEmptyList: data && data.length === 0,

    list: data,
    passedList: data?.filter((bet) => isEventPassed(bet.result)),
    currentList: data?.filter((bet) => isEventCurrent(bet.result)),
  };
};

export const useBetsDashboardQuery = (period: BattlesDashboardPeriod) => {
  const { wallet } = useViewerSelector();

  const betsQuery = useBetsQuery({
    wallet,
    filterCallback: (bet) =>
      bet.isTakenBeforeMatchStarted !== 1 && bet.result !== BattleResult.Cancelled,
  });

  const dashboard = useMemo(() => {
    const dashboard = {
      total: 0,
      future: 0,
      wins: 0,
      loses: 0,
      accuracy: 0,
    };

    if (betsQuery.list) {
      const currentDate = new Date();
      const weekAgoDate = subWeeks(currentDate, 1);
      const monthAgoDate = subMonths(currentDate, 1);

      const bets =
        period === 'all'
          ? betsQuery.list
          : betsQuery.list.filter((bet) => {
              if (period === 'month') {
                const isBetMadeInLastMonth = bet.date > monthAgoDate;

                return isBetMadeInLastMonth;
              }

              const isBetMadeInLastWeek = bet.date > weekAgoDate;

              return isBetMadeInLastWeek;
            });

      bets.forEach((bet) => {
        if (isEventPassed(bet.result)) {
          dashboard.total++;

          if (bet.result === bet.choiceId) {
            dashboard.wins++;
          } else {
            dashboard.loses++;
          }
        } else if (isEventNotStarted(bet.date) || isEventCurrent(bet.result)) {
          dashboard.future++;
        }
      });
    }

    if (dashboard.wins !== 0) {
      dashboard.accuracy = dashboard.wins / dashboard.total;
    }

    return dashboard;
  }, [betsQuery.list, period]);

  return {
    isLoading: betsQuery.isLoading,
    isEmpty: betsQuery.isEmptyList,
    dashboard,
  };
};
