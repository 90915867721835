import { FC, ReactElement, cloneElement, forwardRef, isValidElement, useMemo } from 'react';
import { ClickAwayListener, PopperChildrenProps } from '@mui/base';

import { MenuProps } from './types';
import { UIKitMenu, UIKitMenuListbox, UIKitMenuPopper } from './styled';

export const Menu: FC<MenuProps> = ({ onClose, open, anchorEl, ...props }) => {
  const MenuListbox = useMemo(
    () =>
      forwardRef<HTMLElement>(({ open, TransitionProps, ...props }: any, ref) => {
        const animateVariant = open ? 'visible' : 'hidden';
        const { onExited, onEnter } = TransitionProps ?? {};

        const listBox = (
          <UIKitMenuListbox
            {...props}
            ref={ref}
            variants={motionVariants}
            animate={animateVariant}
            initial="hidden"
            onAnimationStart={open ? onEnter : undefined}
            onAnimationComplete={!open ? onExited : undefined}
          />
        );

        if (onClose) {
          return <ClickAwayListener onClickAway={onClose}>{listBox}</ClickAwayListener>;
        }

        return listBox;
      }),
    [onClose]
  );

  return (
    <UIKitMenu
      {...props}
      slotProps={{ ...props.slotProps, root: { open, anchorEl, ...props.slotProps?.root } }}
      slots={{ root: MenuRoot, listbox: MenuListbox, ...props.slots }}
    />
  );
};

const MenuRoot = forwardRef<HTMLElement>(({ open, modifiers = [], ...props }: any, ref) => {
  return (
    <UIKitMenuPopper
      placement="bottom-start"
      modifiers={[...popperModifiers, ...modifiers]}
      open={open}
      ref={ref}
      transition
      {...props}
    >
      {({ TransitionProps }: PopperChildrenProps) => {
        const children = props.children as ReactElement<Record<any, any>>;

        return isValidElement(children)
          ? cloneElement(children, { ...children.props, open, TransitionProps })
          : children;
      }}
    </UIKitMenuPopper>
  );
});

const popperModifiers = [{ name: 'offset', options: { offset: [0, 3] } }];

const motionVariants = {
  hidden: { opacity: 0, y: -10, transition: { duration: 0.2, type: 'tween' } },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2, type: 'tween' } },
};
