import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

interface RootProps {
  $isCorrectChoice: boolean;
}

export const Root = styled(Box, { name: 'EventDialogChoiceResult', slot: 'root' })<RootProps>(({
  theme,
  $isCorrectChoice,
}) => {
  return {
    padding: 10,
    borderRadius: 8,
    background: $isCorrectChoice ? theme.palette.primary.main : theme.palette.error.main,

    [theme.breakpoints.down('mobile')]: {
      padding: 9,
    },
  };
});

export const StyledTypography = styled(Box, {
  name: 'EventDialogChoiceResult',
  slot: 'typography',
})({
  fontSize: '13px',
  lineHeight: '14px',
  fontWeight: 500,
});
