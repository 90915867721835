import { Children, FC } from 'react';
import { FreeMode, Mousewheel } from 'swiper/modules';

import { HorizontalListRoot, HorizontalListSlide, HorizontalListSwiper } from './styled';
import { HorizontalBestProps } from './types';

//TODO: add commont element for horizontal list and use it here and in BattleHorizontalList
export const HorizontalBestList: FC<HorizontalBestProps> = ({ children }) => {
  return (
    <HorizontalListRoot>
      <HorizontalListSwiper
        spaceBetween={16}
        slidesPerView="auto"
        freeMode={{ sticky: true }}
        mousewheel={{ forceToAxis: true }}
        modules={[Mousewheel, FreeMode]}
      >
        {Children.map(children, (child) => (
          <HorizontalListSlide>{child}</HorizontalListSlide>
        ))}
      </HorizontalListSwiper>
    </HorizontalListRoot>
  );
};
