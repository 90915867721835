import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationIconFactory, NotificationMessage } from '../../../factory';

import { NotificationSetWalletProps } from './types';

export const NotificationSetWallet = forwardRef<HTMLDivElement, NotificationSetWalletProps>(
  ({ notification, place }, ref) => {
    const { t } = useTranslation();

    return (
      <Notification
        ref={ref}
        date={notification.date}
        read={notification.read}
        icon={<NotificationIconFactory type="system" />}
        place={place}
      >
        <NotificationMessage>{t('Notifications.youConnectedAWallet')}</NotificationMessage>
      </Notification>
    );
  }
);
