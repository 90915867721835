import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '~shared/lib/hooks';

import { getNftStatusText } from '../../lib';
import { NftCardInfoStatusAuction } from '../NftCardInfoStatusAuction';
import { NftCardInfoStatusFrozen } from '../NftCardInfoStatusFrozen';

import { NftCardInfoStatusText } from './styled';
import { NftCardInfoStatusProps } from './types';

// todo: maybe refactoring
export const NftCardInfoStatus: FC<NftCardInfoStatusProps> = ({ nft }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  if (nft.isFreezed) {
    return <NftCardInfoStatusFrozen frozenUntil={nft.frozenUntil} />;
  }

  if (nft.isOnAuction && nft.relatedAuction) {
    return <NftCardInfoStatusAuction relatedAuction={nft.relatedAuction} />;
  }

  return (
    <NftCardInfoStatusText align={isMobile ? 'center' : 'left'}>
      {t(getNftStatusText(nft))}
    </NftCardInfoStatusText>
  );
};
