// todo: refactoring in same way as Snackbar

import { ReactNode, createContext, useContext, useState } from 'react';

import { TransactionStatusDialog } from '~shared/ui';

type TransactionStatusState = {
  open: boolean;
  description: string;
  openTransactionStatusDialog: (description?: string) => void;
  closeTransactionStatusDialog: VoidFunction;
};

const initialState: TransactionStatusState = {
  open: false,
  description: 'Transaction in progress...',
  openTransactionStatusDialog: () => undefined,
  closeTransactionStatusDialog: () => undefined,
};

const TransactionStatusContext = createContext<TransactionStatusState>(initialState);

type Props = {
  children: ReactNode;
};

export const TransactionStatusProvider = ({ children }: Props) => {
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatusState>(initialState);

  const handleOpen = (description: string = 'Transaction in progress...') => {
    setTransactionStatus((prevValue) => ({ ...prevValue, open: true, description }));
  };

  const handleClose = () => {
    setTransactionStatus((prevValue) => ({ ...prevValue, open: false }));
  };

  const value: TransactionStatusState = {
    ...transactionStatus,
    openTransactionStatusDialog: handleOpen,
    closeTransactionStatusDialog: handleClose,
  };

  return (
    <TransactionStatusContext.Provider value={value}>
      <TransactionStatusDialog
        open={transactionStatus.open}
        message={transactionStatus.description}
        onClose={handleClose}
      />
      {children}
    </TransactionStatusContext.Provider>
  );
};

export const useTransactionStatusDialog = () => useContext(TransactionStatusContext);
