import { styled } from '~shared/lib/styles';

import { Pill, pillClasses } from '../Pill';

export const pillMenuClasses = {
  root: 'PillMenu-root',

  hasLabel: 'PillMenu-hasLabel',
  opened: 'PillMenu-opened',
  dropdownChevron: 'PillMenu-dropdownArrow',
};

interface UIKitPillProps {
  open: boolean;
}

export const UIKitPill = styled(Pill, { name: 'PillMenu' })<UIKitPillProps>(({ theme }) => ({
  paddingLeft: 8,
  paddingRight: 8,
  justifyContent: 'space-between',

  [`&.${pillMenuClasses.hasLabel}`]: {
    paddingLeft: 12,
  },

  [`& .${pillMenuClasses.dropdownChevron}`]: {
    transition: theme.transitions.create('transform'),
  },

  [`&.${pillMenuClasses.opened}`]: {
    [`& .${pillMenuClasses.dropdownChevron}`]: {
      transform: 'rotate(-180deg)',
    },
  },

  [`& .${pillClasses.append}`]: {
    marginLeft: 0,
  },

  [`&.${pillMenuClasses.hasLabel}`]: {
    [`& .${pillClasses.append}`]: {
      marginLeft: 3,
    },
  },
}));
