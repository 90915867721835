import { BoxProps } from '~shared/ui';

export type DateLabelState = 'planned' | 'today' | 'upcoming' | 'ready' | 'live' | 'past';

export type DateLabelVariant = 'live' | 'active' | 'primary';

export type DateLabelSize = 'sm' | 'md' | 'lg';

export interface DateLabelProps extends Omit<DateLabelShellProps, 'children' | 'variant'> {
  date: Date;
  live?: boolean;
  defaultState?: DateLabelState;
}

export interface DateLabelShellProps extends BoxProps {
  variant?: DateLabelVariant;
  size?: DateLabelSize;
}

export interface DateLabelConstructorProps {
  state: DateLabelState;
  date: Date;
  size: DateLabelSize;
}

export enum DateLabelStateMap {
  Planned = 'planned',
  Today = 'today',
  Upcoming = 'upcoming',
  Ready = 'ready',
  Live = 'live',
  Past = 'past',
}
