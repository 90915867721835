import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  price: yup
    .string()
    .required('Price is required')
    .matches(/^(\d+)$/, 'Fractional values are not allowed')
    .matches(/^(?!0)\d+$/, 'Minimum value is 1 MCN')
    .matches(/^\d{1,6}$/, '1 million is maximum possible value'),
});
