import { css, keyframes } from '@mui/system';

import { styled } from '~shared/lib/styles';

interface UIKitCircularProgressRootProps {
  size: number;
}

export const UIKitCircularProgressRoot = styled('span')<UIKitCircularProgressRootProps>(
  ({ size }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '1em',
    height: '1em',
    fontSize: size,
  })
);

export const UIKitCircularProgressSVG = styled('svg')({
  width: '1em',
  height: '1em',
});

const strokeWidth = '0.1145833333em'; // 2.75;

export const UIKitCircularProgressCircleTrack = styled('circle')(({ theme }) => ({
  cx: '50%',
  cy: '50%',
  r: '0.375em',
  fill: 'none',
  strokeWidth,
  stroke: theme.palette.grey.A10,
}));

const circularRotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface UIKitCircularProgressProgressProps {
  progress: number;
}

export const UIKitCircularProgressCircleProgress = styled(
  'circle'
)<UIKitCircularProgressProgressProps>(
  ({ progress, theme }) => {
    return {
      cx: '50%',
      cy: '50%',
      r: '0.375em',
      fill: 'none',
      strokeWidth,
      stroke: theme.palette.primary.light,

      strokeDasharray: 'calc(0.375em * 2 * 3.14)',
      strokeDashoffset: `calc((0.375em * 2 * 3.14) - (${progress} * (0.375em * 2 * 3.14)) / 100)`,
      strokeLinecap: 'round',
      transformOrigin: 'center center',
    };
  },
  css`
    animation: ${circularRotateKeyframe} 1s linear infinite;
  `
);
