import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentPlaceholder } from '~shared/ui';

import { BattleSeparatedHorizontalList, useBetList } from '~entities/battle';
import { useProfileBattlesQuery } from '~entities/profile';

import { ViewerBattlesListProps } from './types';

export const ViewerBattlesList: FC<ViewerBattlesListProps> = ({ sport, league, divider }) => {
  const profileBattlesQuery = useProfileBattlesQuery({ sport, league });
  const { t } = useTranslation();
  const { passedList, currentList } = useBetList({ list: profileBattlesQuery.list });

  if (profileBattlesQuery.isEmptyList) {
    return (
      <ContentPlaceholder
        heading={t('Other.noBattles')}
        message={sport ? `${t('Other.noBattlesThisSection')}` : `${t('Other.noBattlesThisCard')}`}
      />
    );
  }

  return (
    <BattleSeparatedHorizontalList
      passedList={passedList}
      currentList={currentList}
      loading={profileBattlesQuery.isLoading}
      divider={divider}
    />
  );
};
