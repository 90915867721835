import { useCallback } from 'react';

import {
  getDefaultAvatars,
  getSignOut,
  getViewerProfile,
  postSignIn,
  postUpdatePassword,
  postViewerAvatar,
  putViewerAvatar,
} from '~shared/api';
import { useDispatch } from '~shared/lib/hooks';

import { transformAvatarRelativePathToViewerAvatar } from '~entities/viewer';
import { useDisconnect } from '~entities/wallet';

import { useViewerSelector } from './selectors';
import { viewerActions, viewerInitialState } from './slice';

export const useViewerModel = () => {
  const dispatch = useDispatch();
  const { ...state } = useViewerSelector();

  const disconnect = useDisconnect();

  const checkSessionToken = useCallback(() => {
    return postSignIn()
      .then(({ email, nickname, wallet, verified, id, onboarding }) => {
        dispatch(
          viewerActions.updateData({
            authorized: true,
            sessionTokenChecked: true,
            nickname,
            email,
            wallet,
            verified,
            onboarding,
            id,
          })
        );

        return { authorized: true, verified };
      })
      .catch(() => {
        dispatch(viewerActions.updateData({ authorized: false, sessionTokenChecked: true }));

        return { authorized: false, verified: null };
      });
  }, [dispatch]);

  const authorize = useCallback(
    (data: { login: string; password: string }) => {
      return postSignIn(data).then(({ verified, email, nickname, wallet, id }) => {
        dispatch(viewerActions.updateData({ email, nickname, authorized: true, wallet, id }));

        return { verified };
      });
    },
    [dispatch]
  );

  const verification = useCallback(() => {
    dispatch(
      viewerActions.updateData({
        verified: true,
        authorized: true,
        sessionTokenChecked: true,
        registered: true,
      })
    );
  }, [dispatch]);

  const updatePassword = useCallback((data: Parameters<typeof postUpdatePassword>[0]) => {
    return postUpdatePassword(data);
  }, []);

  const signOut = useCallback(() => {
    getSignOut().then(() => {
      dispatch(
        viewerActions.updateData({
          ...viewerInitialState,
          sessionTokenChecked: true,
        })
      );

      disconnect();
    });
  }, [dispatch, disconnect]);

  const fetchProfile = useCallback(() => {
    return getViewerProfile().then((data) => {
      dispatch(
        viewerActions.updateData({
          email: data.email,
          nickname: data.nickname,
          avatar: data.avatar ? transformAvatarRelativePathToViewerAvatar(data.avatar) : null,
          customAvatar: data.customAvatar
            ? transformAvatarRelativePathToViewerAvatar(data.customAvatar)
            : null,
        })
      );
    });
  }, [dispatch]);

  const fetchDefaultAvatars = useCallback(() => {
    return getDefaultAvatars().then((data) => {
      dispatch(
        viewerActions.updateData({
          defaultAvatars: data.map((avatar) => transformAvatarRelativePathToViewerAvatar(avatar)),
        })
      );
    });
  }, [dispatch]);

  const updateAvatar = useCallback((data: Parameters<typeof putViewerAvatar>[0]) => {
    return putViewerAvatar(data);
  }, []);

  const postCustomAvatar = useCallback(
    (avatar: Blob, onUploadProgress?: (progress: number) => void) => {
      const formData = new FormData();

      formData.append('avatar', new File([avatar], 'avatar.jpeg', { type: 'image/jpeg' }));

      return postViewerAvatar(formData, (event) => {
        if (onUploadProgress) {
          onUploadProgress((event.progress ?? 0) * 100);
        }
      });
    },
    []
  );

  const openSignOut = useCallback(() => {
    dispatch(viewerActions.openSignOutDialog());
  }, [dispatch]);

  const closeSignOut = useCallback(() => {
    dispatch(viewerActions.closeSignOutDialog());
  }, [dispatch]);

  return {
    authorize,
    signOut,
    checkSessionToken,
    verification,

    fetchProfile,
    fetchDefaultAvatars,
    updatePassword,
    updateAvatar,
    postCustomAvatar,

    openSignOut,
    closeSignOut,

    ...state,
  };
};
