import { ApiCommonLeague, ApiCommonSport } from '~shared/api';

import { NotificationsService } from './service';

/**
 * Event types
 */
export enum NotificationServerEventType {
  /** User events */
  SetWalletNotification = 'set_wallet_notification',
  CardAdded = 'card_added',
  CardLivesChanged = 'card_lives_changed',
  PutCardAuction = 'put_card_auction',
  EventSoon = 'event_soon',
  EventLive = 'event_live',

  /** System events */
  NotificationsHistoryResponse = 'notifications_history_response',
}

export enum NotificationClientEventType {
  SetUser = 'set_user',
  ConfirmNotification = 'confirm_notification',
  NotificationsHistory = 'notifications_history',
}

/**
 * Slice
 */
export interface NotificationsState {
  service: NotificationsService | null;
  notifications: NotificationEvent[];
}

/**
 * Notifications
 */
export interface NotificationRawEvent {
  type: NotificationServerEventType;
  notificationId?: number;
  creationDate?: number;
  payload: Object | string;
}

export interface NotificationRawHistoryEvent extends NotificationRawEvent {
  status: 1 | 2;
}

export interface NotificationBaseEvent<Payload = {}, Type = NotificationServerEventType> {
  id: number;
  type: Type;
  payload: Payload;
  date: Date;
  read: boolean;
}

export type NotificationEvent =
  // user events
  | NotificationEventCardAdded
  | NotificationEventCardLivesChanged
  | NotificationEventSetWallet
  | NotificationEventPutCardAuction
  | NotificationEventEventSoon
  | NotificationEventEventLive
  // system events
  | NotificationEventHistoryResponse;

/** User Events */
export type NotificationEventCardAdded = NotificationBaseEvent<
  {
    tokenId: number;
  },
  NotificationServerEventType.CardAdded
>;

export type NotificationEventCardLivesChanged = NotificationBaseEvent<
  {
    newLives: number;
    oldLives: number;
    tokenId: number;
  },
  NotificationServerEventType.CardLivesChanged
>;

export type NotificationEventSetWallet = NotificationBaseEvent<
  {
    message: string;
  },
  NotificationServerEventType.SetWalletNotification
>;

export type NotificationEventPutCardAuction = NotificationBaseEvent<
  unknown,
  NotificationServerEventType.PutCardAuction
>;

export type NotificationEventEventSoon = NotificationBaseEvent<
  {
    homeTeam: string;
    awayTeam: string;
    sport: ApiCommonSport;
    league: ApiCommonLeague;
  },
  NotificationServerEventType.EventSoon
>;

export type NotificationEventEventLive = NotificationBaseEvent<
  {
    homeTeam: string;
    awayTeam: string;
    sport: ApiCommonSport;
    league: ApiCommonLeague;
  },
  NotificationServerEventType.EventLive
>;

/** System events */
export type NotificationEventHistoryResponse = NotificationBaseEvent<
  { History: NotificationRawHistoryEvent[] | null },
  NotificationServerEventType.NotificationsHistoryResponse
>;
