import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useLeaderboardByWalletQuery } from '~entities/ranking/model';
import { useMediaQuery } from '~shared/lib/hooks';

import { Avatar, Icon, Stack, Typography } from '~shared/ui';

import { IconContainer, Root } from './styled';
import { DashboardRankingProps } from './types';

export const DashboardRanking: FC<DashboardRankingProps> = ({ wallet }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('sm');

  const { data } = useLeaderboardByWalletQuery(wallet);

  return (
    <Root>
      <Stack direction="row" spacing={12 / 8} alignItems="center">
        <IconContainer>
          <Icon name="cup" size={20} />
        </IconContainer>
        <Typography variant="h2"> {t('HeaderTabs.ranking')}</Typography>
      </Stack>

      <Stack spacing={10 / 8}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isMobile ? 'start' : 'center'}
          spacing={10 / 8}
        >
          <Typography color="secondary" fontSize="13px" lineHeight="14px">
            {t('Other.youPlace')}
          </Typography>
          <Typography variant="h2">{data?.ranking}</Typography>
        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isMobile ? 'start' : 'center'}
          spacing={10 / 8}
        >
          <Typography color="secondary" fontSize="13px" lineHeight="14px">
            {t('Other.winner')}
          </Typography>
          <Stack direction="row" spacing={4 / 8} alignItems="center">
            <Stack direction="row" spacing={8 / 8} alignItems="center">
              <Typography variant="h2">{data?.nickname}</Typography>
              <Avatar size={24} src={data?.avatar} />
            </Stack>
            <Icon size={24} name="crown" />
          </Stack>
        </Stack>
      </Stack>
    </Root>
  );
};
