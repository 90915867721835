import { FC } from 'react';

import { Icon } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { NftCard, NftDragItemType, StaticNftCard } from '~entities/nft';
import { useEventModel } from '~entities/event';

import { useEventDrag } from '../../../../../model';
import { EventCardsHorizontalList } from '../../../EventCardsHorizontalList';

import { Root, StyledDragCell } from './styled';
import { EventMultipleCardsProps } from './types';

export const EventMultipleCards: FC<EventMultipleCardsProps> = () => {
  const { isMobile } = useMediaQuery();

  const { choice, cards, additionalCards, isPassedEvent, onAddCards } = useEventModel();
  const multipleCell = useEventDrag({ result: choice });

  const allCards = [...cards, ...additionalCards];

  const handleAddCards = () => {
    if (choice) {
      onAddCards(choice, isMobile)();
    }
  };

  return (
    <Root ref={multipleCell.dropRef}>
      <EventCardsHorizontalList centeredSlides={isPassedEvent}>
        {isPassedEvent
          ? allCards.map((card) => (
              <StaticNftCard
                key={card.token_id}
                tokenId={card.token_id}
                height={isMobile ? 76 : 106}
                width="max-content"
              />
            ))
          : [
              <StyledDragCell
                onClick={handleAddCards}
                isHighlighted={multipleCell.isCellHighlighted}
                key="add-cards"
              >
                <Icon opacity={0.75} name="plus" />
              </StyledDragCell>,

              ...allCards.map((card) => (
                <NftCard
                  size={isMobile ? 'sm' : 'md'}
                  key={card.token_id}
                  dragKey={NftDragItemType.Leave}
                  nft={card}
                />
              )),
            ]}
      </EventCardsHorizontalList>
    </Root>
  );
};
