import { isAfter, isBefore } from 'date-fns';

import { TournamentBadgeType } from './types';

export const getBadgeType = (
  startDate: Date,
  endDate: Date
): Exclude<TournamentBadgeType, 'date'> => {
  const isActive = isAfter(new Date(), startDate) && isBefore(new Date(), endDate);
  const isPast = isAfter(new Date(), endDate);

  if (isPast) {
    return 'end';
  }

  if (isActive) {
    return 'active';
  }

  return 'soon';
};
