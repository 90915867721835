import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgressView, ContentPlaceholder, Heading, Stack } from '~shared/ui';
import { ApiGetBetsBetMappedData } from '~shared/api';
import { useMediaQuery } from '~shared/lib/hooks';

import { useEventModel } from '~entities/event';
import { BattleBet } from '~entities/battle';

import { EventsContainer } from './styled';

import { NftCardDetailedInfoBattlesProps } from './types';

export const NftCardDetailedInfoBattles: FC<NftCardDetailedInfoBattlesProps> = ({
  nftBetsQuery,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const { passedList, currentList, isLoading, isEmptyList } = nftBetsQuery;
  const { openBetEvent } = useEventModel();

  const hasCurrentEvents = currentList && currentList.length > 0;
  const hasPastEvents = passedList && passedList.length > 0;

  const handleOpenEvent = useCallback(
    (bet: ApiGetBetsBetMappedData) => () => {
      openBetEvent(bet);
    },
    [openBetEvent]
  );

  const renderBattle = (bet: ApiGetBetsBetMappedData) => (
    <BattleBet
      key={bet.eventId}
      {...bet}
      size="sm"
      variant={isMobile ? 'compact' : 'line'}
      onClick={handleOpenEvent(bet)}
    />
  );

  if (isEmptyList) {
    return (
      <ContentPlaceholder
        heading={`${t('Other.noBattles')}`}
        message={`${t('Other.noBattlesThisCard')}`}
        mobile
      />
    );
  }

  if (isLoading) {
    return <CircularProgressView />;
  }

  return (
    <EventsContainer>
      <Stack spacing={10 / 8}>
        {hasCurrentEvents && (
          <Heading variant="h1" fontSize={18} lineHeight={24 / 18} pb={12 / 8}>
            {t('Profile.futureAndLive')}
          </Heading>
        )}

        {currentList?.map(renderBattle)}

        {hasPastEvents && (
          <Heading variant="h1" fontSize={18} lineHeight={24 / 18} py={12 / 8}>
            {t('Profile.past')}
          </Heading>
        )}

        {passedList?.map(renderBattle)}
      </Stack>
    </EventsContainer>
  );
};
