import { apiInstance } from '../apiInstance';

import { AuthRoutes } from './routes';

import {
  ApiGetSignOut,
  ApiPostCheckEmail,
  ApiPostResendAuthCode,
  ApiPostSignIn,
  ApiPostSignUp,
  ApiPostSignUpVerification,
} from './types';

export const postSignUp: ApiPostSignUp = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.signUp(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSignIn: ApiPostSignIn = (data?) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.signIn(), data, { withCredentials: true })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error.response.data));
  });
};

export const getSignOut: ApiGetSignOut = () => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(AuthRoutes.signOut())
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error.response.data));
  });
};

export const postResendAuthCode: ApiPostResendAuthCode = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.resendAuthCode(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postSignUpVerification: ApiPostSignUpVerification = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.signUpVerification(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

export const postCheckEmail: ApiPostCheckEmail = (data) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(AuthRoutes.checkEmail(), data)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
