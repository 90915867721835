import { FC, useEffect, useRef } from 'react';

import { useSwitcher } from '~shared/lib/hooks';

import {
  EventDialogTeamsNameDefaultName,
  EventDialogTeamsNameRoot,
  EventDialogTeamsNameShortName,
} from './styled';

interface EventDialogTeamsNameProps {
  name: string;
  shortName: string;
}

export const EventDialogTeamsName: FC<EventDialogTeamsNameProps> = ({ name, shortName }) => {
  const ref = useRef<HTMLElement>(null);

  const isOverflowing = useSwitcher(false);

  useEffect(() => {
    if (ref.current) {
      if (isOverflown(ref.current)) {
        isOverflowing.switchOn();
      } else {
        isOverflowing.switchOff();
      }
    }
  });

  return (
    <EventDialogTeamsNameRoot>
      <EventDialogTeamsNameDefaultName ref={ref} isOverflowing={isOverflowing.value}>
        {name}
      </EventDialogTeamsNameDefaultName>

      <EventDialogTeamsNameShortName isOverflowing={isOverflowing.value}>
        {shortName}
      </EventDialogTeamsNameShortName>
    </EventDialogTeamsNameRoot>
  );
};

const isOverflown = (element: Element) => {
  return element.scrollWidth > element.clientWidth;
};
