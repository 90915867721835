import { FC } from 'react';

import { useMediaQuery } from '~shared/lib/hooks';

import {
  Button,
  Dialog,
  DialogTitle,
  DragCell,
  Icon,
  Select,
  SelectOption,
  Stack,
} from '~shared/ui';

import { ActionContainer, DialogCaption, DragCellContainer } from './styled';
import { JoinTournamentDialogProps } from './types';

export const JoinTournamentDialog: FC<JoinTournamentDialogProps> = ({ cardsCount, ...props }) => {
  const { isMobile } = useMediaQuery();

  return (
    <Dialog {...props} width={600}>
      <DialogTitle>Join the tournament</DialogTitle>

      <Stack spacing={(isMobile ? 24 : 32) / 8} alignItems="center">
        <DialogCaption>Add 6 cards to this tournament</DialogCaption>

        <DragCellContainer count={cardsCount}>
          {Array.from(Array(cardsCount)).map((element, index) => (
            <DragCell key={index}>
              <Icon opacity={0.75} name="plus" />
            </DragCell>
          ))}
        </DragCellContainer>

        <DialogCaption>You can add rare or common cards to this tournament. </DialogCaption>

        <ActionContainer>
          <Select defaultValue={options[0].value}>
            {options.map((option) => (
              <SelectOption key={option.value} value={option.value}>
                {option.label}
              </SelectOption>
            ))}
          </Select>

          <Button>Join for 20 matic</Button>
        </ActionContainer>
      </Stack>
    </Dialog>
  );
};

const options = [
  {
    value: 'matic',
    label: 'Matic',
  },
  {
    value: 'usd',
    label: 'USD',
  },
];
