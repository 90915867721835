import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConfirmationDialog } from '~shared/ui';
import { useSwitcher } from '~shared/lib/hooks';

import { DeleteAccountButtonProps } from './types';

export const DeleteAccountButton: FC<DeleteAccountButtonProps> = ({ ...props }) => {
  const { t } = useTranslation();

  const deleteAccountDialog = useSwitcher(false);

  return (
    <Fragment>
      <Button
        {...props}
        variant="outlined"
        color="error"
        onClick={deleteAccountDialog.switchOn}
        disabled
      >
        {t('Account.delete')}
      </Button>

      <ConfirmationDialog
        open={deleteAccountDialog.value}
        title={t('Account.delete')}
        targetButton={<Button color="error">Delete</Button>}
        onClose={deleteAccountDialog.switchOff}
      >
        Do you really want to delete account?
      </ConfirmationDialog>
    </Fragment>
  );
};
