import { getAvatarAbsoluteUri } from '~shared/lib/utils';

import {
  ApiGetLeaderboardByWalletMappedData,
  ApiGetLeaderboardByWalletRawData,
  ApiGetLeaderboardMappedData,
  ApiGetLeaderboardRawData,
} from './types';

export const mapGetLeaderboardResponse = (
  data: Array<ApiGetLeaderboardRawData>
): Array<ApiGetLeaderboardMappedData> => {
  return data
    .filter((leaderboard) => leaderboard.nickname)
    .map((leaderboard, index) => {
      return {
        ...leaderboard,
        avatar: leaderboard.avatar ? getAvatarAbsoluteUri(leaderboard.avatar) : null,
        place: index + 1,
      };
    });
};

export const mapGetLeaderboardByWalletResponse = (
  data: ApiGetLeaderboardByWalletRawData
): ApiGetLeaderboardByWalletMappedData => {
  return {
    ...data,
    avatar: data.avatar ? getAvatarAbsoluteUri(data.avatar) : null,
  };
};
