import { FC, Fragment, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useLoaded, useSwitcher } from '~shared/lib/hooks';
import { mergeRefs } from '~shared/lib/utils';
import { Popper } from '~shared/ui';

import { NftDragItemType, useNftCardModel, useNftPreviewInfo } from '../../model';
import { getNftUri, isNftAvailableForMerge } from '../../lib';

import { NftCardInfo } from '../NftCardInfo';

import { NftNew } from './NftNew';
import { NftMinting } from './NftMinting';
import { NftCardStates } from './NftCardStates';

import { NftCardImage, NftCardRoot, StyledCheckbox } from './styled';
import { NftCardProps } from './types';

export const NftCard: FC<NftCardProps> = ({
  nft,

  highlightVariant = 'small',
  size = 'md',

  areDetailsShown = false,
  isSelectionMode = false,
  isHighlighted = false,
  hideStates = false,
  isNew = false,

  dragKey = NftDragItemType.Place,
  checked,
  ...otherProps
}) => {
  const isNftHighlighted = useSwitcher(isHighlighted);
  const isMinting = useSwitcher(false);
  const ref = useRef();

  const nftUri = getNftUri(nft.token_id);
  const nftImageStatus = useLoaded({ src: nftUri });
  const isNftImageLoaded = nftImageStatus === 'loaded';

  const { closePreview } = useNftPreviewInfo();
  const { setDropZoneOpen, mergeDialogOpen } = useNftCardModel();

  const [{ isDragging, canDrag }, dragRef, preview] = useDrag(
    () => ({
      type: dragKey,
      item: { tokenId: nft.token_id, rarity: nft.rarity, winStreak: nft.winStreak },
      options: { dropEffect: 'link' },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag(),
      }),
      canDrag: () => {
        const isBlockedByMergeDialog = mergeDialogOpen && !isNftAvailableForMerge(nft).isAvailable;

        const isNftBlockedForAnyActions =
          nft.isBlockedForEvent ||
          nft.isOnEvent ||
          nft.isFreezed ||
          nft.isOnAuction ||
          nft.isFreezed ||
          isMinting.value ||
          isBlockedByMergeDialog;

        return !isNftBlockedForAnyActions;
      },
    }),
    [isMinting.value, nft]
  );

  useEffect(() => {
    setDropZoneOpen(isDragging);
  }, [isDragging, setDropZoneOpen]);

  useEffect(() => {
    isNftHighlighted.toggle(areDetailsShown);
  }, [areDetailsShown, isNftHighlighted]);

  useEffect(() => {
    switch (nftImageStatus) {
      case 'loaded':
        isMinting.switchOff();
        break;
      case 'error':
        isMinting.switchOn();
        break;
    }
  }, [isMinting, nftImageStatus]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const selectionAvailable = isSelectionMode && canDrag;
  const shouldShowStates = !hideStates;

  return (
    <NftCardRoot
      ref={mergeRefs([dragRef, ref])}
      $areDetailsShown={areDetailsShown}
      $isHighlighted={isNftHighlighted.value}
      $highlightVariant={highlightVariant}
      $isDragging={isDragging}
      $size={size}
      {...otherProps}
      data-nft-id={nft.token_id}
    >
      {isMinting.value ? (
        <Fragment>
          <NftCardImage src={PLACEHOLDER_URL} alt="NFT Minting" $size={size} />
          <NftMinting />
        </Fragment>
      ) : (
        <NftCardImage src={isNftImageLoaded ? nftUri : PLACEHOLDER_URL} alt="NFT" $size={size} />
      )}

      {isNftImageLoaded ? (
        <Fragment>
          {selectionAvailable && <StyledCheckbox checked={checked} />}
          {shouldShowStates && <NftCardStates nft={nft} size={size} />}
          {isNew && <NftNew />}

          <Popper
            open={areDetailsShown}
            onClose={closePreview}
            anchorEl={ref.current}
            placement="left-start"
            content={<NftCardInfo nft={nft} />}
          />
        </Fragment>
      ) : null}
    </NftCardRoot>
  );
};

const PLACEHOLDER_URL = process.env.PUBLIC_URL + '/assets/nfts/nft-placeholder.png';
