import { forwardRef, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ContentPlaceholder } from '~shared/ui';

import { useNotificationsModel } from '~entities/notifications';

import { NotificationsList, NotificationsListDelimiter } from '../../factory';
import { NotificationConstructor } from '../NotificationConstructor';

import { NotificationCenterTop } from './NotificationCenterTop';

import { NotificationCenterRoot } from './styled';
import { NotificationsCenterProps } from './types';

export const NotificationsCenter = forwardRef<HTMLDivElement, NotificationsCenterProps>(
  ({ backNavigation, ...props }, ref) => {
    const { t } = useTranslation();

    const [notificationsToShow, setNotificationsToShow] = useState(20);

    const {
      newNotifications,
      readNotifications,

      newNotificationsCount,
      readNotificationsCount,
      notificationsCount,
    } = useNotificationsModel();

    const hasNewNotifications = Boolean(newNotificationsCount);
    const hasReadNotifications = Boolean(readNotificationsCount);
    const hasNotifications = Boolean(notificationsCount);

    const hasRestNotifications = readNotifications.length > notificationsToShow;

    const handleShowRestNotifications = () => {
      setNotificationsToShow((notificationsToShow) => notificationsToShow + 20);
    };

    const notificationLists = {
      new: hasNewNotifications ? (
        <Fragment>
          <NotificationsListDelimiter active>New messages</NotificationsListDelimiter>

          <NotificationsList>
            {newNotifications.map((notification) => (
              <NotificationConstructor
                key={notification.id}
                notification={notification}
                shouldRead
              />
            ))}
          </NotificationsList>
        </Fragment>
      ) : null,

      read: hasReadNotifications ? (
        <Fragment>
          {hasNewNotifications && <NotificationsListDelimiter>Other</NotificationsListDelimiter>}

          <NotificationsList>
            {readNotifications.slice(0, notificationsToShow).map((notification) => (
              <NotificationConstructor key={notification.id} notification={notification} />
            ))}
          </NotificationsList>
        </Fragment>
      ) : null,
    };

    return (
      <NotificationCenterRoot ref={ref}>
        <NotificationCenterTop backNavigation={backNavigation} />

        {hasNotifications ? (
          <Fragment>
            {notificationLists.new}
            {notificationLists.read}

            {hasRestNotifications ? (
              <Button mt={1} size="sm" variant="outlined" onClick={handleShowRestNotifications}>
                {t('Notifications.showMore')}
              </Button>
            ) : null}
          </Fragment>
        ) : (
          <ContentPlaceholder
            heading={t('Notifications.nothingHere')}
            message={t('Notifications.noNotificationsYet')}
          />
        )}
      </NotificationCenterRoot>
    );
  }
);
