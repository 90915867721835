import { NftTabRarity } from '../model';

export const CARDS_TABS_MAP: Record<Partial<NftTabRarity>, string> = {
  [NftTabRarity.All]: 'All',
  [NftTabRarity.Common]: 'Common',
  [NftTabRarity.Rare]: 'Rare',
  [NftTabRarity.Epic]: 'Epic',
  [NftTabRarity.Legendary]: 'Legendary',
  [NftTabRarity.Mythic]: 'Mythic',
};

export const CARDS_TABS: Array<{ value: NftTabRarity; label: string }> = [
  NftTabRarity.All,
  NftTabRarity.Common,
  NftTabRarity.Rare,
  NftTabRarity.Epic,
  NftTabRarity.Legendary,
].map((value) => ({
  value,
  label: CARDS_TABS_MAP[value],
}));
