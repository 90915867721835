import { styled } from '~shared/lib/styles';
import { candy } from '~shared/lib/assets';
import { LinearProgressProgressColor } from '~shared/ui';

import { Box } from '../../layout';

export const linearProgressClasses = {
  root: 'LinearProgress-root',
  progress: 'LinearProgress-progress',
};

interface UIKitLinearProgressRootProps {
  height?: number;
}

export const UIKitLinearProgressRoot = styled(Box, {
  name: 'LinearProgress',
  shouldForwardProp: (prop: keyof UIKitLinearProgressRootProps) => {
    switch (prop) {
      case 'height':
        return false;

      default:
        return true;
    }
  },
})<UIKitLinearProgressRootProps>(({ theme, height }) => ({
  height: height ?? 8,
  width: '100%',
  display: 'block',
  background: theme.palette.grey.A20,
  overflow: 'hidden',
  borderRadius: '100px',
  position: 'relative',
}));

interface UIKitLinearProgressProps {
  reverse: boolean;
  progressColor: LinearProgressProgressColor;
}

export const UIKitLinearProgress = styled('span', {
  name: 'LinearProgress',
  slot: 'progress',
  shouldForwardProp: (propName: keyof UIKitLinearProgressProps) => {
    switch (propName) {
      case 'reverse':
      case 'progressColor':
        return false;
      default:
        return true;
    }
  },
})<UIKitLinearProgressProps>(({ progressColor, reverse, theme }) => {
  const MAP_PROGRESS_COLOR: Record<LinearProgressProgressColor, string> = {
    candy: candy,
    error: theme.palette.error.main,
    primary: theme.palette.primary.main,
  };

  return {
    width: '100%',
    height: '100%',
    position: 'absolute',
    inset: 0,

    background: MAP_PROGRESS_COLOR[progressColor],
    backgroundSize: 'contain',

    borderRadius: '100px',
    transformOrigin: reverse ? 'right' : 'left',

    transition: theme.transitions.create(['transform', 'background-position-x'], {
      duration: 400,
      easing: 'linear',
    }),
  };
});
