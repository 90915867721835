import React, { PropsWithChildren } from 'react';

import { Box, Button, Link, Logo, Stack, Typography } from '~shared/ui';
import { routes } from '~shared/config';

export class ErrorBoundary extends React.Component<PropsWithChildren, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };

    this.resolveError = this.resolveError.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  resolveError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack
          spacing={24 / 8}
          height="100vh"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Logo size={48} />

          <Box>
            <Typography variant="h1" align="center" pt={2}>
              Oops!
            </Typography>
            <Typography variant="h3" fontWeight={400} mt={1} color="secondary">
              Something went wrong
            </Typography>
          </Box>

          <Link to={routes.index} underline={false}>
            <Button onClick={this.resolveError}>Return to Home</Button>
          </Link>
        </Stack>
      );
    }

    return this.props.children;
  }
}
