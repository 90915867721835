import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BattleBadgeProps } from './types';
import { BattleBadgeRoot } from './styled';

export const BattleBadge: FC<BattleBadgeProps> = ({ score, win, draw, className }) => {
  const { t } = useTranslation();

  const content = (() => {
    if (draw && score) {
      return `${t('Other.draw')} ${score[0]}:${score[1]}`;
    } else if (draw) {
      return `${t('Other.draw')}`;
    } else if (win) {
      return `${t('BattlePopUp.onlyWin')}`;
    }
  })();

  return <BattleBadgeRoot className={className}>{content}</BattleBadgeRoot>;
};
