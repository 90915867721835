import { Navigate, Outlet } from 'react-router';

import { isProduction, routes } from '~shared/config';

export const TestRoute = () => {
  if (isProduction()) {
    return <Navigate to={routes.index} />;
  }

  return <Outlet />;
};
