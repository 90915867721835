import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Switch, Tooltip, Typography } from '~shared/ui';
import { useMediaQuery } from '~shared/lib/hooks';

import { isAbleToTakeCards, useEventModel } from '~entities/event';

import { EventTimer } from '../../EventTimer';

import {
  ButtonsContainer,
  MakeCallContainer,
  MakeCallFormControlLabel,
  MakeCallQuestionMark,
  PotentialRewardAmount,
  RewardBriefContainer,
  Root,
  YouCanWin,
} from './styled';

import { EventDialogActionsProps } from './types';

export const EventDialogActions: FC<EventDialogActionsProps> = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const {
    isViewMode,
    isNoCardsSelected,
    isPassedEvent,
    isCall,
    isMakeCall,
    isCallAvailable,
    isConnected,

    potentialRewardAmount,
    cards,
    event,
    additionalCards,

    bannerDetailedDialogInfo,

    onClearAll,
    onVote,
    onRemoveAllCardsFromEvent,
    onToggleMakeCall,
  } = useEventModel();

  const isTakeCardsAvailable = !isCall && isAbleToTakeCards(event!.date);
  const rewardText = `${potentialRewardAmount} MCN ${isMakeCall || isCall ? '+ Card' : ''}`;

  const isVoteDisabled = isNoCardsSelected && isConnected;

  if ((isCall && isViewMode) || isPassedEvent || !event) {
    return null;
  }

  if (isViewMode) {
    if (additionalCards.length > 0) {
      return (
        <Root>
          <Stack>{isViewMode && !isCall && !isMakeCall && <EventTimer date={event.date!} />}</Stack>

          <span />

          <ButtonsContainer>
            <Button size={isMobile ? 'md' : 'lg'} variant="outlined" onClick={onClearAll}>
              {t('BattlePopUp.clear')}
            </Button>
            <Button size={isMobile ? 'md' : 'lg'} onClick={onVote}>
              {t('BattlePopUp.vote')}
            </Button>
          </ButtonsContainer>
        </Root>
      );
    }

    return (
      <Root>
        <Stack>{isViewMode && !isCall && !isMakeCall && <EventTimer date={event.date!} />}</Stack>

        {isMobile ? null : <span />}

        {isTakeCardsAvailable && (
          <Button
            size={isMobile ? 'md' : 'lg'}
            variant="outlined"
            onClick={onRemoveAllCardsFromEvent}
          >
            Take all cards
          </Button>
        )}
      </Root>
    );
  }

  if (isMobile) {
    return (
      <Root>
        {isCallAvailable && !isCall ? (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MakeCallContainer>
              <MakeCallFormControlLabel
                control={<Switch />}
                checked={isMakeCall}
                onChange={onToggleMakeCall}
                disabled={!isCallAvailable}
                label={isMobile ? 'Make a call' : t('BattlePopUp.call')}
              />

              <Tooltip
                title={
                  <Typography align="center" lineHeight={1.33} fontSize={12}>
                    {t('Other.ifYouWant')}
                  </Typography>
                }
              >
                <MakeCallQuestionMark
                  onClick={bannerDetailedDialogInfo.switchOn}
                  name="question"
                  size={20}
                />
              </Tooltip>
            </MakeCallContainer>

            <PotentialRewardAmount>Win {rewardText}</PotentialRewardAmount>
          </Stack>
        ) : (
          <PotentialRewardAmount align="center">Win {rewardText}</PotentialRewardAmount>
        )}

        <ButtonsContainer>
          <Button size="md" variant="outlined" onClick={onClearAll} disabled={!cards.length}>
            {t('BattlePopUp.clear')}
          </Button>

          <Button size="md" onClick={onVote} disabled={isVoteDisabled}>
            {t('BattlePopUp.vote')}
          </Button>
        </ButtonsContainer>
      </Root>
    );
  }

  return (
    <Root>
      {isCallAvailable && !isCall ? (
        <MakeCallContainer>
          <MakeCallFormControlLabel
            control={<Switch />}
            checked={isMakeCall}
            onChange={onToggleMakeCall}
            disabled={!isCallAvailable}
            label={isMobile ? 'Make a call' : `${t('BattlePopUp.call')}`}
          />

          <Tooltip
            title={
              <Typography align="center" lineHeight={1.33} fontSize={12}>
                {t('Other.ifYouWant')}
              </Typography>
            }
          >
            <MakeCallQuestionMark onClick={bannerDetailedDialogInfo.switchOn} name="question" />
          </Tooltip>
        </MakeCallContainer>
      ) : (
        <span />
      )}

      <RewardBriefContainer>
        <Fragment>
          <YouCanWin color="secondary">{t('BattlePopUp.win')}</YouCanWin>
          <PotentialRewardAmount>{rewardText}</PotentialRewardAmount>
        </Fragment>
      </RewardBriefContainer>

      <ButtonsContainer>
        <Button variant="outlined" onClick={onClearAll} disabled={!cards.length}>
          {t('BattlePopUp.clear')}
        </Button>

        <Button onClick={onVote} disabled={isVoteDisabled}>
          {t('BattlePopUp.vote')}
        </Button>
      </ButtonsContainer>
    </Root>
  );
};
