import { styled } from '~shared/lib/styles';

export const UIKitDemoIconGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: 24,
  color: theme.palette.text.primary,
}));

export const UIKitDemoIconPaper = styled('div')(({ theme }) => ({
  fontSize: 14,
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  background: theme.palette.paper.dark,
  border: '1px solid #252424',
  borderRadius: '12px',
  boxShadow:
    '0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)',
}));
