import { FC } from 'react';

import { formatLocaleDate } from '../../lib';

import {
  CardDatesBadge,
  CardStatusActiveBadge,
  CardStatusPassedBadge,
  CardStatusSoonBadge,
} from './styled';

import { TournamentBadgeProps } from './types';

export const TournamentBadge: FC<TournamentBadgeProps> = ({ type, startDate, endDate }) => {
  switch (type) {
    case 'active':
      return <CardStatusActiveBadge>Active</CardStatusActiveBadge>;
    case 'soon':
      return <CardStatusSoonBadge>Soon</CardStatusSoonBadge>;
    case 'end':
      return <CardStatusPassedBadge>Ended</CardStatusPassedBadge>;
    case 'date':
      return (
        <CardDatesBadge>
          {formatLocaleDate(startDate)} — {formatLocaleDate(endDate)}
        </CardDatesBadge>
      );
  }
};
