import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs, TabsList } from '~shared/ui';

import { DeleteAccountButton } from '~features/viewer/delete-account';

import { useMediaQuery } from '~shared/lib/hooks';

import { AccountHeader, Root } from './styled';

import { PersonalData, PrivacyPolicy, Security } from './tabs';
import { ProfileAccountProps } from './types';
import { AccountBrief } from './AccountBrief';

enum ProfileAccountTabs {
  PersonalData,
  Security,
  PrivacyPolicy,
}

export const ProfileAccount: FC<ProfileAccountProps> = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();

  const [tab, setTab] = useState<ProfileAccountTabs>(ProfileAccountTabs.PersonalData);

  const tabs = {
    [ProfileAccountTabs.PersonalData]: <PersonalData />,
    [ProfileAccountTabs.Security]: <Security />,
    [ProfileAccountTabs.PrivacyPolicy]: <PrivacyPolicy />,
  };

  const handleChangeTab = useCallback(
    (_: SyntheticEvent | null, value: any) => {
      setTab(value as ProfileAccountTabs);
    },
    [setTab]
  );

  return (
    <Root>
      <AccountHeader>
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <AccountBrief />
          {!isMobile && <DeleteAccountButton />}
        </Stack>
        <Tabs value={tab} underlined={false} onChange={handleChangeTab}>
          <TabsList>
            <Tab value={ProfileAccountTabs.PersonalData}>{t('Other.personalData')}</Tab>
            <Tab value={ProfileAccountTabs.Security}>{t('Other.security')}</Tab>
            <Tab value={ProfileAccountTabs.PrivacyPolicy}>{t('Other.privacy')}</Tab>
          </TabsList>
        </Tabs>
      </AccountHeader>

      {tabs[tab]}
    </Root>
  );
};
