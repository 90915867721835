import { useCallback } from 'react';

import { Stack, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '~shared/ui';
import { useSearchParamsState } from '~shared/lib/hooks';

enum WalletFilterOptions {
  All = 'all',
  Cards = 'cards',
}

const walletFilterOptions = [
  { label: 'All', value: WalletFilterOptions.All },
  { label: 'Cards', value: WalletFilterOptions.Cards },
];

export const FILTER_PARAM_KEY = 'filter';

export const WalletFilter = () => {
  const [filter, setFilter] = useSearchParamsState(FILTER_PARAM_KEY, WalletFilterOptions.All);

  const handleChange = useCallback<Required<ToggleButtonGroupProps>['onChange']>(
    (event, value) => {
      setFilter(value);
    },
    [setFilter]
  );

  return (
    <Stack spacing={1} direction="row">
      <ToggleButtonGroup value={filter} onChange={handleChange}>
        {walletFilterOptions.map((option) => (
          <ToggleButton value={option.value} key={option.value} disabled>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};
