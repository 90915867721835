import { FC } from 'react';

import { Icon } from '~shared/ui';

import { BattleShimmer } from '../misc';

import {
  BattleHorizontalListContainer,
  BattleHorizontalListLabel,
  BattleHorizontalListNavigation,
  BattleHorizontalListRoot,
} from './styled';
import { BattleHorizontalListProps } from './types';

export const BattleHorizontalList: FC<BattleHorizontalListProps> = ({
  children,
  label,
  loading,
  navigation,
  navigationContent,
}) => {
  if (loading) {
    return (
      <BattleHorizontalListRoot>
        {label && <BattleHorizontalListLabel>&nbsp;</BattleHorizontalListLabel>}

        <BattleHorizontalListContainer>
          <BattleShimmer variant="square" />
          <BattleShimmer variant="square" />
          <BattleShimmer variant="square" />
          <BattleShimmer variant="square" />
          <BattleShimmer variant="square" />
          <BattleShimmer variant="square" />
          <BattleShimmer variant="square" />
        </BattleHorizontalListContainer>
      </BattleHorizontalListRoot>
    );
  }

  if (children.length === 0) {
    return null;
  }

  return (
    <BattleHorizontalListRoot>
      {label && <BattleHorizontalListLabel>{label}</BattleHorizontalListLabel>}

      <BattleHorizontalListContainer>
        {children}

        {navigation && (
          <BattleHorizontalListNavigation to={navigation} underline={false}>
            <Icon name="arrow-right" />
            <div>{navigationContent ?? 'See more'}</div>
          </BattleHorizontalListNavigation>
        )}
      </BattleHorizontalListContainer>
    </BattleHorizontalListRoot>
  );
};
