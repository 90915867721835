import { FC, MouseEvent, forwardRef, useCallback } from 'react';
import { clsx } from 'clsx';

import { useControlled } from '~shared/lib/hooks';

import { UIKitToggleButtonRoot, toggleButtonClasses } from './styled';
import { ToggleButtonProps } from './types';

export const ToggleButton: FC<ToggleButtonProps> = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  (
    { selected, value, disabled, className, defaultSelected = false, onChange, onClick, ...props },
    forwardedRef
  ) => {
    const [controlledSelected, setControlledSelected] = useControlled<boolean>({
      controlled: selected,
      default: defaultSelected,
    });

    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
          onClick(event);
        }

        // isControlled
        if (selected === undefined) {
          setControlledSelected((selected) => {
            const newSelected = !selected;

            if (onChange) {
              onChange(event, newSelected);
            }

            return newSelected;
          });
        } else if (onChange) {
          onChange(event, value);
        }
      },
      [onChange, onClick, selected, setControlledSelected, value]
    );

    return (
      <UIKitToggleButtonRoot
        {...props}
        ref={forwardedRef}
        disabled={disabled}
        onClick={handleClick}
        value={value}
        className={clsx(
          toggleButtonClasses.root,
          {
            [toggleButtonClasses.selected]: selected ?? controlledSelected,
            [toggleButtonClasses.disabled]: disabled,
          },
          className
        )}
      />
    );
  }
);
