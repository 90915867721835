import { FC, Fragment, useEffect, useRef } from 'react';
import { ClickAwayListener } from '@mui/base';
import { useNavigate } from 'react-router';

import { Badge } from '~shared/ui';
import { routes } from '~shared/config';
import { useMediaQuery, useSwitcher } from '~shared/lib/hooks';

import { useNotificationsModel } from '../../../model';
import { NotificationsCenterPopper } from '../../factory';
import { NotificationsCenter } from '../NotificationsCenter';

import { NotificationsControlButton } from './styled';
import { NotificationsControlProps } from './types';

export const NotificationsControl: FC<NotificationsControlProps> = () => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const { newNotificationsCount, service } = useNotificationsModel();

  const ref = useRef<HTMLElement>(null);
  const open = useSwitcher(false);

  useEffect(() => {
    if (!open.value && !isMobile) {
      service?.applyReadStack();
    }
  }, [open.value]);

  useEffect(() => {
    if (isMobile && open.value) {
      open.switchOff();
    }
  }, [isMobile, open.value]);

  const navigateToNotifications = () => {
    navigate(routes.notifications);
  };

  return (
    <Fragment>
      <Badge badgeContent={newNotificationsCount} invisible={newNotificationsCount === 0} ref={ref}>
        <NotificationsControlButton
          name="bell"
          variant="outlined"
          size={24}
          onClick={isMobile ? navigateToNotifications : open.toggle}
          $active={open.value}
        />
      </Badge>

      <NotificationsCenterPopper anchorEl={ref.current} open={open.value}>
        <ClickAwayListener onClickAway={open.switchOff} disableReactTree>
          <NotificationsCenter />
        </ClickAwayListener>
      </NotificationsCenterPopper>
    </Fragment>
  );
};
