import { FC, PropsWithChildren, ReactElement, cloneElement, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Portal } from '@mui/base';
import { useMap } from 'react-use';
import { v4 as uuid } from 'uuid';

import { AlertSnackbar } from '~shared/ui';

import { SnackbarContext } from './Сontext';
import { SnackbarsContainer } from './styled';
import { NotificationElement, OpenSnackbar, SnackbarElement, SnackbarId } from './types';
import { isMessageWithOptions, isNotification } from './utils';

const SNACKBAR_CLOSE_DELAY = 10000;

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [snackbars, { set, reset, remove }] = useMap<
    Record<SnackbarId, SnackbarElement | NotificationElement>
  >({});

  const openSnackbar = useCallback<OpenSnackbar>(
    (data) => {
      const { id = uuid(), type = 'info', autoHideDelay = SNACKBAR_CLOSE_DELAY } = data;

      if (isMessageWithOptions(data)) {
        set(id, { message: data.message, type });
      }

      if (isNotification(data)) {
        set(id, data.element as ReactElement);
      }

      setTimeout(() => {
        remove(id);
      }, autoHideDelay);

      return id;
    },
    [remove, set]
  );

  const closeSnackbar = useCallback(
    (id: SnackbarId) => {
      remove(id);
    },
    [remove]
  );

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar, snackbars, reset }}>
      <Portal>
        <SnackbarsContainer>
          <AnimatePresence>
            {Object.entries(snackbars)
              .reverse()
              .map(([id, data]) => {
                if ('message' in data) {
                  const { type, message } = data;

                  return (
                    <AlertSnackbar key={id} severity={type} onClose={() => closeSnackbar(id)}>
                      {message}
                    </AlertSnackbar>
                  );
                }

                return cloneElement(data, { key: id });
              })}
          </AnimatePresence>
        </SnackbarsContainer>
      </Portal>

      {children}
    </SnackbarContext.Provider>
  );
};
