import { styled } from '~shared/lib/styles';

export const BattleBadgeRoot = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: '6px 4px',
  borderRadius: 4,
  whiteSpace: 'nowrap',

  fontSize: 11,
  fontWeight: 700,
  lineHeight: 9 / 11,
  letterSpacing: -0.338,
}));
