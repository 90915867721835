import { styled } from '~shared/lib/styles';
import { Box, Typography } from '~shared/ui';

export const NftBlockedForEventRoot = styled(Box, { name: 'NftBlockedForEvent--root' })({
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  alignItems: 'center',
  width: '100%',
});

export const NftBlockedForEventText = styled(Typography, { name: 'NftBlockedForEvent--text' })({
  textAlign: 'center',
  lineHeight: '13px',
});
