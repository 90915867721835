import { styled } from '~shared/lib/styles';
import { Box, ButtonIcon } from '~shared/ui';

export const WalletsContainer = styled(Box, {
  name: 'ConnectWalletDialogDesktop',
  slot: 'walletsContainer',
})(({ theme }) => ({
  padding: '10px',
  background: theme.palette.grey.A10,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '24px',
  borderRadius: '8px',
}));

export const StyledButtonIcon = styled(ButtonIcon, {
  name: 'ConnectWalletDialogDesktop',
  slot: 'styledButtonIcon',
})({
  background: 'transparent !important',
  borderRadius: '16px',
});
