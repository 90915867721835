import { FC } from 'react';

import { Icon } from '~shared/ui';

import { EventChoiceDragCell, EventChoiceLabel, IconAndLabelContainer, Root } from './styled';
import { EventChoiceProps } from './types';

export const EventChoice: FC<EventChoiceProps> = ({
  isHighlighted,
  isReturn,
  nft,
  dropRef,
  onAddCards,
  label,
  disabled = false,
  icon,
}) => {
  return (
    <Root>
      <EventChoiceDragCell
        onClick={onAddCards}
        ref={dropRef}
        $isHighlighted={isHighlighted}
        $disabled={disabled}
      >
        {nft}

        {(onAddCards || icon) && (
          <IconAndLabelContainer>
            <Icon size={28} name={icon ?? (disabled ? 'x' : isReturn ? 'back' : 'plus-round')} />

            {label && <EventChoiceLabel>{label}</EventChoiceLabel>}
          </IconAndLabelContainer>
        )}
      </EventChoiceDragCell>
    </Root>
  );
};
