import { styled } from '~shared/lib/styles';

import { StyledTypographyProps } from './types';

export const UIKitTypography = styled('span')<StyledTypographyProps>(
  ({ paragraph, noWrap, align, gutterBottom, theme, variant }) => ({
    ...(variant && theme.typography[variant]),
    ...(align !== 'inherit' && {
      textAlign: align,
    }),
    ...(noWrap && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    ...((paragraph || gutterBottom) && {
      marginBottom: 20,
    }),
  })
);
