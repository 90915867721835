import { ApiCommonSport, BattleResult } from '~shared/api';
// todo: move to shared slice
import { NOT_AVAILABLE_SPORTS_FOR_DRAW_RESULT } from '~features/event-dialog';

export const isEventPassed = (result: BattleResult) =>
  ![BattleResult.InProgress, BattleResult.Cancelled].includes(result);

export const isEventCurrent = (result: BattleResult) =>
  [BattleResult.InProgress, BattleResult.Cancelled].includes(result);

export const isEventNotStarted = (date: Date) => {
  const currentDate = new Date();
  const isEventNotStarted = currentDate < date;

  return isEventNotStarted;
};

export const isDrawSlotAvailable = (sport: ApiCommonSport) => {
  return !NOT_AVAILABLE_SPORTS_FOR_DRAW_RESULT.includes(sport);
};
