import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { yupResolver } from '@hookform/resolvers/yup';

import { useWeb3React } from '@web3-react/core';

import { useDispatch, useSnackbar } from '~shared/lib/hooks';

// todo: fsd
import { AuctionTab } from '~widgets/auction-list';

import { getAuctionCards } from '~shared/api';

import { validationSchema } from './config';
import { AuctionPlaceBitInitialPriceForm } from './types';
import { auctionActions } from './slice';
import { getAuctionAdditionalNftInfo, useAuctionModel } from './model';

export const useAuctionCardsQuery = () => {
  const dispatch = useDispatch();

  const fetchAuctionCards = () => {
    dispatch(auctionActions.setLoading(true));

    getAuctionCards().then(async (auctionCards) => {
      const auctionCardsWithAdditionalNftInfo = await getAuctionAdditionalNftInfo(auctionCards);
      const notBurnedCards = auctionCardsWithAdditionalNftInfo.filter((card) => !card.isBurned);

      dispatch(auctionActions.setAuctionCards(notBurnedCards));
      dispatch(auctionActions.setLoading(false));
    });
  };

  useMount(fetchAuctionCards);
};

export const useAuctionCardsListEffects = () => {
  useAuctionCardsQuery();
};

export const useAuctionFunctions = (
  tokenId: string,
  bestBetPriceInWei: string,
  reservePriceInWei: string,
  tab: string | null,
  accepted: boolean
) => {
  const form = useForm<AuctionPlaceBitInitialPriceForm>(
    tab !== AuctionTab.MySales ? { resolver: yupResolver(validationSchema) } : {}
  );

  const { handleTakeCard, handlePlaceBid, handlePermitPlaceBid } = useAuctionModel();

  const { account } = useWeb3React();
  const { openSnackbar } = useSnackbar();

  const handleSubmit = form.handleSubmit(async ({ price }: AuctionPlaceBitInitialPriceForm) => {
    if (!account) {
      return;
    }

    if (tab === AuctionTab.MySales || (tab === AuctionTab.MyBids && !accepted)) {
      handleTakeCard({ args: [tokenId] });

      return;
    }

    const largestBetPrice = Number(ethers.utils.formatEther(bestBetPriceInWei));
    const currentBetPrice = Number(price);
    const reservePrice = Number(ethers.utils.formatEther(reservePriceInWei));

    const isEnough = currentBetPrice > reservePrice && currentBetPrice > largestBetPrice;

    if (!isEnough) {
      const minimumBidPrice = (largestBetPrice === 0 ? reservePrice : largestBetPrice) + 1;

      openSnackbar({
        type: 'error',
        message: `You should bid at least ${minimumBidPrice} MCN`,
      });

      return;
    }

    // TODO: Add permit

    try {
      await handlePermitPlaceBid(price);
      await handlePlaceBid(tokenId, price);
    } catch {}
  });

  return {
    form,
    handleSubmit,
  };
};

export const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
