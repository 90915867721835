import { styled } from '~shared/lib/styles';
import { Stack } from '~shared/ui';

export const ParticipantRoot = styled(Stack)(({ theme }) => ({
  padding: '10px 16px',
  background: theme.palette.grey.A10,
  borderRadius: 8,
  justifySelf: 'flex-start',
  alignItems: 'center',
}));

export const ParticipantPlace = styled('div')(() => ({
  fontSize: 15,
  fontWeight: 600,
  lineHeight: 18 / 15,
  letterSpacing: -0.2,
}));

export const ParticipantName = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.text.secondary,
}));
