export const routes = {
  index: '/',
  ranking: '/ranking',
  marketplace: '/marketplace',
  profile: '/profile',
  tournaments: '/tournaments',
  passwordRecovery: '/reset-password',

  // mobile routes
  cards: '/cards',
  card: '/cards/:tokenId',
  notifications: '/notifications',

  sports: {
    basketball: '/sports/basketball',
    football: '/sports/football',
    hockey: '/sports/hockey',
    mma: '/sports/mma',
    cricket: '/sports/cricket',
    baseball: '/sports/baseball',
    tennis: '/sports/tennis',
    americanFootball: '/sports/american-football',

    esports: '/sports/esports',
    dota: '/sports/dota2',
    csgo: '/sports/csgo',
    leagueOfLegends: '/sports/league-of-legends',

    // Football leagues
    uefaChampionsLeague: '/sports/football/uefa-champions-league',
    uefaEuropaConferenceLeague: '/sports/football/uefa-europa-conference-league',
    englishPremierLeague: '/sports/football/english-premier-league',
    ligue1: '/sports/football/ligue-1',
    laLiga: '/sports/football/la-liga',
    bundesliga: '/sports/football/bundesliga',
    serieA: '/sports/football/serie-a',

    // Tennis leagues
    legends: '/sports/tennis/legends',
    wta125K: '/sports/tennis/wta-125-K',
    wta: '/sports/tennis/wta',
    atp: '/sports/tennis/atp',

    // CSGO
    CSGO_1xP: '/sports/csgo/1xP',
    CSGO_BLAST: '/sports/csgo/BLAST',
    CSGO_CBCS: '/sports/csgo/CBCS',
    CSGO_CCTCEU: '/sports/csgo/CCT_CEU',
    CSGO_CCTEE: '/sports/csgo/CCT_EE',
    CSGO_CCTNE: '/sports/csgo/CCT_NE',
    CSGO_CCTSA: '/sports/csgo/CCT_SA',
    CSGO_EME: '/sports/csgo/EME',
    CSGO_EPLDiv1: '/sports/csgo/EPL_Div_1',
    CSGO_ESEAPSCNA: '/sports/csgo/ESEA_PSC_NA',
    CSGO_ESL: '/sports/csgo/ESL',
    CSGO_ESLAPAC: '/sports/csgo/ESL_APAC',
    CSGO_ESLDA: '/sports/csgo/ESL_DA',
    CSGO_ESLDW: '/sports/csgo/ESL_DW',
    CSGO_ESLEU: '/sports/csgo/ESL_EU',
    CSGO_ESLNA: '/sports/csgo/ESL_NA',
    CSGO_ESLPRO: '/sports/csgo/ESL_PRO',
    CSGO_ESM: '/sports/csgo/ESM',
    CSGO_FRLG: '/sports/csgo/FRLG',
    CSGO_GCLS: '/sports/csgo/GCLS',
    CSGO_GM8: '/sports/csgo/GM8',
    CSGO_IEM: '/sports/csgo/IEM',
    CSGO_IEMSYD: '/sports/csgo/IEM_SYD',
    CSGO_IESF: '/sports/csgo/IESF',
    CSGO_KaBuM: '/sports/csgo/KaBuM',
    CSGO_LLP: '/sports/csgo/LLP',
    CSGO_PCV: '/sports/csgo/PC_V',
    CSGO_PGL: '/sports/csgo/PGL',
    CSGO_PWAP: '/sports/csgo/PWAP',
    CSGO_RBC: '/sports/csgo/RBC',
    CSGO_RCL: '/sports/csgo/RCL',
    CSGO_SKYM: '/sports/csgo/SKY_M',
    CSGO_TPEU: '/sports/csgo/TP_EU',
    CSGO_TPNA: '/sports/csgo/TP_NA',
    CSGO_TPWC: '/sports/csgo/TPWC',

    // dota
    DOTA2_ASIAN: '/sports/dota2/ASIAN',
    DOTA2_MSMS: '/sports/dota2/MSMS',
    DOTA2_PGS: '/sports/dota2/PGS',
    DOTA2_RMS: '/sports/dota2/RMS',
    DOTA2_TI: '/sports/dota2/TI',

    // League of legends
    LOL_CBLOL: '/sports/league-of-legends/CBLOL',
    LOL_CBLOLA: '/sports/league-of-legends/CBLOLA',
    LOL_EBL: '/sports/league-of-legends/EBL',
    LOL_EMEA: '/sports/league-of-legends/EMEA',
    LOL_ESS: '/sports/league-of-legends/ESS',
    LOL_GLL: '/sports/league-of-legends/GLL',
    LOL_HPM: '/sports/league-of-legends/HPM',
    LOL_LCK: '/sports/league-of-legends/LCK',
    LOL_LCKCL: '/sports/league-of-legends/LCK_CL',
    LOL_LCO: '/sports/league-of-legends/LCO',
    LOL_LCS: '/sports/league-of-legends/LCS',
    LOL_LDL: '/sports/league-of-legends/LDL',
    LOL_LECEMEA: '/sports/league-of-legends/LECEMEA',
    LOL_LFL: '/sports/league-of-legends/LFL',
    LOL_LJL: '/sports/league-of-legends/LJL',
    LOL_LLA: '/sports/league-of-legends/LLA',
    LOL_LPL: '/sports/league-of-legends/LPL',
    LOL_LPLOL: '/sports/league-of-legends/LPLOL',
    LOL_LVPSL: '/sports/league-of-legends/LVP_SL',
    LOL_NACL: '/sports/league-of-legends/NACL',
    LOL_NLC: '/sports/league-of-legends/NLC',
    LOL_PCS: '/sports/league-of-legends/PCS',
    LOL_PGN: '/sports/league-of-legends/PGN',
    LOL_PRM: '/sports/league-of-legends/PRM',
    LOL_TAL: '/sports/league-of-legends/TAL',
    LOL_TCL: '/sports/league-of-legends/TCL',
    LOL_UL: '/sports/league-of-legends/UL',
    LOL_VCS: '/sports/league-of-legends/VCS',
    LOL_Worlds: '/sports/league-of-legends/Worlds',
  },

  // test
  uiKitDemo: '/ui-kit-demo',
};
