import { forwardRef } from 'react';

import { clsx } from 'clsx';

import { UIKitLinearProgress, UIKitLinearProgressRoot, linearProgressClasses } from './styled';
import { LinearProgressProps } from './types';

export const LinearProgress = forwardRef<HTMLDivElement, LinearProgressProps>(
  (
    { progress = 0, reverse = false, progressColor = 'candy', height, className, ...props },
    ref
  ) => {
    return (
      <UIKitLinearProgressRoot
        {...props}
        className={clsx(linearProgressClasses.root, className)}
        height={height}
        ref={ref}
      >
        <UIKitLinearProgress
          className={linearProgressClasses.progress}
          reverse={reverse}
          progressColor={progressColor}
          style={{
            transform: `translateX(${reverse ? 100 - progress : progress - 100}%)`,
            backgroundPositionX: `${(progress - 100) / 3}%`,
          }}
        />
      </UIKitLinearProgressRoot>
    );
  }
);
