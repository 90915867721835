import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui';

export const PaperLayoutRoot = styled('main', { name: 'PaperLayout' })({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  minHeight: '100vh',

  paddingLeft: 16,
  paddingRight: 16,
});

export const PaperLayoutLogoWrapper = styled(Box)({
  padding: '20px 8px',
  marginBottom: 10,
});
