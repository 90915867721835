import { ethers } from 'ethers';

import { Arena__factory } from '~shared/contracts';
import { getCalls } from '~shared/api';

export const getViewerCallCards = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const arenaContract = Arena__factory.connect(process.env.REACT_APP_ADDRESS_SK_ARENA, provider);

  const activeCallsAmount = await arenaContract
    .callsByAddressCount(address)
    .then((amount) => amount.toNumber());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const enumeratedAmountArray = Array.from(Array(activeCallsAmount).keys());

  const activeCalls = await Promise.all(
    enumeratedAmountArray.map(async (index) => {
      const { callIds: _callIds, cardIds: _cardIds } = await arenaContract.callsByAddressAndIndex(
        address,
        index
      );

      const callIds = _callIds.map((callId) => callId.toNumber()).filter((callId) => callId !== 0);
      const cardIds = _cardIds.map((cardId) => cardId.toNumber()).filter((cardId) => cardId !== 0);

      return callIds.map((callId, index) => ({
        callId: callId,
        cardId: cardIds[index],
      }));
    })
  ).then((result) =>
    result
      .flatMap((result) => result)
      .map((result) => ({ callId: result.callId.toString(), cardId: result.cardId.toString() }))
  );

  const activeCallEvents = await getCalls({
    userAddress: address,
    ids: activeCalls.map((activeCallAndCard) => activeCallAndCard.callId),
  });

  return {
    callCardIds: activeCalls.map((activeCall) => activeCall.cardId),
    activeCalls,
    activeCallEvents,
  };
};
