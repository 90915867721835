import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SelectOption, Stack } from '~shared/ui';

import { FixCardCurrency } from '~features/nft/fix-card';

import { FixCardFormProps } from './types';
import { StyledSelectField } from './styled';

export const FixCardDialogForm: FC<FixCardFormProps> = ({ price, onFixCard, onCurrencyChange }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={8 / 8}>
      <Stack component="form" direction="row" spacing={18 / 8} alignItems="start">
        <StyledSelectField defaultValue={FixCardCurrency.Matic} onChange={onCurrencyChange}>
          <SelectOption value={FixCardCurrency.Matic}>{price.matic} Matic</SelectOption>
          <SelectOption value={FixCardCurrency.MCN}>{price.mcn} MCN</SelectOption>
        </StyledSelectField>
        <Button onClick={onFixCard}>{t('Other.fixBtn')}</Button>
      </Stack>
    </Stack>
  );
};
