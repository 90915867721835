import { styled } from '~shared/lib/styles';

export const notificationTagClasses = {
  root: 'NotificationTag-root',

  primary: 'NotificationTag-primary',
  success: 'NotificationTag-success',
};

export const NotificationTagRoot = styled('div')(({ theme }) => ({
  fontSize: 10,
  fontWeight: 600,
  lineHeight: 14 / 10,
  padding: '2px 6px',
  borderRadius: 4,
  width: 'max-content',

  [`&.${notificationTagClasses.primary}`]: {
    background: theme.palette.primary.main,
  },

  [`&.${notificationTagClasses.success}`]: {
    background: theme.palette.success.main,
  },
}));
