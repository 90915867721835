import { ReactComponent as ChevronDown } from './icons/chevron-down.svg';
import { ReactComponent as ChevronRight } from './icons/chevron-right.svg';
import { ReactComponent as ChevronUp } from './icons/chevron-up.svg';
import { ReactComponent as ChevronLeft } from './icons/chevron-left.svg';
import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as Back } from './icons/back.svg';
import { ReactComponent as Avatar } from './icons/avatar.svg';
import { ReactComponent as Battle } from './icons/battle.svg';
import { ReactComponent as Bell } from './icons/bell.svg';
import { ReactComponent as BurgerMenu } from './icons/burger-menu.svg';
import { ReactComponent as Calls } from './icons/calls.svg';
import { ReactComponent as Cards } from './icons/cards.svg';
import { ReactComponent as CardsOutlined } from './icons/cards-outlined.svg';
import { ReactComponent as Challenge } from './icons/challenge.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as Close2 } from './icons/close-2.svg';
import { ReactComponent as Coins } from './icons/coins.svg';
import { ReactComponent as Favorites } from './icons/favorites.svg';
import { ReactComponent as Home } from './icons/home.svg';
import { ReactComponent as Like } from './icons/like.svg';
import { ReactComponent as Lock } from './icons/lock.svg';
import { ReactComponent as Marketplace } from './icons/marketplace.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Moon } from './icons/moon.svg';
import { ReactComponent as More } from './icons/more.svg';
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as PlusRound } from './icons/plus-round.svg';
import { ReactComponent as Question } from './icons/question.svg';
import { ReactComponent as Rankings } from './icons/rankings.svg';
import { ReactComponent as Repair } from './icons/repair.svg';
import { ReactComponent as Fix } from './icons/fix.svg';
import { ReactComponent as Snowflake } from './icons/snowflake.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Shop } from './icons/shop.svg';
import { ReactComponent as Time } from './icons/time.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as Bullet } from './icons/bullet.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as CheckCircle } from './icons/check-circle.svg';
import { ReactComponent as X } from './icons/x.svg';
import { ReactComponent as XRound } from './icons/x-round.svg';
import { ReactComponent as Terms } from './icons/terms.svg';
import { ReactComponent as Security } from './icons/security.svg';
import { ReactComponent as AlertRed } from './icons/alert-red.svg';
import { ReactComponent as ViewGrid } from './icons/view-grid.svg';
import { ReactComponent as ViewLine } from './icons/view-line.svg';
import { ReactComponent as Warn } from './icons/warn.svg';
import { ReactComponent as Fire } from './icons/fire.svg';
import { ReactComponent as Auction } from './icons/auction.svg';
import { ReactComponent as Cup } from './icons/cup.svg';
import { ReactComponent as Crown } from './icons/crown.svg';

import { ReactComponent as CoinMainCoin } from './icons/coins/maincoin.svg';

import { ReactComponent as WalletMoney } from './icons/wallet/money.svg';
import { ReactComponent as WalletIncome } from './icons/wallet/income.svg';
import { ReactComponent as WalletOutcome } from './icons/wallet/outcome.svg';
import { ReactComponent as WalletMarketplace } from './icons/wallet/marketplace.svg';
import { ReactComponent as WalletMetaMask } from './icons/wallet/metamask.svg';
import { ReactComponent as WalletCoinbase } from './icons/wallet/coinbase.svg';
import { ReactComponent as WalletTrust } from './icons/wallet/trust.svg';
import { ReactComponent as WalletConnect } from './icons/wallet/walletconnect.svg';

import { ReactComponent as SportAmericanFootball } from './icons/sports/american-football.svg';
import { ReactComponent as SportBaseball } from './icons/sports/baseball.svg';
import { ReactComponent as SportBasketball } from './icons/sports/basketball.svg';
import { ReactComponent as SportHockey } from './icons/sports/hockey.svg';
import { ReactComponent as SportHome } from './icons/sports/home.svg';
import { ReactComponent as SportMma } from './icons/sports/mma.svg';
import { ReactComponent as SportPad } from './icons/sports/pad.svg';
import { ReactComponent as SportFootball } from './icons/sports/football.svg';
import { ReactComponent as SportCricket } from './icons/sports/cricket.svg';
import { ReactComponent as SportDota } from './icons/sports/dota.svg';
import { ReactComponent as SportCSGO } from './icons/sports/csgo.svg';
import { ReactComponent as SportLeagueOfLegends } from './icons/sports/league-of-legends.svg';
import { ReactComponent as SportTennis } from './icons/sports/tennis.svg';

import { ReactComponent as FlagAU } from './icons/flags/au.svg';
import { ReactComponent as FlagBE } from './icons/flags/be.svg';
import { ReactComponent as FlagDE } from './icons/flags/de.svg';
import { ReactComponent as FlagFR } from './icons/flags/fr.svg';
import { ReactComponent as FlagIN } from './icons/flags/in.svg';
import { ReactComponent as FlagIT } from './icons/flags/it.svg';
import { ReactComponent as FlagRU } from './icons/flags/ru.svg';
import { ReactComponent as FlagSP } from './icons/flags/sp.svg';
import { ReactComponent as FlagUK } from './icons/flags/uk.svg';
import { ReactComponent as FlagUS } from './icons/flags/us.svg';

import { ReactComponent as SocialApple } from './icons/socials/apple.svg';
import { ReactComponent as SocialFacebook } from './icons/socials/facebook.svg';
import { ReactComponent as SocialGoogle } from './icons/socials/google.svg';
import { ReactComponent as SocialGoogleColored } from './icons/socials/google-colored.svg';
import { ReactComponent as ContactUs } from './icons/contact-us.svg';

import { ReactComponent as NotificationSystem } from './icons/notifications/system.svg';
import { ReactComponent as NotificationGift } from './icons/notifications/gift.svg';

export const iconsAssets = {
  'chevron-down': ChevronDown,
  'chevron-up': ChevronUp,
  'chevron-right': ChevronRight,
  'chevron-left': ChevronLeft,
  'avatar': Avatar,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'back': Back,
  'battle': Battle,
  'bell': Bell,
  'burger-menu': BurgerMenu,
  'calls': Calls,
  'cards': Cards,
  'cards-outlined': CardsOutlined,
  'challenge': Challenge,
  'close': Close,
  'coins': Coins,
  'favorites': Favorites,
  'home': Home,
  'like': Like,
  'lock': Lock,
  'marketplace': Marketplace,
  'menu': Menu,
  'moon': Moon,
  'more': More,
  'play': Play,
  'plus': Plus,
  'plus-round': PlusRound,
  'question': Question,
  'rankings': Rankings,
  'repair': Repair,
  'fix': Fix,
  'search': Search,
  'shop': Shop,
  'time': Time,
  'eye': Eye,
  'close-2': Close2,
  'info': Info,
  'bullet': Bullet,
  'check': Check,
  'check-circle': CheckCircle,
  'x': X,
  'x-round': XRound,
  'terms': Terms,
  'security': Security,
  'alert-red': AlertRed,
  'snowflake': Snowflake,
  'warn': Warn,
  'fire': Fire,
  'auction': Auction,
  'view-grid': ViewGrid,
  'view-line': ViewLine,
  'contact-us': ContactUs,
  'cup': Cup,
  'crown': Crown,

  'wallet-money': WalletMoney,
  'wallet-income': WalletIncome,
  'wallet-outcome': WalletOutcome,
  'wallet-marketplace': WalletMarketplace,
  'wallet-metamask': WalletMetaMask,
  'wallet-trust': WalletTrust,
  'wallet-coinbase': WalletCoinbase,
  'wallet-walletconnect': WalletConnect,

  'sport-american-football': SportAmericanFootball,
  'sport-baseball': SportBaseball,
  'sport-tennis': SportTennis,
  'sport-basketball': SportBasketball,
  'sport-hockey': SportHockey,
  'sport-home': SportHome,
  'sport-mma': SportMma,
  'sport-pad': SportPad,
  'sport-football': SportFootball,
  'sport-cricket': SportCricket,
  'sport-dota': SportDota,
  'sport-csgo': SportCSGO,
  'sport-league-of-legends': SportLeagueOfLegends,

  'flag-au': FlagAU,
  'flag-be': FlagBE,
  'flag-de': FlagDE,
  'flag-fr': FlagFR,
  'flag-in': FlagIN,
  'flag-it': FlagIT,
  'flag-ru': FlagRU,
  'flag-sp': FlagSP,
  'flag-uk': FlagUK,
  'flag-us': FlagUS,

  'coin-maincoin': CoinMainCoin,

  'social-apple': SocialApple,
  'social-facebook': SocialFacebook,
  'social-google': SocialGoogle,
  'social-google-colored': SocialGoogleColored,

  'notification-system': NotificationSystem,
  'notification-gift': NotificationGift,
};
