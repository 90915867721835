import { Link } from 'react-router-dom';

import { styled } from '~shared/lib/styles';

export const linkClasses = {
  underline: 'Link-underline',
  fullWith: 'Link-fullWidth',
};

export const UIKitLinkRoot = styled(Link, { name: 'Link' })(({ theme }) => ({
  color: theme.palette.grey.A50,
  textDecoration: 'none',

  ['&:visited']: {
    color: theme.palette.grey.A50,
  },

  ['&:hover']: {
    textDecoration: 'none',
  },

  [`&.${linkClasses.underline}:hover`]: {
    textDecoration: 'underline',
  },

  [`&.${linkClasses.fullWith}`]: {
    width: '100%',
  },
}));
