import { noScrollbar, styled } from '~shared/lib/styles';

import { Modal, Paper } from '../../common';

import { ButtonIcon } from '../../controls';

export const dialogClasses = {
  root: 'Dialog-root',
  content: 'Dialog-content',
  scrollPaper: 'Dialog-scrollPaper',
  paper: 'Dialog-paper',
  closeButton: 'Dialog-closeButton',
};

export const UIKitDialogRoot = styled(Modal, { name: 'Dialog' })(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

export const UIKitDialogScrollPaper = styled('div', { name: 'Dialog', slot: 'scrollPaper' })({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
});

interface UIKitDialogPaperProps {
  width?: number;
  backgroundColor?: string;
}

export const UIKitDialogPaper = styled(Paper, {
  name: 'Dialog',
  slot: 'paper',
  shouldForwardProp: (propName: keyof UIKitDialogPaperProps) => propName !== 'backgroundColor',
})<UIKitDialogPaperProps>(({ width, theme, backgroundColor }) => ({
  maxWidth: width,
  width: width ? '100%' : 'initial',
  padding: 0,

  maxHeight: 'calc(100% - 64px)',
  background: backgroundColor || theme.palette.paper.dark,

  overflowY: 'auto',
  margin: 16,
  pointerEvents: 'initial',

  ...noScrollbar,
}));

export const UIKitDialogCloseButton = styled(ButtonIcon, { name: 'Dialog', slot: 'closeButton' })({
  position: 'absolute',
  inset: '10px 10px auto auto',
  zIndex: 1,
});
