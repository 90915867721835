import { styled } from '~shared/lib/styles';

import { Box } from '../../layout';

export const DialogContentRoot = styled(Box, { name: 'DialogContent' })({
  display: 'flex',
  flexDirection: 'column',

  position: 'relative',
  padding: '30px 20px',
  overflowY: 'auto',
});
