import { MenuItem as MuiBaseMenuItem, menuItemClasses as muiBaseMenuItemClasses } from '@mui/base';

import { styled, theme } from '~shared/lib/styles';

interface UIKitMenuItemProps {
  color: 'text' | 'error';
  $selected?: boolean;
}

const menuItemClasses = {
  ...muiBaseMenuItemClasses,
};

export const UIKitMenuItem = styled(MuiBaseMenuItem)<UIKitMenuItemProps>(
  ({ theme, color, $selected }) => ({
    padding: '10px 16px',
    color: getColor(color),
    minWidth: 180,
    minHeight: 40,
    cursor: 'pointer',
    transition: theme.transitions.create('background'),
    display: 'flex',
    alignItems: 'center',

    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,

    [`&:hover:not(.${menuItemClasses.disabled})`]: {
      background: theme.palette.paper.light,
    },

    [`&.${menuItemClasses.disabled}`]: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    ...($selected ? { background: theme.palette.paper.grey } : null),
  })
);

const getColor = (color: UIKitMenuItemProps['color']) => {
  switch (color) {
    case 'error':
      return theme.palette.error.main;
    case 'text':
      return theme.palette.text.primary;
  }
};
