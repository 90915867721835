import { IconAssetNamesType } from '~shared/ui';

export const leagueToLogoMap: Record<string, IconAssetNamesType> = {
  // Football
  'UEFA Champions League': 'illustration-football-uefa-champions-league',
  'UEFA Europa Conference League': 'illustration-football-uefa-europa-conference-league',
  'English Premier League': 'illustration-football-premier-league',
  'Ligue 1': 'illustration-football-ligue-1',
  'LaLiga': 'illustration-football-la-liga',
  'Bundesliga': 'illustration-football-bundesliga',
  'Serie A': 'illustration-football-serie-a',

  // Baseball
  'Major League Baseball': 'illustration-mlb',

  // Basketball
  'NBA': 'illustration-nba',
  'NBA2': 'illustration-nba',

  // Hockey
  'NHL': 'illustration-nhl',

  // Tennis
  'Tennis': 'illustration-tennis',
  'Legends': 'illustration-tennis-legends',
  'WTA 125K': 'illustration-tennis-wta-125k',
  'WTA': 'illustration-tennis-wta',
  'ATP': 'illustration-tennis-atp',

  // MMA
  'UFC': 'illustration-ufc',
  'MMA': 'illustration-ufc',

  // Cricket
  'IndianPL': 'illustration-ipl',
  'IPL': 'illustration-ipl',

  // Esports
  'Dota2': 'sport-dota',
  'LOL': 'sport-league-of-legends',
  'CSGO': 'sport-csgo',

  // American football
  'NFL': 'illustration-nfl',
};
