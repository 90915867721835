import { styled } from '~shared/lib/styles';
import { LinearProgress, linearProgressClasses } from '~shared/ui';

export const EventDialogStatisticsRowContainer = styled('div')(() => ({}));

export const EventDialogStatisticsRowTopContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
}));

export const EventDialogStatisticsRowBottomContainer = styled('div')(() => ({
  marginTop: 16,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: 14,
}));

interface EventDialogStatisticsProgressProps {
  $loses?: boolean;
}

export const EventDialogStatisticsProgress = styled(
  LinearProgress
)<EventDialogStatisticsProgressProps>(({ $loses, theme }) => ({
  borderRadius: 0,
  height: 6,

  [`& .${linearProgressClasses.progress}`]: {
    background: $loses ? theme.palette.error.main : theme.palette.primary.main,
    borderRadius: 0,
  },
}));
