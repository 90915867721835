import { FC, MouseEvent, useCallback } from 'react';
import { clsx } from 'clsx';

import {
  UIKitBottomNavigationActionIndicator,
  UIKitBottomNavigationActionLabel,
  UIKitBottomNavigationActionRoot,
  bottomNavigationActionClasses,
} from './styled';

import { BottomNavigationActionProps } from './types';

export const BottomNavigationAction: FC<BottomNavigationActionProps> = ({
  label,
  icon,
  selected,
  className,
  onChange,
  value,
  onClick,

  ...props
}) => {
  const handleChange = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (onChange) {
        onChange(event, value);
      }

      if (onClick) {
        onClick(event);
      }
    },
    [onChange, onClick, value]
  );

  return (
    <UIKitBottomNavigationActionRoot
      {...props}
      className={clsx(
        {
          [bottomNavigationActionClasses.selected]: selected,
          [bottomNavigationActionClasses.disabled]: props.disabled,
        },
        className
      )}
      onClick={handleChange}
    >
      {icon}
      <UIKitBottomNavigationActionLabel>{label}</UIKitBottomNavigationActionLabel>
      <UIKitBottomNavigationActionIndicator className={bottomNavigationActionClasses.indicator} />
    </UIKitBottomNavigationActionRoot>
  );
};
