import { forwardRef } from 'react';
import { clsx } from 'clsx';

import { Avatar, Icon } from '../../dataDisplay';

import { UIKitAvatarActionRoot, UIKitAvatarEditWrapper, avatarActionClasses } from './styled';
import { AvatarActionProps } from './types';

export const AvatarAction = forwardRef<HTMLDivElement, AvatarActionProps>(
  (
    {
      // action props
      edit,
      selected,
      selectable,
      interactive,
      className,

      // avatar props
      src,
      srcSet,
      referrerPolicy,
      crossOrigin,
      alt,
      size,
      children,

      // box props
      ...props
    },
    ref
  ) => {
    return (
      <UIKitAvatarActionRoot
        {...props}
        ref={ref}
        className={clsx(
          avatarActionClasses.root,
          {
            [avatarActionClasses.edit]: edit,
            [avatarActionClasses.selected]: selected,
            [avatarActionClasses.interactive]: interactive,
            [avatarActionClasses.selectable]: selectable,
            [avatarActionClasses.clickable]: !!props.onClick || !!props.onClickCapture,
          },
          className
        )}
      >
        <Avatar
          src={src}
          srcSet={srcSet}
          referrerPolicy={referrerPolicy}
          crossOrigin={crossOrigin}
          alt={alt}
          size={size}
          children={children}
        />

        <UIKitAvatarEditWrapper className={avatarActionClasses.editWrapper}>
          <Icon name="plus" />
        </UIKitAvatarEditWrapper>
      </UIKitAvatarActionRoot>
    );
  }
);
