import { styled } from '~shared/lib/styles';
import { Icon } from '~shared/ui';

export const sportLineClasses = {
  disabled: 'SportLine-disabled',
};

export const SportLineRoot = styled('button')(({ theme }) => ({
  border: 'none',
  color: theme.palette.text.primary,
  background: theme.palette.grey.A5,
  borderRadius: 12,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  paddingRight: 8,

  [`&.${sportLineClasses.disabled}`]: {
    opacity: 0.2,
  },
}));

export const SportLineIllustration = styled('img')({
  width: 100,
  height: 48,
  objectFit: 'cover',
  objectPosition: 'center',
});

export const SportLineArrow = styled(Icon)({
  marginLeft: 'auto',
});

export const SportLineLabel = styled('div')({
  marginLeft: 20,

  fontSize: 14,
  lineHeight: 18 / 14,
  fontWeight: 500,
});
