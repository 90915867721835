import { apiInstance } from '../apiInstance';

import { ApiPostFeedback } from './types';
import { ContactUsRoutes } from './routes';

export const postFeedback: ApiPostFeedback = (params) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(ContactUsRoutes.feedback(), params)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
