/* eslint-disable no-useless-computed-key */
import { CSSObject } from '@mui/system';

const bgColor = '#3c3c3c'; //theme.palette.grey.A20;
const thumbColor = '#636363'; //theme.palette.grey.A50;

export const customScrollbar: CSSObject = {
  ['&']: {
    scrollbarWidth: 'thin',
    scrollbarColor: `${bgColor}, ${thumbColor}`,
  },

  ['&::-webkit-scrollbar']: {
    width: 6,

    ['&-track']: { background: bgColor },
    ['&-thumb']: { backgroundColor: thumbColor },
  },
};
