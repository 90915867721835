import { createElement } from 'react';

import { BattleChoiceId, BattleResult } from '~shared/api';

import { BattleBadge, BattleBadgeProps } from '../ui';

export const renderResultBadge = (result: BattleResult, currentSlot: BattleChoiceId) => {
  if (result === BattleResult.Draw && currentSlot === BattleChoiceId.Draw) {
    return createElement<BattleBadgeProps>(BattleBadge, { draw: true });
  }

  if (result.valueOf() === currentSlot.valueOf()) {
    return createElement<BattleBadgeProps>(BattleBadge, { win: true });
  }
};
