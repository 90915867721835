import { FC } from 'react';

import { Box, Button, Icon, Stack } from '~shared/ui';

import { InvalidViewProps } from './types';

export const InvalidView: FC<InvalidViewProps> = ({ onChooseFile, onCancel }) => {
  return (
    <Box>
      <Icon name="alert-red" /> Can’t load file
      <Stack mt={12 / 8} spacing={0.5} direction="row">
        <Button size="sm" variant="outlined" onClick={onChooseFile}>
          Select another
        </Button>
        <Button size="sm" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};
