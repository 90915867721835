import { styled } from '~shared/lib/styles';
import { Typography } from '~shared/ui';

export const NftCardInfoStatusText = styled(Typography, {
  name: 'NftCardInfo',
  slot: 'statusText',
})(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 14 / 12,
  marginTop: 6,
  color: theme.palette.grey.A50,
}));
