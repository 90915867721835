import { FC } from 'react';

import { LinearProgress, Typography } from '~shared/ui';

import { ProgressViewProps } from './types';
import { ProgressViewPaper } from './styled';

export const ProgressView: FC<ProgressViewProps> = ({ ...props }) => {
  return (
    <ProgressViewPaper>
      <Typography mb={10 / 8} align="center" color="secondary">
        File is loading
      </Typography>
      <LinearProgress {...props} />
    </ProgressViewPaper>
  );
};
