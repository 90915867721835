import { styled } from '~shared/lib/styles';
import { buttonIconClasses } from '~shared/ui';

export const Root = styled('div')(() => ({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: 24 / 18,
  letterSpacing: -0.2,

  display: 'flex',
  alignItems: 'center',
  justifyItems: 'space-between',
  justifySelf: 'stretch',

  [`& > .${buttonIconClasses.root}`]: {
    marginRight: 8,
  },
}));

export const Amount = styled('span')(({ theme }) => ({
  color: theme.palette.grey.A50,
  marginLeft: 16,
}));

export const ActiveAmount = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));
