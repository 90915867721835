import { ReactNode, createElement } from 'react';

import { ApiGetBetsBetMappedData, ApiGetBetsResponseData } from '~shared/api';

import { useBattleAutoStyle } from '~entities/battle';

import { BattleBet } from '../ui';

import { isEventCurrent, isEventPassed } from './lib';

interface UseBetListProps {
  list?: ApiGetBetsResponseData;
}

interface UseBetList {
  (params: UseBetListProps): {
    list: Array<ReactNode>;
    passedList: Array<ReactNode>;
    currentList: Array<ReactNode>;
  };
}

export const useBetList: UseBetList = ({ list = [] }) => {
  const style = useBattleAutoStyle('line');

  const renderBet = (bet: ApiGetBetsBetMappedData) => {
    return createElement(BattleBet, { ...bet, key: bet.eventId, ...style });
  };

  return {
    list: list.map(renderBet),
    passedList: list.filter((bet) => isEventPassed(bet.result)).map(renderBet),
    currentList: list.filter((bet) => isEventCurrent(bet.result)).map(renderBet),
  };
};
