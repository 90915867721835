import { styled } from '~shared/lib/styles';
import { IconAssetNamesType } from '~shared/ui';

import { staticNftClasses } from '~entities/nft';

interface NotificationIconFactoryStateIconPlateProps {
  name: IconAssetNamesType;
}

export const NotificationIconFactoryStateIconPlate = styled(
  'div'
)<NotificationIconFactoryStateIconPlateProps>(({ name, theme }) => {
  const mapIconNameToBackground: Record<
    Extract<IconAssetNamesType, 'fix' | 'snowflake' | 'auction'>,
    string
  > = {
    fix: theme.palette.warn.secondary,
    snowflake: theme.palette.primary.main,
    auction: theme.palette.violet.main,
  };

  return {
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1,
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1,

    background: mapIconNameToBackground[name!],
  };
});

interface NotificationIconFactoryCardWrapperProps {
  stack?: boolean;
}

export const NotificationIconFactoryCardWrapper = styled(
  'div'
)<NotificationIconFactoryCardWrapperProps>(({ stack }) => {
  const stackStyles = {
    position: 'absolute',

    width: 16,
    height: 23,

    minWidth: 16,
    minHeight: 23,

    ['&:first-child']: {
      bottom: 5,
      left: 7,
    },
    ['&:last-child']: {
      top: 5,
      right: 7,
    },
  };

  return {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [`& > .${staticNftClasses.root}`]: {
      width: 20,
      height: 28,

      minWidth: 20,
      minHeight: 28,

      fontSize: 28,

      border: '1px solid #767676',

      ...(stack ? stackStyles : null),
    },
  };
});

export const NotificationIconFactoryEventText = styled('div')(() => ({
  fontSize: 8,
  fontWeight: 600,
  lineHeight: 13 / 8,
}));
