/* eslint-disable no-useless-computed-key */

import { CSSObject } from '@mui/system';

export const noScrollbar: CSSObject = {
  scrollbarWidth: 'none',

  ['msOverflowStyle']: 'none',
  ['&::-webkit-scrollbar']: {
    display: 'none',
  },
};
