import { ApiGetNfts, Nft } from './types';

const options = {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
    'X-API-Key': 'GlIY6I0HVJBfFrT4pCVAuopNJcqXqO9cIDbeZ4oxBJCWxbxhmA4ybpaADSENWUFM',
  },
};

const chainName = process.env.REACT_APP_IS_MAINNET === 'true' ? 'polygon' : 'mumbai';

export const fetchNfts: ApiGetNfts = async ({ walletAddress }) => {
  const { result: NFTs } = await fetch(
    `https://deep-index.moralis.io/api/v2/${walletAddress}/nft?chain=${chainName}&format=decimal`,
    options
  )
    .then((response) => response.json())
    .catch(console.error);

  return NFTs.filter((item: Nft) => {
    const isMCDSymbol = item.symbol === 'MCD';
    const isAddressEquals =
      item.token_address.toLowerCase() === process.env.REACT_APP_ADDRESS_SK_CARD.toLowerCase();

    return isMCDSymbol && isAddressEquals;
  });
};
