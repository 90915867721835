import { styled } from '~shared/lib/styles';

export const WalletTableRowRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr repeat(3, 130px)',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 92px 80px',
    gap: 12,
  },
}));
