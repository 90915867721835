import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Icon, MenuTab, MenuTabs, MenuTabsList, MenuTabsProps } from '~shared/ui';
import { isTest, routes } from '~shared/config';

import { NavMenuProps } from './types';

export const NavMenu: FC<NavMenuProps> = ({ variant = 'default' }) => {
  const { t } = useTranslation();
  const { pathname: value } = useLocation();
  const navigate = useNavigate();

  const handleChange = useCallback<Exclude<MenuTabsProps['onChange'], undefined>>(
    (e, value) => {
      navigate(value as string);
    },
    [navigate]
  );

  const computedValue = useMemo(() => {
    if ([routes.ranking, routes.marketplace, routes.tournaments].includes(value)) {
      return value;
    }

    return null;
  }, [value]);

  return (
    <MenuTabs value={computedValue} variant={variant} onChange={handleChange}>
      <MenuTabsList>
        <MenuTab value={routes.ranking} icon={<Icon name="rankings" />}>
          {t('HeaderTabs.rankings')}
        </MenuTab>
        <MenuTab value={routes.marketplace} icon={<Icon name="marketplace" />}>
          {t('HeaderTabs.marketplace')}
        </MenuTab>
        {isTest() && (
          <MenuTab value={routes.tournaments} icon={<Icon name="battle" />}>
            {t('HeaderTabs.tournaments')}
          </MenuTab>
        )}
      </MenuTabsList>
    </MenuTabs>
  );
};
