import { FC } from 'react';

import { ContentPlaceholder } from '~shared/ui';

import { BattleVerticalList, useCallList } from '~entities/battle';
import { useCallsListQuery } from '~entities/calls';

import { CallsListProps } from './types';

export const CallsList: FC<CallsListProps> = ({ sport, league }) => {
  const callsListQuery = useCallsListQuery({ sport, league });

  const { list } = useCallList({ list: callsListQuery.list });

  if (callsListQuery.isEmptyList) {
    return <ContentPlaceholder heading="No calls yet" message="There is no calls yet" />;
  }

  return <BattleVerticalList loading={callsListQuery.isLoading}>{list}</BattleVerticalList>;
};
