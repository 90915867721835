import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

import { NftRarity } from '~shared/api';
import { Typography } from '~shared/ui';

import { NFT_RARITY_TO_READABLE_RARITY_NAME_MAP } from './config';

export const getCallSameRarityMessage = (rarity: NftRarity, t: TFunction) => {
  return (
    <Typography>
      <Trans i18nKey="Alerts.acceptCallSameCardRarity">
        You can accept call only with the card of the same rarity.
        <br />
        Please choose{' '}
        <strong>
          {
            // @ts-ignore
            { rarity: t(NFT_RARITY_TO_READABLE_RARITY_NAME_MAP[rarity]) }
          }
        </strong>{' '}
        card
      </Trans>
    </Typography>
  );
};
