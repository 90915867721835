import { motion } from 'framer-motion';

import { styled } from '~shared/lib/styles';

export const UIKitBackdrop = styled(motion.div, { name: 'Backdrop' })({
  position: 'absolute',
  inset: 0,
  zIndex: -1,
  background: 'rgba(0,0,0,0.7)',
});
