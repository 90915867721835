import { addSeconds } from 'date-fns';

export const createTimerEstimateRecord = (estimatedTime: number, uniqueKey: string) => {
  const estimatedTimeDate = addSeconds(new Date(), estimatedTime).getTime();

  window.localStorage.setItem(uniqueKey, String(estimatedTimeDate));
};

/**
 * Returns saved delay time in seconds if actual by comparison, undefined otherwise
 */
export const getTimerEstimateRecordDelay = (uniqueKey: string): number | undefined => {
  const rawEstimatedTimeDate = window.localStorage.getItem(uniqueKey) as string;

  if (rawEstimatedTimeDate) {
    const estimatedTimeDate = new Date(Number(rawEstimatedTimeDate)).getTime();

    const diff = estimatedTimeDate - new Date().getTime();

    if (diff > 0) {
      return Math.round(diff / 1000);
    }
  }
};

export const TIMER_ESTIMATE_RECORD_KEY = 'recovery-password-estimated-time';

export const TIMER_ESTIMATE_DELAY = 45;
