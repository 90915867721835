import { styled } from '~shared/lib/styles';
import { Backdrop, DialogPaper } from '~shared/ui';

export const MOBILE_BREAKPOINT = 500;

export const AuthBackdrop = styled(Backdrop)(({ theme }) => ({
  [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
    background: 'black',
  },
}));

export const Paper = styled(DialogPaper)(({ theme }) => ({
  [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
    margin: 0,
    background: 'transparent',
    borderRadius: 0,
    maxHeight: '100vh',
  },
}));
