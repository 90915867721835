import { Button as MuiBaseButton } from '@mui/base';

import { styled } from '~shared/lib/styles';
import { Box } from '~shared/ui/layout';

export const pillClasses = {
  prepend: 'Pill-prepend',
  append: 'Pill-append',
};

export const UIKitPill = styled(MuiBaseButton)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  padding: '0 10px',
  height: 32,

  borderRadius: '100px',
  border: 'none',
  background: theme.palette.paper.semi,
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  transition: theme.transitions.create('background'),
  verticalAlign: 'middle',

  ['&:hover']: {
    background: theme.palette.paper.light,
    color: theme.palette.text.primary,
  },
}));

export const UIKitPillPrepend = styled(Box)({
  marginRight: 8,
  pointerEvents: 'none',
  display: 'inline-flex',
});

export const UIKitPillAppend = styled(Box)({
  marginLeft: 8,
  pointerEvents: 'none',
  display: 'inline-flex',
});

export const UIKitPillGroup = styled(Box)(({ theme }) => ({
  display: 'flex',

  ['& > *:not(:last-child)']: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: `-1px 0px 0px 0px ${theme.palette.grey.A5} inset`,
  },

  ['& > *:not(:first-child)']: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));
