import { styled } from '~shared/lib/styles';

export const CardPrizeDescription = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 14 / 13,
  color: theme.palette.grey.A50,
}));

export const CardPrize = styled('div')(() => ({
  fontSize: 15,
  fontWeight: 500,
  lineHeight: 18 / 15,
  letterSpacing: -0.2,
}));
