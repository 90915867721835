import { FC, useState } from 'react';

import { Box, Stack } from '~shared/ui/layout';
import { Header } from '~widgets/header';
import {
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Icon,
  MenuTab,
  MenuTabs,
  MenuTabsList,
  Typography,
  VerticalPanel,
  VerticalPanelAction,
} from '~shared/ui';

const MenuTabDemo: FC = () => {
  const [bottomNavigation, setBottomNavigation] = useState(0);
  const [verticalPanel, setVerticalPanel] = useState(0);

  return (
    <Box sx={{ background: '#0B0B0B', minHeight: '100vh' }}>
      <Typography mb={1} borderBottom="1px solid #4A4A4A" sx={{ fontSize: 20, fontWeight: 500 }}>
        Header Authenticated
      </Typography>
      <Box sx={{ position: 'absolute', left: 0, right: 0 }}>
        <Header />
      </Box>
      <Box sx={{ minHeight: 120 }}></Box>

      <Typography
        mb={1}
        mt={4}
        borderBottom="1px solid #4A4A4A"
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        Header Not authenticated
      </Typography>
      <Box sx={{ position: 'absolute', left: 0, right: 0 }}>
        <Header />
      </Box>
      <Box sx={{ minHeight: 120 }}></Box>

      <Typography
        mb={1}
        mt={4}
        borderBottom="1px solid #4A4A4A"
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        Bottom navigation
      </Typography>
      <Box>
        <BottomNavigation
          maxWidth={450}
          value={bottomNavigation}
          onChange={(event, value) => setBottomNavigation(value)}
        >
          <BottomNavigationAction label="Home" icon={<Icon name="sport-home" />} />
          <BottomNavigationAction label="Sports" icon={<Icon name="menu" />} />
          <BottomNavigationAction label="Cards" icon={<Icon name="cards-outlined" />} />
          <BottomNavigationAction label="Marketplace" icon={<Icon name="shop" />} />
          <BottomNavigationAction
            label="Notifications"
            icon={
              <Badge badgeContent={10}>
                <Icon name="bell" />
              </Badge>
            }
          />
        </BottomNavigation>
      </Box>

      <Typography
        mb={1}
        mt={4}
        borderBottom="1px solid #4A4A4A"
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        Vertical Panel
      </Typography>

      <VerticalPanel
        value={verticalPanel}
        onChange={(event, value) => setVerticalPanel(value)}
        maxWidth={220}
        heading={
          <Stack spacing={16 / 8} direction="row" alignItems="center">
            <Avatar size={50} />
            <Typography
              fontSize="18px"
              fontWeight="700"
              lineHeight="24px"
              letterSpacing="-0.5px"
              sx={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}
            >
              Klimov <br /> Pavel
            </Typography>
          </Stack>
        }
      >
        <VerticalPanelAction icon={<Icon name="avatar" />}>Account</VerticalPanelAction>
        <VerticalPanelAction icon={<Icon name="lock" />}>Security</VerticalPanelAction>
        <VerticalPanelAction icon={<Icon name="favorites" />}>Terms</VerticalPanelAction>
        <VerticalPanelAction icon={<Icon name="favorites" />}>Rules</VerticalPanelAction>
        <VerticalPanelAction icon={<Icon name="eye" />}>Privacy</VerticalPanelAction>
        <VerticalPanelAction icon={<Icon name="question" />}>Help</VerticalPanelAction>
      </VerticalPanel>

      <Typography
        mb={1}
        mt={4}
        borderBottom="1px solid #4A4A4A"
        sx={{ fontSize: 20, fontWeight: 500 }}
      >
        Sports menu
      </Typography>

      <MenuTabs variant="sidebar" defaultValue={0}>
        <MenuTabsList>
          <MenuTab icon={<Icon name="sport-home" />}>Home</MenuTab>
          <MenuTab icon={<Icon name="sport-mma" />}>MMA</MenuTab>
          <MenuTab icon={<Icon name="sport-football" />}>Football</MenuTab>
          <MenuTab icon={<Icon name="sport-baseball" />}>Baseball</MenuTab>
          <MenuTab icon={<Icon name="sport-basketball" />}>Basketball</MenuTab>
          <MenuTab icon={<Icon name="sport-american-football" />}>
            American <br /> football
          </MenuTab>
          <MenuTab icon={<Icon name="sport-football" />}>Football</MenuTab>
          <MenuTab icon={<Icon name="sport-hockey" />}>NHL</MenuTab>
          <MenuTab icon={<Icon name="sport-pad" />}>Cybersport</MenuTab>
        </MenuTabsList>
      </MenuTabs>
    </Box>
  );
};

export { MenuTabDemo as MenuTab };
