import { FC } from 'react';

import {
  CircularDiagramContent,
  CircularDiagramContentLabel,
  CircularDiagramContentValue,
  CircularDiagramProgress,
  CircularDiagramProgressSVG,
  CircularDiagramProgressTrack,
  CircularDiagramRoot,
} from './styled';

import { CircularDiagramProps } from './types';

export const CircularDiagram: FC<CircularDiagramProps> = ({ value, label }) => {
  const hasValue = typeof value === 'number';

  return (
    <CircularDiagramRoot>
      <CircularDiagramContent>
        <CircularDiagramContentLabel>{label}</CircularDiagramContentLabel>

        <CircularDiagramContentValue>
          {hasValue ? `${Math.round(value * 100)}%` : '—'}
        </CircularDiagramContentValue>
      </CircularDiagramContent>

      <CircularDiagramProgressSVG>
        <CircularDiagramProgressTrack hasProgress={hasValue} />
        <CircularDiagramProgress hasProgress={hasValue} progress={value ?? 0} />
      </CircularDiagramProgressSVG>
    </CircularDiagramRoot>
  );
};
