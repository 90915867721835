import ReactCrop from 'react-image-crop';

import { styled } from '~shared/lib/styles';

export const CroppedImage = styled('img')({
  maxWidth: '100%',
  width: '100%',
  maxHeight: 180,
  height: '100%',
  borderRadius: 8,
  pointerEvents: 'none',
  userSelect: 'none',

  display: 'block',
});

export const Cropper = styled(ReactCrop)({
  maxWidth: '100%',
  width: '100%',

  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',

  ['.ord-n, .ord-e, .ord-s, .ord-w']: {
    display: 'none',
  },

  ['.ReactCrop__drag-handle.ord-nw']: {
    ['&:after']: { top: -2, left: -2 },
  },
  ['.ReactCrop__drag-handle.ord-ne']: {
    ['&:after']: { top: -2, right: -2 },
  },
  ['.ReactCrop__drag-handle.ord-se']: {
    ['&:after']: { bottom: -2, right: -2 },
  },
  ['.ReactCrop__drag-handle.ord-sw']: {
    ['&:after']: { bottom: -2, left: -2 },
  },

  ['.ReactCrop__crop-selection']: {
    boxShadow: '0 0 0 9999em rgba(11, 11, 11, 0.5)',
    border: 'none',
    position: 'absolute',
    borderRadius: '100%',
    cursor: 'move',
  },
});
